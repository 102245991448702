import { Box, Grid } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestLoadWorkViewGetInfos } from 'Common/Service/common';
import { currencyComma, get_percent } from 'Common/Utils';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import MallNameModule from './Module/MallNameModule';
import PercentModule from './Module/PercentModule';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const WorkView = () => {
  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const [viewInfos, setViewInfos] = useState();
  const [count, setCount] = useState(reload_second);

  const getViewInfo = async () => {
    const view_infos = await requestLoadWorkViewGetInfos({});
    setViewInfos(view_infos);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getViewInfo();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getViewInfo();
  }, []);
  return (
    <>
      <Box>f 1,2,3,4,5</Box>
      <Grid column={['1fr', '1fr']} gap={32} valign="top" height="1000">
        <Box column gap={18}>
          <Box gap={12}>
            <Box>
              노출 진행 상황 <TextSmall> View Progress</TextSmall>{' '}
            </Box>
            <Box>
              [
              <PercentModule
                value={viewInfos?.view_infos?.view_count}
                valueMax={viewInfos?.view_infos?.view_count_max}
              />
              ] {currencyComma(viewInfos?.view_infos?.view_count)} /{' '}
              {currencyComma(viewInfos?.view_infos?.view_count_max)}
              <TextSmall>{count}</TextSmall>
            </Box>
            <Box>
              <progress
                value={viewInfos?.view_infos?.view_count}
                max={viewInfos?.view_infos?.view_count_max}
              />
            </Box>
          </Box>
          <Box column gap={16}>
            {viewInfos?.view_shop_info_list?.map((e, index) => {
              return (
                <Box key={index}>
                  <Box>
                    <MallNameModule {...e?.shop_info} />
                  </Box>
                  <Box>
                    [
                    <PercentModule value={e?.view_count} valueMax={e?.view_count_max} />]{' '}
                    {currencyComma(e?.view_count)} / {currencyComma(e?.view_count_max)}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box column gap={18}>
          <Box gap={12}>
            <Box>
              가상 유입 진행 상황 <TextSmall> Fake Do Progress</TextSmall>
            </Box>
            <Box>
              [
              <PercentModule
                value={viewInfos?.etc_do2_infos?.to_etc_do2_count}
                valueMax={viewInfos?.etc_do2_infos?.to_etc_do2_count_max}
              />
              ] {currencyComma(viewInfos?.etc_do2_infos?.to_etc_do2_count)} /{' '}
              {currencyComma(viewInfos?.etc_do2_infos?.to_etc_do2_count_max)}
              <TextSmall>{count}</TextSmall>
            </Box>
            <Box>
              {viewInfos?.etc_do2_infos?.to_etc_do2_count_max === 0 ? (
                <></>
              ) : (
                <progress
                  value={viewInfos?.etc_do2_infos?.to_etc_do2_count}
                  max={viewInfos?.etc_do2_infos?.to_etc_do2_count_max}
                />
              )}
            </Box>
          </Box>
          <Box column gap={16}>
            {viewInfos?.etc_do2_info_list?.map((e, index) => {
              return (
                <Box key={index}>
                  <Box>
                    <MallNameModule {...e?.shop_info} />
                  </Box>
                  <Box>
                    [
                    <PercentModule value={e?.to_etc_do2_count} valueMax={e?.to_etc_do2_count_max} />
                    ] {currencyComma(e?.to_etc_do2_count)} /{' '}
                    {currencyComma(e?.to_etc_do2_count_max)}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default WorkView;
