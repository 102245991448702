import { Box } from 'Common/UI/atoms';
import Title from 'Common/UI/atoms/Title';
import { setDate } from 'Common/Utils/date';
import React from 'react';
import styled from 'styled-components';

const MainWeekList = ({ getDays, position = 'relative', activeDate, today, onClick, style }) => {
  const dayName = ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat'];
  return (
    <Wrapper styles={style} position={position}>
      {Array(7)
        .fill(0)
        .map((_, n) => {
          const nowWeek = getDays.clone().week();
          let days = getDays.clone().startOf('year').week(nowWeek).startOf('week').add(n, 'day');
          const value = setDate(days);
          const isActive = value === activeDate;
          const isToday = value === today;
          return (
            <DateBox isActive={isActive} isToday={isToday} key={n} onClick={() => onClick(value)}>
              <Title
                color={isActive ? 'G_600' : !n ? 'Error_300' : n === 6 ? 'P_300' : 'G_150'}
                size={'xxxxs'}
              >
                {dayName[n]}
              </Title>
              <Box width={34} height={44} align="center" valign="middle" className="day">
                {days?.format('DD')}
              </Box>
            </DateBox>
          );
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ position, styles }) => `
  display: flex;
  width:100%;
  height:100%;
  gap:10rem;
  justify-content:center;
  position:${position};
  ${styles || ''}
`}
`;

const DateBox = styled.button`
  ${({ isActive, isToday }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:8rem;
//   width: 44rem;
  .day {
      position: relative;
      z-index:1;
      font-size:14rem;
      color:var(--${isActive ? 'White' : isToday ? 'S_500' : 'G_600'});
      ${
        isActive
          ? `
      &::before{
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        border-radius:100%;
        z-index:-1;
        background-color:var(--P_500);
      }
      `
          : ''
      }
  }

`}
`;

export default MainWeekList;
