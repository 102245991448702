import styled, { css } from 'styled-components';

export const TextWrapper = styled.div`
  ${({ status, theme }) => {
    const color = status ? theme.status[status].color : 'var(--G_100)';
    return css`
      color: ${color};
    `;
  }}
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12rem;
  line-height: 170%;
  i {
    display: flex;
    align-items: center;
    margin-right: 10rem;
  }
`;

export const DotText = styled.span`
  position: relative;
  padding-left: 16rem;
  font-weight: 400;
  font-size: 14rem;
  line-height: 172%;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--G_300);
  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 4rem;
    height: 4rem;
    background: currentColor;
    transform: translateY(-50%);
    border-radius: 50rem;
  }
`;

export const UnderLineText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1rem solid;
  border-color: var(--${({ borderColor }) => (borderColor ? borderColor : 'G_700')});
`;

export const PotinText = styled.span`
  ${({ size, weight, isColor = 'P_500' }) => `
    display: contents;
    color: var(--${isColor});
    font-size: ${size ? size + 'rem' : 'inherit'};
    font-weight: ${weight || 'inherit'};
    `}
`;
export const HashText = styled.div`
  position: relative;
  padding-left: 8rem;
  margin-left: 8rem;
  font-weight: 400;
  font-size: 14rem;
  line-height: 172%;
  letter-spacing: -0.03em;
  color: var(--G_300);
  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 8rem;
    content: '';
    background-color: var(--G_100);
  }
`;
