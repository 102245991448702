import Icon from 'Common/UI/atoms/IconBox';
import Modal from 'Common/UI/organisms/Modal/Modal';
import React from 'react';

const ModalQuestion = ({ successText, title, text, isActive, onSuccess, onClose }) => {
  return (
    <Modal
      title={title}
      titleIcon={<Icon.Info size={24} />}
      size="sm"
      isActive={isActive}
      onSuccess={() => {
        onClose();
        onSuccess();
      }}
      onClose={onClose}
      successText={successText}
    >
      {text}
    </Modal>
  );
};

export default ModalQuestion;
