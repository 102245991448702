import { valueSet } from 'Common/Utils/util';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import DropList from './DropList';
import Icon from './IconBox';
import { InputWrapper, LabelText } from './Input/styled';

function DropBox({
  placeholder,
  fill,
  className,
  option,
  onClick,
  name,
  label,
  onChange,
  disabled,
  fontSize = 16,
  size,
  isEdit = true,
  title,
  isColor,
  line,
  //추가
  // direction,
  bottomChk = true,
  isAdd,
  onAddText,
  onChangeText,
  isOnAdd = false,
  isClose = true,
  //추가 20221018
  editOption,
  onEdit,
  onDeleteEdit,
  align,
}) {
  const [isActive, setIsActive] = useState();
  const [selected, setSelected] = useState();
  const [direction, setDirection] = useState('bottom');
  const [list, setList] = useState([]);
  const ref = useRef();
  const onSelect = (item) => {
    setSelected(item);
    setList(
      list.map((i) =>
        i.title === item.title ? { ...i, selected: true } : { ...i, selected: false },
      ),
    );
    isClose && setIsActive(false);
    if (onClick) onClick(item);
    if (onChange) onChange(name, item.value);
  };

  const onFocus = () => {
    setIsActive(true);
  };

  useEffect(() => {
    setList(option || []);
    setSelected(option && option?.filter((i) => i.selected)[0]);
  }, [option]);

  useEffect(() => {
    isClose && setIsActive(false);
  }, [isClose]);

  const position = () => {
    const layout = document.getElementById('root').getBoundingClientRect();
    const layoutY = layout.y;
    const layoutHeight = layout.height;
    const target = ref.current.getBoundingClientRect();
    const targetY = target.y + target.height + 50;

    const childHeight = Math.min(400, (list?.length || 0) * 48);
    const y = layoutHeight + layoutY;
    if (bottomChk) {
      setDirection('bottom');
    } else {
      const filter = y > targetY + childHeight ? 'bottom' : 'top';
      filter !== direction && setDirection(filter);
    }
  };

  useEffect(position, [list]);

  return (
    <InputWrapper className={'dropBox'} fil={fill} isEdit={isEdit} align={align}>
      {label && <LabelText>{label}</LabelText>}
      <Wrapper
        fontSize={valueSet(fontSize)}
        isColor={isColor}
        isTitle={title}
        className={className}
        fil={fill}
        ref={ref}
        disabled={disabled}
        isEdit={isEdit}
        line={line && (title || isEdit)}
        onClick={(e) => {
          !disabled && isEdit && onFocus(e);
          !disabled && isEdit && e.stopPropagation();
        }}
        size={size}
        value={selected?.value}
      >
        {title && <span className="title">{title}</span>}
        {selected ? (
          <p className="dropBoxTitle">{selected.title}</p>
        ) : (
          <span className="placeholder">{placeholder ? placeholder : '선택'}</span>
        )}
        {isEdit && (
          <span className="arrow">
            <Icon.Arrow size="12" />
          </span>
        )}
        {list?.length > 0 || isAdd ? (
          <DropList
            isAdd={isAdd}
            isOnAdd={isOnAdd}
            onAddText={onAddText}
            editOption={editOption}
            onChangeText={onChangeText}
            position={direction}
            onEdit={onEdit}
            onDeleteEdit={onDeleteEdit}
            isClose={isClose}
            parent={ref.current}
            onClick={onSelect}
            isActive={isActive}
            onClose={() => {
              isClose && setIsActive(false);
            }}
            option={list}
          />
        ) : (
          ''
        )}
      </Wrapper>
    </InputWrapper>
  );
}

const Wrapper = styled.div`
  ${({ isColor, isTitle, size, line, fontSize, disabled, isEdit, theme: { ellipsis } }) => css`
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: ${size}rem;
    padding: 0 20rem 0 ${isTitle ? 0 : 26}rem;
    border: 1rem solid;
    border-color: ${line ? 'var(--G_100)' : 'transparent'};
    border-radius: 8rem;
    background: var(--White);
    font-weight: 400;
    font-size: ${fontSize};
    line-height: 172%;
    letter-spacing: -0.03em;
    color: var(--G_600);
    .dropBoxTitle {
      font-size: inherit;
      ${ellipsis(1)}
    }
    .title {
      width: 100rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16rem;
      font-weight: 400;
      font-size: 14rem;
      border-radius: 7rem 0 0 7rem;
      line-height: 172%;
      letter-spacing: -0.03em;
      ${isColor ? 'background-color:var(--P_100);' : ''}
      color: var(--G_700);
    }
    .arrow {
      margin-left: auto;
      padding-left: 26rem;
    }
    .placeholder {
      color: var(--G_200);
    }

    ${disabled || !isEdit
      ? isEdit
        ? `
            background-color: var(--G_50);
            border-color: var(--G_100);
            color: var(--G_150);
            cursor:no-drop;
  `
        : 'cursor:auto;'
      : `
            &:not(:disabled):not(:focus):hover {
                background-color: var(--G_50);
                box-shadow: var(--Elevation_001);
            }
            &.active {
                background-color: var(--G_100);
                box-shadow: var(--Elevation_001);
            }
            &:focus {
                background-color: var(--G_75);
            }
  `}
    .dropList {
      min-width: auto;
    }
  `}
`;
export default DropBox;
