import useTab from 'Common/Hooks/useTab';
import { Box, Grid, TextButton } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  requestLoadWorkKeywordGetNeedKeywordInfos,
  requestLoadWorkKeywordResetCanAccessDatetime,
} from 'Common/Service/common';
import WorkKeywordStatus from './WorkKeywordStatus';
import MallNameModule from './Module/MallNameModule';
import MallKeywordGetStatus from './Module/MallKeywordGetStatus';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  get_uuid,
  reload_second,
} from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import { LineIcon } from 'Page/Module/CheckIconBox';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const WorkKeyword = () => {
  const location = useLocation();
  const [count, setCount] = useState(reload_second);

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const [keywordInfos, setKeywordInfos] = useState();
  const [productInfoId, setProductInfoId] = useState(null);
  const [isTonghapLessCountIds, setIsTonghapLessCountIds] = useState();
  const [isShopLessCountIds, setIsShopLessCountIds] = useState();
  const [isTonghapSearchFailCountIds, setIsTonghapSearchFailCountIds] = useState();
  const [isShopSearchFailCountIds, setIsShopSearchFailCountIds] = useState();
  const [showProductInfoIds, setShowProductInfoIds] = useState([]);
  const [uuid, setUuid] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getKeywordInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const getKeywordInfos = async () => {
    const keyword_infos = await requestLoadWorkKeywordGetNeedKeywordInfos({});
    setKeywordInfos(keyword_infos);
    // const menuItems = url_type_infos.map((e) => {
    //   return {
    //     title: `${e.url_type} (${e.count})`,
    //     value: `/work/${url_type}`,
    //   };
    // });
    // setMenuItems(menuItems);
  };
  useEffect(() => {
    getKeywordInfos();
  }, []);
  return (
    <>
      {/* all_count: 0, doing_count: 0, shop_done_count: 0, shop_less_count: 0, shop_search_fail_count:
      0, slot_shop_done_count: 0, slot_shop_less_count: 0, slot_shop_search_fail_count: 0,
      slot_tonghap_done_count: 0, slot_tonghap_less_count: 0, slot_tonghap_search_fail_count: 0,
      tonghap_done_count: 0, tonghap_less_count: 0, tonghap_search_fail_count: 0 */}

      <Box>
        must_everyday_do1 ,2 <TextSmall>{count}</TextSmall>
      </Box>
      <Grid column={['2fr', '1fr']} gap={32} valign="top" height="1000">
        <Box column>
          <Box column gap={36}>
            <Box column>
              <Box>
                <Box>
                  Tonghap 검색 키워드 진행률 <TextSmall>Tonghap Keyword Search Progress</TextSmall>{' '}
                </Box>
                <Box>
                  [
                  <PercentModule
                    value={keywordInfos?.check_keyword_infos_count?.tonghap_process_count}
                    valueMax={keywordInfos?.check_keyword_infos_count?.all_count}
                  />
                  ] {keywordInfos?.check_keyword_infos_count?.tonghap_process_count + ' '}/{' '}
                  {keywordInfos?.check_keyword_infos_count?.all_count} [작업 중{' '}
                  <TextSmall>In Progress</TextSmall>:{' '}
                  {keywordInfos?.check_keyword_infos_count?.tonghap_doing_count}]{' '}
                  <TextButton
                    onClick={async () => {
                      await requestLoadWorkKeywordResetCanAccessDatetime();
                    }}
                  >
                    time reset
                  </TextButton>
                </Box>
              </Box>

              <Box>
                <button
                  onClick={() => {
                    setIsTonghapLessCountIds(!isTonghapLessCountIds);
                    const ids =
                      keywordInfos?.check_keyword_infos_count?.tonghap_less_count_product_info_ids;
                    if (isTonghapLessCountIds) {
                      const l = showProductInfoIds.filter((id) => !ids?.includes(id));
                      const list = [...new Set(l)];
                      setShowProductInfoIds(list);
                    } else {
                      const list = [...new Set([...showProductInfoIds, ...ids])];
                      setShowProductInfoIds(list);
                    }
                  }}
                >
                  <Box>
                    Tonghap 검색 키워드 필요 상품
                    <TextSmall>Tonghap Keyword Required Product Count</TextSmall>
                    {keywordInfos?.check_keyword_infos_count?.tonghap_less_count}
                  </Box>
                  <Box>
                    <TextSmall>
                      {isTonghapLessCountIds ? (
                        <>
                          {keywordInfos?.check_keyword_infos_count?.tonghap_less_count_ids?.join(
                            ', ',
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </TextSmall>
                  </Box>
                  <Box>
                    <TextWarning>
                      <TextSmall>
                        {isTonghapLessCountIds ? (
                          <>
                            {keywordInfos?.check_keyword_infos_count?.tonghap_less_count_product_info_ids?.join(
                              ', ',
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </TextSmall>
                    </TextWarning>
                  </Box>
                </button>
              </Box>
              <Box>
                <Box>
                  Tonghap 검색 키워드 추출 완료 상품
                  <TextSmall>Tonghap Keyword Search Done Product Count</TextSmall>
                </Box>
                <Box>
                  <TextSucceed>
                    {keywordInfos?.check_keyword_infos_count?.all_done_count +
                      keywordInfos?.check_keyword_infos_count?.tonghap_done_count}
                  </TextSucceed>{' '}
                </Box>
              </Box>
              <Box>
                <button
                  onClick={() => {
                    setIsTonghapSearchFailCountIds(!isTonghapSearchFailCountIds);
                    const ids =
                      keywordInfos?.check_keyword_infos_count
                        ?.tonghap_search_fail_count_product_info_ids;
                    if (isTonghapSearchFailCountIds) {
                      const l = showProductInfoIds.filter((id) => !ids?.includes(id));
                      const list = [...new Set(l)];
                      setShowProductInfoIds(list);
                    } else {
                      const list = [...new Set([...showProductInfoIds, ...ids])];
                      setShowProductInfoIds(list);
                    }
                  }}
                >
                  <Box>
                    검색 키워드 추출 실패 상품
                    <TextSmall>Keyword Search Failed Product Count</TextSmall>
                    <TextFail>
                      {keywordInfos?.check_keyword_infos_count?.search_fail_count}{' '}
                      <TextSmall>
                        (Tonghap Keyword Failed:{' '}
                        {keywordInfos?.check_keyword_infos_count?.tonghap_search_fail_count})
                      </TextSmall>
                    </TextFail>
                  </Box>
                  <Box>
                    <TextSmall>
                      {isTonghapSearchFailCountIds ? (
                        <>
                          {keywordInfos?.check_keyword_infos_count?.tonghap_search_fail_count_ids?.join(
                            ', ',
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </TextSmall>
                  </Box>
                  <Box>
                    <TextWarning>
                      <TextSmall>
                        {isTonghapSearchFailCountIds ? (
                          <>
                            {keywordInfos?.check_keyword_infos_count?.tonghap_search_fail_count_product_info_ids?.join(
                              ', ',
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </TextSmall>
                    </TextWarning>
                  </Box>
                </button>
              </Box>
            </Box>
            <Box column>
              <Box>
                <Box>
                  Shop 검색 키워드 진행률 <TextSmall>Shop Keyword Search Progress</TextSmall>{' '}
                </Box>
                <Box>
                  [
                  <PercentModule
                    value={keywordInfos?.check_keyword_infos_count?.shop_process_count}
                    valueMax={keywordInfos?.check_keyword_infos_count?.all_count}
                  />
                  ] {keywordInfos?.check_keyword_infos_count?.shop_process_count} /{' '}
                  {keywordInfos?.check_keyword_infos_count?.all_count} [작업 중{' '}
                  <TextSmall>In Progress</TextSmall>:{' '}
                  {keywordInfos?.check_keyword_infos_count?.shop_doing_count}]{' '}
                  <TextButton
                    onClick={async () => {
                      await requestLoadWorkKeywordResetCanAccessDatetime();
                    }}
                  >
                    time reset
                  </TextButton>
                </Box>
              </Box>

              <Box>
                <button
                  onClick={() => {
                    setIsShopLessCountIds(!isShopLessCountIds);
                    const ids =
                      keywordInfos?.check_keyword_infos_count?.shop_less_count_product_info_ids;
                    if (isShopLessCountIds) {
                      const l = showProductInfoIds.filter((id) => !ids?.includes(id));
                      const list = [...new Set(l)];
                      setShowProductInfoIds(list);
                    } else {
                      const list = [...new Set([...showProductInfoIds, ...ids])];
                      setShowProductInfoIds(list);
                    }
                  }}
                >
                  <Box>
                    Shop 검색 키워드 필요 상품
                    <TextSmall>Shop Keyword Required Product Count</TextSmall>
                    {keywordInfos?.check_keyword_infos_count?.shop_less_count}
                  </Box>
                  <Box>
                    <TextSmall>
                      {isShopLessCountIds ? (
                        <>
                          {keywordInfos?.check_keyword_infos_count?.shop_less_count_ids?.join(', ')}
                        </>
                      ) : (
                        <></>
                      )}
                    </TextSmall>
                  </Box>
                  <Box>
                    <TextWarning>
                      <TextSmall>
                        {isShopLessCountIds ? (
                          <>
                            {keywordInfos?.check_keyword_infos_count?.shop_less_count_product_info_ids?.join(
                              ', ',
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </TextSmall>
                    </TextWarning>
                  </Box>
                </button>
              </Box>
              <Box>
                <Box>
                  Shop 검색 키워드 추출 완료 상품
                  <TextSmall>Shop Keyword Search Done Product Count</TextSmall>
                </Box>
                <Box>
                  <TextSucceed>
                    {keywordInfos?.check_keyword_infos_count?.all_done_count +
                      keywordInfos?.check_keyword_infos_count?.shop_done_count}
                  </TextSucceed>{' '}
                </Box>
              </Box>
              <Box>
                <button
                  onClick={() => {
                    setIsShopSearchFailCountIds(!isShopSearchFailCountIds);
                    const ids =
                      keywordInfos?.check_keyword_infos_count
                        ?.shop_search_fail_count_product_info_ids;
                    if (isShopSearchFailCountIds) {
                      const l = showProductInfoIds.filter((id) => !ids?.includes(id));
                      const list = [...new Set(l)];
                      setShowProductInfoIds(list);
                    } else {
                      const list = [...new Set([...showProductInfoIds, ...ids])];
                      setShowProductInfoIds(list);
                    }
                  }}
                >
                  <Box>
                    검색 키워드 추출 실패 상품
                    <TextSmall>Keyword Search Failed Product Count</TextSmall>
                    <TextFail>
                      {keywordInfos?.check_keyword_infos_count?.search_fail_count}{' '}
                      <TextSmall>
                        (Shop Keyword Failed:{' '}
                        {keywordInfos?.check_keyword_infos_count?.shop_search_fail_count})
                      </TextSmall>
                    </TextFail>
                  </Box>
                  <Box>
                    <TextSmall>
                      {isShopSearchFailCountIds ? (
                        <>
                          {keywordInfos?.check_keyword_infos_count?.shop_search_fail_count_ids?.join(
                            ', ',
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </TextSmall>
                  </Box>
                  <Box>
                    <TextWarning>
                      <TextSmall>
                        {isShopSearchFailCountIds ? (
                          <>
                            {keywordInfos?.check_keyword_infos_count?.shop_search_fail_count_product_info_ids?.join(
                              ', ',
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </TextSmall>
                    </TextWarning>
                  </Box>
                </button>
              </Box>
            </Box>
          </Box>
          <Box></Box>
          <Box column top={24} gap={16}>
            {keywordInfos?.slot_keyword_status_infos
              ?.filter((e) => {
                if (!showProductInfoIds?.length) return true;
                return showProductInfoIds?.includes(e?.product_info_id);
              })
              ?.map((e, index) => {
                return (
                  <Box column key={index}>
                    <KeywordInfoBox
                      onClick={() => {
                        setUuid(get_uuid());
                        setProductInfoId(e?.product_info_id);
                      }}
                    >
                      <Box>
                        <Box>{index + 1}</Box>
                        {e?.product_state?.keyword_state === 'get_keyword_failed' ? (
                          <>
                            <TextSmall>
                              [
                              <TextFail>
                                키워드 추출 실패 <TextSmall> (Get Search Failed Product)</TextSmall>
                              </TextFail>
                              ]
                            </TextSmall>
                          </>
                        ) : e?.product_state?.keyword_state === 'stop_keyword_not_enough' ? (
                          <>
                            <TextSmall>
                              [
                              <TextFail>
                                키워드 부족 중지 <TextSmall>Paused (Low Keywords)</TextSmall>
                              </TextFail>
                              ]
                            </TextSmall>
                          </>
                        ) : e?.product_state?.keyword_state === 'keyword_not_enough' ? (
                          <>
                            <TextSmall>
                              [
                              <TextWarning>
                                키워드 부족 <TextSmall>Not enough keywords</TextSmall>
                              </TextWarning>
                              ]
                            </TextSmall>
                          </>
                        ) : e?.product_state?.keyword_state === 'keyword_enough' ? (
                          <>
                            <TextSmall>
                              [
                              <TextSucceed>
                                키워드 수집 완료 <TextSmall>Get Keyword Done</TextSmall>{' '}
                              </TextSucceed>
                              ]
                            </TextSmall>
                          </>
                        ) : (
                          <></>
                        )}

                        <MallNameModule {...e} onClick={() => {}} />
                      </Box>
                      <Box>
                        <MallKeywordGetStatus
                          {...e}
                          search_keyword_check_fail_limit_count={
                            keywordInfos?.search_keyword_check_fail_limit_count
                          }
                        />
                      </Box>
                    </KeywordInfoBox>
                  </Box>
                );
              })}
            {/*             
            {keywordInfos?.slot_keyword_status_infos?.less_stop_work_keyword_list?.map(
              (e, index) => {
                return (
                  <Box column key={index}>
                    <KeywordInfoBox
                      onClick={() => {
                        setUuid(get_uuid());
                        setProductInfoId(e?.product_info_id);
                      }}
                    >
                      <Box>
                        <Box>{index + 1}</Box>[
                        <TextFail>
                          키워드 부족 중지 <TextSmall>Paused (Low Keywords)</TextSmall>
                        </TextFail>
                        ]
                        <MallNameModule {...e} onClick={() => {}} />
                      </Box>
                      <Box>
                        <MallKeywordGetStatus
                          {...e}
                          search_keyword_check_fail_limit_count={
                            keywordInfos?.search_keyword_check_fail_limit_count
                          }
                        />
                      </Box>
                    </KeywordInfoBox>
                  </Box>
                );
              },
            )}
            {keywordInfos?.slot_keyword_status_infos?.less_keyword_list?.map((e, index) => {
              return (
                <Box column key={index}>
                  <KeywordInfoBox
                    onClick={() => {
                      setUuid(get_uuid());
                      setProductInfoId(e?.product_info_id);
                    }}
                  >
                    <Box>
                      [
                      <TextWarning>
                        키워드 부족 <TextSmall>Not enough keywords</TextSmall>
                      </TextWarning>
                      ]
                      <MallNameModule {...e} onClick={() => {}} />
                    </Box>
                    <Box>
                      <MallKeywordGetStatus
                        {...e}
                        search_keyword_check_fail_limit_count={
                          keywordInfos?.search_keyword_check_fail_limit_count
                        }
                      />
                    </Box>
                  </KeywordInfoBox>
                </Box>
              );
            })}

            {keywordInfos?.slot_keyword_status_infos?.done_work_keyword_list?.map((e, index) => {
              return (
                <Box column key={index}>
                  <KeywordInfoBox
                    onClick={() => {
                      setUuid(get_uuid());
                      setProductInfoId(e?.product_info_id);
                    }}
                  >
                    <Box>
                      <Box>{index + 1}</Box>[
                      <TextSucceed>
                        키워드 수집 완료 <TextSmall>Get Keyword Done</TextSmall>{' '}
                      </TextSucceed>
                      ]
                      <MallNameModule {...e} onClick={() => {}} />
                    </Box>
                    <Box>
                      <MallKeywordGetStatus
                        {...e}
                        search_keyword_check_fail_limit_count={
                          keywordInfos?.search_keyword_check_fail_limit_count
                        }
                      />
                    </Box>
                  </KeywordInfoBox>
                </Box>
              );
            })} */}

            {/* all_search_fail_count==0 */}

            {/*  all_search_fail_count > 0 */}

            {/*  all_search_fail_count > 0 */}

            {/* [] 쇼핑검색 */}

            {/* 성공율 미검색성공율 all_search_fail_count > 0 [부족] 상품별: 25 % 통합키워드 25%, 쇼핑키워드
        25% field :check_tonghap_search_ok_count, :integer, default: 0 field
        :all_tonghap_search_ok_count, :integer, default: 0 field :check_shop_search_ok_count,
        :integer, default: 0 field :all_shop_search_ok_count, :integer, default: 0 field
        :check_search_ok_count, :integer, default: 0 field :all_search_ok_count, :integer, default:
        0 field :check_tonghap_search_fail_count, :integer, default: 0 field
        :all_tonghap_search_fail_count, :integer, default: 0 field :check_shop_search_fail_count,
        :integer, default: 0 field :all_shop_search_fail_count, :integer, default: 0 field
        :check_search_fail_count, :integer, default: 0 field :all_search_fail_count, :integer,
        default: 0 field :check_tonghap_search_access_count, :integer, default: 0 field
        :all_tonghap_search_access_count, :integer, default: 0 field
        :check_shop_search_access_count, :integer, default: 0 field :all_shop_search_access_count,
        :integer, default: 0 field :check_search_access_count, :integer, default: 0 field
        :all_search_access_count, :integer, default: 0 field :tonghap_search_ok_date,
        Timex.Ecto.Date field :tonghap_search_ok_datetime, Timex.Ecto.DateTime field
        :shop_search_ok_date, Timex.Ecto.Date field :shop_search_ok_datetime, Timex.Ecto.DateTime
        field :search_ok_date, Timex.Ecto.Date field :search_ok_datetime, Timex.Ecto.DateTime field
        :tonghap_search_fail_date, Timex.Ecto.Date field :tonghap_search_fail_datetime,
        Timex.Ecto.DateTime field :shop_search_fail_date, Timex.Ecto.Date field
        :shop_search_fail_datetime, Timex.Ecto.DateTime field :search_fail_date, Timex.Ecto.Date
        field :search_fail_datetime, Timex.Ecto.DateTime */}
          </Box>
        </Box>
        <Box column valign="top">
          <WorkKeywordStatus uuid={uuid} product_info_id={productInfoId} />
        </Box>
      </Grid>
    </>
  );
};
const KeywordInfoBox = styled.button`
  &:hover {
    color: var(--P_700);
    &::after {
      background-color: var(--P_700);
    }
  }
`;
export default WorkKeyword;
