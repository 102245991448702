import { requestLoadPaymentGetSlotIds, requestLoadSlotGetRankInfos } from 'Common/Service/slot';
import { Box, CheckBox, Grid, Icon, TextButton } from 'Common/UI/atoms';
import { currencyComma, getWeekColorStr } from 'Common/Utils';
import MallNameModule from 'Page/Work/Module/MallNameModule';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
const RankLogModule = ({
  rankInfos,
  setRankInfos,
  isShow = false,
  onClick = null,
  date = null,
  ago_day = 7,
  type = 'category',
}) => {
  // const [isSlotShow, setIsSlowShot] = useState(isShow);
  const [isShowRankKeyword, setIsShowRankKeyword] = useState(false);
  const loadCategoryRankInfos = async ({ date, ago_day, type }) => {
    var rank_infos;
    rank_infos = await requestLoadSlotGetRankInfos({ date, ago_day, type });
    setRankInfos(rank_infos);
  };

  useEffect(() => {
    loadCategoryRankInfos({ date, ago_day, type });
  }, [date, ago_day, type]);
  return (
    <>
      <Wrapper column>
        {/* <Box>
              <CategoryText>
                <h1>{category_name}</h1> - 상품: {product_count}개
              </CategoryText>
            </Box> */}
        <Box width="100%" column>
          <Box gap={12} align={isShow ? 'left' : 'right'}>
            <TextButton
              size={10}
              onClick={() => {
                if (onClick) onClick();
              }}
            >
              <TextButtonText isShow={isShow}>
                {isShow ? '간략히 보기' : '전체 보기'}{' '}
                <Icon.DoubleArrow
                  direction={isShow ? 'top' : 'bottom'}
                  fill={'#A0A0A0'}
                  size={10}
                />{' '}
              </TextButtonText>
            </TextButton>
            {isShow ? (
              <Box>
                <CheckBox
                  checked={isShowRankKeyword}
                  onChange={() => {
                    setIsShowRankKeyword(!isShowRankKeyword);
                  }}
                >
                  순위 검색 키워드 표시
                </CheckBox>
              </Box>
            ) : null}
          </Box>
          <Block isShow={isShow}>
            <Cell
              isShow={isShow}
              onClick={() => {
                if (onClick) onClick();
              }}
            ></Cell>
            {rankInfos?.dates?.map((d, index2) => {
              return (
                <DayTitleCell
                  isShow={isShow}
                  onClick={() => {
                    if (onClick) onClick();
                  }}
                  index={index2}
                >
                  {d?.slice(5, 10)} (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getWeekColorStr(moment(d)?.format('ddd')),
                    }}
                  />
                  )
                </DayTitleCell>
              );
            })}
            {rankInfos?.list?.map((e, index) => {
              const category_name = e.category1 ? e.category1 : '미분류';
              const product_count = e.count;
              const slot_count = e.slot_count;
              const count = e.count;
              const rank = e.rank;
              const rank_change_rate = e?.rank_change_rate;
              const rank_change_rate_count = e.rank_change_rate_count;
              const category_date_info_list = e?.category_date_info_list;
              const product_per_date_info_list = e?.product_per_date_info_list;
              return (
                <>
                  <Cell
                    isShow={isShow}
                    onClick={() => {
                      if (onClick) onClick();
                    }}
                  >
                    {category_name} - {currencyComma(product_count)}개 <br />
                    (슬롯 {currencyComma(slot_count)} 개)
                  </Cell>
                  {rankInfos?.dates?.map((d, index2) => {
                    const info = category_date_info_list?.find((i) => i.date == d);
                    const info_count = info?.count;
                    const info_rank = info?.rank;
                    const info_rank_change_rate = info?.rank_change_rate;
                    const info_rank_change_rate_count = info?.rank_change_rate_count;
                    return (
                      <DayCell
                        isShow={isShow}
                        onClick={() => {
                          if (onClick) onClick();
                        }}
                        index={index2}
                      >
                        {/* {rank} {rank_change_rate_count} */}
                        {info_rank ? (
                          <>
                            {info_rank} (
                            {info_rank_change_rate_count > 0 ? (
                              <>
                                {info_rank_change_rate > 0 ? (
                                  <>
                                    <Icon.DoubleArrow direction="top" fill={'#0000f0'} size={10} />{' '}
                                    {info_rank_change_rate}
                                  </>
                                ) : (
                                  <>
                                    <Icon.DoubleArrow
                                      direction="bottom"
                                      fill={'#f00000'}
                                      size={10}
                                    />{' '}
                                    {info_rank_change_rate}
                                  </>
                                )}
                              </>
                            ) : (
                              '-'
                            )}
                            )
                          </>
                        ) : null}
                      </DayCell>
                    );
                  })}
                  {isShow
                    ? product_per_date_info_list?.map((info, index3) => {
                        // const nvmid = info.nvmid;
                        // const group_search_nvmid = info.group_search_nvmid;
                        // const title = info.title;
                        const slot_count = info.slot_count;
                        const search_keyword = info.search_keyword;
                        // const mall_name = info.mall_name;
                        // const smartstore_chk = info.smartstore_chk;
                        // const brandstore_chk = info.brandstore_chk;
                        // const navershopping_chk = info.navershopping_chk;
                        const date_list = info.date_list;

                        return (
                          <>
                            <Cell
                              isShow={isShow}
                              onClick={() => {
                                if (onClick) onClick();
                              }}
                            >
                              <Box column width="100%">
                                <Box width="100%">
                                  <Box width={55}>
                                    <SlotText>
                                      슬롯(<b>{currencyComma(slot_count)}</b>)
                                    </SlotText>
                                  </Box>
                                  <Box>
                                    <MallNameModule
                                      onClick={() => {
                                        if (onClick) onClick();
                                      }}
                                      {...info}
                                    />
                                  </Box>
                                </Box>
                                {isShowRankKeyword ? (
                                  <Box>
                                    <RankSearchText>
                                      키워드:{'  '}
                                      <h1>{search_keyword}</h1>
                                    </RankSearchText>
                                  </Box>
                                ) : null}
                              </Box>
                            </Cell>
                            {rankInfos?.dates?.map((d, index2) => {
                              const count_info = date_list?.find((i) => i.date == d)?.list?.[0];
                              const info_count = count_info?.count;
                              const info_rank = count_info?.rank;
                              const info_rank_change_rate = count_info?.rank_change_rate;
                              const info_last_rank = count_info?.last_rank;
                              const info_rank_change_rate_count =
                                count_info?.rank_change_rate_count;
                              return (
                                <DayCell
                                  isShow={isShow}
                                  onClick={() => {
                                    if (onClick) onClick();
                                  }}
                                  index={index2}
                                >
                                  {/* {rank} {rank_change_rate_count} */}
                                  {info_rank ? (
                                    <>
                                      {info_rank} (
                                      {info_last_rank > 0 ? (
                                        <>
                                          {info_rank_change_rate === 0 ? (
                                            '-'
                                          ) : info_rank_change_rate > 0 ? (
                                            <>
                                              <Icon.DoubleArrow
                                                direction="top"
                                                fill={'#0000f0'}
                                                size={10}
                                              />{' '}
                                              {info_rank_change_rate}
                                            </>
                                          ) : (
                                            <>
                                              <Icon.DoubleArrow
                                                direction="bottom"
                                                fill={'#f00000'}
                                                size={10}
                                              />{' '}
                                              {-info_rank_change_rate}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        '-'
                                      )}
                                      )
                                    </>
                                  ) : null}
                                </DayCell>
                              );
                            })}
                          </>
                        );
                      })
                    : null}
                </>
              );
            })}
          </Block>
        </Box>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  position: relative;
  /* z-index: 10; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10rem;
  background: var(--White);
  box-shadow: var(--Elevation_001);
  border-radius: 12rem;

  overflow: hidden;
`;
const Cell = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 10rem;
  padding: 5rem;
  border-width: 1rem 1rem 0rem 1rem;
  border-style: solid;
  border-color: #f0f0f0;
  &:hover {
    background-color: #f0f0f0;
  }
  ${(props) => {
    const { isShow } = props;
    if (isShow) {
      return css`
        font-weight: 700;
        font-size: 14rem;
      `;
    }
  }}/* border-color: gray; */
`;
const DayCell = styled.button`
  display: flex;
  font-size: 9rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-width: 1rem 1rem 0rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  &:hover {
    background-color: #f0f0f0;
  }
  ${(props) => {
    const { isShow } = props;
    if (isShow) {
      return css`
        font-size: 13rem;
      `;
    }
  }}
`;
const DayTitleCell = styled.button`
  display: flex;
  padding: 5rem;
  &:hover {
    background-color: #f0f0f0;
  }
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  border-width: 1rem 1rem 0rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  /* border-color: gray; */
  ${(props) => {
    const { isShow } = props;
    if (isShow) {
      return css`
        font-size: 14rem;
      `;
    }
  }}
`;
const Block = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  border-width: 0rem 0rem 1rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  grid-template-columns: 100rem repeat(7, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  ${(props) => {
    const { isShow } = props;
    if (isShow) {
      return css`
        grid-template-columns: 3fr repeat(7, 1fr);
      `;
    }
  }}
`;
const TextButtonText = styled.div`
  font-size: 12rem;
  ${(props) => {
    const { isShow } = props;
    if (isShow) {
      return css`
        font-size: 15rem;
      `;
    }
  }}
`;

const SlotText = styled.div`
  font-size: 10rem;
  font-weight: 600rem;
  b {
    color: #5a0aa0;
  }
`;
const RankSearchText = styled.div`
  font-size: 14rem;
  font-weight: 400;
  h1 {
    color: #a0a0f0;
    display: inline;
  }
`;
export default RankLogModule;
