import { Box, InputComponent } from 'Common/UI/atoms';
import LabelBox from 'Common/UI/molecules/LabelBox';
import React from 'react';
import FormAddress from './FormAddress';
import FormBirth from './FormBirth';
import FormCustom from './FormCustom';
import FormDatePicker from './FormDatePicker';
import FormBadge from './FormBadge';
import FormCheckBox from './FormCheckBox';
import FormDropInput from './FormDropInput';
import FormDropList from './FormDropList';
import { Wrapper } from './styled';
import FormDropBadgeInput from './FormDropBadgeInput';

function FormInput({
  label,
  isEdit = true,
  size = 44,
  value,
  btn,
  row = false,
  align = false,
  fontSize,
  gap,
  back,
  styles,
  ...props
}) {
  return (
    <Wrapper className="formInput" isPreWrap={props?.isPreWrap} align={align} styles={styles}>
      <LabelBox gap={gap} fontSize={fontSize} back={back} label={label} row={row}>
        {isEdit ? (
          <Box gap={8} style={row && `flex: 1;`}>
            <InputComponent fontSize={fontSize} size={size} value={value} {...props} />
            {btn}
          </Box>
        ) : (
          <p className="value">{value || '-'}</p>
        )}
      </LabelBox>
    </Wrapper>
  );
}
FormInput.Address = FormAddress;
FormInput.Custom = FormCustom;
FormInput.Badge = FormBadge;
FormInput.DropBox = FormDropList;
FormInput.DropInput = FormDropInput;
FormInput.DropBadge = FormDropBadgeInput;
FormInput.CheckBox = FormCheckBox;
FormInput.Datepicker = FormDatePicker;
FormInput.Birth = FormBirth;
export default FormInput;
