import useTab from 'Common/Hooks/useTab';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { get_percent } from 'Common/Utils';
import { Box } from 'Common/UI/atoms';
import { TextFail, TextSmall, TextSucceed } from 'Common/Utils/util';
import PercentModule from './PercentModule';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const MallKeywordGetStatus = ({
  search_keyword_check_fail_limit_count,
  shop_search_keywords_count,
  tonghap_search_keywords_count,
  tonghap_search_keywords_count_max,
  shop_search_keywords_count_max,
  check_shop_search_ok_count,
  check_tonghap_search_ok_count,
  check_shop_search_access_count,
  check_tonghap_search_access_count,
  check_fail_tonghap_search_keywords_count,
  check_fail_shop_search_keywords_count,
}) => {
  const location = useLocation();

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const { current, onClickLocation } = useTab(location.pathname, true);

  return (
    <Box column>
      <Box>
        키워드 수집률<TextSmall>Get Keyword Rate</TextSmall> [
        <PercentModule
          value={shop_search_keywords_count + tonghap_search_keywords_count}
          valueMax={tonghap_search_keywords_count_max + shop_search_keywords_count_max}
        />
        ] <TextSmall>Tonghap</TextSmall> {tonghap_search_keywords_count}/
        {tonghap_search_keywords_count_max} [
        <PercentModule
          value={tonghap_search_keywords_count}
          valueMax={tonghap_search_keywords_count_max}
        />
        ], <TextSmall>Shop</TextSmall> {shop_search_keywords_count}/{shop_search_keywords_count_max}{' '}
        [
        <PercentModule
          value={shop_search_keywords_count}
          valueMax={shop_search_keywords_count_max}
        />
        ]
      </Box>
      {tonghap_search_keywords_count >= tonghap_search_keywords_count_max &&
      shop_search_keywords_count >= shop_search_keywords_count_max ? null : (
        <>
          {check_fail_tonghap_search_keywords_count > 0 ||
          check_fail_shop_search_keywords_count > 0 ? (
            <Box>
              {check_fail_tonghap_search_keywords_count > 0 ? (
                <Box>
                  Tonghap 검색 수집 실패<TextSmall>Get Tonghap Keyword Failed</TextSmall>:{' '}
                  {check_fail_tonghap_search_keywords_count >=
                  search_keyword_check_fail_limit_count ? (
                    <TextFail>{check_fail_tonghap_search_keywords_count}</TextFail>
                  ) : (
                    check_fail_tonghap_search_keywords_count
                  )}
                  /{search_keyword_check_fail_limit_count}
                </Box>
              ) : null}
              {check_fail_shop_search_keywords_count > 0 ? (
                <Box>
                  Shop 검색 수집 실패<TextSmall>Get Shop Keyword Failed</TextSmall>:{' '}
                  {check_fail_shop_search_keywords_count >=
                  search_keyword_check_fail_limit_count ? (
                    <TextFail>{check_fail_shop_search_keywords_count}</TextFail>
                  ) : (
                    check_fail_shop_search_keywords_count
                  )}
                  /{search_keyword_check_fail_limit_count}
                </Box>
              ) : null}
            </Box>
          ) : null}
        </>
      )}

      {check_shop_search_access_count + check_tonghap_search_access_count > 0 ? (
        <Box>
          검색 성공률<TextSmall>Search Success Rate</TextSmall>{' '}
          <PercentModule
            value={check_shop_search_ok_count + check_tonghap_search_ok_count}
            valueMax={check_shop_search_access_count + check_tonghap_search_access_count}
          />
          <TextSmall>Tonghap Rate</TextSmall>{' '}
          <PercentModule
            value={check_tonghap_search_ok_count}
            valueMax={check_tonghap_search_access_count}
          />
          <TextSmall>Shop Rate</TextSmall>{' '}
          <PercentModule
            value={check_shop_search_ok_count}
            valueMax={check_shop_search_access_count}
          />
        </Box>
      ) : null}
    </Box>
  );
};
const StatusTitleText = styled.span`
  display: flex;
  flex: 1;
  width: 100%;
  /* width: 600rem; */
  /* background-color: red; */

  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 14rem;
  /* display: inline-block; */
  gap: 12rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  h1 {
    font-size: 13rem;
    font-weight: 500;
    color: #4879ba;
  }
  small {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #f00000;
  }
  h2 {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #00a000;
  }
  h2 {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #265926;
  }
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;

  span {
    font-size: 14rem;
    line-height: 18rem;
    /* color: #ba1717; */
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return css`
      color: #fff;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300);
      background-color: #bc340b;
    `;
  }}
`;
export default MallKeywordGetStatus;
