import React, { useEffect, useState } from 'react';
import { TextFail, TextSucceed, TextWarning } from 'Common/Utils/util';
import { get_percent } from 'Common/Utils';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const PercentModule = ({
  value = null,
  valueMax = null,
  succeed_limit = 100,
  warnning_limit = 50,
  n = 2,
}) => {
  const [per, setPer] = useState();
  useEffect(() => {
    setPer(get_percent(value, valueMax, n));
  }, [value, valueMax]);
  if (!valueMax) return;
  return (
    <>
      {per >= succeed_limit ? (
        <>
          <TextSucceed>{per}</TextSucceed>
        </>
      ) : per >= warnning_limit ? (
        <>
          <TextWarning>{per}</TextWarning>
        </>
      ) : (
        <>
          <TextFail>{per}</TextFail>
        </>
      )}
      %
    </>
  );
};
export default PercentModule;
