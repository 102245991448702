import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetNewPatternAllProductKeywordStatus,
  requestLoadWorkNewGetNewPatternProductKeywordStatus,
} from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
import WorkNewPatternActiveWorkerState from './WorkNewPatternActiveWorkerState';
import { keyword_strip } from 'Common/Utils';
import WorkNewPatternProductInfoKeywordStatusModule from './WorkNewPatternProductInfoKeywordStatusModule';
// import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternAllProductInfoKeywordStatus = ({ uuid, shop_link_id, id = 6808 }) => {
  // if (loginInfo?.user_level !== 5) return;
  const [allProductInfoKeywordStatus, setAllProductInfoKeywordStatus] = useState();
  const [count, setCount] = useState(20);
  // const [apiId, setApiId] = useState();
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCount((prevCount) => {
  //       var ct = prevCount - 1;
  //       if (ct < 0) {
  //         getWorkInfos();
  //         ct = reload_second;
  //       }
  //       return ct;
  //     });
  //   }, 1000);
  //   setApiId(intervalId);
  //   return () => clearInterval(intervalId);
  // }, []);
  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetNewPatternAllProductKeywordStatus({});
    setAllProductInfoKeywordStatus(result);
  };
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Block>
      {allProductInfoKeywordStatus?.map((data, index) => {
        return (
          <StatusBox>
            <WorkNewPatternProductInfoKeywordStatusModule data={data} />{' '}
          </StatusBox>
        );
      })}
    </Block>
  );
};
const Block = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13rem;
  font-weight: 500;
  gap: 10rem;
`;
const ActiveLogStatusBox = styled.div`
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  /* border-style: solid; */
  border-width: 1rem;
  border-color: blue;
`;
const StatusBox = styled.div`
  display: flex;
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
export default WorkNewPatternAllProductInfoKeywordStatus;
