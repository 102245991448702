import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import commonSlice from 'features/common/commonSlice';
// import { companyEvaluationSlice, orderSlice } from 'features';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'meetingModal',
    'meetingAModal',
    'meetingBModal',
    'dataCenter',
    'preserve',
    'esop',
    'access',
    'budget',
    // "order",
    'companyEvaluation',
    'workPermit',
    'workStatus',
    'newWorkSlice',
    'account',
  ],
};

const reducers = combineReducers({
  common: commonSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});
