import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetGroupActivePatterns,
  requestLoadWorkNewGetGroupActivePatternsSubInfos,
  requestLoadWorkNewSetGroupActivePatterns,
} from 'Common/Service/common';
import { TextSucceed, useForceUpdate } from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { sortSet } from 'Common/Utils';
import { onShowToast } from 'features/common/commonSlice';
import styled from 'styled-components';
import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternAddGroupPattern = () => {
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  const [groupPatterns, setGroupPatterns] = useState();
  const [groupPatternInfoSubInfos, setGroupPatternInfoSubInfos] = useState();
  const [groupPatternInfo, setGroupPatternInfo] = useState({
    age_chk: true,
    age_list: [20, 30],
    group_name: '',
    do_type: '',
    gender_chk: true,
    gender_list: ['woman'],
    network_library_type: 'ps',
    pattern_use_percent: 100,
    worker_count_max: 1000,
    asn_mode_chk: true,
    active_click_rotation_count_start: 3,
    active_click_rotation_count_end: 5,

    active_pattern_do_count_start: 1,
    active_pattern_do_count_end: 1,
    click_pattern_do_count_start: 2,
    click_pattern_do_count_end: 3,
    after_active_pattern_done_delay_minite_start: 25 * 60,
    after_active_pattern_done_delay_minite_end: 30 * 60,
    after_click_pattern_done_delay_minite_start: 25 * 60,
    after_click_pattern_done_delay_minite_end: 30 * 60,
    active_workstation_chk: false,
    active_workstation_names: '',
    active_vmware_chk: false,
    active_vmware_names: '',
    active_browser_chk: true,
    active_browser_types: ['iphone_safari'].join(','),
    active_proxy_country_chk: true,
    active_proxy_countrys: ['TH'].join(','),
    force_static_shop_link_ids_chk: false,
    force_static_shop_link_ids: [],

    active_pattern_random_percent: 0,

    after_done_wait_delay_minite_start: 2880,
    after_done_wait_delay_minite_end: 5000,

    no_proxy_wait_state: 'ip_change',
    old_worker_use_chk: true,

    hour1_chk: true,
    hour2_chk: true,
    hour3_chk: true,
    hour4_chk: true,
    hour5_chk: true,
    hour6_chk: true,
    hour7_chk: true,
    hour8_chk: true,
    hour9_chk: true,
    hour10_chk: true,
    hour11_chk: true,
    hour12_chk: true,
    hour13_chk: true,
    hour14_chk: true,
    hour15_chk: true,
    hour16_chk: true,
    hour17_chk: true,
    hour18_chk: true,
    hour19_chk: true,
    hour20_chk: true,
    hour21_chk: true,
    hour22_chk: true,
    hour23_chk: true,
  });
  const [groupPatternStateInfos, setGroupPatternStateInfos] = useState({});

  const getWorkSubInfos = async () => {
    const result = await requestLoadWorkNewGetGroupActivePatternsSubInfos();
    setGroupPatternInfoSubInfos(result);
  };
  const getWorkInfos = async (id = null) => {
    console.log(id);
    const result = await requestLoadWorkNewGetGroupActivePatterns({ id });
    console.log(result);
    setGroupPatterns(result?.group_patterns);
    const group_pattern_info = result?.group_pattern_info;
    setGroupPatternInfo(group_pattern_info ? group_pattern_info : groupPatternInfo);
    const group_pattern_state_infos = result?.group_pattern_state_infos;
    setGroupPatternStateInfos(group_pattern_state_infos ? group_pattern_state_infos : {});
    // setWorkstationNames(workstation_infos);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getWorkSubInfos();
    getWorkInfos();
  }, []);
  return (
    <Grid column={['200rem', '800rem', '1fr']} valign="top" gap={8}>
      <Box column>
        {groupPatterns?.map((e) => {
          return (
            <Box>
              <TextButton
                onClick={async () => {
                  getWorkInfos(e?.id);
                }}
              >
                {e?.group_name}
              </TextButton>
            </Box>
          );
        })}
        <Box></Box>
      </Box>
      <Box column>
        <Box gap={8}>
          <Box column width="100%">
            <Box>
              <Button
                fill
                Theme={groupPatternInfo?.id ? 'unFill' : null}
                onClick={async () => {
                  if (!(groupPatternInfo?.group_name?.trim() !== '')) return;
                  if (!(groupPatternInfo?.do_type?.trim() !== '')) return;
                  const result = await requestLoadWorkNewSetGroupActivePatterns(groupPatternInfo);
                  if (!result) return;
                  if (groupPatternInfo?.id) {
                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${groupPatternInfo?.group_name}의 그룹이 수정 되었습니다.`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  } else {
                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${groupPatternInfo?.group_name}의 그룹이 신규 추가 되었습니다.`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }
                  console.log(result);
                  getWorkSubInfos();
                  getWorkInfos(result);
                }}
              >
                {groupPatternInfo?.id ? '수정' : '신규 등록'}
              </Button>
            </Box>
            <Box>
              <Box>그룹이름</Box>
              <Box>
                <InputComponent
                  name={'group_name'}
                  value={groupPatternInfo?.group_name}
                  onChange={(e) => {
                    setGroupPatternInfo({ ...groupPatternInfo, group_name: e.target.value });
                  }}
                />
              </Box>
              <Box>
                <button
                  onClick={() => {
                    setGroupPatternInfo({ ...groupPatternInfo, id: null, group_name: null });
                  }}
                >
                  (고유번호: {groupPatternInfo?.id})
                </button>
              </Box>
            </Box>
            <Box>
              <Box>슬롯 종류</Box>
              <Box>
                <CheckBox
                  type="radio"
                  checked={groupPatternInfo?.do_type === 'etc_do1'}
                  onChange={() => {
                    groupPatternInfo.do_type = 'etc_do1';
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  타입1
                </CheckBox>{' '}
                <CheckBox
                  type="radio"
                  checked={groupPatternInfo?.do_type === 'click'}
                  onChange={() => {
                    groupPatternInfo.do_type = 'click';
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  타입2
                </CheckBox>
              </Box>
            </Box>
            <Box>
              <Box>사용 여부</Box>
              <Box>
                <CheckBox
                  checked={groupPatternInfo?.use1 === undefined ? true : groupPatternInfo?.use1}
                  onChange={() => {
                    groupPatternInfo.use1 = !groupPatternInfo.use1;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
            </Box>
            <Box>
              <Box>성별 설정</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.gender_chk}
                  onChange={() => {
                    groupPatternInfo.gender_chk = !groupPatternInfo.gender_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
              <Box gap={8}>
                {['man', 'woman'].map((gender) => {
                  return (
                    <CheckBox
                      checked={groupPatternInfo?.gender_list?.includes(gender)}
                      onChange={(e) => {
                        var gender_list = groupPatternInfo?.gender_list
                          ? groupPatternInfo?.gender_list
                          : [];

                        if (e.target.checked) {
                          gender_list.push(gender);
                        } else {
                          gender_list = gender_list.filter((t_gender) => t_gender !== gender);
                        }
                        gender_list = gender_list.sort((a, b) => sortSet(a, b));
                        setGroupPatternInfo({ ...groupPatternInfo, gender_list: gender_list });
                      }}
                    >
                      {gender === 'man' ? '남' : gender === 'woman' ? '여' : null}
                    </CheckBox>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Box>나이 설정</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.age_chk}
                  onChange={() => {
                    groupPatternInfo.age_chk = !groupPatternInfo.age_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
              <Box>
                {[20, 30, 40].map((age) => {
                  return (
                    <CheckBox
                      checked={groupPatternInfo?.age_list?.includes(age)}
                      onChange={(e) => {
                        var age_list = groupPatternInfo?.age_list ? groupPatternInfo?.age_list : [];

                        if (e.target.checked) {
                          age_list.push(age);
                        } else {
                          age_list = age_list.filter((t_age) => t_age !== age);
                        }
                        age_list = age_list.sort((a, b) => sortSet(a, b));
                        setGroupPatternInfo({ ...groupPatternInfo, age_list: age_list });
                      }}
                    >
                      {age}대
                    </CheckBox>
                  );
                })}
              </Box>
            </Box>
            <Box gap={8}>
              <Box>허용 시간</Box>

              <Box column>
                <Box gap={8}>
                  {[...Array(12)].map((_hour_chk, index) => {
                    const hour_n = index;
                    const hour_name_chk = `hour${hour_n}_chk`;
                    var use_chk = groupPatternInfo?.[hour_name_chk];
                    use_chk = use_chk === undefined ? true : use_chk;
                    return (
                      <CheckBox
                        key={hour_n}
                        checked={use_chk}
                        onChange={(e) => {
                          console.log(groupPatternInfo);
                          groupPatternInfo[hour_name_chk] = e.target.checked;
                          // setGroupPatternInfo({
                          //   ...groupPatternInfo,
                          //   [hour_name_chk]: e.target.checked,
                          // });
                        }}
                      >
                        {hour_n < 10 ? `0${hour_n}` : hour_n}
                      </CheckBox>
                    );
                  })}
                </Box>
                <Box gap={8}>
                  {[...Array(12)].map((_hour_chk, index) => {
                    const hour_n = index + 12;
                    const hour_name_chk = `hour${hour_n}_chk`;
                    var use_chk = groupPatternInfo?.[hour_name_chk];
                    use_chk = use_chk === undefined ? true : use_chk;
                    return (
                      <CheckBox
                        key={hour_n}
                        checked={use_chk}
                        onChange={(e) => {
                          console.log(groupPatternInfo);
                          groupPatternInfo[hour_name_chk] = e.target.checked;
                          // setGroupPatternInfo({
                          //   ...groupPatternInfo,
                          //   [hour_name_chk]: e.target.checked,
                          // });
                        }}
                      >
                        {hour_n}
                      </CheckBox>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>강제 lte모드</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.force_lte_mode_chk}
                  onChange={() => {
                    groupPatternInfo.force_lte_mode_chk = !groupPatternInfo.force_lte_mode_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
              <Box>구브라우저 사용</Box>
              <Box gap={8}>
                <CheckBox
                  disabled
                  checked={groupPatternInfo?.old_worker_use_chk}
                  onChange={() => {
                    groupPatternInfo.old_worker_use_chk = !groupPatternInfo.old_worker_use_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>

              <Box>lte 사용중 대기처리 상황</Box>
              <Box width={150}>
                <InputComponent
                  name={'no_proxy_wait_state'}
                  value={groupPatternInfo?.no_proxy_wait_state}
                  onChange={(e) => {
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      no_proxy_wait_state: e.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box>네트워크 엑세스 모드 (typhoeus, ps)</Box>
              <Box width={150}>
                <InputComponent
                  name={'network_library_type'}
                  value={
                    groupPatternInfo?.network_library_type
                      ? groupPatternInfo?.network_library_type
                      : 'ps'
                  }
                  onChange={(e) => {
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      network_library_type: e.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box>활동 워커수</Box>
              <Box width={150}>
                <InputComponent
                  name={'worker_count_max'}
                  value={groupPatternInfo?.worker_count_max?.toString()}
                  onChange={(e) => {
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      worker_count_max: e.target.value,
                    });
                  }}
                />
              </Box>

              <Box>asn 모드</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.asn_mode_chk}
                  onChange={() => {
                    groupPatternInfo.asn_mode_chk = !groupPatternInfo.asn_mode_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
            </Box>
            <Box>
              <Box>그룹 사용 비중</Box>
              <Box>
                <InputComponent
                  name={'do_type'}
                  value={groupPatternInfo?.pattern_use_percent?.toString()}
                  onChange={(e) => {
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      pattern_use_percent: e.target.value,
                    });
                  }}
                />
                %
              </Box>
            </Box>

            <Box>
              <Box>허용 브라우저</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.active_browser_chk}
                  onChange={() => {
                    groupPatternInfo.active_browser_chk = !groupPatternInfo.active_browser_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
              <Box width={400}>
                <InputComponent
                  //   autocomplete={'off'}
                  iconSize={11}
                  fontSize={12}
                  padding={[0, 0, 0, 5]}
                  size={25}
                  //   underLine

                  value={groupPatternInfo?.active_browser_types}
                  onChange={(e) => {
                    //   addMdDelay();
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      active_browser_types: e.target.value,
                    });
                    //   addMd();
                    // setProductIds(e.target.value);
                  }}
                  // onKeyUp={onKeyUp}
                  //   required
                />
              </Box>
            </Box>
            {groupPatternInfo?.active_browser_types ? (
              <>
                <Box>
                  {['iphone_safari', 'samsung_browser', 'chrome']?.map((browser_type, index) => {
                    return (
                      <Box key={index}>
                        <TextButton
                          onClick={() => {
                            var active_browser_types = groupPatternInfo?.active_browser_types;
                            if (!active_browser_types) active_browser_types = '';
                            var active_browser_types_list = active_browser_types.split(',');
                            if (!active_browser_types_list.includes(browser_type)) {
                              active_browser_types_list.push(browser_type);
                            }
                            groupPatternInfo.active_browser_types =
                              active_browser_types_list.join(',');
                            forceUpdate();
                          }}
                        >
                          {browser_type}
                        </TextButton>
                      </Box>
                    );
                  })}
                </Box>
              </>
            ) : (
              <></>
            )}
            <Box>
              <Box>허용 country</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.active_proxy_country_chk}
                  onChange={() => {
                    groupPatternInfo.active_proxy_country_chk =
                      !groupPatternInfo.active_proxy_country_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>

              <Box width={400}>
                <InputComponent
                  //   autocomplete={'off'}
                  iconSize={11}
                  fontSize={12}
                  padding={[0, 0, 0, 5]}
                  size={25}
                  //   underLine

                  value={groupPatternInfo?.active_proxy_countrys}
                  onChange={(e) => {
                    //   addMdDelay();
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      active_proxy_countrys: e.target.value,
                    });
                    //   addMd();
                    // setProductIds(e.target.value);
                  }}
                  // onKeyUp={onKeyUp}
                  //   required
                />
              </Box>
            </Box>

            <Box>
              <Box>허용 vmware</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.active_vmware_chk}
                  onChange={() => {
                    groupPatternInfo.active_vmware_chk = !groupPatternInfo.active_vmware_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
              <Box width={400}>
                <InputComponent
                  //   autocomplete={'off'}
                  iconSize={11}
                  fontSize={12}
                  padding={[0, 0, 0, 5]}
                  size={25}
                  //   underLine

                  value={groupPatternInfo?.active_vmware_names}
                  onChange={(e) => {
                    //   addMdDelay();
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      active_vmware_names: e.target.value,
                    });
                    //   addMd();
                    // setProductIds(e.target.value);
                  }}
                  // onKeyUp={onKeyUp}
                  //   required
                />
              </Box>
            </Box>
            <Box>
              <Box>고정할당 shop_link</Box>
              <Box gap={8}>
                <CheckBox
                  checked={groupPatternInfo?.force_static_shop_link_ids_chk}
                  onChange={() => {
                    groupPatternInfo.force_static_shop_link_ids_chk =
                      !groupPatternInfo.force_static_shop_link_ids_chk;
                    setGroupPatternInfo(groupPatternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>

              <Box width={400}>
                <InputComponent
                  //   autocomplete={'off'}
                  iconSize={11}
                  fontSize={12}
                  padding={[0, 0, 0, 5]}
                  size={25}
                  //   underLine

                  value={groupPatternInfo?.force_static_shop_link_ids}
                  onChange={(e) => {
                    //   addMdDelay();
                    setGroupPatternInfo({
                      ...groupPatternInfo,
                      force_static_shop_link_ids: e.target.value,
                    });
                    //   addMd();
                    // setProductIds(e.target.value);
                  }}
                  // onKeyUp={onKeyUp}
                  //   required
                />
              </Box>
            </Box>
            <HighLineBox>
              <Box column>
                <Box>
                  전체 활동 횟수
                  <Box>
                    <InputComponent
                      //   autocomplete={'off'}
                      iconSize={11}
                      fontSize={12}
                      padding={[0, 0, 0, 5]}
                      size={25}
                      //   underLine

                      value={groupPatternInfo?.active_click_rotation_count_start?.toString()}
                      onChange={(e) => {
                        //   addMdDelay();
                        setGroupPatternInfo({
                          ...groupPatternInfo,
                          active_click_rotation_count_start: e.target.value,
                        });
                        //   addMd();
                        // setProductIds(e.target.value);
                      }}
                      // onKeyUp={onKeyUp}
                      //   required
                    />
                  </Box>
                  ~
                  <Box>
                    <InputComponent
                      //   autocomplete={'off'}
                      iconSize={11}
                      fontSize={12}
                      padding={[0, 0, 0, 5]}
                      size={25}
                      //   underLine

                      value={groupPatternInfo?.active_click_rotation_count_end?.toString()}
                      onChange={(e) => {
                        //   addMdDelay();
                        setGroupPatternInfo({
                          ...groupPatternInfo,
                          active_click_rotation_count_end: e.target.value,
                        });
                        //   addMd();
                        // setProductIds(e.target.value);
                      }}
                      // onKeyUp={onKeyUp}
                      //   required
                    />
                  </Box>
                  회
                </Box>
                <Box>
                  전체 활동후 대기시간
                  <Box>
                    <InputComponent
                      //   autocomplete={'off'}
                      iconSize={11}
                      fontSize={12}
                      padding={[0, 0, 0, 5]}
                      size={25}
                      //   underLine

                      value={groupPatternInfo?.after_done_wait_delay_minite_start?.toString()}
                      onChange={(e) => {
                        //   addMdDelay();
                        setGroupPatternInfo({
                          ...groupPatternInfo,
                          after_done_wait_delay_minite_start: e.target.value,
                        });
                        //   addMd();
                        // setProductIds(e.target.value);
                      }}
                      // onKeyUp={onKeyUp}
                      //   required
                    />
                  </Box>
                  ~
                  <Box>
                    <InputComponent
                      //   autocomplete={'off'}
                      iconSize={11}
                      fontSize={12}
                      padding={[0, 0, 0, 5]}
                      size={25}
                      //   underLine

                      value={groupPatternInfo?.after_done_wait_delay_minite_end?.toString()}
                      onChange={(e) => {
                        //   addMdDelay();
                        setGroupPatternInfo({
                          ...groupPatternInfo,
                          after_done_wait_delay_minite_end: e.target.value,
                        });
                        //   addMd();
                        // setProductIds(e.target.value);
                      }}
                      // onKeyUp={onKeyUp}
                      //   required
                    />
                  </Box>
                  분
                </Box>
              </Box>
            </HighLineBox>

            <PatternBox>
              <Box>
                <Box column width={600}>
                  <Box>활동패턴</Box>

                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={150}
                    el="textarea"
                    resize={false}
                    //   underLine

                    placeholder={'패턴이름1:50%:40m-60m,패턴이름2:50%:40m-60m'}
                    value={groupPatternInfo?.active_pattern_info_json_list}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        active_pattern_info_json_list: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                <Box valign="top" width={200} height="100%">
                  <Box column>
                    {groupPatternInfoSubInfos?.['active']?.map((e, index) => {
                      return (
                        <Box key={index}>
                          <TextButton
                            onClick={() => {
                              const pattern_name = e?.pattern_name;
                              const pattern_str = `${pattern_name}:100%:40m-60m`;
                              var active_pattern_info_json_list =
                                groupPatternInfo?.active_pattern_info_json_list?.trim();
                              active_pattern_info_json_list = active_pattern_info_json_list
                                ? `${active_pattern_info_json_list}\n${pattern_str}`
                                : pattern_str;
                              setGroupPatternInfo({
                                ...groupPatternInfo,
                                active_pattern_info_json_list,
                              });
                            }}
                          >
                            {e?.pattern_name}
                          </TextButton>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>활동 패턴 랜덤 배열 확률</Box>
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.active_pattern_random_percent?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        active_pattern_random_percent: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                  %
                </Box>
              </Box>
              <Box>
                <Box>활동횟수:</Box>
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.active_pattern_do_count_start?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        active_pattern_do_count_start: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                ~
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.active_pattern_do_count_end?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        active_pattern_do_count_end: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                회
              </Box>
              <Box>
                <Box>활동 전체 완료후 대기 시간:</Box>
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.after_active_pattern_done_delay_minite_start?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        after_active_pattern_done_delay_minite_start: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                ~
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.after_active_pattern_done_delay_minite_end?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        after_active_pattern_done_delay_minite_end: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                분
              </Box>
              <Box>
                <Box column width={600}>
                  <Box>클릭패턴</Box>

                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={150}
                    el="textarea"
                    resize={false}
                    //   underLine

                    placeholder={'패턴이름1:50%:40m-60m,패턴이름2:50%:40m-60m'}
                    value={groupPatternInfo?.click_pattern_info_json_list}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        click_pattern_info_json_list: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                <Box valign="top" width={200} height="100%">
                  <Box column>
                    {groupPatternInfoSubInfos?.['click']?.map((e, index) => {
                      return (
                        <Box key={index}>
                          <TextButton
                            onClick={() => {
                              const pattern_name = e?.pattern_name;
                              const pattern_str = `${pattern_name}:100%:40m-60m`;
                              var click_pattern_info_json_list =
                                groupPatternInfo?.click_pattern_info_json_list?.trim();
                              click_pattern_info_json_list = click_pattern_info_json_list
                                ? `${click_pattern_info_json_list}\n${pattern_str}`
                                : pattern_str;
                              setGroupPatternInfo({
                                ...groupPatternInfo,
                                click_pattern_info_json_list,
                              });
                            }}
                          >
                            {e?.pattern_name}
                          </TextButton>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>클릭횟수:</Box>
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.click_pattern_do_count_start?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        click_pattern_do_count_start: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                ~
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.click_pattern_do_count_end?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        click_pattern_do_count_end: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                회
              </Box>
              <Box>
                <Box>클릭 전체 완료후 대기 시간:</Box>
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.after_click_pattern_done_delay_minite_start?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        after_click_pattern_done_delay_minite_start: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                ~
                <Box>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={12}
                    padding={[0, 0, 0, 5]}
                    size={25}
                    //   underLine

                    value={groupPatternInfo?.after_click_pattern_done_delay_minite_end?.toString()}
                    onChange={(e) => {
                      //   addMdDelay();
                      setGroupPatternInfo({
                        ...groupPatternInfo,
                        after_click_pattern_done_delay_minite_end: e.target.value,
                      });
                      //   addMd();
                      // setProductIds(e.target.value);
                    }}
                    // onKeyUp={onKeyUp}
                    //   required
                  />
                </Box>
                분
              </Box>
            </PatternBox>
          </Box>
        </Box>
      </Box>
      <Box column>
        {groupPatterns?.map((e) => {
          const group_name = e?.group_name;
          var state_info = groupPatternStateInfos[group_name]?.[0];
          state_info = state_info
            ? state_info
            : {
                count: 0,
                new_pattern_active_count_under_3: 0,
                new_pattern_active_count_over_3: 0,
                new_pattern_click_count_under_3: 0,
                new_pattern_click_count_over_3: 0,
                new_pattern_longterm_countnt: 0,
                active_can_access_count: 0,
                click_can_access_count: 0,
                woman_20_count: 0,
                woman_30_count: 0,
                woman_40_count: 0,
                man_20_count: 0,
                man_30_count: 0,
                man_40_count: 0,
              };

          const new_pattern_active_count =
            state_info.new_pattern_active_count_under_3 +
            state_info.new_pattern_active_count_over_3;

          return <GroupPatternStateModule state_info={state_info} group_name={e?.group_name} />;
        })}
      </Box>
    </Grid>
  );
};
const PatternBox = styled.div`
  border-width: 1rem;
  border-style: solid;
  border-radius: 4rem;
  padding: 10rem;
  border-color: #0000a0;
`;
const PatternStateInfoBox = styled.div`
  font-size: 15rem;
  font-weight: 500;
  h1 {
    font-size: 17rem;
    font-weight: 700;
  }
`;
const HighLineBox = styled.div`
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;

export default WorkNewPatternAddGroupPattern;
