import React from "react";
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

function Overlays({
    className,
    timer,
    blur,
    children,
    isActive,
    isOuterDelete,
    onClose,
    zIndex,
    isBlind = true,
}) {
    const ref = useRef();

    const onClick = (e) => {
        const { target } = e;
        if (target === ref.current && !isOuterDelete) {
            ref?.current?.classList.remove("active");
            onClose();
        }
    };
    const toggleActive = () => {
        setTimeout(() => {
            ref?.current?.classList.add("active");
        }, timer);
    };
    useEffect(() => {
        const root = document.getElementById("root");
        const body = document.getElementsByTagName("body")[0];
        if (isActive) {
            toggleActive();
            const focusEl = document.activeElement;
            root.style.overflow = "hidden";
            body.style.paddingRight = "4rem";
            focusEl.blur();
        } else if (!isActive) {
            root.style.overflow = "";
            body.style.paddingRight = " ";
        }
        return () => {
            root.style.overflow = "";
            body.style.paddingRight = "";
        };
    }, [isActive]);
    return (
        <Wrapper
            className={className}
            onClick={(e) => onClick(e)}
            zIndex={zIndex}
            isActive={isActive}
            ref={ref}
            blur={blur}
            isBlind={isBlind}
        >
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    ${({ blur, isActive, zIndex, isBlind }) => {
        return css`
            display: ${isActive ? "flex" : "none"};
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            ${isBlind
                ? `
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: ${blur ? "blur(4px)" : "none"};
        `
                : ""}
            justify-content: center;
            align-items: center;
            z-index: ${zIndex};
            transition: opacity 0.2s ease-in;
            opacity: 0;
        `;
    }}
    &.active {
        opacity: 1;
    }
`;

Overlays.defaultProps = {
    blur: true,
    isActive: false,
    zIndex: 300,
    timer: 100,
    onClose: () => {},
};
export default Overlays;
