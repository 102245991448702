import React from "react";
import DatepickerReact from "./components/DatepickerReact";
import { Box, InputComponent } from "Common/UI/atoms";
import TimepickerCompoent from "./TimepickerCompoent";
import { BottomSection } from "./components/styled";
import DatepickerHeader from "./components/DatepickerHeader";
import useDatepicker from "./useDatepicker";
import { useEffect } from "react";
import DatepickerHeaderMonth from "./components/DatepickerHeaderMonth";
import moment from "moment";
import { setDate, viewDate } from "Common/Utils/date";

function DatepickerCompoent({
    onChange = () => "",
    name,
    value,
    date = value,
    className,
    size = 44,
    placeholder,
    disabled,
    isTime,
    minDate,
    maxDate,
    timeDisabled,
}) {
    const {
        state,
        ref,
        onChange: onChangeDate,
        setDateFormat,
        onChangeTime,
        onToggleMonth,
        isMonth,
        onCurrentMont,
    } = useDatepicker(date);
    useEffect(() => {
        //에디터 주석 처리
        if (!state) return;
        if (viewDate(state) === viewDate(value)) return;
        onChange(name, isTime ? moment(state).format() : setDate(state));
    }, [state]);

    return (
        <Box gap="20rem">
            <InputComponent
                className={className}
                value={setDateFormat(date)}
                name={name}
                readOnly
                size={size}
                placeholder={placeholder ? placeholder : "기간을 선택하세요"}
                disabled={disabled}
                rightIcon={
                    <DatepickerReact
                        selected={state}
                        onChange={onChangeDate}
                        ref={ref}
                        openToDate={state ? state : null}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={disabled}
                        renderCustomHeader={({ ...props }) => (
                            <DatepickerHeader
                                {...props}
                                onClick={onToggleMonth}
                                onCurrentMont={onCurrentMont}
                            >
                                <DatepickerHeaderMonth
                                    state={state}
                                    isActive={isMonth}
                                    onChange={onChangeDate}
                                    onToggle={onToggleMonth}
                                />
                            </DatepickerHeader>
                        )}
                    >
                        <BottomSection className="bottom">
                            <span>일자를 선택하세요</span>
                        </BottomSection>
                    </DatepickerReact>
                }
            />
            {isTime && (
                <TimepickerCompoent
                    onChange={onChangeTime}
                    disabled={timeDisabled}
                    date={timeDisabled ? undefined : state}
                    isSingle={false}
                    size={size}
                />
            )}
        </Box>
    );
}

export default DatepickerCompoent;
