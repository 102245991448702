import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadSetKeywordCheckVersion,
  requestLoadWorkNewGetActivePatterns,
  requestLoadWorkNewGetManualCountryNormal,
  requestLoadWorkNewGetManualOtherLink,
  requestLoadWorkNewGetShopLinkAddManualKeywords,
  requestLoadWorkNewGetShopLinkSetManualKeywords,
  requestLoadWorkNewGetWorkFiddlerCountInfos,
  requestLoadWorkNewNewPatternKeywordTimeReset,
  requestLoadWorkNewPatternSimpleInfos,
  requestLoadWorkNewSetActivePatterns,
  requestLoadWorkNewSetManualCountryNormal,
  requestLoadWorkNewSetManualOtherLink,
  requestLoadWorkProductInfoResetCanAccessDatetime,
  requestLoadWorkProductInfoResetKeywordFind,
  requestLoadWorkRankResetCanAccessDatetime,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  getKorDate,
  reload_second,
  to_integer,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { sortSet } from 'Common/Utils';
import { onShowToast } from 'features/common/commonSlice';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import MallNameModule from './Module/MallNameModule';
const WorkNewPatternGetFiddlerCountModule = ({
  name = null,
  count = 0,
  get_tonghap_chk_count = null,
  get_tonghap_active_chk_count = null,
  get_shop_chk_count = null,
  get_shop_active_chk_count = null,
}) => {
  const dispatch = useDispatch();
  if (!count) return;
  return (
    <Box gap={20}>
      <Box>
        {count > 0 &&
        ((get_tonghap_chk_count != null &&
          (get_tonghap_chk_count < count || get_tonghap_active_chk_count < count)) ||
          (get_shop_chk_count != null &&
            (get_shop_chk_count < count || get_shop_active_chk_count < count))) ? (
          <Box>
            {name} 부족{' '}
            <TextSmall>
              <TextSucceed>
                <button
                  onClick={async () => {
                    const result = await requestLoadWorkNewNewPatternKeywordTimeReset({ name });
                    if (result) {
                      dispatch(
                        onShowToast({
                          status: 'success',
                          message: `time reset`,
                          cancelText: '',
                          onCancel: false,
                        }),
                      );
                    }
                  }}
                >
                  time reset
                </button>
              </TextSucceed>
            </TextSmall>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box>
        {get_tonghap_chk_count !== null &&
        (get_tonghap_chk_count < count || get_tonghap_active_chk_count < count) ? (
          <>
            <Box>통합키워드</Box>
            <Box gap={8}>
              {get_tonghap_chk_count < count ? (
                <Box>
                  일반
                  <TextFail>{count - get_tonghap_chk_count}</TextFail>개
                </Box>
              ) : null}
              {get_tonghap_active_chk_count < count ? (
                <Box>
                  활동
                  <TextFail>{count - get_tonghap_active_chk_count}</TextFail>개
                </Box>
              ) : null}
            </Box>
          </>
        ) : null}
      </Box>
      <Box>
        {get_shop_chk_count !== null &&
        (get_shop_chk_count < count || get_shop_active_chk_count < count) ? (
          <>
            <Box>쇼핑키워드</Box>
            <Box gap={8}>
              {get_shop_chk_count < count ? (
                <Box>
                  일반
                  <TextFail>{count - get_shop_chk_count}</TextFail>개
                </Box>
              ) : null}
              {get_shop_active_chk_count < count ? (
                <Box>
                  활동
                  <TextFail>{count - get_shop_active_chk_count}</TextFail>개
                </Box>
              ) : null}
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};
const WorkNewPatternNeedAlbaInfos = ({ manual_keyword_insert_chk = false }) => {
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const [browserVersionInfos, setBrowserVersionInfos] = useState({});
  const [workFiddlerCountInfos, setWorkFiddlerCountInfos] = useState();
  const [workNewPatternGetWorkKeywordInfos, setWorkNewPatternGetWorkKeywordInfos] = useState();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const getBrowserInfos = async () => {
    const browser_version_infos = await requestLoadWorkNewPatternSimpleInfos();
    setBrowserVersionInfos(browser_version_infos);
  };
  const getWorkInfos = async () => {
    const work_fiddler_count_infos = await requestLoadWorkNewGetWorkFiddlerCountInfos();
    setWorkFiddlerCountInfos(work_fiddler_count_infos ? work_fiddler_count_infos : {});
  };
  const getWorkKeywordInfos = async () => {
    const work_add_manual_keyword_infos = await requestLoadWorkNewGetShopLinkAddManualKeywords();
    setWorkNewPatternGetWorkKeywordInfos(
      work_add_manual_keyword_infos ? work_add_manual_keyword_infos : {},
    );
  };

  useEffect(() => {
    getBrowserInfos();
    getWorkInfos();
    getWorkKeywordInfos();
  }, []);
  return (
    <Box column>
      <Box>
        <TextSmall>
          <Box gap={8}>
            <Box>
              IOS:{' '}
              <TextSucceed>{browserVersionInfos?.iphone_safari_version_info?.version}</TextSucceed>{' '}
              [<TextFail>{browserVersionInfos?.iphone_safari_version_info?.date}</TextFail>]
            </Box>
            <Box>
              Chrome: <TextSucceed>{browserVersionInfos?.chrome_version_info?.version}</TextSucceed>
              [<TextFail>{browserVersionInfos?.chrome_version_info?.date}</TextFail>]
            </Box>
            <Box>
              SamsungBrowser:{' '}
              <TextSucceed>
                {browserVersionInfos?.samsung_browser_last_version_info?.samsung_browser_version}
              </TextSucceed>
              (
              <TextSucceed>
                {browserVersionInfos?.samsung_browser_last_version_info?.chromium_version}
              </TextSucceed>
              )[<TextFail>{browserVersionInfos?.samsung_browser_last_version_info?.date}</TextFail>]
            </Box>
          </Box>
        </TextSmall>
      </Box>
      <Box>
        {workFiddlerCountInfos !== undefined ? (
          <WorkFiddlerCountInfoBox>
            <Box>
              {workFiddlerCountInfos?.rank_todo_infos?.all_count >
              workFiddlerCountInfos?.rank_todo_infos?.done_count +
                workFiddlerCountInfos?.rank_todo_infos?.fail_done_count ? (
                <>
                  Ranking Check:{' '}
                  <TextFail>
                    {workFiddlerCountInfos?.rank_todo_infos?.all_count -
                      workFiddlerCountInfos?.rank_todo_infos?.done_count -
                      workFiddlerCountInfos?.rank_todo_infos?.fail_done_count}
                  </TextFail>
                  <button
                    onClick={async () => {
                      const result = await requestLoadWorkRankResetCanAccessDatetime();
                      if (result) {
                        dispatch(
                          onShowToast({
                            status: 'success',
                            message: `time reset`,
                            cancelText: '',
                            onCancel: false,
                          }),
                        );
                      }
                    }}
                  >
                    time reset
                  </button>
                </>
              ) : null}
            </Box>
            <Box column>
              <Box>
                <button
                  onClick={async () => {
                    const result = await requestLoadWorkProductInfoResetCanAccessDatetime({});
                    if (result) {
                      dispatch(
                        onShowToast({
                          status: 'success',
                          message: `time reset`,
                          cancelText: '',
                          onCancel: false,
                        }),
                      );
                    }
                  }}
                >
                  slot update time reset
                </button>
              </Box>

              {workFiddlerCountInfos?.new_pattern_need_slot_infos?.map((e, index) => {
                return (
                  <Box key={index}>
                    <TextSucceed>{e?.do_type}</TextSucceed>
                    {e?.list?.map((slots, index2) => {
                      // if (slot?.state === 'done') return;
                      return (
                        <Box index={index2}>
                          <Box>{slots?.state}</Box>
                          <Box>
                            {slots?.state === 'done' ? (
                              <>
                                <TextSucceed>{slots?.count}</TextSucceed>
                              </>
                            ) : slots?.state === 'no_setting' ? (
                              <>
                                <TextSucceed>{slots?.count}</TextSucceed>
                              </>
                            ) : slots?.state === 'no_find_nvmid' ? (
                              <>
                                <TextWarning>{slots?.count}</TextWarning>
                              </>
                            ) : slots?.state === 'find_keyword_fail' ? (
                              <>
                                <TextFail>
                                  {slots?.count} [
                                  {slots?.list?.map((slot) => {
                                    return (
                                      <>
                                        {slot.id}{' '}
                                        <button
                                          onClick={async () => {
                                            const shop_link_id = slot.id;
                                            const result =
                                              await requestLoadWorkProductInfoResetKeywordFind({
                                                shop_link_id,
                                              });

                                            if (result) {
                                              getWorkInfos();
                                              dispatch(
                                                onShowToast({
                                                  status: 'success',
                                                  message: `${result} updated`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            }
                                          }}
                                        >
                                          find info reset
                                        </button>
                                      </>
                                    );
                                  })}
                                  ]
                                </TextFail>
                              </>
                            ) : slots?.state === 'done[keyword less]' ? (
                              <>
                                {/* <TextWarning> */}
                                {slots?.count}
                                {/* </TextWarning> */}
                              </>
                            ) : (
                              <>
                                <TextFail>{slots?.count}</TextFail>
                              </>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
            <Box>
              {workFiddlerCountInfos.get_warning_keyword_count > 0 ? (
                <>
                  <TextWarning>
                    need update datalab_naver_trend keyword (work_add_auto_category1s)
                  </TextWarning>
                </>
              ) : (
                <></>
              )}
              {workFiddlerCountInfos.get_work_auto_datalab_naver_trend_count_infos?.count < 100 ? (
                <>
                  <TextFail>
                    datalab_naver_trend keyword less (work_add_auto_category1s) [
                    {workFiddlerCountInfos.get_work_auto_datalab_naver_trend_count_infos?.count}]
                  </TextFail>
                </>
              ) : (
                <></>
              )}
              <WorkNewPatternGetFiddlerCountModule
                name={'자동_관심사키워드'}
                {...workFiddlerCountInfos.get_work_auto_datalab_naver_trend_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'수동_외부링크'}
                {...workFiddlerCountInfos.get_work_normal_other_link_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'자동_이슈키워드'}
                {...workFiddlerCountInfos.get_work_auto_issue_google_trend_count_infos}
              />
            </Box>
            {/* <Box>
  <WorkNewPatternGetFiddlerCountModule
    name={'자동_상품카테고리1'}
    {...workFiddlerCountInfos.get_work_auto_datalab_naver_trend_count_infos}
  />
</Box> */}
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'자동_상품카테고리3'}
                {...workFiddlerCountInfos.get_work_auto_data_category3_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'자동_국가별키워드'}
                {...workFiddlerCountInfos.get_work_auto_country_normal_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'수동_국가별키워드'}
                {...workFiddlerCountInfos.get_work_manual_country_normal_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'자동_검색량많은키워드'}
                {...workFiddlerCountInfos.get_work_auto_hot_normal_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'수동_그룹키워드'}
                {...workFiddlerCountInfos.get_work_manual_group_keyword_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'수동_그룹쇼핑키워드'}
                {...workFiddlerCountInfos.get_work_manual_group_shop_keyword_count_infos}
              />
            </Box>
            <Box>
              <WorkNewPatternGetFiddlerCountModule
                name={'슬롯_키워드'}
                {...workFiddlerCountInfos.get_work_shop_link_keyword_count_infos}
              />
            </Box>
            <Box>
              이슈키워드: {today_str()}
              {workFiddlerCountInfos?.today_issue_count_need_chk ? (
                <>
                  <TextSucceed>수집 완료</TextSucceed>
                </>
              ) : (
                <>
                  <TextFail>수집 안됨</TextFail> (after 2pm)
                </>
              )}
            </Box>
            <Box column>
              <Box>
                <TextSmall>main pages(work_add_main_page)</TextSmall>
              </Box>
              <Box gap={8}>
                {['iphone_safari', 'samsung_browser'].map((browser_type, index) => {
                  {
                    /* {['samsung_browser', 'iphone_safari', 'chrome'].map((browser_type, index) => { */
                  }
                  const page_info =
                    workFiddlerCountInfos?.get_work_main_page_infos[browser_type]?.[0];

                  const count = page_info?.count ? page_info?.count : 0;
                  const first_chk_count = page_info?.first_chk_count
                    ? page_info?.first_chk_count
                    : 0;
                  const no_first_chk_count = count - first_chk_count;
                  return (
                    <Box index={index} gap={8}>
                      {no_first_chk_count < 2 || first_chk_count < 2 ? (
                        <>
                          <TextFail>{browser_type}</TextFail>{' '}
                          {no_first_chk_count < 2 ? (
                            <Box>
                              no first:
                              <TextWarning>{no_first_chk_count.toString()}</TextWarning>/2
                            </Box>
                          ) : (
                            <></>
                          )}
                          {first_chk_count < 2 ? (
                            <Box>
                              first:<TextWarning>{first_chk_count.toString()}</TextWarning>/2
                            </Box>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>{/* {browser_type}: {page_info?.count} */}</>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box column>
              <Box>in blog pages(work_add_in_blog_page)</Box>
              <Box gap={8}>
                {['iphone_safari', 'samsung_browser'].map((browser_type, index) => {
                  {
                    /* {['samsung_browser', 'iphone_safari', 'chrome'].map((browser_type, index) => { */
                  }
                  const page_info =
                    workFiddlerCountInfos?.get_work_in_blog_page_infos[browser_type]?.[0];

                  const count = page_info?.count ? page_info?.count : 0;
                  return (
                    <Box index={index} gap={8}>
                      {count < 2 ? (
                        <>
                          <TextFail>{browser_type}</TextFail>{' '}
                          <Box>
                            <TextWarning>{count.toString()}</TextWarning>/2
                          </Box>
                        </>
                      ) : (
                        <>{/* {browser_type}: {page_info?.count} */}</>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Box>
                main_page_url version:{' '}
                <Box width={80}>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={13}
                    padding={[0, 0, 0, 5]}
                    resize={false}
                    //   underLine

                    value={workFiddlerCountInfos.check_version_new_pattern_main_page_url}
                    onChange={(i) => {
                      workFiddlerCountInfos.check_version_new_pattern_main_page_url =
                        i.target.value;
                      forceUpdate();
                    }}
                  />
                </Box>
                <Button
                  Theme={'unFill'}
                  size={'xxs'}
                  onClick={async () => {
                    const data = {
                      check_version_new_pattern_main_page_url:
                        workFiddlerCountInfos.check_version_new_pattern_main_page_url,
                    };
                    const result = await requestLoadSetKeywordCheckVersion(data);

                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${result} updated`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }}
                >
                  update
                </Button>
              </Box>
              <Box>
                in_blog_page_url version:{' '}
                <Box width={80}>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={13}
                    padding={[0, 0, 0, 5]}
                    resize={false}
                    //   underLine

                    value={workFiddlerCountInfos.check_version_new_pattern_in_blog_page_url}
                    onChange={(i) => {
                      workFiddlerCountInfos.check_version_new_pattern_in_blog_page_url =
                        i.target.value;
                      forceUpdate();
                    }}
                  />
                </Box>
                <Button
                  Theme={'unFill'}
                  size={'xxs'}
                  onClick={async () => {
                    const data = {
                      check_version_new_pattern_in_blog_page_url:
                        workFiddlerCountInfos.check_version_new_pattern_in_blog_page_url,
                    };
                    const result = await requestLoadSetKeywordCheckVersion(data);

                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${result} updated`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }}
                >
                  update
                </Button>
              </Box>
            </Box>
            <Box>
              <Box>
                in product url version:{' '}
                <Box width={80}>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={13}
                    padding={[0, 0, 0, 5]}
                    resize={false}
                    //   underLine

                    value={workFiddlerCountInfos.check_version_new_pattern_in_shop_product_url}
                    onChange={(i) => {
                      workFiddlerCountInfos.check_version_new_pattern_in_shop_product_url =
                        i.target.value;
                      forceUpdate();
                    }}
                  />
                </Box>
                <Button
                  Theme={'unFill'}
                  size={'xxs'}
                  onClick={async () => {
                    const data = {
                      check_version_new_pattern_in_shop_product_url:
                        workFiddlerCountInfos.check_version_new_pattern_in_shop_product_url,
                    };
                    const result = await requestLoadSetKeywordCheckVersion(data);

                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${result} updated`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }}
                >
                  update
                </Button>
              </Box>
            </Box>
            <Box>
              <Box>
                shop search version:{' '}
                <Box width={80}>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={13}
                    padding={[0, 0, 0, 5]}
                    resize={false}
                    //   underLine

                    value={workFiddlerCountInfos.check_version_new_pattern_shop_search_fetch}
                    onChange={(i) => {
                      workFiddlerCountInfos.check_version_new_pattern_shop_search_fetch =
                        i.target.value;
                      forceUpdate();
                    }}
                  />
                </Box>
                <Button
                  Theme={'unFill'}
                  size={'xxs'}
                  onClick={async () => {
                    const data = {
                      check_version_new_pattern_shop_search_fetch:
                        workFiddlerCountInfos.check_version_new_pattern_shop_search_fetch,
                    };
                    const result = await requestLoadSetKeywordCheckVersion(data);

                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${result} updated`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }}
                >
                  update
                </Button>
              </Box>
              <Box>
                tonghap search version:{' '}
                <Box width={80}>
                  <InputComponent
                    //   autocomplete={'off'}
                    iconSize={11}
                    fontSize={13}
                    padding={[0, 0, 0, 5]}
                    resize={false}
                    //   underLine

                    value={workFiddlerCountInfos.check_version_new_pattern_tonghap_search_fetch}
                    onChange={(i) => {
                      workFiddlerCountInfos.check_version_new_pattern_tonghap_search_fetch =
                        i.target.value;
                      forceUpdate();
                    }}
                  />
                </Box>
                <Button
                  Theme={'unFill'}
                  size={'xxs'}
                  onClick={async () => {
                    const data = {
                      check_version_new_pattern_tonghap_search_fetch:
                        workFiddlerCountInfos.check_version_new_pattern_tonghap_search_fetch,
                    };
                    const result = await requestLoadSetKeywordCheckVersion(data);

                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${result} updated`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }}
                >
                  update
                </Button>
              </Box>
            </Box>
          </WorkFiddlerCountInfoBox>
        ) : null}
      </Box>
      {manual_keyword_insert_chk && (
        <Box column width="100%">
          {workNewPatternGetWorkKeywordInfos?.list
            ?.filter((e) => {
              if (e?.state === 'done') return false;
              return true;
            })
            ?.map((e, index) => {
              return (
                <KeywordStatusBox key={index} width="100%">
                  <Box column width="100%">
                    <Box>
                      <Button
                        Theme={'unFill'}
                        size={'xs'}
                        onClick={async () => {
                          const product_info_id = e?.product_info_id;
                          const new_pattern_shop_keyword_find_fail_keywords =
                            e?.new_pattern_shop_keyword_find_fail_keywords;
                          const new_pattern_work_manual_add_keywords =
                            e?.new_pattern_work_manual_add_keywords;
                          const new_pattern_work_manual_no_use_keywords =
                            e?.new_pattern_work_manual_no_use_keywords;
                          const info = {
                            product_info_id,
                            new_pattern_work_manual_no_use_keywords,
                            new_pattern_shop_keyword_find_fail_keywords,
                            new_pattern_work_manual_add_keywords,
                          };
                          const result = await requestLoadWorkNewGetShopLinkSetManualKeywords(info);
                          if (result) {
                            dispatch(
                              onShowToast({
                                status: 'success',
                                message: `updated`,
                                cancelText: '',
                                onCancel: false,
                              }),
                            );
                          }
                        }}
                      >
                        수정
                      </Button>
                      <MallNameModule {...e?.product_info} />
                    </Box>
                    <Box>
                      {e?.product_info?.salePrice} - {e?.product_info?.new_pattern_color_code_text}
                    </Box>
                    <Box>
                      ({e?.new_pattern_shop_keyword_json_infos?.length})
                      {e?.new_pattern_shop_keyword_json_infos?.map((i) => {
                        return <TextSucceed>[{i?.keyword}]</TextSucceed>;
                      })}
                    </Box>
                    <Box>
                      사용안할 키워드
                      <Box width={700}>
                        <InputComponent
                          //   autocomplete={'off'}
                          iconSize={11}
                          fontSize={13}
                          padding={[0, 0, 0, 5]}
                          resize={false}
                          //   underLine
                          value={e?.new_pattern_work_manual_no_use_keywords}
                          onChange={(i) => {
                            e.new_pattern_work_manual_no_use_keywords = i.target.value;
                            forceUpdate();
                            //   addMdDelay();
                            // setGroupPatternInfo({
                            // ...groupPatternInfo,
                            // click_pattern_info_json_list: e.target.value,
                            // });
                            //   addMd();
                            // setProductIds(e.target.value);
                          }}
                          // onKeyUp={onKeyUp}
                          //   required
                        />
                      </Box>
                    </Box>
                    <Box>
                      {e?.product_info_id} - [{e?.state}] 키워드 찾기 실패:
                      <Box width={400}>
                        <InputComponent
                          //   autocomplete={'off'}
                          iconSize={11}
                          fontSize={13}
                          padding={[0, 0, 0, 5]}
                          resize={false}
                          //   underLine
                          value={e?.new_pattern_shop_keyword_find_fail_keywords}
                          onChange={(i) => {
                            // console;
                            e.new_pattern_shop_keyword_find_fail_keywords = i.target.value;
                            forceUpdate();

                            //   addMdDelay();
                            // setGroupPatternInfo({
                            // ...groupPatternInfo,
                            // click_pattern_info_json_list: e.target.value,
                            // });
                            //   addMd();
                            // setProductIds(e.target.value);
                          }}
                          // onKeyUp={onKeyUp}
                          //   required
                        />
                      </Box>
                    </Box>
                    <Box>
                      수동 키워드 등록 찾기:
                      <Box width={700}>
                        <InputComponent
                          //   autocomplete={'off'}
                          iconSize={11}
                          fontSize={13}
                          padding={[0, 0, 0, 5]}
                          resize={false}
                          //   underLine
                          value={e?.new_pattern_work_manual_add_keywords}
                          onChange={(i) => {
                            e.new_pattern_work_manual_add_keywords = i.target.value;
                            forceUpdate();
                            //   addMdDelay();
                            // setGroupPatternInfo({
                            // ...groupPatternInfo,
                            // click_pattern_info_json_list: e.target.value,
                            // });
                            //   addMd();
                            // setProductIds(e.target.value);
                          }}
                          // onKeyUp={onKeyUp}
                          //   required
                        />
                      </Box>
                    </Box>
                    {/* <Box>
                      <CheckBox
                        checked={e?.product_info?.new_pattern_shop_keyword_json_infos_done_chk}
                        onChange={(i) => {
                          e.product_info.new_pattern_shop_keyword_json_infos_done_chk =
                            !i.target.checked;
                          forceUpdate();
                        }}
                      >
                        키워드 작업 완료
                      </CheckBox>
                    </Box> */}
                  </Box>
                </KeywordStatusBox>
              );
            })}
        </Box>
      )}
    </Box>
  );
};
const KeywordStatusBox = styled.div`
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
const WorkFiddlerCountInfoBox = styled.div`
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
export default WorkNewPatternNeedAlbaInfos;
