import styled from 'styled-components';

export const BoxWrapper = styled.div`
  grid-column: 1/3;
  /* display: flex; */
  flex-direction: column;
  background: #ffffff;

  /* Elevation/Black_Y_001 */

  box-shadow: 0rem 0rem 3rem rgba(0, 0, 0, 0.04), 0rem 1rem 5rem rgba(0, 0, 0, 0.08);
  border-radius: 20rem;
  padding: 60rem 47rem;
`;
export const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

export const Table = styled.table`
  /* display: block; */
  /* width: 500rem; */
  /* overflow: auto; */
  /* background-color: red; */
  /* scrollbar-width: 100rem; */
  width: 100%;
  /* height: 150rem; */
  table-layout: fixed;
  box-shadow: var(--Elevation_001);
  border-radius: 8rem;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .fixedColumns {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .leftBorder {
    border-left: 1rem solid var(--G_50);
  }
  .fileBtn {
    position: relative;
  }
`;
export const THeader = styled.thead`
  tr {
    th {
      padding: 8rem 0;
      background: var(--P_10);
      font-weight: 500;
      font-size: 14rem;
      line-height: 172%;
      text-align: center;
      color: var(--G_700);
      border-width: 1rem 0rem 1rem 0rem;
      border-style: solid;
      border-color: var(--G_50);
      vertical-align: middle;
      + th {
        border-left: 1rem solid var(--G_50);
      }
    }
  }
`;

export const Tbody = styled.tbody`
  tr {
    border-top: 1rem solid var(--G_50);

    td {
      height: 56rem;
      /* background-color: red; */
      padding: 6rem 4rem;
      font-weight: 400;
      font-size: 14rem;
      line-height: 172%;
      text-align: center;
      letter-spacing: -0.03em;
      color: var(--G_700);
      vertical-align: middle;
      /* background-color: var(--White); */
      /* background-color: red; */
      border-right: 1rem solid var(--G_50);
      + td {
        border-left: 1rem solid var(--G_50);
      }
      &.fixedColumns {
        /* background-color: #fff; */
      }
      > div {
        /* display: flex; */
        justify-content: center;
      }
      .badgeBox {
        .badge {
          display: inline-flex;
        }
      }
    }
    &:last-child {
      border-bottom: 1rem solid var(--G_50);
    }
  }
`;

export const CheckWrapper = styled.div`
  /* display: flex; */
  background-color: red;

  align-items: center;
  justify-content: center;
`;
