import moment from 'moment';
import { Cookies } from 'react-cookie';
import { encode } from 'html-entities';
import { decode } from 'html-entities';
import { token_name } from './Env';
import { requestLoadUserLoginCheck } from './Service/user';

const cookies = new Cookies();

export const phoneRemoveHyphen = (number) => {
  if (!number) return;
  const newNumber = number?.replace(/-/g, '');
  return newNumber;
};
export const phoneHyphen = (number) => {
  if (!number) return;
  const phoneNum = number
    ?.replace(/[^0-9]/g, '')
    ?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  return phoneNum;
};
export const currencyComma = (number) => {
  if (number === undefined) return null;
  if (number?.toString() === '0') return '0';
  if (!number) return;
  const currencyNum = number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return currencyNum;
};
export const getFileDate = (name) => {
  if (!name) return;
  const fileName = name?.substring(0, 8)?.replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
  return fileName;
};
export const onlyName = (name) => {
  if (!name) return;
  const memberName = name?.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣| ]/g, '')?.replace('  ', ' ');
  return memberName;
};
export const getWeekColorStr = (ddd) => {
  if (ddd === 'Mon') {
    return "<font color='#0F0F0F'>월</font>";
  } else if (ddd === 'Tue') {
    return "<font color='#0F0F0F'>화</font>";
  } else if (ddd === 'Wed') {
    return "<font color='#0F0F0F'>수</font>";
  } else if (ddd === 'Thu') {
    return "<font color='#0F0F0F'>목</font>";
  } else if (ddd === 'Fri') {
    return "<font color='#0F0F0F'>금</font>";
  } else if (ddd === 'Sat') {
    return "<font color='#0000F0'>토</font>";
  } else if (ddd === 'Sun') {
    return "<font color='#F00000'>일</font>";
  } else {
    return `<font color='#0F0F0F'>${ddd}</font>`;
  }
};
export const getWeekStr = (ddd) => {
  if (ddd === 'Mon') {
    return '월';
  } else if (ddd === 'Tue') {
    return '화';
  } else if (ddd === 'Wed') {
    return '수';
  } else if (ddd === 'Thu') {
    return '목';
  } else if (ddd === 'Fri') {
    return '금';
  } else if (ddd === 'Sat') {
    return '토';
  } else if (ddd === 'Sun') {
    return '일';
  } else {
    return `${ddd}`;
  }
};
export const onlyNum = (num) => {
  if (!num) return;
  const number = num?.replace(/[^0-9]$/g, '');
  return number;
};
export const imgReader = (i) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader?.readAsDataURL(i);
    reader.onload = () => resolve(reader?.result);
  });
};
export const divide = (value, all_value, n = 0) => {
  if (all_value == 0) return 100;
  const per = value / all_value;
  return per.toFixed(n);
};
export const get_percent = (value, all_value, n = 2) => {
  if (all_value == 0) return 100;
  const per = (value / all_value) * 100;
  return per.toFixed(n);
};
export const getImgFromUrl = async (img, fileName) => {
  const response = await fetch(img);
  const data = await response.blob();
  const file = new File([data], fileName);
  return file;
};
export const getImgBase64 = async (images) => {
  const img = typeof images === Array ? Promise.all(images.map((i) => i.url)) : await images;
  return img;
};
export const getCharacters = (str) => {
  let reg = str?.replace(/[^\w\sㄱ-힣]|[\_]/g, '');
  return reg;
};
export const getEncode = (str) => {
  if (!str) return;
  const result = encode(str, { level: 'all' });
  return result;
};
export const getDecode = (str) => {
  if (!str) return;
  const result = decode(str);
  return result;
};

export const filePath = (fullPath) => fullPath?.replace(/^.*[\\\/]/, '');
export const setDateUtc = (date) => moment.utc(date).local().format();

const dataType = (type) => {
  if (['department', 'regular', 'regulars'].includes(type)) {
    return {
      id: 'teamID',
      name: 'memberName',
    };
  }
  if (type === 'partners') {
    return {
      id: 'teamID',
      name: 'memberName',
    };
  }
  if (type === 'process') {
    return {
      id: 'parentID',
      name: 'name',
    };
  }
  if (type === 'space') {
    return {
      id: 'parentID',
      name: 'name',
    };
  }
};

export const getSelectList = (type = 'regulars', setItem, selectDataAll, tree = selectDataAll) => {
  if (!setItem || !selectDataAll || !tree) return [];
  const dataMap = (i, name) => {
    const set = [];
    const settingArray = (array) => {
      for (let n = 0; n < array?.length; n++) {
        set.push(array[n]);
        if (array[n][name].length) {
          settingArray(array[n][name]);
        }
      }
    };
    settingArray(i[name]);
    const filter = [...set];
    return filter;
  };
  const { id, name } = dataType(type);

  const selectDataAllSet = selectDataAll[0]?.childSpaces
    ? [...dataMap(selectDataAll[0], 'childSpaces')]
    : [...selectDataAll];

  const data = [];

  const isType = (i) =>
    [undefined, -1].includes(setItem?.teamType) || [undefined, -1].includes(i?.teamType)
      ? true
      : setItem?.teamType === i?.teamType;

  const getChildItem = (team, child) => {
    data.push(...selectDataAllSet?.filter((i) => i[id] === team?.id && isType(i)));
    for (let i = 0; i < child?.length; i++) {
      const item = child[i];
      data.push(
        ...selectDataAllSet?.filter((i) => i[id] === (item?.team?.id || item?.id) && isType(i)),
      );
      const childTeams = item?.childTeams || item?.childSpaces;
      if (childTeams.length > 0) getChildItem(item?.team || item, childTeams);
    }
  };
  const getChildTree = (item, data) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const childTeams = node?.childTeams || node?.childSpaces;
      if (item?.id === (node?.team?.id || node?.id))
        return getChildItem(node?.team || node, childTeams);
      if (childTeams?.length > 0) getChildTree(item, childTeams);
    }
  };
  getChildTree(setItem, tree);
  const result = data?.filter((i, n) => {
    let isSave = true;
    data.map((j, m) => {
      if (j.id === i.id && j[name] === i[name] && n < m) {
        isSave = false;
      }
    });
    return isSave;
  });

  return result;
};
export const sortSet = (a, b) => {
  if (a > b) return 1;
  if (a === b) return 0;
  if (a < b) return -1;
};

export const getFileName = (file) => file?.split('/')?.reverse()[0];
export const navigate_newtab = (url) => {
  window.open(url, '_blank', 'noreferrer');
};
export const getFileNameOriginal = (fileName) => getFileName(fileName)?.split('_')?.reverse()[0];

/**
 *
 * @param {[object]} data
 * @param {String} childName
 * @param {target} valueTarget
 * @returns [String,Sting,String]
 */
export const childTeamArrayTarget = (data, valueTarget) => {
  if (!valueTarget || !data) return;
  const childFilter = (i, array = []) => {
    const isCheck = i?.filter((j) => j?.team?.id === valueTarget);
    return isCheck.length
      ? [...array]
      : i
          ?.map((j) => childFilter(j?.childTeams, [...array, j?.team?.teamName]))
          ?.filter((i) => i)[0];
  };
  return childFilter(data);
};

export const childTeamArraySet = (data) => {
  if (!data) return;
  const array = [];
  const childMap = (i, index = 0) => {
    return i?.map((i) => {
      array.push({ ...i?.team, child: index });
      childMap(i?.childTeams, index + 1);
    });
  };
  childMap(data);
  const filter = array
    .sort(({ teamName: a }, { teamName: b }) => sortSet(a, b))
    .sort(({ child: a }, { child: b }) => sortSet(a, b));
  return filter;
};

export const childSpaceGroupArraySet = (data, id = null) => {
  if (!id || !data) return;
  const childFilter = (i, array = []) => {
    const isCheck = i?.filter((j) => j?.id === id);
    return isCheck.length
      ? [...array]
      : i?.map((j) => childFilter(j?.childSpaces, [...array, j?.name]))?.filter((i) => i)[0];
  };

  return childFilter(data);
};

export const childSpaceArraySet = (data, filterID = []) => {
  if (!data) return [];
  const isArray = Array.isArray(data);
  const array = [];
  const childMap = (i, index = 0) => {
    return i?.map((i) => {
      const isFilter = filterID?.includes(i?.id);
      if (isFilter) return;
      else {
        array.push({ ...i, child: index });
        childMap(i?.childSpaces, index + 1);
      }
    });
  };
  childMap(isArray ? data : [data]);
  const filter = array
    .sort(({ name: a }, { name: b }) => sortSet(a, b))
    .sort(({ child: a }, { child: b }) => sortSet(a, b));
  return filter;
};

export const getParent = (data, seletedItem) => {
  if (!data) return;
  let targetID = seletedItem?.team?.id;
  let resultTeam = {};
  const parentMap = (i, id, index = 0) => {
    return i?.map((j) => {
      if (j?.parentTeamID && j?.id === id) {
        parentMap(data, j?.parentTeamID);
      } else if (j?.parentTeamID === null && j?.id === id) {
        resultTeam = j;
        return;
      }
    });
  };
  parentMap(data, targetID, 0);
  return resultTeam;
};
export const getBirth = (jumin1, jumin2) => {
  let nByear;
  const first = String(jumin1);
  const last = String(jumin2);
  const today = new Date();
  const year = parseInt(String(first).substring(0, 2), 10);
  const month = parseInt(String(first).substring(2, 4), 10);
  const day = parseInt(String(first).substring(4, 6), 10);
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const currentDay = today.getDate();
  if (parseInt(String(last).substring(0, 1), 10) < 3) {
    nByear = 1900 + year;
  } else {
    nByear = 2000 + year;
  }
  const ageNum = nByear ? Number(currentYear - nByear) : 0;
  const age = `${ageNum} 세`;
  const internationalAge = `만 ${
    month > currentMonth || (month === currentMonth && day > currentDay) ? ageNum - 1 : ageNum
  } 세`;
  const sex = nByear
    ? parseInt(String(last).substring(0, 1), 10) % 2 === 1
      ? '남자'
      : '여자'
    : '';
  return [age, internationalAge, sex];
};

// 한글 받침 확인
export const koreanCheckTheBase = (string) => {
  const finalChrCode = string?.charCodeAt(string?.length - 1);
  const finalConsonantCode = (finalChrCode - 44032) % 28;
  return finalConsonantCode !== 0;
};

//접속시간 표시
// export const accessTime = (data) => {};
export const setItem = (name, data) => localStorage.setItem(name, JSON.stringify(data));

export const removeItem = (name) => localStorage.removeItem(name);

export const setCookie = (name, value, second = null, path = '/', secure = true) =>
  cookies.set(name, value, {
    // secure,
    path,
    expires: second && new Date(Date.now() + second * 1000),
  });

export const getCookie = (name, path = '/') =>
  cookies.get(name, {
    path,
    // secure: true
  });
export const auth_headers = () => {
  return {
    headers: {
      Authorization: getCookie(token_name),
    },
  };
};

export const removeCookie = (name, path = '/') => cookies.remove(name, { path });

export const getUser = async () => {
  return await requestLoadUserLoginCheck();
};
export const getAlphaAndNumber = (text) => {
  const regex = /^[a-zA-Z0-9]+$/;
  const result = text.match(regex);
  return result?.[0];
};
export const setRest = (items = []) => {
  let result = '';
  for (let i = 0; i < items.length; i++) {
    if (i === 0) result += `${items[i]}`;
    else result += `,  ${items[i]}`;
  }
  return result;
};

export const getPromiseAll = async (fn) => {
  const result = await Promise.all(fn);
  return result;
};
export const pipeFn =
  (...funcs) =>
  (v) => {
    return funcs.reduce((res, func) => {
      return func(res);
    }, v);
  };

export const isTop = () => {
  const root = document.getElementById('root');
  return root.scrollTo(0, 0);
};

export const numberSum = (arr) => arr?.reduce((sum, current) => sum + current, 0);

export const getFileSize = (size) => {
  if (size / 1024 / 1024 < 1) return `${(size / 1024).toFixed(1)} KB`; // KB
  else return `${Math.floor(size / 1024 / 1024)} MB`; //MB
};
const getFileInfo = (file) => {
  const fileSplit = file?.split('/')?.reverse(0)[0];
  const date = fileSplit.split('_')[0];
  const name = fileSplit.slice(date.length + 1, fileSplit.length);
  return { name, date };
};

export const setFileToServer = (files) =>
  files.map(({ id, fileName, date, name, fileType, sizeNo }) => {
    return {
      fileType,
      id: id ? id : 0,
      fileSize: sizeNo,
      fileName: fileName ? fileName : `${date}_${name}`,
    };
  });

// export const setFileFromServer = (files) =>
//   files.map((i) => {
//     const { id, fileName, fileSize } = i;
//     if (i.id === 0 || !fileName) return i;
//     const { name, date } = getFileInfo(fileName);
//     return id > 0
//       ? {
//           ...i,
//           name,
//           date,
//           location: `${SERVER_URL}${fileName}`,
//           size: getFileSize(fileSize),
//           url: fileName,
//         }
//       : i;
//   });

export const isEmptyObject = (obj) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

export const keyword_strip = (str) => {
  return str?.replace(/\s+/g, '');
};
