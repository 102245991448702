import React, { useRef, useState } from 'react';
import DatepickerHeader from './components/DatepickerHeader';
import DatepickerMutiple from './components/DatepickerMutiple';

function MultipleMonthDatepicker({ format = 'month', ...props }) {
    const DateHeader = ({ ...props }) => (
        <DatepickerHeader {...props} onClick={false} />
    );
    return (
        <DatepickerMutiple
            format={format}
            DateHeader={DateHeader}
            type="Year"
            showMonthYearPicker={true}
            {...props}
        />
    );
}

export default MultipleMonthDatepicker;
