import React from "react";
import {
    DotText,
    HashText,
    PotinText,
    TextWrapper,
    UnderLineText,
} from "./styled";
import Icon from "../IconBox";
import Title from "../Title";
function Text({ children, status }) {
    const statusIcons = {
        success: <Icon.Complate size="13" />,
        error: <Icon.Danger size="13" />,
        warning: <Icon.Complate size="13" />,
    };
    return (
        <TextWrapper status={status}>
            {status && <i>{statusIcons[status]}</i>}
            {children}
        </TextWrapper>
    );
}

const Dot = ({ children }) => <DotText>{children}</DotText>;

const Point = ({ children, size, color, weight }) => {
    return (
        <PotinText weight={weight} isColor={color} size={size}>
            {children}
        </PotinText>
    );
};

const Hash = ({ children }) => {
    if (!children) return;
    return <HashText className="hasText">{children}</HashText>;
};
const UnderLine = ({ size, color, borderColor, children, etc }) => {
    return (
        <UnderLineText borderColor={borderColor}>
            <Title color={color} size={size}>
                {children}
            </Title>
            {etc}
        </UnderLineText>
    );
};
Text.Dot = Dot;
Text.Hash = Hash;
Text.Point = Point;
Text.UnderLine = UnderLine;

export default Text;
