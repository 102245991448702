import { Box } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
function PaginationComponent({ page = 1, pageMax = 5, onPage }) {
  const [current, setCurrent] = useState(page);
  const [numbers, setNumbers] = useState([]);
  useEffect(() => {
    if (current < 2) {
      setNumbers([1, 2, 3].filter((data) => data <= pageMax && data > 0));
    } else if (current >= pageMax) {
      setNumbers([pageMax - 2, pageMax - 1, pageMax].filter((data) => data <= pageMax && data > 0));
    } else {
      setNumbers([current - 1, current, current + 1]);
    }
  }, [current, page, pageMax]);
  return (
    <Wrapper>
      <Box>
        <PagingLeftIcon
          disabled={0 >= current - 1}
          onClick={() => {
            setCurrent(current - 1);
            onPage(current - 1);
          }}
        />

        {Math.min(...numbers) > 2 && (
          <NumberIcon
            onClick={() => {
              setCurrent(1);
              onPage(1);
            }}
          >
            {1}
          </NumberIcon>
        )}
        {Math.min(...numbers) > 2 && numbers.length ? <SpotIcon /> : null}
        {numbers?.map((data, key) => {
          return (
            <NumberIcon
              key={key}
              active={data === current}
              onClick={() => {
                setCurrent(data);
                onPage(data);
              }}
            >
              {data}
            </NumberIcon>
          );
        })}
        {Math.max(...numbers) < pageMax - 1 && numbers.length ? <SpotIcon /> : null}
        {Math.max(...numbers) < pageMax - 1 &&
          (numbers.length ? (
            <NumberIcon
              onClick={() => {
                setCurrent(pageMax);
                onPage(pageMax);
              }}
            >
              {pageMax}
            </NumberIcon>
          ) : null)}
        <PagingRightIcon
          disabled={pageMax < current + 1}
          onClick={() => {
            setCurrent(current + 1);
            onPage(current + 1);
          }}
        />
      </Box>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;
PaginationComponent.defaultProps = {
  onPage: () => {},
};
const PagingLeftIcon = ({ active, disabled, onClick }) => {
  return (
    <SvgBlock
      disabled={disabled}
      onClick={(e) => {
        !disabled && onClick(e);
      }}
    >
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className="path"
          d="M0.155078 5.3325L5.83739 9.97747C5.85224 9.9897 5.87009 9.99731 5.88888 9.99941C5.90767 10.0015 5.92664 9.99801 5.94362 9.98934C5.96061 9.98066 5.9749 9.96714 5.98487 9.95034C5.99485 9.93354 6.00009 9.91414 6 9.89436L6 8.8746C6 8.80996 5.97101 8.74796 5.92311 8.70838L1.38532 5.00005L5.92311 1.29173C5.97227 1.25215 6 1.19015 6 1.12551L6 0.105751C6 0.0173633 5.90294 -0.0314476 5.8374 0.0226404L0.155078 4.66761C0.106782 4.70704 0.0677126 4.75747 0.0408404 4.81508C0.0139681 4.87269 3.99034e-07 4.93595 3.9343e-07 5.00005C3.87825e-07 5.06416 0.0139681 5.12742 0.0408403 5.18503C0.0677126 5.24263 0.106782 5.29307 0.155078 5.3325Z"
          fill="#DDDDDD"
        />
      </svg>
    </SvgBlock>
  );
};
const PagingRightIcon = ({ active, disabled, onClick }) => {
  return (
    <SvgBlock
      disabled={disabled}
      onClick={(e) => {
        !disabled && onClick(e);
      }}
    >
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className="path"
          d="M5.84492 4.6675L0.162605 0.0225297C0.147757 0.0102955 0.129913 0.00269247 0.111122 0.000593723C0.0923312 -0.00150503 0.073356 0.0019856 0.0563753 0.0106648C0.0393947 0.019344 0.0250976 0.0328598 0.0151257 0.0496598C0.00515376 0.0664599 -8.85023e-05 0.0858629 1.13045e-06 0.105641V1.1254C1.13045e-06 1.19004 0.0289926 1.25204 0.0768915 1.29162L4.61468 4.99994L0.0768915 8.70827C0.0277321 8.74785 1.13045e-06 8.80985 1.13045e-06 8.87449V9.89425C1.13045e-06 9.98264 0.0970593 10.0314 0.162605 9.97736L5.84492 5.33239C5.89322 5.29296 5.93229 5.24252 5.95916 5.18492C5.98603 5.12731 6 5.06405 6 4.99994C6 4.93584 5.98603 4.87258 5.95916 4.81497C5.93229 4.75736 5.89322 4.70693 5.84492 4.6675Z"
          fill="#DDDDDD"
        />
      </svg>
    </SvgBlock>
  );
};
const SpotIcon = () => {
  return (
    <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2ZM10.5 2C10.5 2.82843 9.82843 3.5 9 3.5C8.17157 3.5 7.5 2.82843 7.5 2C7.5 1.17157 8.17157 0.5 9 0.5C9.82843 0.5 10.5 1.17157 10.5 2ZM16 3.5C16.8284 3.5 17.5 2.82843 17.5 2C17.5 1.17157 16.8284 0.5 16 0.5C15.1716 0.5 14.5 1.17157 14.5 2C14.5 2.82843 15.1716 3.5 16 3.5Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};
const NumberIcon = styled.button`
  cursor: default;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  gap: 10rem;

  min-width: 24rem;
  height: 24rem;
  background: #ffffff;
  border: 1rem solid #eeeeee;
  border-radius: 2rem;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12rem;
  line-height: 22rem;
  ${(props) => {
    const { disabled, active } = props;
    if (disabled) return;

    return css`
      color: ${active ? '#0068fe' : '#222222'};
      border: 1rem solid ${active ? '#0068fe' : '#EEEEEE'};

      /* border: 1rem solid #eeeeee; */
      &:hover {
        cursor: ${active ? 'default' : 'pointer'};

        color: #0068fe;
        border: 1rem solid #0068fe;
      }
    `;
  }};
`;
const SvgBlock = styled.button`
  cursor: default;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  gap: 10rem;

  width: 24rem;
  height: 24rem;
  background: #ffffff;
  border: 1rem solid #eeeeee;
  border-radius: 2rem;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12rem;
  line-height: 22rem;

  ${(props) => {
    const { disabled } = props;
    if (disabled) return;
    return css`
      &:hover {
        cursor: pointer;
        .path {
          fill: black;
        }
      }
    `;
  }};
`;
const SVG = styled.svg``;
export default PaginationComponent;
