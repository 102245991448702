import useTab from 'Common/Hooks/useTab';
import { Grid } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// import ProductGroupList from './ProductGroupList';
import ProductList from './ProductList';
import ProductAdd from './ProductAdd';
import useMutations from 'Common/Service/userMutations';
import { useSelector } from 'react-redux';
import ProductCancel from './ProductCancel';
import ProductNoSearch from './ProductNoSearch';
import IssueLog from '../Payment/Page/IssueLog';

const Product = () => {
  const location = useLocation();
  const [result, setResults] = useState({});
  const { current, onClickLocation } = useTab(location.pathname, true);
  const [userGroups, setUserGroups] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);
  const { requestLoadUserListMutation } = useMutations(setResults);

  const packUserGroups = (users) => {
    return users?.reduce((acc, user) => {
      if (!acc[user.partner_name]) acc[user.partner_name] = [];
      acc[user.partner_name].push(user);
      return acc;
    }, {});
  };
  useEffect(() => {
    if (result?.request === 'requestLoadUserListMutation') {
      if (result?.success && result?.result !== undefined) {
        const packGroup = packUserGroups(result?.result);
        setUserGroups(packGroup);
      }
    }
    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadUserListMutation.mutate({ partner_chk: true });
  }, []);
  return (
    <Grid row={['auto', 1]} gap={32}>
      {null && (
        <TabComponent
          current={current}
          onClick={(url) => {
            onClickLocation(url);
          }}
          items={[
            // loginInfo.user_level >= 3 && { title: '슬롯', value: '/product/group' },
            { title: '상품 목록', value: '/product/slot' },
            loginInfo.user_level >= 3 && {
              title: '상품 등록',
              value: '/product/add',
              disabled: !loginInfo || loginInfo?.user_level <= 2,
            },
            // loginInfo.user_level >= 4 && {
            //   title: '상품 일괄중지',
            //   value: '/product/cancel',
            //   disabled: !loginInfo || loginInfo?.user_level <= 3,
            // },
            // loginInfo.user_level >= 4 && {
            //   title: '상품 유효클릭 안잡힘 ',
            //   value: '/product/no_search',
            //   disabled: !loginInfo || loginInfo?.user_level <= 3,
            // },
            loginInfo.user_level >= 4 && {
              title: '이력관리 ',
              value: '/product/logs',
            },
          ]?.filter((e) => e)}
        />
      )}
      <Routes>
        {/* <Route path={'/add'} element={<ProductAdd />} /> */}
        {/* <Route path={'/cancel'} element={<ProductCancel />} /> */}
        <Route path={'/no_search'} element={<ProductNoSearch />} />

        {/* <Route
          path={'/group/:partner_name/:user_name'}
          element={<ProductGroupList userGroups={userGroups} />}
        />
        <Route
          path={'/group/:partner_name'}
          element={<ProductGroupList userGroups={userGroups} />}
        /> */}
        {/* <Route path={'/group/*'} element={<ProductGroupList userGroups={userGroups} />} /> */}

        {/* <Route path={'/slot?partner=:partner_name'} element={<ProductList />} /> */}
        {/* <Route path={'/slot/:partner'} element={<ProductList />} /> */}
        <Route path={'/*'} element={<ProductList />} />
        <Route path={'/slot/*'} element={<ProductList />} />
      </Routes>
    </Grid>
  );
};

export default Product;
