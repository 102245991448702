import { Box, Grid, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoadWorkProxyGetInfos } from 'Common/Service/common';
import { currencyComma, get_percent } from 'Common/Utils';
import PercentModule from './Module/PercentModule';
import { TextFail, TextSmall, TextSucceed, reload_second } from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import WorkProxyControlInfos from './WorkProxyControlInfos';

const WorkProxy = () => {
  const [proxyInfos, setProxyInfos] = useState();

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;

  const getProxyInfos = async () => {
    const proxy_infos = await requestLoadWorkProxyGetInfos({});
    setProxyInfos(proxy_infos);
  };
  const [count, setCount] = useState(reload_second);
  const dispatch = useDispatch();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getProxyInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const CopyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(
        onShowToast({
          status: 'success',
          message: `Clipboard copy succeeded. (${text})`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } catch (error) {
      dispatch(
        onShowToast({
          status: 'error',
          message: 'Clipboard copy failed.',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  useEffect(() => {
    getProxyInfos();
  }, []);

  return (
    <>
      <Box bottom={-40}>
        api get proxy count: {proxyInfos?.proxy_pyproxy_get_count} <TextSmall>{count}</TextSmall>
      </Box>
      <Grid column={['2fr', '1fr', '1fr']} gap={32} valign="top" height="1000">
        <Box column>
          <WorkProxyControlInfos />
          {/* <Box>
            <Box>
              <TextSmall>
                Proxy Succeed countries ({proxyInfos?.ok_proxy_country_list.length})
              </TextSmall>
            </Box>
          </Box>
          <Box column gap={8}>
            {proxyInfos?.ok_proxy_country_list
              ?.filter((e) => e.check_etc_do1_access_count > 0)
              ?.map((e) => {
                return (
                  <Box column>
                    <Box>
                      <Box>
                        <TextSmall>{e?.country}</TextSmall>
                      </Box>
                      <Box>
                        <TextSmall>
                          {e?.check_etc_do1_ok_count}/{e?.check_etc_do1_access_count} (
                          <PercentModule
                            succeed_limit={85}
                            value={e?.check_etc_do1_ok_count}
                            valueMax={e?.check_etc_do1_access_count}
                          />
                          )
                        </TextSmall>
                      </Box>
                    </Box>
                    <Box>
                      <TextSmall>
                        timeout: <TextFail>{currencyComma(e?.check_timeout_count)}</TextFail>{' '}
                        problem:{' '}
                        <TextFail>
                          {currencyComma(e?.check_today_visit_etc_do1_problem_count)}{' '}
                        </TextFail>
                        access per rate:{' '}
                        <TextFail>
                          {parseInt(
                            e?.check_today_visit_etc_do1_problem_count /
                              e?.check_etc_do1_access_count,
                          )}
                        </TextFail>
                      </TextSmall>
                    </Box>
                  </Box>
                );
              })}
          </Box> */}
        </Box>
        <Box column>
          <Box column>
            <Box>
              <Box>
                <TextSmall> logs ({proxyInfos?.basic_logs.length})</TextSmall>
              </Box>
            </Box>
            <Box column>
              {proxyInfos?.basic_logs?.map((e) => {
                return (
                  <Box>
                    <Box>
                      <TextSmall>{e?.datetime.slice(10, 19)}</TextSmall>
                    </Box>
                    <Box>
                      <TextSmall>
                        <TextSucceed> {e?.state}</TextSucceed>
                      </TextSmall>
                    </Box>
                    <Box>
                      <TextSmall>{e?.status}</TextSmall>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box column>
          <Box column>
            <Box>
              <Box>
                <TextSmall>error logs ({proxyInfos?.basic_error_logs.length})</TextSmall>
              </Box>
            </Box>
            <Box column>
              {proxyInfos?.basic_error_logs?.map((e) => {
                return (
                  <Box>
                    <Box>
                      <TextSmall>{e?.datetime?.slice(10, 19)}</TextSmall>
                    </Box>
                    <Box>
                      <TextSmall>{e?.state_sub}</TextSmall>
                    </Box>
                    <Box>
                      <TextSmall>{e?.vmware_name}</TextSmall>
                    </Box>
                    <Box align="left">
                      {e?.status?.includes('errorMsg: user forbidden,userip=') ? (
                        <>
                          <button
                            onClick={async () => {
                              {
                                var n = e?.status.indexOf('userip=');
                                var ip = e?.status?.slice(n + 7);
                                n = ip.indexOf(',');
                                ip = ip?.slice(0, n)?.trim();
                                CopyText(ip);
                              }
                            }}
                          >
                            <TextFail>{`${e?.status}`}</TextFail>
                          </button>
                        </>
                      ) : e?.status?.includes('please add this ip to your ip whitelist') ? (
                        <>
                          <button
                            onClick={async () => {
                              {
                                const n = e?.status.indexOf('request_ip');
                                const ip = e?.status?.slice(n + 11)?.trim();
                                CopyText(ip);
                              }
                            }}
                          >
                            <TextFail>{`${e?.status}`}</TextFail>
                          </button>
                        </>
                      ) : (
                        <>
                          <TextFail>{`${e?.status}`}</TextFail>
                        </>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        {/* <Box column>
          <Box>
            <Box>
              <TextSmall>
                Realtime Using countries ({proxyInfos?.realtime_using_proxy_list.length})
              </TextSmall>
            </Box>
          </Box>
          <Box column>
            {proxyInfos?.realtime_using_proxy_list?.map((e) => {
              return (
                <Box>
                  <Box>
                    <TextSmall>{e?.country}</TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>{currencyComma(e?.count)}</TextSmall>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box> */}
        {/* <Box column>
          <Box>
            <Box>
              <TextSmall>
                Proxy Problem countries ({proxyInfos?.problem_proxy_country_list.length})
              </TextSmall>
            </Box>
          </Box>
          <Box column gap={8}>
            {proxyInfos?.problem_proxy_country_list
              ?.filter((e) => e.check_today_etc_do1_problem_count > 0)
              ?.map((e) => {
                return (
                  <Box column>
                    <Box>
                      <Box>
                        <TextSmall>{e?.country}</TextSmall>
                      </Box>
                      <Box>
                        <TextSmall>
                          {e?.check_today_etc_do1_problem_count}/{e?.check_etc_do1_access_count} (
                          <PercentModule
                            value={e?.check_today_etc_do1_problem_count}
                            valueMax={e?.check_etc_do1_access_count}
                          />
                          )
                        </TextSmall>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box> */}
        {/* <Box column>
          <Box>
            <Box>
              <TextSmall>Block Countries ({proxyInfos?.block_proxy_country_list.length})</TextSmall>
            </Box>
          </Box>
          <Box column>
            {proxyInfos?.block_proxy_country_list?.map((e) => {
              return (
                <Box>
                  <Box>
                    <TextSmall>{e?.country}</TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>
                      {e?.block_date} ({e?.block_date_ago_day} 일 전)
                    </TextSmall>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box> */}
      </Grid>
    </>
  );
};
export default WorkProxy;
