import { Box, DropBox } from "Common/UI/atoms";
import LabelBox from "Common/UI/molecules/LabelBox";
import React from "react";
import { Wrapper } from "./styled";

const FormDropList = ({
    label,
    isEdit = true,
    option,
    placeholder,
    value,
    name,
    onChange,
    disabled,
    size = 44,
    //추가 20221018
    isAdd,
    onAddText,
    onChangeText,
    isOnAdd,
    editOption,
    onEdit,
    onDeleteEdit,
    row,
    fontSize,
}) => {
    const filter = option?.map((i) => {
        const selected = String(i.value) === String(value) || i.selected;
        return { ...i, selected };
    });
    return (
        <Wrapper className="formInput">
            <LabelBox fontSize={fontSize} row={row} label={label}>
                <Box fill>
                    {isEdit ? (
                        <DropBox
                            isAdd={isAdd}
                            isOnAdd={isOnAdd}
                            onAddText={onAddText}
                            editOption={editOption}
                            onEdit={onEdit}
                            onChangeText={onChangeText}
                            onDeleteEdit={onDeleteEdit}
                            size={size}
                            disabled={disabled}
                            name={name}
                            placeholder={placeholder}
                            option={filter}
                            onChange={onChange}
                            line
                        />
                    ) : (
                        <p className="value">
                            {filter?.filter((i) => i.selected)[0]?.title}
                        </p>
                    )}
                </Box>
            </LabelBox>
        </Wrapper>
    );
};

export default FormDropList;
