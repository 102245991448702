import {
  requestLoadWorkRankForceUpdateRankMakeTodayLogs,
  requestLoadWorkRankGetDateInfos,
  requestLoadWorkRankResetCanAccessDatetime,
  requestLoadWorkResultLogsGetTrafficDateLogs,
} from 'Common/Service/common';
// import useMutations from 'Common/Service/userMutations';
import Box from 'Common/UI/atoms/Box';
import { TextFail, TextSmall, TextSucceed, reload_second, today_str } from 'Common/Utils/util';
import MallInfo from 'MallInfo';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import MallNameModule from './Module/MallNameModule';
import RankModule from 'Page/Product/Module/RankModule';
import { TextButton } from 'Common/UI/atoms';
import { onShowToast } from 'features/common/commonSlice';

const WorkRankDateLog = ({ date = null, uuid }) => {
  const [rankDateLogs, setRankDateLogs] = useState([]);
  const [count, setCount] = useState(reload_second);
  const [checkDate, setCheckDate] = useState(date);
  const [apiId, setApiId] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    clearInterval(apiId);
    setCheckDate(date);
    getRankDateLogs(date);
  }, [date, uuid]);

  const getRankDateLogs = async (check_date = null) => {
    const infos = await requestLoadWorkRankGetDateInfos({
      date: check_date ? check_date : checkDate,
    });
    setRankDateLogs(infos);
  };
  useEffect(() => {
    clearInterval(apiId);

    if (today_str(checkDate) != today_str()) return;

    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getRankDateLogs();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);

    return () => clearInterval(intervalId);
  }, [date, uuid]);
  if (!date) return;

  return (
    <Box column>
      <Box>
        rank date [{rankDateLogs?.date}] <TextSmall>{count}</TextSmall>{' '}
        <TextButton
          onClick={async () => {
            await requestLoadWorkRankResetCanAccessDatetime();
          }}
        >
          time reset
        </TextButton>
        <TextButton
          onClick={async () => {
            const result = await requestLoadWorkRankForceUpdateRankMakeTodayLogs();
            if (result) {
              dispatch(
                onShowToast({
                  status: 'success',
                  message: `변경사항이 적용 되었습니다.`,
                  cancelText: '',
                  onCancel: false,
                }),
              );
            }
          }}
        >
          force update ranks [랭킹 키워드 강제처리]
        </TextButton>
      </Box>
      <Box column gap={20}>
        {rankDateLogs?.rank_date_logs?.map((e, index) => {
          return (
            <Box key={index}>
              <Box>
                <MallNameModule {...e?.shop_info} />
              </Box>
              <Box>
                {e?.rank === 0 ? (
                  <>
                    <TextFail>UnChecked</TextFail>
                  </>
                ) : (
                  <>
                    <RankModule rank={e?.rank} last_rank={e?.last_rank} /> [{e?.update_datetime} (
                    {e?.update_datetime_ago_hour} ago hour)]
                  </>
                )}
              </Box>
              <Box>
                {e?.search_rank_find_fail_ct > 0 ? (
                  <>
                    Rank Search Fail: <TextFail>{e?.search_rank_find_fail_ct}</TextFail>
                  </>
                ) : null}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default WorkRankDateLog;
