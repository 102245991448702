import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "./Modal";
import { cardData } from "../../molecules/Card/data";
import Card from "../../molecules/Card/Card";
import { CardWrapper } from "./styled";

function SelectCard({
    type = "policy",
    name = type,
    onClick,
    value,
    disabled = [],
    isSelected = [],
    isActive,
    ...props
}) {
    const [state, setState] = useState(cardData[name]);
    const [active, setActive] = useState(value);
    const { cardList } = state;

    const onSelect = (n) => {
        setActive(n);
        onClick && onClick(n);
    };

    useEffect(() => {
        setActive(value);
    }, [isActive]);
    return (
        <Modal
            isActive={isActive}
            size="md"
            title={state.title}
            close={false}
            {...props}
        >
            <CardWrapper>
                {cardList.map((i, idx) => {
                    return (
                        <Card
                            onClick={() => onSelect(idx)}
                            value={idx}
                            key={idx}
                            disabled={disabled.includes(idx)}
                            isSelected={
                                isSelected.includes(idx) || active === idx
                            }
                            {...i}
                        />
                    );
                })}
            </CardWrapper>
        </Modal>
    );
}

export default SelectCard;
