import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { DropList, Icon, IconButton } from "../atoms";

const More = ({
    className,
    size = "sm",
    disabled,
    isHover,
    option = [],
    onClick,
}) => {
    const [isActive, setIsActive] = useState(false);
    const ref = useRef();
    const controller = {
        eventStart: () => {
            ref.current?.addEventListener(
                "mouseout",
                () => setIsActive(false),
                true
            );
            ref.current?.addEventListener(
                "mouseover",
                () => setIsActive(true),
                true
            );
        },
        eventEnd: () => {
            ref.current?.removeEventListener(
                "mouseout",
                () => setIsActive(false),
                true
            );
            ref.current?.removeEventListener(
                "mouseover",
                () => setIsActive(true),
                true
            );
        },
    };
    const event = useRef(controller);
    useEffect(() => {
        event.current?.eventEnd();
        if (!disabled && isHover) {
            event.current = controller;
            event.current.eventStart();
        } else {
            event.current.eventEnd();
        }
    }, [disabled]);
    return (
        <Wrapper ref={ref} className={className + " more" || "more"}>
            <IconButton
                disabled={disabled}
                Icon={Icon.More}
                onClick={
                    isHover
                        ? undefined
                        : () => {
                              setIsActive(!isActive);
                              onClick && onClick();
                          }
                }
                size={size}
                Theme="unFill"
            />
            {!disabled && (
                <DropList
                    onClose={() => setIsActive(false)}
                    option={option}
                    isHover={isHover}
                    align="right"
                    parent={ref.current}
                    isActive={isActive}
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${({ isActive }) => `
    flex-direction: column;
    gap: 4rem;
    position:relative;
    .dropList{
        width:max-content;
        min-width: 180rem;
    }
    ::after{
        content:'';
        position:absolute;
        top:100%;
        width:100%;
        height:8rem;
    }
`}
`;

export default More;
