import { Box } from "Common/UI/atoms";
import DropBadgeInput from "Common/UI/molecules/DropBadgeInput";
import LabelBox from "Common/UI/molecules/LabelBox";
import React from "react";
import { Wrapper } from "./styled";

function FormDropBadgeInput({
    label,
    isEdit = true,
    size = 44,
    value,
    onChange,
    styles,
    ...props
}) {
    return (
        <Wrapper styles={styles}>
            <LabelBox label={label}>
                {isEdit ? (
                    <Box gap={8}>
                        <DropBadgeInput
                            size={size}
                            onChange={onChange}
                            value={value}
                            {...props}
                        />
                    </Box>
                ) : (
                    <p className="value">{value}</p>
                )}
            </LabelBox>
        </Wrapper>
    );
}
export default FormDropBadgeInput;
