import React from 'react';
import { Wrapper } from './styled';
import styled, { css } from 'styled-components';
function TabComponent({
  style,
  current,
  onClick,
  position = 'sticky',
  background = 'G_20',
  fontSize = 18,
  items = [],
  padding = [0, 12],
  className,
}) {
  const sizeSet = (target) => {
    const type = typeof target;
    const array = Array.isArray(target);
    let style = '';
    array
      ? target.map((i) => {
          return (style += typeof i === 'number' ? i + 'rem ' : i + ' ');
        })
      : (style += type === 'number' ? `${target}rem` : target);
    return style;
  };
  return (
    <>
      <Wrapper
        fSize={sizeSet(fontSize)}
        position={position}
        styles={style}
        bg={background}
        pd={sizeSet(padding)}
        className={className}
      >
        {items.map(({ title, value, disabled }, idx) => (
          <button
            key={idx}
            type="button"
            className={value === current ? 'active' : ''}
            // className={value?.startsWith(current) ? 'active' : ''}
            onClick={() => onClick(value)}
            disabled={disabled}
          >
            <ButtonText disabled={disabled}>{title}</ButtonText>
          </button>
        ))}
      </Wrapper>
    </>
  );
}

export default TabComponent;

const ButtonText = styled.div`
  ${(props) => {
    const { disabled } = props;
    if (!disabled) return;
    return css`
      color: var(--G_100);
      text-decoration: line-through;
      cursor: no-drop;
    `;
  }}
`;
