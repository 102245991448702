import React from "react";
import { Box, Icon } from "Common/UI/atoms";
import { Wrapper } from "./styled";

const Card = ({
    icon,
    title,
    color,
    items,
    subject,
    isActive,
    onClick,
    isSelected,
    disabled,
    value,
}) => {
    const iconType = () => {
        switch (icon) {
            case "complate":
                return <Icon.Complate full size={60} disabled={disabled} />;
            case "stemp": //문서에 도장
                return <Icon.CreateTemplate disabled={disabled} />;
            case "folder": //폴더
                return <Icon.Folder disabled={disabled} />;
            case "location": //문서
                return <Icon.Location disabled={disabled} />;
            case "transmit":
                return <Icon.Transmit disabled={disabled} />;
            case "security":
                return <Icon.Security disabled={disabled} />;
            case "quality":
                return <Icon.QualityControl disabled={disabled} />;
            default:
                break;
        }
    };

    const getCurrentIdx = (value) => {
        if (onClick) onClick(value);
    };
    return (
        <Wrapper
            onClick={() => getCurrentIdx(value)}
            isSelected={isSelected}
            disabled={disabled}
            color={color}
            value={value}
            isActive={isActive}
        >
            {iconType(icon)}
            {title && <h3>{title}</h3>}
            {subject && <span>{subject}</span>}
            {items && (
                <Box top={48} column gap={24}>
                    {items?.map((list, key) => (
                        <Box valign="top" key={key}>
                            <Icon.Check
                                size={18}
                                fill={`var(--${disabled ? "G_200" : "P_500"})`}
                            />
                            <p>{list}</p>
                        </Box>
                    ))}
                </Box>
            )}
        </Wrapper>
    );
};

export default Card;
