import { Box, Button, Icon, DropBox, IconButton } from 'Common/UI/atoms';
import CheckedController from 'Common/UI/molecules/CheckedController';
import PaginationComponent from 'Common/UI/molecules/Pagination/PaginationComponent';
import React from 'react';

function TableComponent({
  onSearch = () => {},
  isController = true,
  isInterval = true,
  checkList,
  allCheckbox = true,
  allChecked,
  onDownload,
  downloadText,
  isHeaad = true,
  deleteText,
  controllerBtns,
  tableBtns,
  onDelete,
  resetChecked,
  children,
  searchText = '검색 및 필터',
  btnSize = 'xl',
  pages,
  onChangeInterval,
  onSet,
}) {
  const pageOption = [30, 50, 70, 100]?.map((i) => ({
    title: i + '개',
    value: i,
    selected: !pages?.pageView || pages?.pageView === i,
  }));

  return (
    <>
      {isHeaad && (
        <Box align="top" bottom="0px">
          <Box gap={12}>
            {allCheckbox && (
              <Button onClick={(e) => allChecked(e)} Theme={'unLine'} size={btnSize}>
                {checkList?.length ? '전체해제' : '전체선택'}
              </Button>
            )}
            {isController && !!checkList.length && (
              <CheckedController
                idx={checkList.length}
                onDownload={onDownload}
                downloadText={downloadText}
                deleteText={deleteText}
                addBtn={controllerBtns}
                onDelete={onDelete}
                notChecked={resetChecked}
              />
            )}
          </Box>
          <Box gap="8px">
            {tableBtns}
            {isInterval && (
              <DropBox
                onChange={(_, n) => onChangeInterval(Number(n))}
                size={'48'}
                option={pageOption}
              />
            )}
            {onSearch && (
              <Button onClick={onSearch} Theme={'unLine'} size={btnSize}>
                {searchText}
              </Button>
            )}
            {onSet && (
              <IconButton Theme={'unLine'} size={'xl'} onClick={onSet} Icon={Icon.Setting} />
            )}
          </Box>
        </Box>
      )}

      {children}
      {pages && <PaginationComponent {...pages} />}
    </>
  );
}

export default TableComponent;
