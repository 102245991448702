import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useMutations from 'Common/Service/commonMutations';
import { numberSum } from 'Common/Utils';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { Button, InputComponent } from 'Common/UI/atoms';
import { requestLoadSlotSet } from 'Common/Service/slot';
import { useDispatch } from 'react-redux';
import { onShowToast } from 'features/common/commonSlice';
import { useParams } from 'react-router-dom';

const AnalyticsVmwareLatelyLogs = () => {
  const [result, setResults] = useState({});
  const [clickCount, setClickCount] = useState(0);

  const [vmwareLogs, setVmwareLogs] = useState();
  const dispatch = useDispatch();
  const { requestLoadVmwareLatelyLogsMutation } = useMutations(setResults);
  const { vmware_name = 't1_n1' } = useParams();
  // const slotSet = async ({ product_id, fields }) => {
  //   const result = await requestLoadSlotSet({
  //     product_id,
  //     fields,
  //   });
  //   if (result) {
  //     dispatch(
  //       onShowToast({
  //         status: 'success',
  //         message: `정보가 변경되었습니다`,
  //         cancelText: '',
  //         onCancel: false,
  //       }),
  //     );
  //   } else {
  //     dispatch(
  //       onShowToast({
  //         status: 'error',
  //         message: `오류가 발생했습니다`,
  //         cancelText: '',
  //         onCancel: false,
  //       }),
  //     );
  //   }
  //   requestLoadVmwareLatelyLogsMutation.mutate({ product_id });

  //   // await reloadSlotList();
  // };
  useEffect(() => {
    const now = new Date(); // 현재 시간
    const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000; // 현재 시간을 utc로 변환한 밀리세컨드값
    const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
    const koreaNow = new Date(utcNow + koreaTimeDiff); // utc로 변환된 값을 한국 시간으로 변환시키기 위해 9시간(밀리세컨드)를 더함
    // 위 코드에서 koreaNow 는 사용자의 시간대가 어떻게 설정되어 있더라도 한국 시간을 보여주게 된다.

    // setNowHourN(koreaNow.getHours());
    // setNowMinN(koreaNow.getMinutes());
    if (result?.request === 'requestLoadVmwareLatelyLogsMutation') {
      if (result?.success && result?.result !== undefined) {
        result?.shop_data?.map((e) => {});
        const data = result?.result;
        const click_logs = data?.click_logs;
        const shop_info = data?.shop_info;
        setVmwareLogs(data);
        // setShopInfo(shop_info);
        // setClickLogs(click_logs);

        // var list = shop_info?.keywords?.split(':')?.filter((e) => e !== '');
        // if (!list) list = [];
        // setKeywords(list);

        // var count = 0;
        // list?.forEach((e) => (count += e?.shop_click_count));
        // setClickCount(count);
      }
      setTimeout(() => {
        // requestLoadShopLinkLatelyClickLogsMutation.mutate({ vmware_names });
      }, 5000);
    }

    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadVmwareLatelyLogsMutation.mutate({ vmware_name });
  }, []);
  return (
    <>
      <Box column>
        <h1>{vmware_name}</h1>
        {vmwareLogs?.map((i) => {
          return (
            <Box>
              <StatusText>
                <span>{i.do2}</span>
              </StatusText>
              <StatusText>{i.do3}</StatusText>
              <StatusText>{i.do4}</StatusText>
              <StatusText>
                <small>{i.datetime}</small>
              </StatusText>
            </Box>
          );
        })}
      </Box>
      {/* </Block> */}
    </>
  );
};
const KeywordText = styled.div`
  font-size: 10rem;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return `
      color: #fff;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 
      background-color: #bc340b;
          
      `;
  }}
`;
export default AnalyticsVmwareLatelyLogs;
