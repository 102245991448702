import styled from 'styled-components';

export const Wrapper = styled.div`
  ${(props) => {
    const { styles, fSize, position, bg, pd, disabled } = props;
    return `
        padding:${pd};
        height:60rem;
        position:${position};
        top:80rem;
        ::after{
            content:'';
            position:absolute;
            width:100%;
            height:1rem;
            left:0;
            bottom:0;
            background-color:var(--G_150);
        }
        ::before{
            content:'';
            position:absolute;
            width:calc(100% + 10rem);
            height:80rem;
            z-index:-1;
            left:-5rem;
            background-color:var(--${bg});
        }
        z-index:2;
        button {
            padding:18rem 12rem 19rem;
            font-weight: 400;
            font-size: ${fSize};
            line-height: 23rem;
            letter-spacing: -0.04em;
            color: var(--G_300);
            position:relative;
            &:not(:disabled)::after{
                content:'';
                position:absolute;
                left:0;
                bottom:0rem;
                width:100%;
                height:2rem;
                border-radius:100vw;
                z-index:1;
            }
            &:hover{
                color:var(--P_700);
                &::after{
                    background-color:var(--P_700);
                }
            }
            + button {
                margin-left:20rem;
            }
            &:focus{
                color:var(--P_800);
                &::after{
                    background-color:var(--P_800);
                }
            }
            &.active {
                font-weight: 500;
                line-height: 23rem;
                color: var(--P_500);
                &::after{
                    background-color:var(--P_500);
                }
            }
            :disabled{
                cursor: no-drop;
                color:var(--G_150);
            }
        }
        ${styles || ''};
        `;
  }}
`;
