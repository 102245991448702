import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadSetBasicInfo,
  requestLoadWorkVmwareFInfos,
  requestLoadWorkVmwareGetSetupInfos,
  requestLoadWorkVmwareSetSetupInfos,
} from 'Common/Service/common';
import {
  TextSmall,
  TextSucceed,
  reload_second,
  to_integer,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { Input } from 'Common/UI/atoms/Button';
import ToggleButton from 'Page/Module/CheckToggleButton';
import styled from 'styled-components';
import { updateLocale } from 'moment';
import { onShowToast } from 'features/common/commonSlice';
const WorkVmwareSetupInputModule = ({ vmware_info, onClick, is_help_show = null }) => {
  const forceUpdate = useForceUpdate();

  return (
    <Box left={10} column>
      {onClick && (
        <Box>
          <b>{vmware_info?.vmware_name}</b>
          <Button
            size="xxs"
            Theme={'unLine'}
            onClick={() => {
              if (onClick) onClick();
            }}
          >
            vmware [{vmware_info?.vmware_name}] 개별 설정
          </Button>
        </Box>
      )}
      <Box left={10} column>
        <Box column>
          {vmware_info?.vmware_name && (
            <Box>
              <Box>기타 설명</Box>
              <Box width={700}>
                <InputComponent
                  maxLength={200}
                  isMax
                  iconSize={10}
                  fontSize={10}
                  padding={[5, 15]}
                  size={20}
                  value={vmware_info?.vmware_admin_status}
                  onChange={(e) => {
                    vmware_info.vmware_admin_status = e?.target?.value;
                    forceUpdate();
                  }}
                />
              </Box>
            </Box>
          )}

          <Box>유입 기본 설정</Box>
          <Box left={10} column>
            <SetupBox>
              <Box>
                CMD 자동 실행 갯수:{' '}
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_multi_run_num?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_multi_run_num = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>
                개
              </Box>
              {is_help_show && (
                <HelpStatus>만약 0이거나 공백이면 프로그램 기본 설정을 따름</HelpStatus>
              )}
            </SetupBox>
            <SetupBox>
              <Box>
                유입 진행 횟수:{' '}
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_start_etc_do1?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_start_etc_do1 = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>
                ~
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_end_etc_do1?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_end_etc_do1 = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
              {is_help_show && (
                <HelpStatus>
                  브라우져당 유입 진행 횟수 [ex: 3~3 일 경우 3개의 상품을 유입 시도함]
                </HelpStatus>
              )}
            </SetupBox>
            <SetupBox>
              <Box>
                유입 실패시 종료
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_stop_count_start_after_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_stop_count_start_after_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>
                ~
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_stop_count_end_after_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_stop_count_end_after_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
              {is_help_show && (
                <HelpStatus>
                  유입 실패시에 종료하고 다음 브라우져 활동 시작 [ex: 1~1 일 경우 상품 유입 시도 1회
                  실패시 바로 종료]
                </HelpStatus>
              )}
            </SetupBox>
            <Box column>
              <SetupBox>
                <Box>
                  <h1>URL 엑세스 시간 제한</h1>
                  <Box width={100}>
                    <InputComponent
                      iconSize={10}
                      fontSize={10}
                      padding={[5, 15]}
                      size={20}
                      value={vmware_info?.setup_network_timeout_sec_limit?.toString()}
                      onChange={(e) => {
                        vmware_info.setup_network_timeout_sec_limit = to_integer(e?.target?.value);
                        forceUpdate();
                      }}
                    />
                    초
                  </Box>
                </Box>
                {is_help_show && (
                  <HelpStatus>
                    URL 엑세스 시도시 완료까지 기다리는 시간
                    <br />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<font color='blue'>수치가 작을 경우</font> 연결[프록시 속도 및 컴퓨터 속도 총괄포함] 이 느릴 경우 성공할 시도도 실패로 간주할 확률이 증가`,
                      }}
                    />
                    <br />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `<font color='red'>수치가 클 경우</font> 해당 프록시가 사용 못하거나 연결 자체가 끊길 경우 빠른 전환이 힘듬으로 전체 속도가 저하됨`,
                      }}
                    />
                  </HelpStatus>
                )}
              </SetupBox>
            </Box>
          </Box>
        </Box>
        <Box column>
          <Box>유입 파트별 재시도 설정</Box>
          <Box left={10} column>
            <SetupBox>
              <Box>
                검색 실패시 재시도{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_start_search_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_start_search_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_end_search_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_end_search_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
              {is_help_show && <HelpStatus>통합 및 쇼핑 검색 실패시 재 시도할 횟수</HelpStatus>}
            </SetupBox>
            <SetupBox>
              <Box>
                클릭 실패시 재시도{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_start_click_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_start_click_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_end_click_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_end_click_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
              {is_help_show && <HelpStatus>상품 클릭시 실패시 재 시도할 횟수</HelpStatus>}
            </SetupBox>
            <SetupBox>
              <Box>
                유입 관련 활동 실패시 재시도
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_start_etc_do1_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_start_etc_do1_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_end_etc_do1_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_end_etc_do1_fail = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
              {is_help_show && (
                <HelpStatus>
                  상품 추천 및 상품 리스트 가져오기 등의 유입 동작 실패시 재 시도할 횟수
                </HelpStatus>
              )}
            </SetupBox>
            <SetupBox>
              <Box>
                상품 진입 실패시 재시도{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_start_into_product_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_start_into_product_fail = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_try_count_end_into_product_fail?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_try_count_end_into_product_fail = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
              {is_help_show && (
                <HelpStatus>모든 유입 활동후에 상품 최종 진입 실패시 재 시도할 횟수</HelpStatus>
              )}
            </SetupBox>
          </Box>
        </Box>
        <Box column>
          <Box>활동 관련</Box>
          <Box left={10} column>
            <SetupBox>
              <Box>
                브라우져당 처음 접속시 활동 시작할 확률{' '}
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_active_percent_before_start?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_active_percent_before_start = to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                %
              </Box>
              {is_help_show && (
                <HelpStatus>브라우저가 처음 접속시 유입 시작 전에 활동할 확률</HelpStatus>
              )}
            </SetupBox>
            <SetupBox>
              <Box>
                브라우져당 처음 접속시 활동할 횟수
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_active_count_start_before_start?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_active_count_start_before_start = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_active_count_end_before_start?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_active_count_end_before_start = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
            </SetupBox>
            <SetupBox>
              <Box>
                브라우져당 처음 접속시 활동후에 대기 시간
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_delay_start_after_active_before_start?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_delay_start_after_active_before_start = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_delay_end_after_active_before_start?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_delay_end_after_active_before_start = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                초
              </Box>
            </SetupBox>
            <SetupBox>
              <Box>
                유입 시도 전 활동할 확률
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_active_percent_before_etc_do1?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_active_percent_before_etc_do1 = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>
                %
              </Box>
              {is_help_show && (
                <HelpStatus>
                  브라우저가 처음 시작시 활동한 직 후라도 확률에 따라서 활동 시작함
                </HelpStatus>
              )}
            </SetupBox>
            <SetupBox>
              <Box>
                유입 시도 전 활동할 횟수{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_active_count_start_before_etc_do1?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_active_count_start_before_etc_do1 = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_active_count_end_before_etc_do1?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_active_count_end_before_etc_do1 = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                회
              </Box>
            </SetupBox>
            <SetupBox>
              <Box>
                유입 시도 전 활동후 대기 시간{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_delay_sec_start_after_active_before_etc_do1?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_delay_sec_start_after_active_before_etc_do1 = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                ~{' '}
                <Box width={80}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_delay_sec_end_after_active_before_etc_do1?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_delay_sec_end_after_active_before_etc_do1 = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>{' '}
                초
              </Box>
            </SetupBox>
          </Box>
        </Box>
        <Box column>
          <Box>유입 제제 관련 설정</Box>
          <Box left={10} column>
            <SetupBox>
              <Box>
                <h1>URL 엑세스중 제제 발견시 시도 횟수</h1>
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_problem_access_try_per_url_count_start_limit?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_problem_access_try_per_url_count_start_limit = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                </Box>
                ~
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_problem_access_try_per_url_count_end_limit?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_problem_access_try_per_url_count_end_limit = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                  회
                </Box>
              </Box>
              {is_help_show && (
                <HelpStatus>
                  URL 엑세스 시도중 제제를 만났을 경우 몇회까지 재시도 할지 정함
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<font color='blue'>수치가 작을 경우</font> 쿠키 및 해당 국가 아이피로 유입 성공을 못할 경우 빠른 전환이 가능함 [단점: 성공이 가능한 상황에서도 포기를 함으로 확률이 줄어듬]`,
                    }}
                  />
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<font color='red'>수치가 클 경우</font> 많은 시도로 인해 유입 성공 확률이 증가함 [단점: 오랜 시도 끝에 실패할 경우 전반적은 유입 속도가 굉장히 느려짐]`,
                    }}
                  />
                </HelpStatus>
              )}
            </SetupBox>
            <SetupBox>
              <Box>
                <h1>URL 엑세스중 제제 시도중 아이피 변경 횟수</h1>
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_problem_ip_per_change_count_start_per_url_count?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_problem_ip_per_change_count_start_per_url_count =
                        to_integer(e?.target?.value);
                      forceUpdate();
                    }}
                  />
                </Box>
                ~
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_problem_ip_per_change_count_end_per_url_count?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_problem_ip_per_change_count_end_per_url_count = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                  회
                </Box>
              </Box>
              {is_help_show && (
                <HelpStatus>
                  제제 발견시 아이피 변경 관련을 정함
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<font color='blue'>수치가 작을 경우</font> 다양한 아이피 시도로 성공 확률을 높임 [단점: 새로고침을 여러번 해야 통과될 경우 잦은 아이피 변경으로 성공 확률 매우 감소될 위험 존재]`,
                    }}
                  />
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<font color='red'>수치가 클 경우</font> 사용 가능한 IP가 할당시 URL 엑세스 재시도 (새로고침)시 바로 통과될 경우 성공 확률 올라감 [단점: 제제 아이피를 지속적 시도로 속도 저하가 발생함]`,
                    }}
                  />
                </HelpStatus>
              )}
            </SetupBox>
            <SetupBox>
              <Box>
                한 상품에 대한 모든 유입 관련 URL 엑세스 시도시에 제제 발견시 종료할 기준{' '}
                <Box width={100}>
                  <InputComponent
                    iconSize={10}
                    fontSize={10}
                    padding={[5, 15]}
                    size={20}
                    value={vmware_info?.setup_all_visible_problem_limit_count?.toString()}
                    onChange={(e) => {
                      vmware_info.setup_all_visible_problem_limit_count = to_integer(
                        e?.target?.value,
                      );
                      forceUpdate();
                    }}
                  />
                  회
                </Box>
              </Box>

              {is_help_show && (
                <HelpStatus>
                  모든 동작의 제제 발견 횟수 누적 개념
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<font color='blue'>수치가 작을 경우</font> 해당 브라우져[쿠키]값의 사용이 힘들 경우 특정 기준 성공 가능한 다른 브라우져와 새로운 아이피로 재시도함`,
                    }}
                  />
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<font color='red'>수치가 클 경우</font> 충분한 시도로 유입 활동 성공 확률이 올라감 [단점: 최종 실패시 수많은 시도로 속도가 느려짐]`,
                    }}
                  />
                </HelpStatus>
              )}
            </SetupBox>
          </Box>
        </Box>

        <Box>프로그램 기본 설정</Box>
        <Box left={10} column>
          <SetupBox>사용 브라우져: {vmware_info?.setup_etc_do1_worker_browser_types} </SetupBox>
          <SetupBox>
            <Box gap={24}>
              <Box>Proxy: {vmware_info?.setup_proxy_company} </Box>
              <Box>
                <CheckBox
                  onChange={() => {
                    vmware_info.setup_proxy_ip_from_cmd_server =
                      !vmware_info.setup_proxy_ip_from_cmd_server;
                    forceUpdate();
                  }}
                  checked={vmware_info?.setup_proxy_ip_from_cmd_server}
                >
                  CMD 서버에서 IP 받아오기
                </CheckBox>
              </Box>
            </Box>
          </SetupBox>
          <SetupBox>
            <Box>
              네트워크 엑세스 라이브러리
              <Box width={200}>
                <CheckBox
                  type="radio"
                  onChange={() => {
                    vmware_info.setup_proxy_https_access_adapter = 'typhoeus';
                    forceUpdate();
                  }}
                  checked={vmware_info?.setup_proxy_https_access_adapter === 'typhoeus'}
                >
                  루비
                </CheckBox>
                <CheckBox
                  type="radio"
                  onChange={() => {
                    vmware_info.setup_proxy_https_access_adapter = 'ps_support';
                    forceUpdate();
                  }}
                  // checked={false}
                  checked={vmware_info?.setup_proxy_https_access_adapter === 'ps_support'}
                >
                  파워쉘
                </CheckBox>

                <CheckBox
                  onChange={() => {
                    vmware_info.setup_proxy_https_access_adapter = 'curl';
                    forceUpdate();
                  }}
                  type="radio"
                  checked={vmware_info?.setup_proxy_https_access_adapter === 'curl'}
                >
                  CUrl
                </CheckBox>
              </Box>
            </Box>
            {is_help_show && (
              <HelpStatus>
                임의 변경: 아이피 변경시 프록시 주소를 랜덤하게 변경 [단점: 프록시 국가 랜덤, 없는
                아이피 확률 있음], 정밀 변경: 서버 송수신으로 실제 존재하는 프록시 [단점: 서버에
                무리를 줌] 주소로 변경
              </HelpStatus>
            )}
          </SetupBox>
          <SetupBox>
            <Box>
              아이피 변경 방식
              <Box width={150}>
                <ToggleButton
                  active={vmware_info?.setup_proxy_change_rule === 'random'}
                  onClick={() => {
                    const rule =
                      vmware_info?.setup_proxy_change_rule === 'random' ? 'static' : 'random';
                    vmware_info.setup_proxy_change_rule = rule;
                    forceUpdate();
                  }}
                >
                  {vmware_info?.setup_proxy_change_rule === 'random' ? '임의 변경' : '정밀 변경'}
                </ToggleButton>
              </Box>
            </Box>
            {is_help_show && (
              <HelpStatus>
                임의 변경: 아이피 변경시 프록시 주소를 랜덤하게 변경 [단점: 프록시 국가 랜덤, 없는
                아이피 확률 있음], 정밀 변경: 서버 송수신으로 실제 존재하는 프록시 [단점: 서버에
                무리를 줌] 주소로 변경
              </HelpStatus>
            )}
          </SetupBox>
        </Box>
      </Box>
    </Box>
  );
};
const WorkVmwareSetup = () => {
  const [workstationInfos, setWorkstationInfos] = useState([]);
  const [pyproxyInfos, setPyproxyInfos] = useState([]);
  const [basicDefaultSetupInfo, setBasicDefaultSetupInfo] = useState();
  const [isShowbasicDefaultSetupInfo, setIsShowbasicDefaultSetupInfo] = useState(true);
  const [vmwarename, setVmwarename] = useState();
  const [vmwareInfos, setVmwareInfos] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  const [apiId, setApiId] = useState();
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const getWorkInfos = async () => {
    const workstation_infos = await requestLoadWorkVmwareGetSetupInfos();
    setWorkstationInfos(workstation_infos?.list);
    setPyproxyInfos(workstation_infos?.pyproxy_country_infos);
    setBasicDefaultSetupInfo(workstation_infos?.basic_default_setup_info);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    // const intervalId = setInterval(() => {
    //   setCount((prevCount) => {
    //     var ct = prevCount - 1;
    //     if (ct < 0) {
    //       getWorkInfos();
    //       ct = reload_second;
    //     }
    //     return ct;
    //   });
    // }, 1000);
    // setApiId(intervalId);
    // return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Box column>
      <Box column top={10}>
        <Box>
          기본 설정{' '}
          <CheckBox
            checked={isShowbasicDefaultSetupInfo}
            onChange={() => {
              setIsShowbasicDefaultSetupInfo(!isShowbasicDefaultSetupInfo);
            }}
          >
            설정 보기
          </CheckBox>
          [신규 생성 vmware 설정도 이 설정대로 적용 됨]
        </Box>

        <Box>
          <Button
            //   disabled={!workstation_info?.is_show_default_setup}
            Theme={'unLine'}
            size="xxs"
            onClick={async () => {
              var count = 0;

              const infos = workstationInfos.map((workstation_info) => {
                workstation_info.default_setup_info = {
                  ...workstation_info.default_setup_info,
                  ...basicDefaultSetupInfo,
                };
                count += 1;
                return workstation_info;
              });
              setWorkstationInfos(infos);
              dispatch(
                onShowToast({
                  status: 'success',
                  message: `컴퓨터 ${count}개의 기본 설정이 일괄 입력 되었습니다.`,
                  cancelText: '',
                  onCancel: false,
                }),
              );
            }}
          >
            모든 컴퓨터(기본 설정) 일괄 값 입력 (값만 넣음)
          </Button>
          <Button
            //   disabled={!workstation_info?.is_show_default_setup}
            Theme={'unLine'}
            size="xxs"
            onClick={async () => {
              var count = 0,
                vmware_count = 0;

              const infos = workstationInfos.map((workstation_info) => {
                count += 1;
                workstation_info.list = workstation_info.list.map((vmware_info) => {
                  vmware_count += 1;
                  return { ...vmware_info, ...basicDefaultSetupInfo };
                });
                workstation_info.default_setup_info = {
                  ...workstation_info.default_setup_info,
                  ...basicDefaultSetupInfo,
                };
                return workstation_info;
              });
              setWorkstationInfos(infos);
              dispatch(
                onShowToast({
                  status: 'success',
                  message: `컴퓨터 ${count}개의 기본 설정과 vmwares ${vmware_count}개의 설정이 일괄 입력 되었습니다.`,
                  cancelText: '',
                  onCancel: false,
                }),
              );
            }}
          >
            모든 컴퓨터(기본 설정) 및 vmwares 일괄 값 입력 (값만 넣음)
          </Button>

          <Button
            //   disabled={!workstation_info?.is_show_default_setup}
            size="xxs"
            onClick={async () => {
              const result = await requestLoadSetBasicInfo({
                fields: basicDefaultSetupInfo,
              });

              if (result) {
                dispatch(
                  onShowToast({
                    status: 'success',
                    message: `기본 설정이 변경 되었습니다.`,
                    cancelText: '',
                    onCancel: false,
                  }),
                );
              } else {
                dispatch(
                  onShowToast({
                    status: 'error',
                    message: `오류가 발생했습니다`,
                    cancelText: '',
                    onCancel: false,
                  }),
                );
              }
            }}
          >
            기본 설정 변경
          </Button>
        </Box>
        {isShowbasicDefaultSetupInfo && (
          <SetupInfoDefaultBlock>
            <WorkVmwareSetupInputModule is_help_show={true} vmware_info={basicDefaultSetupInfo} />
          </SetupInfoDefaultBlock>
        )}
      </Box>
      {workstationInfos?.map((workstation_info, index) => {
        return (
          <Block key={index}>
            <Box>
              <h2>
                {workstation_info?.workstation_name} ({workstation_info?.count}){' '}
              </h2>
              <CheckBox
                checked={workstation_info?.is_show_default_setup}
                onChange={() => {
                  workstation_info.is_show_default_setup = !workstation_info?.is_show_default_setup;
                  forceUpdate();
                }}
              >
                기본 세팅 보기
              </CheckBox>
              <Button
                disabled={!workstation_info?.is_show_default_setup}
                Theme={'unLine'}
                size="xxs"
                onClick={() => {
                  var vmware_count = 0;
                  workstation_info.list = workstation_info?.list?.map((vmware_info) => {
                    // workstation_info?.default_setup_info;
                    vmware_count += 1;
                    return { ...vmware_info, ...workstation_info?.default_setup_info };
                  });
                  forceUpdate();
                  dispatch(
                    onShowToast({
                      status: 'success',
                      message: `vmwares ${vmware_count}개의 설정이 일괄 입력 되었습니다.`,
                      cancelText: '',
                      onCancel: false,
                    }),
                  );
                }}
              >
                하위 vmwares 일괄 값 입력 (값만 넣음)
              </Button>{' '}
              <Button
                onClick={async () => {
                  const workstation_name = workstation_info?.workstation_name;
                  const result = await requestLoadWorkVmwareSetSetupInfos({
                    workstation_name,
                    fields: workstation_info?.default_setup_info,
                  });

                  if (result) {
                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${workstation_name} 컴퓨터 vmwares ${workstation_info?.count}개의 기본 세팅대로 일괄 적용이 완료 되었습니다.`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  } else {
                    dispatch(
                      onShowToast({
                        status: 'error',
                        message: `오류가 발생했습니다`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }
                }}
                disabled={!workstation_info?.is_show_default_setup}
                size="xxs"
              >
                컴퓨터 변경 사항일괄 적용
              </Button>
            </Box>
            {workstation_info.is_show_default_setup && (
              <SetupInfoDefaultBlock>
                <Box>
                  <TextSucceed>[{workstation_info?.workstation_name}] 기본 세팅값 </TextSucceed>
                  <TextSmall>컴퓨터의 기본 세팅값은 vmwares의 첫번째 세팅값을 가져옴</TextSmall>
                </Box>
                <WorkVmwareSetupInputModule
                  is_help_show={true}
                  vmware_info={workstation_info?.default_setup_info}
                />
              </SetupInfoDefaultBlock>
            )}
            <Box>
              Vmwares {workstation_info?.list?.length}개{' '}
              <TextButton
                onClick={() => {
                  workstation_info.is_show_list = !workstation_info.is_show_list;
                  forceUpdate();
                }}
              >
                {workstation_info.is_show_list ? '숨기기' : '모두 보기'}
              </TextButton>
            </Box>
            {workstation_info.is_show_list && (
              <Box left={10} column>
                {workstation_info?.list?.map((vmware_info, index2) => {
                  return (
                    <VmwareBlock>
                      <WorkVmwareSetupInputModule
                        key={index2}
                        onClick={async () => {
                          const vmware_names = [vmware_info?.vmware_name];
                          const result = requestLoadWorkVmwareSetSetupInfos({
                            vmware_names,
                            fields: vmware_info,
                          });
                          if (result) {
                            dispatch(
                              onShowToast({
                                status: 'success',
                                message: `vmware ${vmware_info?.vmware_name} 의 세팅이 개별 적용 되었습니다.`,
                                cancelText: '',
                                onCancel: false,
                              }),
                            );
                          } else {
                            dispatch(
                              onShowToast({
                                status: 'error',
                                message: `오류가 발생했습니다`,
                                cancelText: '',
                                onCancel: false,
                              }),
                            );
                          }
                        }}
                        is_help_show={false}
                        vmware_info={vmware_info}
                      />
                    </VmwareBlock>
                  );
                })}
              </Box>
            )}
          </Block>
        );
      })}
    </Box>
  );
};

const SetupBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  /* gap: 10rem; */
`;
const HelpStatus = styled.div`
  font-size: 11rem;
  font-weight: 400;
  color: #0000a5;
`;
const SetupInfoDefaultBlock = styled.div`
  padding: 10rem 0rem 10rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  margin-bottom: 10rem;

  border-width: 1rem;
  border-style: solid;
  /* border-color: transparent; */
  /* &:hover { */
  /* background-color: #f0f0f0; */
  border-color: blue;
  /* } */
`;
const VmwareBlock = styled.div`
  padding: 10rem 0rem 0rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  &:hover {
    background-color: #f0f0f0;
    border-color: blue;
  }
`;
const Block = styled.div`
  font-size: 14rem;
  font-weight: 400;

  h1 {
    color: #f00000;
    font-weight: 600;
  }
  h2 {
    margin-right: 10rem;
    color: blue;
    font-size: 18rem;
    font-weight: 600;
  }
  b {
    margin-right: 10rem;
    color: #1010a0;
    font-size: 16rem;
    font-weight: 600;
  }
`;

export default WorkVmwareSetup;
