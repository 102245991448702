import { Box } from 'Common/UI/atoms';
import {
  DatepickerCompoent,
  MonthpickerCompoent,
  MultipleDatepickerCompoent,
  MultipleMonthDatepickerCompoent,
  RangepickerCompoent,
  TimepickerCompoent,
} from 'Common/UI/molecules/Datepicker';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { formatDate, viewDay } from 'Common/Utils/date';
import React from 'react';
import { Wrapper } from './styled';

function FormDatePicker({
  label,
  isEdit = true,
  value,
  size = 44,
  type = 'defualt',
  row = false,
  onlyTime,
  className,
  ...props
}) {
  const picker = setDatePicker({ ...props, value, size });

  return (
    <Wrapper className="picker">
      <LabelBox label={label} row={row} className={className}>
        {isEdit ? (
          <Box gap={8} style={`flex: 1;>*{flex:1;}`}>
            {picker[type]}
          </Box>
        ) : onlyTime ? (
          formatDate(new Date(value)).dateTime
        ) : (
          <p className="value">
            {value
              ? value?.start
                ? viewDay(value?.start) + '~' + viewDay(value?.end)
                : formatDate(new Date(value)).completeDate
              : '-'}
            {props.isTime && ` ${formatDate(new Date(value)).dateTime}`}
          </p>
        )}
      </LabelBox>
    </Wrapper>
  );
}

const setDatePicker = ({ ...props }) => {
  return {
    defualt: <DatepickerCompoent {...props} />,
    month: <MonthpickerCompoent {...props} />,
    multiple: <MultipleDatepickerCompoent {...props} />,
    multipleMonth: <MultipleMonthDatepickerCompoent {...props} />,
    range: <RangepickerCompoent {...props} />,
    time: <TimepickerCompoent {...props} />,
  };
};
export default FormDatePicker;
