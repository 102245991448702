import { requestLoadSlotSet } from 'Common/Service/slot';
import useMutations from 'Common/Service/slotMutations';
import {
  requestLoadSlotGroupGetNames,
  requestLoadUserGetNames,
  requestLoadUserNameCheck,
} from 'Common/Service/user';
import { Button, DropBox, Grid, Icon, InputComponent, Tooltip } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { FormInput } from 'Common/UI/organisms/FormControl';
import { debounce, getNumbers, getRandomAlphabet, random, today_str } from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const ProductCancel = () => {
  const dispatch = useDispatch();

  const [result, setResults] = useState({});

  const [slotAddInfos, setSlotAddInfos] = useState([]);
  const [slotIds, setSlotIds] = useState([]);
  const [slotAddCountMax, setSlotAddCountMax] = useState(0);

  const [selectedSlotGroupName, setSelectedSlotGroupName] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const [selectedPartnerName, setSelectedPartnerName] = useState();
  const [partnerNames, setPartnerNames] = useState([
    { title: '전체', value: null, selected: true },
  ]);
  const [slotGroupNames, setSlotGroupNames] = useState([
    { title: '신규', value: null, selected: true },
    // { title: '미분류', value: 'none', selected: true },
  ]);
  const [isUserNameCheck, setIsUserNameCheck] = useState(null);
  const [isUserNameMessage, setIsUserNameMessage] = useState();
  // const [userNames, setUserNames] = useState([{ title: '미분류', value: 'none', selected: true }]);
  const [userNames, setUserNames] = useState([
    // { title: '신규', value: null, selected: true }
  ]);
  const [productIds, setProductIds] = useState();
  const [modal, setModal] = useState({
    title: '',
    successText: '삭제',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, idsActive: false });
    },
  });

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1)),
  );
  const { loginInfo } = useSelector((state) => state.common);
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));

  const { requestLoadSlotAddMutation, requestLoadPartnerGetNamesMutation } =
    useMutations(setResults);
  const slotSet = async ({ id = null, product_ids = null, product_id = null, fields }) => {
    const result = await requestLoadSlotSet({
      id,
      product_ids,
      product_id,
      fields,
    });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `${result}개의 정보가 변경되었습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  if (loginInfo?.user_level <= 3) return <></>;

  return (
    <>
      <Grid column={['1fr', '1fr']} gap={12}>
        <Box valign="top" column>
          <LabelBox label={'상품번호'}>
            <InputComponent
              size={350}
              isEdit
              el="textarea"
              name="id"
              placeholder={''}
              value={productIds}
              onChange={(e) => {
                setProductIds(e.target.value);
              }}
            />
            <Box top={8}>
              <Box width="100%">
                <Button
                  fill
                  Theme={'unFill'}
                  disabled={!getNumbers(productIds)}
                  onClick={() => {
                    const product_ids = productIds
                      ?.split('\n')
                      ?.filter((e) => e?.trim()?.length >= 1);
                    if (!product_ids || product_ids?.length <= 0) {
                      setProductIds('');
                      return;
                    }
                    const product_count = product_ids?.length;
                    setModal({
                      ...modal,
                      title: '강제 중지',
                      text: `[${product_count}] 개의 상품을 중지 하시겠습니까?`,
                      successText: '중지',
                      isActive: true,
                      cancelText: '취소',
                      onSuccess: () => {
                        const fields = {
                          temp_stop_chk: true,
                        };
                        slotSet({ product_ids, fields });
                      },
                    });
                  }}
                >
                  일괄 중지
                </Button>
              </Box>
            </Box>
          </LabelBox>
        </Box>
        <Box column align="top">
          <>
            {slotAddInfos?.map((e, idx) => {
              const status = e.status;
              const message = e.message;
              const product_id = e.product_id;
              return (
                <SlotBlock key={idx}>
                  <SlotNumStatus>{idx + 1}</SlotNumStatus>
                  <SlotIdDiv>{product_id}</SlotIdDiv>
                  <SlotStatus>
                    {status === 'ok' ? (
                      <>
                        <Icon.Complate size={20} /> {message}
                      </>
                    ) : status === 'duplication' ? (
                      <>
                        <Icon.Warning size={20} /> {message}
                      </>
                    ) : status === 'fail' ? (
                      <>
                        <Icon.Danger size={20} /> {message}
                      </>
                    ) : (
                      <>
                        <Icon.Watch size={20} /> 등록 대기 중
                      </>
                    )}
                  </SlotStatus>
                </SlotBlock>
              );
            })}
          </>
        </Box>
      </Grid>
      <ModalQuestion {...modal} />
    </>
  );
};

const SlotBlock = styled.div`
  display: flex;
`;
const SlotNumStatus = styled.div`
  display: flex;
  border: 1rem;
  width: 50rem;
  font-size: 20rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`;
const SlotIdDiv = styled.div`
  display: flex;
  flex: 1;
  border: 1rem;
  font-size: 20rem;
  font-weight: 500;
  justify-content: center;
  border-style: solid;
  border-width: 1rem;
  align-items: center;
  background-color: #d5dee8;
  border-radius: 12rem;
`;
const SlotStatus = styled.div`
  font-size: 18rem;
  font-weight: 400;
  padding-left: 10rem;
  display: flex;
  gap: 8rem;
  flex: 3;
  align-items: center;
`;

export default ProductCancel;
