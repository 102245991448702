import { Box, Grid, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestLoadWorkVmwareFNumInfos } from 'Common/Service/common';
import { currencyComma, get_percent, navigate_newtab } from 'Common/Utils';
import PercentModule from './Module/PercentModule';
import { TextFail, TextSmall, TextSucceed, reload_second } from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TextLengthBox } from 'Common/UI/atoms/Input/styled';
import RankModule from 'Page/Product/Module/RankModule';

const WorkVmwareFInfos = () => {
  const [vmwareFInfos, setVmwareFInfos] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);
  const [apiId, setApiId] = useState();

  // if (loginInfo?.user_level !== 5) return;
  const { vmware_name } = useParams();

  const getWorkInfos = async () => {
    const vmware_f_infos = await requestLoadWorkVmwareFNumInfos({ vmware_name });
    setVmwareFInfos(vmware_f_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    clearInterval(apiId);
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);

    return () => clearInterval(intervalId);
  }, [vmware_name]);
  useEffect(() => {
    // clearInterval(apiId);
    getWorkInfos();
  }, [vmware_name]);
  if (!vmwareFInfos?.vmware_f_num_infos) return;
  return (
    <>
      <Box bottom={-50}>
        <TextButton
          onClick={() => {
            navigate_newtab(`/work/result_logs?vmware_name=${vmware_name}`);
          }}
        >
          {vmware_name}
        </TextButton>
        <TextSmall>{count} </TextSmall>cmds:{' '}
        <TextFail>{vmwareFInfos?.vmware_f_num_infos?.f_infos?.length}</TextFail>
      </Box>
      <Grid
        column={[
          '150rem',
          '150rem',
          '1fr',
          ...vmwareFInfos?.vmware_f_num_infos?.hour_list?.map((e) => '1fr'),
        ]}
        row={['40rem']}
        valign="top"
        gap={4}
      >
        <Box> </Box>
        <Box> 5 minute</Box>
        <Box align="center">
          {' '}
          하루 <TextSmall>all day</TextSmall>
        </Box>
        {vmwareFInfos?.vmware_f_num_infos?.hour_list?.map((hour_n, index) => {
          return (
            <Box valign="top" key={index}>
              {hour_n}시
            </Box>
          );
        })}
        {vmwareFInfos?.vmware_f_num_infos?.f_infos?.map((f_info, index) => {
          const f_num = f_info?.f_num;
          const realtime_info = vmwareFInfos?.vmware_f_num_realtime_infos_hash[f_num]?.[0];
          const current_info = f_info?.current_info;
          return (
            <React.Fragment key={index}>
              <Box>
                <TextButton
                  onClick={() => {
                    navigate_newtab(
                      `/work/result_logs?vmware_name=${f_info?.vmware_name}&f_num=${f_info?.f_num}`,
                    );
                  }}
                >
                  {f_info?.vmware_name} - {f_info?.f_num}
                </TextButton>
              </Box>
              <Box column valign="center">
                <Box>
                  <TextSmall>
                    [<TextSucceed>{realtime_info?.succeed_count}</TextSucceed>/
                    {realtime_info?.count}]
                  </TextSmall>
                </Box>
                <Box valign="center">
                  <PercentModule
                    succeed_limit={70}
                    value={realtime_info?.succeed_count}
                    valueMax={realtime_info?.count}
                  />
                </Box>
                <Box valign="center">
                  <TextSmall>
                    succeed: <TextSucceed>{realtime_info?.succeed_count}</TextSucceed>
                  </TextSmall>
                </Box>
                <Box valign="center">
                  <TextSmall>
                    fail:{' '}
                    <TextFail>
                      {realtime_info?.count && realtime_info?.count - realtime_info?.succeed_count}
                    </TextFail>
                  </TextSmall>
                </Box>
                <Box column bottom={10} style={'background: #F0F0F0'}>
                  <Box>
                    <TextSmall>type: {current_info?.current_using_type}</TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>
                      ok: <TextSucceed>{current_info?.current_using_ok_count}</TextSucceed>
                    </TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>fail: {current_info?.current_using_fail_count}</TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>which: {current_info?.current_using_cmd_which}</TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>status: {current_info?.current_using_cmd_status}</TextSmall>
                  </Box>

                  <Box>
                    <TextSmall>
                      ago_sec: {current_info?.current_using_cmd_datetime_ago_sec}
                    </TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>{current_info?.current_using_debug_info}</TextSmall>
                  </Box>
                </Box>
              </Box>

              <Hourbox>
                <Box>
                  유입오류:{' '}
                  <TextFail>{currencyComma(f_info?.count_infos?.etc_do1_problem_count)}</TextFail>
                </Box>
                <Box>
                  제제노출:
                  <TextFail>
                    {currencyComma(f_info?.count_infos?.visible_etc_do1_problem_count)}
                  </TextFail>
                </Box>
                <Box>
                  do:
                  {currencyComma(f_info?.count_infos?.to_etc_do1_ok_count)}/
                  {currencyComma(f_info?.count_infos?.to_etc_do1_access_count)}{' '}
                  <PercentModule
                    succeed_limit={70}
                    warnning_limit={45}
                    value={f_info?.count_infos?.to_etc_do1_ok_count}
                    valueMax={f_info?.count_infos?.to_etc_do1_access_count}
                  />
                </Box>
                <Box>
                  view:
                  {currencyComma(f_info?.count_infos?.to_view_ok_count)}/
                  {currencyComma(f_info?.count_infos?.to_view_access_count)}{' '}
                  <PercentModule
                    succeed_limit={70}
                    warnning_limit={45}
                    value={f_info?.count_infos?.to_view_ok_count}
                    valueMax={f_info?.count_infos?.to_view_access_count}
                  />
                </Box>
                <Box>
                  admin work:
                  {currencyComma(f_info?.count_infos?.admin_work_count)}
                </Box>
              </Hourbox>

              {vmwareFInfos?.vmware_f_num_infos?.hour_list?.map((hour_n, hour_index) => {
                const count_infos = f_info?.hour_infos[hour_index]?.count_infos;

                const etc_do1_problem_count_hour_key = `etc_do1_problem_count_hour${hour_n}`;
                const etc_do1_problem_count_hour = count_infos[etc_do1_problem_count_hour_key];

                const visible_etc_do1_problem_count_hour_key = `visible_etc_do1_problem_count_hour${hour_n}`;
                const visible_etc_do1_problem_count_hour =
                  count_infos[visible_etc_do1_problem_count_hour_key];

                const to_etc_do1_ok_count_hour_key = `to_etc_do1_ok_count_hour${hour_n}`;
                const to_etc_do1_ok_count_hour = count_infos[to_etc_do1_ok_count_hour_key];

                const to_etc_do1_access_count_hour_key = `to_etc_do1_access_count_hour${hour_n}`;
                const to_etc_do1_access_count_hour = count_infos[to_etc_do1_access_count_hour_key];

                const to_view_ok_count_hour_key = `to_view_ok_count_hour${hour_n}`;
                const to_view_ok_count_hour = count_infos[to_view_ok_count_hour_key];

                const to_view_access_count_hour_key = `to_view_access_count_hour${hour_n}`;
                const to_view_access_count_hour = count_infos[to_view_access_count_hour_key];

                const admin_work_count_hour_key = `admin_work_count_hour${hour_n}`;
                const admin_work_count_hour = count_infos[admin_work_count_hour_key];

                return (
                  <Hourbox key={hour_index}>
                    <Box>
                      유입오류: <TextFail>{currencyComma(etc_do1_problem_count_hour)}</TextFail>
                    </Box>
                    <Box>
                      제제노출:
                      <TextFail>{currencyComma(visible_etc_do1_problem_count_hour)}</TextFail>
                    </Box>
                    <Box>
                      do:
                      {currencyComma(to_etc_do1_ok_count_hour)}/
                      {currencyComma(to_etc_do1_access_count_hour)}{' '}
                      <PercentModule
                        succeed_limit={70}
                        warnning_limit={45}
                        value={to_etc_do1_ok_count_hour}
                        valueMax={to_etc_do1_access_count_hour}
                      />
                    </Box>
                    <Box>
                      view:
                      {currencyComma(to_view_ok_count_hour)}/
                      {currencyComma(to_view_access_count_hour)}{' '}
                      <PercentModule
                        succeed_limit={70}
                        warnning_limit={45}
                        value={to_view_ok_count_hour}
                        valueMax={to_view_access_count_hour}
                      />
                    </Box>
                    <Box>
                      admin work:
                      {currencyComma(admin_work_count_hour)}
                    </Box>
                  </Hourbox>
                );
              })}
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
};
const Hourbox = styled.div`
  font-size: 14rem;
  border-style: solid;
  border-radius: 12rem;
  border: 10rem;
  background-color: #d5dee8;
  padding: 10rem;
  margin: 5rem;
  border-color: red;
  border-width: 10rem;
`;
export default WorkVmwareFInfos;
