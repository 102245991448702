import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadGetBasicInfo,
  requestLoadSetBasicInfo,
  requestLoadWorkProductSetSetupInfos,
  requestLoadWorkProxyGetControlInfos,
  requestLoadWorkProxySetControlInfos,
  requestLoadWorkVmwareGetCalcHourInfos,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  getKorDate,
  reload_second,
  to_integer,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PercentModule from './Module/PercentModule';
import { currencyComma, divide, get_percent, sortSet } from 'Common/Utils';
import ShowInfoModule from './Module/ShowInfoModule';
import ToggleButton from 'Page/Module/CheckToggleButton';
import { onShowToast } from 'features/common/commonSlice';

const WorkProxyControlInfos = () => {
  const [proxyInfos, setProxyInfos] = useState([]);
  const [basicInfo, setBasicInfo] = useState();
  const [apiId, setApiId] = useState();
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const setProxyInfo = async ({ id, fields }) => {
    const result = await requestLoadWorkProxySetControlInfos({ id, fields });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `설정이 변경 되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  const getWorkInfos = async ({}) => {
    const infos = await requestLoadWorkProxyGetControlInfos({});
    console.log(infos);
    setBasicInfo(infos?.basic_info);
    setProxyInfos(infos?.proxy_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos({});
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkInfos({});
  }, []);
  return (
    <Box column>
      <Box>
        성공률{' '}
        <Box width={80}>
          <InputComponent
            maxLength={3}
            isMax
            iconSize={10}
            fontSize={12}
            padding={[5, 15]}
            value={basicInfo?.setup_proxy_country_use_percent?.toString()}
            onChange={(e) => {
              basicInfo.setup_proxy_country_use_percent = to_integer(e.target.value);
              forceUpdate();
            }}
          />
        </Box>{' '}
        % 이상인 국가만 사용가능{' '}
        <TextButton
          onClick={async () => {
            const fields = {
              setup_proxy_country_use_percent: basicInfo?.setup_proxy_country_use_percent,
            };
            const result = requestLoadSetBasicInfo({
              fields,
            });
            if (result) {
              dispatch(
                onShowToast({
                  status: 'success',
                  message: `설정이 변경되었습니다.`,
                  cancelText: '',
                  onCancel: false,
                }),
              );
            } else {
              dispatch(
                onShowToast({
                  status: 'error',
                  message: `오류가 발생했습니다`,
                  cancelText: '',
                  onCancel: false,
                }),
              );
            }
          }}
        >
          값 변경
        </TextButton>{' '}
        {count}
      </Box>
      {proxyInfos
        ?.sort((b, a) => sortSet(a?.check_etc_do1_ok_per, b?.check_etc_do1_ok_per))
        .map((proxy_info, index) => {
          return (
            <Block key={index}>
              <Box>
                <Box>
                  {proxy_info?.check_etc_do1_ok_per < basicInfo.setup_proxy_country_use_percent ||
                  proxy_info?.check_temp_stop_chk ? (
                    <>
                      <TextFail>[사용 안함]</TextFail>
                    </>
                  ) : (
                    <></>
                  )}{' '}
                  {proxy_info?.country}
                </Box>
                <Box>
                  <ToggleButton
                    active={!proxy_info?.check_temp_stop_chk}
                    onClick={async () => {
                      const check_temp_stop_chk = !proxy_info.check_temp_stop_chk;
                      proxy_info.check_temp_stop_chk = check_temp_stop_chk;
                      forceUpdate();
                      const id = proxy_info?.id;
                      const fields = {
                        check_temp_stop_chk: check_temp_stop_chk,
                      };

                      await setProxyInfo({ id, fields });
                    }}
                  >
                    {proxy_info?.check_temp_stop_chk ? `임시 중지중` : `가동중`}
                  </ToggleButton>
                </Box>
              </Box>
              <Box>
                {proxy_info?.check_etc_do1_ok_count} / {proxy_info?.check_etc_do1_access_count}
                <PercentModule
                  value={proxy_info?.check_etc_do1_ok_count}
                  valueMax={proxy_info?.check_etc_do1_access_count}
                />
                <TextButton
                  onClick={async () => {
                    const id = proxy_info?.id;
                    const fields = {
                      check_today_etc_do1_problem_count: 0,
                      check_etc_do1_access_count: 0,
                      check_etc_do1_ok_count: 0,
                      check_etc_do1_ok_per: 101,
                    };

                    await setProxyInfo({ id, fields });
                  }}
                >
                  초기화
                </TextButton>
              </Box>
            </Block>
          );
        })}
    </Box>
  );
};

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  /* gap: 10rem; */
`;
const InfoBlock = styled.div`
  font-size: 12rem;
  font-weight: 400;
`;
const VmwareBlock = styled.div`
  padding: 10rem 0rem 0rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  &:hover {
    background-color: #f0f0f0;
    border-color: blue;
  }
`;
const WorkStationBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 110rem;
  font-size: 14rem;
  font-weight: 400;

  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  /* &:hover { */
  /* background-color: #f0f0f0; */
  /* border-color: blue; */
  border-color: #a0a0f0;
  /* } */

  h1 {
    color: #f00000;
    font-weight: 600;
  }
  h2 {
    margin-right: 10rem;
    color: blue;
    font-size: 18rem;
    font-weight: 600;
  }
  b {
    margin-right: 10rem;
    color: #1010a0;
    font-size: 16rem;
    font-weight: 600;
  }
`;

export default WorkProxyControlInfos;
