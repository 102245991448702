import useTab from 'Common/Hooks/useTab';
import { Grid } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import UserAdd from './UserAdd';
import { useSelector } from 'react-redux';

const User = () => {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState();
  const { current, onClickLocation } = useTab(location.pathname, true);
  const { loginInfo } = useSelector((state) => state.common);
  useEffect(() => {
    if (loginInfo?.user_type === 'root') {
      setMenuItems([
        { title: '일반 유저 등록', value: '/signup/' },
        {
          title: '2차 대행사 등록',
          value: '/signup/partner/',
        },
      ]);
    } else if (loginInfo?.user_type === 'admin') {
      setMenuItems([
        { title: '일반 유저 등록', value: '/signup/' },
        {
          title: '2차 대행사 등록',
          value: '/signup/partner/',
        },
      ]);
    } else if (loginInfo?.user_type === 'partner') {
      setMenuItems([{ title: '일반 유저 등록', value: '/signup/' }]);
    } else if (loginInfo?.user_type === 'user') {
      setMenuItems([]);
    } else {
      setMenuItems([]);
    }
  }, []);

  return (
    <>
      <Grid row={['auto', 1]} gap={32}>
        <TabComponent
          current={current}
          onClick={(url) => {
            onClickLocation(url);
          }}
          items={menuItems}
        />
        <Routes>
          <Route path={'/partner'} element={<UserAdd />} />
          <Route path={'/'} element={<UserAdd />} />
        </Routes>
      </Grid>
    </>
  );
};
export default User;
