import React from 'react';
import styled, { css } from 'styled-components';

function CategoryBadge({
  children,
  color = 'P_500',
  sizeX = 'md',
  className,
  sizeY = 'md',
  isbg,
  pd,
  labelBg,
}) {
  const colors = {
    P_500: 'P_60',
    S_500: 'S_200',
    G_500: 'G_50',
    S_200: 'S_500',
    G_50: 'G_500',
    Warning_600: 'Warning_100',
    Error_500: 'Error_100',
  };
  return (
    <Wrapper
      bgColor={labelBg ? labelBg : isbg ? color : colors[color] ? colors[color] : 'P_60'}
      className={`categoryBadge ${className || ''}`}
      color={isbg ? 'White' : color}
      sizeX={sizeX}
      sizeY={sizeY}
      pd={pd}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ color, bgColor, sizeX, sizeY, theme: { ellipsis, categoryBadge } }) => {
    return css`
      color: var(--${color});
      background-color: var(--${bgColor});

      text-align: center;
      ${ellipsis(1)}
      ${categoryBadge.sizeX[sizeX]};
      ${categoryBadge.sizeY[sizeY]};
      border-radius: 0rem 20rem 20rem 0rem;
    `;
  }}
`;
export default CategoryBadge;
