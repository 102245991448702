import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useMutations from 'Common/Service/commonMutations';
import { numberSum, sortSet } from 'Common/Utils';
import { useNavigate } from 'react-router-dom';
import { chunk } from 'Common/Utils/util';

const AnalyticsVmwareShopLinkCounts = ({}) => {
  const navigate = useNavigate();

  const [result, setResults] = useState({});
  const [clickCount, setClickCount] = useState(0);
  const [allClickCountsInfos, setAllClickCountsInfos] = useState([]);
  const [workstationInfos, setWorkstationInfos] = useState({});
  const [workstationAccessInfos, setWorkstationAccessInfos] = useState({
    w1: {
      anydesk: '1912322313',
    },
    w2: {
      anydesk: '1582259796',
    },
    w3: {
      anydesk: '1753713338',
    },
    t1: {
      anydesk: '1138641342',
    },
    t2: {
      anydesk: '1121858811',
    },
    t3: {
      anydesk: '1008025221',
    },
    t4: {
      anydesk: '942368959',
    },
    t5: {
      anydesk: '',
      // anydesk: '1379831440',
    },
    t6: {
      anydesk: '671196610',
    },
    t7: {
      anydesk: '',
      // anydesk: '1104005408',
    },
    s0: {
      anydesk: '1166312113',
    },
    s1: {
      anydesk: '1716123707',
    },
    s2: {
      anydesk: '1566251427',
    },
  });
  const [nowHourN, setNowHourN] = useState(new Date().getHours());
  const [nowMinN, setNowMinN] = useState(new Date().getMinutes());

  const { requestLoadVmwareShopLinkCountWorkstationInfosMutation } = useMutations(setResults);

  useEffect(() => {
    const now = new Date(); // 현재 시간
    const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000; // 현재 시간을 utc로 변환한 밀리세컨드값
    const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
    const koreaNow = new Date(utcNow + koreaTimeDiff); // utc로 변환된 값을 한국 시간으로 변환시키기 위해 9시간(밀리세컨드)를 더함
    // 위 코드에서 koreaNow 는 사용자의 시간대가 어떻게 설정되어 있더라도 한국 시간을 보여주게 된다.

    setNowHourN(koreaNow.getHours());
    setNowMinN(koreaNow.getMinutes());
    if (result?.request === 'requestLoadVmwareShopLinkCountWorkstationInfosMutation') {
      if (result?.success && result?.result !== undefined) {
        const workstation_infos = result?.result;
        setWorkstationInfos(workstation_infos);
        //   result?.shop_data?.map((e) => {});
        //   const list = result?.result;
        //   setVmwareInfos(list);
        //   var count = 0;
        //   list?.forEach((e) => (count += e?.shop_click_count));
        //   setClickCount(count);
        // }
      }
      setTimeout(() => {
        requestLoadVmwareShopLinkCountWorkstationInfosMutation.mutate({});
      }, 5000);
    }

    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadVmwareShopLinkCountWorkstationInfosMutation.mutate({});
  }, []);
  const clickGoal = 6000;

  return (
    <>
      <Box column>
        <div>시간당 필요 카운터: {(clickGoal - clickCount) / (23 - nowHourN)}</div>
        <div>필요 카운터: {clickGoal - clickCount}</div>
        <Box>{clickCount}</Box>
        {Object.keys(workstationInfos)
          .sort((a, b) => sortSet(a, b))
          .map((workstation_name) => {
            const vmwareInfos = workstationInfos[workstation_name]?.list;
            const off_phones = workstationInfos[workstation_name]?.off_phones;

            const vmwareInfos_chunks = chunk(vmwareInfos, 12);
            return (
              <>
                <Box>
                  <WorkstationInfoBox>
                    [{vmwareInfos?.length}] <h1>{workstation_name} </h1>
                    {workstationAccessInfos[workstation_name]?.anydesk}{' '}
                    {off_phones.length > 0 ? (
                      <>
                        <h3>unuse phone:</h3> <h2>{off_phones.join(', ')}</h2>
                      </>
                    ) : null}
                  </WorkstationInfoBox>
                </Box>
                {vmwareInfos_chunks.map((vmwareInfos) => (
                  <>
                    <GridHeader columns={vmwareInfos?.length}>
                      <NameBox></NameBox>
                      <NameBox></NameBox>
                      {vmwareInfos?.map((e) => {
                        return (
                          <NameBox>
                            <Box column align="center">
                              <Box>
                                <button
                                  onClick={() => {
                                    const vmware_url = `/analytics/vmware/${e?.vmware_name}`;
                                    navigate(vmware_url);
                                  }}
                                >
                                  {e?.vmware_name}
                                  {/* [{e?.worker_network_type}] */}
                                </button>
                              </Box>
                              <Box>
                                <ResultBox>
                                  <h1>{e?.active_browser_type}</h1>
                                </ResultBox>
                              </Box>
                              <Box>
                                <ResultBox>
                                  {e?.shop_click_count} (
                                  <h1>{((e?.shop_click_count * 100) / 600).toFixed(1)}%</h1>)
                                </ResultBox>
                              </Box>
                              <Box>
                                <ResultBox>
                                  <small>
                                    [{e?.phone_type}
                                    {e?.phone_speed ? `-${e?.phone_speed}` : ''}]{' '}
                                    {e?.phone_num ? `(${e?.phone_num})` : ''}
                                    {e?.phone}
                                    {e?.phone_modem_type ? ` [${e?.phone_modem_type}]` : ''}
                                  </small>
                                </ResultBox>
                              </Box>
                            </Box>
                          </NameBox>
                        );
                      })}
                    </GridHeader>
                    {Array.from(Array(24)).map((_, i) => {
                      return (
                        <GridBody columns={vmwareInfos?.length} active={nowHourN === i}>
                          <CountBox>{i}</CountBox>
                          <CountBox>
                            {numberSum(vmwareInfos?.map((e) => e[`shop_click_count_hour${i}`]))}(
                            <h2>
                              {numberSum(vmwareInfos?.map((e) => e[`shop_view_count_hour${i}`]))}
                            </h2>
                            )(
                            <h2>
                              {numberSum(vmwareInfos?.map((e) => e[`to_etc_do1_count_hour${i}`]))}
                            </h2>
                            ) (
                            <h2>
                              {numberSum(vmwareInfos?.map((e) => e[`to_etc_do2_count_hour${i}`]))}
                            </h2>
                            )
                          </CountBox>

                          {vmwareInfos?.map((e) => {
                            return (
                              <CountBox>
                                {e[`shop_click_count_hour${i}`]}(
                                <h2>{e[`shop_view_count_hour${i}`]}</h2>)(
                                <h2>{e[`to_etc_do1_count_hour${i}`]}</h2>)
                                <h2>{e[`to_etc_do2_count_hour${i}`]}</h2>)
                                {e.isUsing === false && <h1> Off</h1>}
                                {(e.lately_access_status === 'error' ||
                                  e.lately_access_main_status === 'error') && <h1> error</h1>}
                              </CountBox>
                            );
                          })}
                        </GridBody>
                      );
                    })}
                  </>
                ))}
              </>
            );
          })}
      </Box>
      {/* </Block> */}
    </>
  );
};
const WorkstationInfoBox = styled.div`
  color: blue;
  h1 {
    font-size: 30rem;
    font-weight: 800;
    display: inline;
    color: red;
  }
  h2 {
    font-size: 15rem;
    font-weight: 600;
    display: inline;
    color: #d2c210;
  }
  h3 {
    font-size: 15rem;
    font-weight: 600;
    display: inline;
    color: #f00000;
  }
`;
const ResultBox = styled.span`
  font-size: 12rem;
  h1 {
    display: inline;
    color: red;
  }
  small {
    font-size: 12rem;
  }
`;
const NameBox = styled.div`
  text-align: center;
  font-size: 14rem;
`;
const CountBox = styled.div`
  text-align: center;
  font-size: 12rem;
  padding: 2rem;
  h1 {
    display: inline;
    color: red;
  }
  h2 {
    display: inline;
    color: blue;
  }
`;

const GridBody = styled.div`
  width: 100%;
  display: grid;
  /* background-color: red; */
  /* grid-template-columns: auto; */
  /* grid-template-columns: repeat(auto-fill, 1fr); */

  &:hover {
    background-color: #f0f0f0;
    color: #2e29ab;
  }
  grid-gap: 8rem;
  /* gap: 8rem; */
  justify-content: left;
  /* align-items: center; */
  ${(props) => {
    const { columns } = props;

    return css`
      /* grid-template-columns: repeat(${columns}, 1fr); */
      grid-template-columns: 50rem 50rem repeat(${columns}, 1fr);
    `;
  }}
  ${(props) => {
    const { active } = props;

    if (active) {
      return css`
        background-color: #f0f0f0;
        color: #dd0606;
      `;
    }
  }}
`;

const GridHeader = styled.div`
  /* margin-top: 20rem; */
  display: grid;
  /* background-color: red; */
  /* grid-template-columns: auto; */
  /* grid-template-columns: repeat(auto-fill, 1fr); */

  grid-gap: 8rem;
  /* gap: 8rem; */
  justify-content: left;
  /* align-items: center; */
  ${(props) => {
    const { columns } = props;

    return css`
      grid-template-columns: 50rem 50rem repeat(${columns}, 1fr);
    `;
  }}
`;

export default AnalyticsVmwareShopLinkCounts;
