import { valueSet } from 'Common/Utils/util';
import React, { Children, forwardRef, useRef, useState } from 'react';
import Icon from '../IconBox';
import { LabelText, Wrapper } from './styled';

const InputWrapper = ({
  children,
  label,
  size = 36,
  leftIcon,
  rightIcon,
  disabled,
  readOnly,
  bulr,
  isEdit,
  padding = [0, isEdit ? 20 : 0],
  underLine,
  value,
  onClick,
  fontSize = 16,
  weight = 400,
  iconSize = 16,
  isDelete = true,
  isError,
}) => {
  const inputRef = useRef();
  const [isFocus, setIsFocus] = useState(false);

  const onBlur = (e) => setIsFocus(false);

  const onFocus = () => {
    if (readOnly) return;
    setIsFocus(true);
  };

  return (
    <>
      {label && <LabelText>{label}</LabelText>}
      <Wrapper
        isFocus={isFocus}
        onBlur={(e) => onBlur(e)}
        onFocus={(e) => onFocus(e)}
        isEdit={isEdit}
        padding={valueSet(padding)}
        weight={weight}
        ref={inputRef}
        fSize={valueSet(fontSize)}
        underLine={underLine}
        isDisabled={disabled}
        sizeY={size ? valueSet(size) : undefined}
        bulr={bulr}
        isError={isError}
      >
        <div className="inner">
          {leftIcon && <div className="leftIcon">{leftIcon}</div>}
          {children}
          {isEdit && isDelete && value?.length > 0 && !disabled && !readOnly && (
            <span className="iconDelete" onClick={onClick}>
              <Icon.Closer size={iconSize} color="light" />
            </span>
          )}
          {rightIcon && rightIcon}
        </div>
      </Wrapper>
    </>
  );
};

export default InputWrapper;
