import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoadWorkVmwareGetCalcHourInfos } from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  getKorDate,
  reload_second,
  to_integer,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { Input } from 'Common/UI/atoms/Button';
import ToggleButton from 'Page/Module/CheckToggleButton';
import styled from 'styled-components';
import { updateLocale } from 'moment';
import { onShowToast } from 'features/common/commonSlice';
import PercentModule from './Module/PercentModule';
import { currencyComma, divide, get_percent, navigate_newtab, sortSet } from 'Common/Utils';
import { current } from '@reduxjs/toolkit';
import TrafficSizeModule from './Module/TrafficSizeModule';
import ShowInfoModule from './Module/ShowInfoModule';

const WorkVmwareCalcHourDataInfos = () => {
  const { workstation_name } = useParams();
  const [workstationInfos, setWorkstationInfos] = useState([]);
  const [hourInfos, setHourInfos] = useState([]);
  const [hourDataInfos, setHourDataInfos] = useState([]);
  const [hourOKDataInfos, setHourOkDataInfos] = useState([]);
  const [hourFailDataInfos, setHourFailDataInfos] = useState([]);
  const [isSucceedInfo, setIsSucceedInfo] = useState(false);
  const [hourDataWorkstationInfos, setHourDataWorkstationInfos] = useState({});
  const [isShowAllData, setIsShowAllData] = useState(true);
  const [allInfo, setAllInfo] = useState();
  const [checkHourList, setCheckHourList] = useState([]);
  const [date, setDate] = useState();
  const [showDate, setShowDate] = useState(today_str(getKorDate()));

  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const getWorkInfos = async ({ date }) => {
    setHourDataInfos([]);
    setCheckHourList([]);
    setAllInfo([]);
    setHourInfos([]);
    setWorkstationInfos([]);
    const infos = await requestLoadWorkVmwareGetCalcHourInfos({ date, isSucceedInfo });
    console.log(infos);
    const workstation_infos = infos?.workstation_infos;
    const hour_infos = infos?.hour_infos;
    const list = infos?.list?.sort((a, b) => sortSet(b?.hour_n, a?.hour_n));
    const ok_list = infos?.ok_list?.sort((a, b) => sortSet(b?.hour_n, a?.hour_n));
    const fail_list = infos?.fail_list?.sort((a, b) => sortSet(b?.hour_n, a?.hour_n));
    setHourDataInfos(list);
    setHourOkDataInfos(ok_list);
    setHourFailDataInfos(fail_list);
    const all_info = {
      count: infos?.count,
      succeed_count: infos?.succeed_count,
    };
    setDate(infos?.date);
    // if (isSucceedInfo) {
    // setCheckHourList(hour_infos?.slice(0, 1)?.map((e) => e.hour_n));
    // } else {
    setCheckHourList(hour_infos?.slice(0, 4)?.map((e) => e.hour_n));
    // }

    setAllInfo(all_info);
    setHourInfos(hour_infos);
    setWorkstationInfos(workstation_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    // const intervalId = setInterval(() => {
    //   setCount((prevCount) => {
    //     var ct = prevCount - 1;
    //     if (ct < 0) {
    //       getWorkInfos();
    //       ct = reload_second;
    //     }
    //     return ct;
    //   });
    // }, 1000);
    // setApiId(intervalId);
    // return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkInfos({ date });
  }, []);
  const getWorkstationInfo = async ({ workstation_name }) => {
    const infos = await requestLoadWorkVmwareGetCalcHourInfos({
      date,
      workstation_name,
    });

    setHourDataWorkstationInfos({
      ...hourDataWorkstationInfos,
      [workstation_name]: infos?.list,
    });
  };
  useEffect(() => {
    if (!workstation_name) return;
    setIsShowAllData(false);
    getWorkstationInfo({ workstation_name });
  }, [workstation_name]);
  return (
    <Box column>
      <Box gap={1} top={10}>
        {hourInfos?.map((hour_info, index) => {
          return (
            <Box key={index} column width={220} style="background: #f0f0f0">
              <Box>
                <CheckBox
                  checked={checkHourList.includes(hour_info?.hour_n)}
                  onChange={(e, e2) => {
                    if (e?.currentTarget.checked) {
                      setCheckHourList([...checkHourList, hour_info.hour_n]);
                    } else {
                      setCheckHourList(checkHourList.filter((hour) => hour != hour_info.hour_n));
                    }
                  }}
                >
                  <TextSmall>{hour_info?.hour_n}시</TextSmall>
                </CheckBox>
              </Box>
              <Box align="center">
                <TextSmall>
                  <TextSucceed>{currencyComma(hour_info?.succeed_count)}</TextSucceed>/
                  {currencyComma(hour_info?.count)}
                </TextSmall>
              </Box>
              <Box align="center">
                <TextSmall>
                  <PercentModule value={hour_info?.succeed_count} valueMax={hour_info?.count} />
                </TextSmall>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box gap={12}>
        <Box>
          날짜: <TextSucceed>{date}</TextSucceed>
        </Box>
        <CheckBox
          checked={isSucceedInfo}
          onChange={() => {
            setIsSucceedInfo(!isSucceedInfo);
          }}
        >
          성공/실패 내역 보기
        </CheckBox>
        <CheckBox
          checked={isShowAllData}
          onChange={() => {
            setIsShowAllData(!isShowAllData);
          }}
        >
          전체 통계 보기
        </CheckBox>
        <Box>
          <Box width={150}>
            <InputComponent
              iconSize={10}
              fontSize={12}
              padding={[5, 15]}
              size={25}
              value={showDate}
              onChange={(e) => {
                setShowDate(e?.target?.value);

                // vmware_info.setup_problem_access_try_per_url_count_end_limit = to_integer(
                // e?.target?.value,
                // );
                // forceUpdate();
              }}
            />
          </Box>
          <Button
            onClick={() => {
              getWorkInfos({ date: showDate?.trim() });
            }}
            size={'xxs'}
          >
            불러오기
          </Button>
        </Box>
      </Box>
      {hourInfos && (
        <Grid
          column={['150rem', '5rem', ...checkHourList?.map((e) => '1fr')]}
          row={['40rem']}
          valign="top"
          gap={4}
        >
          {/* 헤더 제목 */}
          <Box>컴퓨터</Box>

          <Box>
            &nbsp;
            {/* 종일 */}
          </Box>
          {checkHourList
            ?.sort((a, b) => sortSet(b, a))
            ?.map((hour_n) => {
              return <Box>{hour_n}시</Box>;
            })}

          {/* 본문 출력 시작 */}
          {isShowAllData ? (
            <>
              <Box column>
                <Box>전체</Box>
                <Box>
                  <TextSmall>
                    <TextSucceed>{currencyComma(allInfo?.succeed_count)}</TextSucceed>/
                    {currencyComma(allInfo?.count)}
                  </TextSmall>
                </Box>
                <Box>
                  <TextSmall>
                    <PercentModule value={allInfo?.succeed_count} valueMax={allInfo?.count} />
                  </TextSmall>
                </Box>
              </Box>

              {/* 전체 데이터 */}
              <Box>&nbsp;</Box>
              {checkHourList
                ?.sort((a, b) => sortSet(b, a))
                ?.map((hour_n) => {
                  const info = hourDataInfos?.find((data) => data?.hour_n === hour_n);
                  const okInfo = hourOKDataInfos?.find((data) => data?.hour_n === hour_n);
                  const failInfo = hourFailDataInfos?.find((data) => data?.hour_n === hour_n);
                  return (
                    <Box valign="top">
                      <Box column valign="top">
                        <ShowInfoModule info={info}></ShowInfoModule>
                      </Box>
                      {isSucceedInfo && (
                        <Box column valign="top">
                          <Box>
                            [<TextSucceed>성공 내역</TextSucceed>]
                          </Box>
                          <ShowInfoModule info={okInfo}></ShowInfoModule>
                        </Box>
                      )}
                      {isSucceedInfo && (
                        <Box column valign="top">
                          <Box>
                            [<TextFail>실패 내역</TextFail>]
                          </Box>
                          <ShowInfoModule info={failInfo}></ShowInfoModule>
                        </Box>
                      )}
                    </Box>
                  );
                })}
            </>
          ) : null}
          {workstationInfos
            ?.filter((workstation_info) => {
              const w_name = workstation_info?.workstation_name;
              if (workstation_name) {
                if (w_name !== workstation_name) return false;
              }
              return true;
            })
            ?.sort((a, b) => sortSet(b?.succeed_count_per_by_all, a?.succeed_count_per_by_all))
            ?.map((workstation_info, index) => {
              return (
                <>
                  <WorkStationBlock key={index} column width={100}>
                    <Box>
                      <TextButton
                        onClick={() => {
                          if (workstation_info?.workstation_name === workstation_name) {
                            navigate(`/vmware_count`);
                          } else {
                            navigate(`/vmware_count/${workstation_info?.workstation_name}`);
                          }
                        }}
                      >
                        <b>{workstation_info?.workstation_name}</b>
                      </TextButton>
                    </Box>
                    <Box>
                      <TextButton
                        onClick={() => {
                          navigate_newtab(`/cmd/${workstation_info?.workstation_name}`);
                        }}
                      >
                        <TextSmall>
                          <TextSucceed>CMD 현황 보기</TextSucceed>
                        </TextSmall>
                      </TextButton>
                    </Box>
                    <Box>
                      <Box column>
                        <Box>
                          <TextSmall>유입 성공</TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            <TextSucceed>{workstation_info?.succeed_count_per_by_all}</TextSucceed>{' '}
                            %
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            <TextSucceed>
                              {currencyComma(workstation_info?.succeed_count)}
                            </TextSucceed>
                          </TextSmall>
                        </Box>
                      </Box>
                      <Box column>
                        <Box>
                          <TextSmall>전체 시도</TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>{workstation_info?.count_per_by_all} %</TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>{currencyComma(workstation_info?.count)}</TextSmall>
                        </Box>
                      </Box>
                    </Box>
                    <Box align="center">
                      <TextSmall>
                        유입 성공:{' '}
                        <PercentModule
                          value={workstation_info?.succeed_count}
                          valueMax={workstation_info?.count}
                        />
                      </TextSmall>
                    </Box>
                  </WorkStationBlock>
                  <Box>&nbsp;</Box>
                  {checkHourList
                    ?.sort((a, b) => sortSet(b, a))
                    ?.map((hour_n) => {
                      const workstation_name = workstation_info?.workstation_name;
                      const datas = hourDataWorkstationInfos[workstation_name];
                      const info = datas?.find((data) => data?.hour_n === hour_n);
                      const okInfo = hourOKDataInfos?.find((data) => data?.hour_n === hour_n);
                      const failInfo = hourFailDataInfos?.find((data) => data?.hour_n === hour_n);
                      if (info)
                        return (
                          <Box valign="top">
                            <Box column valign="top">
                              <ShowInfoModule info={info}></ShowInfoModule>
                            </Box>
                            {isSucceedInfo && (
                              <Box column valign="top">
                                <Box>
                                  [<TextSucceed>성공 내역</TextSucceed>]
                                </Box>
                                <ShowInfoModule info={okInfo}></ShowInfoModule>
                              </Box>
                            )}
                            {isSucceedInfo && (
                              <Box column valign="top">
                                <Box>
                                  [<TextFail>실패 내역</TextFail>]
                                </Box>
                                <ShowInfoModule info={failInfo}></ShowInfoModule>
                              </Box>
                            )}
                          </Box>
                        );
                      else if (datas) {
                        return <Box>데이터가 없습니다.</Box>;
                      } else {
                        return (
                          <Box align="center">
                            <Button
                              onClick={async () => {
                                const infos = await requestLoadWorkVmwareGetCalcHourInfos({
                                  date,
                                  workstation_name,
                                });

                                setHourDataWorkstationInfos({
                                  ...hourDataWorkstationInfos,
                                  [workstation_name]: infos?.list,
                                });
                              }}
                            >
                              데이터 받아오기
                            </Button>
                          </Box>
                        );
                      }
                    })}
                </>
              );
            })}
        </Grid>
      )}
    </Box>
  );
};

const SetupBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  /* gap: 10rem; */
`;
const HelpStatus = styled.div`
  font-size: 11rem;
  font-weight: 400;
  color: #0000a5;
`;
const SetupInfoDefaultBlock = styled.div`
  padding: 10rem 0rem 10rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  margin-bottom: 10rem;

  border-width: 1rem;
  border-style: solid;
  /* border-color: transparent; */
  /* &:hover { */
  /* background-color: #f0f0f0; */
  border-color: blue;
  /* } */
`;
const InfoBlock = styled.div`
  font-size: 12rem;
  font-weight: 400;
`;
const VmwareBlock = styled.div`
  padding: 10rem 0rem 0rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  &:hover {
    background-color: #f0f0f0;
    border-color: blue;
  }
`;
const WorkStationBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 110rem;
  font-size: 14rem;
  font-weight: 400;
  transition: all 0.2s;
  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  /* &:hover { */
  /* background-color: #f0f0f0; */
  /* border-color: blue; */
  border-color: #a0a0f0;
  /* } */

  h1 {
    color: #f00000;
    font-weight: 600;
  }
  h2 {
    margin-right: 10rem;
    color: blue;
    font-size: 18rem;
    font-weight: 600;
  }
  b {
    margin-right: 10rem;
    color: #1010a0;
    font-size: 16rem;
    font-weight: 600;
  }
`;

export default WorkVmwareCalcHourDataInfos;
