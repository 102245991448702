import { valueSet } from "Common/Utils/util";
import React from "react";
import { Wrapper } from "./styled";

function Scroll({ height, className, children, fix }) {
    return (
        <Wrapper
            scrollHeight={valueSet(height)}
            fix={fix}
            className={className}
        >
            {children}
        </Wrapper>
    );
}

export default Scroll;
