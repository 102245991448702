import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Icon, IconButton, TextButton } from 'Common/UI/atoms';
import Menu from 'Common/Menu';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = ({ headerType, isOuter }) => {
  const [title, setTitle] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const menu = Menu();

  const titleName = (name) => {
    const filter = menu.filter(({ url }) => {
      const head = (url?.split('/')?.filter((i) => i) || [])[0];
      return head !== undefined && (name?.split('/')?.filter((i) => i) || [])[0]?.includes(head);
    })[0];
    setTitle(name === '/' ? '메인' : filter?.title);
  };

  const menuArray = location?.pathname
    ?.split('/')
    ?.filter((i) => i)
    ?.filter((i) => !(Number(i) > 0));

  const naviArray = () => {
    const array = [];
    const saerchMenu = (data, index = 0, frontUrl = '') => {
      const filter = data?.filter(({ url }) => url?.includes(menuArray[index]))[0];
      filter &&
        array?.push({
          name: filter?.title,
          url: frontUrl + filter?.url,
        });
      filter?.depth?.length &&
        menuArray[index + 1] &&
        saerchMenu(filter?.depth, index + 1, frontUrl + filter.url);
    };
    saerchMenu(menu);
    return array;
  };

  useEffect(() => {
    titleName(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Wrapper isOuter={isOuter} headerType={headerType}>
        <Box align={'side'} width={'calc(100vw - 180rem)'} style={`padding:0 40rem;`}>
          <Box gap={20}>
            <>
              <h1>{title}</h1>
              <Box gap={12}>
                <IconButton
                  Theme={'unFill'}
                  size="md"
                  Icon={Icon.Home}
                  onClick={() => navigate('/')}
                />
                {naviArray().map((i, key) => (
                  <React.Fragment key={key}>
                    <Icon.Arrow direction={'right'} size={20} fill={'val(--G_500)'} />
                    <TextButton
                      size={'md'}
                      onClick={() =>
                        navigate(naviArray().length === key + 1 ? location.pathname : i?.url)
                      }
                    >
                      {i.name}
                    </TextButton>
                  </React.Fragment>
                ))}
              </Box>
            </>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.header`
  ${({ headerType, isOuter }) => `
    position: fixed;
    width: 100vw;
    ${isOuter ? '' : 'padding-left: 140rem;'}
    height: 80rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: ${headerType ? 'rgba(255,255,255,0)' : 'var(--White)'};
    box-sizing: border-box;
    box-shadow:${headerType ? '0 0 0 rgba(0,0,0,0)' : 'var(--Elevation_002)'};
    transition:background 0.3s;
    > div {
        width: ${isOuter ? '100%' : 'calc(100vw - 180rem)'};
    }
    @media (max-width: 1860px) {
        > div {
            width: 100%;
        }
    }
    .headerUser {
        color: var(--${headerType ? 'White' : 'G_500'});
        text-align: right;
        margin-right: 4rem;
        span {
            font-size: 12rem;
            line-height: 16rem;
            color: var(--${headerType ? 'White' : 'G_700'});
        }
        strong {
            font-weight: 500;
            color: var(--${headerType ? 'White' : 'G_700'});
            line-height: 20rem;
        }
    }
    h1 {
        font-size: 24rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        button {
            margin-left: 12rem;
        }
    }
`}
`;

export const Alarm = styled.div`
  ${(props) => {
    const { isActive } = props;
    return `
        position:relative;
        &::after{
            ${isActive ? `content:''` : ''};
            position:absolute;
            top:4rem;
            right:4rem;
            width:8rem;
            height:8rem;
            box-sizing:border-box;
            border-radius:100%;
            background-color:var(--S_500);
            border:1rem solid var(--White);
        }
    `;
  }}
`;

export const User = styled.nav`
  display: flex;
  align-items: center;
  gap: 8rem;
  * {
    transition: 0.3s;
  }
  &:hover {
    p {
      color: var(--P_500);
      * {
        color: var(--P_500);
      }
    }
  }
`;
export default Header;
