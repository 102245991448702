import { Box, Button, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  requestLoadWorkProxyGetInfos,
  requestLoadWorkVmwareFInfos,
  requestLoadWorkVmwareInfos,
} from 'Common/Service/common';
import { currencyComma, get_percent, sortSet } from 'Common/Utils';
import PercentModule from './Module/PercentModule';
import { TextSmall, TextSucceed, reload_second } from 'Common/Utils/util';
import { Route, Routes, useNavigate } from 'react-router-dom';
import WorkVmwareOtherLogInfos from './WorkVmwareOtherLogInfos';
import WorkVmwareFInfos from './WorkVmwareFInfos';
import WorkVmwareViewLogInfos from './WorkVmwareViewLogInfos';
import WorkVmwareOrderMakeOrder from './WorkVmwareOrderMakeOrder';
import WorkVmwareOrderInfos from './WorkVmwareOrderInfos';

const WorkVmwareInfos = () => {
  const [workstationNames, setWorkstationNames] = useState([]);
  const [workstationName, setWorkstationName] = useState('order');
  const [vmwarename, setVmwarename] = useState();
  const [vmwareInfos, setVmwareInfos] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  const [apiId, setApiId] = useState();
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();

  const getWorkInfos = async () => {
    const workstation_infos = await requestLoadWorkVmwareFInfos();
    setWorkstationNames(workstation_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Box column>
      <Box bottom={0}>
        {count}
        <TextButton
          size={'md'}
          Theme={workstationName === 'order' ? 'primary' : null}
          onClick={() => {
            setWorkstationName('order');
            navigate('/work/vmware/order');
          }}
        >
          vmware 자동 명령 <TextSmall>vmware auto orders</TextSmall>
        </TextButton>
        <Box column>
          <TextButton
            size={'md'}
            Theme={workstationName === 'etc_do1_10minutes' ? 'primary' : null}
            onClick={() => {
              setWorkstationName('etc_do1_10minutes');
              navigate('/work/vmware/etc_do1_10min');
            }}
          >
            유입(Do) 실시간 10분 통계 in 10 minutes
          </TextButton>
          <TextButton
            size={'md'}
            Theme={workstationName === 'etc_do1_20minutes' ? 'primary' : null}
            onClick={() => {
              setWorkstationName('etc_do1_20minutes');
              navigate('/work/vmware/etc_do1_20min');
            }}
          >
            유입(Do) 실시간 20분 통계 in 10 minutes
          </TextButton>
          <TextButton
            size={'md'}
            Theme={workstationName === 'etc_do1_30minutes' ? 'primary' : null}
            onClick={() => {
              setWorkstationName('etc_do1_30minutes');
              navigate('/work/vmware/etc_do1_30min');
            }}
          >
            유입(Do) 실시간 30분 통계 in 10 minutes
          </TextButton>
        </Box>
        {/* <TextButton
          size={'md'}
          Theme={workstationName === 'view_10minutes' ? 'primary' : null}
          onClick={() => {
            setWorkstationName('view_10minutes');
            navigate('/work/vmware/view_10min');
          }}
        >
          View 실시간 10분 통계 in 10 minutes
        </TextButton> */}
        <br />
        {workstationNames?.working_vmware_f_infos
          ?.sort((a, b) => sortSet(a?.workstation_name, b?.workstation_name))
          ?.map((e, index) => {
            const workstation_name = e.workstation_name;
            const vmware_infos = e?.vmware_infos;
            return (
              <Box key={index}>
                <TextButton
                  Theme={workstationName === workstation_name ? 'primary' : null}
                  onClick={() => {
                    setWorkstationName(workstation_name);
                    setVmwareInfos(vmware_infos);
                  }}
                >
                  {workstation_name}
                </TextButton>
              </Box>
            );
          })}
      </Box>
      <Grid column={['200rem', '1fr']} gap={32} valign="top" height="1000">
        {workstationName === 'etc_do1_10minutes' ? (
          <>
            <TextSmall>
              유입(Do) 실시간 10분 통계 <br />
              in 10 minutes
            </TextSmall>
          </>
        ) : workstationName === 'etc_do1_20minutes' ? (
          <>
            <TextSmall>
              유입(Do) 실시간 20분 통계 <br />
              in 20 minutes
            </TextSmall>
          </>
        ) : workstationName === 'etc_do1_30minutes' ? (
          <>
            <TextSmall>
              유입(Do) 실시간 30분 통계 <br />
              in 30 minutes
            </TextSmall>
          </>
        ) : workstationName === 'view_10minutes' ? (
          <>
            <TextSmall>
              View 실시간 10분 통계 <br />
              in 10 minutes
            </TextSmall>
          </>
        ) : workstationName === 'order' ? (
          <WorkVmwareOrderMakeOrder />
        ) : (
          <Box column>
            {vmwareInfos
              ?.sort((a, b) => sortSet(a?.vmware_name, b?.vmware_name))
              ?.map((e, index) => {
                return (
                  <Box key={index}>
                    <TextButton
                      Theme={vmwarename === e?.vmware_name ? 'primary' : null}
                      onClick={() => {
                        setVmwarename(e?.vmware_name);
                        navigate(`/work/vmware/${e?.vmware_name}`);
                      }}
                    >
                      {e?.vmware_name}
                    </TextButton>
                  </Box>
                );
              })}
          </Box>
        )}

        <Box column gap={30}>
          <Routes>
            <Route path={'/'} element={<WorkVmwareOrderInfos />} />
            <Route path={'/etc_do1_10min'} element={<WorkVmwareOtherLogInfos min={10} />} />
            <Route path={'/etc_do1_20min'} element={<WorkVmwareOtherLogInfos min={20} />} />
            <Route path={'/etc_do1_30min'} element={<WorkVmwareOtherLogInfos min={30} />} />
            <Route path={'/order'} element={<WorkVmwareOrderInfos />} />
            <Route path={'/view_10min'} element={<WorkVmwareViewLogInfos />} />
            <Route path={'/:vmware_name'} element={<WorkVmwareFInfos />} />
          </Routes>
        </Box>
      </Grid>
    </Box>
  );
};

export default WorkVmwareInfos;
