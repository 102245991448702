import React, { useCallback, useEffect, useState } from 'react';
import MainListSetting from '../Module/MainListSetting';
import moment from 'moment';
import { Box, Title, IconButton, Icon, TextButton, TimeBar, Button } from 'Common/UI/atoms';
import MainWeekList from '../Module/MainWeekList';
import { setDate } from 'Common/Utils/date';
import { useRef } from 'react';
import Scroll from 'Common/UI/molecules/Scroll';
import MainViewSchedule from '../Module/MainViewSchedule';
import { useNavigate } from 'react-router-dom';
import useMutations from 'Common/Service/countMutations';
import { currencyComma, getWeekColorStr, getWeekStr, get_percent, sortSet } from 'Common/Utils';
import { useSelector } from 'react-redux';
import * as xlsx from 'xlsx-js-style';

const MainCalendar = ({ rankInfos }) => {
  // const xlsx = require('xlsx');
  const { loginInfo } = useSelector((state) => state.common);

  const [result, setResults] = useState({});
  const [getWeek, setGetWeek] = useState(moment());
  const [prevWeek, setPrevWeek] = useState(moment());
  const [nextWeek, setNextWeek] = useState(moment());
  const [activeDate, setActiveDate] = useState(setDate(moment()));
  const [shopLinkCount, setShopLinkCount] = useState([]);
  const [isMove, setIsMove] = useState(false);
  // const [allSchedule, setAllSchedule] = useState({
  //   slotCountMax: 54,
  // });
  // const [doneSchedule, setDoneSchedule] = useState({
  //   slotCount: 50,
  //   use_id_count: 30,
  //   clickAvgSpeed: 30,
  //   ipChangeSpeed: 40,
  // });
  const navigate = useNavigate();

  const nowWeek = getWeek?.clone();
  const today = moment();
  const timer = 500;
  const raf = useRef();

  const setWeek = () => {
    const prev = moment(nowWeek.clone().subtract(1, 'week'));
    const next = moment(nowWeek.clone().add(1, 'week'));
    setPrevWeek(prev);
    setNextWeek(next);
  };

  const { requestLoadShopLinkCountGetDateMutation } = useMutations(setResults);
  const outTransition = useCallback((data) => {
    setTimeout(() => {
      setGetWeek(data.clone());
      setIsMove(false);
    }, timer);
  });
  const isAction = typeof isMove === 'number';
  useEffect(() => {
    if (result?.request === 'requestLoadShopLinkCountGetDateMutation') {
      if (result?.success && result?.result !== undefined) {
        setShopLinkCount(result?.result);
      }
      setTimeout(() => {
        // requestLoadInfoGetApiInfoMutation.mutate({ fields: default_info_fields });
      }, 2000);
    }
    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadShopLinkCountGetDateMutation.mutate({ date: activeDate });
  }, [activeDate]);
  const getResultExcel = ({ info }) => {
    if (info?.type === 'admin') {
      return getResultExcelForAdmin({ info });
    } else if (info?.type === 'partner') {
      return getResultExcelForPartner({ info });
    } else if (info?.type === 'user') {
      return getResultExcelForUser({ info });
    }
  };
  const getResultExcelForUser = ({ info }) => {
    var line_list = [];
    var list = [];
    var str = '';

    str = `상품 ${currencyComma(info?.list?.length)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = `슬롯 ${currencyComma(info?.slot_count)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = `유입 ${currencyComma(shopLinkCount?.to_count)}/${currencyComma(
      shopLinkCount?.to_count_max,
    )} [${get_percent(shopLinkCount?.to_count, shopLinkCount?.to_count_max)}%]`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = ``;
    list.push(str);
    line_list.push(list);
    list = [];

    info?.list?.forEach((e) => {
      str = `${e?.group_search_nvmid ? `[원부]` : `[단일]`} ${e?.nvmid} - 슬롯: ${e?.slot_count} [${
        e?.mall_name
      } ]${e?.title}`;
      list.push(str);

      str = `[${get_percent(e.to_count, e.to_count_max)}%] ${`${currencyComma(
        e.to_count,
      )} / ${currencyComma(e.to_count_max)}`}`;
      list.push(str);
      line_list.push(list);
      list = [];
    });
    str = ``;
    list.push(str);
    line_list.push(list);
    list = [];
    return line_list;
  };
  const getResultExcelForPartner = ({ info }) => {
    var line_list = [];
    var list = [];
    var str = '';

    str = `유저 ${currencyComma(info?.list?.length)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = `상품 ${currencyComma(info?.product_count)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = `슬롯 ${currencyComma(info?.slot_count)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = ``;
    list.push(str);
    line_list.push(list);
    list = [];

    info?.list?.forEach((e) => {
      const user_name = e?.user_name;
      str = `${user_name}`;
      list.push(str);
      str = `[${get_percent(e.to_count, e.to_count_max)}%] ${`${currencyComma(
        e.to_count,
      )} / ${currencyComma(e.to_count_max)}`} 상품: ${currencyComma(
        e?.product_count,
      )} 슬롯: ${currencyComma(e?.slot_count)}`;
      list.push(str);
      line_list.push(list);
      list = [];
    });
    str = ``;
    list.push(str);
    line_list.push(list);
    list = [];
    return line_list;
  };
  const getResultExcelForAdmin = ({ info }) => {
    var line_list = [];
    var list = [];
    var str = '';

    str = `2차 대행사 ${currencyComma(info?.list?.length)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = `상품 ${currencyComma(info?.product_count)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = `슬롯 ${currencyComma(info?.slot_count)}`;
    list.push(str);
    line_list.push(list);
    list = [];

    str = ``;
    list.push(str);
    line_list.push(list);
    list = [];

    info?.list?.forEach((e) => {
      const partner_name = e?.partner_name;
      str = `${partner_name}`;
      list.push(str);
      str = `[${get_percent(e.to_count, e.to_count_max)}%] ${`${currencyComma(
        e.to_count,
      )} / ${currencyComma(e.to_count_max)}`} 상품: ${currencyComma(
        e?.product_count,
      )} 슬롯: ${currencyComma(e?.slot_count)}`;
      list.push(str);
      line_list.push(list);
      list = [];
    });
    str = ``;
    list.push(str);
    line_list.push(list);
    list = [];
    return line_list;
  };
  const rankInfosToList = ({ rankInfos }) => {
    var line_list = [];
    var list = [];
    var str = '';
    // return;

    str = ``;
    list.push(str);
    rankInfos?.dates?.forEach((d) => {
      str = `${d?.slice(5, 10)} (${getWeekStr(moment(d)?.format('ddd'))})`;
      list.push(str);
    });
    line_list.push(list);
    list = [];

    rankInfos?.list?.forEach((e) => {
      const category_name = e.category1 ? e.category1 : '미분류';
      const product_count = e.count;
      const slot_count = e.slot_count;
      const category_date_info_list = e?.category_date_info_list;
      const product_per_date_info_list = e?.product_per_date_info_list;
      str = `${category_name} - ${currencyComma(product_count)}개 (슬롯 ${currencyComma(
        slot_count,
      )}개)`;
      list.push(str);

      rankInfos?.dates?.forEach((d) => {
        const info = category_date_info_list?.find((i) => i.date == d);
        const info_rank = info?.rank;
        const info_rank_change_rate = info?.rank_change_rate;
        const info_rank_change_rate_count = info?.rank_change_rate_count;
        if (info_rank) {
          if (info_rank_change_rate_count > 0) {
            if (info_rank_change_rate > 0) {
              str = `${info_rank} (+${info_rank_change_rate})`;
              list.push(str);
            } else {
              str = `${info_rank} (-${info_rank_change_rate})`;
              list.push(str);
            }
          } else {
            str = `${info_rank} (-)`;
            list.push(str);
          }
        } else {
          str = ``;
          list.push(str);
        }
      });
      line_list.push(list);
      list = [];
      product_per_date_info_list?.forEach((info) => {
        const nvmid = info.nvmid;
        // const group_search_nvmid = info.group_search_nvmid;
        const title = info.title;
        const mall_name = info.mall_name;
        // const smartstore_chk = info.smartstore_chk;
        // const brandstore_chk = info.brandstore_chk;
        // const navershopping_chk = info.navershopping_chk;
        const date_list = info.date_list;
        const slot_count = info.slot_count;

        // <MallNameModule {...info} />
        str = `슬롯(${currencyComma(slot_count)}) [${mall_name}] ${title}`;
        list.push(str);

        rankInfos?.dates?.forEach((d) => {
          const count_info = date_list?.find((i) => i.date == d)?.list?.[0];
          // const info_count = count_info?.count;
          const info_rank = count_info?.rank;
          const info_rank_change_rate = count_info?.rank_change_rate;
          // const info_rank_change_rate_count = count_info?.rank_change_rate_count;
          const info_last_rank = count_info?.last_rank;
          if (info_rank > 0) {
            if (info_last_rank > 0) {
              if (info_rank_change_rate > 0) {
                str = `${info_rank} (+${info_rank_change_rate})`;
                list.push(str);
              } else {
                str = `${info_rank} (${info_rank_change_rate})`;
                list.push(str);
              }
            } else {
              str = `${info_rank} (-)`;
              list.push(str);
            }
          } else {
            str = ``;
            list.push(str);
          }
        });
        line_list.push(list);
        list = [];
      });
    });
    str = ``;
    list.push(str);
    line_list.push(list);
    list = [];
    return line_list;
  };

  const handleDownload = (list = []) => {
    const book = xlsx.utils.book_new();

    // let row = [
    //   { v: 'Courier: 24', t: 's', s: { font: { name: 'Courier', sz: 24 } } },
    //   { v: 'bold & color', t: 's', s: { font: { bold: true, color: { rgb: 'FF0000' } } } },
    //   { v: 'fill: color', t: 's', s: { fill: { fgColor: { rgb: 'E9E9E9' } } } },
    //   { v: 'line\nbreak', t: 's', s: { alignment: { wrapText: true } } },
    // ];
    const day_counts = xlsx.utils.aoa_to_sheet([
      [activeDate],
      [''],
      // ['슬롯번호', '유입', 'nvmid', '쇼핑몰', '상품명'],
      // [''],
      // row,
      ...list,
    ]);
    day_counts['!cols'] = [
      { wpx: 500 }, // A열
      { wpx: 100 }, // A열
      { wpx: 100 }, // B열
      { wpx: 100 }, // B열
      { wpx: 100 }, // B열
      { wpx: 100 }, // B열
      { wpx: 100 }, // B열

      // { wch: 60 }, // E열
    ];

    xlsx.utils.book_append_sheet(book, day_counts, `${activeDate}`);
    xlsx.writeFile(book, `${activeDate}.xlsx`);
  };

  useEffect(setWeek, [getWeek]);

  useEffect(() => {
    typeof isMove === 'number' && outTransition(isMove ? nextWeek : prevWeek);
  }, [isMove]);

  return (
    <MainListSetting width="100%">
      <Box height={36} align="side">
        <Title size={'xxs'}>
          {moment(activeDate)?.format('YYYY년 M월 D일')} (
          <span
            dangerouslySetInnerHTML={{ __html: getWeekColorStr(moment(activeDate)?.format('ddd')) }}
          />
          )
        </Title>
        <Box gap={8}>
          <Box gap={0}>
            <IconButton
              size={'sm'}
              Theme={'unFill'}
              Icon={Icon.Arrow}
              onClick={isAction ? undefined : () => setIsMove(0)}
              direction="left"
            />
            <IconButton
              size={'sm'}
              Theme={'unFill'}
              Icon={Icon.Arrow}
              onClick={isAction ? undefined : () => setIsMove(1)}
              direction="right"
            />
          </Box>
        </Box>
      </Box>
      <Box fill column>
        <Box raf={raf} height={72} style={`overflow:hidden; `}>
          <Box
            align="center"
            style={`
                            position:absolute;
                            
                            ${
                              isAction
                                ? `transform:translate(${isMove ? '-' : ''}100%,0);
                                      transition:transform ${timer}ms;
                                      `
                                : ''
                            }
                    `}
          >
            <MainWeekList
              position="absolute"
              onClick={setActiveDate}
              getDays={prevWeek}
              activeDate={activeDate}
              style={`right:100%;`}
            />
            <MainWeekList
              onClick={setActiveDate}
              getDays={getWeek}
              today={setDate(today)}
              activeDate={activeDate}
            />
            <MainWeekList
              position="absolute"
              onClick={setActiveDate}
              activeDate={activeDate}
              getDays={nextWeek}
              style={`left:100%;`}
            />
          </Box>
        </Box>
        <Box fill column style={`>div{height:100%;};`}>
          <Scroll>
            <Box height={'100%'} gap={16} column>
              {/* <Box height={!allSchedule?.slotCountMax ? '100%' : 'max-content'} gap={16} column> */}
              {shopLinkCount?.list?.length > 0 ? (
                <>
                  <>
                    <TimeBar>내역</TimeBar>
                    {shopLinkCount?.type === 'user' ? (
                      <>
                        <MainViewSchedule
                          list={[
                            {
                              title: '상품',
                              value: currencyComma(shopLinkCount?.list?.length),
                            },
                            {
                              title: '슬롯',
                              value: currencyComma(shopLinkCount?.slot_count),
                            },
                            {
                              title: '유입',
                              value: `${currencyComma(shopLinkCount?.to_count)}/${currencyComma(
                                shopLinkCount?.to_count_max,
                              )} [${get_percent(
                                shopLinkCount?.to_count,
                                shopLinkCount?.to_count_max,
                              )}%]`,
                            },
                          ]}
                        />
                      </>
                    ) : shopLinkCount?.type === 'partner' ? (
                      <>
                        <MainViewSchedule
                          list={[
                            {
                              title: '유저',
                              value: currencyComma(shopLinkCount?.list?.length),
                            },
                            {
                              title: '상품',
                              value: currencyComma(shopLinkCount?.product_count),
                            },
                            {
                              title: '슬롯',
                              value: currencyComma(shopLinkCount?.slot_count),
                            },
                          ]}
                        />
                      </>
                    ) : shopLinkCount?.type === 'admin' ? (
                      <>
                        <MainViewSchedule
                          list={[
                            {
                              title: '2차대행사',
                              value: currencyComma(shopLinkCount?.list?.length),
                            },
                            {
                              title: '상품',
                              value: currencyComma(shopLinkCount?.product_count),
                            },
                            {
                              title: '슬롯',
                              value: currencyComma(shopLinkCount?.slot_count),
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                  <>
                    <Button
                      Theme={'line'}
                      size={'xl'}
                      onClick={() => {
                        // const list = shopLinkCount
                        //   .sort((a, b) => {
                        //     var n = 0;
                        //     if (a?.group_name >= b?.group_name) return 1;
                        //     else return -1;
                        //     // if (a?.group_name < b?.group_name) return 0;
                        //     // if (a?.mall_name >= b?.mall_name) n += 10;
                        //     // if (a?.product_id >= b?.product_id) n += 1;
                        //   })
                        //   .map((e) => {
                        //     return [
                        //       e.id,
                        //       e.close_product_chk ? '' : currencyComma(e.count),
                        //       e.nvmid,
                        //       e.close_product_chk ? e.close_product_status : e.mall_name,
                        //       e.close_product_chk ? '' : e.title,
                        //     ];
                        //   });
                        // handleDownload([]);
                        const result_list = getResultExcel({ info: shopLinkCount });
                        const rank_list = rankInfosToList({ rankInfos });
                        const list = result_list.concat(rank_list);
                        handleDownload(list);
                      }}
                    >
                      순위 변화 다운로드
                      <Icon.Fileload size="xxs" />
                    </Button>

                    {shopLinkCount?.type === 'user' ? (
                      <>
                        <TimeBar color="S_500">상품</TimeBar>
                        <MainViewSchedule
                          list={shopLinkCount?.list?.map((e) => {
                            return {
                              title: `${e?.group_search_nvmid ? `[원부]` : `[단일]`} ${
                                e?.nvmid
                              } - 슬롯: ${e?.slot_count}`,
                              value: `[${get_percent(e.to_count, e.to_count_max)}%] ${
                                e.close_product_chk
                                  ? e.close_product_status
                                  : `${currencyComma(e.to_count)} / ${currencyComma(
                                      e.to_count_max,
                                    )}`
                              }`,
                            };
                          })}
                        />
                      </>
                    ) : shopLinkCount?.type === 'partner' ? (
                      <>
                        <TimeBar color="S_500">유저</TimeBar>
                        <MainViewSchedule
                          list={shopLinkCount?.list?.map((e) => {
                            return {
                              title: `${e.user_name} `,
                              value: `[${get_percent(
                                e.to_count,
                                e.to_count_max,
                              )}%] ${`${currencyComma(e.to_count)} / ${currencyComma(
                                e.to_count_max,
                              )}`} 상품: ${currencyComma(e?.product_count)} 슬롯: ${currencyComma(
                                e?.slot_count,
                              )}`,
                            };
                          })}
                        />
                      </>
                    ) : shopLinkCount?.type === 'admin' ? (
                      <>
                        <TimeBar color="S_500">2차대행사</TimeBar>
                        <MainViewSchedule
                          list={shopLinkCount?.list?.map((e) => {
                            return {
                              title: `${e.partner_name} `,
                              value: `[${get_percent(
                                e.to_count,
                                e.to_count_max,
                              )}%] ${`${currencyComma(e.to_count)} / ${currencyComma(
                                e.to_count_max,
                              )}`} 상품: ${currencyComma(e?.product_count)} 슬롯: ${currencyComma(
                                e?.slot_count,
                              )}`,
                            };
                          })}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </>
              ) : (
                <Box
                  top={30}
                  gap={20}
                  align="center"
                  // valign="middle"
                  fill
                  column
                >
                  <Icon.BigCalendar />
                  <Box align="center" column>
                    <Title color="G_800" weight={400} size="xxs">
                      해당 날짜에 작업 내역이 없어요
                    </Title>
                    <Title color="G_500" weight={400} size="xxxs"></Title>
                  </Box>
                  <Box>
                    <TextButton
                      onClick={() => {
                        navigate('/product/add');
                      }}
                      leftIcon={<Icon.PM size={12} />}
                      size={'sm'}
                      fill
                    >
                      상품 추가하기
                    </TextButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Scroll>
        </Box>
      </Box>
    </MainListSetting>
  );
};

export default MainCalendar;
