import { Box, Button, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetAutoCountryNormal,
  requestLoadWorkNewGetAutoHotNormal,
  requestLoadWorkNewGetManualOtherLink,
  requestLoadWorkNewSetAutoCountryNormal,
  requestLoadWorkNewSetAutoHotNormal,
  requestLoadWorkNewSetManualOtherLink,
  requestLoadWorkProxyGetInfos,
  requestLoadWorkVmwareFInfos,
  requestLoadWorkVmwareInfos,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  getKorDate,
  reload_second,
  to_integer,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { sortSet } from 'Common/Utils';
import { onShowToast } from 'features/common/commonSlice';
import PercentModule from './Module/PercentModule';

const WorkNewPatternAddKeywordAutoCountryNormal = () => {
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const [keywordInfo, setKeywordInfo] = useState();
  const [keywordCountInfo, setKeywordCountInfo] = useState();

  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetAutoCountryNormal();
    console.log(result);
    setKeywordInfo(result?.keyword_info);
    setKeywordCountInfo(result?.count_infos);
    // setWorkstationNames(workstation_infos);
  };
  const [count, setCount] = useState(reload_second);
  const dispatch = useDispatch();
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Box column width={400}>
      <Box>나라 자동 생성 키워드</Box>
      <Box>
        키워드 {keywordCountInfo?.count}개 키워드수집{' '}
        <PercentModule
          value={keywordCountInfo?.get_tonghap_chk_count}
          valueMax={keywordCountInfo?.count}
        />
        , 키워드수집(활동){' '}
        <PercentModule
          value={keywordCountInfo?.get_tonghap_active_chk_count}
          valueMax={keywordCountInfo?.count}
        />
      </Box>
      <Box>
        <Button
          fill
          onClick={async () => {
            const result = await requestLoadWorkNewSetAutoCountryNormal({
              keywords: keywordInfo?.keywords,
            });
            if (!result) return;
            const new_keywords_count = result?.new_keywords_count;
            const del_keywords_count = result?.del_keywords_count;
            dispatch(
              onShowToast({
                status: 'success',
                message: `${new_keywords_count}개의 신규 키워드가 추가 되었습니다. ${del_keywords_count}개의 키워드가 삭제 되었습니다.`,
                cancelText: '',
                onCancel: false,
              }),
            );
          }}
        >
          수정
        </Button>
      </Box>
      <Box height="100%">
        <InputComponent
          //   autocomplete={'off'}
          size={500}
          el="textarea"
          //   underLine

          placeholder={''}
          value={keywordInfo?.keywords}
          onChange={(e) => {
            //   addMdDelay();
            setKeywordInfo({ ...keywordInfo, keywords: e.target.value });
            //   addMd();
            // setProductIds(e.target.value);
          }}
          // onKeyUp={onKeyUp}
          //   required
        />
      </Box>
    </Box>
  );
};

export default WorkNewPatternAddKeywordAutoCountryNormal;
