import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import Icon from './IconBox';

function CheckBox({
  type,
  children,
  value,
  name,
  disabled,
  checked,
  onMouseOver,
  onMouseLeave,
  onChange,
  single,
  size,
  style,
  fontSize,
  onlyChecked = false,
}) {
  const [active, setActive] = useState(checked || false);
  const onChangeInput = (e) => {
    const {
      target: { checked, name },
    } = e;

    if (onChange) onChange({ ...e, target: { ...e.target, name, value } });
    if (!onlyChecked) checked ? setActive(true) : setActive(false);
  };

  useEffect(() => {
    setActive(checked);
  }, [checked]);

  return (
    <Wrapper
      onClick={(e) => e.stopPropagation()}
      className="checkBox"
      type={type}
      single={single}
      styles={style}
      fontSize={fontSize}
    >
      <input
        type={type}
        value={value || ''}
        name={name}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onChange={onChangeInput}
        disabled={disabled}
        className="checkBox"
        checked={active || ''}
        // onChange={check}
      />

      {type === 'checkbox' ? (
        <Icon.CheckBox size={size} isActive={active} disabled={disabled} />
      ) : (
        <Icon.RadioBox size={size} isActive={active} disabled={disabled} />
      )}
      {children && <span className="checkBoxText">{children}</span>}
    </Wrapper>
  );
}

const Wrapper = styled.label`
  ${({ styles, single, type, fontSize }) => `
position: relative;
display: inline-flex;
align-items: center;
cursor: pointer;
.checkBoxText {
    margin-left: 8rem;
    font-size: ${fontSize ? fontSize : 14}rem;
    line-height: 20rem;
    color: var(--G_500);
}
input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: none;
    z-index: 10;
    &:not(:checked):hover ~ .checkIcon {
        background-color: var(--P_75);
    }
    &:not(:disabled):not(:checked):hover ~ svg {
        .stroke,
        rect {
            fill: var(--P_500);
        }
        .background {
            fill: var(--P_25);
        }
    }
    &:checked ~ .checkIcon {
        background-color: var(--P_500);
        border-color: var(--P_500);
    }
    &:not(:checked) ~ .checkIcon svg {
        display: none;
    }
    &:disabled {
        cursor: no-drop;
        ~ .checkIcon {
            &::after {
                background-color: #eee;
            }
        }
        ~ .checkBoxText {
        }
    }
}
.checkIcon {
    ${
      !single
        ? `
            position:absolute;
            left:0;
            top:50%;
            transform: translateY(-50%);
        `
        : ` `
    }
    display:flex;
    justify-content: center;
    align-items: center;
    svg {
        position: absolute;
    }
    width: 28rem;
    height: 28rem;
    border: 1rem solid #c4c9df;
    border-radius: ${type === 'radio' ? '100rem' : '4rem'};
    ${type === 'radio' ? 'svg{display:none;}' : ''}
}
${styles || ''}
`}
`;
CheckBox.defaultProps = {
  type: 'checkbox',
  onChange: () => {},
};
export default CheckBox;
