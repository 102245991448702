import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadSetKeywordCheckVersion,
  requestLoadWorkNewGetActivePatterns,
  requestLoadWorkNewGetManualCountryNormal,
  requestLoadWorkNewGetManualOtherLink,
  requestLoadWorkNewGetShopLinkAddManualKeywords,
  requestLoadWorkNewGetShopLinkSetManualKeywords,
  requestLoadWorkNewGetWorkFiddlerCountInfos,
  requestLoadWorkNewNewPatternKeywordTimeReset,
  requestLoadWorkNewRemoveGotShopSearchKeyword,
  requestLoadWorkNewSetActivePatterns,
  requestLoadWorkNewSetManualCountryNormal,
  requestLoadWorkNewSetManualOtherLink,
  requestLoadWorkProductInfoResetCanAccessDatetime,
  requestLoadWorkProductInfoResetKeywordFind,
  requestLoadWorkRankResetCanAccessDatetime,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  getKorDate,
  reload_second,
  to_integer,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { onShowToast } from 'features/common/commonSlice';
import styled from 'styled-components';
import MallNameModule from './Module/MallNameModule';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import { keyword_strip } from 'Common/Utils';

const WorkNewPatternNeedAlbaKeywordInfos = ({ all_read_chk = false }) => {
  // if (loginInfo?.user_level !== 5) return;
  const [modal, setModal] = useState({
    title: '',
    successText: '발급 취소',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, isActive: false });
    },
  });
  const [workNewPatternGetWorkKeywordInfos, setWorkNewPatternGetWorkKeywordInfos] = useState();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const remove_keyword = async ({ keyword, product_info_id }) => {
    const result = await requestLoadWorkNewRemoveGotShopSearchKeyword({ product_info_id, keyword });
    if (result) {
      if (typeof result == 'number') {
        dispatch(
          onShowToast({
            status: 'success',
            message: `삭제가 완료되었습니다. 남은 키워드: ${result} `,
            cancelText: '',
            onCancel: false,
          }),
        );
      } else {
        dispatch(
          onShowToast({
            status: 'error',
            message: `오류가 발생했습니다`,
            cancelText: '',
            onCancel: false,
          }),
        );
      }
    }
  };
  const getWorkKeywordInfos = async () => {
    const work_add_manual_keyword_infos = await requestLoadWorkNewGetShopLinkAddManualKeywords();
    setWorkNewPatternGetWorkKeywordInfos(
      work_add_manual_keyword_infos ? work_add_manual_keyword_infos : {},
    );
  };

  useEffect(() => {
    getWorkKeywordInfos();
  }, []);
  return (
    <Box column width="100%">
      {workNewPatternGetWorkKeywordInfos?.list
        ?.filter((e) => {
          if (all_read_chk) return true;
          if (e?.state === 'done') return false;
          return true;
        })
        ?.map((e, index) => {
          return (
            <KeywordStatusBox key={index} width="100%">
              <Box>
                <Box>
                  <Box column width="100%">
                    <Box>
                      <Button
                        Theme={'unFill'}
                        size={'xs'}
                        onClick={async () => {
                          const product_info_id = e?.product_info_id;
                          const new_pattern_shop_keyword_find_fail_keywords =
                            e?.new_pattern_shop_keyword_find_fail_keywords;
                          const new_pattern_work_manual_add_keywords =
                            e?.new_pattern_work_manual_add_keywords;
                          const new_pattern_work_manual_no_use_keywords =
                            e?.new_pattern_work_manual_no_use_keywords;
                          const info = {
                            product_info_id,
                            new_pattern_work_manual_no_use_keywords,
                            new_pattern_shop_keyword_find_fail_keywords,
                            new_pattern_work_manual_add_keywords,
                          };
                          const result = await requestLoadWorkNewGetShopLinkSetManualKeywords(info);
                          if (result) {
                            dispatch(
                              onShowToast({
                                status: 'success',
                                message: `updated`,
                                cancelText: '',
                                onCancel: false,
                              }),
                            );
                          }
                        }}
                      >
                        수정
                      </Button>
                      <MallNameModule {...e?.product_info} />
                    </Box>
                    <Box>
                      {e?.product_info?.salePrice} - {e?.product_info?.new_pattern_color_code_text}
                    </Box>
                    <Box>
                      ({e?.new_pattern_shop_keyword_json_infos?.length})
                      {e?.new_pattern_shop_keyword_json_infos?.map((i) => {
                        return (
                          <>
                            {e?.new_pattern_shop_got_keywords
                              ?.map((k) => keyword_strip(k))
                              ?.includes(keyword_strip(i.keyword)) ? (
                              <>
                                <TextSucceed>[{i?.keyword}]</TextSucceed>{' '}
                              </>
                            ) : (
                              <>
                                <TextWarning>[{i?.keyword}]</TextWarning>{' '}
                              </>
                            )}
                            <TextSmall>
                              page:{i?.page},{i.price1}-{i.price2},{i.color}
                              <button
                                onClick={async () => {
                                  setModal({
                                    ...modal,
                                    title: 'Del',
                                    // text: `[${checkList?.length}]개의 상품을 ${extendDay}일 연장발급하시겠습니까?`,
                                    text: `[${i?.keyword}] 키워드를 삭제 하시겠습니까?`,
                                    successText: '삭제',
                                    isActive: true,
                                    cancelText: '취소',
                                    onSuccess: () => {
                                      // new_pattern_shop_search_find_fail(product_info, keyword)
                                      // new_pattern_remove_got_shop_search_keyword(product_info, keyword)

                                      // new_pattern_shop_got_keywords
                                      // new_pattern_shop_got_keywords_count
                                      // new_pattern_shop_keyword_json_infos
                                      // new_pattern_shop_keyword_json_infos_count

                                      // new_pattern_shop_got_keywords_relatedtags
                                      // new_pattern_shop_got_keywords_relatedtags_count
                                      // new_pattern_shop_keyword_json_infos_relatedtags
                                      // new_pattern_shop_keyword_json_infos_relatedtags_count
                                      const keyword = i?.keyword;
                                      const product_info_id = e?.product_info_id;
                                      remove_keyword({ keyword, product_info_id });
                                      getWorkKeywordInfos();
                                    },
                                  });
                                }}
                              >
                                Del
                              </button>
                            </TextSmall>
                          </>
                        );
                      })}
                    </Box>
                    <Box>
                      자동수집 실패 키워드수:{' '}
                      <TextFail>{e?.new_pattern_shop_keyword_find_fail_keywords_count}</TextFail>
                      <button
                        onClick={async () => {
                          setModal({
                            ...modal,
                            title: 'Init',
                            // text: `[${checkList?.length}]개의 상품을 ${extendDay}일 연장발급하시겠습니까?`,
                            text: `검색키워드 수집 정보를 완전 초기화 하시겠습니까?`,
                            successText: '초기화',
                            isActive: true,
                            cancelText: '취소',
                            onSuccess: async () => {
                              const shop_link_id = e.id;
                              const result = await requestLoadWorkProductInfoResetKeywordFind({
                                shop_link_id,
                              });

                              if (result) {
                                getWorkKeywordInfos();
                                dispatch(
                                  onShowToast({
                                    status: 'success',
                                    message: `${result} updated`,
                                    cancelText: '',
                                    onCancel: false,
                                  }),
                                );
                              }
                            },
                          });
                        }}
                      >
                        [수집 초기화]
                      </button>
                      [
                      <TextSmall>
                        {e?.new_pattern_shop_keyword_find_fail_keywords?.join(',')}
                      </TextSmall>
                      ]
                    </Box>
                    <Box>
                      사용안할 키워드
                      <Box width={700}>
                        <InputComponent
                          //   autocomplete={'off'}
                          iconSize={11}
                          fontSize={13}
                          padding={[0, 0, 0, 5]}
                          resize={false}
                          //   underLine
                          value={e?.new_pattern_work_manual_no_use_keywords}
                          onChange={(i) => {
                            e.new_pattern_work_manual_no_use_keywords = i.target.value;
                            forceUpdate();
                            //   addMdDelay();
                            // setGroupPatternInfo({
                            // ...groupPatternInfo,
                            // click_pattern_info_json_list: e.target.value,
                            // });
                            //   addMd();
                            // setProductIds(e.target.value);
                          }}
                          // onKeyUp={onKeyUp}
                          //   required
                        />
                      </Box>
                    </Box>
                    <Box>
                      {e?.product_info_id} - [{e?.state}] 키워드 찾기 실패:
                      <Box width={400}>
                        <InputComponent
                          //   autocomplete={'off'}
                          iconSize={11}
                          fontSize={13}
                          padding={[0, 0, 0, 5]}
                          resize={false}
                          //   underLine
                          value={e?.new_pattern_shop_keyword_find_fail_keywords}
                          onChange={(i) => {
                            // console;
                            e.new_pattern_shop_keyword_find_fail_keywords = i.target.value;
                            forceUpdate();

                            //   addMdDelay();
                            // setGroupPatternInfo({
                            // ...groupPatternInfo,
                            // click_pattern_info_json_list: e.target.value,
                            // });
                            //   addMd();
                            // setProductIds(e.target.value);
                          }}
                          // onKeyUp={onKeyUp}
                          //   required
                        />
                      </Box>
                    </Box>
                    <Box>
                      수동 키워드 등록 찾기:
                      <Box width={700}>
                        <InputComponent
                          //   autocomplete={'off'}
                          iconSize={11}
                          fontSize={13}
                          padding={[0, 0, 0, 5]}
                          resize={false}
                          //   underLine
                          value={e?.new_pattern_work_manual_add_keywords}
                          onChange={(i) => {
                            e.new_pattern_work_manual_add_keywords = i.target.value;
                            forceUpdate();
                            //   addMdDelay();
                            // setGroupPatternInfo({
                            // ...groupPatternInfo,
                            // click_pattern_info_json_list: e.target.value,
                            // });
                            //   addMd();
                            // setProductIds(e.target.value);
                          }}
                          // onKeyUp={onKeyUp}
                          //   required
                        />
                      </Box>
                    </Box>
                    {/* <Box>
                      <CheckBox
                        checked={e?.product_info?.new_pattern_shop_keyword_json_infos_done_chk}
                        onChange={(i) => {
                          e.product_info.new_pattern_shop_keyword_json_infos_done_chk =
                            !i.target.checked;
                          forceUpdate();
                        }}
                      >
                        키워드 작업 완료
                      </CheckBox>
                    </Box> */}
                  </Box>
                </Box>
                <Box column>
                  <Box>
                    실시간 검색 성공 키워드 <TextSmall>Real-time Search Succeed Keywords</TextSmall>
                  </Box>
                  <Box>
                    {e?.new_pattern_auto_shop_search_find_ok_infos?.map((ok_info) => {
                      return (
                        <Box>
                          {ok_info?.keyword} <TextFail>{ok_info?.count}</TextFail>{' '}
                          <TextSmall>{ok_info?.date}</TextSmall>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box>
                    실시간 검색 실패 키워드(5회시 제거){' '}
                    <TextSmall>Real-time Search Failure Keywords</TextSmall>
                  </Box>
                  <Box>
                    {e?.new_pattern_auto_shop_search_find_fail_infos?.map((fail_info) => {
                      return (
                        <Box>
                          {fail_info?.keyword} <TextFail>{fail_info?.count}</TextFail>{' '}
                          <TextSmall>{fail_info?.date}</TextSmall>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </KeywordStatusBox>
          );
        })}
      <ModalQuestion {...modal} />
    </Box>
  );
};
const KeywordStatusBox = styled.div`
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
const WorkFiddlerCountInfoBox = styled.div`
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;

export default WorkNewPatternNeedAlbaKeywordInfos;
