import { Box, Grid, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestLoadWorkVmwareEtcDo1RealtimeInfos } from 'Common/Service/common';
import { currencyComma, get_percent } from 'Common/Utils';
import PercentModule from './Module/PercentModule';
import { TextSmall, TextSucceed, reload_second } from 'Common/Utils/util';

const WorkVmwareOtherLogInfos = ({ min = 10 }) => {
  const [workInfos, setWorkInfos] = useState();
  const [apiId, setApiId] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  // const [dataMin, setDataMin] = useState();
  const getWorkInfos = async () => {
    const work_infos = await requestLoadWorkVmwareEtcDo1RealtimeInfos({ min });
    console.log(work_infos);
    setWorkInfos(work_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    clearInterval(apiId);
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, [min]);
  useEffect(() => {
    getWorkInfos();
  }, [min]);
  return (
    <>
      <Box bottom={0}>
        <TextSmall>{count}</TextSmall>
      </Box>
      {/* <Grid column={['1fr']} gap={32} valign="top"> */}
      {workInfos?.working_vmware_infos?.map((e) => {
        // SmartstoreNewOthersLog.get_realtime_working_vmwares_api
        return (
          <Box column>
            <Box>
              <Box>
                <Box>
                  {e?.workstation_name} succeed rate ({workInfos?.min} min): (cmd ct:{' '}
                  <TextSucceed>{e?.f_num_working_count}</TextSucceed> (
                  <TextSucceed>{parseInt(e?.working_count / e?.f_num_working_count)}</TextSucceed>{' '}
                  work per cmd))
                  <TextSucceed>{e?.succeed_count}</TextSucceed>/{e?.working_count}
                  <PercentModule
                    succeed_limit={70}
                    value={e?.succeed_count}
                    valueMax={e?.working_count}
                  />
                </Box>
              </Box>
            </Box>
            <Box gap={12} column>
              {e?.vmware_infos?.map((info, index) => {
                return (
                  <Box key={index}>
                    <Box>{info?.vmware_name}</Box>
                    <Box>
                      {' '}
                      <TextSucceed>{currencyComma(info?.f_num_list?.length)}</TextSucceed>
                    </Box>
                    <Box>cmds: {info?.f_num_list_str}</Box>
                    <Box>
                      <TextSucceed>{info?.succeed_count}</TextSucceed>/{info?.working_count}
                      <PercentModule
                        succeed_limit={70}
                        value={info?.succeed_count}
                        valueMax={info?.working_count}
                      />{' '}
                      cpu:{info?.cpu_per}%, mem[{(info.mem_size / 1024)?.toFixed(1)}GB]:
                      {info?.mem_per}%
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
      {/* </Grid> */}
    </>
  );
};
export default WorkVmwareOtherLogInfos;
