import useMutations from 'Common/Service/slotMutations';
import {
  requestLoadSlotGroupGetNames,
  requestLoadUserGetNames,
  requestLoadUserNameCheck,
} from 'Common/Service/user';
import { Button, CheckBox, Grid, Icon, InputComponent, Tooltip } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { FormInput } from 'Common/UI/organisms/FormControl';
import { navigate_newtab } from 'Common/Utils';
import {
  debounce,
  getNumbers,
  getRandomAlphabet,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const ProductAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [result, setResults] = useState({});
  const [isDisableSlotGroupName, setIsDisableSlotGroupName] = useState(true);
  const [isActiveTooltipForce, setIsActiveTooltipForce] = useState(false);
  const [slotType, setSlotType] = useState('etc_do1_1000_7');
  const [issueType, setIssueType] = useState();
  const [slotDays, setSlotDays] = useState(7);
  // const [slotCountMax, setSlotCountMax] = useState();
  const [toClickCountMax, setToClickCountMax] = useState(75);
  const [slotAddInfos, setSlotAddInfos] = useState([]);
  const [slotIds, setSlotIds] = useState([]);
  const [slotAddInfoParams, setSlotAddInfoParams] = useState({});
  // const [slotAddCount, setSlotAddCount] = useState(0);
  const [slotAddCountMax, setSlotAddCountMax] = useState(0);

  const [selectedSlotGroupName, setSelectedSlotGroupName] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const [selectedPartnerName, setSelectedPartnerName] = useState();
  const [partnerNames, setPartnerNames] = useState([
    { title: '전체', value: null, selected: true },
  ]);
  const [slotGroupNames, setSlotGroupNames] = useState([
    { title: '신규', value: null, selected: true },
    // { title: '미분류', value: 'none', selected: true },
  ]);
  const [isUserNameCheck, setIsUserNameCheck] = useState(null);
  const [isUserNameMessage, setIsUserNameMessage] = useState();
  // const [userNames, setUserNames] = useState([{ title: '미분류', value: 'none', selected: true }]);
  const [userNames, setUserNames] = useState([
    // { title: '신규', value: null, selected: true }
  ]);
  const [productIds, setProductIds] = useState();
  const [modal, setModal] = useState({
    title: '',
    successText: '삭제',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, idsActive: false });
    },
  });
  const forceUpdate = useForceUpdate();

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1)),
  );
  const [extendDay, setExtendDay] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 10)));

  const onUserNameInputChange = async (user_name) => {
    InputSearchDebounce(user_name);
  };
  const [buttonDisabledChk, setButtonDisabledChk] = useState(false);
  const check_user_name = async (user_name) => {
    const partner_name = selectedPartnerName;
    const result = await requestLoadUserNameCheck({ partner_name, user_name });
    setIsUserNameCheck(result?.result);
    setIsUserNameMessage(result?.message);
    if (result?.result) {
      if (user_name) {
        setSelectedSlotGroupName(
          `${user_name}_${today_str()
            .replaceAll('-', '')
            .slice(2)}_${getRandomAlphabet()}${getRandomAlphabet()}`,
        );
        selectUserName(user_name);
        setIsDisableSlotGroupName(false);
      } else {
        setIsDisableSlotGroupName(true);
        setSlotGroupNames([{ title: '신규', value: null, selected: true }]);
        setSelectedSlotGroupName(null);
      }
    } else {
      setIsDisableSlotGroupName(true);
      setSlotGroupNames([{ title: '신규', value: null, selected: true }]);
      setSelectedSlotGroupName(null);
    }
  };
  const InputSearchDebounce = useCallback(
    debounce((user_name) => {
      check_user_name(user_name);
    }, 300),
    [],
  );
  const { requestLoadSlotAddMutation, requestLoadPartnerGetNamesMutation } =
    useMutations(setResults);

  useEffect(() => {
    if (result?.request === 'requestLoadSlotAddMutation') {
      if (result?.success && result?.result !== undefined) {
        if (!result?.result?.result) {
          dispatch(
            onShowToast({
              status: 'error',
              message: '오류가 발생했습니다',
              cancelText: '',
              onCancel: false,
              timer: 15000,
            }),
          );
          setButtonDisabledChk(false);
          return;
        }
        dispatch(
          onShowToast({
            status: 'success',
            message: '상품 등록이 정상적으로 완료되었어요',
            cancelText: '',
            onCancel: false,
          }),
        );
        const user_name = result?.result?.user_name;
        const issue_daetime = result?.result?.issue_datetime.slice(5, 16);
        setButtonDisabledChk(false);
        setTimeout(() => {
          navigate_newtab(`/product/slot?user=${user_name}&slot=${issue_daetime}&active=s`);
        }, 2000);
      } else {
        dispatch(
          onShowToast({
            status: 'error',
            message: '오류가 발생 했습니다.',
            cancelText: '',
            onCancel: false,
          }),
        );
        setButtonDisabledChk(false);
      }
    }
    // else if (result?.request === 'requestLoadSlotAddMutation') {
    //   if (result?.success && result?.result !== undefined) {
    //     const data = result?.result;
    //     if (!data?.result) {
    //       dispatch(
    //         onShowToast({
    //           status: 'error',
    //           message: data?.message,
    //           cancelText: '',
    //           onCancel: false,
    //           timer: 15000,
    //         }),
    //       );
    //       setProductIds('');
    //       // setIsNewSlotGroupName('');
    //       return;
    //     }
    //     const slot_add_count = data.count + data.product_ids.length;
    //     const slot_add_count_max = slotIds.length;

    //     const list = slotAddInfos.map((e) => {
    //       const product = data?.product_results?.find((i) => i.product_id == e.product_id);
    //       return {
    //         ...e,
    //         ...product,
    //       };
    //     });
    //     setSlotAddInfos(list);

    //     setSlotAddCount(slot_add_count);
    //     if (slot_add_count >= slot_add_count_max) {
    //       dispatch(
    //         onShowToast({
    //           status: 'success',
    //           message: '상품 등록이 정상적으로 완료되었어요',
    //           cancelText: '',
    //           onCancel: false,
    //         }),
    //       );
    //       setProductIds('');
    //       return;
    //     }

    //     var n_slot_add_count = slot_add_count + 5 + random(50);
    //     // var n_slot_add_count = slot_add_count + 1;
    //     if (n_slot_add_count > slot_add_count_max) n_slot_add_count = slot_add_count_max;

    //     setSlotAddCountMax(slot_add_count_max);
    //     const add_product_ids = slotIds.slice(slot_add_count, n_slot_add_count);

    //     const params = {
    //       ...slotAddInfoParams,
    //       count: slot_add_count,
    //       product_ids: add_product_ids,
    //     };
    //     setSlotAddInfoParams(params);
    //     requestLoadSlotAddMutation.mutate(params);
    //   } else {
    //     dispatch(
    //       onShowToast({
    //         status: 'error',
    //         message: '오류가 발생 했습니다.',
    //         cancelText: '',
    //         onCancel: false,
    //       }),
    //     );
    //   }
    // }
    else if (result?.request === 'requestLoadPartnerGetNamesMutation') {
      if (result?.success && result?.result !== undefined) {
        const list1 = [{ title: '전체', value: null, selected: true }];
        var list2 = result?.result?.map((e, idx) => {
          return {
            title: e,
            value: e,
          };
        });
        if (list2 === undefined) list2 = [];

        setPartnerNames([...list1, ...list2]);
      } else {
        dispatch(
          onShowToast({
            status: 'error',
            message: '오류가 발생 했습니다.',
            cancelText: '',
            onCancel: false,
          }),
        );
      }
    }
    setResults({});
  }, [result.request]);

  const preAddProduct = ({ slot_count, slot_type }) => {
    const params = {
      slot_type,
      slot_count,
      user_name: selectedUserName,
      start_date: today_str(startDate),
      end_date: today_str(endDate),
    };
    setSlotAddInfoParams(params);
    setResults({ result: { request: 'init' } });
    requestLoadSlotAddMutation.mutate(params);
  };

  const selectPartnerName = async (partner_name = null) => {
    const result = await requestLoadUserGetNames({ partner_name });

    const list1 = [];
    var list2 = [];
    if (loginInfo.user_level >= 4) {
      list2 = result?.map((e, _idx) => {
        const title = e.user_name;
        return {
          title,
          value: e.user_name,
        };
      });
    } else {
      setSelectedPartnerName(loginInfo.user_name);
      list2 = result?.map((e, _idx) => {
        const title = e.user_name;
        return {
          title,
          value: e.user_name,
        };
      });
    }
    if (list2 === undefined) list2 = [];
    setUserNames([...list1, ...list2]);

    setSelectedUserName(null);
  };
  const selectUserName = async (user_name = null) => {
    const result = await requestLoadSlotGroupGetNames({ user_name });

    var list1 = [];
    if (user_name === 'none' || user_name === null) {
      list1.push({ title: '신규', value: null, selected: true });
    } else {
      list1.push({ title: '신규', value: null, selected: true });
    }

    var list2 = [];
    if (loginInfo.user_level >= 4) {
      list2 = result?.map((e, _idx) => {
        const title =
          e.partner_name || e.user_name
            ? // ? `[${e.partner_name}-${e.user_name}] ${e.group_name}`
              // `[${e.partner_name}-${e.user_name}] ${e.group_name}`
              e.group_name
            : e.group_name;
        return {
          title,
          value: e.group_name,
          // selected: selectedUserName === e,
        };
      });
    } else if (loginInfo.user_level >= 3) {
      list2 = result?.map((e, _idx) => {
        const title = e.user_name
          ? // `[${e.user_name}] ${e.group_name}`
            e.group_name
          : e.group_name;
        return {
          title,
          value: e.group_name,
          // selected: selectedUserName === e,
        };
      });
    } else {
      list2 = result?.map((e, _idx) => {
        const title = e.group_name;
        return {
          title,
          value: e.group_name,
          // selected: selectedUserName === e,
        };
      });
    }
    if (list2 === undefined) list2 = [];
    const list = [...list1, ...list2];
    setSlotGroupNames(list);
    setSelectedSlotGroupName(null);
    setSelectedSlotGroupName(
      `${user_name}_${today_str()
        .replaceAll('-', '')
        .slice(2)}_${getRandomAlphabet()}${getRandomAlphabet()}`,
    );

    // setIsNewSlotGroupName(true);
  };
  // const resetDropboxUserNames = (user = null) => {
  //   var newChk = true;
  //   const user_name = user ? user : selectedUserName;
  //   var list = userNames?.map((user) => {
  //     const selected = user.value === user_name;
  //     if (selected) newChk = false;
  //     return {
  //       ...user,
  //       selected,
  //     };
  //   });
  //   if (newChk) {
  //     list = userNames?.map((user) => {
  //       const selected = user.value === null;
  //       return {
  //         ...user,
  //         selected,
  //       };
  //     });
  //   }
  //   setUserNames(list);
  // };
  const resetDropboxGroupNames = (group = null) => {
    var newChk = true;
    const group_name = group ? group : selectedSlotGroupName;
    var list = slotGroupNames?.map((group) => {
      const selected = group.value === group_name;
      if (selected) newChk = false;
      return {
        ...group,
        selected,
      };
    });
    if (newChk) {
      list = slotGroupNames?.map((group) => {
        const selected = group.value === null;
        return {
          ...group,
          selected,
        };
      });
    }
    setSlotGroupNames(list);
  };

  useEffect(() => {
    if (loginInfo?.user_level >= 4) {
      requestLoadPartnerGetNamesMutation.mutate({});
      selectPartnerName();
    } else if (loginInfo?.user_level === 3) {
      const partner_name = loginInfo?.user_name;
      selectPartnerName(partner_name);
    } else if (loginInfo?.user_level === 2) {
      selectedUserName(loginInfo.user_name);
      setIsUserNameCheck(true);
      setIsUserNameMessage();
    }
  }, []);
  if (loginInfo?.user_level <= 2) return <></>;
  return (
    <>
      <Grid column={['1fr']} gap={12}>
        <Box valign="top" column gap={34}>
          {loginInfo?.user_level >= 3 && (
            <Box column>
              <LabelBox label={'슬롯 발급 유저'}>
                <InputComponent
                  isEdit
                  fill
                  name="id"
                  placeholder={'사용자 아이디를 입력해 주세요'}
                  value={selectedUserName}
                  size={50}
                  onChange={(e) => {
                    var user_name = e?.target?.value;
                    if (user_name === '') user_name = null;
                    if (user_name === 'none') user_name = null;
                    setIsUserNameCheck();
                    setIsUserNameMessage();
                    setSelectedUserName(user_name);
                    onUserNameInputChange(user_name);
                    setSelectedSlotGroupName();
                  }}
                />
              </LabelBox>
              {/* </Box> */}
              <Box>
                {isUserNameCheck === false && selectedUserName && (
                  <StatusText isError={true}>{isUserNameMessage}</StatusText>
                )}
                {isUserNameCheck === true && selectedUserName && (
                  <StatusText isError={false}>{isUserNameMessage}</StatusText>
                )}
              </Box>
            </Box>
          )}
          <Box>
            <LabelBox label={'슬롯 종류'}>
              <Box gap={24}>
                {/* <CheckBox
                  checked={slotType === 'etc_do2_300_10'}
                  type={'radio'}
                  onChange={() => {
                    setSlotType('etc_do2_300_10');
                    setIssueType('new');
                    setSlotDays(10);

                    setEndDate(new Date(new Date().setDate(new Date().getDate() + parseInt(10))));
                  }}
                >
                  비로그인_유입(300타)x10일
                </CheckBox> */}

                {/* <CheckBox
                  checked={slotType === 'etc_do1_3_5'}
                  type={'radio'}
                  // disabled
                  onChange={() => {
                    setSlotType('etc_do1_3_5');
                    setIssueType('new');
                    setSlotDays(5);

                    setEndDate(
                      new Date(new Date().setDate(new Date(startDate).getDate() + parseInt(4))),
                    );
                  }}
                >
                  (test)타입1 x 3일
                </CheckBox>
                <CheckBox
                  checked={slotType === 'click_3_5'}
                  type={'radio'}
                  // disabled
                  onChange={() => {
                    setSlotType('click_3_5');
                    setIssueType('new');
                    setSlotDays(5);

                    setEndDate(
                      new Date(new Date().setDate(new Date(startDate).getDate() + parseInt(4))),
                    );
                  }}
                >
                  (test)타입2 x 3일
                </CheckBox> */}

                <CheckBox
                  checked={slotType === 'etc_do1_75_7'}
                  type={'radio'}
                  // disabled
                  onChange={() => {
                    setSlotType('etc_do1_75_7');
                    setIssueType('new');
                    setSlotDays(7);

                    setEndDate(
                      new Date(new Date().setDate(new Date(startDate).getDate() + parseInt(6))),
                    );
                  }}
                >
                  {/* 타입1 x  */}
                  7일
                </CheckBox>

                <CheckBox
                  checked={slotType === 'etc_do1_75_10'}
                  type={'radio'}
                  // disabled
                  onChange={() => {
                    setSlotType('etc_do1_75_10');
                    setIssueType('new');
                    setSlotDays(10);

                    setEndDate(
                      new Date(new Date().setDate(new Date(startDate).getDate() + parseInt(9))),
                    );
                  }}
                >
                  {/* 타입1 x  */}
                  10일
                </CheckBox>
                {/* <CheckBox
                  checked={slotType === 'click_75_7'}
                  type={'radio'}
                  // disabled
                  onChange={() => {
                    setSlotType('click_75_7');
                    setIssueType('new');
                    setSlotDays(7);

                    setEndDate(
                      new Date(new Date().setDate(new Date(startDate).getDate() + parseInt(6))),
                    );
                  }}
                >
                  타입2 x 7일
                </CheckBox> */}
                {/* <CheckBox
                  checked={slotType === 'test_etc_do1_75_3'}
                  type={'radio'}
                  onChange={() => {
                    setSlotType('test_etc_do1_75_3');
                    setIssueType('test');
                    setSlotDays(3);
                    setEndDate(
                      new Date(
                        new Date(startDate).setDate(new Date(startDate).getDate() + parseInt(2)),
                      ),
                    );
                  }}
                >
                  타입1 x 3일[테스트]
                </CheckBox> */}
                {/* <CheckBox
                  checked={slotType === 'test_click_75_3'}
                  type={'radio'}
                  onChange={() => {
                    setSlotType('test_click_75_3');
                    setIssueType('test');
                    setSlotDays(3);
                    setEndDate(
                      new Date(
                        new Date(startDate).setDate(new Date(startDate).getDate() + parseInt(2)),
                      ),
                    );
                  }}
                >
                  타입2 x 3일 [테스트]
                </CheckBox> */}
                {/* 
                <CheckBox
                  checked={slotType === 'click_70_7'}
                  type={'radio'}
                  onChange={() => {
                    setSlotType('click_70_7');
                    setIssueType('new');
                    setSlotDays(7);
                    setEndDate(
                      new Date(
                        new Date(startDate).setDate(new Date(startDate).getDate() + parseInt(6)),
                      ),
                    );
                  }}
                >
                  유효타(70타)x7일
                </CheckBox>
                <CheckBox
                  checked={slotType === 'test_click_70_1'}
                  type={'radio'}
                  onChange={() => {
                    setSlotType('test_click_70_1');
                    setIssueType('new');
                    setSlotDays(1);
                    setEndDate(
                      new Date(
                        new Date(startDate).setDate(new Date(startDate).getDate() + parseInt(0)),
                      ),
                    );
                  }}
                >
                  유효타(70타)x1일(테스트)
                </CheckBox> */}
              </Box>
            </LabelBox>
          </Box>
          <Box>
            <LabelBox label={'시작일'}>
              <FormInput.Datepicker
                // disabled={isDisableSlotGroupName}
                // type="multiple"
                row
                value={startDate}
                placeholder="YYYY.MM.DD"
                onChange={(_, e) => {
                  if (e === 'Invalid date') return;
                  if (new Date(e) < new Date()) {
                    dispatch(
                      onShowToast({
                        status: 'error',
                        message: '시작 날짜가 잘못 되었습니다.',
                        cancelText: '',
                        onCancel: false,
                        timer: 5000,
                      }),
                    );
                    setStartDate(startDate);
                    forceUpdate();
                    return;
                  }
                  setStartDate(e);
                  setEndDate(
                    new Date(
                      new Date(e).setDate(new Date(e).getDate() + parseInt(parseInt(slotDays) - 1)),
                    ),
                  );
                  //   setStartDate(e.start);
                  //   setEndDate(e.end);
                }}
              />
            </LabelBox>
            <LabelBox label={'종료일'}>
              <FormInput.Datepicker
                disabled
                // disabled={isDisableSlotGroupName}
                // type="multiple"
                row
                value={endDate}
                placeholder="YYYY.MM.DD"
                onChange={(_, e) => {
                  if (e === 'Invalid date') return;
                  //   setStartDate(e.start);
                  setEndDate(e);
                }}
              />
            </LabelBox>
            {/* <LabelBox label={'일수 선택'}>
              <Box width={200} gap={10}>
                <LabelBox row back label={'일'}>
                  <InputComponent
                    value={extendDay}
                    onChange={(e) => {
                      setExtendDay(getNumbers(e.target.value));
                    }}
                    size={45}
                  />
                </LabelBox>
                <Box bottom={8}>
                  <Button
                    size="md"
                    disabled={!extendDay}
                    onClick={() => {
                      setEndDate(
                        new Date(new Date().setDate(new Date().getDate() + parseInt(extendDay))),
                      );
                    }}
                  >
                    적용
                  </Button>
                </Box>
              </Box>
            </LabelBox> */}
            {/* <InputComponent /> */}
          </Box>
          {/* <DropBox
            disabled={isDisableSlotGroupName}
            title={'슬롯그룹'}
            isColor
            option={slotGroupNames}
            size={44}
            onChange={(_name, value) => {
              // setIsNewSlotGroupName(!value);

              setSelectedSlotGroupName(value);
              value && setSelectedSlotGroupName(value);
            }}
          /> */}
          {/* {isNewSlotGroupName && */}
          {/* <LabelBox label={'등록 슬롯 그룹명'}>
            <InputComponent
              isEdit
              disabled={isDisableSlotGroupName}
              size={50}
              name="id"
              placeholder={''}
              value={selectedSlotGroupName}
              onChange={(e) => {
                const group_name = e?.target?.value?.replace(' ', '');
                if (!group_name) return;
                setSelectedSlotGroupName(group_name);
                resetDropboxGroupNames(group_name);
              }}
            />
          </LabelBox> */}
          <LabelBox row label={'등록할 슬롯갯수'}>
            <Box>
              <InputComponent
                disabled={isDisableSlotGroupName}
                isEdit
                placeholder={''}
                value={slotAddCountMax}
                onChange={(e) => {
                  setSlotAddCountMax(getNumbers(e.target.value));
                }}
              />
            </Box>
          </LabelBox>
          {/* <LabelBox label={'상품번호'}>
            <InputComponent
              disabled={isDisableSlotGroupName}
              size={350}
              isEdit
              el="textarea"
              name="id"
              placeholder={''}
              value={productIds}
              onChange={(e) => {
                setProductIds(e.target.value);
              }}
            />
            <Box top={8}>
              <Box width="100%">
                <TooltipBox left={'50%'} bottom={'20rem'}>
                  <Tooltip
                    direction={'top'}
                    className={'tooltip'}
                    left={31}
                    padding={16}
                    fill="#343A40"
                    isActive={isActiveTooltipNormal}
                  >
                    <Box top={10} />
                    <ToolTipText>신규 등록</ToolTipText>
                    <ToolTipSubText>등록된 상품은 자동으로 제외됩니다</ToolTipSubText>
                    <Box bottom={15} />
                  </Tooltip>
                </TooltipBox>

                <Button
                  fill
                  onMouseOver={() => {
                    setIsActiveTooltipNormal(true);
                  }}
                  onMouseLeave={() => {
                    setIsActiveTooltipNormal(false);
                  }}
                  disabled={
                    true ||
                    isDisableSlotGroupName ||
                    !selectedUserName ||
                    !isUserNameCheck ||
                    !selectedSlotGroupName ||
                    !getNumbers(productIds)
                  }
                  onClick={() => {
                    const product_ids = productIds
                      ?.split('\n')
                      ?.filter((e) => e?.trim()?.length >= 1);
                    // const product_ids = productIds
                    // ?.filter((e) => e?.trim()?.length === 10);
                    if (!product_ids || product_ids?.length <= 0) {
                      dispatch(
                        onShowToast({
                          status: 'error',
                          message: '등록할 상품이 없습니다',
                          cancelText: '',
                          onCancel: false,
                        }),
                      );
                      setProductIds('');
                      return;
                    }
                    const product_count = product_ids?.length;
                    setModal({
                      ...modal,
                      title: '신규 등록',
                      text: `[${product_count}] 개의 상품을 등록하겠습니까?`,
                      successText: '등록',
                      isActive: true,
                      cancelText: '취소',
                      onSuccess: () => {
                        const force_change_chk = false;
                        preAddProduct({ product_ids, force_change_chk });
                      },
                    });
                  }}
                >
                  신규 등록
                </Button>
              </Box>

              <Box width="100%">
                <TooltipBox left={'50%'} bottom={'20rem'}>
                  <Tooltip
                    direction={'top'}
                    className={'tooltip'}
                    left={31}
                    padding={16}
                    fill="#343A40"
                    isActive={isActiveTooltipForce}
                  >
                    <Box top={10} />
                    <ToolTipText>강제 등록</ToolTipText>
                    <ToolTipSubText>등록된 상품도 강제로 등록됩니다</ToolTipSubText>
                    <ToolTipSubText>(이전 그룹에 등록된상품은 자동으로 제외됩니다)</ToolTipSubText>
                    <Box bottom={15} />
                  </Tooltip>
                </TooltipBox>
                <Button
                  onMouseOver={() => {
                    setIsActiveTooltipForce(true);
                  }}
                  onMouseLeave={() => {
                    setIsActiveTooltipForce(false);
                  }}
                  fill
                  Theme={'unFill'}
                  disabled={
                    isDisableSlotGroupName ||
                    !selectedUserName ||
                    !slotAddCountMax ||
                    // !slotCountMax ||
                    !isUserNameCheck ||
                    !selectedSlotGroupName

                    // ||
                    // !getNumbers(productIds)
                  }
                  onClick={() => {
                    const product_ids = productIds
                      ?.split('\n')
                      ?.filter((e) => e?.trim()?.length >= 1);

                    const product_count = product_ids?.length;
                    const slot_add_ct_max = parseInt(slotAddCountMax);
                    const add_ct =
                      product_count > slot_add_ct_max ? product_count : slot_add_ct_max;
                    if (add_ct <= 0) return;
                    setModal({
                      ...modal,
                      title: '강제 등록',
                      text: `[${add_ct}] 개의 상품을 등록하겠습니까?`,
                      successText: '등록',
                      isActive: true,
                      cancelText: '취소',
                      onSuccess: () => {
                        const force_change_chk = true;
                        preAddProduct({ product_ids, force_change_chk });
                      },
                    });
                  }}
                >
                  강제 등록
                </Button>
              </Box>
            </Box>
          </LabelBox> */}
          <Button
            onMouseOver={() => {
              setIsActiveTooltipForce(true);
            }}
            onMouseLeave={() => {
              setIsActiveTooltipForce(false);
            }}
            fill
            // Theme={'unFill'}
            disabled={
              // isDisableSlotGroupName ||
              buttonDisabledChk ||
              !selectedUserName ||
              !(parseInt(slotAddCountMax) > 0) ||
              // !slotCountMax ||
              !isUserNameCheck ||
              // !selectedSlotGroupName
              !slotType

              // ||
              // !getNumbers(productIds)
            }
            onClick={() => {
              // const product_ids = productIds?.split('\n')?.filter((e) => e?.trim()?.length >= 1);

              // const product_count = product_ids?.length;
              const slot_count = parseInt(slotAddCountMax);
              const slot_type = slotType;
              // const add_ct = product_count > slot_add_ct_max ? product_count : slot_add_ct_max;
              if (slot_count <= 0) return;
              setModal({
                ...modal,
                title: '발급',
                text: `[${slot_count}] 개의 상품을 등록하겠습니까?`,
                successText: '등록',
                isActive: true,
                cancelText: '취소',
                onSuccess: () => {
                  setButtonDisabledChk(true);
                  preAddProduct({ slot_count, slot_type });
                },
              });
            }}
          >
            신규 발행
          </Button>
        </Box>
        {false && (
          <Box column align="top">
            {/* {slotAddInfoCountMax > 0 && ( */}
            <>
              {/* <SlotBlock> */}
              {/* [{slotAddCount} / {slotAddInfoCountMax}]{' '} */}
              {/* {selectedUserName ? selectedUserName : '미분류'} -{' '} */}
              {/* {selectedSlotGroupName === 'none' ? '미분류' : selectedSlotGroupName} */}
              {/* </SlotBlock> */}
              {slotAddInfos?.map((e, idx) => {
                const status = e.status;
                const message = e.message;
                const product_id = e.product_id;
                return (
                  <SlotBlock key={idx}>
                    <SlotNumStatus>{idx + 1}</SlotNumStatus>
                    <SlotIdDiv>{product_id}</SlotIdDiv>
                    <SlotStatus>
                      {status === 'ok' ? (
                        <>
                          <Icon.Complate size={20} /> {message}
                        </>
                      ) : status === 'duplication' ? (
                        <>
                          <Icon.Warning size={20} /> {message}
                        </>
                      ) : status === 'fail' ? (
                        <>
                          <Icon.Danger size={20} /> {message}
                        </>
                      ) : (
                        <>
                          <Icon.Watch size={20} /> 등록 대기 중
                        </>
                      )}
                    </SlotStatus>
                  </SlotBlock>
                );
              })}
            </>
            {/* )} */}
          </Box>
        )}
      </Grid>
      <ModalQuestion {...modal} />
    </>
  );
};

const TooltipBox = styled.div`
  z-index: 23;
  flex-direction: row;
  position: relative;
  left: 0rem;
  ${(props) => {
    const { left, bottom } = props;
    return css`
      left: ${left ? left : '0rem'};
      /* left: 50%; */
      bottom: ${bottom ? bottom : '0rem'};
    `;
  }};
`;
const ToolTipText = styled.div`
  padding: 5rem;
  white-space: pre-line;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 18rem;
  line-height: 20rem;

  color: #ffffff;
`;
const ToolTipSubText = styled.div`
  white-space: pre-line;

  font-family: 'Pretendard';
  font-style: normal;
  line-height: 20rem;

  color: #ffffff;

  font-weight: 400;
  font-size: 12rem;
`;

const SlotBlock = styled.div`
  display: flex;
`;
const SlotNumStatus = styled.div`
  display: flex;
  border: 1rem;
  width: 50rem;
  font-size: 20rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`;
const SlotIdDiv = styled.div`
  display: flex;
  flex: 1;
  border: 1rem;
  font-size: 20rem;
  font-weight: 500;
  justify-content: center;
  border-style: solid;
  border-width: 1rem;
  align-items: center;
  background-color: #d5dee8;
  border-radius: 12rem;
`;
const SlotStatus = styled.div`
  font-size: 18rem;
  font-weight: 400;
  padding-left: 10rem;
  display: flex;
  gap: 8rem;
  flex: 3;
  align-items: center;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  width: 100%;
  justify-content: space-between;
  color: #18795a;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 25rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { isError } = props;
    if (isError)
      return `
      color: #f00000;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 

      // background-color: #bc340b;
          
      `;
  }}
`;
export default ProductAdd;
