import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './Common/UI/GlobalStyles';
import App from './App';
import { store } from './Common/store';
import theme from './Common/UI/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import MallInfo from 'MallInfo';
import TestTable from 'TestTable';

let persistor = persistStore(store);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <GlobalStyles />
            <Routes>
              <Route path="/mall/:mallNames" element={<MallInfo graph_chk />} />
              <Route path="/test_slot/" element={<MallInfo graph_chk mall_names={'오야르'} />} />
              {/* <Route path="/table/" element={<TestTable />} /> */}
              <Route path="/*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </QueryClientProvider>,
  // </React.StrictMode>,
);
