import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 1;
  .example-custom-input {
    display: flex;
  }
  .react-datepicker {
    display: flex;
    flex-direction: column;
  }
  &.multipleMonth {
    .react-datepicker__month-container {
      .custormHeader {
        .headerDate + button {
          display: none;
        }
      }
      + .react-datepicker__month-container {
        .custormHeader {
          button {
            display: none;
          }
          .headerDate + button {
            display: block;
          }
        }
      }
    }
  }
  .react-datepicker__day--disabled {
    color: var(--G_100) !important;
    background-color: transparent !important;
  }
  .react-datepicker-time__header {
    display: none;
  }
  .react-datepicker__time-box {
  }
  .react-datepicker__time-list {
    max-height: 170rem;
    overflow: auto;
  }
  .react-datepicker__time-list-item {
    position: relative;
    padding: 4rem 0 4rem 14rem;
    font-weight: 400;
    font-size: 14rem;
    line-height: 20rem;
    color: #94a1c7;
    cursor: pointer;
    border-radius: 8rem;
    border: 1rem solid transparent;
    &::after {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 5rem;
      height: 100%;
      border-radius: 8rem 0 0 8rem;
      content: '';
    }
    &:hover {
      border-color: var(--P_500);
      &::after {
        background-color: var(--P_500);
      }
    }
    &.react-datepicker__time-list-item--selected {
      border-color: var(--P_700);
      &::after {
        background-color: var(--P_700);
      }
    }
  }
  .react-datepicker-popper {
    position: realtive;
    inset: 10rem 0rem auto auto;
    background: var(--White);
    box-shadow: var(--Elevation_004);
    border-radius: 8rem;
  }
  .react-datepicker__navigation {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    text-indent: -99999rem;
    overflow: hidden;
    &.react-datepicker__navigation--previous {
      left: 0;
      top: 0;
      background-color: red;
      width: 8rem;
      height: 8rem;
    }
  }
  .react-datepicker__month-container {
    min-height: 320rem;
    padding: 22rem 18rem;
  }

  .react-datepicker__day-names {
    display: flex;
    margin-bottom: 8rem;
    .react-datepicker__day-name {
      font-weight: 500;
      font-size: 12rem;
      line-height: 170%;
      text-align: center;
      color: var(--G_800);
      flex: 0 28rem;
      + .react-datepicker__day-name {
        margin-left: 8rem;
      }
    }
  }
  .react-datepicker__week {
    display: flex;
    .react-datepicker__day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28rem;
      height: 28rem;
      font-weight: 400;
      font-size: 12rem;
      color: var(--G_600);
      text-align: center;
      cursor: pointer;
      border-radius: 50rem;
      &:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--selected):hover {
        background: var(--P_500);
        font-weight: 400;
        color: var(--White);
      }
      &.react-datepicker__day--today {
        font-size: 12rem;
        line-height: 130%;
        display: flex;
        color: var(--S_500);
      }
      &.react-datepicker__day--selected {
        background: var(--P_500);
        color: var(--White);
      }
      &.react-datepicker__day--outside-month {
        color: var(--G_100);
        background-color: transparent;
      }
      &.react-datepicker__day--in-range {
        position: relative;
        background: var(--P_700);
        color: var(--White);
        border-radius: 0;
        &::after {
          display: block;
          position: absolute;
          left: -8rem;
          top: 0;
          width: 8rem;
          height: 100%;
          content: '';
          background-color: var(--P_700);
        }
        &.react-datepicker__day--outside-month {
          background-color: transparent;
          &::after {
            display: none;
          }
        }

        &.react-datepicker__day--range-start {
          border-radius: 50rem 0 0 50rem;
          &::after {
            display: none;
          }
        }
        &.react-datepicker__day--range-end {
          border-radius: 0 50rem 50rem 0;
        }
      }
      & + .react-datepicker__day {
        margin-left: 8rem;
      }
    }
    + .react-datepicker__week {
      margin-top: 8rem;
    }
  }
  .react-datepicker__month-wrapper {
    display: flex;
    .react-datepicker__month-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 68rem;
      height: 40rem;
      font-weight: 400;
      font-size: 12rem;
      color: var(--G_600);
      border-radius: 50rem;
      cursor: pointer;
      &.react-datepicker__month-text--today {
        color: var(--S_500);
      }
      &.react-datepicker__month--selected {
        background: var(--P_800);
        color: var(--White);
      }
      &:not(.react-datepicker__month--selected):not(
          .react-datepicker__month-text--keyboard-selected
        ):not(.react-datepicker__day--in-range):hover {
        background: var(--P_500);
        font-weight: 400;
        color: var(--White);
      }
      + .react-datepicker__month-text {
        margin-left: 12rem;
      }
    }
    + .react-datepicker__month-wrapper {
      margin-top: 6rem;
    }
  }
`;

export const InnerMont = styled.div`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: var(--White);
  box-shadow: var(--Elevation_004);
  border-radius: 8rem;
  .react-datepicker__month-wrapper {
    width: 100%;
    .react-datepicker__month-text {
      flex: 1 1 33.33%;
      height: 40rem;
      &.react-datepicker__month-text--keyboard-selected {
        background-color: var(--P_800);
        color: var(--White);
      }
    }
  }
  .bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .textClose {
      margin-left: auto;
    }
  }
`;

export const TimepickerBox = styled.div`
  position: relative;
  width: ${(props) => (props.fill ? '100%' : '120rem')};
  .iconDelete {
    display: none;
  }
  .react-datepicker__header--time {
    display: none;
  }
  .react-datepicker-popper {
    width: 100%;
    margin-top: 8rem;
    background: var(--White);
    box-shadow: var(--Elevation_004);
    border-radius: 8rem;
    ul {
      padding: 8rem 0;
      max-height: 140rem;
      overflow: auto;
      li {
        padding: 4rem 20rem;
        font-weight: 400;
        font-size: 16rem;
        line-height: 172%;
        cursor: pointer;
        color: var(--G_700);
        + li {
          margin-top: 4rem;
        }
        &:not(.react-datepicker__time-list-item--disabled):hover {
          background-color: var(--P_25);
          color: var(--P_500);
        }
        &.react-datepicker__time-list-item--disabled {
          color: var(--G_150);
        }
      }
    }
  }
`;

export const DatepickerHeaderBox = styled.div`
  display: flex;
  height: 28rem;
  align-items: center;
  margin-bottom: 14rem;
  .headerDate {
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 auto;
    padding: 0 20rem;
    font-weight: 500;
    font-size: 14rem;
    line-height: 172%;
    text-align: center;
    letter-spacing: -0.04em;
    color: var(--Black);
    .btnShowMonth {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`;

export const BottomSection = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32rem;
  height: 60rem;
  background: var(--G_25);
  border-radius: 0rem 0rem 12rem 12rem;
  font-weight: 400;
  font-size: 14rem;
  line-height: 172%;
  letter-spacing: -0.03em;
  color: var(--G_300);
  span {
    margin-right: auto;
  }
`;
