import React from 'react';
import styled from 'styled-components';
function Layout({ children, id }) {
  return <Wrapper id={id}>{children}</Wrapper>;
}
const Wrapper = styled.div`
  width: 100%;
  max-width: 1560rem;
  padding: 80rem 0 0rem;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1860rem) {
    margin: 0;
  }
  min-height: calc(100vh - 80rem);
  color: var(--G_600);
`;
export default Layout;
