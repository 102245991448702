import { Box, Button, CheckBox, DropBox, Grid, InputComponent } from 'Common/UI/atoms';
import { Table, Tbody, THeader } from 'Common/UI/organisms/FormTabel/styeld';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import useMutations from 'Common/Service/slotMutations';
import { onShowToast } from 'features/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import LabelBox from 'Common/UI/molecules/LabelBox';
import Highlight from 'react-highlighter';
import { getNumbers, slotStatus, useForceUpdate } from 'Common/Utils/util';
import {
  requestLoadPaymentGetSlots,
  requestLoadSlotAsExtendsDay,
  requestLoadSlotDel,
  requestLoadSlotExtendsDay,
  requestLoadSlotPaid,
} from 'Common/Service/slot';
import { FormInput } from 'Common/UI/organisms/FormControl';
import PaginationBox from 'Page/Module/PaginationBox';

function PaymentSlot({
  reloadStatus,
  start_date,
  end_date,
  issue_type,
  slot_status,
  partner_name,
}) {
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);

  const [checkList, setCheckList] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const { loginInfo } = useSelector((state) => state.common);
  const [result, setResults] = useState({});
  const { requestLoadPaymentGetSlotsMutation } = useMutations(setResults);
  const forceUpdate = useForceUpdate();
  const [allChk, setAllChk] = useState(false);
  const checkTarget = useRef();
  const [asExtendDay, setAsExtendDay] = useState();
  const [extendDay, setExtendDay] = useState();
  // const [extendDay, setExtendDay] = useState();
  const [modal, setModal] = useState({
    title: '',
    successText: '발급 취소',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, isActive: false });
    },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (result?.request === 'requestLoadPaymentGetSlotsMutation') {
      if (result?.success && result?.result !== undefined) {
        setAllList(result?.result);
      }
    }

    setResults({});
  }, [result.request]);
  useEffect(() => {
    setList([]);
    requestLoadPaymentGetSlotsMutation.mutate({
      partner_name,
      start_date,
      end_date,
      issue_type,
      slot_status,
    });
  }, [partner_name, start_date, end_date, issue_type, slot_status]);
  const reloadPayments = async () => {
    const result = await requestLoadPaymentGetSlots({
      partner_name,
      start_date,
      end_date,
      issue_type,
      slot_status,
    });
    setAllList(result);
  };
  const slotPaid = async ({ ids }) => {
    const result = await requestLoadSlotPaid({ ids });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `정산이 완료되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
      reloadStatus && reloadStatus();
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    await reloadPayments();
    setCheckList([]);
  };
  const slotDel = async ({ ids }) => {
    const result = await requestLoadSlotDel({ ids });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `발급이 취소되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
      reloadStatus && reloadStatus();
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    await reloadPayments();
    setCheckList([]);
  };
  const extendProductClick = async () => {
    const ids = checkList;
    const extend_days = parseInt(extendDay);
    const result = await requestLoadSlotExtendsDay({
      ids,
      extend_days,
    });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `슬롯 기간이 성공적으로 연장되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
      await reloadPayments({});
      setCheckList([]);
      reloadStatus && reloadStatus();
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  const asExtendProductClick = async () => {
    const ids = checkList;
    const as_extend_days = parseInt(asExtendDay);
    const result = await requestLoadSlotAsExtendsDay({
      ids,
      as_extend_days,
    });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `슬롯 AS 기간이 성공적으로 연장되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );

      await reloadPayments({});
      setCheckList([]);
      reloadStatus && reloadStatus();
      //asdfasdf
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  if (loginInfo.user_level <= 2) return;
  return (
    <Box column>
      <Box gap={10} top={8} bottom={8}>
        <Box>
          <Box gap={36}>
            {loginInfo?.user_level >= 3 && (
              <>
                <Box>
                  <LabelBox label={'슬롯기간 연장'}>
                    <Box>
                      <Box>
                        <InputComponent
                          fontSize={14}
                          weight={400}
                          maxLength={3}
                          value={extendDay}
                          onChange={(e) => {
                            setExtendDay(getNumbers(e.target.value));
                          }}
                        />
                      </Box>
                      <Button
                        size={'xs'}
                        // disabled={!checkList?.length || !extendDay}
                        disabled={!checkList?.length}
                        onClick={async () => {
                          setModal({
                            ...modal,
                            title: '슬롯 기간연장',
                            // text: `[${checkList?.length}]개의 상품을 ${extendDay}일 연장발급하시겠습니까?`,
                            text: `[${checkList?.length}]개의 상품을 연장 발급하시겠습니까?`,
                            successText: '기간 연장',
                            isActive: true,
                            cancelText: '취소',
                            onSuccess: () => {
                              extendProductClick();
                            },
                          });
                        }}
                      >
                        슬롯기간 연장
                      </Button>
                    </Box>
                  </LabelBox>
                </Box>
              </>
            )}

            {loginInfo?.user_level >= 4 && (
              <Box>
                <LabelBox label={'슬롯 AS 기간연장'}>
                  <Box>
                    <Box width={100}>
                      <InputComponent
                        fontSize={14}
                        weight={400}
                        maxLength={3}
                        value={asExtendDay}
                        onChange={(e) => {
                          setAsExtendDay(getNumbers(e.target.value));
                        }}
                        size={40}
                      />
                    </Box>
                    <Button
                      Theme={'unFill'}
                      size={'sm'}
                      disabled={!checkList?.length || !asExtendDay}
                      onClick={() => {
                        setModal({
                          ...modal,
                          title: '슬롯 AS 기간연장',
                          text: `[${checkList?.length}] 개의 상품을 ${asExtendDay}일 AS 연장하시겠습니까?`,
                          successText: 'AS 기간 연장',
                          isActive: true,
                          cancelText: '취소',
                          onSuccess: () => {
                            asExtendProductClick();
                          },
                        });
                      }}
                    >
                      슬롯기간 AS 연장
                    </Button>
                  </Box>
                </LabelBox>
              </Box>
            )}
            {loginInfo?.user_level >= 4 && (
              <Box gap={12}>
                <LabelBox label={'일괄 적용'}>
                  <Box>
                    {loginInfo?.user_level >= 4 && (
                      <Button
                        Theme={'unFill'}
                        size={'sm'}
                        disabled={!checkList?.length}
                        onClick={() => {
                          setModal({
                            ...modal,
                            title: '발급 취소',
                            text: `[${checkList?.length}] 개의 슬롯을 발급 취소시키겠습니까?`,
                            successText: '발급 취소',
                            isActive: true,
                            cancelText: '취소',
                            onSuccess: () => {
                              const ids = checkList;
                              slotDel({ ids });
                              setAllChk(false);
                              setCheckList([]);
                            },
                          });
                        }}
                      >
                        발급 취소
                      </Button>
                    )}
                    {loginInfo?.user_level >= 4 && (
                      <Button
                        size={'sm'}
                        disabled={!checkList?.length}
                        onClick={() => {
                          setModal({
                            ...modal,
                            title: '슬롯 정산',
                            text: `[${checkList?.length}] 개의 슬롯을 정산시키겠습니까?`,
                            successText: '슬롯 정산',
                            isActive: true,
                            cancelText: '취소',
                            onSuccess: () => {
                              const ids = checkList;
                              slotPaid({ ids });
                              setAllChk(false);
                              setCheckList([]);
                            },
                          });
                        }}
                      >
                        정산
                      </Button>
                    )}
                  </Box>
                </LabelBox>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <TableComponent
        onSearch={null}
        isController={false}
        allCheckbox={false}
        isInterval={false}
        searchText={null}
      >
        <LabelBox row label="검색">
          <Box fill top={10}>
            <InputComponent
              value={searchWord}
              onChange={(e) => {
                setSearchWord(e.target.value);
              }}
            />
          </Box>
        </LabelBox>
        <Box top={8} />
        <PaginationBox
          checkList={checkList}
          setCheckList={setCheckList}
          setList={setList}
          allList={allList}
        />

        <Table>
          <colgroup>
            {/* 체크박스 */}
            {loginInfo?.user_level >= 4 && <col style={{ width: '50rem' }} />}
            <col style={{ width: '70rem' }} />
            {/* 분류 */}
            <col style={{ width: '130rem' }} />
            {/* 발급일시 */}
            <col style={{ width: '120rem' }} />
            {/* 유저 */}
            <col style={{ width: '100rem' }} />
            {/* 발급자 */}
            <col style={{ width: '100rem' }} />
            {/* 묶음 nvmid */}
            {/* <col style={{ width: 'auto' }} /> */}
            {/* nvmid */}
            <col style={{ width: 'auto' }} />
            {/* 쇼핑몰 */}
            <col style={{ width: 'auto' }} />
            {/* 기간 */}
            <col style={{ width: '150rem' }} />
            {/* 종류 */}
            <col style={{ width: 'auto' }} />
            {/* 총 AS 연장일 */}
            {/* <col style={{ width: 'auto' }} /> */}
            <col style={{ width: 'auto' }} />
          </colgroup>
          <THeader>
            <tr>
              {/* 발급일시 유저 2차대행사 분류 총 일수 연장 AS연장 기간 상태 기간완료/세팅전/진행중/완료
              정산처리하면 */}
              {loginInfo?.user_level >= 4 && (
                <th>
                  <CheckBox
                    checked={allChk}
                    onChange={(e) => {
                      setAllChk(e.target.checked);
                      if (e.target.checked) {
                        const list = [];
                        // const paid_list = [];
                        checkTarget.current
                          .querySelectorAll('input.checkBox[type="checkbox"]')
                          .forEach((i) => {
                            list.push(parseInt(i.name));
                          });
                        setCheckList(list);
                      } else {
                        setCheckList([]);
                      }
                      forceUpdate();
                    }}
                  />
                </th>
              )}
              <th rowSpan={'1'}>발급번호</th>
              <th rowSpan={'1'}>분류</th>
              <th rowSpan={'1'}>발급일시</th>
              <th rowSpan={'1'}>유저</th>
              <th rowSpan={'1'}>발급자</th>
              {/* <th rowSpan={'1'}>묶음nvmid</th> */}
              <th rowSpan={'1'}>nvmid</th>
              <th rowSpan={'1'}>쇼핑몰</th>
              <th rowSpan={'1'}>기간</th>
              <th rowSpan={'1'}>종류</th>
              {/* <th rowSpan={'1'}>총 AS 연장일</th> */}
              <th rowSpan={'1'}>상태</th>
            </tr>
          </THeader>
          <Tbody ref={checkTarget}>
            {!list?.length ? (
              <tr>
                <td colSpan="13">&nbsp;</td>
              </tr>
            ) : (
              <>
                {list
                  ?.filter((e) => {
                    if (searchWord === '') return true;
                    const status = slotStatus(e);
                    const date = e?.issue_datetime?.slice(5, 16);
                    const start_date = e?.start_date?.slice(5);
                    const end_date = e?.end_date?.slice(5);

                    if (e.id.toString()?.indexOf(searchWord) >= 0) return true;
                    if (e.user_name?.indexOf(searchWord) >= 0) return true;
                    if (e.slot_type_text?.indexOf(searchWord) >= 0) return true;
                    if (e.add_user_name?.indexOf(searchWord) >= 0) return true;
                    if (e.group_search_nvmid?.indexOf(searchWord) >= 0) return true;
                    if (e.nvmid?.indexOf(searchWord) >= 0) return true;
                    if (e.issue_type?.indexOf(searchWord) >= 0) return true;
                    if (e.slot_type?.indexOf(searchWord) >= 0) return true;
                    if (e.partner_name?.indexOf(searchWord) >= 0) return true;
                    if (e.mall_name?.indexOf(searchWord) >= 0) return true;
                    if (status?.indexOf(searchWord) >= 0) return true;

                    if (date?.indexOf(searchWord) >= 0) return true;

                    if (start_date?.indexOf(searchWord) >= 0) return true;
                    if (end_date?.indexOf(searchWord) >= 0) return true;
                    return false;
                  })
                  ?.map((i, idx) => {
                    const status = slotStatus(i);
                    return (
                      <React.Fragment key={idx}>
                        <TrBlock>
                          <td rowSpan={'1'}>
                            <CheckBox
                              name={i.id}
                              checked={checkList.includes(i?.id)}
                              onChange={(c) => {
                                if (c?.target.checked) {
                                  if (!checkList.includes(i?.id)) {
                                    checkList.push(i?.id);
                                    setCheckList(checkList);
                                    forceUpdate();
                                  }
                                } else {
                                  const list = checkList.filter((x) => x !== i?.id);
                                  setCheckList(list);
                                }
                              }}
                            />
                          </td>
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{i?.id?.toString()}</Highlight>
                          </td>
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{i.slot_type_text}</Highlight>
                          </td>
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>
                              {i.issue_datetime.slice(5, 16)}
                            </Highlight>
                          </td>
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{i.user_name}</Highlight>
                          </td>
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{i.add_user_name}</Highlight>
                          </td>
                          {/* <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{i.group_search_nvmid}</Highlight>
                          </td> */}
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{i.nvmid}</Highlight>
                          </td>
                          <td rowSpan={'1'}>
                            <StatusTitleText>
                              {i.smartstore_chk === false ? <small>[오픈마켓]</small> : null}
                              {i.adult_chk ? <small>[성인인증상품]</small> : null}
                              <h1>
                                <Highlight search={searchWord}>{i.mall_name?.toString()}</Highlight>
                              </h1>
                            </StatusTitleText>
                          </td>
                          <td rowSpan={'1'}>
                            {i.days}일 [
                            <Highlight search={searchWord}>{i.start_date?.slice(5)}</Highlight> ~{' '}
                            <Highlight search={searchWord}>{i.end_date?.slice(5)}</Highlight>]
                          </td>
                          {/* <td rowSpan={'1'}>{i.extend_days}</td> */}
                          <td rowSpan={'1'}>{i.issue_type_text}</td>
                          {/* <td rowSpan={'1'}>{i.as_extend_days}</td> */}
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{status}</Highlight>
                          </td>
                        </TrBlock>
                      </React.Fragment>
                    );
                  })}
              </>
            )}
          </Tbody>
        </Table>
      </TableComponent>
      <ModalQuestion {...modal} />
    </Box>
  );
}
const TrBlock = styled.tr`
  &:hover {
    background-color: #f0f0f0;
    /* cursor: grab; */
  }
`;
const TextStatus = styled.div`
  font-weight: 600;
  color: #18795a;
`;
const StatusText = styled.div`
  text-align: left;
  font-size: 17rem;
  display: inline-block;
  gap: 12rem;
  width: 100%;
  justify-content: space-between;

  span {
    line-height: 18rem;
  }
  small {
    padding-left: 2rem;
    /* font-size: 13rem; */
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return `
        border-width: 1rem;
        border-style: solid;
        border-color: var(--P_300); 
        background-color: #9cd0e7;
            
        `;
  }}
`;

const ButtonBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-height: 50rem;
  padding: 5rem;
  padding-left: 10rem;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const StatusTitleText = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  /* text-align: left; */

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  h1 {
    font-size: 13rem;
    /* line-height: 9rem; */
    font-weight: 500;
    color: #4879ba;
  }
  small {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #f00000;
  }
`;

export default PaymentSlot;
