import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
// import { Route, Routes, useLocation, useParams } from "react-router-dom";
import styled from 'styled-components';

const NaverId = () => {
  return (
    <>
      <Box column top={20}>
        NaverId
      </Box>
      {/* </Block> */}
    </>
  );
};
const Block = styled.div`
  /* height: 1000rem; */
`;
export default NaverId;
