import React from 'react';
import DatepickerReact from './components/DatepickerReact';
import TimepickerCompoent from './TimepickerCompoent';
import { Box, InputComponent } from 'Common/UI/atoms';
import { BottomSection } from './components/styled';
import DatepickerHeader from './components/DatepickerHeader';
import useDatepicker from './useDatepicker';
import { useEffect } from 'react';
import moment from 'moment';

function MonthpickerCompoent({
  onChange = () => '',
  name,
  value,
  date = value,
  className,
  size = 44,
  placeholder,
  disabled,
  isTime,
  minDate,
  maxDate,
}) {
  const {
    state,
    ref,
    onChange: onChangeDate,
    setDateFormat,
    setMonthFormat,
    onChangeTime,
    onToggleMonth,
    isMonth,
    onCurrentMont,
  } = useDatepicker(date);

  useEffect(() => {
    //에디터 주석 처리
    onChange(name, moment(state).format());
  }, [state]);

  return (
    <Box gap="20rem">
      <InputComponent
        className={className}
        value={setMonthFormat(state)}
        name={name}
        readOnly
        size={size}
        placeholder={placeholder ? placeholder : '기간을 선택하세요'}
        disabled={disabled}
        rightIcon={
          <DatepickerReact
            selected={state}
            onChange={onChangeDate}
            ref={ref}
            openToDate={state ? state : null}
            showMonthYearPicker
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            renderCustomHeader={({ ...props }) => (
              <DatepickerHeader
                {...props}
                type="Year"
                onCurrentMont={onCurrentMont}
              ></DatepickerHeader>
            )}
          >
            <BottomSection className="bottom">
              <span>일자를 선택하세요</span>
            </BottomSection>
          </DatepickerReact>
        }
      />
      {isTime && <TimepickerCompoent onChange={onChangeTime} date={state} size={size} />}
    </Box>
  );
}

export default MonthpickerCompoent;
