import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /** defaultTheme */
  :root{

    --Black:${(props) => props.theme.colors.Black};
    --White:${(props) => props.theme.colors.White};
    
    --G_900:${(props) => props.theme.colors.G_900};
    --G_800:${(props) => props.theme.colors.G_800};
    --G_700:${(props) => props.theme.colors.G_700};
    --G_600:${(props) => props.theme.colors.G_600};
    --G_500:${(props) => props.theme.colors.G_500};
    --G_400:${(props) => props.theme.colors.G_400};
    --G_300:${(props) => props.theme.colors.G_300};
    --G_200:${(props) => props.theme.colors.G_200};
    --G_150:${(props) => props.theme.colors.G_150};
    --G_100:${(props) => props.theme.colors.G_100};
    --G_75:${(props) => props.theme.colors.G_75};
    --G_50:${(props) => props.theme.colors.G_50};
    --G_25:${(props) => props.theme.colors.G_25};
    --G_20:${(props) => props.theme.colors.G_20};


    --P_900:${(props) => props.theme.colors.P_900};
    --P_800:${(props) => props.theme.colors.P_800};
    --P_700:${(props) => props.theme.colors.P_700};
    --P_600:${(props) => props.theme.colors.P_600};
    --P_500:${(props) => props.theme.colors.P_500};
    --P_400:${(props) => props.theme.colors.P_400};
    --P_300:${(props) => props.theme.colors.P_300};
    --P_200:${(props) => props.theme.colors.P_200};
    --P_100:${(props) => props.theme.colors.P_100};
    --P_75:${(props) => props.theme.colors.P_75};
    --P_60:${(props) => props.theme.colors.P_60};
    --P_50:${(props) => props.theme.colors.P_50};
    --P_25:${(props) => props.theme.colors.P_25};
    --P_10:${(props) => props.theme.colors.P_10};

    --S_600:${(props) => props.theme.colors.S_600};
    --S_500:${(props) => props.theme.colors.S_500};
    --S_400:${(props) => props.theme.colors.S_400};
    --S_300:${(props) => props.theme.colors.S_300};
    --S_200:${(props) => props.theme.colors.S_200};
    --S_150:${(props) => props.theme.colors.S_150};
    --S_100:${(props) => props.theme.colors.S_100};

    --Success_600:${(props) => props.theme.colors.Success_600};
    --Success_500:${(props) => props.theme.colors.Success_500};
    --Success_400:${(props) => props.theme.colors.Success_400};
    --Success_300:${(props) => props.theme.colors.Success_300};
    --Success_250:${(props) => props.theme.colors.Success_250};
    --Success_200:${(props) => props.theme.colors.Success_200};
    --Success_100:${(props) => props.theme.colors.Success_100};

    --Error_600:${(props) => props.theme.colors.Error_600};
    --Error_500:${(props) => props.theme.colors.Error_500};
    --Error_400:${(props) => props.theme.colors.Error_400};
    --Error_300:${(props) => props.theme.colors.Error_300};
    --Error_200:${(props) => props.theme.colors.Error_200};
    --Error_100:${(props) => props.theme.colors.Error_100};


    --Warning_900:${(props) => props.theme.colors.Warning_900};
    --Warning_800:${(props) => props.theme.colors.Warning_800};
    --Warning_700:${(props) => props.theme.colors.Warning_700};
    --Warning_600:${(props) => props.theme.colors.Warning_600};
    --Warning_500:${(props) => props.theme.colors.Warning_500};
    --Warning_400:${(props) => props.theme.colors.Warning_400};
    --Warning_300:${(props) => props.theme.colors.Warning_300};
    --Warning_200:${(props) => props.theme.colors.Warning_200};
    --Warning_100:${(props) => props.theme.colors.Warning_100};

    --Elevation_001:${(props) => props.theme.elevation.Elevation__001};
    --Elevation_002:${(props) => props.theme.elevation.Elevation__002};
    --Elevation_003:${(props) => props.theme.elevation.Elevation__003};
    --Elevation_004:${(props) => props.theme.elevation.Elevation__004};
    --Elevation_005:${(props) => props.theme.elevation.Elevation__005};
    --Elevation_006:${(props) => props.theme.elevation.Elevation__006};
    --Elevation_007:${(props) => props.theme.elevation.Elevation__007};
    --Elevation_008:${(props) => props.theme.elevation.Elevation__008};
  }

`;

export default GlobalStyle;
