import React from 'react';
import styled, { css } from 'styled-components';

function Title({ children, size = 'md', weight = 500, label, color, className, align = 'left' }) {
  return (
    <Wrapper
      className={`title ${className || ''}`}
      size={size}
      color={color}
      weight={weight}
      align={align}
    >
      {label && <p className="titleLabel">{label}</p>}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ theme: { title, colors }, size, weight, align, color }) => {
    const textColor = color ? colors[color] : 'var(--G_800)';
    const textSize = size ? title[size] : 'md';
    return css`
      font-weight: ${weight};
      ${textSize};
      color: ${textColor};
      text-align: ${align};
      .titleLabel {
        margin-bottom: 8rem;
        font-weight: 400;
        font-size: 16rem;
        line-height: 22rem;
        color: ${colors.primary};
      }
    `;
  }}
`;

export default Title;
