import React, { forwardRef } from 'react';
import { Box, Icon, TextButton } from 'Common/UI/atoms';
import { Wrapper } from './styled';
import { onHideToast } from '../../../../features/common/commonSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
const ToastComponent = forwardRef(
  (
    {
      status = 'success',
      message,
      onClick,
      onClose,
      onCancel,
      onSuccess, //Timer 꺼진 시점
      cancelText,
      icon = true,
      isActive,
      timer = 3000, // 20000
      bottom = 20,
      left = 88,
    },
    ref,
  ) => {
    const selectTypes = (status) => {
      switch (status) {
        case 'success':
          return {
            icon: <Icon.Complate fill={'var(--White)'} size={20} />,
            color: 'Success_500',
          };
        case 'warning':
          return {
            icon: <Icon.Warning fill={'var(--White)'} size={20} />,
            color: 'Warning_500',
          };
        case 'error':
          return {
            icon: <Icon.Danger fill={'var(--White)'} size={20} />,
            color: 'Error_500',
          };
        case 'info':
          return {
            icon: <Icon.Info fill={'var(--White)'} size={20} />,
            color: 'P_500',
          };
        default:
          return '';
      }
    };

    let isRun = false;
    let isCancle = false;
    const dispatch = useDispatch();
    const onTimer = (fn) => {
      if (isRun) return;
      isRun = true;
      setTimeout(() => {
        dispatch(onHideToast());
        isRun = false;
        !isCancle && onSuccess && onSuccess();
      }, timer);
    };
    useEffect(() => {
      if (isActive) onTimer();
    }, [isActive]);

    return (
      <Wrapper
        className="toast"
        bottom={bottom}
        left={left}
        isActive={isActive}
        ref={ref}
        onClick={(e) => onClick(e)}
        status={selectTypes(status)?.color}
      >
        <Box gap={12}>
          {icon && selectTypes(status)?.icon}
          <p>{message}</p>
        </Box>
        <Box gap={12}>
          {cancelText && (
            <TextButton
              className={'btnCancel'}
              onClick={() => {
                isCancle = true;
                onCancel();
              }}
              size={'xs'}
            >
              {cancelText || '취소'}
            </TextButton>
          )}
          <button className="btnClose" onClick={() => onClose()}>
            <Icon.Closer fill={'var(--White)'} size={16} />
          </button>
        </Box>
      </Wrapper>
    );
  },
);

export default ToastComponent;
