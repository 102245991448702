import React, { forwardRef, useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Box, Icon, TextButton } from '..';
import Textarea from '../Textarea';
import ErrorMessage from './ErrorMessage';
import InputWrapper from './InputWrapper';
import Search from './Search';
import { BottomTextBox, TextLengthBox } from './styled';

const InputComponent = forwardRef(
  (
    {
      onChange,
      autocomplete,
      onKeyPress,
      onCancel,
      isEdit = true,
      fontSize = 16,
      iconSize = 16,
      filter,
      onMouseClick = null,
      onKeyDown,
      weight,
      pattern,
      ...props
    },
    ref,
  ) => {
    const {
      value,
      name,
      placeholder,
      disabled,
      readOnly,
      el,
      onClick,
      size = 36,
      errors,
      help,
      className,
      rows,
      type = 'text',
      onKeyUp,
      maxLength,
      isMax = false,
      onFocus,
      onBlur,
    } = props;
    const [state, setState] = useState(value);
    const [isPassword, setIsPasswrod] = useState('password');
    const inputRef = useRef();
    const onChangeValue = (e) => {
      const {
        target: { name, value },
      } = e;
      if (isMax && value.length > maxLength) return;
      const valueFilter =
        filter === 'number'
          ? value?.replace(/[^0-9]/g, '')
          : filter === 'decimal'
          ? value?.replace(/[^\.|^0(0)+|^0-9\.]/g, '')
          : filter === 'phone'
          ? value?.replace(/[^0-9]/g, '')
          : value;
      if (filter === 'phone' && value?.length > 11) return;
      if (onChange) onChange({ target: { name, value: valueFilter } });
      setState(value);
    };

    const onDelete = () => {
      setState('');
      if (onChange) onChange({ target: { name: name, value: '' } });
      onCancel && onCancel({ target: { name: name, value: '' } });
      inputRef.current?.focus();
    };

    const onChangeType = () => {
      setIsPasswrod(isPassword === 'password' ? 'text' : 'password');
    };

    const selectElement = (el) => {
      switch (el) {
        case 'textarea':
          return (
            <>
              {size === 'auto' ? (
                <Box height={'max-content;'} fill>
                  <SizeBox>{value + ' '}</SizeBox>

                  <Textarea
                    resize={false}
                    ref={ref ? ref : inputRef}
                    readOnly={readOnly}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value || ''}
                    rows={rows}
                    onKeyDown={onKeyDown}
                    name={name}
                    onChange={onChangeValue}
                    onKeyPress={onKeyPress}
                  />
                </Box>
              ) : (
                <Textarea
                  resize={false}
                  ref={ref ? ref : inputRef}
                  readOnly={readOnly}
                  disabled={disabled}
                  placeholder={placeholder}
                  onKeyDown={onKeyDown}
                  value={value || ''}
                  rows={rows}
                  name={name}
                  onChange={onChangeValue}
                  onKeyPress={onKeyPress}
                />
              )}
              {isMax && (
                <TextLengthBox>
                  {value?.length ? value?.length : 0} /{maxLength}자
                </TextLengthBox>
              )}
            </>
          );
        case 'password':
          return (
            <>
              <input
                type={isPassword}
                ref={ref ? ref : inputRef}
                readOnly={readOnly}
                disabled={disabled}
                placeholder={placeholder}
                value={value || ''}
                name={name}
                onChange={onChangeValue}
                onKeyDown={onKeyDown}
                onKeyPress={onKeyPress}
              />
              {!disabled && (
                <button type="button" className="password" onClick={() => onChangeType()}>
                  <Icon.Eyes isActive={isPassword === 'text'} full size={12} />
                </button>
              )}
            </>
          );
        default:
          return (
            <input
              type={type}
              ref={ref ? ref : inputRef}
              readOnly={readOnly}
              onClick={onClick}
              disabled={disabled}
              placeholder={placeholder}
              autoComplete={autocomplete && 'off'}
              value={value || ''}
              name={name}
              onKeyDown={onKeyDown}
              onChange={onChangeValue}
              onKeyPress={onKeyPress}
              onKeyUp={onKeyUp}
              pattern={pattern}
              onFocus={onFocus}
              onBlur={onBlur}
              maxLength={maxLength}
            />
          );
      }
    };
    return (
      <Wrapper className={className ? `${className} input` : 'input'}>
        {isEdit === null && 'a'}
        <InputWrapper
          iconSize={iconSize}
          isEdit={isEdit}
          {...props}
          isError={errors?.[name]}
          value={state}
          onClick={onDelete}
          fontSize={fontSize}
          weight={weight}
        >
          {isEdit ? (
            selectElement(el)
          ) : (
            <TextButton
              onClick={() => {
                onMouseClick && onMouseClick();
              }}
              className="viewText"
            >
              {value}
            </TextButton>
          )}
        </InputWrapper>
        <BottomTextBox isHelp={errors || help}>
          {!errors?.[name] && <span className="infoText">{help}</span>}
          <ErrorMessage errors={errors} name={name} />
        </BottomTextBox>
      </Wrapper>
    );
  },
);

InputComponent.Search = Search;

const Wrapper = styled.div`
  width: 100%;
  .viewText {
    /* padding-top: 6rem; */
    align-items: center;
    /* vertical-align: middle; */
    white-space: pre-line;
  }
`;

const SizeBox = styled.div`
  white-space: pre-wrap;
  flex: 1;
  padding: 8rem 0;
  min-height: 44rem;
  opacity: 0;
  + textarea {
    /* position: absolute; */
  }
`;

export default InputComponent;
