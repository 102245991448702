import React, { useCallback, useEffect, useRef, useState } from 'react';
// import 'swiper/css'; //basic
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import { Swiper, SwiperSlide } from 'swiper/react';

import styled, { css } from 'styled-components';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import { Table, Tbody, THeader } from 'Common/UI/organisms/FormTabel/styeld';
import ToggleButton from 'Page/Module/CheckToggleButton';
import { useNavigate } from 'react-router-dom';
import ProductRankModal from './Module/ProductRankModal';
import {
  Badge,
  Box,
  Button,
  CheckBox,
  DropBox,
  Icon,
  InputComponent,
  Tag,
  TextButton,
} from 'Common/UI/atoms';
import useMutations from 'Common/Service/slotMutations';
import { requestLoadPartnerGetNames, requestLoadUserGetNames } from 'Common/Service/user';
import {
  requestLoadSlotAdd,
  requestLoadSlotAsExtendsDay,
  requestLoadSlotDel,
  requestLoadSlotExtendsDay,
  requestLoadSlotGetRankLog,
  requestLoadSlotList,
  requestLoadSlotRequestEtcDo1ProblemReset,
  requestLoadSlotRequestSearchKeywordAgain,
  requestLoadSlotSet,
  requestLoadSlotUse,
  requestLoadSlotsSet,
} from 'Common/Service/slot';
import { onShowToast } from 'features/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import LabelBox from 'Common/UI/molecules/LabelBox';
import {
  getNumbers,
  isSlotUsing,
  TextFail,
  TextSmall,
  today,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import Highlight from 'react-highlighter';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import RankLogGraph from './RankLogGraph';
import PaginationBox from 'Page/Module/PaginationBox';
import RankModule from './Module/RankModule';
import { get_percent, navigate_newtab } from 'Common/Utils';
const ProductList = () => {
  const [result, setResults] = useState({});
  const [allChk, setAllChk] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [rankLog, setRankLog] = useState({});

  const [checkList, setCheckList] = useState([]);
  const checkTarget = useRef();
  const [isKeywordLessChk, setIsKeywordLessChk] = useState();
  const [isKeywordStopChk, setIsKeywordStopChk] = useState();

  const { loginInfo } = useSelector((state) => state.common);
  const [modal, setModal] = useState({
    title: '',
    successText: '발급 취소',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, isActive: false });
    },
  });
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);

  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [productRankModalStatus, setProductRankModalStatus] = useState({ isActive: false });

  const [toggleActiveChk, setToggleActiveChk] = useState(true);
  const [togglePreActiveChk, setTogglePreActiveChk] = useState(true);
  const [toggleNotSettingChk, setToggleNotSettingChk] = useState(
    loginInfo.user_level === 2 ? true : false,
  );

  const [activeCount, setActiveCount] = useState();
  const [preActiveCount, setPreActiveCount] = useState();
  const [notSettingCount, setNotSettingCount] = useState();
  // const [toggleUnactiveSlot, setToggleUnactiveSlot] = useState(false);
  // const [togglePaidActiveSlot, setTogglePaidActiveSlot] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    var search_slot = url.searchParams.get('slot');
    if (search_slot) setSearchWord(search_slot);

    const partner_name = url.searchParams.get('partner')?.trim();
    const user_name = url.searchParams.get('user')?.trim();
    // set_user_name(url.searchParams.get('user'));
    setSelectedPartnerName(partner_name);
    setSelectedUserName(user_name);
    // var active_chk;
    const active_str = url.searchParams.get('active');
    if (active_str?.indexOf('a') >= 0) {
      setToggleActiveChk(false);
    }
    if (active_str?.indexOf('p') >= 0) {
      setTogglePreActiveChk(false);
      // active_chk = false;
      // setToggleUnactiveSlot(true);
    }
    if (active_str?.indexOf('s') >= 0) {
      setToggleActiveChk(false);
      setTogglePreActiveChk(false);
      setToggleNotSettingChk(true);
      // active_chk = false;
      // setToggleUnactiveSlot(true);
    }

    // if (loginInfo?.user_level >= 4) loadPartnerNames();
    // search({ partner_name, user_name, active_chk });
    // set_user_name(url.searchParams.get('unactive'))
  }, []);
  useEffect(() => {
    search({});
  }, [toggleActiveChk, togglePreActiveChk, toggleNotSettingChk]);

  const [asExtendDay, setAsExtendDay] = useState();
  const [extendDay, setExtendDay] = useState();

  const [partnerNames, setPartnerNames] = useState([
    // { title: '전체', value: null, selected: true },
  ]);
  const [userNames, setUserNames] = useState([
    // { title: '전체', value: null, selected: true }
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (result?.request === 'requestLoadSlotAsExtendsDayMutation') {
      if (result?.success && result?.result === true) {
        dispatch(
          onShowToast({
            status: 'success',
            message: `AS 슬롯기간이 연장되었습니다.`,
            cancelText: '',
            onCancel: false,
          }),
        );
        search({});
      } else {
        dispatch(
          onShowToast({
            status: 'error',
            message: `오류가 발생했습니다`,
            cancelText: '',
            onCancel: false,
          }),
        );
      }
    } else if (result?.request === 'requestLoadSlotListMutation') {
      if (result?.success && result?.result !== undefined) {
        setActiveCount(result?.result?.active_count);
        setPreActiveCount(result?.result?.pre_active_count);
        setNotSettingCount(result?.result?.not_setting_count);
        setAllList(result?.result?.list);
      } else {
        dispatch(
          onShowToast({
            status: 'error',
            message: `오류가 발생했습니다`,
            cancelText: '',
            onCancel: false,
          }),
        );
        return;
      }
    }
    setResults({});
  }, [result.request]);
  const [showDoTypes, setShowDoTypes] = useState(['click', 'etc_do1']);

  const { requestLoadSlotListMutation } = useMutations(setResults);
  // const loadPartnerNames = async () => {
  //   const result = await requestLoadPartnerGetNames({});
  //   const list1 = [];
  //   var list2 = result?.map((e, _idx) => {
  //     return {
  //       title: e,
  //       value: e,
  //       selected: selectedPartnerName === e,
  //     };
  //   });
  //   if (list2 === undefined) list2 = [];
  //   setPartnerNames([...list1, ...list2]);
  // };
  const reloadSlotList = async () => {
    const result = await requestLoadSlotList({
      partner_name: selectedPartnerName,
      user_name: selectedUserName,
      // group_name: selectedSlotGroupName,
      active_chk: toggleActiveChk,
      pre_active_chk: togglePreActiveChk,
      not_setting_chk: toggleNotSettingChk,
    });
    if (!result) {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
      return;
    }
    setActiveCount(result?.active_count);
    setPreActiveCount(result?.pre_active_count);
    setNotSettingCount(result?.not_setting_count);
    setAllList(result?.list);
  };
  const slotDel = async ({ ids }) => {
    const result = await requestLoadSlotDel({ ids });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `슬롯이 발급 취소되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    await reloadSlotList();
  };
  const slotsSet = async ({ modify_infos, reload_chk = true }) => {
    if (modify_infos.length === 0) {
      return;
    }

    const result = await requestLoadSlotsSet({
      modify_infos,
    });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `${result}개의 정보가 변경되었습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    if (reload_chk) {
      await reloadSlotList();
    }
  };

  // const slotSet = async ({ id = null, fields }) => {
  //   const result = await requestLoadSlotSet({
  //     id,
  //     fields,
  //   });
  //   if (result) {
  //     dispatch(
  //       onShowToast({
  //         status: 'success',
  //         message: `${result}개의 정보가 변경되었습니다`,
  //         cancelText: '',
  //         onCancel: false,
  //       }),
  //     );
  //   } else {
  //     dispatch(
  //       onShowToast({
  //         status: 'error',
  //         message: `오류가 발생했습니다`,
  //         cancelText: '',
  //         onCancel: false,
  //       }),
  //     );
  //   }
  // };

  useEffect(() => {
    const partner_name = selectedPartnerName;
    selectPartnerName({ partner_name });
  }, [selectedPartnerName]);

  const selectPartnerName = async (partner_name = null) => {
    const result = await requestLoadUserGetNames(partner_name);
    // requestLoadSlotGroupListMutation.mutate({ partner_name });

    const list1 = [{ title: '전체', value: null, selected: true }];
    var list2 = [];
    if (loginInfo.user_level >= 4) {
      list2 = result?.map((e, _idx) => {
        // const title = e.partner_name ? `[${e.partner_name}] ${e.user_name}` : e.user_name;
        const title = e.user_name;
        return {
          title,
          value: e.user_name,
          // selected: selectedUserName === e,
        };
      });
    } else {
      list2 = result?.map((e, _idx) => {
        const title = e.user_name;
        return {
          title,
          value: e.user_name,
          // selected: selectedUserName === e,
        };
      });
    }
    if (list2 === undefined) list2 = [];
    setUserNames([...list1, ...list2]);
  };

  // const selectUserName = async (user_name = null, init_chk = false) => {
  //   const result = await requestLoadSlotGroupGetNames({ user_name });
  //   // const packSlotGroup = packProductGroups(result);
  //   // setSlotGroups(packSlotGroup);
  //   const list1 = [
  //     // { title: '전체', value: null, selected: true }
  //   ];
  //   var list2 = [];
  //   if (loginInfo.user_level >= 4) {
  //     list2 = result?.map((e, _idx) => {
  //       const title =
  //         // e.partner_name || e.user_name
  //         //   ? `[${e.partner_name}-${e.user_name}] ${e.group_name}`
  //         //   : e.group_name;
  //         e.group_name;
  //       return {
  //         title,
  //         value: e.group_name,
  //         // selected: selectedUserName === e,
  //       };
  //     });
  //   } else if (loginInfo.user_level >= 3) {
  //     list2 = result?.map((e, _idx) => {
  //       // const title = e.user_name ? `[${e.user_name}] ${e.group_name}` : e.group_name;
  //       const title = e.group_name;
  //       return {
  //         title,
  //         value: e.group_name,
  //         // selected: selectedUserName === e,
  //       };
  //     });
  //   } else {
  //     list2 = result?.map((e, _idx) => {
  //       const title = e.group_name;
  //       return {
  //         title,
  //         value: e.group_name,
  //         // selected: selectedUserName === e,
  //       };
  //     });
  //   }
  //   // if (list2 === undefined) list2 = [];
  //   // const list = [...list1, ...list2];
  //   // setSlotGroupNames(list);
  //   // setSelectedSlotGroupName(null);
  //   // if (init_chk) {
  //   //   init_select_group_name(list);
  //   // }
  // };

  const search = async ({
    partner_name = null,
    user_name = null,
    // group_name = null,
  }) => {
    // return;
    const field = {
      partner_name: partner_name ? partner_name : selectedPartnerName,
      user_name: user_name ? user_name : selectedUserName,
      // group_name: group_name ? group_name : selectedSlotGroupName,
      active_chk: toggleActiveChk,
      pre_active_chk: togglePreActiveChk,
      not_setting_chk: toggleNotSettingChk,
    };

    setAllList([]);
    requestLoadSlotListMutation.mutate(field);
  };

  const adminManualToggleSlotUse = async (id, admin_manual_temp_stop_chk) => {
    const result = await requestLoadSlotUse({
      id,
      admin_manual_temp_stop_chk,
    });

    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `변경사항이 적용 되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
      // const list = slots.map((slot) => {
      //   return {
      //     ...slot,
      //     toggle_temp_stop_chk:
      //       slot.product_id === product_id ? toggle_temp_stop_chk : slot.toggle_temp_stop_chk,
      //   };
      // });
      await reloadSlotList();
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: '오류가 발생 했습니다.',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  const toggleSlotUse = async (id, temp_stop_chk) => {
    const result = await requestLoadSlotUse({
      id,
      temp_stop_chk,
    });

    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `변경사항이 적용 되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
      // const list = slots.map((slot) => {
      //   return {
      //     ...slot,
      //     toggle_temp_stop_chk:
      //       slot.product_id === product_id ? toggle_temp_stop_chk : slot.toggle_temp_stop_chk,
      //   };
      // });
      await reloadSlotList();
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: '오류가 발생 했습니다.',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  // const onInputChange = async (e) => {
  //   // setUserNameStatus({ status: '', isUse: false });

  //   InputDebounce(e);
  // };
  // const InputDebounce = useCallback(
  //   debounce((e) => {
  //     const id = e.target.name;
  //     const fields = {
  //       product_id: e.target.value,
  //     };
  //     slotSet({ id, fields });
  //     // requestLoadUserNameExistCheckMutation.mutate({ user_name: value });
  //   }, 500),
  //   [],
  // );
  const forceUpdate = useForceUpdate();
  // const init_select_group_name = (groupNames) => {
  //   var find_chk = false;
  //   const list = groupNames.map((e) => {
  //     if (e.value === group_name) find_chk = true;
  //     return { ...e, selected: e.value === group_name };
  //   });
  //   if (find_chk) {
  //     setSlotGroupNames(list);
  //   } else {
  //   }
  // };
  // const init = async () => {
  //   if (loginInfo?.user_level >= 4) {
  //     if (partner_name) {
  //       setSelectedPartnerName(partner_name);
  //     }
  //     await loadPartnerNames();
  //     await selectPartnerName(partner_name);

  //     if (user_name) {
  //       setSelectedUserName(user_name);
  //       await selectUserName(user_name, true);
  //     }
  //     if (group_name) {
  //       setSelectedSlotGroupName(group_name);
  //     }
  //   } else if (loginInfo?.user_level === 3) {
  //     const partner_name = loginInfo?.user_name;
  //     setSelectedPartnerName(partner_name);
  //     await selectPartnerName(partner_name);

  //     if (user_name) {
  //       setSelectedUserName(user_name);
  //       await selectUserName(user_name, true);
  //     }
  //     if (group_name) {
  //       setSelectedSlotGroupName(group_name);
  //     }
  //   } else if (loginInfo?.user_level === 2) {
  //     const partner_name = loginInfo?.partner_name;
  //     const user_name = loginInfo?.user_name;
  //     setSelectedPartnerName(partner_name);
  //     setSelectedUserName(user_name);
  //     await selectUserName(user_name);
  //     if (group_name) {
  //       setSelectedSlotGroupName(group_name);
  //     }
  //   }

  //   // if (partner_name && user_name) {
  //   await search({ partner_name, user_name, group_name });
  //   // }
  // };
  // useEffect(() => {
  //   init();
  //   // const partner_name = selectedPartnerName;
  //   // const user_name = selectedUserName;
  //   // search({ group_name, partner_name, user_name });
  // }, []);

  const preExtendProduct = async ({
    product_ids = [],
    extend_chk = true,
    start_date = null,
    user_name,
    end_date,
  }) => {
    if (product_ids?.length <= 0) return;

    const params = {
      extend_chk,
      // product_counts: slotAddCountMax,
      slot_type: 'normal',
      // to_click_count_max: toClickCountMax,
      start_date,
      end_date,
      product_ids,
      user_name,
      // end_date: today_str(endDate),
    };
    await requestLoadSlotAdd(params);
  };
  const extendProductClick = async () => {
    const ids = checkList;
    const extend_days = parseInt(extendDay);
    await requestLoadSlotExtendsDay({
      ids,
      extend_days,
    });
    dispatch(
      onShowToast({
        status: 'success',
        message: `슬롯 기간이 성공적으로 연장되었습니다.`,
        cancelText: '',
        onCancel: false,
      }),
    );
    search({});
  };
  const asExtendProductClick = async () => {
    const ids = checkList;
    const as_extend_days = parseInt(asExtendDay);
    await requestLoadSlotAsExtendsDay({
      ids,
      as_extend_days,
    });
    dispatch(
      onShowToast({
        status: 'success',
        message: `슬롯 AS 기간이 성공적으로 연장되었습니다.`,
        cancelText: '',
        onCancel: false,
      }),
    );
    search({});
  };
  return (
    <Box valign={'top'} column>
      {/* <Box top={6}>
        {loginInfo?.user_level >= 3 && (
          <>
            {loginInfo?.user_level >= 4 && (
              <DropBox
                title={'2차대행사'}
                isColor
                placeholder="전체"
                option={partnerNames?.map((e) => {
                  return { ...e, selected: e.value === selectedPartnerName };
                })}
                onChange={(_a, partner_name) => {
                  // setUserNames([{ title: '전체', value: null, selected: true }]);
                  setUserNames();
                  setSelectedPartnerName(partner_name);
                  selectPartnerName(partner_name);
                  // search({ partner_name });
                }}
                size={44}
              />
            )}
            {loginInfo?.user_level >= 3 && (
              <DropBox
                title={'유저'}
                isColor
                placeholder="전체"
                option={userNames?.map((e) => {
                  return { ...e, selected: e.value === selectedUserName };
                })}
                size={44}
                onChange={(_a, user_name) => {
                  setSelectedUserName(user_name);
                }}
              />
            )}

            <Box left={8}>
              <Button
                size="sm"
                onClick={() => {
                  search({});
                }}
              >
                검색
              </Button>
            </Box>
          </>
        )}
      </Box> */}

      <Box gap={8} top={14}>
        <ToggleButton
          // isReadOnly={togglePaidActiveSlot}
          // disabled={toggleNotSettingChk}
          active={toggleActiveChk}
          onClick={async () => {
            await setToggleActiveChk(!toggleActiveChk);
          }}
        >
          현재 구동중인 슬롯보기
        </ToggleButton>
        {/* <ToggleButton
          isReadOnly={togglePaidActiveSlot}
          active={toggleUnactiveSlot}
          onClick={() => {
            setToggleUnactiveSlot(!toggleUnactiveSlot);
          }}
        >
          만료된 슬롯보기
        </ToggleButton> */}
        <ToggleButton
          // disabled={toggleNotSettingChk}
          active={togglePreActiveChk}
          onClick={() => {
            setTogglePreActiveChk(!togglePreActiveChk);
          }}
        >
          구동예정인 슬롯보기
        </ToggleButton>
        <ToggleButton
          active={toggleNotSettingChk}
          onClick={() => {
            setToggleNotSettingChk(!toggleNotSettingChk);
          }}
        >
          미세팅 슬롯보기
        </ToggleButton>
        <Box>
          <SlotText>
            구동 예정: <small>{preActiveCount}</small>
          </SlotText>
        </Box>
        <Box>
          <SlotText>
            현재 구동중인 슬롯: <b>{activeCount}</b>
          </SlotText>
        </Box>
        <Box>
          <SlotText>
            미세팅 슬롯: <span>{notSettingCount}</span>
          </SlotText>
        </Box>
      </Box>
      <Box gap={10} top={8} bottom={8}>
        <Box>
          {loginInfo?.user_level >= 3 && (
            <>
              <Box>
                <LabelBox label={'슬롯기간 연장'}>
                  <Box>
                    <Box width={100}>
                      <InputComponent
                        fontSize={14}
                        weight={400}
                        maxLength={3}
                        value={extendDay}
                        onChange={(e) => {
                          setExtendDay(getNumbers(e.target.value));
                        }}
                      />
                    </Box>
                    <Button
                      size={'xs'}
                      disabled={!checkList?.length || !extendDay}
                      onClick={async () => {
                        // setEndDate(
                        //   new Date(new Date().setDate(new Date().getDate() + parseInt(extendDay))),
                        // );
                        // return;
                        // const paid_slots = slots.filter((i) =>
                        //   paidCheckList.includes(i.product_id),
                        // );
                        // const user_slots = [];
                        // paid_slots.forEach((i) => {
                        //   if (!user_slots[i.user_name]) user_slots[i.user_name] = [];
                        //   user_slots[i.user_name].push(i);
                        // });

                        // const user_names = Object.keys(user_slots);

                        // return;
                        setModal({
                          ...modal,
                          title: '슬롯 기간연장',
                          text: `[${checkList?.length}]개의 상품을 ${extendDay}일 연장발급하시겠습니까?`,
                          successText: '기간 연장',
                          isActive: true,
                          cancelText: '취소',
                          onSuccess: () => {
                            extendProductClick();
                          },
                        });
                      }}
                    >
                      슬롯기간 연장
                    </Button>
                  </Box>
                </LabelBox>
              </Box>
            </>
          )}
          {/* <Box bottom={10}>
              <Button
                size={'sm'}
                Theme="unFill"
                disabled={!extendDay}
                onClick={() => {
                  setEndDate(
                    new Date(new Date().setDate(new Date().getDate() + parseInt(extendDay))),
                  );
                }}
              >
                적용
              </Button>
            </Box> */}
          {loginInfo?.user_level >= 4 && (
            <Box>
              <LabelBox label={'슬롯 AS 기간연장'}>
                <Box>
                  <Box width={100}>
                    <InputComponent
                      fontSize={14}
                      weight={400}
                      maxLength={3}
                      value={asExtendDay}
                      onChange={(e) => {
                        setAsExtendDay(getNumbers(e.target.value));
                      }}
                      size={40}
                    />
                  </Box>
                  <Button
                    Theme={'unFill'}
                    size={'sm'}
                    disabled={!checkList?.length || !asExtendDay}
                    onClick={() => {
                      setModal({
                        ...modal,
                        title: '슬롯 AS 기간연장',
                        text: `[${checkList?.length}] 개의 상품을 ${asExtendDay}일 AS 연장하시겠습니까?`,
                        successText: 'AS 기간 연장',
                        isActive: true,
                        cancelText: '취소',
                        onSuccess: () => {
                          // const product_ids = checkList;
                          // const end_date = moment(endDate).format('YYYY-MM-DD');
                          // const fields = {
                          //   end_date,
                          // };
                          asExtendProductClick();
                        },
                      });
                    }}
                  >
                    슬롯기간 AS 연장
                  </Button>
                </Box>
              </LabelBox>
            </Box>
          )}
          <Box>
            <LabelBox label={'일괄 적용'}>
              <Box>
                <Button
                  Theme={'unFill'}
                  size={'sm'}
                  // disabled={!checkList?.length}
                  onClick={() => {
                    const modify_infos = [];
                    list.forEach((e) => {
                      if (e.modifyChk) {
                        const keywords = e.keywords
                          ?.trim()
                          .split(',')
                          .map((keyword) => keyword.trim())
                          .filter((e) => e)
                          .join(',');
                        const nvmid = e.nvmid?.trim();
                        const group_search_nvmid = e.group_search_nvmid?.trim();
                        const search_rank_keyword = e.search_rank_keyword?.trim();
                        const field = {
                          id: e.id,
                          fields: {
                            keywords: keywords === '' ? null : keywords,
                            nvmid: nvmid === '' ? null : nvmid,
                            search_rank_keywords: e?.search_rank_keywords,
                            group_search_nvmid:
                              group_search_nvmid === '' ? null : group_search_nvmid,
                            search_rank_keyword:
                              search_rank_keyword === '' ? null : search_rank_keyword,
                          },
                        };
                        modify_infos.push(field);
                      }
                    });
                    setModal({
                      ...modal,
                      title: '일괄 적용',
                      text: `[${modify_infos?.length}] 개의 변경 사항을 일괄 적용시키겠습니까?`,
                      successText: '일괄 적용',
                      isActive: true,
                      cancelText: '취소',
                      onSuccess: () => {
                        if (modify_infos?.length) slotsSet({ modify_infos });
                      },
                    });
                  }}
                >
                  일괄 변경
                </Button>
                <Button
                  Theme={'unFill'}
                  size={'sm'}
                  disabled={!checkList?.length}
                  onClick={() => {
                    setModal({
                      ...modal,
                      title: '일시 중지',
                      text: `[${checkList?.length}] 개의 슬롯을 일시 중지시키겠습니까?`,
                      successText: '일시 중지',
                      isActive: true,
                      cancelText: '취소',
                      onSuccess: () => {
                        const modify_infos = [];
                        checkList.forEach((id) => {
                          const field = {
                            id: parseInt(id),
                            fields: {
                              temp_stop_chk: true,
                            },
                          };
                          modify_infos.push(field);
                        });
                        slotsSet({ modify_infos });
                        setCheckList([]);
                        setAllChk(false);
                      },
                    });
                  }}
                >
                  일시 중지
                </Button>
                {loginInfo?.user_level >= 4 && (
                  <Button
                    Theme={'unFill'}
                    size={'sm'}
                    disabled={!checkList?.length}
                    onClick={() => {
                      setModal({
                        ...modal,
                        title: '발급 취소',
                        text: `[${checkList?.length}] 개의 슬롯을 발급 취소시키겠습니까?`,
                        successText: '발급 취소',
                        isActive: true,
                        cancelText: '취소',
                        onSuccess: () => {
                          const ids = checkList;
                          slotDel({ ids });
                          setAllChk(false);
                          setCheckList([]);
                        },
                      });
                    }}
                  >
                    발급 취소
                  </Button>
                )}
              </Box>
            </LabelBox>
          </Box>
        </Box>
      </Box>
      {loginInfo?.user_level >= 5 ? (
        <Box>
          <CheckBox checked={isKeywordLessChk} onClick={() => {}}>
            키워드 부족 슬롯 보기
          </CheckBox>
          <CheckBox checked={isKeywordStopChk}>키워드 붖고 중지 슬롯 보기</CheckBox>
        </Box>
      ) : null}
      <Box>
        <CheckBox
          checked={showDoTypes?.includes('etc_do1')}
          onChange={(e) => {
            console.log(e);
            if (e?.target.checked) {
              setShowDoTypes([...showDoTypes, 'etc_do1']);
            } else {
              setShowDoTypes(showDoTypes?.filter((i) => i !== 'etc_do1'));
            }
          }}
        >
          유입 슬롯보기
        </CheckBox>
        <CheckBox
          checked={showDoTypes?.includes('click')}
          onChange={(e) => {
            console.log(e);
            if (e?.target.checked) {
              setShowDoTypes([...showDoTypes, 'click']);
            } else {
              setShowDoTypes(showDoTypes?.filter((i) => i !== 'click'));
            }
          }}
        >
          유효타 슬롯보기
        </CheckBox>
      </Box>
      <TableComponent
        onSearch={null}
        isController={false}
        isHeaad={false}
        allCheckbox={true}
        isInterval={false}
        searchText={null}
      >
        <LabelBox row label="검색">
          <Box fill>
            <InputComponent
              value={searchWord}
              onChange={(e) => {
                setSearchWord(e.target.value);
                // forceUpdate();
              }}
            />
          </Box>
        </LabelBox>
        <Box top={8} />

        {/* <ButtonBlock>
          <Box>
            {slots?.length} [{checkList?.length}]
          </Box>
          <Box>
            <PaginationComponent page={page} pageMax={pageMax} onPage={onPage} />
            
          </Box>
          <Box>
            <DropBox
              line
              option={[
                { title: '1000개', selected: true },
                { title: '500개' },
                { title: '300개' },
                { title: '100개' },
              ]}
              size={40}
              isColor
            />
          </Box>
        </ButtonBlock> */}
        <PaginationBox
          checkList={checkList}
          setCheckList={setCheckList}
          setList={setList}
          allList={allList}
        />
        <Table>
          <colgroup>
            {/*체크박스  */}
            {loginInfo.user_level >= 2 && <col style={{ width: '40rem' }} />}
            {/* 순번 */}
            <col style={{ width: '50rem' }} />
            {/* 발급 */}
            <col style={{ width: '50rem' }} />
            {/* 종류 */}
            <col style={{ width: '60rem' }} />
            {/* 유저 */}
            <col style={{ width: '90rem' }} />
            {/* 발행일 */}
            <col style={{ width: '100rem' }} />
            {/* 묶음nvmid */}
            <col style={{ width: '150rem' }} />
            {/* nvmid */}
            <col style={{ width: '150rem' }} />
            {/* 키워드 */}
            <col style={{ width: '270rem' }} />
            {/* 진행 */}
            <col style={{ width: '60rem' }} />
            {/* 순위 */}
            <col style={{ width: '100rem' }} />
            {/* 쇼핑몰 */}
            <col style={{ width: '110rem' }} />
            {/* 기간 */}
            <col style={{ width: '120rem' }} />
            {/* 2차대행사 */}
            {loginInfo.user_level >= 4 && <col style={{ width: '80rem' }} />}
            {/* 진행 */}
            {loginInfo.user_level >= 5 && <col style={{ width: '100rem' }} />}
          </colgroup>
          <THeader>
            <tr>
              {loginInfo.user_level >= 2 && (
                <th>
                  <CheckBox
                    checked={allChk}
                    onChange={(e) => {
                      setAllChk(e.target.checked);
                      if (e.target.checked) {
                        const list = [];
                        // const paid_list = [];
                        checkTarget.current
                          .querySelectorAll('input.checkBox[type="checkbox"]')
                          .forEach((i) => {
                            list.push(parseInt(i.name));
                            // if (i.value === 'paid') paid_list.push(i.name);
                          });
                        setCheckList(list);
                        // setPaidCheckList(paid_list);
                      } else {
                        // setPaidCheckList([]);
                        setCheckList([]);
                      }
                      forceUpdate();
                    }}
                  />
                </th>
              )}
              <th>&nbsp;</th>
              <th>발급</th>
              <th>종류</th>
              <th>유저</th>
              <th>발행일</th>
              <th>묶음nvmid</th>
              <th>nvmid</th>
              <th>키워드</th>
              <th>진행</th>
              <th>순위</th>
              {/* <th >네이버스토어</th> */}
              <th>쇼핑몰</th>
              <th>기간</th>
              {/* {loginInfo.user_level >= 4 && <th >유저</th>} */}
              {loginInfo.user_level >= 4 && <th>2차대행사</th>}
              {loginInfo.user_level >= 5 && <th>실제,show Ct</th>}
              {/* <th >상태</th> */}
              {/* <th >순위변화</th> */}
              {/* <th >체크 키워드</th> */}
            </tr>
          </THeader>
          {/* <SwiperBlock> */}
          {/* <Swiper
              spaceBetween={100}
              slidesPerView={1}
              navigation
              clickable
              pagination={{ clickable: true }}
            >
              <SwiperSlide key={1}> */}
          <Tbody ref={checkTarget}>
            <React.Fragment>
              {(searchWord === '' ? list : allList)
                ?.filter((e) => {
                  if (searchWord === '') return true;
                  const date = e?.issue_datetime?.slice(5, 16);
                  const start_date = e?.start_date?.slice(5);
                  const end_date = e?.end_date?.slice(5);

                  if (e.id?.toString()?.indexOf(searchWord) >= 0) return true;
                  if (e.user_name?.indexOf(searchWord) >= 0) return true;
                  // if (e.group_name?.indexOf(searchWord) >= 0) return true;
                  if (e.keywords?.indexOf(searchWord) >= 0) return true;
                  if (e.group_search_nvmid?.indexOf(searchWord) >= 0) return true;
                  if (e.nvmid?.indexOf(searchWord) >= 0) return true;
                  if (e.issue_type_text?.indexOf(searchWord) >= 0) return true;
                  if (e.do_type_text?.indexOf(searchWord) >= 0) return true;
                  if (e.partner_name?.indexOf(searchWord) >= 0) return true;
                  if (e.mall_name?.indexOf(searchWord) >= 0) return true;
                  if (e.search_rank?.toString()?.indexOf(searchWord) >= 0) return true;
                  // if (e.title?.indexOf(searchWord) >= 0) return true;
                  if (date?.indexOf(searchWord) >= 0) return true;
                  if (start_date?.indexOf(searchWord) >= 0) return true;
                  if (end_date?.indexOf(searchWord) >= 0) return true;

                  return false;
                })

                ?.filter((e) => {
                  return showDoTypes?.includes(e?.do_type);
                  // if (togglePaidActiveSlot) {
                  // if (e?.paid_type === 'paid') return true;
                  // else return false;
                  // }
                  // if (toggleActiveSlot && e?.isUsing) return true;
                  // if (toggleActiveSlot && e?.start_date > today_str()) return true;
                  // if (toggleUnactiveSlot && !e?.isUsing && e?.start_date <= today_str())
                  // return true;
                  // return false;
                  // return true;
                })
                ?.map((e, idx) => {
                  var line_ct = e?.expand_chk ? 2 : 1;
                  // if (e.group_search_chk) line_ct += 1;
                  // if (e.manual_keywords_chk) line_ct += 2;
                  var colsPanCt = '10';
                  const in_shop_update_chk = e?.in_shop_update_chk;
                  const type =
                    !e?.keywords || !e?.nvmid
                      ? 'not_setting'
                      : e?.start_date <= today
                      ? 'active'
                      : 'pre_active';
                  const type_text =
                    type === 'not_setting'
                      ? ''
                      : type === 'active'
                      ? in_shop_update_chk
                        ? '진행'
                        : '대기'
                      : '예정';

                  if (loginInfo.user_level >= 5) colsPanCt = '13';
                  else if (loginInfo.user_level >= 4) colsPanCt = '12';
                  else if (loginInfo.user_level >= 3) colsPanCt = '11';
                  else colsPanCt = '11';

                  return (
                    <React.Fragment key={idx}>
                      <TrBlock
                        etc_do1_problem_chk={e?.etc_do1_problem_chk}
                        closeChk={e?.close_product_chk}
                        isCancel={e?.isCancel}
                      >
                        {loginInfo.user_level >= 2 && (
                          <td rowSpan={line_ct}>
                            <StatusLogo type={type}>{type_text}</StatusLogo>

                            <CheckBox
                              disabled={e.isCancel}
                              name={e.id}
                              value={e.paid_type}
                              checked={checkList.includes(e?.id)}
                              onChange={(i) => {
                                if (i?.target.checked) {
                                  if (!checkList.includes(e?.id)) {
                                    checkList.push(e?.id);
                                    setCheckList(checkList);
                                    forceUpdate();
                                  }
                                  // if (e.paid_type === 'paid') {
                                  //   if (!paidCheckList.includes(e?.id)) {
                                  //     paidCheckList.push(e?.id);
                                  //     setPaidCheckList(paidCheckList);
                                  //     forceUpdate();
                                  //   }
                                  // }
                                } else {
                                  const list = checkList.filter((x) => x !== e?.id);
                                  setCheckList(list);

                                  // const paidList = paidCheckList.filter((x) => x !== e?.id);
                                  // setPaidCheckList(paidList);
                                }
                              }}
                            />
                          </td>
                        )}
                        {/* <td rowSpan={line_ct}>
                          <StatusText>{idx + 1}</StatusText>
                        </td> */}
                        <td>
                          <TextBlock>
                            <Highlight search={searchWord}>{e.id?.toString()}</Highlight>
                          </TextBlock>
                        </td>
                        <td>
                          <TextBlock>
                            <Highlight search={searchWord}>
                              {e.issue_type_text?.toString()}
                            </Highlight>
                          </TextBlock>
                        </td>
                        <td>
                          <TextBlock>
                            <Highlight search={searchWord}>{e.do_type_text?.toString()}</Highlight>
                            {loginInfo?.user_level >= 5 ? (
                              <>
                                {e.do_type_text === '타입1'
                                  ? ' (유입)'
                                  : e.do_type_text === '타입2'
                                  ? ' (클릭)'
                                  : ' (신규임)'}
                              </>
                            ) : null}
                          </TextBlock>
                        </td>
                        <td>
                          <TextBlock>
                            <TextButton
                              to={null}
                              onClick={() => {
                                // setSelectedUserName(e.user_name);
                                setSearchWord(e?.user_name);
                                // navigate(`/product/slot?slot=${date}`);
                              }}
                            >
                              <TextBlock>
                                <Highlight search={searchWord}>{e?.user_name}</Highlight>
                              </TextBlock>
                            </TextButton>
                          </TextBlock>
                        </td>

                        <td>
                          {/* {e?.paid_type === 'paid' ? (
                            <Badge color="Success_500" status size="auto">
                              지급확인
                            </Badge>
                          ) : (
                            <></>
                          )} */}
                          <TextButton
                            to={null}
                            onClick={() => {
                              const date = e.issue_datetime.slice(5, 16);
                              // setSelectedUserName(e.user_name);
                              setSearchWord(date);
                              // navigate(`/product/slot?slot=${date}`);
                            }}
                          >
                            <TextBlock>
                              <Highlight search={searchWord}>
                                {e?.issue_datetime?.slice(5, 16)?.toString()}
                              </Highlight>
                            </TextBlock>
                          </TextButton>
                        </td>
                        <td>
                          <Box width={130} valign={'center'}>
                            <InputComponent
                              name={e?.id}
                              fontSize={12}
                              weight={400}
                              maxLength={12}
                              padding={[0, 10, 0, 10]}
                              value={e.group_search_nvmid}
                              onChange={(i) => {
                                e.modifyChk = true;
                                e.group_search_nvmid = i?.target.value;
                                forceUpdate();
                                // onInputChange(i);
                              }}
                            />
                          </Box>
                        </td>
                        {/* <td >
                          <Box>
                            <ToggleButton
                              isReadOnly={loginInfo?.user_level <= 1}
                              active={e?.group_search_chk}
                              onClick={() => {
                                const id = e.id;
                                const other_store_chk = e.group_search_chk
                                  ? false
                                  : e.other_store_chk;

                                slotSet({
                                  id,
                                  fields: {
                                    other_store_chk,
                                    group_search_chk: !e.group_search_chk,
                                  },
                                });
                              }}
                            />
                          </Box>
                        </td> */}
                        <td>
                          <Box width={130} valign={'center'}>
                            <InputComponent
                              fontSize={12}
                              weight={400}
                              maxLength={12}
                              padding={[0, 10]}
                              name={e?.id}
                              value={e.nvmid}
                              onChange={(i) => {
                                e.modifyChk = true;
                                e.nvmid = i?.target.value;
                                forceUpdate();
                                // onInputChange(i);
                              }}
                            />
                          </Box>
                        </td>
                        <td>
                          {/* {loginInfo?.user_level >= 5 ? (
                            <>
                              <Box>
                                <>
                                  {loginInfo?.user_level >= 5 ? (
                                    <>
                                      {e?.etc_do1_problem_chk ? (
                                        <>
                                          <Badge color="Error_500" status size="auto">
                                            네이버 유입 제제
                                          </Badge>
                                          <TextButton>
                                            <TextSmall
                                              onClick={async () => {
                                                const result =
                                                  await requestLoadSlotRequestEtcDo1ProblemReset({
                                                    id: e?.id,
                                                  });
                                                if (result) {
                                                  dispatch(
                                                    onShowToast({
                                                      status: 'success',
                                                      message: `요청이 완료되었습니다.`,
                                                      cancelText: '',
                                                      onCancel: false,
                                                    }),
                                                  );
                                                } else {
                                                  dispatch(
                                                    onShowToast({
                                                      status: 'error',
                                                      message: `오류가 발생했습니다`,
                                                      cancelText: '',
                                                      onCancel: false,
                                                    }),
                                                  );
                                                }
                                              }}
                                            >
                                              유입 다시 체크요청
                                            </TextSmall>
                                          </TextButton>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {e?.check_fail_tonghap_search_keywords_count >=
                                  e?.search_keyword_check_fail_limit_count ? (
                                    <>
                                      <Badge color="Error_500" status size="auto">
                                        검색 키워드 찾기 실패
                                      </Badge>
                                      <TextButton>
                                        <TextSmall
                                          onClick={async () => {
                                            const result =
                                              await requestLoadSlotRequestSearchKeywordAgain({
                                                id: e?.id,
                                              });
                                            if (result) {
                                              dispatch(
                                                onShowToast({
                                                  status: 'success',
                                                  message: `재검색 요청이 완료되었습니다.`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            } else {
                                              dispatch(
                                                onShowToast({
                                                  status: 'error',
                                                  message: `오류가 발생했습니다`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            }
                                          }}
                                        >
                                          재검색 요청
                                        </TextSmall>
                                      </TextButton>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e?.check_fail_shop_search_keywords_count >=
                                  e?.search_keyword_check_fail_limit_count ? (
                                    <>
                                      <Badge color="Error_500" status size="auto">
                                        검색 키워드 찾기 실패
                                      </Badge>
                                      <TextButton>
                                        <TextSmall
                                          onClick={async () => {
                                            const result =
                                              await requestLoadSlotRequestSearchKeywordAgain({
                                                id: e?.id,
                                              });
                                            if (result) {
                                              dispatch(
                                                onShowToast({
                                                  status: 'success',
                                                  message: `재검색 요청이 완료되었습니다.`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            } else {
                                              dispatch(
                                                onShowToast({
                                                  status: 'error',
                                                  message: `오류가 발생했습니다`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            }
                                          }}
                                        >
                                          재검색 요청
                                        </TextSmall>
                                      </TextButton>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              </Box>
                              {e.close_product_chk ? null : !e?.nvmid || !e?.keywords ? (
                                <></>
                              ) : e?.tonghap_search_keywords_count >=
                                  e?.tonghap_search_keywords_count_limit_max &&
                                e?.shop_search_keywords_count >=
                                  e?.shop_search_keywords_count_limit_max ? (
                                <Badge color="Success_500" status size="auto">
                                  쇼핑/통합 키워드 수집완료
                                </Badge>
                              ) : (
                                <Box gap={12}>
                                  {e?.tonghap_search_keywords_count >=
                                  e?.tonghap_search_keywords_count_limit_max ? (
                                    <>
                                      <Badge color="Success_500" status size="auto">
                                        통합검색 완료
                                      </Badge>
                                    </>
                                  ) : (
                                    <Badge color="Warning_500" status size="auto" fontSize={12}>
                                      통합검색 체크중[
                                      {get_percent(
                                        e.tonghap_search_keywords_count,
                                        e.tonghap_search_keywords_count_max,
                                      )}
                                      %]
                                    </Badge>
                                  )}

                                  {e?.shop_search_keywords_count >=
                                  e?.shop_search_keywords_count_max ? (
                                    <Badge color="Success_500" status size="auto">
                                      쇼핑검색 완료
                                    </Badge>
                                  ) : (
                                    <Badge color="Warning_500" status size="auto" fontSize={12}>
                                      쇼핑검색 체크중[
                                      {get_percent(
                                        e.shop_search_keywords_count,
                                        e.shop_search_keywords_count_max,
                                      )}
                                      %]
                                    </Badge>
                                  )}
                                </Box>
                              )}
                            </>
                          ) : (
                            <>
                              <Box>
                                <>
                                  {e?.check_fail_tonghap_search_keywords_count >=
                                  e?.search_keyword_check_fail_limit_count ? (
                                    <>
                                      <Badge color="Error_500" status size="auto">
                                        검색 키워드 찾기 실패
                                      </Badge>
                                      <TextButton>
                                        <TextSmall
                                          onClick={async () => {
                                            const result =
                                              await requestLoadSlotRequestSearchKeywordAgain({
                                                id: e?.id,
                                              });
                                            if (result) {
                                              dispatch(
                                                onShowToast({
                                                  status: 'success',
                                                  message: `재검색 요청이 완료되었습니다.`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            } else {
                                              dispatch(
                                                onShowToast({
                                                  status: 'error',
                                                  message: `오류가 발생했습니다`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            }
                                          }}
                                        >
                                          재검색 요청
                                        </TextSmall>
                                      </TextButton>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e?.check_fail_shop_search_keywords_count >=
                                  e?.search_keyword_check_fail_limit_count ? (
                                    <>
                                      <Badge color="Error_500" status size="auto">
                                        검색 키워드 찾기 실패
                                      </Badge>
                                      <TextButton>
                                        <TextSmall
                                          onClick={async () => {
                                            const result =
                                              await requestLoadSlotRequestSearchKeywordAgain({
                                                id: e?.id,
                                              });
                                            if (result) {
                                              dispatch(
                                                onShowToast({
                                                  status: 'success',
                                                  message: `재검색 요청이 완료되었습니다.`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            } else {
                                              dispatch(
                                                onShowToast({
                                                  status: 'error',
                                                  message: `오류가 발생했습니다`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            }
                                          }}
                                        >
                                          재검색 요청
                                        </TextSmall>
                                      </TextButton>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              </Box>
                              {e.close_product_chk ? null : !e?.nvmid || !e?.keywords ? (
                                <></>
                              ) : e?.tonghap_search_keywords_count >=
                                  e?.tonghap_search_keywords_count_limit_max ||
                                e?.shop_search_keywords_count >=
                                  e?.shop_search_keywords_count_limit_max ? (
                                <Badge color="Success_500" status size="auto">
                                  쇼핑/통합 키워드 수집완료
                                </Badge>
                              ) : (
                                <Box gap={12}>
                                  {e?.tonghap_search_keywords_count >=
                                  e?.tonghap_search_keywords_count_limit_max ? (
                                    <>
                                      <Badge color="Success_500" status size="auto">
                                        통합검색 완료
                                      </Badge>
                                    </>
                                  ) : (
                                    <Badge color="Warning_500" status size="auto" fontSize={12}>
                                      통합검색 체크중[
                                      {get_percent(
                                        e.tonghap_search_keywords_count,
                                        e.tonghap_search_keywords_count_max,
                                      )}
                                      %]
                                    </Badge>
                                  )}

                                  {e?.shop_search_keywords_count >=
                                  e?.shop_search_keywords_count_max ? (
                                    <Badge color="Success_500" status size="auto">
                                      쇼핑검색 완료
                                    </Badge>
                                  ) : (
                                    <Badge color="Warning_500" status size="auto" fontSize={12}>
                                      쇼핑검색 체크중[
                                      {get_percent(
                                        e.shop_search_keywords_count,
                                        e.shop_search_keywords_count_max,
                                      )}
                                      %]
                                    </Badge>
                                  )}
                                </Box>
                              )}
                            </>
                          )} */}
                          <Box>
                            <InputComponent
                              fontSize={12}
                              weight={400}
                              padding={[0, 10]}
                              value={e.keywords}
                              onChange={(i) => {
                                e.modifyChk = true;
                                e.keywords = i.target.value;
                                forceUpdate();
                              }}
                            />
                            {/* <ToggleButton
                              isReadOnly={loginInfo?.user_level <= 1}
                              active={e?.manual_keywords_chk}
                              onClick={() => {
                                const id = e.id;
                                const auto_keywords_chk = e.manual_keywords_chk
                                  ? e.auto_keywords_chk
                                  : true;
                                slotSet({
                                  id,
                                  fields: {
                                    manual_keywords_chk: !e.manual_keywords_chk,
                                    auto_keywords_chk,
                                  },
                                });
                              }}
                            /> */}
                          </Box>
                        </td>
                        <td>
                          <PercentText percent={e.count_per}>{e.count_per}%</PercentText>
                        </td>
                        <td>
                          <Box column>
                            <Highlight search={searchWord}>
                              {e?.search_rank_find_fail_chk ? (
                                <>
                                  <Badge color="Error_500" status size="auto">
                                    체크 실패
                                  </Badge>
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              <RankModule
                                insertKeywordChk={!e?.search_rank_keyword}
                                onClick={async () => {
                                  const nvmid = e.nvmid;
                                  const group_search_nvmid = e.group_search_nvmid;
                                  const r = rankLog[`${group_search_nvmid}_${nvmid}`]
                                    ? rankLog[`${group_search_nvmid}_${nvmid}`]
                                    : await requestLoadSlotGetRankLog({
                                        nvmid,
                                        group_search_nvmid,
                                      });
                                  rankLog[`${group_search_nvmid}_${nvmid}`] = r?.list;
                                  setRankLog(rankLog);
                                  e.expand_chk = !e.expand_chk;
                                  forceUpdate();
                                }}
                                rank={e?.search_rank}
                                rank_keyword={e?.search_rank_keyword}
                                last_rank={e?.search_last_rank}
                                last_rank_date_ago_day={e?.search_last_rank_date_ago_day}
                                last_rank_keyword={e?.search_last_rank_keyword}
                              />
                              {/* RankModule = (
                                {((keyword = null), (rank = null), (last_rank = null))}) */}
                              {/* <Box column>
                                  {e?.search_rank_keyword ? (
                                    <>
                                      <Box>
                                        {!e?.search_rank ? '-' : e.search_rank.toString()} (
                                        {!e?.search_last_rank ? (
                                          '-'
                                        ) : e?.search_last_rank > e?.search_rank ? (
                                          <>
                                            <Icon.DoubleArrow
                                              direction="top"
                                              fill={'#0000f0'}
                                              size="15"
                                            />
                                            <small>{e?.search_last_rank - e?.search_rank}</small>
                                          </>
                                        ) : (
                                          <>
                                            <Icon.DoubleArrow
                                              direction="bottom"
                                              fill={'#f00000'}
                                              size="15"
                                            />
                                            <b>{e?.search_rank - e?.search_last_rank}</b>
                                          </>
                                        )}
                                        )
                                      </Box>
                                    </>
                                  ) : (
                                    <RankEmptyBlock>키워드 입력</RankEmptyBlock>
                                  )}
                                </Box> */}
                              {/* <Box column>
                                  {rank_info_list?.length ? (
                                    <>
                                      {rank_info_list
                                        .sort((a, b) => a.search_rank > b.search_rank)
                                        .slice(0, 2)
                                        ?.map((rank_info) => (
                                          <Box>
                                            {!rank_info?.search_rank
                                              ? '-'
                                              : rank_info.search_rank.toString()}{' '}
                                            (
                                            {!rank_info?.search_last_rank ? (
                                              '-'
                                            ) : rank_info.search_last_rank >
                                              rank_info.search_rank ? (
                                              <>
                                                <Icon.DoubleArrow
                                                  direction="top"
                                                  fill={'#0000f0'}
                                                  size="15"
                                                />
                                                <small>
                                                  {rank_info.search_last_rank -
                                                    rank_info.search_rank}
                                                </small>
                                              </>
                                            ) : (
                                              <>
                                                <Icon.DoubleArrow
                                                  direction="bottom"
                                                  fill={'#f00000'}
                                                  size="15"
                                                />
                                                <b>
                                                  {rank_info.search_rank -
                                                    rank_info.search_last_rank}
                                                </b>
                                              </>
                                            )}
                                            )
                                          </Box>
                                        ))}
                                    </>
                                  ) : (
                                    <RankEmptyBlock>키워드 입력</RankEmptyBlock>
                                  )}
                                </Box> */}
                            </Highlight>
                          </Box>
                        </td>
                        {/* <td >
                          <Box>
                            <ToggleButton
                              isReadOnly={
                                loginInfo?.user_level < 5 &&
                                (!e?.group_search_chk || loginInfo?.user_level <= 1)
                              }
                              active={!e?.other_store_chk}
                              onClick={() => {
                                const id = e.id;
                                slotSet({
                                  id,
                                  fields: { other_store_chk: !e.other_store_chk },
                                });
                              }}
                            />
                          </Box>
                        </td> */}
                        <td align={'center'}>
                          {e.no_find_nvmid_ct >= e?.no_find_nvmid_fail_ct_limit ? (
                            <>
                              <StatusText>
                                <TextFail>
                                  <TextSmall>
                                    <span>NVMID값 찾기 실패</span>
                                  </TextSmall>
                                </TextFail>
                              </StatusText>
                            </>
                          ) : e.close_product_chk ? (
                            <StatusText>
                              <span>
                                <TextFail>{e.close_product_status}</TextFail>
                              </span>
                            </StatusText>
                          ) : (
                            <button
                              onClick={() => {
                                const mall_name = e?.mall_name;
                                if (mall_name) navigate_newtab(`/mall/${mall_name}`);
                              }}
                            >
                              <StatusTitleText>
                                {e.navershopping_foreign_chk ? <h2>[해외직구]</h2> : <></>}
                                {e.navershopping_chk ? <h2>[쇼핑윈도]</h2> : <></>}
                                {e.brandstore_chk ? <h3>[브랜드몰]</h3> : <></>}
                                {e.smartstore_chk === true ? (
                                  <></>
                                ) : e.smartstore_chk === false ? (
                                  <small>[오픈마켓]</small>
                                ) : (
                                  <></>
                                )}
                                {e.adult_chk ? <small>[성인인증상품]</small> : null}
                                <h1>
                                  <Highlight search={searchWord}>
                                    {e.mall_name?.toString()}
                                  </Highlight>
                                </h1>
                                {/* <Highlight search={searchWord}>{e.title?.toString()}</Highlight> */}
                              </StatusTitleText>
                            </button>
                          )}
                        </td>
                        <td>
                          <TextBlock>
                            [{e?.days}일]
                            <Highlight search={searchWord}>
                              {e?.start_date?.slice(5)?.toString()}
                            </Highlight>
                            ~
                            <Highlight search={searchWord}>
                              {e?.end_date?.slice(5)?.toString()}
                            </Highlight>
                          </TextBlock>
                        </td>

                        {loginInfo.user_level >= 4 && (
                          <td>
                            <TextButton
                              onClick={() => {
                                setSearchWord(e?.partner_name);
                                // setSelectedPartnerName(e.partner_name);
                                // navigate(`/product/slot?slot=${e.partner_name}`);
                              }}
                            >
                              <TextBlock>
                                <Highlight search={searchWord}>
                                  {e.partner_name?.toString()}
                                </Highlight>
                              </TextBlock>
                            </TextButton>
                          </td>
                        )}
                        {loginInfo.user_level >= 5 && (
                          <td>
                            <Box>
                              <ToggleButton
                                active={!e?.temp_stop_chk}
                                // isReadOnly={loginInfo?.user_level >= 3 ? false : true}
                                onClick={async () => {
                                  await toggleSlotUse(e.id, !e?.temp_stop_chk);
                                }}
                              />
                            </Box>
                          </td>
                        )}
                        {loginInfo.user_level >= 5 && (
                          <td>
                            <Box>
                              <ToggleButton
                                active={!e?.admin_manual_temp_stop_chk}
                                // isReadOnly={loginInfo?.user_level >= 3 ? false : true}
                                onClick={async () => {
                                  await adminManualToggleSlotUse(
                                    e.id,
                                    !e?.admin_manual_temp_stop_chk,
                                  );
                                }}
                              />
                            </Box>
                          </td>
                        )}
                      </TrBlock>
                      {e?.expand_chk && (
                        <TrBlock>
                          <td colspan={colsPanCt}>
                            <Box column>
                              <Box gap={24}>
                                <Box column>
                                  <Box>
                                    <LabelBox row label={'순위 체크 키워드 추가'}>
                                      <Box width={300}>
                                        <InputComponent
                                          // disabled={e?.search_rank_keyword?.length > 0}
                                          // value={e.add_search_rank_keyword}
                                          value={e.search_rank_keyword}
                                          fontSize={14}
                                          weight={400}
                                          // maxLength={3}
                                          size={30}
                                          onChange={(i) => {
                                            e.modifyChk = true;
                                            // e.add_search_rank_keyword = i?.target.value;
                                            e.search_rank_keyword = i?.target.value;
                                            forceUpdate();

                                            // onInputChange(i);
                                          }}
                                        />
                                      </Box>
                                    </LabelBox>
                                    <Button
                                      // disabled={e?.search_rank_keyword?.length > 0}
                                      onClick={async () => {
                                        const id = e.id;
                                        // const product_id = e.product_id;
                                        // const search_rank_keywords = e?.search_rank_keywords;
                                        // const add_search_rank_keyword = e.add_search_rank_keyword;
                                        // const new_search_rank_keywords = Array.from(
                                        //   new Set(
                                        //     search_rank_keywords.concat([add_search_rank_keyword]),
                                        //   ),
                                        // );

                                        // await slotSet({
                                        //   id,
                                        //   fields: {
                                        //     search_rank_keywords: new_search_rank_keywords,
                                        //   },
                                        // });
                                        const search_rank_keyword = e?.search_rank_keyword;
                                        await slotsSet({
                                          reload_chk: false,
                                          modify_infos: [
                                            {
                                              id,
                                              fields: {
                                                nvmid: e.nvmid,
                                                group_search_nvmid: e.group_search_nvmid,
                                                keywords: e.keywords,
                                                search_rank_keyword: search_rank_keyword,
                                              },
                                            },
                                          ],
                                        });

                                        // const nvmid = e.nvmid;
                                        // const group_search_nvmid = e.group_search_nvmid;
                                        // const r = rankLog[`${group_search_nvmid}_${nvmid}`]
                                        //   ? rankLog[`${group_search_nvmid}_${nvmid}`]
                                        //   : await requestLoadSlotGetRankLog({
                                        //       nvmid,
                                        //       group_search_nvmid,
                                        //     });
                                        // rankLog[`${group_search_nvmid}_${nvmid}`] = r?.list;
                                        // setRankLog(rankLog);
                                        // e.search_rank_keywords = new_search_rank_keywords;
                                        // e.add_search_rank_keyword = null;
                                        forceUpdate();
                                      }}
                                      size="xs"
                                    >
                                      설정
                                    </Button>
                                  </Box>

                                  {/* <Box>
                                    <TagGroup>
                                      {e.search_rank_keywords
                                        ?.filter((search_rank_keyword) => search_rank_keyword)
                                        ?.map((search_rank_keyword) => {
                                          return (
                                            <>
                                              <Tag
                                                isActive={false}
                                                onClick={async () => {
                                                  const search_rank_keyword =
                                                    e?.search_rank_keyword;
                                                  const id = e.id;
                                                  // const product_id = e.product_id;
                                                  const search_rank_keywords =
                                                    e?.search_rank_keywords;
                                                  const new_search_rank_keywords =
                                                    search_rank_keywords.filter(
                                                      (i) => i !== search_rank_keyword,
                                                    );
                                                  await slotsSet({
                                                    reload_chk: false,
                                                    modify_infos: [
                                                      {
                                                        id,
                                                        fields: {
                                                          nvmid: e.nvmid,
                                                          group_search_nvmid: e.group_search_nvmid,
                                                          keywords: e.keywords,
                                                          search_rank_keyword: search_rank_keyword,
                                                          search_rank_keywords:
                                                            new_search_rank_keywords,
                                                        },
                                                      },
                                                    ],
                                                  });
                                                  const nvmid = e.nvmid;
                                                  const group_search_nvmid = e.group_search_nvmid;
                                                  const r = rankLog[
                                                    `${group_search_nvmid}_${nvmid}`
                                                  ]
                                                    ? rankLog[`${group_search_nvmid}_${nvmid}`]
                                                    : await requestLoadSlotGetRankLog({
                                                        nvmid,
                                                        group_search_nvmid,
                                                      });
                                                  rankLog[`${group_search_nvmid}_${nvmid}`] =
                                                    r?.list;
                                                  e.search_rank_keywords = new_search_rank_keywords;
                                                  forceUpdate();
                                                }}
                                              >
                                                {search_rank_keyword}
                                              </Tag>
                                            </>
                                          );
                                        })}
                                    </TagGroup>
                                  </Box> */}
                                </Box>
                              </Box>
                              {/* <Box>
                                {rankLog[`${e.group_search_nvmid}_${e.nvmid}`]?.length ? (
                                  <>
                                    <Box column>
                                      <Box>{e?.title}</Box>
                                      <Box>
                                        <RankLogGraph
                                          data={rankLog[`${e.group_search_nvmid}_${e.nvmid}`]}
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                ) : null}
                              </Box> */}
                            </Box>
                          </td>
                        </TrBlock>
                      )}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          </Tbody>
          {/* </SwiperSlide>
            </Swiper>
          </SwiperBlock> */}
        </Table>
      </TableComponent>
      <ModalQuestion {...modal} />
      <ProductRankModal
        {...productRankModalStatus}
        onClose={() => {
          setProductRankModalStatus({ ...productRankModalStatus, isActive: false });
        }}
      />
    </Box>
  );
};
const SwiperBlock = styled.div`
  .swiper {
    height: 1000rem;
  }
  .swiper-button-next::after {
    display: none;
  }
  .swiper-button-prev::after {
    display: none;
  }

  .swiper-slide {
    display: flex;
    height: 100%;
  }
  .swiper-pagination {
  }
  ${(props) => {
    const { width } = props;
    return css`
      width: ${width ? width : '1000'}rem;
    `;
  }};
`;

const TagGroup = styled.div`
  align-items: first baseline;
  padding: 5rem;
  gap: 10rem;
  display: block;
  max-width: 900rem;
`;
const TrBlock = styled.tr`
  ${(props) => {
    const { etc_do1_problem_chk, closeChk, isCancel, type } = props;
    if (etc_do1_problem_chk) {
      return css`
        background-color: #e0e0e0;
      `;
    } else if (closeChk || isCancel) {
      return css`
        background-color: #e0e0e0;
      `;
    } else if (type === 'active') {
      return css`
        /* background-color: #c5ecc5; */
      `;
    } else if (type === 'pre_active') {
      return css`
        /* background-color: #c3deea; */
      `;
    } else if (type === 'not_setting') {
      return css`
        /* background-color: #dd8282; */
      `;
    }
  }}

  &:hover {
    background-color: #f0f0f0;
    ${(props) => {
      const { isCancel } = props;
      if (isCancel) {
        return css`
          text-decoration: line-through;
          background-color: rgb(255, 0, 0);
          opacity: 0.5;
        `;
      }
    }}
  }
  ${(props) => {
    const { closeChk } = props;
    if (!closeChk) return;
    return css`
      border-bottom: 1rem solid red;
      border-collapse: collapse;
    `;
  }}
`;
const RankEmptyBlock = styled.div`
  font-size: 14rem;
  color: #67616176;
`;
const TextBlock = styled.div`
  font-size: 12rem;
  font-weight: 500;
  /* color: orange; */
`;
const SlotText = styled.div`
  font-size: 15rem;
  font-weight: 600;
  b {
    color: green;
  }
  small {
    color: blue;
  }
  span {
  }
`;
const ButtonBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-height: 50rem;
  padding: 5rem;
  padding-left: 10rem;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const StatusTitleText = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  /* text-align: left; */

  text-overflow: ellipsis;
  white-space: nowrap;

  h1 {
    font-size: 11rem;
    /* line-height: 9rem; */
    font-weight: 500;
    color: #4879ba;
  }
  small {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #f00000;
  }
  h2 {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #00a000;
  }
  h2 {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #265926;
  }
  h3 {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #265926;
  }
`;
const StatusLogo = styled.div`
  position: absolute;
  font-size: 12rem;
  font-weight: 400;
  text-align: center;
  left: -45rem;
  width: 40rem;
  height: 40rem;
  ${(props) => {
    const { type } = props;
    if (type === 'active') {
      return css`
        color: #4e8f4e;
        /* background-color: #c5ecc5; */
      `;
    } else if (type === 'pre_active') {
      return css`
        /* background-color: #c3deea; */
      `;
    } else if (type === 'not_setting') {
      return css`
        /* background-color: #dd8282; */
      `;
    }
  }}
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;

  span {
    font-size: 14rem;
    line-height: 18rem;
    /* color: #ba1717; */
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return css`
      color: #fff;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300);
      background-color: #bc340b;
    `;
  }}
`;

const PercentText = styled.span`
  font-size: 12rem;
  font-weight: 400;
  color: rgb(41, ${(props) => 100 + parseInt(props.percent)}, 59);
`;
export default ProductList;
