import React from 'react';
import styled, { css } from 'styled-components';

function Rank({ rank, customColor, count, disabled, size = 56, isOneLetter = false }) {
  const colors = {
    P: 'P_500',
    S: 'P_500',
    A: 'Success_500',
    B: 'Warning_600',
    C: 'S_600',
    D: 'Error_500',
    F: 'Error_500',
    '-': 'G_100',
  };

  return (
    <Wrapper
      sz={size}
      colors={colors}
      rank={rank}
      count={count}
      customColor={customColor}
      disabled={disabled}
    >
      {isOneLetter ? rank?.substr(0, 1) : rank}
      {count && <span className="count">{count}</span>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ rank, colors, disabled, sz, customColor }) => {
    if (customColor) {
      return css`
        border: 1rem solid var(--${customColor});
        color: var(--${customColor});
        width: ${sz}rem;
        height: ${sz}rem;
      `;
    }
    return css`
      border: 1rem solid ${disabled ? 'var(--G_100)' : `var(--${colors[rank]})`};
      color: var(--${colors[rank]});
      width: ${sz}rem;
      height: ${sz}rem;
    `;
  }}
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: ${(props) => (props.count ? '28rem' : '40rem')};
  letter-spacing: -0.03em;
  background: #ffffff;
  border-radius: 4rem;
  .count {
    font-weight: 400;
    font-size: 12rem;
    margin-top: -12rem;
    text-align: center;
    letter-spacing: -0.03em;
  }
  &::after {
    display: ${(props) => (props.disabled ? 'block' : 'none')};
    content: '';
    width: 14rem;
    height: 4rem;
    background-color: var(--G_100);
  }
`;
export default Rank;
