import useMutations from 'Common/Service/commonMutations';

import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import WorkStatusBox from 'Common/UI/molecules/WorkStatusBox';
import MainWorkerNotification from './Tab/MainWorkerNotification';
import { currencyComma, sortSet } from 'Common/Utils';
import { Button, Grid, InputComponent, TextBox, TextButton } from 'Common/UI/atoms';
import Accordion from 'Common/UI/molecules/Accordion/Accordion';
import CommonProgressBar from './Module/CommonProgressBar';
import { TextSmall, useForceUpdate } from 'Common/Utils/util';
import MainCalendar from './Tab/MainCalendar';
import MallInfo from 'MallInfo';
import RankLogModule from 'RankLogModule';
import ToggleButton from 'Page/Module/CheckToggleButton';
import { requestLoadGetBasicInfo, requestLoadSetBasicInfo } from 'Common/Service/common';
import { onShowToast } from 'features/common/commonSlice';

const Dashboard = () => {
  const [doCt, setDoCt] = useState();
  const [slotPer, setSlotPer] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  const [countType, setCountType] = useState(loginInfo.user_level >= 5 ? 'real' : null);
  const [etcDo1Count, setEtcDo1Count] = useState();
  const [result, setResults] = useState({});
  const [shopLinkCountData, setShopLinkCountData] = useState([]);
  const forceUpdate = useForceUpdate();
  const [workStatusList, setWorkStatusList] = useState();
  const [gridLayoutType, setGridLayoutType] = useState(0);
  const [rankInfos, setRankInfos] = useState([]);
  const [basicInfo, setBasicInfo] = useState({});
  const dispatch = useDispatch();
  // useState([
  //   {
  //     title: 'Vmware 작동 상황',
  //     // main_text: '30 %',
  //     // color_text: '12',
  //     // text: ' / 34',
  //   },
  //   {
  //     title: '슬롯 진행률',
  //     // main_text: '30 %',
  //     // color_text: '12',
  //     // text: ' / 34',
  //   },
  //   {
  //     title: '아이피 변환 속도',
  //     // main_text: '30 ',
  //     // text: ' s',
  //   },
  //   {
  //     title: '유효타 속도',
  //     // main_text: '30 ',
  //     // text: ' s',
  //   },
  //   {
  //     title: '네이버아이디 현황',
  //     // main_text: '20 완료',
  //     // color_text: '대기 80',
  //     // text: ' / 총 700개',
  //   },
  // ]);
  const [closeStatus, setCloseStatus] = useState({});
  const [showSlotcountLimit, setShowSlotcountLimit] = useState(1);
  const { requestLoadShopLinkGetApiDataInfoMutation, requestLoadDashboardGetDataInfoMutation } =
    useMutations(setResults);

  useEffect(() => {
    if (result?.request === 'requestLoadDashboardGetDataInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        const list = result?.result?.map((e) => {
          if (e['title'] === '작업') {
            return { title: '작업', main_text: currencyComma(doCt) };
          } else if (e['title'] === '슬롯 진행률') {
            if (slotPer) return { title: '슬롯 진행률', main_text: `${slotPer?.toFixed(1)} %` };
          } else if (e['title'] === '유입') {
            return { title: '유입', text: e['text'], main_text: currencyComma(etcDo1Count) };
          }
          return e;
        });
        setWorkStatusList(list);
        requestLoadShopLinkGetApiDataInfoMutation.mutate({ type: countType });
      }
    } else if (result?.request === 'requestLoadShopLinkGetApiDataInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        const shop_data = result?.result?.shop_data;
        const list = shop_data
          ?.map((e) => {
            const shop_data = e;
            var percent = Math.round(shop_data.to_count_per);
            if (percent > 100) percent = 100;
            return {
              to_etc_do1_count: shop_data.to_etc_do1_count,
              // to_etc_do1_count: shop_data.to_etc_do1_count,
              to_count: shop_data.to_count,
              to_count_max: shop_data.to_count_max,
              slot_count: shop_data.slot_count,
              mall_name: shop_data.mall_name,
              name: `슬롯:<font color='red'>${currencyComma(
                shop_data.slot_count,
              )}</font> [<font color='blue'>${currencyComma(shop_data.to_count)}</font>] ${
                // name: `[${shop_data.do_type_text}-${shop_data.to_click_count}] ${
                shop_data.etc_status
              }${`[${shop_data.mall_name}] ${shop_data.title}`}`,
              // }${
              //   shop_data.title
              //     ? shop_data.to_click_count
              //       ? `[${shop_data.id}] ${shop_data.mall_name} - ${shop_data.title}`
              //       : `[${shop_data.id}] ${shop_data.mall_name} - ${shop_data.title}`
              //     : shop_data.id
              // }`,
              // click_fail_continue_count: shop_data.click_fail_continue_count,
              // product_id: shop_data.product_id,
              user_name: shop_data.user_name,
              // do_type_text: shop_data.do_type_text,
              partner_name: shop_data.partner_name,
              group_name: shop_data.group_name,
              percent: percent,
            };
          })
          .sort(({ percent: a }, { percent: b }) => sortSet(b, a));
        var all_to_count = 0;
        var all_to_count_max = 0;
        var etc_do1_count = 0;
        const partner_list = list?.reduce((acc, data) => {
          var p = acc.find((e) => e.partner_name === data.partner_name);
          if (!p) {
            p = {
              partner_name: data.partner_name,
              to_etc_do1_count: 0,
              to_count: 0,
              to_count_max: 0,
              group_count: 0,
              product_count: 0,
              slot_count: 0,
              list: [],
            };
            acc.push(p);
          }

          var u = p.list.find((e) => e.user_name === data.user_name);
          if (!u) {
            u = {
              user_name: data.user_name,
              to_etc_do1_count: 0,
              to_count: 0,
              to_count_max: 0,
              product_count: 0,
              slot_count: 0,
              // user_name: data.user_name,
              list: [],
            };
            p.list.push(u);
            p.user_count += 1;
          }
          u.list.push(data);

          u.product_count += 1;
          p.product_count += 1;
          u.slot_count += data.slot_count;
          p.slot_count += data.slot_count;

          u.to_count += data.to_count;
          p.to_count += data.to_count;
          all_to_count += data.to_count;
          etc_do1_count += data.to_etc_do1_count;

          u.to_count_max += data.to_count_max;
          p.to_count_max += data.to_count_max;
          all_to_count_max += data.to_count_max;

          return acc;
        }, []);
        setEtcDo1Count(etc_do1_count);
        setDoCt(all_to_count);
        const slot_per = (all_to_count / all_to_count_max) * 100;
        setSlotPer(slot_per);
        const new_workStatusList = workStatusList?.map((e) => {
          if (e['title'] === '작업') {
            return { title: '작업', main_text: currencyComma(all_to_count) };
          } else if (e['title'] === '슬롯 진행률') {
            if (slot_per) return { title: '슬롯 진행률', main_text: `${slot_per?.toFixed(1)} %` };
          } else if (e['title'] === '유입') {
            if (etcDo1Count)
              return { title: '유입', text: e['text'], main_text: currencyComma(etc_do1_count) };
          }
          return e;
        });
        setWorkStatusList(new_workStatusList);
        setShopLinkCountData(partner_list);
      }
    }
    setResults({});
  }, [result.request]);
  const loadGetBasicInfo = async () => {
    const fields = [
      'limit_etc_do1_step1_count',
      'limit_start_slot_count_start',
      'limit_start_slot_count_end',
    ];
    const result = await requestLoadGetBasicInfo({ fields });
    setBasicInfo(result);
  };
  const requestSetBasicInfo = async () => {
    const fields = basicInfo;
    const result = await requestLoadSetBasicInfo({ fields });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `정보가 변경 되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    // const result =
  };

  const requestDatas = ({ type }) => {
    requestLoadDashboardGetDataInfoMutation.mutate({ type });
    // if (loginInfo.user_level >= 5) return
    setTimeout(() => {
      requestDatas({ type: countType });
    }, 50000);
    // setTimeout(() => {
    //   requestLoadShopLinkGetApiDataInfoMutation.mutate({});
    // }, 1000);
  };
  useEffect(() => {
    requestDatas({ type: countType });
    loadGetBasicInfo();
  }, []);

  const level = 'admin';
  return (
    <>
      <Box column gap={12} top={10}>
        {workStatusList?.length > 0 && (
          <Box gap={12} column style={`grid-column: 1/5;`}>
            <WorkStatusBox items={workStatusList} />
          </Box>
        )}

        <Grid column={gridLayoutType === 0 ? ['2fr', '3fr'] : ['1fr']} gap={12}>
          <Box column gap={8}>
            <Box width="100%">
              <RankLogModule
                rankInfos={rankInfos}
                setRankInfos={setRankInfos}
                isShow={gridLayoutType === 1}
                onClick={() => {
                  setGridLayoutType(1 - gridLayoutType);
                }}
              />
            </Box>
            {/* <Box>
              <MallInfo mall_names={'all'} />
            </Box> */}
            <Box>
              <MainCalendar rankInfos={rankInfos} />
            </Box>
          </Box>
          {/* <Box width="100%"> */}
          <span height="100%" width="100%">
            {loginInfo.user_level >= 5 ? (
              <Box bottom={10} gap={10}>
                <ToggleButton
                  active={countType === 'real'}
                  onClick={async () => {
                    const new_countType = countType === 'real' ? null : 'real';
                    setCountType(new_countType);

                    requestLoadShopLinkGetApiDataInfoMutation.mutate({ type: new_countType });
                  }}
                >
                  {countType === 'real' ? '실제 유입 표시' : '유저 데이터 표시'}
                </ToggleButton>
                <Box>
                  <Box width={50}>
                    <TextSmall>최소유입</TextSmall>
                  </Box>
                  <Box width={85}>
                    <InputComponent
                      padding={[5, 10]}
                      size={27}
                      fontSize={14}
                      name={'limit_etc_do1_step1_count'}
                      value={basicInfo?.limit_etc_do1_step1_count}
                      onChange={(e) => {
                        setBasicInfo({
                          ...basicInfo,
                          limit_etc_do1_step1_count: parseInt(e?.target?.value),
                        });
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Box width={95}>
                    <TextSmall>추가 관리 슬롯수</TextSmall>
                  </Box>
                  <Box width={80}>
                    <InputComponent
                      padding={[5, 10]}
                      size={27}
                      fontSize={12}
                      name={'limit_start_slot_count_start'}
                      value={basicInfo?.limit_start_slot_count_start}
                      onChange={(e) => {
                        setBasicInfo({
                          ...basicInfo,
                          limit_start_slot_count_start: parseInt(e?.target?.value),
                        });
                      }}
                    />
                  </Box>
                  ~
                  <Box width={80}>
                    <InputComponent
                      padding={[5, 10]}
                      size={27}
                      fontSize={12}
                      name={'limit_start_slot_count_end'}
                      value={basicInfo?.limit_start_slot_count_end}
                      onChange={(e) => {
                        setBasicInfo({
                          ...basicInfo,
                          limit_start_slot_count_end: parseInt(e?.target?.value),
                        });
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Button
                    size={'xxs'}
                    onClick={async () => {
                      await requestSetBasicInfo();
                      await loadGetBasicInfo();
                    }}
                  >
                    수정
                  </Button>
                </Box>

                <Box width={200}>
                  <Box width={90}>
                    <InputComponent
                      padding={[5, 10]}
                      size={27}
                      fontSize={12}
                      name={'show_slot_count_limit'}
                      value={showSlotcountLimit}
                      onChange={(e) => {
                        setShowSlotcountLimit(parseInt(e?.target?.value));
                      }}
                    />
                  </Box>
                  <TextSmall>이상 슬롯 보기</TextSmall>
                </Box>
              </Box>
            ) : null}
            {shopLinkCountData?.map((partner, key) => {
              const name = partner.partner_name;
              const isPartnerOpen = closeStatus[name] ? false : true;
              return (
                <React.Fragment key={key}>
                  {/* <Accordion
                    isShow={level === 'admin'}
                    isMin={false}
                    checkBox={false}
                    isHidden={true}
                    isOpen={true}
                    btnList={[
                      {
                        isActive: isPartnerOpen,
                        type: 'arrow',
                        onClick: () => {
                          closeStatus[name] = isPartnerOpen;
                          setCloseStatus(closeStatus);
                          forceUpdate();
                        },
                      },
                    ]}
                    custom={
                      <>
                        <Box width="100%" gap={8}>
                          <StatusBlock>{partner?.partner_name}</StatusBlock>
                          <Box width="100%">
                            <CommonProgressBar
                              column="170rem 1fr 50rem"
                              name={`유저 ${partner?.list?.length}명`}
                              percent={Math.round((partner?.to_count / partner.to_count_max) * 100)}
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  > */}
                  {partner?.list?.map((group, key2) => {
                    const name = `${partner?.partner_name}_${group?.user_name}`;
                    const isGroupOpen = closeStatus[name] ? false : true;
                    return (
                      <React.Fragment key={key2}>
                        <AccodianBlock isActive={isPartnerOpen}>
                          {/* <InBlock> */}
                          <Box width="100%" align="left">
                            <Accordion
                              isShow={level === 'admin' || level === 'partner'}
                              isMin={false}
                              isRound={false}
                              // key={index}
                              // style={'height: '}
                              checkBox={false}
                              isHidden={true}
                              isOpen={true}
                              btnList={[
                                {
                                  isActive: isGroupOpen,
                                  type: 'arrow',
                                  onClick: () => {
                                    closeStatus[name] = isGroupOpen;
                                    setCloseStatus(closeStatus);
                                    forceUpdate();
                                  },
                                },
                              ]}
                              custom={
                                <>
                                  <Box width="100%" gap={8}>
                                    <StatusBlock>{group?.user_name}</StatusBlock>
                                    <Box width="100%">
                                      <CommonProgressBar
                                        column="170rem 1fr 50rem"
                                        name={
                                          loginInfo?.user_level >= 3
                                            ? // ? `[${group?.user_name}] ${group?.list?.length}개 슬롯`
                                              `${currencyComma(
                                                group?.slot_count,
                                              )}개 슬롯 (${currencyComma(
                                                group?.list?.length,
                                              )} 상품)`
                                            : `${currencyComma(
                                                group?.slot_count,
                                              )}개 슬롯 (${currencyComma(
                                                group?.list?.length,
                                              )} 상품)`
                                        }
                                        percent={Math.round(
                                          (group?.to_count / group.to_count_max) * 100,
                                        )}
                                      />
                                    </Box>
                                  </Box>
                                </>
                              }
                            >
                              <Box width="100%" column bottom={12}>
                                <AccodianBlock isActive={isGroupOpen} count={group?.list?.length}>
                                  <MainWorkerNotification
                                    column={
                                      loginInfo?.user_level >= 5
                                        ? '500rem 1fr 50rem'
                                        : '300rem 1fr 50rem'
                                    }
                                    title="슬롯 진행 상황"
                                    data={group?.list?.filter((e) => {
                                      if (!showSlotcountLimit) return true;
                                      if (e?.slot_count >= parseInt(showSlotcountLimit))
                                        return true;
                                      else return false;
                                    })}
                                  />
                                </AccodianBlock>
                              </Box>
                            </Accordion>
                          </Box>
                          {/* </InBlock> */}
                        </AccodianBlock>
                      </React.Fragment>
                    );
                  })}
                  {/* </Accordion> */}
                </React.Fragment>
              );
            })}
          </span>
        </Grid>
        <Box top={12} />
      </Box>
    </>
  );
};
const AccodianBlock = styled.div`
  transition: all 0.3s ease-in;
  ${(props) => {
    const { count } = props;
    if (count === undefined) {
      return css`
        height: inhert;
      `;
    }
    return css`
      height: ${23 + parseInt(count) * 34}rem;
    `;
  }}
  ${(props) => {
    const { isActive } = props;
    if (!isActive) {
      return css`
        height: 0rem;
      `;
    } else {
    }
  }};
`;

const StatusBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 12rem;

  position: relative;
  /* width: 83rem; */
  height: 24rem;

  background: #e2e9ff;
  border-radius: 999rem;

  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 12rem;
  line-height: 170%;
  text-align: center;
  letter-spacing: -0.03em;

  color: #102587;
`;
const InBlock = styled.div`
  display: block;
  justify-content: flex-start;
  height: 100%;
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--White);
  border-radius: 12rem;
  box-shadow: var(--Elevation_001);
`;
export default Dashboard;
