import { onHideToast, onShowToast } from 'features/common/commonSlice';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useToast(timer = 5000) {
  // const isActive = useSelector(state => state.common.isToast);
  const dispatch = useDispatch();
  const toastRef = useRef();

  const onClick = (e) => {
    const { target } = e;
    const { current } = toastRef;
    if (current && !current.contains(target)) {
      dispatch(onHideToast());
    }
  };

  const onCancel = () => {
    onClose();
  };
  const onClose = () => dispatch(onHideToast());

  useEffect(() => {
    window.addEventListener('click', onClick, true);
    return () => {
      window.removeEventListener('click', onClick, true);
    };
  }, []);
  return {
    onClose,
    onCancel,
    toastRef,
    onClick,
  };
}

export default useToast;
