import moment from 'moment';

/*date 관련 함수 */
export const setDate = (date) => {
  const set = moment(date).format('YYYY-MM-DD');
  return set;
};

export const viewDay = (date) =>
  date === undefined || date ? moment(date).format('YYYY.MM.DD') : '-';
export const viewMMDD = (date) => moment(date).format('MM.DD');
export const viewTime = (date) => {
  const setDate = moment(date).format('YYYY.MM.DDTHH:mm');
  return moment(setDate, 'YYYY.MM.DDTHH:mm').format('HH:mm');
};
export const setTime = (date) => moment(date).format('HH:mm');

export const viewDate = (date) => {
  const setDate = moment(date).format('YYYY.MM.DDTHH:mm');
  return moment(setDate, 'YYYY.MM.DDTHH:mm').format('YYYY.MM.DD HH:mm');
};
export const viewDateTime = (date) => {
  const setDate = moment(date).format('YYYY.MM.DDTHH:mm:ss');
  return moment(setDate, 'YYYY.MM.DDTHH:mm:ss').format('YYYY.MM.DD HH:mm:ss');
};
export const settingDate = (date) => moment(date).format('YYYY-MM-DDTHH:mm');

export const formatDate = (date) => {
  date = new Date(date);
  const year = date?.getFullYear();
  let month = date?.getMonth() + 1;
  let day = date?.getDate();
  let hour = date?.getHours();
  let minute = date?.getMinutes();
  let second = date?.getSeconds();
  const padSet = (n) => String(n).padStart(2, '0');
  month = padSet(month);
  day = padSet(day);
  hour = padSet(hour);
  minute = padSet(minute);
  second = padSet(second);

  return {
    completeDate: `${year}.${month}.${day}`,
    completeMonth: `${year}.${month}`,
    month: `${month}월`,
    yearMonth: `${year}.${month}`,
    yearOnly: `${year}`,
    dateTimeEST: `${year}.${month}.${day}${hour}${minute}`,
    dateTime: `${hour}:${minute}`,
    yearMonthKo: `${year}년 ${month}월`,
    yearOnlyKo: `${year}년`,
  };
};

export const dateRange = (start, end) => {
  const startDate = formatDate(start);
  const endDate = formatDate(end);

  return {
    completeDate: `${startDate.completeDate} ~ ${endDate.completeDate}`,
    time: `${startDate.completeDate} ~ ${endDate.completeDate} ${startDate.dateTime} ~ ${endDate.dateTime}`,
    withTime: `${startDate.completeDate} ${startDate.dateTime} ~ ${endDate.completeDate} ${endDate.dateTime}`,
  };
};
