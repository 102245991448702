import { TextSmall, TextSucceed, TextWarning } from 'Common/Utils/util';

const TrafficSizeModule = ({ size }) => {
  return (
    <TextSmall>
      {size > 1024 * 1024 * 1024 ? (
        <>
          <TextSucceed>{(size / 1024 / 1024 / 1024).toFixed(1)} </TextSucceed>
          <TextWarning>GB</TextWarning>
        </>
      ) : size > 1024 * 1024 ? (
        <>
          <TextSucceed>{(size / 1024 / 1024).toFixed(1)} </TextSucceed>
          MB
        </>
      ) : size > 1024 ? (
        <>
          <TextSucceed>{(size / 1024).toFixed(1)} </TextSucceed>
          KB
        </>
      ) : (
        <>
          <TextSucceed>{size} </TextSucceed>B
        </>
      )}
    </TextSmall>
  );
};

export default TrafficSizeModule;
