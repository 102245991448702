import React, { useEffect, useState } from 'react';
import 'App.css';
import Layout from 'Components/UI/organisms/Layout';
import Header from 'Components/UI/organisms/Header';
import LeftMenu from 'Page/LeftMenu/LeftMenu';
import ToastComponent from 'Common/UI/molecules/Toast/ToastComponent';
import useToast from 'Common/Hooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import { onShowToast, setLoginInfo } from 'features/common/commonSlice';
import Login from 'Page/Login';
import { requestLoadUserLoginCheck } from 'Common/Service/user';
import { getCookie, removeCookie } from 'Common/Utils';
import { token_name } from 'Common/Env';
import { Route, Routes, useLocation } from 'react-router-dom';
import MdAdd from 'Page/Dev/MdAdd';
import Manage from 'Page/Manage';
import User from 'Page/User';
import Product from 'Page/Product';
import NaverId from 'Page/NaverId';
import Dashboard from 'Page/Dashboard';
import Analytics from 'Page/Analytics';
import Payment from 'Page/Payment';
import AnalyticsVmwareShopLinkClickLogs from 'Page/Analytics/Page/AnalyticsVmwareShopLinkClickLogs';
import UserList from 'Page/User/UserList';
import StoreAdd from 'Page/Dev/StoreAdd';
import TestSlot from 'MallInfo';
import SlotRankGraph from 'SlotRankGraph';
import Work from 'Page/Work/Work';
import TestTable from 'TestTable';
import ProductAdd from 'Page/Product/ProductAdd';
import WorkVmwareSetup from 'Page/Work/WorkVmwareSetup';
import WorkVmwareCalcHourDataInfos from 'Page/Work/WorkVmwareCalcHourDataInfos';
import WorkVmwareCurrentCmdInfos from 'Page/Work/WorkVmwareCurrentCmdInfos';
import WorkProxyControlInfos from 'Page/Work/WorkProxyControlInfos';
import WorkNewPattern from 'Page/Work/WorkNewPattern';
import WorkNewPatternNeedAlbaInfos from 'Page/Work/WorkNewPatternNeedAlbaInfos';
import WorkNewPatternWorkerState from 'Page/Work/WorkNewPatternWorkerState';
import WorkNewPatternNeedAlbaKeywordInfos from 'Page/Work/WorkNewPatternNeedAlbaKeywordInfos';
import WorkNewPatternNeedAlbaSlotInfos from 'Page/Work/WorkNewPatternNeedAlbaSlotInfos';
import WorkNewPatternVmwareState from 'Page/Work/WorkNewPatternVmwareState';
import WorkNewPatternActiveState from 'Page/Work/WorkNewPatternActiveState';
import WorkNewPatternActiveWorkerState from 'Page/Work/WorkNewPatternActiveWorkerState';
import WorkNewPatternProductInfoKeywordStatus from 'Page/Work/WorkNewPatternProductInfoKeywordStatus';
import WorkNewPatternActiveDoneFinishDoneState from 'Page/Work/WorkNewPatternActiveDoneFinishDoneState';
import WorkNewPatternAllProductInfoKeywordStatus from 'Page/Work/WorkNewPatternAllProductInfoKeywordStatus';
function Iphone() {
  const [a, setA] = useState();
  useEffect(() => {
    setA(navigator.maxTouchPoints);
  }, []);
  return <>{a}</>;
}
function App() {
  const toast = useToast();
  const [loginChk, setLoginChk] = useState(0);
  const { isToast, toastData, loginInfo } = useSelector((state) => {
    if (!state?.loginInfo) {
      return { ...state.common, loginInfo: {} };
    }
    return state.common;
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const userLoginCheck = async () => {
    const token = getCookie(token_name);
    const login_chk = true;

    if (login_chk) {
      if (loginInfo?.user_name && loginInfo?.token && loginInfo?.token === token) {
        const result = await requestLoadUserLoginCheck({});
        const requestUuserInfo = result?.login_info;
        const message = result?.message;
        if (message) {
          dispatch(
            onShowToast({
              status: message?.status,
              message: message?.message,
              cancelText: '',
              onCancel: false,
            }),
          );
        }
        if (requestUuserInfo) {
          const loginInfo = requestUuserInfo;
          dispatch(setLoginInfo({ ...loginInfo, token }));
          setLoginChk(1);
        } else {
          removeCookie(token_name);
          dispatch(setLoginInfo({}));
          setLoginChk(-1);
        }
      } else {
        const result = await requestLoadUserLoginCheck({});
        const requestUuserInfo = result?.login_info;
        const message = result?.message;
        if (message) {
          dispatch(
            onShowToast({
              status: message?.status,
              message: message?.message,
              cancelText: '',
              onCancel: false,
            }),
          );
        }
        if (requestUuserInfo) {
          const loginInfo = requestUuserInfo;
          dispatch(setLoginInfo({ ...loginInfo, token }));
          setLoginChk(1);
        } else {
          removeCookie(token_name);
          dispatch(setLoginInfo({}));
          setLoginChk(-1);
        }
      }
    }
  };
  useEffect(() => {
    userLoginCheck();
  }, [location.pathname]);
  useEffect(() => {
    userLoginCheck();
  }, [loginInfo.user_name]);

  if (loginChk === 0) {
    return null;
  } else if (loginChk === -1) {
    return (
      <>
        <Login />
        <ToastComponent isActive={isToast} ref={toast.toastRef} {...toast} {...toastData} />
      </>
    );
  }
  return (
    <>
      <Layout id="layout">
        <LeftMenu />
        <Header />

        <Routes>
          <Route path="/product_state" element={<WorkNewPatternAllProductInfoKeywordStatus />} />
          <Route
            path="/product_info_keyword_state"
            element={<WorkNewPatternProductInfoKeywordStatus />}
          />
          <Route path="/done_state" element={<WorkNewPatternActiveDoneFinishDoneState />} />
          <Route
            path="/active_worker_state"
            element={<WorkNewPatternActiveWorkerState worker_id="all" />}
          />
          <Route path="/active_state" element={<WorkNewPatternActiveState />} />
          <Route path="/vmware_state" element={<WorkNewPatternVmwareState />} />
          <Route path="/worker_state" element={<WorkNewPatternWorkerState />} />
          <Route path="/worker_state/:group_name" element={<WorkNewPatternWorkerState />} />
          <Route
            path="/work_state"
            element={<WorkNewPatternNeedAlbaInfos manual_keyword_insert_chk />}
          />
          <Route path="/slot_state" element={<WorkNewPatternNeedAlbaSlotInfos />} />
          <Route
            path="/keyword_state"
            element={<WorkNewPatternNeedAlbaKeywordInfos all_read_chk />}
          />
          <Route path="/new_pattern" element={<WorkNewPattern ma />} />
          <Route path="/new_pattern/*" element={<WorkNewPattern />} />
          <Route path="/cmd" element={<WorkVmwareCurrentCmdInfos />} />
          <Route path="/cmd/:workstation_name" element={<WorkVmwareCurrentCmdInfos />} />
          <Route path="/proxy_control" element={<WorkProxyControlInfos />} />
          <Route path="/vmware_setup" element={<WorkVmwareSetup />} />
          <Route path="/vmware_count" element={<WorkVmwareCalcHourDataInfos />} />
          <Route path="/vmware_count/:workstation_name" element={<WorkVmwareCalcHourDataInfos />} />
          <Route path="/table" element={<TestTable />} />
          <Route path="/store" element={<StoreAdd />} />
          <Route path="/md" element={<MdAdd />} />
          <Route path="/work/*" element={<Work />} />
          <Route path="/iphone" element={<Iphone />} />
          <Route path="/analytics/*" element={<Analytics />} />
          <Route path="/manage/*" element={<Manage />} />
          <Route path={'/partner'} element={<UserList />} />
          <Route path={'/user'} element={<UserList />} />
          <Route path={'/user/:partner_name'} element={<UserList />} />
          <Route path="/signup/*" element={<User />} />
          <Route path="/payment/*" element={<Payment />} />
          <Route path={'/logs/:product_id'} element={<AnalyticsVmwareShopLinkClickLogs />} />
          <Route path={'/product/add'} element={<ProductAdd />} />
          <Route path="/product/*" element={<Product />} />
          <Route path="/naver_id/*" element={<NaverId />} />
          <Route path="/login/*" element={<Login />} />
          {/* <Route path="/test_slot/*" element={<TestSlot />} /> */}
          <Route path="/rank/:check" element={<SlotRankGraph />} />
          <Route path="/rank" element={<SlotRankGraph />} />
          <Route path="/*" element={<Dashboard />} />
        </Routes>
      </Layout>
      <ToastComponent isActive={isToast} ref={toast.toastRef} {...toast} {...toastData} />
    </>
  );
}

export default App;
