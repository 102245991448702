import { Box, Button, InputComponent, Title } from 'Common/UI/atoms';
import { Table, Tbody, THeader } from 'Common/UI/organisms/FormTabel/styeld';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import React, { useEffect, useState } from 'react';
import useMutations from 'Common/Service/commonMutations';
import { css, styled } from 'styled-components';
import ToggleButton from 'Page/Module/CheckToggleButton';
import {
  requestLoadInfoGetApiInfo,
  requestLoadInfoSetApiInfo,
  requestLoadSetClickFinalReport,
} from 'Common/Service/common';
import { onShowToast, setInfo } from 'features/common/commonSlice';
import { useDispatch } from 'react-redux';
import { default_info_fields } from 'Common/data';
import { getNumbers, useForceUpdate } from 'Common/Utils/util';

function ManageUseTimeTable({ etcStatus, setDefaultData, defaultData }) {
  const [nowHourN, setNowHourN] = useState(new Date().getHours());
  const [nowMinN, setNowMinN] = useState(new Date().getMinutes());
  const [isShowToast, setIsShowToast] = useState(true);

  const [result, setResults] = useState({});
  const dispatch = useDispatch();

  const { requestLoadInfoGetApiInfoMutation, requestLoadAddDoScheduleMutation } =
    useMutations(setResults);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const now = new Date(); // 현재 시간
    const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000; // 현재 시간을 utc로 변환한 밀리세컨드값
    const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
    const koreaNow = new Date(utcNow + koreaTimeDiff); // utc로 변환된 값을 한국 시간으로 변환시키기 위해 9시간(밀리세컨드)를 더함
    // 위 코드에서 koreaNow 는 사용자의 시간대가 어떻게 설정되어 있더라도 한국 시간을 보여주게 된다.

    setNowHourN(koreaNow.getHours());
    setNowMinN(koreaNow.getMinutes());
    if (result?.request === 'requestLoadAddDoScheduleMutation') {
      if (result?.success && result?.result !== undefined) {
        dispatch(
          onShowToast({
            status: 'success',
            message: 'A new automatic schedule has been created',
            cancelText: '',
            onCancel: false,
          }),
        );
      }
    } else if (result?.request === 'requestLoadInfoGetApiInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        setDefaultData(result?.result);
      }
      setTimeout(() => {
        requestLoadInfoGetApiInfoMutation.mutate({ fields: default_info_fields });
      }, 2000);
    } else if (result?.request === 'requestLoadVmwareSetInitdefaultApiInfoMutation') {
      if (result?.success && result?.result === true) {
        if (isShowToast) {
          dispatch(
            onShowToast({
              status: 'success',
              message: 'The changes have been applied.',
              cancelText: '',
              onCancel: false,
            }),
          );
        }
        setIsShowToast(true);
      } else {
        dispatch(
          onShowToast({
            status: 'error',
            message: 'An error has occurred.',
            cancelText: '',
            onCancel: false,
          }),
        );
      }
    }
    setResults({});
  }, [result.request]);

  const changeFieldChange = async (setFields) => {
    const result = await requestLoadInfoSetApiInfo({
      fields: setFields,
    });
    if (result) {
      const defaultData = await requestLoadInfoGetApiInfo({ fields: default_info_fields });

      setDefaultData(defaultData);
      dispatch(setInfo(defaultData));
      dispatch(
        onShowToast({
          status: 'success',
          message: 'The changes have been applied.',
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: 'An error has occurred.',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };

  useEffect(() => {
    requestLoadInfoGetApiInfoMutation.mutate({ fields: default_info_fields });
  }, []);
  return (
    <>
      <TableComponent
        onSearch={null}
        isController={false}
        isHeaad={false}
        allCheckbox={false}
        isInterval={false}
        searchText={null}
      >
        <Box>
          <Box>
            <Title>유효클릭 허용시간</Title>
            {defaultData?.all_force_test_click_allow_chk ? (
              <>
                {/* <Button
                  size="xs"
                  onClick={() => {
                    changeFieldChange({
                      all_force_test_click_allow_chk: false,
                    });
                  }}
                >
                  <StatusText>Test [Traffic] program is running.</StatusText>
                </Button> */}
              </>
            ) : (
              <>
                {/* <Button
                  size="xs"
                  Theme="unFill"
                  onClick={() => {
                    setIsShowToast(false);

                    changeFieldChange({
                      all_force_test_click_allow_chk: true,
                    });
                    requestLoadAddDoScheduleMutation.mutate({
                      do_schedule: 'all_force_test_click_allow_chk_false',
                      min: 12,
                    });
                  }}
                >
                  <StatusText>Test [Traffic] program is not running.</StatusText>
                </Button> */}
              </>
            )}
          </Box>
        </Box>
        <Box>
          default setting
          <Box column>
            <Box>
              <ToggleButton
                active={!defaultData?.use_vmware_browser_mode_stop_chk}
                onClick={() => {
                  changeFieldChange({
                    use_vmware_browser_mode_stop_chk: !defaultData.use_vmware_browser_mode_stop_chk,
                  });
                }}
              >
                browser mode [
                {
                  defaultData?.etc_info?.worker_network_mode_counts?.find(
                    (e) => !e.worker_login_network_socket_mode_chk,
                  )?.count
                }
                ]
              </ToggleButton>
              <ToggleButton
                active={!defaultData?.use_vmware_network_mode_stop_chk}
                onClick={() => {
                  changeFieldChange({
                    use_vmware_network_mode_stop_chk: !defaultData.use_vmware_network_mode_stop_chk,
                  });
                }}
              >
                network mode [
                {
                  defaultData?.etc_info?.worker_network_mode_counts?.find(
                    (e) => e.worker_login_network_socket_mode_chk,
                  )?.count
                }
                ]
              </ToggleButton>
              <ToggleButton
                active={!defaultData?.use_vmware_korea_country_mode_stop_chk}
                onClick={() => {
                  changeFieldChange({
                    use_vmware_korea_country_mode_stop_chk:
                      !defaultData.use_vmware_korea_country_mode_stop_chk,
                  });
                }}
              >
                kor mode [
                {
                  defaultData?.etc_info?.vmware_worker_login_country_counts?.find(
                    (e) => e.worker_login_country === 'korea',
                  )?.count
                }
                ]
              </ToggleButton>
              <ToggleButton
                active={!defaultData?.use_vmware_other_country_mode_stop_chk}
                onClick={() => {
                  changeFieldChange({
                    use_vmware_other_country_mode_stop_chk:
                      !defaultData.use_vmware_other_country_mode_stop_chk,
                  });
                }}
              >
                other mode [
                {
                  defaultData?.etc_info?.vmware_worker_login_country_counts?.find(
                    (e) => e.worker_login_country !== 'korea',
                  )?.count
                }
                ]
              </ToggleButton>
            </Box>
            <Box gap={24}>
              <Box gap={10}>
                {defaultData?.etc_info?.simcard_type_counts.map((e) => {
                  return (
                    <Box>
                      {e?.phone_type} {e?.count}
                    </Box>
                  );
                })}
              </Box>
              <Box gap={10}>
                {defaultData?.etc_info?.modem_type_counts.map((e) => {
                  return (
                    <Box>
                      {e?.phone_modem_type} {e?.count}
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Box>
                vmware_idle_sec_max
                <InputComponent
                  value={defaultData?.default_vmware_idle_sec_max}
                  onChange={(e) => {
                    defaultData.default_vmware_idle_sec_max = getNumbers(e.target.value);
                    forceUpdate();
                  }}
                  size={'xs'}
                  placeholder="38282"
                />
              </Box>
              <Box>
                vmware_reboot_sec_max
                <InputComponent
                  value={defaultData?.default_vmware_reboot_sec_max}
                  onChange={(e) => {
                    defaultData.default_vmware_reboot_sec_max = getNumbers(e.target.value);
                    forceUpdate();
                  }}
                  size={'xs'}
                  placeholder="38282"
                />
              </Box>
              <Box>
                <Button
                  size="xs"
                  onClick={() => {
                    changeFieldChange({
                      default_vmware_reboot_sec_max: defaultData.default_vmware_reboot_sec_max,
                      default_vmware_idle_sec_max: defaultData.default_vmware_idle_sec_max,
                    });
                  }}
                >
                  update
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {null && (
          <Box>
            use vmware:
            <Box column>
              <Box left={10} gap={12}>
                <ToggleButton
                  active={defaultData?.use_worker_login_types
                    ?.split(':')
                    .includes('android_samsung_browser')}
                  onClick={() => {
                    const use_chk = defaultData?.use_worker_login_types
                      ?.split(':')
                      .includes('android_samsung_browser');
                    const worker_login_type = 'android_samsung_browser';
                    var use_worker_login_types = defaultData?.use_worker_login_types;
                    if (use_chk) {
                      use_worker_login_types = use_worker_login_types.replaceAll(
                        `${worker_login_type}`,
                        '',
                      );
                    } else {
                      use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                      use_worker_login_types = Array.from(
                        new Set(use_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_worker_login_types: use_worker_login_types,
                    });
                  }}
                >
                  삼성 브라우져 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'android_samsung_browser',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_worker_login_types
                    ?.split(':')
                    .includes('android_chrome_naver_real_id')}
                  onClick={() => {
                    const use_chk = defaultData?.use_worker_login_types
                      ?.split(':')
                      .includes('android_chrome_naver_real_id');
                    const worker_login_type = 'android_chrome_naver_real_id';
                    var use_worker_login_types = defaultData?.use_worker_login_types;
                    if (use_chk) {
                      use_worker_login_types = use_worker_login_types.replaceAll(
                        `${worker_login_type}`,
                        '',
                      );
                    } else {
                      use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                      use_worker_login_types = Array.from(
                        new Set(use_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_worker_login_types: use_worker_login_types,
                    });
                  }}
                >
                  안드로이드 크롬 실명 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'android_chrome_naver_real_id',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_worker_login_types
                    ?.split(':')
                    .includes('android_chrome_naver_id')}
                  onClick={() => {
                    const use_chk = defaultData?.use_worker_login_types
                      ?.split(':')
                      .includes('android_chrome_naver_id');
                    const worker_login_type = 'android_chrome_naver_id';
                    var use_worker_login_types = defaultData?.use_worker_login_types;
                    if (use_chk) {
                      use_worker_login_types = use_worker_login_types.replaceAll(
                        `${worker_login_type}`,
                        '',
                      );
                    } else {
                      use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                      use_worker_login_types = Array.from(
                        new Set(use_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_worker_login_types: use_worker_login_types,
                    });
                  }}
                >
                  안드로이드 크롬 비실명 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'android_chrome_naver_id',
                    )?.count
                  }
                  대]
                </ToggleButton>

                <ToggleButton
                  active={defaultData?.use_worker_login_types?.split(':').includes('iphone_app')}
                  onClick={() => {
                    const use_chk = defaultData?.use_worker_login_types
                      ?.split(':')
                      .includes('iphone_app');
                    const worker_login_type = 'iphone_app';
                    var use_worker_login_types = defaultData?.use_worker_login_types;
                    if (use_chk) {
                      use_worker_login_types = use_worker_login_types.replaceAll(
                        `${worker_login_type}`,
                        '',
                      );
                    } else {
                      use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                      use_worker_login_types = Array.from(
                        new Set(use_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_worker_login_types: use_worker_login_types,
                    });
                  }}
                >
                  아이폰 앱 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'iphone_app',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_worker_login_types?.split(':').includes('iphone_safari')}
                  onClick={() => {
                    const use_chk = defaultData?.use_worker_login_types
                      ?.split(':')
                      .includes('iphone_safari');
                    const worker_login_type = 'iphone_safari';
                    var use_worker_login_types = defaultData?.use_worker_login_types;
                    if (use_chk) {
                      use_worker_login_types = use_worker_login_types.replaceAll(
                        `${worker_login_type}`,
                        '',
                      );
                    } else {
                      use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                      use_worker_login_types = Array.from(
                        new Set(use_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_worker_login_types: use_worker_login_types,
                    });
                  }}
                >
                  아이폰 사파리 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'iphone_safari',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_worker_login_types?.split(':').includes('ipad_safari')}
                  onClick={() => {
                    const use_chk = defaultData?.use_worker_login_types
                      ?.split(':')
                      .includes('ipad_safari');
                    const worker_login_type = 'ipad_safari';
                    var use_worker_login_types = defaultData?.use_worker_login_types;
                    if (use_chk) {
                      use_worker_login_types = use_worker_login_types.replaceAll(
                        `${worker_login_type}`,
                        '',
                      );
                    } else {
                      use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                      use_worker_login_types = Array.from(
                        new Set(use_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_worker_login_types: use_worker_login_types,
                    });
                  }}
                >
                  아아패드 사파리 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'ipad_safari',
                    )?.count
                  }
                  대]
                </ToggleButton>
                {/*               
              <ToggleButton
                active={defaultData?.use_worker_login_types?.split(':').includes('android_app')}
                onClick={() => {
                  const use_chk = defaultData?.use_worker_login_types
                    ?.split(':')
                    .includes('android_app');
                  const worker_login_type = 'android_app';
                  var use_worker_login_types = defaultData?.use_worker_login_types;
                  if (use_chk) {
                    use_worker_login_types = use_worker_login_types.replaceAll(
                      `${worker_login_type}`,
                      '',
                    );
                  } else {
                    use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                    use_worker_login_types = Array.from(
                      new Set(use_worker_login_types.split(':')),
                    ).join(':');
                  }
                  changeFieldChange({
                    use_worker_login_types: use_worker_login_types,
                  });
                }}
              >
                안드로이드 앱 [
                {
                  defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                    (e) => e.worker_login_type === 'android_app',
                  )?.count
                }
                대]
              </ToggleButton>
             
              <ToggleButton
                active={defaultData?.use_worker_login_types?.split(':').includes('ipad_app')}
                onClick={() => {
                  const use_chk = defaultData?.use_worker_login_types
                    ?.split(':')
                    .includes('ipad_app');
                  const worker_login_type = 'ipad_app';
                  var use_worker_login_types = defaultData?.use_worker_login_types;
                  if (use_chk) {
                    use_worker_login_types = use_worker_login_types.replaceAll(
                      `${worker_login_type}`,
                      '',
                    );
                  } else {
                    use_worker_login_types = `${use_worker_login_types}:${worker_login_type}`;
                    use_worker_login_types = Array.from(
                      new Set(use_worker_login_types.split(':')),
                    ).join(':');
                  }
                  changeFieldChange({
                    use_worker_login_types: use_worker_login_types,
                  });
                }}
              >
                아아패드 앱 [
                {
                  defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                    (e) => e.worker_login_type === 'ipad_app',
                  )?.count
                }
                대]
              </ToggleButton> */}
              </Box>
              <Box gap={40}>
                <Box left={10} gap={12}>
                  <ToggleButton
                    active={defaultData?.use_worker_login_countries?.split(':').includes('korea')}
                    onClick={() => {
                      const use_chk = defaultData?.use_worker_login_countries
                        ?.split(':')
                        .includes('korea');
                      const worker_login_country = 'korea';
                      var use_worker_login_countries = defaultData?.use_worker_login_countries;
                      if (use_chk) {
                        use_worker_login_countries = use_worker_login_countries.replaceAll(
                          `${worker_login_country}`,
                          '',
                        );
                      } else {
                        use_worker_login_countries = `${use_worker_login_countries}:${worker_login_country}`;
                        use_worker_login_countries = Array.from(
                          new Set(use_worker_login_countries.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_worker_login_countries: use_worker_login_countries,
                      });
                    }}
                  >
                    한국 [
                    {
                      defaultData?.etc_info?.vmware_worker_login_country_counts?.find(
                        (e) => e.worker_login_country === 'korea',
                      )?.count
                    }
                    대]
                  </ToggleButton>
                  <ToggleButton
                    active={defaultData?.use_worker_login_countries
                      ?.split(':')
                      .includes('thailand')}
                    onClick={() => {
                      const use_chk = defaultData?.use_worker_login_countries
                        ?.split(':')
                        .includes('thailand');
                      const worker_login_country = 'thailand';
                      var use_worker_login_countries = defaultData?.use_worker_login_countries;
                      if (use_chk) {
                        use_worker_login_countries = use_worker_login_countries.replaceAll(
                          `${worker_login_country}`,
                          '',
                        );
                      } else {
                        use_worker_login_countries = `${use_worker_login_countries}:${worker_login_country}`;
                        use_worker_login_countries = Array.from(
                          new Set(use_worker_login_countries.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_worker_login_countries: use_worker_login_countries,
                      });
                    }}
                  >
                    태국 [
                    {
                      defaultData?.etc_info?.vmware_worker_login_country_counts?.find(
                        (e) => e.worker_login_country === 'thailand',
                      )?.count
                    }
                    대]
                  </ToggleButton>
                </Box>
                <Box>
                  <ToggleButton
                    active={defaultData?.use_worker_network_types?.split(':').includes('mobile')}
                    onClick={() => {
                      const use_chk = defaultData?.use_worker_network_types
                        ?.split(':')
                        .includes('mobile');
                      const worker_network_type = 'mobile';
                      var use_worker_network_types = defaultData?.use_worker_network_types;
                      if (use_chk) {
                        use_worker_network_types = use_worker_network_types.replaceAll(
                          `${worker_network_type}`,
                          '',
                        );
                      } else {
                        use_worker_network_types = `${use_worker_network_types}:${worker_network_type}`;
                        use_worker_network_types = Array.from(
                          new Set(use_worker_network_types.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_worker_network_types: use_worker_network_types,
                      });
                    }}
                  >
                    모바일 회선 [
                    {
                      defaultData?.etc_info?.vmware_worker_network_type_lte_counts?.find(
                        (e) => e.worker_network_type_mobile_chk === true,
                      )?.count
                    }
                    대]
                  </ToggleButton>
                  <ToggleButton
                    active={defaultData?.use_worker_network_types?.split(':').includes('lan')}
                    onClick={() => {
                      const use_chk = defaultData?.use_worker_network_types
                        ?.split(':')
                        .includes('lan');
                      const worker_network_type = 'lan';
                      var use_worker_network_types = defaultData?.use_worker_network_types;
                      if (use_chk) {
                        use_worker_network_types = use_worker_network_types.replaceAll(
                          `${worker_network_type}`,
                          '',
                        );
                      } else {
                        use_worker_network_types = `${use_worker_network_types}:${worker_network_type}`;
                        use_worker_network_types = Array.from(
                          new Set(use_worker_network_types.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_worker_network_types: use_worker_network_types,
                      });
                    }}
                  >
                    유선 회선 [
                    {
                      defaultData?.etc_info?.vmware_worker_network_type_lte_counts?.find(
                        (e) => e.worker_network_type_mobile_chk === false,
                      )?.count
                    }
                    대]
                  </ToggleButton>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {null && (
          <Box>
            use shop click:
            <Box column>
              <Box left={10} gap={12}>
                <ToggleButton
                  active={defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('android_samsung_browser')}
                  onClick={() => {
                    const use_chk = defaultData?.use_shop_click_worker_login_types
                      ?.split(':')
                      .includes('android_samsung_browser');
                    const worker_login_type = 'android_samsung_browser';
                    var use_shop_click_worker_login_types =
                      defaultData?.use_shop_click_worker_login_types;
                    if (use_chk) {
                      use_shop_click_worker_login_types =
                        use_shop_click_worker_login_types.replaceAll(`${worker_login_type}`, '');
                    } else {
                      use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                      use_shop_click_worker_login_types = Array.from(
                        new Set(use_shop_click_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                    });
                  }}
                >
                  삼성 브라우져 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'android_samsung_browser',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('android_chrome_naver_real_id')}
                  onClick={() => {
                    const use_chk = defaultData?.use_shop_click_worker_login_types
                      ?.split(':')
                      .includes('android_chrome_naver_real_id');
                    const worker_login_type = 'android_chrome_naver_real_id';
                    var use_shop_click_worker_login_types =
                      defaultData?.use_shop_click_worker_login_types;
                    if (use_chk) {
                      use_shop_click_worker_login_types =
                        use_shop_click_worker_login_types.replaceAll(`${worker_login_type}`, '');
                    } else {
                      use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                      use_shop_click_worker_login_types = Array.from(
                        new Set(use_shop_click_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                    });
                  }}
                >
                  안드로이드 크롬 실명 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'android_chrome_naver_real_id',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('android_chrome_naver_id')}
                  onClick={() => {
                    const use_chk = defaultData?.use_shop_click_worker_login_types
                      ?.split(':')
                      .includes('android_chrome_naver_id');
                    const worker_login_type = 'android_chrome_naver_id';
                    var use_shop_click_worker_login_types =
                      defaultData?.use_shop_click_worker_login_types;
                    if (use_chk) {
                      use_shop_click_worker_login_types =
                        use_shop_click_worker_login_types.replaceAll(`${worker_login_type}`, '');
                    } else {
                      use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                      use_shop_click_worker_login_types = Array.from(
                        new Set(use_shop_click_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                    });
                  }}
                >
                  안드로이드 크롬 비실명 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'android_chrome_naver_id',
                    )?.count
                  }
                  대]
                </ToggleButton>

                <ToggleButton
                  active={defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('iphone_app')}
                  onClick={() => {
                    const use_chk = defaultData?.use_shop_click_worker_login_types
                      ?.split(':')
                      .includes('iphone_app');
                    const worker_login_type = 'iphone_app';
                    var use_shop_click_worker_login_types =
                      defaultData?.use_shop_click_worker_login_types;
                    if (use_chk) {
                      use_shop_click_worker_login_types =
                        use_shop_click_worker_login_types.replaceAll(`${worker_login_type}`, '');
                    } else {
                      use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                      use_shop_click_worker_login_types = Array.from(
                        new Set(use_shop_click_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                    });
                  }}
                >
                  아이폰 앱 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'iphone_app',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('iphone_safari')}
                  onClick={() => {
                    const use_chk = defaultData?.use_shop_click_worker_login_types
                      ?.split(':')
                      .includes('iphone_safari');
                    const worker_login_type = 'iphone_safari';
                    var use_shop_click_worker_login_types =
                      defaultData?.use_shop_click_worker_login_types;
                    if (use_chk) {
                      use_shop_click_worker_login_types =
                        use_shop_click_worker_login_types.replaceAll(`${worker_login_type}`, '');
                    } else {
                      use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                      use_shop_click_worker_login_types = Array.from(
                        new Set(use_shop_click_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                    });
                  }}
                >
                  아이폰 사파리 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'iphone_safari',
                    )?.count
                  }
                  대]
                </ToggleButton>
                <ToggleButton
                  active={defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('ipad_safari')}
                  onClick={() => {
                    const use_chk = defaultData?.use_shop_click_worker_login_types
                      ?.split(':')
                      .includes('ipad_safari');
                    const worker_login_type = 'ipad_safari';
                    var use_shop_click_worker_login_types =
                      defaultData?.use_shop_click_worker_login_types;
                    if (use_chk) {
                      use_shop_click_worker_login_types =
                        use_shop_click_worker_login_types.replaceAll(`${worker_login_type}`, '');
                    } else {
                      use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                      use_shop_click_worker_login_types = Array.from(
                        new Set(use_shop_click_worker_login_types.split(':')),
                      ).join(':');
                    }
                    changeFieldChange({
                      use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                    });
                  }}
                >
                  아아패드 사파리 [
                  {
                    defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                      (e) => e.worker_login_type === 'ipad_safari',
                    )?.count
                  }
                  대]
                </ToggleButton>
                {/*               
              <ToggleButton
                active={defaultData?.use_shop_click_worker_login_types?.split(':').includes('android_app')}
                onClick={() => {
                  const use_chk = defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('android_app');
                  const worker_login_type = 'android_app';
                  var use_shop_click_worker_login_types = defaultData?.use_shop_click_worker_login_types;
                  if (use_chk) {
                    use_shop_click_worker_login_types = use_shop_click_worker_login_types.replaceAll(
                      `${worker_login_type}`,
                      '',
                    );
                  } else {
                    use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                    use_shop_click_worker_login_types = Array.from(
                      new Set(use_shop_click_worker_login_types.split(':')),
                    ).join(':');
                  }
                  changeFieldChange({
                    use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                  });
                }}
              >
                안드로이드 앱 [
                {
                  defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                    (e) => e.worker_login_type === 'android_app',
                  )?.count
                }
                대]
              </ToggleButton>
             
              <ToggleButton
                active={defaultData?.use_shop_click_worker_login_types?.split(':').includes('ipad_app')}
                onClick={() => {
                  const use_chk = defaultData?.use_shop_click_worker_login_types
                    ?.split(':')
                    .includes('ipad_app');
                  const worker_login_type = 'ipad_app';
                  var use_shop_click_worker_login_types = defaultData?.use_shop_click_worker_login_types;
                  if (use_chk) {
                    use_shop_click_worker_login_types = use_shop_click_worker_login_types.replaceAll(
                      `${worker_login_type}`,
                      '',
                    );
                  } else {
                    use_shop_click_worker_login_types = `${use_shop_click_worker_login_types}:${worker_login_type}`;
                    use_shop_click_worker_login_types = Array.from(
                      new Set(use_shop_click_worker_login_types.split(':')),
                    ).join(':');
                  }
                  changeFieldChange({
                    use_shop_click_worker_login_types: use_shop_click_worker_login_types,
                  });
                }}
              >
                아아패드 앱 [
                {
                  defaultData?.etc_info?.vmware_worker_login_type_counts?.find(
                    (e) => e.worker_login_type === 'ipad_app',
                  )?.count
                }
                대]
              </ToggleButton> */}
              </Box>
              <Box gap={40}>
                <Box left={10} gap={12}>
                  <ToggleButton
                    active={defaultData?.use_shop_click_worker_login_countries
                      ?.split(':')
                      .includes('korea')}
                    onClick={() => {
                      const use_chk = defaultData?.use_shop_click_worker_login_countries
                        ?.split(':')
                        .includes('korea');
                      const worker_login_country = 'korea';
                      var use_shop_click_worker_login_countries =
                        defaultData?.use_shop_click_worker_login_countries;
                      if (use_chk) {
                        use_shop_click_worker_login_countries =
                          use_shop_click_worker_login_countries.replaceAll(
                            `${worker_login_country}`,
                            '',
                          );
                      } else {
                        use_shop_click_worker_login_countries = `${use_shop_click_worker_login_countries}:${worker_login_country}`;
                        use_shop_click_worker_login_countries = Array.from(
                          new Set(use_shop_click_worker_login_countries.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_shop_click_worker_login_countries:
                          use_shop_click_worker_login_countries,
                      });
                    }}
                  >
                    한국 [
                    {
                      defaultData?.etc_info?.vmware_worker_login_country_counts?.find(
                        (e) => e.worker_login_country === 'korea',
                      )?.count
                    }
                    대]
                  </ToggleButton>
                  <ToggleButton
                    active={defaultData?.use_shop_click_worker_login_countries
                      ?.split(':')
                      .includes('thailand')}
                    onClick={() => {
                      const use_chk = defaultData?.use_shop_click_worker_login_countries
                        ?.split(':')
                        .includes('thailand');
                      const worker_login_country = 'thailand';
                      var use_shop_click_worker_login_countries =
                        defaultData?.use_shop_click_worker_login_countries;
                      if (use_chk) {
                        use_shop_click_worker_login_countries =
                          use_shop_click_worker_login_countries.replaceAll(
                            `${worker_login_country}`,
                            '',
                          );
                      } else {
                        use_shop_click_worker_login_countries = `${use_shop_click_worker_login_countries}:${worker_login_country}`;
                        use_shop_click_worker_login_countries = Array.from(
                          new Set(use_shop_click_worker_login_countries.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_shop_click_worker_login_countries:
                          use_shop_click_worker_login_countries,
                      });
                    }}
                  >
                    태국 [
                    {
                      defaultData?.etc_info?.vmware_worker_login_country_counts?.find(
                        (e) => e.worker_login_country === 'thailand',
                      )?.count
                    }
                    대]
                  </ToggleButton>
                </Box>
                <Box>
                  <ToggleButton
                    active={defaultData?.use_shop_click_worker_network_types
                      ?.split(':')
                      .includes('mobile')}
                    onClick={() => {
                      const use_chk = defaultData?.use_shop_click_worker_network_types
                        ?.split(':')
                        .includes('mobile');
                      const worker_network_type = 'mobile';
                      var use_shop_click_worker_network_types =
                        defaultData?.use_shop_click_worker_network_types;
                      if (use_chk) {
                        use_shop_click_worker_network_types =
                          use_shop_click_worker_network_types.replaceAll(
                            `${worker_network_type}`,
                            '',
                          );
                      } else {
                        use_shop_click_worker_network_types = `${use_shop_click_worker_network_types}:${worker_network_type}`;
                        use_shop_click_worker_network_types = Array.from(
                          new Set(use_shop_click_worker_network_types.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_shop_click_worker_network_types: use_shop_click_worker_network_types,
                      });
                    }}
                  >
                    모바일 회선 [
                    {
                      defaultData?.etc_info?.vmware_worker_network_type_lte_counts?.find(
                        (e) => e.worker_network_type_mobile_chk === true,
                      )?.count
                    }
                    대]
                  </ToggleButton>
                  <ToggleButton
                    active={defaultData?.use_shop_click_worker_network_types
                      ?.split(':')
                      .includes('lan')}
                    onClick={() => {
                      const use_chk = defaultData?.use_shop_click_worker_network_types
                        ?.split(':')
                        .includes('lan');
                      const worker_network_type = 'lan';
                      var use_shop_click_worker_network_types =
                        defaultData?.use_shop_click_worker_network_types;
                      if (use_chk) {
                        use_shop_click_worker_network_types =
                          use_shop_click_worker_network_types.replaceAll(
                            `${worker_network_type}`,
                            '',
                          );
                      } else {
                        use_shop_click_worker_network_types = `${use_shop_click_worker_network_types}:${worker_network_type}`;
                        use_shop_click_worker_network_types = Array.from(
                          new Set(use_shop_click_worker_network_types.split(':')),
                        ).join(':');
                      }
                      changeFieldChange({
                        use_shop_click_worker_network_types: use_shop_click_worker_network_types,
                      });
                    }}
                  >
                    유선 회선 [
                    {
                      defaultData?.etc_info?.vmware_worker_network_type_lte_counts?.find(
                        (e) => e.worker_network_type_mobile_chk === false,
                      )?.count
                    }
                    대]
                  </ToggleButton>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {etcStatus !== undefined && (
          <>
            {etcStatus?.schedules?.map((e, e_key) => {
              return (
                <Box gap={10} key={e_key} column>
                  <Box gap={10}>
                    <Box>
                      <StatusText>
                        {e?.do_schedule === 'all_force_test_click_allow_chk_start'
                          ? 'The test [Traffic] will be started in '
                          : e?.do_schedule === 'all_force_test_click_allow_chk_false'
                          ? 'The test [Traffic] will be stopped in '
                          : e?.do_schedule}
                      </StatusText>
                    </Box>
                    <Box>
                      <StatusText>
                        <span>{`${-e.ago_min}`}</span> minutes.
                      </StatusText>
                    </Box>
                    <Box>
                      <StatusText>[Scheduled time is {`${e.datetime}`}]</StatusText>
                    </Box>
                  </Box>
                </Box>
              );
            })}
            <Box column>
              {etcStatus?.test_click_logs?.map((i) => {
                return (
                  <Box>
                    <SuccessCheckBox is_succeed={i.is_succeed}>
                      <StatusText>
                        [{i.datetime.slice(11, 16)}]{' '}
                        <small>
                          [{i.click_mode}({i.click_mode_status})] {i.ip_country} {i.vmware_name}{' '}
                          {i.worker_login_naver_id_type}[{i.naver_id}]-
                          {i.worker_id}
                        </small>
                      </StatusText>
                      <Box>
                        <StatusText>
                          <span>
                            {i.product_id} -{i.is_succeed ? 'ok' : 'fail'}{' '}
                            {i.search_keyword?.replaceAll(' ', '')}{' '}
                          </span>
                          - ({i.mall_name}) {i.worker_login_type}
                        </StatusText>
                      </Box>
                      <Box left={50}>
                        {i.is_succeed ? (
                          <>
                            {i.final_report_status ? (
                              <>
                                <StatusText>
                                  {i.final_report_status === 'ok' ? (
                                    <small>
                                      {i.final_report_user_name === 'auto'
                                        ? `[${i.final_report_user_name}] `
                                        : null}
                                      {i.final_report_status}
                                    </small>
                                  ) : (
                                    <span>{i.final_report_status}</span>
                                  )}
                                  {'  '}[{i.check_time}]
                                </StatusText>
                                <Button
                                  onClick={async () => {
                                    await requestLoadSetClickFinalReport({
                                      id: i.id,
                                      status: null,
                                    });
                                    i.final_report_status = null;
                                    forceUpdate();
                                  }}
                                  Theme={'unFill'}
                                  size={'xxs'}
                                >
                                  cancel
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={async () => {
                                    await requestLoadSetClickFinalReport({
                                      id: i.id,
                                      status: 'ok',
                                    });
                                    i.final_report_status = 'ok';
                                    forceUpdate();
                                  }}
                                  size={'xxs'}
                                >
                                  ok
                                </Button>
                                <Button
                                  onClick={async () => {
                                    await requestLoadSetClickFinalReport({
                                      id: i.id,
                                      status: 'fail',
                                    });
                                    i.final_report_status = 'fail';
                                    forceUpdate();
                                  }}
                                  Theme={'line'}
                                  size={'xxs'}
                                >
                                  fail
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </SuccessCheckBox>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        <Table>
          <colgroup>
            <col style={{ width: 'auto' }} />
            {Array.from(Array(24)).map((_, i) => (
              <col style={{ width: 'auto' }} />
            ))}
          </colgroup>
          <THeader>
            <tr>
              <th rowSpan={'1'}>
                <StatusText>Allow Time</StatusText>
              </th>
              {Array.from(Array(24)).map((_, i) => (
                <th rowSpan={'1'}>
                  <StatusText active={nowHourN === i}>{i}시</StatusText>
                </th>
              ))}
            </tr>
          </THeader>
          <Tbody>
            <React.Fragment>
              <tr>
                <td rowSpan={'1'}>
                  <StatusText active={nowMinN < 30}>00~29</StatusText>
                </td>
                {Array.from(Array(24)).map((_, hour) => {
                  const name = `default_click_active_time_hour${hour}_0`;

                  return (
                    <td rowSpan={'1'} key={hour}>
                      <StatusText active={nowHourN === hour && nowMinN < 30}>
                        <ToggleButton
                          active={defaultData?.[name]}
                          onClick={() => {
                            changeFieldChange({
                              [name]: !defaultData?.[name],
                            });
                          }}
                        />
                      </StatusText>
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td rowSpan={'1'}>
                  <StatusText active={nowMinN >= 30}>30~59</StatusText>
                </td>
                {Array.from(Array(24)).map((_, hour) => {
                  const name = `default_click_active_time_hour${hour}_30`;
                  return (
                    <td rowSpan={'1'} key={hour}>
                      <StatusText active={nowHourN === hour && nowMinN >= 30}>
                        <ToggleButton
                          active={defaultData?.[name]}
                          onClick={() => {
                            changeFieldChange({
                              [name]: !defaultData?.[name],
                            });
                          }}
                        />
                      </StatusText>
                    </td>
                  );
                })}
              </tr>
            </React.Fragment>
          </Tbody>
        </Table>
      </TableComponent>
    </>
  );
}
const SuccessCheckBox = styled.div`
  display: flex;
  ${(props) => {
    const { is_succeed } = props;
    if (is_succeed) return;
    return css`
      background-color: #d0d0d0;
    `;
  }}
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return `
      color: #fff;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 
      background-color: #bc340b;
          
      `;
  }}
`;
export default ManageUseTimeTable;
