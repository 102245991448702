import {
  requestLoadWorkRefilterUrlTypeGetInfos,
  requestLoadWorkResultLogsGetInfos,
} from 'Common/Service/common';
import useMutations from 'Common/Service/userMutations';
import { Button, DropBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { currencyComma, getAlphaAndNumber, get_percent } from 'Common/Utils';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  debounce,
  getKorDate,
  get_uuid,
  now_str,
  reload_second,
  today_str,
} from 'Common/Utils/util';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import WorkResultLogsDate from './WorkResultLogsDate';
import WorkResultLogsTrafficDate from './WorkResultLogsTrafficDate';
import WorkResultLogsErrorLastErrorStatus from './WorkResultLogsErrorLastErrorStatus';
import WorkResultLogsErrorLastWhich from './WorkResultLogsErrorLastWhich';
import TrafficSizeModule from './Module/TrafficSizeModule';

const WorkResultLogs = () => {
  const [workDateList, setWorkDateList] = useState([]);
  const [checkDate, setCheckDate] = useState(today_str(getKorDate()));
  const [uuid, setUuid] = useState();

  const [today, setToday] = useState(today_str(getKorDate()));
  const [subMenu, setSubMenu] = useState('last_which');

  const { loginInfo } = useSelector((state) => state.common);
  const [count, setCount] = useState(reload_second);

  // const dispatch = useDispatch();
  const { url_type } = useParams();
  const getWorkDateList = async () => {
    const infos = await requestLoadWorkResultLogsGetInfos();
    setWorkDateList(infos?.work_date_list);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        setToday(today_str(getKorDate()));
        if (ct < 0) {
          getWorkDateList();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkDateList();
  }, [url_type]);

  return (
    <>
      {/* <Wrapper> */}
      <Grid column={['300rem', '700rem', '1fr']} gap={32} valign="top" height="1000">
        {/* <Box column> */}
        <Box column gap={4}>
          <Box>
            date logs <TextSmall>{count}</TextSmall>
          </Box>

          {workDateList?.map((e, index) => {
            const s_per = get_percent(e.succeed_count, e.count);
            return (
              <ResultLogBox
                active={today == e.date}
                selected={e.date == checkDate}
                key={index}
                onClick={() => {
                  setUuid(get_uuid());
                  setCheckDate(e.date);
                  //   setUrlTypeLogId(e.id);
                }}
              >
                <Box valign="top" width="100%" gap={12}>
                  <Box>{e.date}</Box>
                  {/* <Box>{e.fail_count}</Box> */}
                  <Box>{currencyComma(e.count)}</Box>
                  <Box>
                    <TextSucceed>{s_per}</TextSucceed> %
                  </Box>
                  <Box>
                    <TrafficSizeModule
                      size={e.url_access_request_size + e.url_access_response_size}
                    />
                  </Box>
                  {/* <Box>{e.aceess_traffic_bytes / e.count}</Box> */}
                  {/* <Box>2_{e.access_using_sec / e.count}</Box> */}
                  {/* <Box>3_{e.access_real_using_sec / e.count}</Box> */}
                </Box>
              </ResultLogBox>
            );
          })}
        </Box>
        {/* </Box> */}
        {/* <Box column> */}
        <Box valign="top" column>
          <Box column gap={30}>
            {/* adsf */}
            <WorkResultLogsDate date={checkDate} uuid={uuid} />
          </Box>
        </Box>

        <Box valign="top" column>
          <Box>
            <TextButton
              Theme={subMenu === 'last_which' ? 'primary' : null}
              onClick={() => {
                setSubMenu('last_which');
                navigate('/work/result_logs/last_which');
              }}
            >
              last which
            </TextButton>
            <TextButton
              Theme={subMenu === 'last_error_status' ? 'primary' : null}
              onClick={() => {
                setSubMenu('last_error_status');
                navigate('/work/result_logs/last_error_status');
              }}
            >
              error status
            </TextButton>
            <TextButton
              Theme={subMenu === 'traffic' ? 'primary' : null}
              onClick={() => {
                setSubMenu('traffic');
                navigate('/work/result_logs/traffic');
              }}
            >
              traffic
            </TextButton>
          </Box>
          <Box column gap={30}>
            <Routes>
              <Route
                path={'/'}
                element={<WorkResultLogsErrorLastWhich date={checkDate} uuid={uuid} />}
              />
              <Route
                path={'/last_which/*'}
                element={<WorkResultLogsErrorLastWhich date={checkDate} uuid={uuid} />}
              />
              <Route
                path={'/last_error_status/*'}
                element={<WorkResultLogsErrorLastErrorStatus date={checkDate} uuid={uuid} />}
              />
              <Route
                path={'/traffic/*'}
                element={<WorkResultLogsTrafficDate date={checkDate} uuid={uuid} />}
              />
            </Routes>
          </Box>
        </Box>
        {/* </Box> */}
      </Grid>
      {/* </Wrapper> */}
    </>
  );
};

const ResultLogBox = styled.button`
  display: flex;
  flex-direction: column;
  ${(props) => {
    const { active, selected } = props;
    if (selected && active) {
      return css`
        /* color: #fff; */
        border-radius: 8rem;
        padding: 0 10rem;
        border-color: blue;
        border-width: 1rem;
        border-style: solid;
        font-weight: 500;
        background-color: #f0f0f0;
      `;
    } else if (active) {
      return css`
        /* color: #fff; */
        border-radius: 8rem;
        padding: 0 10rem;
        border-color: blue;
        border-width: 1rem;
        border-style: solid;
        font-weight: 500;
      `;
    } else if (selected) {
      return css`
        /* color: #fff; */
        background-color: #f0f0f0;
      `;
    }
  }}
`;
export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;

export default WorkResultLogs;
