import { pipeFn } from 'Common/Utils';
import { formatDate } from 'Common/Utils/date';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

function useDatepicker(initialState) {
  const setDate = (date) => (date ? new Date(date) : '');
  const dateFormat = (date) => (date ? formatDate(date).completeDate : '');
  const monthFormat = (date) => (date ? formatDate(date).completeMonth : '');
  const onChange = (date) => setState(date);
  const ref = useRef();

  const checkDate = (date) =>
    date?.start
      ? {
          start: setDate(date?.start),
          end: setDate(date?.end),
        }
      : setDate(date);

  const onChangeTime = (date) => {
    if (!date) return;
    const hour = date?.getHours();
    const minute = date?.getMinutes();
    const newDate = new Date(date.setHours(hour, minute, 0));
    setState(newDate);
  };

  const [state, setState] = useState(checkDate(initialState));
  const [isMonth, setIsMonth] = useState(false);
  const [currentMont, setCurrentMont] = useState(state);

  const onToggleMonth = () => setIsMonth(!isMonth);

  const onCurrentMont = (fn, date, type) => {
    const currentDate = moment(date);
    type ? currentDate.add(1, 'months') : currentDate.subtract(1, 'months');
    setCurrentMont(currentDate._d);
    fn();
  };

  const getRangValue = (date) => (format) => {
    const start = date?.start ? `${formatDate(date?.start)[format]} ~ ` : '';
    const end = date?.end ? formatDate(date?.end)[format] : '';

    return start + end;
  };

  const onCalendarOpen = () => {
    ref.current.style.zIndex = 1000;
  };
  const onCalendarClose = () => {
    ref.current.style.zIndex = 1;
  };

  const onClearDate = (state) => setState(state);
  const setDateFormat = pipeFn(setDate, dateFormat);
  const setMonthFormat = pipeFn(setDate, monthFormat);

  useEffect(() => {
    initialState || setState(undefined);
  }, [initialState]);

  return {
    state,
    ref,
    setDate,
    setDateFormat,
    setMonthFormat,
    onChange,
    onChangeTime,
    isMonth,
    onToggleMonth,
    currentMont,
    onCurrentMont,
    getRangValue,
    onClearDate,
    onCalendarOpen,
    onCalendarClose,
  };
}

export default useDatepicker;
