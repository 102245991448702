import useTab from 'Common/Hooks/useTab';
import { Box, Button, Grid, TextButton } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import WorkRefilterUrlType from './WorkRefilterUrlType';
import WorkBasicUrlType from './WorkBasicUrlType';
import WorkKeyword from './WorkKeyword';
import WorkProductInfo from './WorkProductInfo';
import WorkRank from './WorkRank';
import WorkGetSbth from './WorkGetSbth';
import WorkView from './WorkView';
import WorkProxy from './WorkProxy';
import WorkResultLogs from './WorkResultLogs';
import { requestLoadWorkCategorySimpleInfos } from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import WorkFinalResultLogs from './WorkFinalResultLogs';
import styled from 'styled-components';
import WorkVmwareInfos from './WorkVmwareInfos';
import WorkDateLogs from './WorkDateLogs';
import { useDispatch } from 'react-redux';
import { onShowToast } from 'features/common/commonSlice';
import { currencyComma, navigate_newtab } from 'Common/Utils';
import { SmallDashOutlined } from '@ant-design/icons';

const Work = () => {
  const [workType, setWorkType] = useState('url_type');
  const navigate = useNavigate();
  const [simpleInfos, setSimpleInfos] = useState({});
  const [count, setCount] = useState(reload_second);
  const [apiId, setApiId] = useState();
  const getCategorySimpleInfos = async () => {
    clearInterval(apiId);
    const infos = await requestLoadWorkCategorySimpleInfos();
    setSimpleInfos(infos);
    timeerStart();
  };
  const dispatch = useDispatch();

  const CopyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(
        onShowToast({
          status: 'success',
          message: `Clipboard copy succeeded. (${text})`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } catch (error) {
      dispatch(
        onShowToast({
          status: 'error',
          message: 'Clipboard copy failed.',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  const timeerStart = async () => {
    clearInterval(apiId);
    setTimeout(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getCategorySimpleInfos();
          clearInterval(apiId);
          // ct = reload_second;
          ct = reload_second;
        } else {
          timeerStart();
        }
        return ct;
      });
    }, 1000);
  };
  useEffect(() => {
    getCategorySimpleInfos();
  }, []);
  return (
    <Box top={34} column>
      <Box>
        <Box column>
          <Box gap={24}>
            <Box>
              chrome: <TextSucceed> {simpleInfos?.last_version?.chrome_version}</TextSucceed>
            </Box>
            <Box>
              iphone: <TextSucceed>{simpleInfos?.last_version?.iphone_safari_version}</TextSucceed>
            </Box>
            <Box>
              {simpleInfos?.proxy?.error_proxy_api_ip_logs.length > 0 ? (
                <>
                  {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status?.includes(
                    'errorMsg: user forbidden,userip=',
                  ) ? (
                    <>
                      <button
                        onClick={async () => {
                          {
                            var n =
                              simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status.indexOf(
                                'userip=',
                              );
                            var ip = simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status?.slice(
                              n + 7,
                            );
                            n = ip.indexOf(',');
                            ip = ip?.slice(0, n)?.trim();
                            CopyText(ip);
                          }
                        }}
                      >
                        <TextFail>
                          [
                          <TextSmall>
                            {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.vmware_name}]{' '}
                            {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.datetime?.slice(
                              10,
                              19,
                            )}
                          </TextSmall>{' '}
                          {`${simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status}`}
                        </TextFail>
                      </button>
                    </>
                  ) : simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status?.includes(
                      'please add this ip to your ip whitelist',
                    ) ? (
                    <>
                      <button
                        onClick={async () => {
                          {
                            const n =
                              simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status.indexOf(
                                'request_ip',
                              );
                            const ip = simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status
                              ?.slice(n + 11)
                              ?.trim();
                            CopyText(ip);
                          }
                        }}
                      >
                        <TextFail>
                          [
                          <TextSmall>
                            {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.vmware_name}]{' '}
                            {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.datetime?.slice(
                              10,
                              19,
                            )}
                          </TextSmall>{' '}
                          {`${simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status}`}
                        </TextFail>
                      </button>
                    </>
                  ) : (
                    <>
                      <TextFail>
                        {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.state}: [
                        <TextSmall>
                          {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.vmware_name}]{' '}
                          {simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.datetime?.slice(10, 19)}
                        </TextSmall>{' '}
                        {`${simpleInfos?.proxy?.error_proxy_api_ip_logs[0]?.status}`}
                      </TextFail>
                    </>
                  )}
                </>
              ) : null}
            </Box>
            <Box>
              <TextSmall>
                Network Connect:{' '}
                <TextSucceed>
                  {simpleInfos?.server_info?.socket_info?.all_socket_connect_ct}
                </TextSucceed>{' '}
                DB:{' '}
                <TextSucceed>
                  {simpleInfos?.server_info?.socket_info?.db_socket_connect_ct}
                </TextSucceed>{' '}
                Web:{' '}
                <TextSucceed>
                  {simpleInfos?.server_info?.socket_info?.web_socket_connect_ct}
                </TextSucceed>
              </TextSmall>
            </Box>
            <Box>
              <TextSmall>
                CPU Usage:{' '}
                {simpleInfos?.server_info?.cpu_score > 1.6 ? (
                  <>
                    <TextFail>{(simpleInfos?.server_info?.cpu_score * 100)?.toFixed(1)}</TextFail>%
                  </>
                ) : simpleInfos?.server_info?.cpu_score >= 1 ? (
                  <>
                    <TextWarning>
                      {(simpleInfos?.server_info?.cpu_score * 100)?.toFixed(1)}
                    </TextWarning>
                    %
                  </>
                ) : (
                  <>
                    <TextSucceed>
                      {(simpleInfos?.server_info?.cpu_score * 100)?.toFixed(1)}
                    </TextSucceed>
                    %
                  </>
                )}
              </TextSmall>
            </Box>
            <Box>
              <TextSmall>
                Memory: {simpleInfos?.server_info?.memory_info?.use_mem}/
                {simpleInfos?.server_info?.memory_info?.total}
                GB <TextSucceed>{simpleInfos?.server_info?.memory_info?.per}</TextSucceed>%
              </TextSmall>
            </Box>

            <Box>
              {simpleInfos?.url_type_basic_lately_required?.length > 0 ? (
                <Box>
                  <Box>
                    <TextFail>error required url type</TextFail>
                  </Box>
                  <Box column>
                    {simpleInfos?.url_type_basic_lately_required?.map((e, index) => {
                      return (
                        <Box key={index}>
                          <Box>
                            <TextSmall>{index + 1}</TextSmall>
                          </Box>
                          <Box>
                            <TextSmall>
                              <TextWarning>{e?.datetime.slice(5, 16)}</TextWarning>
                            </TextSmall>
                          </Box>
                          <Box>
                            <TextSmall>
                              [<TextFail>{e?.pure_url_type}</TextFail>]
                            </TextSmall>
                          </Box>
                          <Box>
                            <TextSmall>{e?.pure_url}</TextSmall>
                          </Box>
                          <Box></Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box>
            <Box>
              사전 준비 <TextSmall>Pre-Work Checklist</TextSmall>
            </Box>
            <Box left={100}>
              <TextButton
                onClick={() => {
                  navigate_newtab('http://log.fnn50.com/sites/log/report.html');
                }}
              >
                <TextSmall>traffic logs</TextSmall>
              </TextButton>

              <TextButton
                onClick={() => {
                  navigate_newtab('http://log.fnn50.com/sites/log/error_report.html');
                }}
              >
                <TextWarning>
                  <TextSmall>error traffic logs</TextSmall>
                </TextWarning>
              </TextButton>
            </Box>
          </Box>
          <Box gap={24}>
            {' '}
            <TextButton
              Theme={workType === 'keyword' ? 'primary' : null}
              onClick={() => {
                setWorkType('keyword');
                navigate('/work/keyword');
              }}
              size={30}
            >
              {' '}
              [2] keyword 수집 (
              {simpleInfos?.get_keyword?.per >= 100 ? (
                <>
                  <TextSucceed>
                    {simpleInfos?.get_keyword?.per}% {simpleInfos?.get_keyword?.done_count}
                  </TextSucceed>
                  /{simpleInfos?.get_keyword?.all_count}
                </>
              ) : simpleInfos?.get_keyword?.per >= 80 ? (
                <>
                  <TextWarning>
                    {simpleInfos?.get_keyword?.per}% {simpleInfos?.get_keyword?.done_count}
                  </TextWarning>
                  /{simpleInfos?.get_keyword?.all_count}
                </>
              ) : (
                <>
                  <TextFail>
                    {simpleInfos?.get_keyword?.per}% {simpleInfos?.get_keyword?.done_count}
                  </TextFail>
                  /{simpleInfos?.get_keyword?.all_count}
                </>
              )}
              )
            </TextButton>{' '}
            <TextButton
              Theme={workType === 'product_info' ? 'primary' : null}
              onClick={() => {
                setWorkType('product_info');
                navigate('/work/product_info');
              }}
              size={30}
            >
              {' '}
              [3] Slot 정보 수집 (
              <PercentModule
                value={simpleInfos?.product?.done_count + simpleInfos?.product?.no_find_nvmid_count}
                valueMax={simpleInfos?.product?.all_count}
              />{' '}
              <TextSucceed>
                {simpleInfos?.product?.done_count + simpleInfos?.product?.no_find_nvmid_count}
              </TextSucceed>
              /{simpleInfos?.product?.all_count})
            </TextButton>{' '}
            <TextButton
              Theme={workType === 'get_sbth' ? 'primary' : null}
              onClick={() => {
                setWorkType('get_sbth');
                navigate('/work/get_sbth');
              }}
              size={30}
            >
              {' '}
              [6] Get Sbth 쇼핑검색 암호화 추출 Encrypted Data (
              <TextSucceed>
                {simpleInfos?.sbth?.per}% {simpleInfos?.sbth?.sbth_count}
              </TextSucceed>
              )
              {/* (
              {simpleInfos?.sbth?.per >= 100 ? (
                <>
                  <TextSucceed>
                    {simpleInfos?.sbth?.per}% {simpleInfos?.sbth?.sbth_count}
                  </TextSucceed>
                </>
              ) : simpleInfos?.sbth?.per >= 80 ? (
                <>
                  <TextWarning>
                    {simpleInfos?.sbth?.per}% {simpleInfos?.sbth?.sbth_count}
                  </TextWarning>
                </>
              ) : (
                <>
                  <TextFail>
                    {simpleInfos?.sbth?.per}% {simpleInfos?.sbth?.sbth_count}
                  </TextFail>
                </>
              )}
              ) */}
            </TextButton>
          </Box>
          <Box>
            작업 <TextSmall>Work</TextSmall>
          </Box>
          <Box gap={24}>
            {' '}
            <TextButton
              Theme={workType === 'view' ? 'primary' : null}
              onClick={() => {
                setWorkType('view');
                navigate('/work/view');
              }}
              size={30}
            >
              {' '}
              [7] view 노출/가상 유입 View(
              <PercentModule
                value={simpleInfos?.view?.done_count}
                valueMax={simpleInfos?.view?.all_count}
              />{' '}
              {(simpleInfos?.view?.done_count / 1000).toFixed(1)}/
              {parseInt(simpleInfos?.view?.all_count / 1000)}K) F Do(
              <PercentModule
                value={simpleInfos?.etc_do2?.done_count}
                valueMax={simpleInfos?.etc_do2?.all_count}
              />{' '}
              {(simpleInfos?.etc_do2?.done_count / 1000).toFixed(1)}/
              {parseInt(simpleInfos?.etc_do2?.all_count / 1000)}K)
            </TextButton>{' '}
            <TextButton
              Theme={workType === 'date_logs' ? 'primary' : null}
              onClick={() => {
                setWorkType('date_logs');
                navigate('/work/date_logs');
              }}
              size={30}
            >
              {' '}
              [-] date logs{' '}
            </TextButton>{' '}
            <TextButton
              Theme={workType === 'result_logs' ? 'primary' : null}
              onClick={() => {
                setWorkType('result_logs');
                navigate('/work/result_logs');
              }}
              size={30}
            >
              {' '}
              [-] do result logs succeed rate{' '}
              <PercentModule
                succeed_limit={80}
                value={simpleInfos?.result_log?.done_count}
                valueMax={simpleInfos?.result_log?.all_count}
              />{' '}
              {currencyComma(simpleInfos?.etc_do1?.done_count)}/
              {currencyComma(simpleInfos?.etc_do1?.all_count)} (
              <PercentModule
                value={simpleInfos?.etc_do1?.done_count}
                valueMax={simpleInfos?.etc_do1?.all_count}
              />
              )
            </TextButton>
          </Box>
          <Box>
            상황 <TextSmall>Status</TextSmall>
          </Box>
          <Box gap={24}>
            {' '}
            <TextButton
              Theme={workType === 'view' ? 'primary' : null}
              onClick={() => {
                setWorkType('proxy');
                navigate('/work/proxy');
              }}
              size={30}
            >
              {' '}
              [8] Proxy Ip 상황{' '}
              {simpleInfos?.proxy?.proxy_ip_count >= 300 ? (
                <>
                  <TextSucceed>{simpleInfos?.proxy?.proxy_ip_count} </TextSucceed>(country:{' '}
                  {simpleInfos?.proxy?.proxy_ip_country_count})
                </>
              ) : simpleInfos?.proxy?.proxy_ip_count >= 100 ? (
                <>
                  <TextWarning>{simpleInfos?.proxy?.proxy_ip_count} </TextWarning>(country:{' '}
                  {simpleInfos?.proxy?.proxy_ip_country_count})
                </>
              ) : (
                <>
                  <TextFail>{simpleInfos?.proxy?.proxy_ip_count} </TextFail>(country:{' '}
                  {simpleInfos?.proxy?.proxy_ip_country_count})
                </>
              )}
            </TextButton>{' '}
            <TextButton
              Theme={workType === 'final_result_logs' ? 'primary' : null}
              onClick={() => {
                setWorkType('final_result_logs');
                navigate('/work/final_result_logs');
              }}
              size={30}
            >
              {' '}
              [-] final result logs (
              {(simpleInfos?.final_result_log_infos?.to_count / 1000).toFixed(1)}/
              {simpleInfos?.final_result_log_infos?.to_count_max / 1000}K{' '}
              <PercentModule
                succeed_limit={80}
                value={simpleInfos?.final_result_log_infos?.to_count}
                valueMax={simpleInfos?.final_result_log_infos?.to_count_max}
              />
              )
            </TextButton>
          </Box>
          <Box>
            관리 <TextSmall>Management</TextSmall>
          </Box>
          <Box gap={24}>
            <TextButton
              Theme={workType === 'vmware' ? 'primary' : null}
              onClick={() => {
                setWorkType('vmware');
                navigate('/work/vmware');
              }}
              size={30}
            >
              {' '}
              [-] vmware{' '}
              <TextSucceed>
                {simpleInfos?.vmware_working_simple_infos?.working_vmware_names_count} (
              </TextSucceed>
              {simpleInfos?.vmware_working_simple_infos?.vmware_name_f_nums_count})
            </TextButton>{' '}
            <TextButton
              Theme={workType === 'rank' ? 'primary' : null}
              onClick={() => {
                setWorkType('rank');
                navigate('/work/rank');
              }}
              size={30}
            >
              {' '}
              [4] ranking (
              {simpleInfos?.rank?.per >= 100 ? (
                <>
                  <TextSucceed>
                    {simpleInfos?.rank?.per}% {simpleInfos?.rank?.done_count}
                  </TextSucceed>
                  /{simpleInfos?.rank?.all_count}
                </>
              ) : simpleInfos?.rank?.per >= 80 ? (
                <>
                  <TextWarning>
                    {simpleInfos?.rank?.per}% {simpleInfos?.rank?.done_count}
                  </TextWarning>
                  /{simpleInfos?.rank?.all_count}
                </>
              ) : (
                <>
                  <TextFail>
                    {simpleInfos?.rank?.per}% {simpleInfos?.rank?.done_count}
                  </TextFail>
                  /{simpleInfos?.rank?.all_count}
                </>
              )}
              )
            </TextButton>{' '}
            <TextButton
              Theme={workType === 'basic_url_type' ? 'primary' : null}
              onClick={() => {
                setWorkType('basic_url_type');
                navigate('/work/basic_url_type');
              }}
              size={30}
            >
              {' '}
              [5] url type basic 헤더 사전 작업 Basic URL Headers{' '}
            </TextButton>
            <TextButton
              Theme={workType === 'refilter_url_type' ? 'primary' : null}
              onClick={() => {
                setWorkType('refilter_url_type');
                navigate('/work/refilter_url_type');
              }}
              size={30}
            >
              {' '}
              [1] url type refilter 헤더 재조합 작업 (URL Header Rebuilding){' '}
            </TextButton>
          </Box>
          <Box>
            <Box gap={24}>
              <TextStatus>
                do speed/ 5m[<h1> {simpleInfos?.speed_infos?.etc_do1_five_ct} </h1>] day{' '}
                <h1>{simpleInfos?.speed_infos?.etc_do1_can_slot_ct}</h1>/5m
              </TextStatus>
              <TextStatus>
                view speed/ 5m[<h1> {simpleInfos?.speed_infos?.view_five_ct} </h1>] day{' '}
                <h1>{simpleInfos?.speed_infos?.view_can_slot_ct}</h1>/5m (
                <TextSmall>
                  cmds ct:
                  <TextSucceed>{simpleInfos?.speed_infos?.view_cmd_five_ct}</TextSucceed> avg: (
                  <TextSucceed>
                    {parseInt(
                      simpleInfos?.speed_infos?.view_five_ct /
                        simpleInfos?.speed_infos?.view_cmd_five_ct,
                    )}
                  </TextSucceed>
                  )/5m) ok rate: {simpleInfos?.speed_infos?.view_five_succeed_info?.succeed_count}/
                  {simpleInfos?.speed_infos?.view_five_succeed_info?.count}{' '}
                  <PercentModule
                    value={simpleInfos?.speed_infos?.view_five_succeed_info?.succeed_count}
                    valueMax={simpleInfos?.speed_infos?.view_five_succeed_info?.count}
                  />
                </TextSmall>
              </TextStatus>
            </Box>
          </Box>
        </Box>
        <Box>
          <TextSmall>{count}</TextSmall>
        </Box>
      </Box>
      <Routes>
        <Route path={'/refilter_url_type/*'} element={<WorkRefilterUrlType />} />
        <Route path={'/basic_url_type/*'} element={<WorkBasicUrlType />} />
        <Route path={'/keyword/*'} element={<WorkKeyword />} />
        <Route path={'/refilter_ukeywordrl_type/*'} element={<WorkRefilterUrlType />} />
        <Route path={'/product_info/*'} element={<WorkProductInfo />} />
        <Route path={'/rank/*'} element={<WorkRank />} />
        <Route path={'/get_sbth/*'} element={<WorkGetSbth />} />
        <Route path={'/view/*'} element={<WorkView />} />
        <Route path={'/proxy/*'} element={<WorkProxy />} />
        <Route path={'/result_logs/*'} element={<WorkResultLogs />} />
        <Route path={'/final_result_logs/*'} element={<WorkFinalResultLogs />} />
        <Route path={'/date_logs/*'} element={<WorkDateLogs />} />
        <Route path={'/vmware/*'} element={<WorkVmwareInfos />} />
      </Routes>
      {/* 
      {workType === 'refilter_url_type' ? (
        <WorkRefilterUrlType />
      ) : workType === 'basic_url_type' ? (
        <WorkBasicUrlType />
      ) : workType === 'keyword' ? (
        <WorkKeyword />
      ) : workType === 'product_info' ? (
        <WorkProductInfo />
      ) : workType === 'rank' ? (
        <WorkRank />
      ) : (
        <></>
      )} */}
    </Box>
  );
};
const TextStatus = styled.span`
  display: inline;
  font-weight: 600;
  font-size: 18rem;
  h1 {
    color: green;
    display: inline;
  }
`;
export default Work;
