import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMutations from 'Common/Service/commonMutations';
import { Box, Text } from 'Common/UI/atoms';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { currencyComma } from 'Common/Utils';

const AnalyticsAllVmware = () => {
  const [status, setStatus] = useState({});
  const [result, setResults] = useState({});

  const { requestLoadStatusGetDataInfoMutation } = useMutations(setResults);
  useEffect(() => {
    if (result?.request === 'requestLoadStatusGetDataInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        setStatus(result?.result);
      }
      setTimeout(() => {
        requestLoadStatusGetDataInfoMutation.mutate();
      }, 2000);
    }

    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadStatusGetDataInfoMutation.mutate();
  }, []);
  return (
    <>
      <Box column gap={20}>
        <LabelBox label={'네이버 아이디'}>
          <Box gap="20rem" column>
            <Box>
              로그인대기:{' '}
              <Text.Point>{currencyComma(status?.ready_naver_login_id_count)}</Text.Point>
            </Box>
            <Box gap="14rem">
              <Text.Point>{currencyComma(status?.use_naver_count)}</Text.Point>/
              <Text.Point color="G_150">
                {currencyComma(status?.block_naver_count + status?.use_naver_count)}
              </Text.Point>
            </Box>
          </Box>
        </LabelBox>
        <LabelBox label={'유효클릭 가능 네이버 현황'}>
          <Box gap="20rem" column>
            <Box>
              접속가능: 첫클릭 {status?.naver_shop_click_infos?.active?.first_click_id_count}
              ,2번째이상 {status?.naver_shop_click_infos?.active?.step_click_id_count}
            </Box>
            <Box gap="14rem">
              접속대기: 첫클릭 {status?.naver_shop_click_infos?.ready?.first_click_id_count}
              ,2번째이상 {status?.naver_shop_click_infos?.ready?.step_click_id_count}
            </Box>
          </Box>
        </LabelBox>
        <LabelBox label={'유효클릭 완료 현황'}>
          <Box gap="20rem" column>
            <Box>
              일반대기 {status?.naver_shop_click_done_infos?.click_done_count}, 장기대기{' '}
              {status?.naver_shop_click_done_infos?.click_done_long_term_count}
            </Box>
          </Box>
        </LabelBox>
        <LabelBox label={'네이버 활동 현황'}>
          <Box gap="20rem" column>
            <Box>
              접속가능: 유효클릭가능 활동수 충족 {status?.naver_active_infos?.active?.use_count},
              유효클릭안됨 {status?.naver_active_infos?.active?.no_use_count}
            </Box>
            <Box gap="14rem">
              접속대기: 유효클릭가능 활동수 충족 {status?.naver_active_infos?.ready?.use_count},
              유효클릭가능안됨 {status?.naver_active_infos?.ready?.no_use_count}
            </Box>
          </Box>
        </LabelBox>
        <LabelBox label={'브라우져 활동 현황'}>
          <Box gap="20rem" column>
            <Box>
              네이버로그인가능 {status?.naver_can_login_browser_infos?.use_browser_count}
              네이버로그인안됨 {status?.naver_can_login_browser_infos?.no_use_browser_count}
            </Box>
          </Box>
        </LabelBox>
      </Box>
    </>
  );
};
export default AnalyticsAllVmware;
