import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoadWorkNewGetNewPatternActiveDoneLogs } from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  get_uuid,
  reload_second,
} from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
import WorkNewPatternActiveWorkerState from './WorkNewPatternActiveWorkerState';
import WorkNewPatternProductInfoKeywordStatus from './WorkNewPatternProductInfoKeywordStatus';
// import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternActiveState = () => {
  // if (loginInfo?.user_level !== 5) return;
  const [activeLogs, setActiveLogs] = useState();
  const [count, setCount] = useState(20);
  const [selectShopLinkIdInfo, setSelectShopLinkIdInfo] = useState();
  const [selectWorkerIdInfo, setSelectWorkerIdInfo] = useState();
  const [selectShowProductInfo, setSelectShowProductInfo] = useState({});
  const [apiId, setApiId] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetNewPatternActiveDoneLogs({});
    setActiveLogs(result);
  };

  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Box column width="100%">
      <Box width="100%">
        <TextSmall>[{count}]</TextSmall>
      </Box>
      <Box height="100%">
        <Box column width="100%" valign="top">
          {activeLogs?.map((vmware_info, index) => {
            const vmware_name = vmware_info.vmware_name;
            const list = vmware_info?.list;
            const count = vmware_info.count;
            return (
              <Box column key={index}>
                <Box>
                  {vmware_name} [{count}]
                </Box>
                <Box column>
                  {list?.map((info, index) => {
                    // const info = data?.list[0];
                    const id = info.id;
                    const worker_id = info.worker_id;
                    const group_active_pattern_name = info.group_active_pattern_name;
                    const active_pattern_name = info.active_pattern_name;
                    const step_done_ct = info.step_done_ct;
                    const step_done_ct_max = info.step_done_ct_max;
                    const using_sec = info.using_sec;
                    const proxy_ip_country = info.proxy_ip_country;
                    const workstation_name = info.workstation_name;
                    const vmware_name = info.vmware_name;
                    const vmware_f_num = info.vmware_f_num;
                    const new_pattern_lately_start_datetime =
                      info.new_pattern_lately_start_datetime;
                    const new_pattern_lately_start_datetime_ago_sec =
                      info.new_pattern_lately_start_datetime_ago_sec;
                    const url_access_request_size = info.url_access_request_size;
                    const url_access_response_size = info.url_access_response_size;
                    const url_access_total_time = info.url_access_total_time;
                    const url_access_count = info.url_access_count;
                    const new_pattern_which = info.new_pattern_which;
                    const done_date = info.done_date;
                    const done_datetime = info.done_datetime;
                    const done_datetime_ago_sec = info.done_datetime_ago_sec;
                    const done_using_min = info.done_using_min;
                    const state = info.state;
                    const size = url_access_request_size + url_access_response_size;

                    const current_step_state = info.current_step_state;
                    const current_step_result = info.current_step_result;
                    const current_step_next_add_sec = info.current_step_next_add_sec;
                    const current_step_work_do = info.current_step_work_do;
                    const current_step_date = info.current_step_date;
                    const current_step_datetime = info.current_step_datetime;
                    const current_step_datetime_ago_sec = info.current_step_datetime_ago_sec;

                    const lately_click_type = info.lately_click_type;

                    const lately_click_shop_link_id = info.lately_click_shop_link_id;
                    const lately_click_shop_link_title = info.lately_click_shop_link_title;
                    const lately_click_shop_link_mall_name = info.lately_click_shop_link_mall_name;
                    const lately_click_shop_link_smartstore_chk =
                      info.lately_click_shop_link_smartstore_chk;
                    const lately_click_shop_link_date = info.lately_click_shop_link_date;
                    const lately_click_shop_link_datetime = info.lately_click_shop_link_datetime;
                    const lately_click_shop_link_datetime_ago_sec =
                      info.lately_click_shop_link_datetime_ago_sec;
                    const lately_click_shop_link_state = info.lately_click_shop_link_state;
                    //click or find or no_find
                    const lately_click_shop_link_search_type =
                      info.lately_click_shop_link_search_type;
                    const lately_click_shop_link_search_keyword =
                      info.lately_click_shop_link_search_keyword;

                    const click_done_count = info.click_done_count;
                    const real_click_count = info.real_click_count;
                    const real_etc_do1_click_count = info.real_etc_do1_click_count;

                    return (
                      <Box key={index} width="100%">
                        <ActiveLogStatusBox>
                          <Box>
                            {selectShowProductInfo?.index === index &&
                              selectShowProductInfo?.lately_click_shop_link_id ===
                                lately_click_shop_link_id && (
                                <Box column>
                                  <Box>
                                    <button
                                      onClick={() => {
                                        const shop_link_id =
                                          selectShowProductInfo?.lately_click_shop_link_id;
                                        const uuid = get_uuid();
                                        const info = {
                                          uuid,
                                          index,
                                          shop_link_id,
                                        };
                                        setSelectShopLinkIdInfo(info);
                                      }}
                                    >
                                      샵 세부 로고
                                    </button>
                                    {selectShopLinkIdInfo && (
                                      <button
                                        onClick={() => {
                                          setSelectShopLinkIdInfo();
                                        }}
                                      >
                                        닫기
                                      </button>
                                    )}
                                  </Box>
                                  <StatusBox>
                                    <WorkNewPatternProductInfoKeywordStatus
                                      uuid={selectShowProductInfo?.uuid}
                                      shop_link_id={
                                        selectShowProductInfo?.lately_click_shop_link_id
                                      }
                                    />
                                  </StatusBox>
                                </Box>
                              )}
                            {selectShopLinkIdInfo?.index === index &&
                            selectShopLinkIdInfo?.shop_link_id === lately_click_shop_link_id ? (
                              <StatusBox>
                                <Box width={800} height="100%" valign={'top'}>
                                  <WorkNewPatternActiveWorkerState
                                    uuid={selectShopLinkIdInfo?.uuid}
                                    shop_link_id={selectShopLinkIdInfo?.shop_link_id}
                                  />
                                </Box>
                              </StatusBox>
                            ) : selectWorkerIdInfo?.index === index &&
                              selectWorkerIdInfo?.worker_id === worker_id ? (
                              <StatusBox>
                                <Box width={800} height="100%" valign={'top'}>
                                  <WorkNewPatternActiveWorkerState
                                    uuid={selectWorkerIdInfo?.uuid}
                                    worker_id={selectWorkerIdInfo?.worker_id}
                                  />
                                </Box>
                              </StatusBox>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box gap={8}>
                            <Box>
                              <button
                                onClick={() => {
                                  const uuid = get_uuid();
                                  const info = {
                                    uuid,
                                    index,
                                    worker_id,
                                  };
                                  setSelectWorkerIdInfo(info);
                                }}
                              >
                                {worker_id}
                              </button>
                            </Box>
                            <Box>
                              {vmware_name}
                              <TextFail>{vmware_f_num}</TextFail>
                            </Box>
                            <Box>{group_active_pattern_name}</Box>
                            <Box>{proxy_ip_country}</Box>
                            <Box>{new_pattern_which}</Box>
                            <Box>
                              <TextSucceed>{state}</TextSucceed>
                            </Box>

                            <Box>
                              {state === 'start' ? (
                                <>
                                  <Box>{current_step_work_do}</Box>
                                  {current_step_state === 'done' ? (
                                    <TextWarning>
                                      {current_step_result}-대기중[{current_step_next_add_sec}초]
                                    </TextWarning>
                                  ) : (
                                    <TextSucceed>
                                      진행중[{current_step_datetime_ago_sec}초]
                                    </TextSucceed>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box>{current_step_datetime_ago_sec}</Box>
                            <Box>
                              using:{using_sec} {lately_click_shop_link_state}
                            </Box>
                            <Box>
                              {lately_click_shop_link_state === 'click' ? (
                                <StatusBox>
                                  <Box column>
                                    <Box>
                                      <Box>
                                        <TextSucceed>
                                          {lately_click_type}-{lately_click_shop_link_state}
                                        </TextSucceed>
                                      </Box>
                                      <Box>{real_click_count}</Box>
                                      <Box>{real_etc_do1_click_count}</Box>
                                      <Box>
                                        <TextSucceed>{click_done_count}</TextSucceed>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Box>
                                        <button
                                          onClick={() => {
                                            const uuid = get_uuid();
                                            const info = {
                                              index,
                                              uuid,
                                              lately_click_shop_link_id,
                                            };
                                            setSelectShowProductInfo(info);
                                          }}
                                        >
                                          {lately_click_shop_link_search_keyword}[
                                          {lately_click_shop_link_id}]
                                          {lately_click_shop_link_mall_name}
                                        </button>
                                      </Box>
                                      <Box>{lately_click_shop_link_datetime_ago_sec}s</Box>
                                    </Box>
                                  </Box>
                                </StatusBox>
                              ) : lately_click_shop_link_state === 'find' ? (
                                <StatusBox>
                                  <Box column>
                                    <Box>
                                      <Box>
                                        <TextWarning>
                                          {lately_click_type}-{lately_click_shop_link_state}
                                        </TextWarning>
                                      </Box>
                                      <Box>{real_click_count}</Box>
                                      <Box>{real_etc_do1_click_count}</Box>
                                      <Box>
                                        <TextSucceed>{click_done_count}</TextSucceed>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Box>
                                        <button
                                          onClick={() => {
                                            const uuid = get_uuid();
                                            const info = {
                                              index,
                                              uuid,
                                              lately_click_shop_link_id,
                                            };
                                            setSelectShowProductInfo(info);
                                          }}
                                        >
                                          {lately_click_shop_link_search_keyword}[
                                          {lately_click_shop_link_id}]
                                          {lately_click_shop_link_mall_name}
                                        </button>
                                      </Box>
                                      <Box>{lately_click_shop_link_datetime_ago_sec}s</Box>
                                    </Box>
                                  </Box>
                                </StatusBox>
                              ) : lately_click_shop_link_state === 'no_find' ? (
                                <StatusBox>
                                  <Box column>
                                    <Box>
                                      <Box>
                                        <TextFail>
                                          {lately_click_type}-{lately_click_shop_link_state}
                                        </TextFail>
                                      </Box>
                                      <Box>{real_click_count}</Box>
                                      <Box>{real_etc_do1_click_count}</Box>
                                      <Box>
                                        <TextSucceed>{click_done_count}</TextSucceed>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Box>
                                        <button
                                          onClick={() => {
                                            const uuid = get_uuid();
                                            const info = {
                                              index,
                                              uuid,
                                              lately_click_shop_link_id,
                                            };
                                            setSelectShowProductInfo(info);
                                          }}
                                        >
                                          {lately_click_shop_link_search_keyword}[
                                          {lately_click_shop_link_id}]
                                          {lately_click_shop_link_mall_name}
                                        </button>
                                      </Box>
                                      <Box>{lately_click_shop_link_datetime_ago_sec}s</Box>
                                    </Box>
                                  </Box>
                                </StatusBox>
                              ) : lately_click_shop_link_state === 'etc_do1_problem' ? (
                                <StatusBox>
                                  <Box column>
                                    <Box>
                                      <Box>
                                        <TextFail>유입제제</TextFail>
                                      </Box>
                                      <Box>{real_click_count}</Box>
                                      <Box>{real_etc_do1_click_count}</Box>
                                      <Box>
                                        <TextSucceed>{click_done_count}</TextSucceed>
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Box>
                                        <button
                                          onClick={() => {
                                            const uuid = get_uuid();
                                            const info = {
                                              index,
                                              uuid,
                                              lately_click_shop_link_id,
                                            };
                                            setSelectShowProductInfo(info);
                                          }}
                                        >
                                          {lately_click_shop_link_search_keyword}[
                                          {lately_click_shop_link_id}]
                                          {lately_click_shop_link_mall_name}
                                        </button>
                                      </Box>
                                      <Box>{lately_click_shop_link_datetime_ago_sec}s</Box>
                                    </Box>
                                  </Box>
                                </StatusBox>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box>
                              {step_done_ct}/{step_done_ct_max}{' '}
                              <PercentModule value={step_done_ct} valueMax={step_done_ct_max} />
                            </Box>
                            <Box>
                              <TrafficSizeModule size={size} />
                            </Box>
                            <Box></Box>
                          </Box>
                        </ActiveLogStatusBox>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
const ActiveLogStatusBox = styled.div`
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  /* border-style: solid; */
  border-width: 1rem;
  border-color: blue;
`;
const StatusBox = styled.div`
  display: flex;
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
export default WorkNewPatternActiveState;
