import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoadWorkNewGetProductSimpleStatusInfos } from 'Common/Service/common';

import styled from 'styled-components';
import { Box } from 'Common/UI/atoms';
import MallInfo from 'MallInfo';
import MallNameModule from './Module/MallNameModule';
import { reload_second, TextFail, TextSmall, TextSucceed, TextWarning } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';

const WorkNewPatternNeedAlbaSlotInfos = ({ all_read_chk = false }) => {
  // if (loginInfo?.user_level !== 5) return;
  const [modal, setModal] = useState({
    title: '',
    successText: '발급 취소',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, isActive: false });
    },
  });
  const [count, setCount] = useState(reload_second);
  const [apiId, setApiId] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkSlotStatusInfo();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  const [workSlotSimpleStatusInfos, setWorkSlotSimpleStatusInfos] = useState();
  const [clickDateStatusInfos, setClickDateStatusInfos] = useState();
  const [clickRealtimeStatusInfos, setClickRealtimeStatusInfos] = useState();
  const [toCountStatus, setToCountStatus] = useState();

  const getWorkSlotStatusInfo = async () => {
    const result = await requestLoadWorkNewGetProductSimpleStatusInfos();
    // work_slot_status_infos = result.work_slot_status_infos
    setToCountStatus({
      to_count: result.to_count,
      to_count_max: result.to_count_max,
    });
    setClickDateStatusInfos(result.click_date_status_infos ? result.click_date_status_infos : {});
    setClickRealtimeStatusInfos(
      result.click_realtime_status_infos ? result.click_realtime_status_infos : {},
    );
    setWorkSlotSimpleStatusInfos(
      result.work_slot_status_infos ? result.work_slot_status_infos : {},
    );
  };

  useEffect(() => {
    getWorkSlotStatusInfo();
  }, []);
  return (
    <Box column>
      <Box>
        <TextSmall>reload {count}</TextSmall>
      </Box>
      <Box>
        in process <TextSucceed>{toCountStatus?.to_count}</TextSucceed>/{' '}
        {toCountStatus?.to_count_max}{' '}
        <PercentModule value={toCountStatus?.to_count} valueMax={toCountStatus?.to_count_max} />
      </Box>
      <Box>
        today <TextSucceed>{clickDateStatusInfos?.click_done_count}</TextSucceed>/{' '}
        {clickDateStatusInfos?.count}{' '}
        <PercentModule
          value={clickDateStatusInfos?.click_done_count}
          valueMax={clickDateStatusInfos?.count}
        />
      </Box>
      <Box>
        in 2 hour <TextSucceed>{clickRealtimeStatusInfos?.click_done_count}</TextSucceed>/{' '}
        {clickRealtimeStatusInfos?.count}{' '}
        <PercentModule
          value={clickRealtimeStatusInfos?.click_done_count}
          valueMax={clickRealtimeStatusInfos?.count}
        />
      </Box>
      <Box column>
        {workSlotSimpleStatusInfos?.product_status_infos?.map((e, index) => {
          const product_info_id = e.product_info_id;
          const ok_keyword_infos = e.ok_keyword_infos;
          const warning_keyword_infos = e?.warning_keyword_infos;
          const stop_keywords = e?.stop_keywords;
          const to_view_count = e?.to_view_count;
          const to_view_count_max = e?.to_view_count_max;
          const view_access_datetime_ago_min = e?.view_access_datetime_ago_min;
          const view_access_workstation_name = e?.view_access_workstation_name;
          const view_access_vmware_name = e?.view_access_vmware_name;
          const view_access_vmware_f_num = e?.view_access_vmware_f_num;
          const to_click_count = e?.to_click_count;
          const to_click_count_max = e?.to_click_count_max;
          const to_etc_do1_count = e?.to_etc_do1_count;
          const to_etc_do1_count_max = e?.to_etc_do1_count_max;
          const etc_do1_access_datetime_ago_min = e?.etc_do1_access_datetime_ago_min;
          const etc_do1_access_workstation_name = e?.etc_do1_access_workstation_name;
          const etc_do1_access_vmware_name = e?.etc_do1_access_vmware_name;
          const etc_do1_access_vmware_f_num = e?.etc_do1_access_vmware_f_num;
          const can_access_chk = e?.can_access_chk;
          const to_etc_do1_can_access_datetime_ago_min = e?.to_etc_do1_can_access_datetime_ago_min;
          var view_status = null;
          if (view_access_datetime_ago_min < 30) {
            view_status = `${view_access_vmware_name}-${view_access_vmware_f_num} ${view_access_datetime_ago_min} 분전`;
          }
          var etc_do1_status = null;
          if (etc_do1_access_datetime_ago_min < 30) {
            etc_do1_status = `${etc_do1_access_vmware_name}-${etc_do1_access_vmware_f_num} ${etc_do1_access_datetime_ago_min} 분전`;
          }
          return (
            <KeywordStatusBox key={index} width="100%">
              <Box>
                <Box>
                  {can_access_chk ? (
                    <TextSucceed>{index + 1}</TextSucceed>
                  ) : (
                    <TextFail>
                      {index + 1} [{to_etc_do1_can_access_datetime_ago_min}분]
                    </TextFail>
                  )}
                </Box>
                <MallNameModule {...e} />
                <Box gap={4}>
                  {ok_keyword_infos?.map((keyword_info) => {
                    return (
                      <>
                        <TextSucceed>
                          {keyword_info?.keyword}(<TextFail>{keyword_info?.count}</TextFail>)
                        </TextSucceed>
                      </>
                    );
                  })}
                </Box>
                <Box gap={4}>
                  {warning_keyword_infos?.map((keyword_info) => {
                    return (
                      <>
                        <TextWarning>
                          {keyword_info?.keyword}(<TextFail>{keyword_info?.count}</TextFail>/
                          {keyword_info?.count_max})
                        </TextWarning>
                      </>
                    );
                  })}
                </Box>
                <Box gap={4}>
                  {stop_keywords?.map((keyword) => {
                    return (
                      <>
                        <TextFail>{keyword}</TextFail>
                      </>
                    );
                  })}
                </Box>
              </Box>
              <Box gap={8}>
                <Box>
                  {view_status ? (
                    <>
                      <TextSucceed>
                        view <TextWarning>{view_status}</TextWarning>
                      </TextSucceed>
                    </>
                  ) : (
                    <>view</>
                  )}{' '}
                  <TextSucceed>{to_view_count}</TextSucceed>
                </Box>
                <Box>
                  {etc_do1_status ? (
                    <>
                      <TextSucceed>
                        click <TextWarning>{etc_do1_status}</TextWarning>
                      </TextSucceed>
                    </>
                  ) : (
                    <>click</>
                  )}{' '}
                  etc_do1 <TextSucceed>{to_etc_do1_count}</TextSucceed>/{to_etc_do1_count_max} click{' '}
                  <TextSucceed>{to_click_count}</TextSucceed>/{to_click_count_max}
                </Box>
                <Box></Box>
              </Box>
            </KeywordStatusBox>
          );
        })}
      </Box>
    </Box>
  );
};

const KeywordStatusBox = styled.div`
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
const WorkFiddlerCountInfoBox = styled.div`
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;

export default WorkNewPatternNeedAlbaSlotInfos;
