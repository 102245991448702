import { API_SERVER_URL } from 'Common/Env';
import { auth_headers } from 'Common/Utils';
import axios from 'axios';

export const requestLoadSlotGetRankInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/get_rank_infos`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadPaymentGetSlotIds = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/get_ids`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadPaymentGetSlotStateCalendarMonthInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/payment/get_slot_state_calendar_month_infos`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadPaymentGetSlotStateCalendarDayInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/payment/get_slot_state_calendar_day_infos`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadPaymentGetSearchInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/payment/get_search_info`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadPaymentGetPaidSlots = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/payment/get_paid_slots`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadPaymentGetSlots = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/payment/get_slots`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotGetRankLog = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/get_rank_log`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotExtendsDay = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/extends_day`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotAsExtendsDay = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/as_extends_day`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotGroupSet = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/set`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotGroupSetDate = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/set_date`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotGroupPaid = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/paid`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotGroupExtendsDay = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/extends_day`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotGroupList = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/list`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotSet = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/set`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotsSet = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slots/set`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotSetGroupName = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/set/group_name`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotDel = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/del`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotAdd = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/add`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotList = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/list`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotGroupUse = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/use`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotPaid = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/paid`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotCancelDelete = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/cancel_del`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotCancelPaid = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/cancel_paid`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotRequestSearchKeywordAgain = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/request_search_keyword_again`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotRequestEtcDo1ProblemReset = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/request_etc_do1_problem_reset`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotUse = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot/use`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadSlotGroupSetOwner = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/set/owner`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
