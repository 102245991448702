import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoadWorkNewGetNewPatternProductKeywordStatus } from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
import WorkNewPatternActiveWorkerState from './WorkNewPatternActiveWorkerState';
import { keyword_strip } from 'Common/Utils';
import { TextLengthBox } from 'Common/UI/atoms/Input/styled';
// import GroupPatternStateModule from './Module/GroupPatternStateModule';
export const WorkNewPatternProductInfoKeywordStatusModule = ({ data }) => {
  const [productInfoKeywordStatus, setProductInfoKeywordStatus] = useState();

  const getWorkInfos = () => {
    const new_pattern_shop_keyword_json_infos_strip_keywords =
      data?.new_pattern_shop_keyword_json_infos?.map((info) => keyword_strip(info?.keyword));
    const new_pattern_shop_keyword_json_infos_relatedtags_strip_keywords =
      data?.new_pattern_shop_keyword_json_infos_relatedtags?.map((info) =>
        keyword_strip(info?.keyword),
      );

    // console.log(result);

    const new_pattern_shop_got_keywords_relatedtags_no_infomation =
      data?.new_pattern_shop_got_keywords_relatedtags
        ?.map((keyword) => {
          if (
            new_pattern_shop_keyword_json_infos_relatedtags_strip_keywords
              ?.map((k) => keyword_strip(k))
              ?.includes(keyword_strip(keyword))
          ) {
            return null;
          } else {
            return keyword;
          }
        })
        .filter((k) => k);

    const new_pattern_shop_got_keywords_no_infomation = data?.new_pattern_shop_got_keywords
      ?.map((keyword) => {
        if (
          new_pattern_shop_keyword_json_infos_strip_keywords
            ?.map((k) => keyword_strip(k))
            ?.includes(keyword_strip(keyword))
        ) {
          return null;
        } else {
          return keyword;
        }
      })
      .filter((k) => k);
    setProductInfoKeywordStatus({
      ...data,
      ...{
        new_pattern_shop_keyword_json_infos_strip_keywords,
        new_pattern_shop_got_keywords_no_infomation,
        new_pattern_shop_got_keywords_relatedtags_no_infomation,
      },
    });
  };

  useEffect(() => {
    // setProductInfoKeywordStatus(null);
    getWorkInfos();
  }, [data]);
  return (
    <Block>
      <Box column>
        <Box>
          [{productInfoKeywordStatus?.mall_name}] {productInfoKeywordStatus?.title}
        </Box>
        <Box>
          가격: {productInfoKeywordStatus?.price} 색상:{' '}
          {productInfoKeywordStatus?.new_pattern_color_code_text} [
          {productInfoKeywordStatus?.new_pattern_color_code}] 카테고리:{' '}
          {productInfoKeywordStatus?.category_level}-[{productInfoKeywordStatus?.category_code}]{' '}
          {productInfoKeywordStatus?.category}
        </Box>
      </Box>

      <Box>
        <Box>
          [work] Keyword Status{' '}
          <TextSucceed>
            {productInfoKeywordStatus?.new_pattern_shop_keyword_json_infos?.length}
          </TextSucceed>{' '}
          {productInfoKeywordStatus?.new_pattern_shop_keyword_json_infos_done_chk ? (
            <>
              <TextSucceed>
                수집완료 {productInfoKeywordStatus?.new_pattern_shop_keyword_json_infos?.length}
              </TextSucceed>
              /2
            </>
          ) : (
            <>
              <TextFail>
                수집 완료 안된 상태{' '}
                {productInfoKeywordStatus?.new_pattern_shop_keyword_json_infos?.length}
              </TextFail>
              /2
            </>
          )}
        </Box>

        <Box>
          <TextSmall>
            {productInfoKeywordStatus?.new_pattern_shop_keyword_json_infos?.map((i) => {
              return (
                <>
                  {productInfoKeywordStatus?.new_pattern_shop_got_keywords
                    ?.map((k) => keyword_strip(k))
                    ?.includes(keyword_strip(i.keyword)) ? (
                    <>
                      <TextSucceed>[{i?.keyword}]</TextSucceed>{' '}
                    </>
                  ) : (
                    <>
                      <TextWarning>[{i?.keyword}]</TextWarning>{' '}
                    </>
                  )}
                </>
              );
            })}
          </TextSmall>
        </Box>
      </Box>
      <Box>
        {productInfoKeywordStatus?.to_click_count < productInfoKeywordStatus?.to_click_count_max ||
          (productInfoKeywordStatus?.to_etc_do1_count <
            productInfoKeywordStatus?.to_etc_do1_count_max && (
            <>
              {productInfoKeywordStatus?.etc_do1_access_datetime_ago_sec > 300 ? (
                <>
                  <TextFail>{productInfoKeywordStatus?.etc_do1_access_datetime_ago_sec}</TextFail>s
                  ago
                </>
              ) : (
                <>
                  <TextSucceed>
                    {productInfoKeywordStatus?.etc_do1_access_datetime_ago_sec}
                  </TextSucceed>
                  s ago
                </>
              )}
            </>
          ))}

        {productInfoKeywordStatus?.to_click_count_max > 0 && (
          <Box>
            [
            <PercentModule
              value={productInfoKeywordStatus?.to_click_count}
              valueMax={productInfoKeywordStatus?.to_click_count_max}
            />
            ] 클릭: <TextSucceed>{productInfoKeywordStatus?.to_click_count}</TextSucceed>/
            {productInfoKeywordStatus?.to_click_count_max}
          </Box>
        )}
        {productInfoKeywordStatus?.to_etc_do1_count_max > 0 && (
          <Box>
            [
            <PercentModule
              value={productInfoKeywordStatus?.to_etc_do1_count}
              valueMax={productInfoKeywordStatus?.to_etc_do1_count_max}
            />
            ] 유입: <TextSucceed>{productInfoKeywordStatus?.to_etc_do1_count}</TextSucceed>/
            {productInfoKeywordStatus?.to_etc_do1_count_max}
          </Box>
        )}
        <Box>
          유입제제: <TextFail>{productInfoKeywordStatus?.etc_do1_problem_count}</TextFail>
        </Box>
        <Box>노출 {productInfoKeywordStatus?.to_view_count}</Box>
      </Box>
      <Box>
        {productInfoKeywordStatus?.category_level < 3 && (
          <Box>
            <TextFail>[문제발생] 카테고리 level이 2 이하임 </TextFail>
          </Box>
        )}
      </Box>
      <Box>
        <Box>
          {productInfoKeywordStatus?.new_pattern_shop_got_keywords_no_infomation?.length > 0 ? (
            <>
              <TextFail>
                [문제발생] 정보 없는 키워드수:{' '}
                {productInfoKeywordStatus?.new_pattern_shop_got_keywords_no_infomation?.length}
              </TextFail>
              {productInfoKeywordStatus?.new_pattern_shop_got_keywords_no_infomation?.join(', ')}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box>
        <Box>[work] 키워드자동 수집 실패수</Box>
        <Box>
          <TextFail>
            {productInfoKeywordStatus?.new_pattern_shop_keyword_find_fail_keywords?.length}
          </TextFail>
          {/* {productInfoKeywordStatus?.new_pattern_shop_keyword_find_fail_keywords?.join(',')} */}
        </Box>
      </Box>
      <Box>
        <Box>[auto] shop_link 찾기 최종 실패</Box>
        <Box>
          <TextFail>
            {productInfoKeywordStatus?.new_pattern_auto_shop_search_find_fail_keywords?.length}
          </TextFail>

          {productInfoKeywordStatus?.new_pattern_auto_shop_search_find_fail_keywords?.join(',')}
        </Box>
      </Box>
      <Box>
        <Box>
          [실시간 검색 성공 키워드]
          {/* <TextSmall>Real-time Search Succeed Keywords</TextSmall> */}
        </Box>
        <Box>
          {productInfoKeywordStatus?.new_pattern_auto_shop_search_find_ok_infos?.map((ok_info) => {
            return (
              <Box>
                {ok_info?.keyword} <TextSucceed>{ok_info?.count}</TextSucceed>{' '}
                <TextSmall>{ok_info?.date}</TextSmall>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Box>
          [실시간 검색 실패 키워드(5회시 제거)]
          {/* <TextSmall>Real-time Search Failure Keywords</TextSmall> */}
        </Box>
        <Box>
          {productInfoKeywordStatus?.new_pattern_auto_shop_search_find_fail_infos?.map(
            (fail_info) => {
              return (
                <Box>
                  {fail_info?.keyword} <TextFail>{fail_info?.count}</TextFail>{' '}
                  <TextSmall>{fail_info?.date}</TextSmall>
                </Box>
              );
            },
          )}
        </Box>
      </Box>
      <Box>
        <Box>[auto] 등록된 연관검색어</Box>
        <Box>
          <TextFail>
            {productInfoKeywordStatus?.new_pattern_shop_got_keywords_relatedtags?.length}
          </TextFail>

          {productInfoKeywordStatus?.new_pattern_shop_got_keywords_relatedtags?.join(',')}
        </Box>
      </Box>
      <Box>
        <Box>
          {productInfoKeywordStatus?.new_pattern_shop_got_keywords_relatedtags_no_infomation
            ?.length > 0 ? (
            <>
              <TextFail>
                [문제발생] 정보 없는 연관 키워드수:{' '}
                {
                  productInfoKeywordStatus?.new_pattern_shop_got_keywords_relatedtags_no_infomation
                    ?.length
                }
              </TextFail>
              {productInfoKeywordStatus?.new_pattern_shop_got_keywords_relatedtags_no_infomation?.join(
                ', ',
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      {productInfoKeywordStatus?.new_pattern_work_manual_no_use_keywords?.length > 0 && (
        <Box>
          <Box>[manual] 등록된 수동 키워드</Box>
          <Box>
            <TextSucceed>
              {productInfoKeywordStatus?.new_pattern_work_manual_no_use_keywords?.length}
            </TextSucceed>
            {productInfoKeywordStatus?.new_pattern_work_manual_no_use_keywords?.join(',')}
          </Box>
        </Box>
      )}
      {productInfoKeywordStatus?.new_pattern_work_manual_no_use_keywords?.length > 0 && (
        <Box>
          <Box>[manual] 등록된 제외 키워드</Box>
          <Box>
            <TextFail>
              {productInfoKeywordStatus?.new_pattern_work_manual_no_use_keywords?.length}
            </TextFail>
            {productInfoKeywordStatus?.new_pattern_work_manual_no_use_keywords?.join(',')}
          </Box>
        </Box>
      )}
    </Block>
  );
};

const Block = styled.div`
  font-size: 13rem;
  font-weight: 500;
`;
export default WorkNewPatternProductInfoKeywordStatusModule;
