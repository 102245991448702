import { Box, Icon, Title } from 'Common/UI/atoms';
import Scroll from 'Common/UI/molecules/Scroll';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import CommonProgressBar from '../Module/CommonProgressBar';
import { styled } from 'styled-components';

const MainWorkerNotification = ({ column, data }) => {
  useEffect(() => {
    setList(data);
  }, [data]);
  const [list, setList] = useState(data);

  return (
    <Wrapper>
      <Box fill column>
        <Scroll height={'100%'}>
          <Box gap={4} column>
            {/* <Box gap={4} height={'max-content'} style={`min-height:352rem`} column> */}
            {list?.length ? (
              list?.map(
                ({ click_fail_continue_count, product_id, name, mall_name, percent }, key) => {
                  return (
                    <CommonProgressBar
                      mall_name={mall_name}
                      click_fail_continue_count={click_fail_continue_count}
                      product_id={product_id}
                      key={key}
                      column={column}
                      name={name}
                      percent={percent}
                    />
                  );
                },
              )
            ) : (
              <Box fill align="center" valign="middle" column gap={20}>
                <Icon.Opinion />
                <Box align="center" column>
                  <Title weight={400} size={'xxs'} color={'G_800'} align="center">
                    현재 작업중인 내역이 없어요
                  </Title>
                </Box>
              </Box>
            )}
          </Box>
        </Scroll>
      </Box>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: inherit;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 4rem;
`;
export default MainWorkerNotification;
