import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useMutations from 'Common/Service/commonMutations';
import { numberSum } from 'Common/Utils';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { Button, InputComponent } from 'Common/UI/atoms';
import { requestLoadSlotSet } from 'Common/Service/slot';
import { useDispatch } from 'react-redux';
import { onShowToast } from 'features/common/commonSlice';
import { useParams } from 'react-router-dom';

const AnalyticsVmwareShopLinkClickLogs = () => {
  const [result, setResults] = useState({});
  const [clickCount, setClickCount] = useState(0);

  const [clickLogs, setClickLogs] = useState();
  const [keywords, setKeywords] = useState();
  const [ignoreKeywords, setIgnoreKeywords] = useState();
  const [shopInfo, setShopInfo] = useState();
  const [nowHourN, setNowHourN] = useState(new Date().getHours());
  const [nowMinN, setNowMinN] = useState(new Date().getMinutes());
  const dispatch = useDispatch();
  const { requestLoadShopLinkLatelyClickLogsMutation } = useMutations(setResults);
  const { product_id = '9385611654' } = useParams();
  const slotSet = async ({ product_id, fields }) => {
    const result = await requestLoadSlotSet({
      product_id,
      fields,
    });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `정보가 변경되었습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    requestLoadShopLinkLatelyClickLogsMutation.mutate({ product_id });

    // await reloadSlotList();
  };
  useEffect(() => {
    const now = new Date(); // 현재 시간
    const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000; // 현재 시간을 utc로 변환한 밀리세컨드값
    const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
    const koreaNow = new Date(utcNow + koreaTimeDiff); // utc로 변환된 값을 한국 시간으로 변환시키기 위해 9시간(밀리세컨드)를 더함
    // 위 코드에서 koreaNow 는 사용자의 시간대가 어떻게 설정되어 있더라도 한국 시간을 보여주게 된다.

    setNowHourN(koreaNow.getHours());
    setNowMinN(koreaNow.getMinutes());
    if (result?.request === 'requestLoadShopLinkLatelyClickLogsMutation') {
      if (result?.success && result?.result !== undefined) {
        result?.shop_data?.map((e) => {});
        const data = result?.result;
        const click_logs = data?.click_logs;
        const shop_info = data?.shop_info;
        setShopInfo(shop_info);
        setClickLogs(click_logs);

        var list = shop_info?.keywords?.split(':')?.filter((e) => e !== '');
        if (!list) list = [];
        setKeywords(list);

        // var count = 0;
        // list?.forEach((e) => (count += e?.shop_click_count));
        // setClickCount(count);
      }
      setTimeout(() => {
        // requestLoadShopLinkLatelyClickLogsMutation.mutate({ vmware_names });
      }, 5000);
    }

    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadShopLinkLatelyClickLogsMutation.mutate({ product_id });
  }, []);
  return (
    <>
      <Box column top={30}>
        <Box column>
          <Box>
            [{shopInfo?.mall_name}] {shopInfo?.title}
          </Box>
          <Box>
            카테고리: {shopInfo?.category1} {'-> '}
            {shopInfo?.category2} {'-> '}
            {shopInfo?.category3} {'-> '}
            {shopInfo?.category4}
          </Box>
          <Box>[{shopInfo?.category3}]</Box>
          <Box column>
            {shopInfo?.category3_keywords?.map((e) => {
              return <KeywordText>{e}</KeywordText>;
            })}
          </Box>
          <Box>[{shopInfo?.category4}]</Box>
          <Box column>
            {shopInfo?.category4_keywords?.map((e) => {
              return <KeywordText>{e}</KeywordText>;
            })}
          </Box>
        </Box>
        <LabelBox label={'검색 제외 키워드'}>
          <Box width={700} column>
            <InputComponent
              fill
              // disabled={isDisableSlotGroupName}
              size={200}
              isEdit
              el="textarea"
              name="ignore_keywords"
              placeholder={''}
              value={ignoreKeywords?.join('\n')}
              onChange={(e) => {
                const list = e.target.value.split('\n');
                setIgnoreKeywords(list);
              }}
            />
            <Button
              onClick={() => {
                const list = ignoreKeywords.filter((e) => e !== '');
                // setKeywords(list);
                const ignore_keywords_str = list.join(':');
                const fields = {
                  ignore_keywords: ignore_keywords_str,
                };
                slotSet({ product_id, fields });
              }}
            >
              검색 제외 키워드 설정
            </Button>
          </Box>
        </LabelBox>
        <LabelBox label={'사용자 추가 키워드'}>
          <Box width={700} column>
            <InputComponent
              fill
              // disabled={isDisableSlotGroupName}
              size={200}
              isEdit
              el="textarea"
              name="keywords"
              placeholder={''}
              value={keywords?.join('\n')}
              onChange={(e) => {
                const list = e.target.value.split('\n');
                setKeywords(list);
              }}
            />
            <Button
              onClick={() => {
                const list = keywords.filter((e) => e !== '');
                // setKeywords(list);
                const keywords_str = list.join(':');
                const fields = {
                  keywords: keywords_str,
                };
                slotSet({ product_id, fields });
              }}
            >
              키워드 설정
            </Button>
          </Box>
        </LabelBox>
        {clickLogs?.map((i) => {
          return (
            <Box>
              {i?.click_mode && i?.vmware_name && (
                <StatusText>
                  <small>
                    [{i.click_mode}({i.click_mode_status})] {i.ip_country} {i.vmware_name}{' '}
                    {i.worker_login_naver_id_type}[{i.naver_id}]-
                    {i.worker_id}
                  </small>
                </StatusText>
              )}
              <Box>
                <StatusText>
                  <span>
                    {i.product_id} -{i.is_succeed ? 'ok' : 'fail'}{' '}
                    {i.search_keyword?.replaceAll(' ', '')}{' '}
                  </span>
                  - ({i.mall_name}) {i.worker_login_type} [{i.datetime.slice(11, 16)}]
                </StatusText>
              </Box>
            </Box>
          );
        })}
      </Box>
      {/* </Block> */}
    </>
  );
};
const KeywordText = styled.div`
  font-size: 10rem;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return `
      color: #fff;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 
      background-color: #bc340b;
          
      `;
  }}
`;
export default AnalyticsVmwareShopLinkClickLogs;
