import {
  requestLoadAddImpCid,
  requestLoadAddMd,
  requestLoadAddNlogid,
  requestLoadAddSc2,
} from 'Common/Service/common';
import useMutations from 'Common/Service/commonMutations';
import { Button, Grid, InputComponent } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { debounce } from 'Common/Utils/util';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';

const MdAdd = () => {
  const [result, setResults] = useState({});
  const [value, setValue] = useState();
  const [sc2Value, setSc2Value] = useState();
  const [nlogid, setNlogid] = useState();
  const [impCid, setImpCid] = useState();
  const [impCidContentVersion, setImpCidContentVersion] = useState();
  const [mdCounts, setMdCounts] = useState();
  const [sc2Counts, setSc2Counts] = useState();
  const [nlogCount, setNlogCount] = useState();
  const addMdDelay = useCallback(
    debounce(() => {
      addMd();
    }, 300),
    [],
  );
  const {
    requestLoadGetMdCountMutation,
    requestLoadGetSc2CountMutation,
    requestLoadGetNlogCountMutation,
  } = useMutations(setResults);
  useEffect(() => {
    addMd();
    addNlog();
    addImpCid();
    addSc2();
    if (result?.request === 'requestLoadGetMdCountMutation') {
      if (result?.success && result?.result !== undefined) {
        setMdCounts(result?.result);
      }
      setTimeout(() => {
        requestLoadGetMdCountMutation.mutate({});
      }, 1000);
    } else if (result?.request === 'requestLoadGetNlogCountMutation') {
      if (result?.success && result?.result !== undefined) {
        setNlogCount(result?.result);
      }
      setTimeout(() => {
        requestLoadGetNlogCountMutation.mutate({});
      }, 2000);
    } else if (result?.request === 'requestLoadGetSc2CountMutation') {
      if (result?.success && result?.result !== undefined) {
        setSc2Counts(result?.result);
      }
      setTimeout(() => {
        requestLoadGetSc2CountMutation.mutate({});
      }, 2000);
    }
    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadGetMdCountMutation.mutate({});
    setTimeout(() => {
      requestLoadGetNlogCountMutation.mutate({});
    }, 1000);
    setTimeout(() => {
      requestLoadGetSc2CountMutation.mutate({});
    }, 3000);
  }, []);
  const addImpCid = async () => {
    if (!impCid) return null;
    impCid?.replaceAll(' ', '');
    impCidContentVersion?.replaceAll(' ', '');

    if (impCid?.length >= 22 && impCidContentVersion?.length >= 5) {
      const value1 = impCid;
      const value2 = impCidContentVersion;
      setImpCid('');
      setImpCidContentVersion('');
      await requestLoadAddImpCid({ imp_cid: value1, content_version: value2 });
    }
  };

  const addNlog = async () => {
    if (!nlogid) return null;
    nlogid?.replaceAll(' ', '');
    if (nlogid?.startsWith('1i') && nlogid?.length === 12) {
      const value1 = nlogid;
      setNlogid('');
      await requestLoadAddNlogid({ nlogid: value1 });
    }
  };
  const addSc2 = async () => {
    var fields = {};
    const str = sc2Value;
    str?.split('\n')?.forEach((e) => {
      if (e.startsWith('st=')) {
        fields['st'] = e.replace('st=', '');
      } else if (e.startsWith('ct=')) {
        fields['ct'] = e.replace('ct=', '');
      } else if (e.startsWith('rt=')) {
        fields['rt'] = e.replace('rt=', '');
      } else if (e.startsWith('sz=')) {
        fields['sz'] = e.replace('sz=', '');
      } else if (e.startsWith('t0=')) {
        fields['t0'] = e.replace('t0=', '');
      } else if (e.startsWith('data=')) {
        fields['data'] = e.replace('data=', '');
      } else if (e.startsWith('prm=')) {
        fields['prm'] = e.replace('prm=', '');
      }
    });
    if (Object.keys(fields).length >= 4) {
      setSc2Value('');
      await requestLoadAddSc2(fields);
    }
  };
  const addMd = async () => {
    if (!value) return false;
    const list = value?.split('\n');
    var add_list = [];
    list?.forEach((full_line) => {
      const f_l = full_line.split('	');
      const full_url = f_l.find((e) => e.startsWith('http'));

      const l = full_line
        .replaceAll('	', '\n')
        ?.replaceAll('&', '\n')
        ?.replaceAll('?', '\n')
        ?.replaceAll(' ', '\n')
        ?.split('\n');
      var md;
      var msgpad;
      var url;
      l.forEach((s) => {
        if (s.indexOf('http') >= 0) {
          var n = s.indexOf('?');
          if (n >= 0) s = s.slice(0, n);
          url = s;
        }
        if (s.indexOf('msgpad=') >= 0) {
          var n = s.indexOf('=');
          if (n >= 0) s = s.slice(n + 1);
          msgpad = s;
        }
        if (s.indexOf('md=') >= 0) {
          var n = s.indexOf('=');
          if (n >= 0) s = s.slice(n + 1);
          md = s;
        }
      });
      if (url && md && msgpad) {
        add_list.push({ url, md, msgpad, full_url });
      }
    });
    if (add_list.length > 0) {
      setValue('');
      for (let value of add_list) {
        await requestLoadAddMd(value);
      }
    }
  };
  return (
    <>
      <Grid column={['1fr', '1fr']} gap={12}>
        <Box valign="top" column>
          <Box column>
            <Box gap={8}>
              <Box>
                <StatusText>nlog</StatusText>
              </Box>
              <Box>
                <StatusText>
                  <b>{nlogCount}</b>
                </StatusText>
              </Box>
            </Box>
            <Box gap={8}>
              <Box>
                <StatusText>sc2</StatusText>
              </Box>
              <Box>
                <StatusText>
                  <b>{sc2Counts}</b>
                </StatusText>
              </Box>
            </Box>
            {mdCounts?.map((e) => {
              return (
                <Box gap={8}>
                  <Box>
                    <StatusText>
                      {e?.url
                        ?.replaceAll('https://apis.naver.com/mobileapps/', '')
                        ?.replaceAll('https://inspector-collector.m.naver.com/', '')}
                    </StatusText>
                  </Box>
                  <Box>
                    <StatusText>
                      <b>{e.count}</b>
                    </StatusText>
                  </Box>
                  <Box column>
                    {e?.list?.map((i) => {
                      return (
                        <Box>
                          <Box>{i?.names_sort}</Box>
                          <Box>{i?.count}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
          {/* <LabelBox label={'imp'}>
            <Box>
              <InputComponent
                placeholder="imp_cids"
                value={impCid}
                onChange={(e) => {
                  setImpCid(e.target.value);
                }}
              />
              <InputComponent
                placeholder="contentVersion"
                value={impCidContentVersion}
                onChange={(e) => {
                  setImpCidContentVersion(e.target.value);
                }}
              />
            </Box>
          </LabelBox> */}
          <LabelBox label={'nlog'}>
            <InputComponent
              value={nlogid}
              onChange={(e) => {
                setNlogid(e.target.value);
              }}
            />
          </LabelBox>
          <LabelBox label={'md'}>
            <InputComponent
              //   autocomplete={'off'}
              size={200}
              isEdit
              el="textarea"
              //   underLine
              name="id"
              placeholder={''}
              value={value}
              onChange={(e) => {
                addMdDelay();
                setValue(e.target.value);
                addMd();
                // setProductIds(e.target.value);
              }}
              // onKeyUp={onKeyUp}
              //   required
            />
            <Box top={8}>
              <Button
                fill
                // disabled={!isCheckMdValue()}
                onClick={() => {
                  addMd();
                }}
              >
                Add
              </Button>
            </Box>
          </LabelBox>
          <LabelBox label={'sc2'}>
            <InputComponent
              //   autocomplete={'off'}
              size={200}
              isEdit
              el="textarea"
              //   underLine
              name="id"
              placeholder={''}
              value={sc2Value}
              onChange={(e) => {
                setSc2Value(e.target.value);
                addSc2();
                // setProductIds(e.target.value);
              }}
              // onKeyUp={onKeyUp}
              //   required
            />
            <Box top={8}>
              <Button
                fill
                // disabled={!isCheckMdValue()}
                onClick={() => {
                  addSc2();
                }}
              >
                Sc2 Add
              </Button>
            </Box>
          </LabelBox>
        </Box>
      </Grid>
    </>
  );
};
const SlotIdDiv = styled.div`
  display: flex;
  flex: 1;
  border: 1rem;
  font-size: 20rem;
  font-weight: 500;
  justify-content: center;
  border-style: solid;
  border-width: 1rem;
  align-items: center;
  background-color: #d5dee8;
  border-radius: 12rem;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  width: 100%;
  justify-content: space-between;
  color: #18795a;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 20rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { isError } = props;
    if (isError)
      return css`
        color: #f00000;
        border-width: 1rem;
        border-style: solid;
        border-color: var(--P_300);
      `;
  }}
`;
export default MdAdd;
