import React, { useEffect, useState } from 'react';
import { Box } from 'Common/UI/atoms';
import Modal from 'Common/UI/organisms/Modal/Modal';
import styled from 'styled-components';
const ProductRankModal = ({
  isActive,
  product_id,
  onSuccess,
  onClose,
  Icon,
  title = '상품 순위 변화',
  isRegistrationNo,
  cancelText = '닫기',
  successText = '',
}) => {
  const [value, setValue] = useState();
  useEffect(() => {
    setValue();
  }, [isActive]);
  const [isSuccess, setIsSuccess] = useState(!isRegistrationNo);
  useEffect(() => {
    if (value?.length === 7) setIsSuccess(true);
    else setIsSuccess(false);
  }, [value]);
  return (
    <Modal
      className={'modal'}
      size="sm"
      isActive={isActive}
      successText={successText}
      cancelText={cancelText}
      close={true}
      onClose={() => onClose()}
      onSuccess={
        isSuccess
          ? () => {
              onClose();
              onSuccess(value);
            }
          : null
      }
    >
      <Wrapper>
        <Box gap={12}>
          {Icon && <Icon size="sm" fill={'var(--P_500)'} />}
          <Title>{title}</Title>
        </Box>
        <Box top={29}>{product_id}</Box>
      </Wrapper>
    </Modal>
  );
};
const Title = styled.div`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 20rem;
  line-height: 172%;

  letter-spacing: -0.03em;
  color: var(--G_900);
`;
const Wrapper = styled.div`
  position: relative;
  transition: 0.3s;

  display: flex;
  flex-direction: column;
  border-radius: 8rem;
`;

ProductRankModal.defaultProps = {
  setting: () => {},
  status: {},
  onClose: () => {},
  onSuccess: () => {},
};

export default ProductRankModal;
