import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    ${({ size, theme: { modal } }) => {
        const modalSize = size ? `${modal.size[size]}rem` : "700rem";
        return css`
            width: ${modalSize};
        `;
    }}
    transition:width 0.5s;
    box-shadow: var(--Elevation_008);
    border-radius: 12rem;
    background-color: var(--White);
    p {
        &.gray {
            font-size: 14rem;
            color: var(--G_500);
        }
    }
`;
export const InnerBox = styled.div`
    ${({ padding }) => {
        return css`
            padding: ${padding ? padding : "32"}rem;
        `;
    }}
`;

export const Content = styled.div`
    ${({ background }) => {
        return css`
            ${background ? `background: ${background}` : ""}
        `;
    }}
`;

export const ConfirmModalText = styled.div`
    font-weight: ${(props) => (props.title ? "500" : "400")};
    font-size: ${(props) => (props.title ? "16rem" : "14rem")};
    line-height: 172%;
    letter-spacing: -0.03em;
    color: var(--${(props) => (props.title ? "G_800" : "G_500")});
    margin-bottom: ${(props) => (props.title ? "12rem" : "0")};
    span {
        color: var(--P_500);
    }
`;

export const Title = styled.div`
    display: flex;
    gap: 14rem;
    font-weight: 500;
    font-size: 20rem;
    line-height: 172%;
    letter-spacing: -0.03em;
    color: var(--G_900);
    .btnClose {
        margin-left: auto;
    }
    + .modalContent {
        margin-top: 28rem;
    }
`;
export const Sub = styled.div`
    margin-top: 8rem;
    font-weight: 400;
    font-size: 14rem;
    line-height: 172%;
    letter-spacing: -0.03em;
    color: var(--G_500);
    + .modalContent {
        margin-top: 28rem;
    }
    span {
        color: var(--P_500);
    }
`;

export const BottomGroup = styled.div`
    display: flex;
    height: 60rem;
    padding: 12rem 38rem;
    background-color: var(--G_25);
    border-radius: 0 0 12rem 12rem;
    .rightBtn {
        display: flex;
        gap: 52rem;
        margin-left: auto;
    }
    .dsec {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14rem;
        line-height: 172%;
        letter-spacing: -0.03em;
        color: var(--G_300);
    }
`;

export const CardWrapper = styled.div`
    display: flex;
    gap: 32rem;
`;

export const TotalSeach = styled.div`
    margin-bottom: 10rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14rem;
    line-height: 172%;
    letter-spacing: -0.03em;
    color: var(--G_500);
    span {
        margin: 0 4rem;
        color: var(--P_500);
    }
`;

export const Item = styled.button`
    display: flex;
    padding: 10rem 20rem;
    background: var(--White);
    box-shadow: var(--Elevation_001);
    border-radius: 8rem;
    p {
        ${({ theme: { ellipsis } }) => {
            return css`
                color: inherit;
                ${ellipsis(1)}
            `;
        }}
    }
    &:hover {
        background: var(--P_25);
        color: var(--P_500);
    }
    &:active {
        background: var(--P_60);
        color: var(--P_800);
    }
    &:focus {
        background: var(--P_50);
        color: var(--P_700);
    }
`;

export const ListWrapper = styled.div`
    .scroll {
        padding: 5rem 3rem;
    }
`;
