import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadSetBasicInfo,
  requestLoadWorkVmwareFInfos,
  requestLoadWorkVmwareGetCurrentCmdInfos,
  requestLoadWorkVmwareGetSetupInfos,
  requestLoadWorkVmwareSetCurrentCmdStatusOff,
  requestLoadWorkVmwareSetSetupInfos,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  get_time,
  reload_second,
  to_integer,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { Input } from 'Common/UI/atoms/Button';
import ToggleButton from 'Page/Module/CheckToggleButton';
import styled, { css } from 'styled-components';
import PercentModule from './Module/PercentModule';
import { divide, navigate_newtab } from 'Common/Utils';
import { onShowToast } from 'features/common/commonSlice';

const WorkVmwareCurrentCmdInfos = () => {
  const { workstation_name } = useParams();
  const [workstationInfos, setWorkstationInfos] = useState([]);
  const [cmdServerInfos, setCmdServerInfos] = useState({});
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [isProblemOnlyChk, setIsProblemOnlyChk] = useState(false);
  const [isCmdServerProblemOnlyChk, setIsCmdServerProblemOnlyChk] = useState(false);
  const [basicInfo, setBasicInfo] = useState({});
  const [limitEtcDo1Count, setLimitEtcDo1Count] = useState();
  const [overOrderAgoSec, setOverOrderAgoSec] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  const [apiId, setApiId] = useState();
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const getWorkInfos = async () => {
    const infos = await requestLoadWorkVmwareGetCurrentCmdInfos();
    console.log(infos);
    setWorkstationInfos(infos?.cmd_infos);
    setBasicInfo(infos?.basic_info);
    setCmdServerInfos(infos?.cmd_server_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Block>
      <Box>
        <CheckBox
          checked={isProblemOnlyChk}
          onChange={() => {
            setIsProblemOnlyChk(!isProblemOnlyChk);
          }}
        >
          문제 있는 CMD만 보기
        </CheckBox>
        <CheckBox
          checked={isCmdServerProblemOnlyChk}
          onChange={() => {
            setIsCmdServerProblemOnlyChk(!isCmdServerProblemOnlyChk);
          }}
        >
          문제 있는 CMD Server만 보기
        </CheckBox>
        <CheckBox
          checked={isShowDetail}
          onChange={() => {
            setIsShowDetail(!isShowDetail);
          }}
        >
          세부 정보 보기
        </CheckBox>

        <ToggleButton
          active={basicInfo?.use_all_vmware_chk}
          onClick={() => {
            const fields = {
              use_all_vmware_chk: !basicInfo?.use_all_vmware_chk,
            };

            basicInfo.use_all_vmware_chk = !basicInfo.use_all_vmware_chk;
            const result = requestLoadSetBasicInfo({ fields });
            if (result) {
              dispatch(
                onShowToast({
                  status: 'success',
                  message: `설정이 변경되었습니다.`,
                  cancelText: '',
                  onCancel: false,
                }),
              );
            } else {
              dispatch(
                onShowToast({
                  status: 'error',
                  message: `오류가 발생했습니다`,
                  cancelText: '',
                  onCancel: false,
                }),
              );
            }
            forceUpdate();
          }}
        >
          {basicInfo?.use_all_vmware_chk ? <>전체 가동중</> : <>전체 중지중</>}
        </ToggleButton>

        {count}
      </Box>
      <Box>
        전체 cmd 가동률 <TextSucceed>{workstationInfos?.active_cmd_count}</TextSucceed> /{' '}
        {workstationInfos?.cmd_count}
        <PercentModule
          value={workstationInfos?.active_cmd_count}
          valueMax={workstationInfos?.cmd_count}
        />
        {workstationInfos?.off_cmd_count > 0 ? (
          <>
            (Off: <TextFail>{workstationInfos?.off_cmd_count}</TextFail>개)
          </>
        ) : null}
        {workstationInfos?.warning_cmd_count > 0 ? (
          <>
            (이상징후: <TextWarning>{workstationInfos?.warning_cmd_count}</TextWarning>개)
          </>
        ) : null}
      </Box>
      <Box>
        전체 active cmd server 가동률{' '}
        <TextSucceed>{cmdServerInfos?.active_cmd_server_count}</TextSucceed> /{' '}
        {cmdServerInfos?.cmd_server_count}
        <PercentModule
          value={cmdServerInfos?.active_cmd_server_count}
          valueMax={cmdServerInfos?.cmd_server_count}
        />
        {cmdServerInfos?.off_cmd_server_count > 0 ? (
          <>
            (Off: <TextFail>{cmdServerInfos?.off_cmd_server_count}</TextFail>개)
          </>
        ) : null}
        {cmdServerInfos?.warning_cmd_server_count > 0 ? (
          <>
            (이상징후: <TextWarning>{cmdServerInfos?.warning_cmd_server_count}</TextWarning>개)
          </>
        ) : null}
        {cmdServerInfos?.start_over_1_day_cmd_server_count > 0 ? (
          <>
            (부팅한지 하루 경과:{' '}
            <TextWarning>{cmdServerInfos?.start_over_1_day_cmd_server_count}</TextWarning>개)
          </>
        ) : null}
      </Box>
      <Box>
        <Box>
          유입시도
          <Box width={80}>
            <InputComponent
              iconSize={10}
              fontSize={10}
              padding={[5, 15]}
              size={20}
              value={limitEtcDo1Count?.toString()}
              onChange={(e) => {
                setLimitEtcDo1Count(e?.target?.value);
                forceUpdate();
              }}
            />
          </Box>
          회 이하인것만 보기
        </Box>
        <Box>
          전체 시작후
          <Box width={80}>
            <InputComponent
              iconSize={10}
              fontSize={10}
              padding={[5, 15]}
              size={20}
              value={overOrderAgoSec?.toString()}
              onChange={(e) => {
                setOverOrderAgoSec(e?.target?.value);
                forceUpdate();
              }}
            />
          </Box>
          초 초과한것만 보기
        </Box>
      </Box>
      <Box>{workstation_name}</Box>
      {workstationInfos?.list
        ?.filter((workstation_info) => {
          const w_name = workstation_info?.workstation_name;
          if (workstation_name) {
            if (w_name !== workstation_name) return false;
          }
          const workstation_cmd_server_info = cmdServerInfos?.workstation_infos?.[w_name]?.[0];
          const cmd_problem_chk = workstation_info?.active_cmd_count < workstation_info?.cmd_count;
          const cmd_server_problem_chk =
            !workstation_cmd_server_info ||
            workstation_cmd_server_info?.off_cmd_server_count +
              workstation_cmd_server_info?.warning_cmd_server_count;

          var is_show_chk = true;
          if (isProblemOnlyChk && !cmd_problem_chk) return (is_show_chk = false);
          if (isCmdServerProblemOnlyChk && !cmd_server_problem_chk) return (is_show_chk = false);
          return is_show_chk;
          // isCmdServerProblemOnlyChk
        })
        ?.map((workstation_info, index) => {
          const w_name = workstation_info?.workstation_name;
          const workstation_cmd_server_info = cmdServerInfos?.workstation_infos?.[w_name]?.[0];
          // if (cmd_server_info) console.log(cmd_server_info);
          return (
            <Box index={index} column>
              <Box>
                <Box>
                  <TextButton
                    onClick={() => {
                      if (workstation_info?.workstation_name === workstation_name) {
                        navigate(`/cmd`);
                      } else {
                        navigate(`/cmd/${workstation_info?.workstation_name}`);
                      }
                    }}
                  >
                    <h2>{workstation_info?.workstation_name}</h2>
                  </TextButton>

                  <TextButton
                    onClick={() => {
                      navigate_newtab(`/vmware_count/${workstation_info?.workstation_name}`);
                    }}
                  >
                    <TextSmall>
                      <TextSucceed>통계 보기</TextSucceed>
                    </TextSmall>
                  </TextButton>
                </Box>
                <Box>
                  cmd server 가동률{' '}
                  <TextSucceed>{workstation_cmd_server_info?.active_cmd_server_count}</TextSucceed>{' '}
                  / {workstation_cmd_server_info?.cmd_server_count}
                  <PercentModule
                    value={workstation_cmd_server_info?.active_cmd_server_count}
                    valueMax={workstation_cmd_server_info?.cmd_server_count}
                  />
                  {workstation_cmd_server_info?.off_cmd_server_count > 0 ? (
                    <>
                      (Off: <TextFail>{workstation_cmd_server_info?.off_cmd_server_count}</TextFail>
                      개)
                    </>
                  ) : null}
                  {workstation_cmd_server_info?.warning_cmd_server_count > 0 ? (
                    <>
                      (이상징후:{' '}
                      <TextWarning>
                        {workstation_cmd_server_info?.warning_cmd_server_count}
                      </TextWarning>
                      개)
                    </>
                  ) : null}
                  {workstation_cmd_server_info?.start_over_1_day_cmd_server_count > 0 ? (
                    <>
                      (부팅한지 하루 경과:{' '}
                      <TextWarning>
                        {workstation_cmd_server_info?.start_over_1_day_cmd_server_count}
                      </TextWarning>
                      개)
                    </>
                  ) : null}
                </Box>
                <Box>
                  vmware: <TextSucceed>{workstation_info?.vmware_infos.length}</TextSucceed>
                </Box>
              </Box>
              <Box column left={10}>
                {workstation_info?.vmware_infos
                  ?.filter((vmware_info) => {
                    const v_name = vmware_info?.vmware_name;
                    const cmd_server_info =
                      workstation_cmd_server_info?.vmware_infos?.[v_name]?.[0];
                    const cmd_problem_chk = vmware_info?.active_cmd_count < vmware_info?.count;
                    const cmd_server_problem_chk =
                      !cmd_server_info || cmd_server_info?.warning_chk || cmd_server_info?.off_chk;

                    var is_show_chk = true;
                    if (isProblemOnlyChk && !cmd_problem_chk) return (is_show_chk = false);
                    if (isCmdServerProblemOnlyChk && !cmd_server_problem_chk)
                      return (is_show_chk = false);
                    return is_show_chk;
                  })
                  ?.map((vmware_info, index2) => {
                    const v_name = vmware_info?.vmware_name;
                    const cmd_server_info =
                      workstation_cmd_server_info?.vmware_infos?.[v_name]?.[0];
                    return (
                      <VmwareBlock
                        isWarning={cmd_server_info?.warning_chk}
                        isOff={cmd_server_info?.off_chk}
                        key={index2}
                        column
                      >
                        <Box column>
                          <Box>
                            <Box>{vmware_info?.vmware_name} </Box>
                            <Box>
                              (cmd server:
                              {cmd_server_info?.active_chk ? (
                                <TextSucceed>활성</TextSucceed>
                              ) : (
                                <></>
                              )}
                              {cmd_server_info?.warning_chk ? (
                                <TextWarning>체크필요</TextWarning>
                              ) : (
                                <></>
                              )}
                              {cmd_server_info?.off_chk ? <TextFail>Off</TextFail> : <></>} 최근:
                              <TextSucceed>
                                {cmd_server_info?.cmd_server_access_datetime_ago_sec}
                              </TextSucceed>
                              초 부팅후경과:
                              <TextSucceed>
                                {get_time(cmd_server_info?.cmd_server_start_datetime_ago_sec)}
                              </TextSucceed>
                              )
                            </Box>
                          </Box>
                          <Box>
                            cmd 가동: <TextSucceed>{vmware_info?.active_cmd_count}</TextSucceed>/
                            {vmware_info?.count}
                            <PercentModule
                              value={vmware_info?.active_cmd_count}
                              valueMax={vmware_info?.count}
                            />{' '}
                            {vmware_info?.off_cmd_count > 0 ? (
                              <>
                                (Off: <TextFail>{vmware_info?.off_cmd_count}</TextFail>개)
                              </>
                            ) : null}
                            {vmware_info?.warning_cmd_count > 0 ? (
                              <>
                                (이상징후:{' '}
                                <TextWarning>{vmware_info?.warning_cmd_count}</TextWarning>개)
                              </>
                            ) : null}
                            <Box left={300}>
                              <TextButton
                                onClick={async () => {
                                  const vmware_name = vmware_info?.vmware_name;
                                  const f_num = null;
                                  const result = await requestLoadWorkVmwareSetCurrentCmdStatusOff({
                                    vmware_name,
                                    f_num,
                                  });
                                  if (result) {
                                    dispatch(
                                      onShowToast({
                                        status: 'success',
                                        message: `${result}개의 설정이 변경되었습니다.`,
                                        cancelText: '',
                                        onCancel: false,
                                      }),
                                    );
                                  } else {
                                    dispatch(
                                      onShowToast({
                                        status: 'error',
                                        message: `오류가 발생했습니다`,
                                        cancelText: '',
                                        onCancel: false,
                                      }),
                                    );
                                  }
                                }}
                              >
                                <TextSmall>현황 삭제</TextSmall>
                              </TextButton>
                            </Box>
                          </Box>
                        </Box>
                        <Box column left={10}>
                          {vmware_info?.list
                            ?.filter((cmd_info) => {
                              var is_show_chk = true;
                              if (isProblemOnlyChk && cmd_info?.active_chk) is_show_chk = false;
                              if (
                                overOrderAgoSec &&
                                cmd_info?.order_cmd_datetime_by_cmd_server_ago_sec <
                                  parseInt(overOrderAgoSec)
                              )
                                is_show_chk = false;

                              if (
                                limitEtcDo1Count &&
                                cmd_info?.etc_do1_count >= parseInt(limitEtcDo1Count)
                              )
                                is_show_chk = false;

                              return is_show_chk;
                            })
                            ?.map((cmd_info, index3) => {
                              const start_status = cmd_info?.start_datetime_ago_sec
                                ? `가동 <font color='green'>${cmd_info?.start_datetime_ago_sec}</font>초`
                                : `시작 전`;
                              var error_status = cmd_info?.last_error_status;
                              const last_error_status = cmd_info?.last_error_status;
                              if (last_error_status === 'tonghap_search_error') {
                                error_status = '통합검색실패';
                              } else if (last_error_status === 'shop_search_error') {
                                error_status = '쇼핑검색실패';
                              } else if (last_error_status === 'get_foryou_url_error') {
                                error_status = '유입 제제';
                              } else if (last_error_status === 'get_foryou_ce_list_error') {
                                error_status = '유입 활동';
                              } else if (last_error_status === 'get_foryou_ce_list_error') {
                                error_status = '상품 진입';
                              } else if (last_error_status === 'network_click_error') {
                                error_status = '상품 클릭';
                              } else if (last_error_status === 'timeout') {
                                error_status = '시간 초과';
                              } else if (last_error_status === 'into_product_error') {
                                error_status = '상품 진입 실패';
                              } else if (last_error_status === 'reload_product_error') {
                                error_status = '상품 진입후 재시도 실패';
                              } else if (last_error_status === 'network_click_foryou_error') {
                                error_status = '관심 물품 클릭 실패';
                              } else if (!last_error_status) {
                                // error_status = '원인 불명';
                              }
                              return (
                                <CmdBlock
                                  key={index3}
                                  isOff={cmd_info?.off_chk}
                                  isWarning={cmd_info?.warning_chk}
                                >
                                  <Box>
                                    {cmd_info?.off_chk ? (
                                      <Box>
                                        <TextButton
                                          onClick={async () => {
                                            const vmware_name = vmware_info?.vmware_name;
                                            const f_num = cmd_info?.f_num;
                                            const result =
                                              await requestLoadWorkVmwareSetCurrentCmdStatusOff({
                                                vmware_name,
                                                f_num,
                                              });
                                            if (result) {
                                              dispatch(
                                                onShowToast({
                                                  status: 'success',
                                                  message: `${result}개의 설정이 변경되었습니다.`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            } else {
                                              dispatch(
                                                onShowToast({
                                                  status: 'error',
                                                  message: `오류가 발생했습니다`,
                                                  cancelText: '',
                                                  onCancel: false,
                                                }),
                                              );
                                            }
                                          }}
                                        >
                                          <TextSmall>현황 삭제</TextSmall>
                                        </TextButton>
                                      </Box>
                                    ) : null}
                                    <Box>
                                      [<font color="blue">{cmd_info?.pyproxy_info_country}</font>]
                                    </Box>
                                    <CmdStatusBlock>
                                      {cmd_info?.access_datetime_ago_sec > 200 ? (
                                        <>
                                          <TextFail>{cmd_info?.access_datetime_ago_sec}</TextFail>
                                        </>
                                      ) : cmd_info?.access_datetime_ago_sec > 120 ? (
                                        <>
                                          <TextWarning>
                                            {cmd_info?.access_datetime_ago_sec}
                                          </TextWarning>
                                        </>
                                      ) : (
                                        <>
                                          <TextSucceed>
                                            {cmd_info?.access_datetime_ago_sec}
                                          </TextSucceed>
                                        </>
                                      )}
                                      초
                                    </CmdStatusBlock>
                                    <CmdStatusBlock>
                                      진행:{' '}
                                      <TextSucceed>
                                        {cmd_info?.to_etc_do1_ok_count_hour}
                                      </TextSucceed>
                                      /{cmd_info?.to_etc_do1_access_count_hour}{' '}
                                      <PercentModule
                                        value={cmd_info?.to_etc_do1_ok_count_hour}
                                        valueMax={cmd_info?.to_etc_do1_access_count_hour}
                                      />
                                      (전체:
                                      <TextSucceed>{cmd_info?.to_etc_do1_ok_count}</TextSucceed>/
                                      {cmd_info?.to_etc_do1_access_count}{' '}
                                      <PercentModule
                                        value={cmd_info?.to_etc_do1_ok_count}
                                        valueMax={cmd_info?.to_etc_do1_access_count}
                                      />
                                      )
                                    </CmdStatusBlock>
                                    <Box>num:{cmd_info?.f_num}</Box>

                                    {cmd_info?.etc_do1_count > 0 ? (
                                      <CmdStatusBlock>
                                        <TextSucceed>
                                          진행{cmd_info?.etc_do1_count}회 - {cmd_info?.step_logs_n}
                                          단계
                                        </TextSucceed>
                                      </CmdStatusBlock>
                                    ) : null}
                                    {cmd_info?.calc_network_error_etc_do1_problem_count ? (
                                      <CmdStatusBlock>
                                        제제발견:
                                        <TextFail>
                                          {cmd_info?.calc_network_error_etc_do1_problem_count}
                                        </TextFail>
                                        회(
                                        <TextFail>
                                          {cmd_info?.calc_network_error_etc_do1_problem_using_sec}
                                        </TextFail>
                                        )초
                                      </CmdStatusBlock>
                                    ) : null}
                                    <CmdStatusBlock>
                                      <h3> {cmd_info?.access_type}</h3>
                                    </CmdStatusBlock>
                                    <CmdStatusBlock>
                                      시작:{' '}
                                      <TextSucceed>
                                        {cmd_info?.order_cmd_datetime_by_cmd_server_ago_sec}
                                      </TextSucceed>
                                      초
                                    </CmdStatusBlock>
                                    <CmdStatusBlock>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: start_status,
                                        }}
                                      />
                                    </CmdStatusBlock>
                                    <CmdStatusBlock>
                                      {error_status ? (
                                        <TextFail>최근에러:{error_status}</TextFail>
                                      ) : null}
                                    </CmdStatusBlock>
                                    <Box width={900}>{cmd_info?.step_logs}</Box>
                                  </Box>
                                  {isShowDetail && (
                                    <Box column left={10}>
                                      <Box>비정상종료횟수:{cmd_info?.check_idle_error_count}</Box>
                                      <Box>
                                        비정상종료시간합산:{cmd_info?.check_idle_error_sec_sum}초
                                      </Box>
                                      <Box>할당된나라:{cmd_info?.proxy_country}</Box>
                                      <Box>아이피:{cmd_info?.proxy_api_ip}</Box>

                                      <Box>총 가동시간: {cmd_info?.check_using_sec_sum}</Box>
                                      <Box>
                                        정상완료종료횟수: {cmd_info?.check_last_done_chk_count}
                                      </Box>
                                      <Box>
                                        총 가동시간[대기시간제외]: {cmd_info?.check_using_sec_sum}초
                                      </Box>

                                      <Box>시작횟수:{cmd_info?.cmd_start_count}</Box>
                                      {/* <Box>비정상종료시간합산:{cmd_info?.check_idle_error_sec_sum}초</Box> */}

                                      <Box>명령횟수:{cmd_info?.check_order_cmd_start_count}회</Box>
                                      <Box>
                                        명령과 명령사이 간격:
                                        {divide(
                                          cmd_info?.check_order_cmd_start_gap_wait_sec_sum,
                                          cmd_info?.check_order_cmd_start_count,
                                        )}
                                        초
                                      </Box>
                                      <Box>대기시간합:{cmd_info?.check_wait_status_sec_sum}초</Box>
                                      <Box>
                                        {cmd_info?.check_wait_status_sec ? (
                                          <>대기중 {cmd_info?.check_wait_status_sec}</>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                      <Box>{cmd_info?.last_result_status}</Box>
                                    </Box>
                                  )}
                                </CmdBlock>
                              );
                            })}
                        </Box>
                      </VmwareBlock>
                    );
                  })}
              </Box>
            </Box>
          );
        })}
    </Block>
  );
};
const CmdStatusBlock = styled.div`
  display: flex;
  /* max-width: 400rem; */
  white-space: nowrap;

  /* background-color: red; */
  /* ${(props) => {
    const { mwidth } = props;
    return css`
      max-width: ${mwidth}rem;
    `;
  }} */
`;
const SetupBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  /* gap: 10rem; */
`;
const HelpStatus = styled.div`
  font-size: 11rem;
  font-weight: 400;
  color: #0000a5;
`;
const SetupInfoDefaultBlock = styled.div`
  padding: 10rem 0rem 10rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  margin-bottom: 10rem;

  border-width: 1rem;
  border-style: solid;
  /* border-color: transparent; */
  /* &:hover { */
  /* background-color: #f0f0f0; */
  border-color: blue;
  /* } */
`;
const CmdBlock = styled.div`
  transition: all 0.2s;
  &:hover {
    background-color: #a0f0f0;
  }
  ${(props) => {
    const { isWarning, isOff, isActive } = props;
    if (isOff)
      return css`
        background-color: #ffd0d0;
      `;
    if (isWarning)
      return css`
        background-color: #f0f0d0;
      `;
  }}
`;
const VmwareBlock = styled.div`
  padding: 10rem 0rem 0rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  &:hover {
    background-color: #f0f0f0;
    border-color: blue;
  }
  ${(props) => {
    const { isWarning, isOff, isActive } = props;
    if (isOff)
      return css`
        background-color: #f0d0d0;
      `;
    if (isWarning)
      return css`
        background-color: #f0f0d0;
      `;
  }}
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  font-size: 11rem;
  font-weight: 500;
  width: 100%;
  overflow: auto;
  h3 {
    font-size: 11rem;
    color: #0808a8;
  }

  h1 {
    color: #f00000;
    font-weight: 600;
  }
  h2 {
    margin-right: 10rem;
    color: blue;
    font-size: 18rem;
    font-weight: 600;
  }
  b {
    margin-right: 10rem;
    color: #1010a0;
    font-size: 16rem;
    font-weight: 600;
  }
`;

export default WorkVmwareCurrentCmdInfos;
