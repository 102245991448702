import { Badge, Box, Button, CheckBox, InputComponent } from 'Common/UI/atoms';
import { Table, Tbody, THeader } from 'Common/UI/organisms/FormTabel/styeld';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import useMutations from 'Common/Service/slotMutations';
import {
  requestLoadPaymentGetPaidSlots,
  requestLoadSlotCancelDelete,
  requestLoadSlotCancelPaid,
} from 'Common/Service/slot';
import { onShowToast } from 'features/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import Highlight from 'react-highlighter';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { slotStatus, useForceUpdate } from 'Common/Utils/util';
import PaginationBox from 'Page/Module/PaginationBox';

function PaymentLog({ reloadStatus, partner_name, start_date, end_date }) {
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);

  const [allChk, setAllChk] = useState(false);

  const [checkList, setCheckList] = useState([]);
  const [checkDeleteList, setCheckDeleteList] = useState([]);
  const [checkPaidList, setCheckPaidList] = useState([]);
  const checkTarget = useRef();

  const [result, setResults] = useState({});
  const forceUpdate = useForceUpdate();
  const { loginInfo } = useSelector((state) => state.common);
  const [searchWord, setSearchWord] = useState('');
  const [nowHourN, setNowHourN] = useState(new Date().getHours());
  const { requestLoadPaymentGetPaidSlotsMutation } = useMutations(setResults);
  const [allPaidIdList, setAllPaidIdList] = useState([]);
  const [allDeleteIdList, setAllDeleteIdList] = useState([]);
  const [modal, setModal] = useState({
    title: '',
    successText: '삭제',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, isActive: false });
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const delete_list = [];
    const paid_list = [];
    allList?.forEach((e) => {
      if (e.delete_chk) delete_list.push(e.id);
      if (e.paid_chk) paid_list.push(e.id);
    });
    setAllDeleteIdList(delete_list);
    setAllPaidIdList(paid_list);
  }, [allList]);

  useEffect(() => {
    if (result?.request === 'requestLoadPaymentGetPaidSlotsMutation') {
      if (result?.success && result?.result !== undefined) {
        setAllList(result?.result);
      }
    }

    setResults({});
  }, [result.request]);

  useEffect(() => {
    requestLoadPaymentGetPaidSlotsMutation.mutate({ partner_name, end_date, start_date });
  }, [partner_name, start_date, end_date]);
  const reloadPayments = async () => {
    const result = await requestLoadPaymentGetPaidSlots({ partner_name, end_date, start_date });
    setAllList(result);
  };
  const slotCancelDelete = async ({ ids }) => {
    const result = await requestLoadSlotCancelDelete({ ids });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `슬롯 발급 취소 철회가 완료되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
      reloadStatus && reloadStatus();
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    await reloadPayments();
    setCheckList([]);
    setCheckDeleteList([]);
    setCheckPaidList([]);
  };

  const slotCancelPaid = async ({ ids }) => {
    const result = await requestLoadSlotCancelPaid({ ids });
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `정산 취소가 완료되었습니다.`,
          cancelText: '',
          onCancel: false,
        }),
      );
      reloadStatus && reloadStatus();
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    await reloadPayments();
    setCheckList([]);
    setCheckDeleteList([]);
    setCheckPaidList([]);
  };
  if (loginInfo?.user_level <= 2) return;
  return (
    <Box column>
      <Box gap={10} top={8} bottom={8}>
        <Box>
          <Box>
            {loginInfo?.user_level >= 4 ? (
              <Button
                size={'sm'}
                disabled={!checkDeleteList?.length}
                onClick={() => {
                  setModal({
                    ...modal,
                    title: '발급 취소 철회',
                    text: `[${checkDeleteList?.length}] 개의 슬롯 발급 취소를 철회시키겠습니까?`,
                    successText: '발급 취소 철회',
                    isActive: true,
                    cancelText: '취소',
                    onSuccess: () => {
                      const ids = checkDeleteList;
                      slotCancelDelete({ ids });
                      setAllChk(false);
                      setCheckList([]);
                      setCheckDeleteList([]);
                      setCheckPaidList([]);
                    },
                  });
                }}
              >
                발급 취소 철회
              </Button>
            ) : null}
          </Box>
          <Box>
            {loginInfo?.user_level >= 4 ? (
              <Button
                size={'sm'}
                disabled={!checkPaidList?.length}
                onClick={() => {
                  setModal({
                    ...modal,
                    title: '정산 취소',
                    text: `[${checkPaidList?.length}] 개의 슬롯 정산을 취소시키겠습니까?`,
                    successText: '정산 취소',
                    isActive: true,
                    cancelText: '취소',
                    onSuccess: () => {
                      const ids = checkPaidList;
                      slotCancelPaid({ ids });
                      setAllChk(false);
                      setCheckList([]);
                      setCheckDeleteList([]);
                      setCheckPaidList([]);
                    },
                  });
                }}
              >
                정산 취소
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
      <TableComponent
        onSearch={null}
        isController={false}
        allCheckbox={false}
        isInterval={false}
        searchText={null}
      >
        <LabelBox row label="검색">
          <Box fill top={10}>
            <InputComponent
              value={searchWord}
              onChange={(e) => {
                setSearchWord(e.target.value?.trim());
              }}
            />
          </Box>
        </LabelBox>
        <Box top={8} />
        <PaginationBox
          checkList={checkList}
          setCheckList={setCheckList}
          setList={setList}
          allList={allList}
        />

        <Table>
          <colgroup>
            {/* 체크박스 */}
            {loginInfo?.user_level >= 4 && <col style={{ width: '50rem' }} />}
            {/* 발급번호 */}
            <col style={{ width: '70rem' }} />
            {/* 분류 */}
            <col style={{ width: '130rem' }} />
            {/* 발급일시 */}
            <col style={{ width: '120rem' }} />
            {/* 유저 */}
            <col style={{ width: '100rem' }} />
            {/* 발급자 */}
            <col style={{ width: '100rem' }} />
            {/* 묶움nvmid */}
            {/* <col style={{ width: 'auto' }} /> */}
            {/* nvmid */}
            <col style={{ width: 'auto' }} />
            {/* 쇼핑몰 */}
            <col style={{ width: 'auto' }} />
            {/* 정산일수 */}
            {/* <col style={{ width: '150rem' }} /> */}
            {/* 종류 */}
            <col style={{ width: 'auto' }} />
            {/* AS연장일 */}
            {/* <col style={{ width: 'auto' }} /> */}
            {/* 기간 */}
            <col style={{ width: 'auto' }} />
            {/* 상태 */}
            <col style={{ width: 'auto' }} />
          </colgroup>
          <THeader>
            <tr>
              {loginInfo?.user_level >= 4 && (
                <th>
                  <CheckBox
                    checked={allChk}
                    onChange={(e) => {
                      setAllChk(e.target.checked);
                      if (e.target.checked) {
                        const list = [];
                        const delete_list = [];
                        const paid_list = [];
                        checkTarget.current
                          .querySelectorAll('input.checkBox[type="checkbox"]')
                          .forEach((i) => {
                            list.push(parseInt(i.name));
                          });
                        setCheckList(list);
                        setCheckDeleteList(allDeleteIdList);
                        setCheckPaidList(allPaidIdList);
                      } else {
                        setCheckList([]);
                        setCheckDeleteList([]);
                        setCheckPaidList([]);
                      }
                      forceUpdate();
                    }}
                  />
                </th>
              )}
              <th rowSpan={'1'}>발급번호</th>
              <th rowSpan={'1'}>분류</th>
              <th rowSpan={'1'}>발급일시</th>
              <th rowSpan={'1'}>유저</th>
              <th rowSpan={'1'}>발급자</th>
              {/* <th rowSpan={'1'}>묶음nvmid</th> */}
              <th rowSpan={'1'}>nvmid</th>
              <th rowSpan={'1'}>쇼핑몰</th>
              {/* <th rowSpan={'1'}>정산일수</th> */}
              {/* <th rowSpan={'1'}>총 연장일</th> */}
              <th rowSpan={'1'}>종류</th>
              <th rowSpan={'1'}>기간</th>
              {/* <th rowSpan={'1'}>총 AS 연장일</th> */}
              <th rowSpan={'1'}>상태</th>
            </tr>
          </THeader>
          <Tbody ref={checkTarget}>
            {list?.length === 0 ? (
              <tr>
                <td colSpan="11">&nbsp;</td>
              </tr>
            ) : (
              <>
                {list
                  ?.filter((e) => {
                    if (searchWord === '') return true;
                    const status = slotStatus(e);
                    const date = e?.issue_datetime?.slice(5, 16);
                    const start_date = e?.start_date?.slice(5);
                    const end_date = e?.end_date?.slice(5);

                    if (e.id?.toString()?.indexOf(searchWord) >= 0) return true;
                    if (e.user_name?.indexOf(searchWord) >= 0) return true;
                    if (e.slot_type_text?.indexOf(searchWord) >= 0) return true;
                    if (e.add_user_name?.indexOf(searchWord) >= 0) return true;
                    if (e.group_search_nvmid?.indexOf(searchWord) >= 0) return true;
                    if (e.nvmid?.indexOf(searchWord) >= 0) return true;
                    if (e.issue_type?.indexOf(searchWord) >= 0) return true;
                    if (e.slot_type?.indexOf(searchWord) >= 0) return true;
                    if (e.partner_name?.indexOf(searchWord) >= 0) return true;
                    if (e.mall_name?.indexOf(searchWord) >= 0) return true;
                    if (status?.indexOf(searchWord) >= 0) return true;

                    if (date?.indexOf(searchWord) >= 0) return true;

                    if (start_date?.indexOf(searchWord) >= 0) return true;
                    if (end_date?.indexOf(searchWord) >= 0) return true;
                    return false;
                  })
                  .map((i, idx) => {
                    const status = slotStatus(i);
                    var issue_status = '';
                    if (i.delete_chk) issue_status = `발급 취소 [${i?.delete_date}]`;
                    if (i.paid_chk) issue_status = `정산 완료 [${i?.paid_date}]`;

                    return (
                      <React.Fragment key={idx}>
                        <TrBlock>
                          {loginInfo?.user_level >= 4 && (
                            <td rowSpan={'1'}>
                              <CheckBox
                                name={i.id}
                                checked={checkList.includes(i?.id)}
                                onChange={(c) => {
                                  if (c?.target.checked) {
                                    if (!checkList.includes(i?.id)) {
                                      checkList.push(i?.id);
                                      setCheckList(checkList);
                                    }
                                    if (i?.delete_chk) {
                                      if (!checkDeleteList.includes(i?.id)) {
                                        checkDeleteList.push(i?.id);
                                        setCheckDeleteList(checkDeleteList);
                                      }
                                    }
                                    if (i?.paid_chk) {
                                      if (!checkPaidList.includes(i?.id)) {
                                        checkPaidList.push(i?.id);
                                        setCheckPaidList(checkPaidList);
                                      }
                                    }

                                    forceUpdate();
                                  } else {
                                    const list = checkList.filter((x) => x !== i?.id);
                                    setCheckList(list);

                                    const delete_list = checkDeleteList.filter((x) => x !== i?.id);
                                    setCheckDeleteList(delete_list);

                                    const paid_list = checkPaidList.filter((x) => x !== i?.id);
                                    setCheckPaidList(paid_list);
                                  }
                                }}
                              />
                            </td>
                          )}
                          <td rowSpan={'1'}>{i?.id?.toString()}</td>
                          <td rowSpan={'1'}>{i.slot_type_text}</td>
                          <td rowSpan={'1'}>{i.issue_datetime?.slice(5, 16)}</td>
                          <td rowSpan={'1'}>
                            <Highlight search={searchWord}>{i?.user_name}</Highlight>
                          </td>
                          <td rowSpan={'1'}>{i?.add_user_name}</td>
                          {/* <td rowSpan={'1'}>{i.group_search_nvmid}</td> */}
                          <td rowSpan={'1'}>{i.nvmid}</td>
                          <td rowSpan={'1'}>
                            <StatusTitleText>
                              {i.smartstore_chk === false ? <small>[오픈마켓]</small> : null}
                              {i.adult_chk ? <small>[성인인증상품]</small> : null}
                              <h1>
                                <Highlight search={searchWord}>{i.mall_name?.toString()}</Highlight>
                              </h1>
                            </StatusTitleText>
                          </td>
                          <td rowSpan={'1'}>{i.issue_type_text}</td>
                          <td rowSpan={'1'}>
                            {i.days}일 [{i.start_date?.slice(5)} ~ {i.end_date?.slice(5)}]
                          </td>
                          {/* <td rowSpan={'1'}>{i.extend_days}</td> */}
                          {/* <td rowSpan={'1'}>{i.as_extend_days}</td> */}
                          <td rowSpan={'1'}>
                            <Box column>
                              {/* {status} */}
                              {issue_status}
                              {/* <br />[{i.paid_datetime?.slice(5, 16)}] */}
                            </Box>
                          </td>
                        </TrBlock>
                      </React.Fragment>
                    );
                  })}
              </>
            )}
          </Tbody>
        </Table>
      </TableComponent>
      <ModalQuestion {...modal} />
    </Box>
  );
}

const TrBlock = styled.tr`
  &:hover {
    background-color: #f0f0f0;
  }
`;
const ButtonBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-height: 50rem;
  padding: 5rem;
  padding-left: 10rem;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const StatusTitleText = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  /* text-align: left; */

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  h1 {
    font-size: 13rem;
    /* line-height: 9rem; */
    font-weight: 500;
    color: #4879ba;
  }
  small {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #f00000;
  }
`;
export default PaymentLog;
