import { requestLoadAddStore } from 'Common/Service/common';
import useMutations from 'Common/Service/commonMutations';
import { Button, Grid, InputComponent } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const StoreAdd = () => {
  const use_type = 'in_shop';
  const [result, setResults] = useState({});
  const [value, setValue] = useState();
  const [workStoreInfos, setWorkStoreInfos] = useState();

  const { requestLoadGetWorkStoreInfoMutation } = useMutations(setResults);
  useEffect(() => {
    if (result?.request === 'requestLoadGetWorkStoreInfoMutation') {
      setWorkStoreInfos(result?.result);
    }
    setResults({});
  }, [result.request]);
  useEffect(() => {
    requestLoadGetWorkStoreInfoMutation.mutate({ use_type });
  }, []);

  const addMd = async () => {
    if (!value) return false;
    const list = value?.split('\n\n');
    if (list.length != 2) return;
    const headers = list[0].split('\n');
    const str = list[1];
    const j = JSON.parse(str);

    const constructor_src = j['constructor']['src'];
    const v_list = constructor_src.split('/');
    if (v_list[6].length != 14) return;

    const version = v_list[6];
    const project_name = j['projectName'];
    const project_version = j['projectVersion'];
    const id_value = j['id'];
    const os = j['os'];
    const os_version = j['osVersion'];
    const browser = j['browser'];
    const browser_version = j['browserVersion'];
    const device = j['device'];
    const type = j['type'];
    const module_src = j['module']['src'];
    const fields = {
      use_type,
      headers,
      version,
      project_name,
      project_version,
      id_value,
      os,
      os_version,
      browser,
      browser_version,
      device,
      type,
      module_src,
      constructor_src,
    };
    setValue(null);
    const result = await requestLoadAddStore(fields);
    if (result) {
      requestLoadGetWorkStoreInfoMutation.mutate({ use_type });
    }
  };
  return (
    <>
      <Grid column={['1fr', '1fr']} gap={12}>
        <Box valign="top" column>
          <Box column>
            {workStoreInfos?.map((e, i) => {
              return (
                <Box gap={8} key={i}>
                  <Box>
                    <StatusText>
                      <b>{e.ago_min}</b> min ago [{e?.datetime}]
                    </StatusText>
                  </Box>
                  <Box>
                    <StatusText>{e?.version}</StatusText>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <LabelBox label={'_store'}>
            <InputComponent
              size={400}
              isEdit
              el="textarea"
              name="id"
              placeholder={''}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <Box top={8}>
              <Button
                fill
                onClick={() => {
                  addMd();
                }}
              >
                Add
              </Button>
            </Box>
          </LabelBox>
        </Box>
      </Grid>
    </>
  );
};

const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  width: 100%;
  justify-content: space-between;
  color: #18795a;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 20rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { isError } = props;
    if (isError)
      return css`
        color: #f00000;
        border-width: 1rem;
        border-style: solid;
        border-color: var(--P_300);
      `;
  }}
`;
export default StoreAdd;
