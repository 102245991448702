import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SideMenuNavi = ({
  to,
  disabled,
  onClick,
  fontSize = null,
  onClose,
  children,
  isActive,
  height = 80,
  space,
}) => {
  const naviate = useNavigate();
  return (
    <Wrapper
      fontSize={fontSize}
      space={space}
      disabled={disabled}
      isActive={isActive}
      height={height}
      onClick={
        // to
        //   ? () => {
        //       naviate(to);
        //       onClose();
        //     }
        //   :
        onClick //# || undefined
      }
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  ${(props) => {
    const { height, disabled, isActive, space, fontSize } = props;
    return `
        
        color:${isActive ? 'var(--P_500)' : disabled ? 'var(--G_200)' : 'var(--G_600)'};
        font-size:${fontSize ? '14' : fontSize}rem;
        transition:0.3s;
        
        width:100%;
        height:${height ? height : 80}rem;
        font-weight:400;
        border-radius:8rem;
        display:flex;
        flex-direction:column;
        align-items:${space ? 'center' : 'flex-start'};
        justify-content:center;
        svg{
            color:inherit;
        }
        padding:0 ${space ? 0 : 24}rem;
        ${
          isActive
            ? 'background-color:var(--P_75);'
            : disabled
            ? `
            cursor: no-drop;
            `
            : `;
        &:hover{
            color:var(--White);
            background-color:var(--P_500);
        }
        `
        }
    `;
  }}
`;

export default SideMenuNavi;
