import React, { useRef, useState } from "react";
import DatepickerHeader from "./components/DatepickerHeader";
import DatepickerHeaderMonth from "./components/DatepickerHeaderMonth";
import DatepickerMutiple from "./components/DatepickerMutiple";

function MultipleDatepickerCompoent({ ...props }) {
    const DateHeader = ({ ...props }) => (
        <DatepickerHeader {...props}>
            <DatepickerHeaderMonth {...props} />
        </DatepickerHeader>
    );
    return (
        <DatepickerMutiple
            DateHeader={DateHeader}
            type="year"
            showMonthYearPicker={false}
            {...props}
        />
    );
}

export default MultipleDatepickerCompoent;
