import useMutations from 'Common/Service/commonMutations';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import useTab from 'Common/Hooks/useTab';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import ManageVmwareWorkstation from './ManageVmwaresWorkstation';
import ManageDefault from './ManageDefault';
import ManageShopMallSetting from './ManageShopMallSetting';
import { sortSet } from 'Common/Utils';

const ManageVmwares = () => {
  const location = useLocation();
  const { current, onClickLocation } = useTab(location.pathname, true);
  const [result, setResults] = useState({});
  const [etcStatus, setEtcStatus] = useState();

  const [workstationInfos, setWorkstationInfos] = useState([]);
  // const changeFieldChange = (fields) => {
  //   requestLoadVmwareSetApiInfoMutation.mutate({
  //     fields,
  //   });
  // };
  const { requestLoadVmwareGetApiInfoMutation, requestLoadEtcStatusGetApiInfoMutation } =
    useMutations(setResults);
  useEffect(() => {
    if (result?.request === 'requestLoadEtcStatusGetApiInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        setEtcStatus(result?.result);
      }
      setTimeout(() => {
        if (location.pathname === '/manage/vmwares/default') {
          requestLoadEtcStatusGetApiInfoMutation.mutate({});
        }
      }, 10000);
    } else if (result?.request === 'requestLoadVmwareGetApiInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        const list = result?.result?.workstation_infos;

        setWorkstationInfos([
          // { workstation_name: 'shop_mall' },
          { workstation_name: 'default' },
          { workstation_name: 'z_all' },
          ...list,
        ]);
      }
    }
    setResults({});
  }, [result.request]);

  useEffect(() => {
    requestLoadVmwareGetApiInfoMutation.mutate({});
  }, []);
  useEffect(() => {
    if (location.pathname === '/manage/vmwares/default') {
      requestLoadEtcStatusGetApiInfoMutation.mutate({});
    }
  }, [location.pathname]);
  return (
    <>
      <TabComponent
        current={current}
        onClick={onClickLocation}
        items={workstationInfos
          .sort((a, b) => sortSet(a.workstation_name, b.workstation_name))
          .filter((e) => e.workstation_name)
          .map((e) => {
            var title = e.workstation_name;

            if (title === 'shop_mall') title = '쇼핑몰 설정';
            else if (title === 'default') title = '기본 세팅';
            else if (title === 'z_all') title = '전체';
            return {
              title: title,
              value: `/manage/vmwares/${e.workstation_name}`,
            };
          })}
      />
      <Routes>
        <Route path={'/shop_mall'} element={<ManageShopMallSetting />} />
        <Route path={'/default'} element={<ManageDefault etcStatus={etcStatus} />} />
        <Route path={'/'} element={<ManageVmwareWorkstation />} />
        <Route path={'/:workstation_name'} element={<ManageVmwareWorkstation />} />
      </Routes>
    </>
  );
};
export default ManageVmwares;
