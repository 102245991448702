import Title from 'Common/UI/atoms/Title';
import DetailInfoTextBox from 'Common/UI/molecules/DetailInfoTextBox';
import React from 'react';
import styled from 'styled-components';

const MainViewSchedule = ({ isComplete, title, list, onClick }) => {
  return (
    <Wrapper onClick={onClick} isComplete={isComplete}>
      <Title size="xxs" color="G_800">
        {title}
      </Title>
      {list?.map((e, key) => (
        <DetailInfoTextBox
          key={key}
          option={{
            title: e?.title,
            value: e?.value,
          }}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ isComplete }) => `
    background-color:var(--G_20);
    display:flex;
    flex-direction:column;
    gap:4rem;
    padding:12rem 20rem;
    transition:opacity:0.3s;
    border-radius:12rem;
    ${isComplete ? 'opacity:0.6;' : ''}
`}
`;

export default MainViewSchedule;
