import useTab from 'Common/Hooks/useTab';
import { Box, Grid } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MdAdd from 'Page/Dev/MdAdd';
import WorkRefilterUrlFetch from './WorkRefilterUrlTypeFetch';
import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';
import WorkRefilterUrlTypeFetchNewProblem from './WorkRefilterUrlTypeFetchNewProblem';

const WorkRefilterUrlType = () => {
  const location = useLocation();

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const [menuItems, setMenuItems] = useState();
  const { current, onClickLocation } = useTab(location.pathname, true);

  const getUrlTypes = async () => {
    const r = await requestLoadWorkRefilterUrltypeGetUrlType({});
    const url_type_infos = r['refilter_header_url_types'];
    const no_data_refilter_infos = r['no_data_refilter_infos'];
    const menuItems = url_type_infos.map((e) => {
      const url_type = e.url_type.replaceAll('/', '_backslash_').replaceAll('?', '_question_');
      return {
        title: `${e.url_type} (${e.count})`,
        value: `/work/refilter_url_type/${url_type}`,
      };
    });

    var menu = menuItems;
    if (no_data_refilter_infos.length > 0) {
      menu = [
        {
          title: `신규 제제[new problem] (${no_data_refilter_infos.length})`,
          value: 'work/refilter_url_type/new_problem',
        },
        ...menuItems,
      ];
    }

    setMenuItems(menu);
  };
  useEffect(() => {
    getUrlTypes();
  }, []);
  return (
    <>
      <Box bottom={-44}>read_fiddler_header()</Box>
      <Grid row={['auto', 1]} gap={32}>
        <TabComponent
          current={current}
          onClick={(url) => {
            onClickLocation(url);
          }}
          items={menuItems}
        />
        <Routes>
          {/* <Route path={'/'} element={<WorkUrlFetch />} /> */}
          <Route path={'new_problem'} element={<WorkRefilterUrlTypeFetchNewProblem />} />
          <Route path={':url_type'} element={<WorkRefilterUrlFetch />} />
          {/* <Route path={'/'} element={<UserAdd />} /> */}
        </Routes>
      </Grid>
    </>
  );
};
export default WorkRefilterUrlType;
