import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, IconButton, InputComponent, Text, Title } from 'Common/UI/atoms';
import { useParams } from 'react-router-dom';
import useMutations from 'Common/Service/commonMutations';
import { Table, Tbody } from 'Common/UI/organisms/FormTabel/styeld';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import { currencyComma } from 'Common/Utils';
import ToggleButton from 'Page/Module/CheckToggleButton';
import { requestLoadVmwareGetApiInfo, requestLoadVmwareSetApiInfo } from 'Common/Service/common';
import { default_vmwares_fields } from 'Common/data';
import { useForceUpdate } from 'Common/Utils/util';
const ManageVmwareWorkstation = () => {
  const { workstation_name } = useParams();
  const [result, setResults] = useState({});
  const [workerLoginTypeCounts, setWorkerLoginTypeCounts] = useState();
  const [smartstoreVmwares, setSmartstoreVmwares] = useState([]);
  const [workstationName, setWorkstationName] = useState(workstation_name);

  const { requestLoadWorkerLoginTypeCountsMutation, requestLoadVmwareGetApiInfoMutation } =
    useMutations(setResults);

  useEffect(() => {
    var name;
    if (workstation_name !== undefined) name = workstation_name;
    else name = 'all';
    setWorkstationName(name);
    requestLoadVmwareGetApiInfoMutation.mutate({
      workstation_name,
      fields: default_vmwares_fields,
    });
  }, [workstation_name]);
  useEffect(() => {
    if (result?.request === 'requestLoadWorkerLoginTypeCountsMutation') {
      setWorkerLoginTypeCounts(result?.result);
      setTimeout(() => {
        requestLoadWorkerLoginTypeCountsMutation.mutate({});
      }, 10000);
      //
    } else if (result?.request === 'requestLoadVmwareGetApiInfoMutation') {
      if (result?.success && result?.result !== undefined && result?.result?.data) {
        var name;
        if (workstation_name !== undefined) name = workstation_name;
        else name = 'all';
        setWorkstationName(name);
        var vmwareData = smartstoreVmwares;
        if (!vmwareData) vmwareData = {};
        setSmartstoreVmwares(result?.result?.data);
      }
      // setTimeout(() => {
      //   requestLoadVmwareGetApiInfoMutation.mutate({
      //     workstation_name,
      //     fields: default_vmwares_fields,
      //   });
      // }, 2000);
    }

    setResults({});
  }, [result.request]);

  const changeFieldChange = async (fields, vmware_name = null, id = null) => {
    const result = await requestLoadVmwareSetApiInfo({
      vmware_name,
      fields,
      id,
    });
    if (result) {
      const result = await requestLoadVmwareGetApiInfo({
        workstation_name,
        fields: default_vmwares_fields,
      });
      setSmartstoreVmwares(result?.data);
    }
  };
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    // if(location.pathname)
    requestLoadWorkerLoginTypeCountsMutation.mutate({});
  }, []);
  return (
    <>
      <Block>
        {workstationName === 'all' && (
          <>
            <Box gap={20}>
              <Title size={20}>전체 수정</Title>
              <IconButton
                Icon={Icon.Closer}
                Theme={'unFill'}
                size={'xs'}
                onClick={() => {
                  changeFieldChange({
                    force_click_stop_chk: true,
                  });
                }}
              >
                유효클릭 강제중지
              </IconButton>
              <IconButton
                Icon={Icon.Complate}
                // Theme={'unFill'}
                size={'xs'}
                onClick={() => {
                  changeFieldChange({
                    force_click_stop_chk: false,
                  });
                }}
              >
                유효클릭 사용
              </IconButton>
              <IconButton
                circle={0}
                size={'xs'}
                Theme={'unFill'}
                onClick={() => {
                  changeFieldChange({
                    force_do_type: 'naver_active',
                  });
                }}
              >
                <StatusText>활동</StatusText>
              </IconButton>
              <IconButton
                Theme={'unFill'}
                size={'xs'}
                onClick={() => {
                  changeFieldChange({
                    force_do_type: 'naver_login',
                  });
                }}
              >
                <StatusText>네이버로그인</StatusText>
              </IconButton>
              <IconButton
                Theme={'line'}
                size={'xs'}
                onClick={() => {
                  changeFieldChange({
                    force_do_type: 'shop_click',
                  });
                }}
              >
                <StatusText>유효클릭</StatusText>
              </IconButton>
              <IconButton
                Theme={'unFill'}
                size={'xs'}
                onClick={() => {
                  changeFieldChange({
                    force_do_type: 'browser_active',
                  });
                }}
              >
                <StatusText>활동</StatusText>
              </IconButton>
            </Box>
          </>
        )}
        <TableComponent
          onSearch={null}
          isController={false}
          allCheckbox={false}
          isInterval={false}
          searchText={null}
        >
          <Table>
            <colgroup>
              <col style={{ width: '60rem' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>

            <Tbody>
              {smartstoreVmwares === undefined ||
              workstationName === undefined ||
              smartstoreVmwares?.length === 0 ? (
                <tr>
                  <td colSpan="25">등록된 정보가 없어요</td>
                </tr>
              ) : (
                <>
                  {smartstoreVmwares?.map((i, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <tr>
                          <td rowSpan={'3'}>{i?.vmware_name}</td>

                          <td rowSpan={'1'}>
                            <Box column align="center" gap={12}>
                              <Box gap={4}>
                                <IconButton
                                  circle={0}
                                  size={'xs'}
                                  Theme={i.force_do_type === 'naver_active' ? 'normal' : 'unFill'}
                                  Icon={i.force_do_type === 'naver_active' && Icon.Check}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        force_do_type: 'naver_active',
                                      },
                                      i?.vmware_name,
                                    );
                                  }}
                                >
                                  <StatusText>활동</StatusText>
                                </IconButton>
                                <IconButton
                                  Theme={i.force_do_type === 'naver_login' ? 'normal' : 'unFill'}
                                  Icon={i.force_do_type === 'naver_login' && Icon.Check}
                                  size={'xs'}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        force_do_type: 'naver_login',
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  <StatusText>네이버로그인</StatusText>
                                </IconButton>
                                <IconButton
                                  Theme={i.force_do_type === 'shop_click' ? 'normal' : 'line'}
                                  Icon={i.force_do_type === 'shop_click' && Icon.Check}
                                  size={'xs'}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        force_do_type: 'shop_click',
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  <StatusText>유효클릭</StatusText>
                                </IconButton>
                                <IconButton
                                  Theme={i.force_do_type === 'browser_active' ? 'normal' : 'unFill'}
                                  size={'xs'}
                                  Icon={i.force_do_type === 'browser_active' && Icon.Check}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        force_do_type: 'browser_active',
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  <StatusText>브라우져 활동</StatusText>
                                </IconButton>
                              </Box>
                              <Box gap={4}>
                                회선정보
                                <Box width={100}>
                                  <InputComponent
                                    value={i?.phone_num}
                                    onChange={(e) => {
                                      i.phone_num = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="100"
                                  />
                                </Box>
                                <Box width={130}>
                                  <InputComponent
                                    value={i?.phone}
                                    onChange={(e) => {
                                      i.phone = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="0896710041"
                                  />
                                </Box>
                                라우터정보
                                <Box width={150}>
                                  <InputComponent
                                    value={i?.router_ip}
                                    onChange={(e) => {
                                      i.router_ip = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="192.168.102.1"
                                  />
                                </Box>
                                명의
                                <Box width={120}>
                                  <InputComponent
                                    value={i?.owner_name}
                                    onChange={(e) => {
                                      i.owner_name = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                </Box>
                                명의관리자
                                <Box width={120}>
                                  <InputComponent
                                    value={i?.partner_owner_name}
                                    onChange={(e) => {
                                      i.partner_owner_name = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                </Box>
                                가격
                                <Box width={80}>
                                  <InputComponent
                                    value={i?.network_price}
                                    onChange={(e) => {
                                      i.network_price = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                </Box>
                                속도
                                <Box width={80}>
                                  <InputComponent
                                    value={i?.network_speed}
                                    onChange={(e) => {
                                      i.network_speed = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                </Box>
                                <Box size={80}>
                                  <Button
                                    size="xxs"
                                    onClick={(e) => {
                                      changeFieldChange(
                                        {
                                          phone_num: i.phone_num,
                                          phone: i.phone,
                                          router_ip: i.router_ip,
                                          owner_name: i.owner_name,
                                          partner_owner_name: i.partner_owner_name,
                                          network_price: parseInt(i.network_price),
                                          network_speed: i.network_speed,
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                  >
                                    적용
                                  </Button>
                                </Box>
                              </Box>
                              <Box gap={4}>
                                워크스테이션
                                <Box width={150}>
                                  <InputComponent
                                    value={i?.workstation_name}
                                    onChange={(e) => {
                                      i.workstation_name = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                </Box>
                                VMWARE 이름
                                <Box width={180}>
                                  <InputComponent
                                    value={i?.vmware_name}
                                    onChange={(e) => {
                                      i.vmware_name = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                </Box>
                                기타 정보
                                <Box width={400}>
                                  <InputComponent
                                    value={i?.etc_status}
                                    onChange={(e) => {
                                      i.etc_status = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                </Box>
                                <Box size={80}>
                                  <Button
                                    size="xxs"
                                    onClick={(e) => {
                                      changeFieldChange(
                                        {
                                          workstation_name: i.workstation_name,
                                          etc_status: i.etc_status,
                                          vmware_name: i.vmware_name,
                                        },
                                        i.vmware_name,
                                        i.id,
                                      );
                                    }}
                                  >
                                    적용
                                  </Button>
                                </Box>
                              </Box>
                              {i.force_do_type === 'shop_click' && (
                                <Box>
                                  유효클릭 아이디 아이디 부르기
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          force_do_type_shop_click_worker_login_naver_id_type:
                                            'korea',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={
                                      i?.force_do_type_shop_click_worker_login_naver_id_type !==
                                        'korea' && 'unFill'
                                    }
                                  >
                                    한국 실명
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          force_do_type_shop_click_worker_login_naver_id_type:
                                            'thailand',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={
                                      i?.force_do_type_shop_click_worker_login_naver_id_type !==
                                        'thailand' && 'unFill'
                                    }
                                  >
                                    태국 가입
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          force_do_type_shop_click_worker_login_naver_id_type:
                                            'other',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={
                                      i?.force_do_type_shop_click_worker_login_naver_id_type !==
                                        'other' && 'unFill'
                                    }
                                  >
                                    비실명
                                  </Button>
                                  <Button
                                    dsiabled
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          force_do_type_shop_click_worker_login_naver_id_type: null,
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={
                                      i?.force_do_type_shop_click_worker_login_naver_id_type !==
                                        null && 'unFill'
                                    }
                                  >
                                    비로그인
                                  </Button>
                                </Box>
                              )}
                              <Box gap={34}>
                                <Button
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        vmware_type:
                                          i?.vmware_type === 'workstation'
                                            ? 'vmware'
                                            : 'workstation',
                                        vmware_workstation_run_multi_mode_chk:
                                          i?.vmware_type === 'vmware'
                                            ? false
                                            : i?.vmware_workstation_run_multi_mode_chk,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={i?.vmware_type === 'vmware' && 'unFill'}
                                >
                                  {i?.vmware_type}
                                </Button>

                                <ToggleButton
                                  active={i.vmware_workstation_run_multi_mode_chk}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        vmware_workstation_run_multi_mode_chk:
                                          !i.vmware_workstation_run_multi_mode_chk,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  {i.vmware_workstation_run_multi_mode_chk
                                    ? 'in 워크스테이션 모드'
                                    : '싱글실행 모드'}
                                </ToggleButton>

                                <ToggleButton
                                  active={i.worker_login_network_socket_mode_chk}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_network_socket_mode_chk:
                                          !i.worker_login_network_socket_mode_chk,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  {i.worker_login_network_socket_mode_chk
                                    ? '네트워크 소켓모드'
                                    : '브라우저 모드'}
                                </ToggleButton>
                              </Box>
                              <Box gap={34}>
                                <ToggleButton
                                  active={i.worker_login_type_proxy_chk}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type_proxy_chk: !i.worker_login_type_proxy_chk,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  {i.worker_login_type_proxy_chk ? '프록시' : '프록시'}
                                </ToggleButton>
                                <Box width={180}>
                                  <InputComponent
                                    value={i?.worker_login_type_proxy_port}
                                    onChange={(e) => {
                                      i.worker_login_type_proxy_port = e.target.value;
                                      forceUpdate();
                                    }}
                                    size={'xs'}
                                    placeholder="38282"
                                  />
                                  <Box size={80}>
                                    <Button
                                      size="xxs"
                                      onClick={(e) => {
                                        changeFieldChange(
                                          {
                                            worker_login_type_proxy_port:
                                              i.worker_login_type_proxy_port,
                                          },
                                          i.vmware_name,
                                        );
                                      }}
                                    >
                                      적용
                                    </Button>
                                  </Box>
                                </Box>

                                <Box>
                                  <ToggleButton
                                    active={i.vmware_setting_auto_start_program_chk}
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          vmware_setting_auto_start_program_chk:
                                            !i.vmware_setting_auto_start_program_chk,
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                  >
                                    {i.vmware_setting_auto_start_program_chk
                                      ? '프로그램 자동재실행'
                                      : '프로그램 자동실행 중단'}
                                  </ToggleButton>
                                  <Box width={180}>
                                    <InputComponent
                                      value={i?.vmware_setting_auto_start_idle_sec_max}
                                      onChange={(e) => {
                                        i.vmware_setting_auto_start_idle_sec_max = e.target.value;
                                        forceUpdate();
                                      }}
                                      size={'xs'}
                                      placeholder="자동재실행 딜레이초"
                                    />
                                    <Box size={80}>
                                      <Button
                                        size="xxs"
                                        onClick={(e) => {
                                          changeFieldChange(
                                            {
                                              vmware_setting_auto_start_idle_sec_max: parseInt(
                                                i.vmware_setting_auto_start_idle_sec_max,
                                              ),
                                            },
                                            i.vmware_name,
                                          );
                                        }}
                                      >
                                        적용
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box gap={34}>
                                <ToggleButton
                                  active={i.vmware_setting_fiddler_auto_check_restart_chk}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        vmware_setting_fiddler_auto_check_restart_chk:
                                          !i.vmware_setting_fiddler_auto_check_restart_chk,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  부팅시 피들러 자동실행
                                </ToggleButton>

                                <ToggleButton
                                  active={i.vmwaer_setting_reboot_auto_program_start_chk}
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        vmwaer_setting_reboot_auto_program_start_chk:
                                          !i.vmwaer_setting_reboot_auto_program_start_chk,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                >
                                  부팅시 프로그램 자동실행
                                </ToggleButton>
                                <Box>
                                  <ToggleButton
                                    active={i.vmware_setting_reboot_auto_mode_chk}
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          vmware_setting_reboot_auto_mode_chk:
                                            !i.vmware_setting_reboot_auto_mode_chk,
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                  >
                                    vmware 자동재부팅
                                  </ToggleButton>
                                  <Box width={180}>
                                    <InputComponent
                                      value={i?.vmware_setting_reboot_sec_max}
                                      onChange={(e) => {
                                        i.vmware_setting_reboot_sec_max = e.target.value;
                                        forceUpdate();
                                      }}
                                      size={'xs'}
                                      placeholder="자동재실행 딜레이초"
                                    />
                                    <Box size={80}>
                                      <Button
                                        size="xxs"
                                        onClick={(e) => {
                                          changeFieldChange(
                                            {
                                              vmware_setting_reboot_sec_max: parseInt(
                                                i.vmware_setting_reboot_sec_max,
                                              ),
                                            },
                                            i.vmware_name,
                                          );
                                        }}
                                      >
                                        적용
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                {/* <Button
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'window_pc_browser',
                                        worker_login_type_proxy_chk: true,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={i?.worker_login_type !== 'window_pc_browser' && 'unFill'}
                                >
                                  PC
                                </Button> */}
                                <Button
                                  disabled
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'android_samsung_browser',
                                        worker_login_type_proxy_chk: true,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={
                                    i?.worker_login_type !== 'android_samsung_browser' && 'unFill'
                                  }
                                >
                                  삼성 브라우져
                                </Button>
                                {/* <Button
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'android_chrome_naver_real_id',
                                        worker_login_type_proxy_chk: false,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={
                                    i?.worker_login_type !== 'android_chrome_naver_real_id' &&
                                    'unFill'
                                  }
                                >
                                  안드로이드 크롬 실명
                                </Button>
                                <Button
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'android_chrome_naver_id',
                                        worker_login_type_proxy_chk: false,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={
                                    i?.worker_login_type !== 'android_chrome_naver_id' && 'unFill'
                                  }
                                >
                                  안드로이드 크롬 비실명
                                </Button> */}
                                <Button
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'iphone_safari_r_real_naver_id',
                                        worker_login_type_proxy_chk: true,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={
                                    i?.worker_login_type !== 'iphone_safari_r_real_naver_id' &&
                                    'unFill'
                                  }
                                >
                                  아이폰 사파리[실제실명]
                                </Button>
                                <Button
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'iphone_safari',
                                        worker_login_type_proxy_chk: true,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={i?.worker_login_type !== 'iphone_safari' && 'unFill'}
                                >
                                  아이폰 사파리
                                </Button>
                                <Button
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'iphone_app',
                                        worker_login_type_proxy_chk: true,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={i?.worker_login_type !== 'iphone_app' && 'unFill'}
                                >
                                  아이폰 앱
                                </Button>

                                {/* <Button
                                  dsiabled
                                  onClick={() => {
                                    changeFieldChange(
                                      {
                                        worker_login_type: 'ipad_safari',
                                        worker_login_type_proxy_chk: true,
                                      },
                                      i.vmware_name,
                                    );
                                  }}
                                  size="xs"
                                  Theme={i?.worker_login_type !== 'ipad_safari' && 'unFill'}
                                >
                                  아아패드 사파리
                                </Button> */}
                                {/* <Button
                                onClick={() => {
                                  changeFieldChange(
                                    {
                                      worker_login_type: 'android_app',
                                      worker_login_type_proxy_chk: true,
                                    },
                                    i.vmware_name,
                                  );
                                }}
                                size="xs"
                                Theme={i?.worker_login_type !== 'android_app' && 'unFill'}
                              >
                                안드로이드 앱
                              </Button>

                              <Button
                                dsiabled={true}
                                onClick={() => {
                                  changeFieldChange(
                                    {
                                      worker_login_type: 'ipad_app',
                                      worker_login_type_proxy_chk: true,
                                    },
                                    i.vmware_name,
                                  );
                                }}
                                size="xs"
                                Theme={i?.worker_login_type !== 'ipad_app' && 'unFill'}
                              >
                                아아패드 앱
                              </Button> */}
                              </Box>
                              <Box gap={24}>
                                <Box>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          worker_login_country: 'korea',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={i?.worker_login_country !== 'korea' && 'unFill'}
                                  >
                                    한국
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          worker_login_country: 'thailand',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={i?.worker_login_country !== 'thailand' && 'unFill'}
                                  >
                                    태국
                                  </Button>
                                </Box>
                                <Box>
                                  <Box>
                                    <Button
                                      onClick={() => {
                                        changeFieldChange(
                                          {
                                            worker_network_type_mobile_chk: false,
                                            worker_network_adapter: i?.worker_network_adapter,
                                            vmware_setting_lan_default_network_adapter_mac_address:
                                              i?.vmware_setting_lan_default_network_adapter_mac_address,
                                            vmware_setting_lan_default_ip_address:
                                              i?.vmware_setting_lan_default_ip_address,
                                          },
                                          i.vmware_name,
                                        );
                                      }}
                                      size="xs"
                                      Theme={i?.worker_network_type_mobile_chk && 'unFill'}
                                    >
                                      일반 회선
                                    </Button>
                                    {!i?.worker_network_type_mobile_chk && (
                                      <>
                                        <Box width={150}>
                                          <InputComponent
                                            placeholder="네트워크 어댑터"
                                            value={i?.worker_network_adapter}
                                            onChange={(e) => {
                                              i.worker_network_adapter = e?.target?.value;
                                              forceUpdate();
                                            }}
                                          />
                                        </Box>
                                        <Box width={150}>
                                          <InputComponent
                                            placeholder="기본 맥어드레스"
                                            value={
                                              i?.vmware_setting_lan_default_network_adapter_mac_address
                                            }
                                            onChange={(e) => {
                                              i.vmware_setting_lan_default_network_adapter_mac_address =
                                                e?.target?.value;
                                              forceUpdate();
                                            }}
                                          />
                                        </Box>
                                        <Box width={150}>
                                          <InputComponent
                                            placeholder="기본 아이피"
                                            value={i?.vmware_setting_lan_default_ip_address}
                                            onChange={(e) => {
                                              i.vmware_setting_lan_default_ip_address =
                                                e?.target?.value;
                                              forceUpdate();
                                            }}
                                          />
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          worker_network_type_mobile_chk: true,
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={!i?.worker_network_type_mobile_chk && 'unFill'}
                                  >
                                    모바일 회선
                                  </Button>
                                </Box>
                                <Box>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          worker_network_type: 'SK Telecom',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={i?.worker_network_type !== 'SK Telecom' && 'unFill'}
                                  >
                                    SKT
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          worker_network_type: 'AIS',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={i?.worker_network_type !== 'AIS' && 'unFill'}
                                  >
                                    AIS
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          worker_network_type: 'dtac',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={i?.worker_network_type !== 'dtac' && 'unFill'}
                                  >
                                    DTAC
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      changeFieldChange(
                                        {
                                          worker_network_type: 'TRUE-H',
                                        },
                                        i.vmware_name,
                                      );
                                    }}
                                    size="xs"
                                    Theme={i?.worker_network_type !== 'TRUE-H' && 'unFill'}
                                  >
                                    TRUE-H
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                            {/* <StatusText>
                              클릭: 190 <small>[1 s]</small> <br />
                              IP: 190 <small>[1 s]</small> <br />
                              아이디수 20 (<small>5</small>) <br />
                            </StatusText> */}
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={'1'}>
                            <Box>
                              클릭가능: {i.shop_link_click_allow_count} [총
                              {
                                workerLoginTypeCounts
                                  ?.find((e) => {
                                    return e.worker_login_type === i.worker_login_type;
                                  })
                                  ?.list?.find((e2) => {
                                    return e2?.worker_login_country === i.worker_login_country;
                                  })?.worker_login_country_count
                              }
                              개] {i.status}
                              {i.ago_sec < 60 * 3 ? (
                                <Text status={'success'}>온라인</Text>
                              ) : i.ago_sec < 60 * 5 ? (
                                <Text status={'warning'}>
                                  딜레이 [{currencyComma(i.ago_sec)}] sec
                                </Text>
                              ) : (
                                <Text status={'error'}>
                                  오프라인 [{currencyComma(i.ago_sec)}] sec
                                </Text>
                              )}
                            </Box>

                            {/* 'force_do_type',
        'allow_shop_click_chk',
        'force_click_stop_chk',
        'use1',
        'lately_access_status',
        'error_count',
        'worker_do_count',
        'worker_re_do_count',
        'worker_do_sec',
        'shop_click_count',
        'ip_change_count',
        'ip_no_use_change_count',
        'ip_change_sec',
        'naver_login_count',
        'naver_active_count',
        'naver_active_sec',
        'browser_active_count',
        'browser_active_sec',
        'naver_block_count', */}
                            <ToggleButton
                              active={!i.stop_chk}
                              onClick={() => {
                                changeFieldChange(
                                  {
                                    stop_chk: !i.stop_chk,
                                  },
                                  i.vmware_name,
                                );
                              }}
                            >
                              {i.stop_chk ? '임시 중단중' : '가동중'}
                            </ToggleButton>
                            <StatusText>
                              <Box gap={10}>
                                <Box>네이버로그인: {currencyComma(i.naver_login_count)}</Box>
                                <Box>
                                  아이피변환: {currencyComma(i.ip_change_count)}
                                  <small>[{i.ip_change_sec} s]</small>
                                  <Box>
                                    활동성공: {i.naver_active_success_count}, 활동실패:{' '}
                                    {i.naver_active_fail_count}
                                  </Box>
                                  <Box>
                                    활동: {currencyComma(i.naver_active_count)}
                                    <small>
                                      [{Math.round(i.naver_active_sec / i.naver_active_count, 2)} s]
                                    </small>
                                  </Box>
                                </Box>
                                {/* <Box></Box>네이버제제: {currencyComma(i.naver_block_count)} */}
                              </Box>
                            </StatusText>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={'1'}>
                            {/* <LineStatusText>
                              시간당 클릭 평균: <small>500</small> , 총 클릭: <small>500</small>,
                              작업 완료 아이디수: <small>500</small>, 오늘 사용가능 대기 아이디수:{' '}
                              <small>500</small>, 총 세팅 아이디수: <small>500</small>, 총 아이디
                              정지:
                              <small>500</small>, 총 대기 아이디수: <small>500</small>, 장기 휴식
                              아이디수: <small>500</small>
                            </LineStatusText> */}
                          </td>
                        </tr>

                        {/* <tr>
                        {Array.from(Array(24)).map((_, i) => (
                          <td rowSpan={'1'}>
                            <Number>3,500</Number>
                          </td>
                        ))}
                      </tr> */}

                        {/* <td rowSpan={2}>{i.location}</td>
                      <td rowSpan={2}>{i.purpose}</td>
                      <td rowSpan={2}>{setDate(i.visitDate)}</td>
                      <td>
                        <Box>{status(i.approval)}</Box>
                      </td>
                      <td rowSpan={2}>
                        <Button
                          disabled={i.addUserInfo === 0}
                          onClick={() => onClickDetail(i.id)}
                          value={i.id}
                          size="xs"
                          inline
                          Theme="line"
                        >
                          정보보기
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>{setDate(i.approvalDate)}</td>
                    </tr> */}
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </Tbody>
          </Table>
        </TableComponent>
      </Block>
    </>
  );
};
const Block = styled.div`
  margin-top: 20rem;
`;
const StatusText = styled.div`
  font-size: 12rem;
  display: inline-block;
  justify-content: space-between;
  padding: 10rem 10rem;
`;

export default ManageVmwareWorkstation;
