import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-rangeslider';
import './rangslide.css';

import useMutations from 'Common/Service/commonMutations';
import { onShowToast } from 'features/common/commonSlice';
import { useDispatch } from 'react-redux';
import {
  requestLoadShopMallGetWorkerLoginInfoRatios,
  requestLoadShopMallSetWorkerLoginInfoRatio,
} from 'Common/Service/common';
import { useForceUpdate } from 'Common/Utils/util';

const ManageShopMallSetting = () => {
  const [result, setResults] = useState({});
  const [mallRatioInfos, setMallRatioInfos] = useState();
  const [updateMallRatioInfosChk, setUpdateMallRatioInfosChk] = useState(true);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const { requestLoadShopMallGetWorkerLoginInfoRatiosMutation } = useMutations(setResults);

  useEffect(() => {
    if (result?.request === 'requestLoadShopMallGetWorkerLoginInfoRatiosMutation') {
      if (result?.success && result?.result !== undefined) {
        if (updateMallRatioInfosChk) setMallRatioInfos(result?.result);
      }
      setTimeout(() => {
        requestLoadShopMallGetWorkerLoginInfoRatiosMutation.mutate({});
      }, 5000);
    }
    setResults({});
  }, [result.request]);

  const changeShopMallRatioFieldChange = async ({ channel_id, worker_login_info_ratios }) => {
    const result = await requestLoadShopMallSetWorkerLoginInfoRatio({
      channel_id,
      worker_login_info_ratios,
    });
    if (result) {
      const result = await requestLoadShopMallGetWorkerLoginInfoRatios({});
      setMallRatioInfos(result);

      dispatch(
        onShowToast({
          status: 'success',
          message: 'The changes have been applied.',
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: 'An error has occurred.',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
    setUpdateMallRatioInfosChk(true);
  };

  useEffect(() => {
    requestLoadShopMallGetWorkerLoginInfoRatiosMutation.mutate({});
  }, []);

  const verticalLabels = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  };

  return (
    <>
      <Box column gap={30} top={12}>
        {mallRatioInfos?.map((e, key) => {
          var worker_login_info_ratios = e.worker_login_info_ratios;
          const channel_id = e.channel_id;
          return (
            <MallBox key={key}>
              <MallNameText>{e.mall_name}</MallNameText>
              <Box gap={12} top={24}>
                {worker_login_info_ratios.map((app, i) => {
                  const l = app.split(':');
                  const app_name = l[0];
                  var app_ratio = l[1];
                  var app_name_show = app_name;
                  app_name_show = app_name_show.replace('korea', '한국');
                  app_name_show = app_name_show.replace('thailand', '태국');

                  app_name_show = app_name_show.replace('iphone', '아이폰');
                  app_name_show = app_name_show.replace('ipad', '아이패드');
                  app_name_show = app_name_show.replace('safari', '사파리');
                  app_name_show = app_name_show.replace('android_chrome', '크롬');
                  app_name_show = app_name_show.replace('android_samsung_browser', '인터넷');
                  app_name_show = app_name_show.replace('naver_real_id', '실명');
                  app_name_show = app_name_show.replace('naver_id', '비실명');

                  return (
                    <Box column key={i}>
                      <RatioText>{app_name_show}</RatioText>
                      <Box width={200}>
                        <SliderBox>
                          <div className="slider">
                            <Slider
                              min={0}
                              tooltip={true}
                              max={10}
                              labels={verticalLabels}
                              value={app_ratio}
                              onChangeStart={(e) => {
                                setUpdateMallRatioInfosChk(false);
                              }}
                              onChange={(value) => {
                                setUpdateMallRatioInfosChk(false);
                                var str = `${app_name}:${value}`;
                                worker_login_info_ratios[i] = str;

                                forceUpdate();
                              }}
                              onChangeComplete={(e) => {
                                changeShopMallRatioFieldChange({
                                  channel_id,
                                  worker_login_info_ratios,
                                });
                              }}
                            />
                          </div>
                        </SliderBox>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </MallBox>
          );
        })}
      </Box>
    </>
  );
};
const SliderBox = styled.div`
  display: inline-block;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const MallBox = styled.div`
  display: flex;
  flex-direction: column;

  &:hover {
    background: var(--P_60);
    color: var(--P_800);
  }
  &:focus {
    background: red;
  }
`;
const MallNameText = styled.div`
  font-size: 20rem;
  line-height: 20rem;
  color: #331084;
`;
const RatioText = styled.div`
  font-size: 14rem;
  line-height: 15rem;
  color: #ba1717;
`;

export default ManageShopMallSetting;
