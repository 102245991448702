import {
  Box,
  Button,
  DropBox,
  Grid,
  Icon,
  IconButton,
  InputComponent,
  TextButton,
} from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import useTab from 'Common/Hooks/useTab';
import { useSelector } from 'react-redux';
import useMutations from 'Common/Service/slotMutations';
import PaymentSlot from './Page/PaymentSlot';
import PaymentLog from './Page/PaymentLog';
import IssueLog from 'Page/Payment/Page/IssueLog';
import styled from 'styled-components';
import {
  TextSmall,
  TextSucceed,
  dateDiffInDays,
  getEndOfDate,
  getMonth_str,
  getStartOfDate,
  get_uuid,
  today_str,
} from 'Common/Utils/util';
import { requestLoadPaymentGetSearchInfo } from 'Common/Service/slot';
import { currencyComma } from 'Common/Utils';
import { FormInput } from 'Common/UI/organisms/FormControl';
import LabelBox from 'Common/UI/molecules/LabelBox';
import PaymentSlotDateCalendar from './Page/PaymentSlotDateCalendar';
const Payment = () => {
  const [issueTypeOption, setIssueTypeOption] = useState([
    { title: '전체' },
    { title: '신규발급' },
    { title: '연장발급' },
    { title: 'AS연장' },
  ]);

  const [slotStatusOption, setSlotStatusOption] = useState([
    { title: '전체' },
    { title: '기간만료' },
    { title: '진행중' },
    { title: '진행예정' },
    { title: '미세팅' },
  ]);
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const location = useLocation();
  const [result, setResults] = useState({});
  const [startDate, setStartDate] = useState(getStartOfDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const [start_date, set_start_date] = useState(today_str(startDate));
  const [end_date, set_end_date] = useState(today_str(endDate));
  const [partner_name, set_partner_name] = useState();
  const [slot_status, set_slot_status] = useState();
  const [issue_type, set_issue_type] = useState();
  const [selectedSlotStatus, setSelectedSlotStatus] = useState();
  const [selectedIssueType, setSelectedIssueType] = useState();

  const [lastStartDate, setLastStartDate] = useState();
  const [lastEndDate, setLastEndDate] = useState();

  const [searchDiffdays, setSearchDiffdays] = useState();

  const [slotStatusCount, setSlotStatusCount] = useState({});
  // const [allSlotCount, setAllSlotcount] = useState({});
  // const [testSlotCount, setTestSlotCount] = useState({});
  // const [deleteSlotCount, setDeleteSlotCount] = useState({});
  // const [extendSlotCount, setExtendSlotCount] = useState({});
  // const [newSlotCount, setNewSlotCount] = useState({});
  const { current, onClickLocation } = useTab(location.pathname, true);
  const { loginInfo } = useSelector((state) => state.common);
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  useEffect(() => {
    if (result?.request === 'requestLoadPaymentGetSearchInfoMutation') {
      if (result?.success && result?.result !== undefined) {
      }
    } else if (result?.request === 'requestLoadPartnerGetNamesMutation') {
      // if (result?.success && result?.result !== undefined) {
      //   const list1 = [{ title: '전체', value: null, selected: selectedPartnerName === null }];
      //   var list2 = result?.result?.map((e, _idx) => {
      //     return {
      //       title: e,
      //       value: e,
      //       selected: selectedPartnerName === e,
      //     };
      //   });
      //   if (list2 === undefined) list2 = [];
      //   setPartnerNames([...list1, ...list2]);
      // }
    }
    setResults({});
  }, [result.request]);
  const reloadStatus = async () => {
    const result = await getPaymentGetSearchInfo({
      partner_name: selectedPartnerName,
      start_date: today_str(startDate),
      end_date: today_str(endDate),
    });
    return result;
  };
  const getPaymentGetSearchInfo = async ({ partner_name, start_date, end_date }) => {
    const n = dateDiffInDays(start_date, end_date);
    setSearchDiffdays(n + 1);
    const result = await requestLoadPaymentGetSearchInfo({ partner_name, start_date, end_date });
    setLastStartDate(start_date);
    setLastEndDate(end_date);
    if (!result) return;
    setSlotStatusCount(result);
    return result;

    // accPaymentSlotInfo(result);
  };

  useEffect(() => {
    getPaymentGetSearchInfo({ partner_name, start_date, end_date });
  }, []);
  useEffect(() => {
    if (location.pathname === '/payment') {
      setIssueTypeOption([
        { title: '전체', value: null },
        { title: '신규발급', value: 'new' },
        { title: '연장발급', value: 'extend' },
        { title: '테스트발급', value: 'test' },
        { title: 'AS연장', value: 'as_extend' },
      ]);

      setSlotStatusOption([
        { title: '전체', value: null },
        // { title: '기간만료', value: 'done' },
        // { title: '진행중', value: 'ing' },
        // { title: '진행예정', value: 'pre' },
        // { title: '미세팅', value: 'no_setting' },
      ]);
    } else if (location.pathname === '/payment/log') {
      setIssueTypeOption([{ title: '전체', value: null }]);
      setSlotStatusOption([
        { title: '전체', value: null },
        // { title: '정산완료', value: 'paid' },
        // { title: '취소내역', value: 'delete' },
      ]);
    } else if (location.pathname === '/payment/issue') {
      setIssueTypeOption([
        { title: '전체', value: null },
        // { title: '신규발급', value: 'new' },
        // { title: '연장발급', value: 'extend' },
        // { title: '테스트발급', value: 'test' },
        // { title: 'AS연장', value: 'as_extend' },
        // { title: '환불', value: 'delete' },
      ]);
      setSlotStatusOption([{ title: '전체', value: null }]);
    }
  }, [location.pathname]);
  return (
    <>
      {/* <Grid row={['auto', 'auto', 'auto']} gap={32}> */}
      <Box column gap={32}>
        <Box column top={35} bottom={10}>
          <Box>
            <TabComponent
              current={current}
              onClick={(url) => {
                onClickLocation(url);
              }}
              items={[
                loginInfo.user_level >= 3 ? { title: '결제', value: '/payment' } : null,
                loginInfo.user_level >= 3 ? { title: '결제 내역', value: '/payment/log' } : null,
                loginInfo.user_level >= 2 ? { title: '이력관리', value: '/payment/issue' } : null,
                loginInfo.user_level >= 3
                  ? {
                      disabled: loginInfo.user_level < 4,
                      title: '판매 내역',
                      value: '/payment/slot',
                    }
                  : null,
                // { title: '슬롯 타수내역', value: '/payment/counts' },
              ].filter((e) => e)}
            />
          </Box>
          {loginInfo.user_level >= 3 ? (
            <InfoBox>
              <Box>
                기간: <NumberText3>{currencyComma(searchDiffdays)}</NumberText3>일 (
                {today_str(startDate)} ~ {today_str(endDate)})
              </Box>
              <Box gap={24}>
                <Box column>
                  <Box>
                    총 판매 :{' '}
                    <NumberText1>{currencyComma(slotStatusCount?.all_sell_count)} </NumberText1>
                  </Box>
                  <Box>
                    환불 : <NumberText2>{currencyComma(slotStatusCount?.refund_count)}</NumberText2>{' '}
                    취소: <NumberText2>{currencyComma(slotStatusCount?.cancel_count)}</NumberText2>
                  </Box>
                </Box>
                <Box column>
                  <Box>
                    발급 :{' '}
                    <TextSucceed>
                      {currencyComma(
                        slotStatusCount?.issue_new_slot_count +
                          slotStatusCount?.issue_extend_slot_count,
                      )}{' '}
                    </TextSucceed>
                    개
                  </Box>
                  <Box>AS 연장 : {currencyComma(slotStatusCount?.as_extend_count)}</Box>
                </Box>
                <Box column valign="top" height="100%">
                  <Box>
                    (신규발급 :{' '}
                    <TextSucceed>
                      {currencyComma(slotStatusCount?.issue_new_slot_count)}{' '}
                    </TextSucceed>
                    개 연장발급 :{' '}
                    <TextSucceed>
                      {currencyComma(slotStatusCount?.issue_extend_slot_count)}{' '}
                    </TextSucceed>
                    개)
                  </Box>
                  <Box>
                    테스트발급 : {currencyComma(slotStatusCount?.issue_test_slot_count)} 개{' '}
                  </Box>
                  <Box />
                </Box>
              </Box>

              <Box gap={24}>
                <Box>
                  총 정산 :{' '}
                  <NumberText3>
                    {currencyComma(
                      slotStatusCount?.all_sell_count -
                        slotStatusCount?.refund_count -
                        slotStatusCount?.cancel_count -
                        slotStatusCount?.as_extend_count -
                        slotStatusCount?.issue_test_slot_count,
                    )}
                  </NumberText3>
                  정산 완료(
                  <NumberText3>{currencyComma(slotStatusCount?.refund_count)}</NumberText3>)
                </Box>
                <Box>
                  {/* 미 정산 (기간만료) :{' '}
                  <NumberText2>
                    {currencyComma(allSlotCount?.expired_count - allSlotCount?.paid_count)}
                  </NumberText2> */}
                </Box>
              </Box>
              {/* <Box gap={24}>
                <Box column>
                  <Box>
                    총 판매 :{' '}
                    <NumberText1>
                      {currencyComma(allSlotCount?.count - testSlotCount?.count)}{' '}
                    </NumberText1>
                    {allSlotCount?.expired_count
                      ? `[만료: ${currencyComma(allSlotCount?.expired_count)}]`
                      : ''}
                  </Box>
                  <Box>
                    환불 : <NumberText2>{currencyComma(deleteSlotCount?.count)}</NumberText2>
                  </Box>
                </Box>
                <Box column>
                  <Box>
                    신규발급 : {currencyComma(newSlotCount?.count)}{' '}
                    {newSlotCount?.expired_count
                      ? `[만료: ${currencyComma(newSlotCount?.expired_count)}]`
                      : ''}
                  </Box>
                  <Box>
                    연장발급 : {currencyComma(extendSlotCount?.count)}{' '}
                    {extendSlotCount?.expired_count
                      ? `[만료: ${currencyComma(extendSlotCount?.expired_count)}]`
                      : ''}
                  </Box>
                </Box>
                <Box column valign="top" height="100%">
                  <Box>
                    테스트발급 : {currencyComma(testSlotCount?.count)}{' '}
                    {testSlotCount?.expired_count
                      ? `[만료: ${currencyComma(testSlotCount?.expired_count)}]`
                      : ''}
                  </Box>
                  <Box>AS 연장 : {currencyComma(allSlotCount?.as_count)}일</Box>
                  <Box />
                </Box>
              </Box>

              <Box gap={24}>
                <Box>
                  총 정산 : <NumberText3>{currencyComma(allSlotCount?.paid_count)}</NumberText3>
                </Box>
                <Box>
                  미 정산 (기간만료) :{' '}
                  <NumberText2>
                    {currencyComma(allSlotCount?.expired_count - allSlotCount?.paid_count)}
                  </NumberText2>
                </Box>
              </Box> */}
            </InfoBox>
          ) : (
            <></>
          )}
        </Box>
        {current === '/payment/slot' ? null : (
          <Box top={-30} height={100}>
            {/* <Grid column={['200rem', '250rem', '1fr', '1fr']} gap={10}> */}
            <Grid column={['200rem', '290rem', '300rem', '200rem', '200rem', '200rem']} gap={10}>
              <Box width="100%">
                <FormInput.Datepicker
                  // fill
                  line
                  type={'month'}
                  value={month}
                  placeholder="YYYY.MM.DD"
                  onChange={(_, e) => {
                    if (e?.toString() === 'Invalid date') return;
                    if (!e) return;

                    setMonth(month);
                    setStartDate && setStartDate(getStartOfDate(e));

                    if (getMonth_str() === getMonth_str(e)) {
                      setEndDate && setEndDate(new Date());
                    } else {
                      setEndDate && setEndDate(getEndOfDate(e));
                    }
                  }}
                />
              </Box>
              <Box width={'100%'}>
                <FormInput.Datepicker
                  fill
                  type={'multiple'}
                  value={{ start: startDate, end: endDate }}
                  placeholder="YYYY.MM.DD"
                  onChange={(_, e) => {
                    if (e?.start?.toString() === 'Invalid date') return;
                    if (e?.end?.toString() === 'Invalid date') return;
                    if (!e?.start) return;
                    if (!e?.end) return;
                    setStartDate && setStartDate(new Date(e.start));
                    setEndDate && setEndDate(new Date(e.end));
                  }}
                />
              </Box>

              {loginInfo?.user_level >= 4 ? (
                <Box>
                  <Box width={90}>2차 대행사</Box>
                  <Box>
                    <InputComponent
                      size="44"
                      value={selectedPartnerName}
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        setSelectedPartnerName(value);
                      }}
                    />
                  </Box>

                  {/* <DropBox
                  title={'2차대행사'}
                  isColor
                  arrow
                  line
                  isSearch={true}
                  placeholder="전체"
                  option={partnerNames?.map((e) => {
                    return { ...e, selected: e.value === selectedPartnerName };
                  })}
                  onChange={(_a, partner_name) => {
                    // setUserNames([{ title: '전체', value: null, selected: true }]);
                    // setUserNames();
                    setSelectedPartnerName(partner_name);
                    // selectPartnerName(partner_name);
                    // search({ partner_name });
                  }}
                  size={43}
                /> */}
                </Box>
              ) : null}
              {loginInfo?.user_level >= 3 ? (
                <Box>
                  <DropBox
                    isColor
                    arrow
                    line
                    isSearch={true}
                    placeholder="전체"
                    option={issueTypeOption?.map((e) => {
                      return { ...e, selected: e.value === selectedIssueType };
                    })}
                    onChange={(_a, issue_type) => {
                      setSelectedIssueType(issue_type);
                    }}
                    size={43}
                  />
                </Box>
              ) : null}
              {/* <Box>
              <DropBox
                isColor
                arrow
                line
                isSearch={true}
                placeholder="전체"
                option={slotStatusOption?.map((e) => {
                  return { ...e, selected: e.value === selectedSlotStatus };
                })}
                onChange={(_a, slot_status) => {
                  setSelectedSlotStatus(slot_status);
                }}
                // { title: '전체' },
                // { title: '진행중' },
                // { title: '진행완료' },
                // { title: '진행예정' },
                // { title: '미세팅' },

                size={43}
              />
            </Box> */}
              <Box width={200}>
                <Button
                  size={'lg'}
                  fill
                  onClick={async () => {
                    const start_date = today_str(startDate);
                    const end_date = today_str(endDate);
                    const result = await getPaymentGetSearchInfo({
                      partner_name: selectedPartnerName,
                      start_date,
                      end_date,
                    });
                    const diff_day = dateDiffInDays(start_date, end_date) + 1;
                    set_start_date(start_date);
                    set_end_date(end_date);
                    set_partner_name(selectedPartnerName);
                    set_slot_status(selectedSlotStatus);
                    set_issue_type(selectedIssueType);
                    if (selectedPartnerName) {
                      setPaymentStatusList([
                        ...paymentStatusList,
                        {
                          ...result,
                          uuid: get_uuid(),
                          diff_day,
                          partner_name: selectedPartnerName,
                          start_date,
                          end_date,
                        },
                      ]);
                    }
                  }}
                >
                  검색
                </Button>
              </Box>
            </Grid>
          </Box>
        )}
        <Box column>
          <Box>2차대행사</Box>
          {/* {currencyComma(
                      slotStatusCount?.all_sell_count -
                        slotStatusCount?.refund_count -
                        slotStatusCount?.cancel_count -
                        slotStatusCount?.as_extend_count -
                        slotStatusCount?.issue_test_slot_count,
                    )}
                  </NumberText3> */}
          {paymentStatusList?.map((e, index) => {
            return (
              <StatusBlock key={index}>
                <TextSmall>
                  [
                  <TextButton
                    onClick={() => {
                      setSelectedPartnerName(e?.partner_name);
                    }}
                  >
                    {e?.partner_name}
                  </TextButton>
                  ] <NumberText3>{e?.diff_day}</NumberText3>일 [{e?.start_date} ~ {e?.end_date}] 총
                  판매:
                  {currencyComma(e?.all_sell_count)} 환불:{' '}
                  <NumberText2>{currencyComma(e?.refund_count)}</NumberText2>, 총 정산:{' '}
                  <NumberText3>
                    {currencyComma(
                      e?.all_sell_count -
                        e?.refund_count -
                        e?.cancel_count -
                        e?.as_extend_count -
                        e?.issue_test_slot_count,
                    )}
                  </NumberText3>{' '}
                  (정산 완료 <NumberText3>{currencyComma(e?.refund_count)}</NumberText3>)
                </TextSmall>
                <IconButton
                  Theme={'unFill'}
                  Icon={Icon.Closer}
                  size={'xs'}
                  onClick={() => {
                    setPaymentStatusList(paymentStatusList?.filter((i) => i?.uuid != e?.uuid));
                  }}
                />
              </StatusBlock>
            );
          })}
        </Box>

        <Box>
          <Routes>
            <Route
              path={'/issue'}
              element={
                <IssueLog reloadStatus={reloadStatus} start_date={start_date} end_date={end_date} />
              }
            />

            <Route
              path={'/log'}
              element={
                <PaymentLog
                  reloadStatus={reloadStatus}
                  start_date={start_date}
                  end_date={end_date}
                  partner_name={partner_name}
                  issue_type={issue_type}
                  slot_status={slot_status}
                />
              }
            />
            <Route
              path={'/slot'}
              element={
                <PaymentSlotDateCalendar
                  reloadStatus={reloadStatus}
                  start_date={start_date}
                  end_date={end_date}
                  partner_name={partner_name}
                  issue_type={issue_type}
                  slot_status={slot_status}
                />
              }
            />
            <Route
              path={'/'}
              element={
                <PaymentSlot
                  reloadStatus={reloadStatus}
                  start_date={start_date}
                  end_date={end_date}
                  partner_name={partner_name}
                  issue_type={issue_type}
                  slot_status={slot_status}
                />
              }
            />
          </Routes>
        </Box>
      </Box>
    </>
  );
};
const NumberText1 = styled.span`
  color: #253cae;
`;
const NumberText2 = styled.span`
  color: red;
`;
const NumberText3 = styled.span`
  font-weight: 700;
  color: blue;
`;
const StatusBlock = styled.div`
  display: flex;
  /* flex-direction: ; */
`;
const InfoBox = styled.div`
  display: flex;
  font-size: 12rem;
  position: absolute;
  margin-top: -15rem;
  flex-direction: column;
  border-radius: 8rem;
  align-items: left;
  /* align-items: ; */
  padding: 5rem 5rem 6rem 10rem;
  /* padding-top: -100rem; */
  /* background-color: red; */
  margin-left: 450rem;
  border-color: blue;
  border-width: 2rem 2rem 2rem 2rem;
  border-style: solid;
  width: 500rem;
  height: 115rem;
  b {
    font-size: 14rem;
    font-weight: 700;
  }
`;
export default Payment;
