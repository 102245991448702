import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetGroupActivePatterns,
  requestLoadWorkNewNewPatternGroupInfoGetWorkerStatus,
  requestLoadWorkNewResetWorkerDoCanAccessDatetime,
  requestLoadWorkProductInfoResetCanAccessDatetime,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  getKorDate,
  reload_second,
  to_integer,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { sortSet } from 'Common/Utils';
import { onShowToast } from 'features/common/commonSlice';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternWorkerState = () => {
  // if (loginInfo?.user_level !== 5) return;
  const [workerStateInfos, setWorkerStateInfos] = useState();
  const [groupPatternStateInfos, setGroupPatternStateInfos] = useState({});
  const { group_name } = useParams();
  const [count, setCount] = useState(60);
  const [apiId, setApiId] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetGroupActivePatterns({});
    const group_pattern_state_infos = result?.group_pattern_state_infos;
    setGroupPatternStateInfos(group_pattern_state_infos ? group_pattern_state_infos : {});
  };
  const getWorkerInfos = async () => {
    const group_pattern_worker_status_infos =
      await requestLoadWorkNewNewPatternGroupInfoGetWorkerStatus();
    setWorkerStateInfos(group_pattern_worker_status_infos ? group_pattern_worker_status_infos : {});
  };

  useEffect(() => {
    getWorkInfos();
    // getWorkerInfos();
  }, []);
  return (
    <Box column width="100%">
      <Box gap={22} valign="top" width="100%">
        {Object.keys(groupPatternStateInfos).map((group_name) => {
          var state_info = groupPatternStateInfos[group_name]?.[0];
          state_info = state_info
            ? state_info
            : {
                count: 0,
                new_pattern_active_count_under_3: 0,
                new_pattern_active_count_over_3: 0,
                new_pattern_click_count_under_3: 0,
                new_pattern_click_count_over_3: 0,
                new_pattern_longterm_countnt: 0,
                active_can_access_count: 0,
                click_can_access_count: 0,
                woman_20_count: 0,
                woman_30_count: 0,
                woman_40_count: 0,
                man_20_count: 0,
                man_30_count: 0,
                man_40_count: 0,
              };

          const new_pattern_active_count =
            state_info.new_pattern_active_count_under_3 +
            state_info.new_pattern_active_count_over_3;

          return <GroupPatternStateModule state_info={state_info} group_name={group_name} />;
        })}
      </Box>
      <Box width="100%">
        <TextSmall>{count}</TextSmall>
      </Box>
      <Box column width="100%">
        {false &&
          workerStateInfos?.list
            ?.filter((e) => {
              if (!group_name) return true;
              else if (e?.group_name === group_name) return true;
              return false;
            })
            ?.map((worker, index) => {
              const can_access_chk = worker.can_access_chk;
              const worker_id = worker.id;
              const new_pattern_current_pattern_list = worker.new_pattern_current_pattern_list;
              const new_pattern_current_work_do_list = worker.new_pattern_current_work_do_list;
              const group_name = worker.group_name;

              const worker_login_type = worker.worker_login_type;
              const new_pattern_url_access_count = worker.new_pattern_url_access_count;
              const new_pattern_url_access_total_time = worker.new_pattern_url_access_total_time;
              const new_pattern_url_access_request_size =
                worker.new_pattern_url_access_request_size;
              const new_pattern_url_access_response_size =
                worker.new_pattern_url_access_response_size;
              const new_pattern_url_access_upload_speed =
                worker.new_pattern_url_access_upload_speed;
              const new_pattern_url_access_download_speed =
                worker.new_pattern_url_access_download_speed;
              const tab = worker.tab;

              const lately_traffic_info = worker.lately_traffic_info;
              const proxy_ip_org_traffic_infos = lately_traffic_info.proxy_ip_org_traffic_infos;
              const active_pattern_session_traffic_info =
                lately_traffic_info?.active_pattern_session_traffic_info;
              const group_pattern_rotation_traffic_info =
                lately_traffic_info?.group_pattern_rotation_traffic_info;

              const active_pattern_session_traffic_info_size =
                active_pattern_session_traffic_info?.url_access_request_size +
                active_pattern_session_traffic_info?.url_access_response_size;
              const group_pattern_rotation_traffic_info_size =
                group_pattern_rotation_traffic_info?.url_access_request_size +
                group_pattern_rotation_traffic_info?.url_access_response_size;
              // const size =
              //   lately_traffic_info?.url_access_request_size +
              //   lately_traffic_info?.url_access_response_size;

              // lately_traffic_info?.url_access_count
              // lately_traffic_info?.url_access_total_time
              // lately_traffic_info?.count
              const new_pattern_active_count = worker.new_pattern_active_count;
              const new_pattern_active_count_max = worker.new_pattern_active_count_max;
              const new_pattern_click_count = worker.new_pattern_click_count;
              const new_pattern_click_count_max = worker.new_pattern_click_count_max;
              const new_pattern_click_ok_count = worker.new_pattern_click_ok_count;
              const new_pattern_rotation_count = worker.new_pattern_rotation_count;
              const new_pattern_rotation_count_max = worker.new_pattern_rotation_count_max;
              const new_pattern_which = worker.new_pattern_which;
              const new_pattern_which_status = worker.new_pattern_which_status;
              const do_can_access_datetime_ago_hour = worker.do_can_access_datetime_ago_hour;
              const do_can_access_datetime_ago_min = worker.do_can_access_datetime_ago_min;
              const new_pattern_which_status_datetime_ago_sec =
                worker.new_pattern_which_status_datetime_ago_sec;
              const new_pattern_which_status_result = worker.new_pattern_which_status_result;
              const proxy_ip_address = worker.proxy_ip_address;
              const proxy_ip_city = worker.proxy_ip_city;
              const proxy_ip_country = worker.proxy_ip_country;
              const proxy_ip_org = worker.proxy_ip_org;
              const work_active_chk = worker.work_active_chk;
              const on_page_state = worker.on_page_state;

              const new_pattern_lately_active_name = worker.new_pattern_lately_active_name;
              const new_pattern_lately_vmware_name = worker.new_pattern_lately_vmware_name;
              const new_pattern_lately_vmware_f_num = worker.new_pattern_lately_vmware_f_num;
              const new_pattern_lately_start_datetime_ago_min =
                worker.new_pattern_lately_start_datetime_ago_min;
              const new_pattern_lately_group_pattern_start_count =
                worker.new_pattern_lately_group_pattern_start_count;
              var check_new_pattern_lately_group_pattern_start_count =
                worker.new_pattern_lately_group_pattern_start_count;

              const new_pattern_lately_in_active_work_do =
                worker.new_pattern_lately_in_active_work_do;
              const new_pattern_lately_in_active_work_do_result =
                worker.new_pattern_lately_in_active_work_do_result;
              const new_pattern_lately_in_active_work_do_state =
                worker.new_pattern_lately_in_active_work_do_state;
              const new_pattern_lately_in_active_status =
                worker.new_pattern_lately_in_active_status;
              const new_pattern_lately_in_active_count = worker.new_pattern_lately_in_active_count;
              const new_pattern_lately_in_active_count_max =
                worker.new_pattern_lately_in_active_count_max;
              const new_pattern_lately_in_active_vmware_name =
                worker.new_pattern_lately_in_active_vmware_name;
              const new_pattern_lately_in_active_vmware_f_num =
                worker.new_pattern_lately_in_active_vmware_f_num;
              const new_pattern_lately_in_active_datetime_ago_min =
                worker.new_pattern_lately_in_active_datetime_ago_min;

              const new_pattern_lately_group_pattern_done_count =
                worker.new_pattern_lately_group_pattern_done_count;
              const new_pattern_lately_group_pattern_done_date =
                worker.new_pattern_lately_group_pattern_done_date;
              const new_pattern_lately_group_pattern_done_datetime_ago_min =
                worker.new_pattern_lately_group_pattern_done_datetime_ago_min;
              const new_pattern_lately_in_active_doing_state =
                worker.new_pattern_lately_in_active_doing_state;

              if (new_pattern_lately_in_active_doing_state === 'start') {
                check_new_pattern_lately_group_pattern_start_count -= 1;
              } else if (new_pattern_lately_in_active_doing_state === 'doing') {
                check_new_pattern_lately_group_pattern_start_count -= 1;
              }
              const worker_login_country_asn_company = worker.worker_login_country_asn_company;
              const worker_login_country_asn_code = worker.worker_login_country_asn_code;

              return (
                <Box key={index} width="100%">
                  <Box column width="100%">
                    <Box column width="100%">
                      <Box width="100%">
                        <Box>
                          <TextSmall>{index + 1}</TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            <TextWarning>{new_pattern_lately_in_active_vmware_name}</TextWarning>-
                            {new_pattern_lately_in_active_vmware_f_num}
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            시작 <TextFail>{new_pattern_lately_start_datetime_ago_min}</TextFail>
                            분전
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            {worker_id} now:
                            <TrafficSizeModule
                              size={active_pattern_session_traffic_info_size}
                            />{' '}
                            all:
                            <TrafficSizeModule size={group_pattern_rotation_traffic_info_size} />
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            시도:
                            <TextSucceed>{new_pattern_lately_group_pattern_done_count}</TextSucceed>
                            /
                            <TextWarning>
                              {check_new_pattern_lately_group_pattern_start_count}
                            </TextWarning>
                            (
                            <PercentModule
                              value={new_pattern_lately_group_pattern_done_count}
                              valueMax={check_new_pattern_lately_group_pattern_start_count}
                            />
                            )
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            {work_active_chk ? (
                              <>
                                <button
                                  onClick={async () => {
                                    await requestLoadWorkNewResetWorkerDoCanAccessDatetime({
                                      worker_id,
                                    });
                                  }}
                                >
                                  (
                                  <TextSucceed>
                                    작업중 {String(new_pattern_lately_in_active_datetime_ago_min)}
                                    분전
                                  </TextSucceed>
                                  [<TextWarning>{new_pattern_lately_in_active_work_do}</TextWarning>{' '}
                                  -{' '}
                                  {new_pattern_lately_in_active_work_do_state === 'done' ? (
                                    <TextWarning>{new_pattern_lately_in_active_status}</TextWarning>
                                  ) : (
                                    <TextSucceed>
                                      {new_pattern_lately_in_active_work_do_state}
                                    </TextSucceed>
                                  )}
                                  ] <TextSucceed>{new_pattern_lately_in_active_count}</TextSucceed>/
                                  <TextFail>{new_pattern_lately_in_active_count_max}</TextFail>)
                                </button>
                              </>
                            ) : can_access_chk ? (
                              <>
                                [<TextWarning>stanby</TextWarning>] 지남{' '}
                                {do_can_access_datetime_ago_hour > 0 ? (
                                  <>{do_can_access_datetime_ago_hour} 시간</>
                                ) : (
                                  <>{do_can_access_datetime_ago_min} 분</>
                                )}
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={async () => {
                                    await requestLoadWorkNewResetWorkerDoCanAccessDatetime({
                                      worker_id,
                                    });
                                  }}
                                >
                                  [<TextFail>대기중</TextFail>] 남음{' '}
                                  <TextWarning>
                                    {do_can_access_datetime_ago_hour < 0 ? (
                                      <>{-do_can_access_datetime_ago_hour} 시간후</>
                                    ) : (
                                      <>{-do_can_access_datetime_ago_min} 분후</>
                                    )}
                                  </TextWarning>
                                </button>
                              </>
                            )}
                            -({new_pattern_lately_active_name})-[총
                            {new_pattern_lately_group_pattern_start_count}
                            회]
                          </TextSmall>
                        </Box>
                        {/* </Box>
                    <Box> */}
                        <Box>
                          <TextSmall>
                            on_page:<TextSucceed>{on_page_state}</TextSucceed>
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            <TextWarning>{group_name}</TextWarning>
                          </TextSmall>
                          {/* [{worker_login_type}] */}
                        </Box>
                        <Box>
                          <TextSmall>{proxy_ip_country}</TextSmall>
                          {/* -{proxy_ip_city} */}
                        </Box>
                        <Box>
                          <TextSmall>
                            <TextFail>
                              {new_pattern_which === 'active' ? (
                                <></>
                              ) : new_pattern_which === 'click' ? (
                                <></>
                              ) : (
                                <>
                                  <TextFail>{new_pattern_which}</TextFail>
                                </>
                              )}
                            </TextFail>
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            {new_pattern_which === 'active' ? (
                              <>
                                <TextFail>active</TextFail>
                              </>
                            ) : (
                              <>active</>
                            )}
                            : {new_pattern_active_count}/
                            <TextFail>{new_pattern_active_count_max}</TextFail>
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            {new_pattern_which === 'click' ? (
                              <>
                                <TextFail>click</TextFail>
                              </>
                            ) : (
                              <>click</>
                            )}
                            : {new_pattern_click_count}/
                            <TextFail>{new_pattern_click_count_max}</TextFail>
                          </TextSmall>
                        </Box>
                        <Box>
                          <TextSmall>
                            순환: {new_pattern_rotation_count}/
                            <TextFail>{new_pattern_rotation_count_max}</TextFail>
                          </TextSmall>
                        </Box>
                        <Box gap={2}>
                          <TextSmall>
                            {proxy_ip_org_traffic_infos?.map((proxy_ip_org_info) => {
                              return (
                                <Box>
                                  {proxy_ip_org_info.proxy_ip_org}
                                  <TextWarning>{proxy_ip_org_info.count}</TextWarning>
                                </Box>
                              );
                            })}
                          </TextSmall>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};

export default WorkNewPatternWorkerState;
