import React, { useState, useEffect } from 'react';
import {
  Box,
  CheckBox,
  Badge,
  TextButton,
  IconButton,
  CategoryBadge,
  Title,
  Icon,
} from 'Common/UI/atoms';
import DetailInfoTextBox from '../DetailInfoTextBox';
import { Wrapper } from './styled';
import More from '../More';
import { valueSet } from 'Common/Utils/util';

const Accordion = ({
  isShow = true,
  isOpen,
  title,
  children,
  checked,
  onChecked,
  value,
  leftOption,
  rightOption,
  isMin = true,
  isHidden = true,
  option,
  status,
  custom,
  etc,
  gap,
  padding = [12, 40],
  style,
  label,
  btnList,
  checkBox = true,
  isRoof,
  isBorder = true,
  column = false,
  isRound = true,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => setIsChecked(checked), [checked]);

  const setChecked = (e) => {
    const isCheck = e.target.checked;
    setIsChecked(isCheck);
    onChecked && onChecked(isCheck);
  };
  if (!isShow) return <>{children}</>;
  return (
    <>
      <Wrapper
        isMin={isMin}
        isRoof={isRoof}
        pd={valueSet(padding)}
        isHidden={isHidden}
        gap={valueSet(gap)}
        styles={style}
        isActive={isOpen || isChecked}
        isBorder={isBorder}
        isRound={isRound}
      >
        {isRoof ? (
          <Box align="side" className="accordionHeader" style={`position:sticky;top:`}>
            {option?.map((i, key) => (
              <Box
                align="center"
                key={key}
                width={i?.size}
                style={`font-weight:500;${i?.size ? `` : 'flex:1 1;'}`}
              >
                {i?.title === undefined ? i : i.title}
              </Box>
            ))}
          </Box>
        ) : (
          <Box className="accordionHeader">
            {label && (
              <CategoryBadge color={label?.color} sizeX={label?.sizeX} sizeY={label?.sizeY}>
                {label?.title || label}
              </CategoryBadge>
            )}
            {checkBox && <CheckBox value={value} onChange={setChecked} checked={isChecked} />}
            {custom || (
              <>
                {title ? (
                  !leftOption ? (
                    <Box fill top={label ? 32 : 0}>
                      <Title
                        size={'xxxs'}
                        color={isOpen ? 'P_700' : 'G_900'}
                        className="accordionTitle"
                      >
                        {title}
                      </Title>
                    </Box>
                  ) : (
                    // )
                    <Box fill column>
                      {title && (
                        <Title
                          size={'xxxs'}
                          color={isOpen ? 'P_700' : 'G_900'}
                          className="accordionTitle"
                        >
                          {title}
                        </Title>
                      )}
                      <DetailInfoTextBox option={leftOption} column={column} />
                    </Box>
                  )
                ) : undefined}
                {etc}
                {(status || rightOption) && (
                  <Box top={label ? 32 : 0} column align="right">
                    {status && (
                      <Badge color={status?.color} status size={'auto'}>
                        {status?.title || status}
                      </Badge>
                    )}
                    {rightOption && <DetailInfoTextBox option={rightOption} />}
                  </Box>
                )}
              </>
            )}
            {btnList && (
              <Box className="btnBox" left={30} gap={16}>
                {btnList.map(({ title, type, isActive, disabled, onClick, option }, key) =>
                  type === 'arrow' ? (
                    <IconButton
                      Theme={'unFill'}
                      size="sm"
                      onClick={onClick}
                      key={key}
                      disabled={disabled}
                      Icon={Icon.Arrow}
                      direction={isActive ? 'top' : ''}
                      // direction={''}
                    />
                  ) : type === 'trash' ? (
                    <IconButton
                      Theme={'unFill'}
                      size="sm"
                      onClick={onClick}
                      disabled={disabled}
                      key={key}
                      Icon={Icon.Trash}
                    />
                  ) : type === 'more' ? (
                    <More key={key} option={option} />
                  ) : (
                    <TextButton size="sm" key={key} onClick={onClick} disabled={disabled}>
                      {title}
                    </TextButton>
                  ),
                )}
              </Box>
            )}
          </Box>
        )}
        {children}
      </Wrapper>
    </>
  );
};

export default Accordion;
