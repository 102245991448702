import { valueSet } from "Common/Utils/util";
import React from "react";
import styled from "styled-components";
import { Box } from "../atoms";

const DetailInfoTextBox = ({
    thick,
    option = [{ title: "title", value: "" }],
    height,
    column,
    valign = "top",
    gap = 8,
    padding = [4, 0],
    fontSize = 14,
    className,
    full = false,
}) => {
    const array = () => {
        return option
            .filter((i) => i?.value || i?.value === 0)
            .map((list, key) => {
                return (
                    <React.Fragment key={key}>
                        {!key || <span className="bar" />}
                        <Box column={column} valign={valign} gap={gap}>
                            {list.title && (
                                <div className="optionTitle">{list.title}</div>
                            )}
                            <div className="optionValue">{list.value}</div>
                        </Box>
                    </React.Fragment>
                );
            });
    };

    return (
        <Wrapper
            className={
                className
                    ? `${className} detailInfoTextBox`
                    : "detailInfoTextBox"
            }
            thick={thick}
            fSize={valueSet(fontSize)}
            pd={valueSet(padding)}
            sizeY={height && valueSet(height)}
            valign={valign}
            gap={column ? gap : undefined}
            full={full}
        >
            {option?.title ? (
                <Box column={column} valign={valign} gap={gap}>
                    {option.title && (
                        <div className="optionTitle">{option.title}</div>
                    )}
                    <div className="optionValue">{option.value}</div>
                </Box>
            ) : (
                array()
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${({ sizeY, thick, fSize, pd, valign, gap, full }) => `
    display:flex;
    align-items:${
        valign === "top"
            ? "flex-start"
            : valign === "middle"
            ? "center"
            : valign === "bottom"
            ? "flex-end"
            : "flex-start"
    };
    padding:${pd};
    gap:${gap ? gap : 8}rem;
    ${sizeY ? `height:${sizeY};` : ""};
    .optionTitle{
        font-size:${fSize};
        color:var(--G_${thick ? 500 : 300});
        font-weight:400;
    }
    .optionValue{
        font-size:${fSize};
        color:var(--G_700);
        white-space: pre-line;
        font-weight:400;
        ${full ? "width: calc(100% - 80rem)" : ""}
    }
    .bar{
        width:1rem;
        align-self:center;
        height:8rem;
        background-color:var(--G_100);
    }
`}
`;

export default DetailInfoTextBox;
