import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Wrapper } from './styled';
import { ko } from 'date-fns/esm/locale';
import Icon from '../../../atoms/IconBox';

const DatepickerReact = forwardRef(({ ...props }, ref) => {
  const IconButton = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      <Icon.Calendar size="xxs" />
    </button>
  ));
  const onCalendarOpen = () => {
    if (props.onOpen) props.onOpen();
    ref.current.style.zIndex = 1000;
  };
  const onCalendarClose = () => {
    if (props.onClose) props.onClose();
    ref.current.style.zIndex = 1;
  };
  if (props?.selected?.toString() === 'Invalid Date') return;
  if (props?.openToDate?.toString() === 'Invalid Date') return;
  if (props?.startDate?.toString() === 'Invalid Date') return;
  if (props?.endDate?.toString() === 'Invalid Date') return;
  return (
    <Wrapper ref={ref} className={props.className} big={props.big}>
      <DatePicker
        locale={ko}
        showPopperArrow={false}
        customInput={<IconButton />}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        {...{ selected: null, ...props }}
      >
        {props.children}
      </DatePicker>
    </Wrapper>
  );
});

DatepickerReact.defaultProps = {};
export default DatepickerReact;
