import React from "react";
import Modal from "./Modal";
import { ConfirmModalText } from "./styled";

function Confirm({ contentTit, text, children, ...props }) {
    return (
        <Modal {...props} close={false} size={"sm"}>
            {contentTit && (
                <ConfirmModalText title>{contentTit}</ConfirmModalText>
            )}
            {text && <ConfirmModalText>{text}</ConfirmModalText>}
            {children}
        </Modal>
    );
}

export default Confirm;
