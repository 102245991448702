import React, { forwardRef } from "react";
import Icon from "../IconBox";
import InputComponent from "./InputComponent";
import { SearchButton } from "./styled";

const Search = forwardRef(
    ({ left = true, right, label, onClick, ...props }, ref) => {
        return (
            <InputComponent
                {...props}
                ref={ref}
                leftIcon={
                    left ? (
                        <SearchButton className="" onClick={onClick}>
                            <Icon.Search size="16" />
                        </SearchButton>
                    ) : (
                        ""
                    )
                }
                rightIcon={
                    right ? (
                        <SearchButton className="" onClick={onClick}>
                            <Icon.Search size="16" />
                        </SearchButton>
                    ) : (
                        ""
                    )
                }
            />
        );
    }
);

export default Search;
