import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isToast: false,
  toastData: {
    status: 'success',
    message: '정상적으로 되었어요',
    cancelText: '',
    onCancel: false,
  },
  paging: 1000,
  loginInfo: { user_name: null, user_type: null, user_level: null },
  smartstoreInfo: {},
  smartstoreVmwares: {},
  smartstoreCounts: {},
  smartstoreAnalytics: {},
  smartstoreShopLinks: {},
  smartstoreShopLinkCounts: {},
  date: {
    date: null,
    hourN: null,
    minN: null,
  },
  etcStatus: {},
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    onShowToast: (state, { payload }) => {
      state.isToast = true;
      state.toastData = payload;
    },
    onHideToast: (state, { _payload }) => {
      state.isToast = false;
    },
    setLoginInfo: (state, { payload }) => {
      state.loginInfo = payload;
    },
    setInfo: (state, { payload }) => {
      state.smartstoreInfo = payload;
    },
    setVmwares: (state, { payload }) => {
      state.smartstoreVmwares = payload;
    },
    setCounts: (state, { payload }) => {
      state.smartstoreCounts = payload;
    },
    setAnalytics: (state, { payload }) => {
      state.smartstoreAnalytics = payload;
    },
    setShopLinks: (state, { payload }) => {
      state.smartstoreShopLinks = payload;
    },
    setShopLinkCounts: (state, { payload }) => {
      state.smartstoreShopLinkCounts = payload;
    },
    setToday: (state, { payload }) => {
      state.date = payload;
    },
    setPaging: (state, { payload }) => {
      state.paging = payload;
    },
    setEtcStatus: (state, { payload }) => {
      state.etcStatus = payload;
    },
  },
});

export const {
  onShowToast,
  onHideToast,
  setLoginInfo,
  setInfo,
  setVmwares,
  setCounts,
  setAnalytics,
  setPaging,
  setShopLinks,
  setShopLinkCountss,
  setToday,
  setEtcStatus,
} = commonSlice.actions;

export default commonSlice;
