import { requestLoadWorkResultLogsGetErrorLastWhichLogs } from 'Common/Service/common';
// import useMutations from 'Common/Service/userMutations';
import Box from 'Common/UI/atoms/Box';
import { currencyComma } from 'Common/Utils';
import { TextFail, TextSmall, TextSucceed, reload_second, today_str } from 'Common/Utils/util';
import React, { useEffect, useState } from 'react';

const WorkResultLogsErrorLastWhich = ({ date = null, uuid }) => {
  const [errorInfos, setErrorInfos] = useState([]);
  const [count, setCount] = useState(reload_second);
  const [checkDate, setCheckDate] = useState(date);
  const [apiId, setApiId] = useState();
  useEffect(() => {
    clearInterval(apiId);
    setCheckDate(date);
    getErrorLastErrorStatusLogs(date);
  }, [date, uuid]);

  const getErrorLastErrorStatusLogs = async (check_date = null) => {
    const infos = await requestLoadWorkResultLogsGetErrorLastWhichLogs({
      date: check_date ? check_date : checkDate,
    });
    setErrorInfos(infos);
  };
  useEffect(() => {
    if (today_str(checkDate) != today_str()) return;
    clearInterval(apiId);
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getErrorLastErrorStatusLogs(checkDate);
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, [date, uuid]);
  if (!date) return;

  return (
    <Box column>
      <Box>
        error last which log date [{errorInfos?.date}] <TextSmall>{count}</TextSmall>
      </Box>
      <Box>
        logs: <TextFail>{currencyComma(errorInfos?.last_which_logs?.count)}</TextFail> (
        {currencyComma(errorInfos?.last_which_logs?.error_count)})
      </Box>
      <Box column gap={20}>
        {errorInfos?.last_which_logs?.list?.map((e, index) => {
          return (
            <Box column key={index}>
              <Box>
                <Box>{e?.last_which}</Box>
                <Box>
                  <Box>
                    <TextSmall>
                      <TextFail>{currencyComma(e?.count)}</TextFail>
                    </TextSmall>{' '}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default WorkResultLogsErrorLastWhich;
