import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

function TextButton({
  Theme,
  onClick = null,
  weight,
  disabled,
  className,
  type,
  size,
  loading,
  leftIcon,
  align = 'center',
  rightIcon,
  children,
  gap,
  to = null,
  form,
  fill,
  color = 'inherit',
}) {
  const navigate = useNavigate();
  return (
    <Wrapper
      className={className}
      fil={fill}
      Theme={Theme}
      align={align}
      onClick={
        (to || onClick) &&
        ((e) => {
          to ? navigate(to) : onClick && onClick();
          e.stopPropagation();
        })
      }
      disabled={disabled}
      type={type}
      size={size}
      weight={weight}
      loading={loading || undefined}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      gap={gap}
      form={form}
    >
      {leftIcon ? <i className="leftIcon">{leftIcon}</i> : ''}
      <span className="btnTit" style={{ color: color }}>
        {children}
      </span>
      {rightIcon ? <i className="rightIcon">{rightIcon}</i> : ''}
    </Wrapper>
  );
}

const TextButtonPropsStyle = css`
  ${({ size, Theme, theme: { textButton, ellipsis } }) => {
    const propsSize = size ? size : 'lg';

    const propsTheme = Theme ? Theme : 'normal';
    return css`
      ${textButton.size[propsSize]};
      ${textButton.theme[propsTheme]};
      .btnTit {
        ${ellipsis(1)}
      }
      gap: ${(props) => (props.gap ? `${props.gap}rem` : '8rem')};
      &:not(:disabled):not(:focus):hover {
        ${textButton.theme[propsTheme].hover}
      }
      &:not(:disabled):active {
        ${textButton.theme[propsTheme].active}
      }
      &:disabled {
        cursor: no-drop;
        ${textButton.theme[propsTheme].disabled}
      }
      &:not(:active):not(:disabled):focus {
        ${textButton.theme[propsTheme].focus}
      }
    `;
  }}
`;
const Wrapper = styled.button`
  display: inline-flex;
  align-items: center;

  justify-content: ${(props) => (props.align === 'center' ? 'center' : 'flex-start')};
  cursor: ${(props) => (props.onClick ? 'grab' : 'default')};
  position: relative;
  padding: 0 4rem;
  width: ${(props) => (props.fil ? '100%' : 'auto')};
  flex: ${(props) => (props.fil ? '0 1 auto' : '0 0 auto;')};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  border: 1rem solid transparent;
  border-radius: 8rem;
  .btnTit {
    position: relative;
    display: inline-block;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
  .leftIcon {
    display: flex;
  }
  .rightIcon {
    display: flex;
  }
  ${TextButtonPropsStyle}
`;

TextButton.defaultProps = {
  Theme: false,
  fill: false,
  color: null,
  onClick: () => {},
  disabled: false,
  className: null,
  type: 'button',
  loading: false,
  leftIcon: false,
  rightIcon: false,
};
export default TextButton;
