import { requestLoadWorkDateLogsGetInfos } from 'Common/Service/common';
import useMutations from 'Common/Service/userMutations';
import { Button, DropBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { currencyComma, getAlphaAndNumber, get_percent } from 'Common/Utils';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  debounce,
  getKorDate,
  get_uuid,
  now_str,
  reload_second,
  today_str,
} from 'Common/Utils/util';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PercentModule from './Module/PercentModule';

const WorkDateLogs = () => {
  const [workDateList, setWorkDateList] = useState([]);
  const [checkDate, setCheckDate] = useState(today_str(getKorDate()));
  const [uuid, setUuid] = useState();

  const [today, setToday] = useState(today_str(getKorDate()));
  const [subMenu, setSubMenu] = useState('last_which');

  const { loginInfo } = useSelector((state) => state.common);
  const [count, setCount] = useState(reload_second);

  // const dispatch = useDispatch();
  const { url_type } = useParams();
  const getWorkDateList = async () => {
    const infos = await requestLoadWorkDateLogsGetInfos();
    setWorkDateList(infos?.date_list);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        setToday(today_str(getKorDate()));
        if (ct < 0) {
          getWorkDateList();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkDateList();
  }, []);

  return (
    <>
      {/* <Wrapper> */}
      <Grid column={['700rem', '1fr']} gap={32} valign="top" height="1000">
        {/* <Box column> */}
        <Box column gap={12}>
          <Box>
            date logs <TextSmall>{count}</TextSmall>
          </Box>

          {workDateList?.map((e, index) => {
            return (
              <ResultLogBox
                active={today === e.date}
                selected={e.date === checkDate}
                key={index}
                onClick={() => {
                  setUuid(get_uuid());
                  setCheckDate(e.date);
                  //   setUrlTypeLogId(e.id);
                }}
              >
                <Box valign="top" width="100%" gap={12} column>
                  <Box>{e.date}</Box>
                  {/* <Box>{e.fail_count}</Box> */}
                  <Box>
                    상품: <TextSucceed>{e?.count}</TextSucceed> (슬롯:{' '}
                    <TextSucceed>{e?.using_shop_link_count}</TextSucceed>)
                  </Box>
                  <Box>
                    순위: {e?.rank} 순위 상승: {e?.rank_increase_rate} {e?.rank_count}
                  </Box>
                  <Box>
                    유입제제: <TextFail>{e?.etc_do1_foryou_problem_count}</TextFail> (
                    {e?.etc_do1_foryou_no_problem_continuity_days},{' '}
                    {e?.etc_do1_foryou_problem_continuity_days})
                  </Box>
                  <Box>
                    노출완료: {e?.to_view_done_count} 노출: {currencyComma(e?.to_view_count)} /{' '}
                    {currencyComma(e?.to_view_count_max)}
                    <PercentModule
                      value={e?.to_etc_do1_count}
                      valueMax={e?.to_etc_do1_count_max}
                    />{' '}
                    도달률:{' '}
                    <PercentModule
                      succeed_limit={70}
                      value={e?.to_view_count}
                      valueMax={e?.to_view_access_count}
                    />
                  </Box>
                  <Box>
                    유입완료: {e?.to_etc_do1_done_count} 유입: {currencyComma(e?.to_etc_do1_count)}{' '}
                    / {currencyComma(e?.to_etc_do1_count_max)}
                    <PercentModule
                      value={e?.to_etc_do1_count}
                      valueMax={e?.to_etc_do1_count_max}
                    />{' '}
                    도달률:{' '}
                    <PercentModule
                      succeed_limit={70}
                      value={e?.to_etc_do1_count}
                      valueMax={e?.to_etc_do1_access_count}
                    />
                  </Box>
                  <Box>
                    가상유입완료: {e?.to_etc_do2_done_count} 가상유입:{' '}
                    {currencyComma(e?.to_etc_do2_count)} / {currencyComma(e?.to_etc_do2_count_max)}
                    <PercentModule
                      value={e?.to_etc_do2_count}
                      valueMax={e?.to_etc_do2_count_max}
                    />{' '}
                    도달률:{' '}
                    <PercentModule
                      succeed_limit={70}
                      value={e?.to_etc_do2_count}
                      valueMax={e?.to_etc_do2_access_count}
                    />
                  </Box>

                  {/* <Box>{e.aceess_traffic_bytes / e.count}</Box> */}
                  {/* <Box>2_{e.access_using_sec / e.count}</Box> */}
                  {/* <Box>3_{e.access_real_using_sec / e.count}</Box> */}
                </Box>
              </ResultLogBox>
            );
          })}
        </Box>
        {/* </Box> */}
        {/* <Box column> */}
        {/* </Box> */}
      </Grid>
      {/* </Wrapper> */}
    </>
  );
};

const ResultLogBox = styled.button`
  font-size: 14rem;
  display: flex;
  flex-direction: column;
  ${(props) => {
    const { active, selected } = props;
    if (selected && active) {
      return css`
        /* color: #fff; */
        border-radius: 8rem;
        padding: 0 10rem;
        border-color: blue;
        border-width: 1rem;
        border-style: solid;
        font-weight: 500;
        background-color: #f0f0f0;
      `;
    } else if (active) {
      return css`
        /* color: #fff; */
        border-radius: 8rem;
        padding: 0 10rem;
        border-color: blue;
        border-width: 1rem;
        border-style: solid;
        font-weight: 500;
      `;
    } else if (selected) {
      return css`
        /* color: #fff; */
        background-color: #f0f0f0;
      `;
    }
  }}
`;
export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;

export default WorkDateLogs;
