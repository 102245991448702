import { IconButton, InputComponent, Icon } from "Common/UI/atoms";
import React, { useState } from "react";
import Modal from "./Modal";

function Share({
    shareLink,
    children,
    subTit = "이 링크는 결과 공표 14일 이후 만료됩니다",
    onSuccess,
    ...props
}) {
    const onClickCopy = () => {
        navigator.clipboard.writeText(shareLink);
        onSuccess && onSuccess();
    };
    return (
        <Modal
            {...props}
            onSuccess={onClickCopy}
            subTit={subTit}
            size={"sm"}
            close={false}
            successText="URL 복사"
            cancelText="닫기"
        >
            {children}
            <InputComponent
                readOnly
                value={shareLink}
                rightIcon={
                    <IconButton
                        onClick={onClickCopy}
                        Theme={"unFill"}
                        size={"xxxs"}
                        Icon={Icon.Link}
                    />
                }
            />
        </Modal>
    );
}

export default Share;
