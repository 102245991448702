import { Box, Grid } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  requestLoadWorkFinalResultLogsGetInfos,
  requestLoadWorkViewGetInfos,
} from 'Common/Service/common';
import { currencyComma, get_percent, navigate_newtab } from 'Common/Utils';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import MallNameModule from './Module/MallNameModule';
import PercentModule from './Module/PercentModule';
import { SmallDashOutlined } from '@ant-design/icons';
import styled from 'styled-components';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const WorkFinalResultLogs = () => {
  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const [finalResultInfos, setFinalResultInfos] = useState();
  const [count, setCount] = useState(reload_second);

  const [okResultInfos, setOkResultInfos] = useState();
  const [stopKeywordNotEnoughResultInfos, setStopKeywordNotEnoughResultInfos] = useState();
  const [getKeywrodFailedResultInfos, setGetKeywrodFailedResultInfos] = useState();
  const [etcDo1ProblemfinalResultInfos, setEtcDo1ProblemfinalResultInfos] = useState();

  const getFinalResultInfo = async () => {
    var ok_result_infos = [];
    var get_keyword_failed_result_infos = [];
    var stop_keyword_not_enough_result_infos = [];
    var etc_do1_problem_result_infos = [];
    const final_result_infos = await requestLoadWorkFinalResultLogsGetInfos({});
    final_result_infos?.result_infos?.forEach((e) => {
      if (
        e?.product_state?.keyword_state != 'get_keyword_failed' &&
        e?.product_state?.work_state === 'working'
      ) {
        if (e?.product_state?.keyword_state === 'stop_keyword_not_enough') {
          stop_keyword_not_enough_result_infos.push(e);
        } else {
          ok_result_infos.push(e);
        }
      } else if (
        e?.product_state?.keyword_state === 'get_keyword_failed' &&
        e?.product_state?.work_state === 'working'
      ) {
        get_keyword_failed_result_infos.push(e);
      } else if (
        e?.product_state?.keyword_state != 'get_keyword_failed' &&
        e?.product_state?.work_state != 'working'
      ) {
        etc_do1_problem_result_infos.push(e);
      }
    });
    setOkResultInfos(ok_result_infos);
    setGetKeywrodFailedResultInfos(get_keyword_failed_result_infos);
    setEtcDo1ProblemfinalResultInfos(etc_do1_problem_result_infos);
    setStopKeywordNotEnoughResultInfos(stop_keyword_not_enough_result_infos);
    setFinalResultInfos(final_result_infos);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getFinalResultInfo();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getFinalResultInfo();
  }, []);
  return (
    <>
      <Box column>
        <Box>
          <Box>
            전체 진행 상황 <TextSmall> Final Result Progress</TextSmall> [
            <TextSucceed>{currencyComma(finalResultInfos?.result_infos?.length)}</TextSucceed>]
          </Box>
          <Box>
            <>
              [
              <PercentModule
                value={finalResultInfos?.to_count}
                valueMax={finalResultInfos?.to_count_max}
              />
              ] {currencyComma(finalResultInfos?.to_count)} /{' '}
              {currencyComma(finalResultInfos?.to_count_max)}
            </>
            <TextSmall>{count}</TextSmall>
          </Box>
        </Box>
        <Box top={-30}>
          <Grid column={['1fr', '1fr', '1fr', '1fr']} gap={1} valign="top" height="1000">
            {[
              okResultInfos,
              stopKeywordNotEnoughResultInfos,
              getKeywrodFailedResultInfos,
              etcDo1ProblemfinalResultInfos,
            ].map((list, index) => {
              return (
                <FinalResultbox key={index}>
                  <Box>
                    {index === 0 ? (
                      <>
                        <TextSucceed>정상</TextSucceed>[
                        <TextSucceed>{currencyComma(list?.length)}</TextSucceed>]
                      </>
                    ) : index === 1 ? (
                      <>
                        <TextWarning>키워드 부족 종료</TextWarning>[
                        <TextWarning>{currencyComma(list?.length)}</TextWarning>]
                      </>
                    ) : index === 2 ? (
                      <>
                        <TextWarning>키워드 수집 실패</TextWarning>[
                        <TextWarning>{currencyComma(list?.length)}</TextWarning>]
                      </>
                    ) : index === 3 ? (
                      <>
                        <TextFail>유입제제</TextFail> [
                        <TextFail>{currencyComma(list?.length)}</TextFail>]
                      </>
                    ) : null}
                  </Box>
                  <Box column gap={24}>
                    {list
                      // ?.filter((e) => {
                      //   if (index === 0) {
                      //     //정상
                      //     if (e?.product_state?.keyword_state === 'get_keyword_failed')
                      //       return false;
                      //     if (e?.product_state?.work_state != 'working') return false;
                      //     return true;
                      //   } else if (index === 1) {
                      //     //키워드 부족
                      //     if (e?.product_state?.keyword_state != 'get_keyword_failed') return false;
                      //     if (e?.product_state?.work_state != 'working') return false;
                      //     return true;
                      //   } else if (index === 2) {
                      //     //유입오류
                      //     if (e?.product_state?.keyword_state === 'get_keyword_failed')
                      //       return false;
                      //     if (e?.product_state?.work_state != 'stop_etc_do1_problem') return false;
                      //     return true;
                      //   } else return false;
                      // })
                      ?.map((e, index) => {
                        return (
                          <Box key={index} column>
                            <Box>
                              <Box>{index + 1}</Box>
                              <MallNameModule
                                onClick={() => {
                                  navigate_newtab(
                                    `/work/result_logs?product_info_id=${e?.shop_info?.product_info_id}`,
                                  );
                                }}
                                {...e?.shop_info}
                              />{' '}
                              <TextSmall>slots: {e?.slot_count}</TextSmall>
                            </Box>
                            <Box gap={14}>
                              <Box>Final Progress</Box>
                              <Box>
                                [
                                <PercentModule
                                  value={e?.to_count}
                                  valueMax={e?.to_count_max}
                                />] {e?.to_count} / {e?.to_count_max}
                              </Box>
                              <Box>
                                <progress value={e?.to_count} max={e?.to_count_max} />
                              </Box>
                            </Box>
                            <Box gap={12}>
                              <Box>
                                <Box>Do </Box>
                                <Box>
                                  {e?.to_etc_do1_count_max <= 0 ? (
                                    <>
                                      <TextFail>유입 서비스 없음</TextFail>
                                    </>
                                  ) : (
                                    <>
                                      [
                                      <PercentModule
                                        value={e?.to_etc_do1_count}
                                        valueMax={e?.to_etc_do1_count_max}
                                      />
                                      ] {currencyComma(e?.to_etc_do1_count)} /{' '}
                                      {currencyComma(e?.to_etc_do1_count_max)}
                                    </>
                                  )}
                                </Box>
                              </Box>
                              <Box>
                                <Box>View </Box>
                                <Box>
                                  {e?.to_view_count_max <= 0 ? (
                                    <>
                                      <TextFail>노출 서비스 없음</TextFail>
                                    </>
                                  ) : (
                                    <>
                                      [
                                      <PercentModule
                                        value={e?.to_view_count}
                                        valueMax={e?.to_view_count_max}
                                      />
                                      ] {currencyComma(e?.to_view_count)} /{' '}
                                      {currencyComma(e?.to_view_count_max)}
                                    </>
                                  )}
                                </Box>
                              </Box>
                              <Box>
                                <Box>Fake Do </Box>
                                <Box>
                                  {e?.to_view_count_max <= 0 ? (
                                    <>
                                      <TextFail>F유입 서비스 없음</TextFail>
                                    </>
                                  ) : (
                                    <>
                                      [
                                      <PercentModule
                                        value={e?.to_etc_do2_count}
                                        valueMax={e?.to_etc_do2_count_max}
                                      />
                                      ] {currencyComma(e?.to_etc_do2_count)} /{' '}
                                      {currencyComma(e?.to_etc_do2_count_max)}
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box gap={8}>
                              {e?.shop_info?.check_etc_do1_access_count > 0 ? (
                                <Box>
                                  <Box>유입(Do) 성공률</Box>
                                  <Box>
                                    <TextSucceed>
                                      {e?.shop_info?.check_etc_do1_ok_count}
                                    </TextSucceed>
                                    /{e?.shop_info?.check_etc_do1_access_count}{' '}
                                    <PercentModule
                                      value={e?.shop_info?.check_etc_do1_ok_count}
                                      valueMax={e?.shop_info?.check_etc_do1_access_count}
                                    />
                                  </Box>
                                </Box>
                              ) : null}
                              {e?.shop_info?.to_view_access_count > 0 ? (
                                <Box>
                                  <Box>노출(View) 성공률</Box>
                                  <Box>
                                    <TextSucceed>{e?.shop_info?.to_view_count}</TextSucceed>/
                                    {e?.shop_info?.to_view_access_count}{' '}
                                    <PercentModule
                                      value={e?.shop_info?.to_view_count}
                                      valueMax={e?.shop_info?.to_view_access_count}
                                    />
                                  </Box>
                                </Box>
                              ) : null}
                            </Box>
                            <Box>
                              <Box>
                                {e?.product_state?.shop_info_chk ? (
                                  <>
                                    <TextSucceed>
                                      샵 정보 수집 완료 <TextSmall>Shop Info Updated</TextSmall>
                                    </TextSucceed>
                                  </>
                                ) : (
                                  <>
                                    <TextFail>
                                      샵 정보 수집 안됨 <TextSmall>Shop Info No Updated</TextSmall>
                                    </TextFail>
                                  </>
                                )}
                              </Box>
                              <Box>
                                {e?.product_state?.keyword_state === 'get_keyword_failed' ? (
                                  <>
                                    <TextFail>
                                      키워드 수집 실패 <TextSmall>Get Keyword Failed</TextSmall>
                                      {e?.product_state?.keyword_sub_state ===
                                      'get_keyword_failed_all' ? (
                                        <>
                                          <>
                                            {' '}
                                            <TextSmall>(Tonghap,Shop)</TextSmall>
                                          </>
                                        </>
                                      ) : e?.product_state?.keyword_sub_state ===
                                        'get_keyword_failed_tonghap' ? (
                                        <>
                                          {' '}
                                          <TextSmall>(Tonghap)</TextSmall>
                                        </>
                                      ) : e?.product_state?.keyword_sub_state ===
                                        'get_keyword_failed_shop' ? (
                                        <>
                                          <>
                                            {' '}
                                            <TextSmall>(Shop)</TextSmall>
                                          </>
                                        </>
                                      ) : (
                                        <>Error</>
                                      )}
                                    </TextFail>
                                  </>
                                ) : e?.product_state?.keyword_state === 'keyword_enough' ? (
                                  <>
                                    <TextSucceed>
                                      키워드 수집 완료 <TextSmall>Get Keyword Done</TextSmall>{' '}
                                    </TextSucceed>
                                  </>
                                ) : e?.product_state?.keyword_state === 'keyword_not_enough' ? (
                                  <>
                                    <TextWarning>
                                      키워드 부족 <TextSmall>Not enough keywords</TextSmall>
                                      {e?.product_state?.keyword_sub_state ===
                                      'keyword_not_enough_all' ? (
                                        <>
                                          <>
                                            {' '}
                                            <TextSmall>(Tonghap,Shop)</TextSmall>
                                          </>
                                        </>
                                      ) : e?.product_state?.keyword_sub_state ===
                                        'keyword_not_enough_tonghap' ? (
                                        <>
                                          {' '}
                                          <TextSmall>(Tonghap)</TextSmall>
                                        </>
                                      ) : e?.product_state?.keyword_sub_state ===
                                        'keyword_not_enough_shop' ? (
                                        <>
                                          <>
                                            {' '}
                                            <TextSmall>(Shop)</TextSmall>
                                          </>
                                        </>
                                      ) : (
                                        <>Error</>
                                      )}
                                    </TextWarning>
                                  </>
                                ) : e?.product_state?.keyword_state ===
                                  'stop_keyword_not_enough' ? (
                                  <>
                                    <TextFail>
                                      키워드 부족 중지 <TextSmall>Paused (Low Keywords)</TextSmall>
                                    </TextFail>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <Box>
                                {e?.product_state?.work_state === 'working' ? (
                                  <>
                                    <TextSucceed>
                                      정상 <TextSmall>Ok</TextSmall>
                                    </TextSucceed>
                                  </>
                                ) : e?.product_state?.work_state === 'stop_etc_do1_problem' ? (
                                  <>
                                    <TextFail>
                                      유입 오류로 중지 <TextSmall>Do Error Stop</TextSmall>
                                    </TextFail>
                                  </>
                                ) : (
                                  <>
                                    <TextFail>
                                      오류로 중지 <TextSmall>Error Stop</TextSmall>
                                    </TextFail>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </FinalResultbox>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
const FinalResultbox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* min-width: 350rem; */
`;
export default WorkFinalResultLogs;
