import styled from "styled-components";

export const Wrapper = styled.button`
    ${({ color, isSelected, disabled }) => `
    display:flex;
    flex-direction:column;
    ${disabled ? "cursor:no-drop;" : ""}
    align-items:center;
    padding:38rem;
    flex:1 1;
    border-radius:16rem;
    box-shadow:var(--Elevation_002);
    transition:0.3s;
    background-color:var(--${isSelected ? "P_60" : "White"});
    min-height:440rem;
    ${
        isSelected
            ? ""
            : `
        &:hover{
            background-color:var(--P_25);
            box-shadow:var(--Elevation_004);
        }
        &:focus{
            background-color:var(--P_50);
        }
    `
    }
    &:disabled {
        background: var(--G_50);
        box-shadow: var(--Elevation_002);
    }
    >svg{
        margin-bottom:40rem;
    }
    >h3{
        font-size:16rem;
        font-weight:400;
        color:var(--${disabled ? "G_150" : color}); 
        margin-bottom:8rem;
        width:max-content;
        white-space: pre-line;
        text-align:center;
    }
    >span{
        font-size:12rem;
        color:var(--${disabled ? "G_150" : "G_500"});
        font-weight:400;
    }
    div{
        p{
            display:flex;
            font-size:14rem;
            line-height: 172%;
            text-align:left;
            color:var(--${disabled ? "G_150" : "G_700"});
            flex: 1 1;
        }
        svg{
            margin-top:2rem;
        }
    }
`}
`;
