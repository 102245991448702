import { Box, DropBox } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PaginationComponent from './PaginationComponent';
import { setPaging } from 'features/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';

const PaginationBox = ({ allList, checkList, setCheckList, setList }) => {
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const { paging } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const onPage = (page) => {
    setPage(page);
  };
  useEffect(() => {
    if (!allList) return;

    const list_len = allList?.length;
    const n = list_len % pageSize;
    var page_max = parseInt(list_len / pageSize);
    if (n > 0) page_max += 1;
    const start_n = (page - 1) * pageSize;
    const end_n = start_n + pageSize;
    const t_list = allList.slice(start_n, end_n);
    setPageMax(page_max);
    setList(t_list);
    setCheckList && setCheckList([]);
  }, [pageSize, page, allList]);
  return (
    <ButtonBlock>
      <Box>
        {allList?.length} {checkList?.length ? `[${checkList?.length}]` : ''}
      </Box>
      <Box>
        <PaginationComponent page={page} pageMax={pageMax} onPage={onPage} />
      </Box>
      <Box>
        <DropBox
          line
          option={[
            { value: 10000, title: '10000개' },
            { value: 3000, title: '3000개' },
            { value: 1000, title: '1000개' },
            { value: 500, title: '500개' },
            { value: 300, title: '300개' },
            { value: 100, title: '100개' },
          ].map((e) => {
            if ((paging === undefined && e.value === 1000) || paging === e.value) {
              e.selected = true;
            }
            return e;
          })}
          onChange={(_a, value) => {
            dispatch(setPaging(value));
            setPageSize(value);
          }}
          size={40}
          isColor
        />
      </Box>
    </ButtonBlock>
  );
};

const ButtonBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-height: 50rem;
  padding: 5rem;
  padding-left: 10rem;
  &:hover {
    background-color: #f0f0f0;
  }
`;
export default PaginationBox;
