import { valueSet } from 'Common/Utils/util';
import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from './IconBox';

const CompressionText = ({ children, height = 100, clamp = 2, style }) => {
  const [view, setView] = useState(false);

  return (
    <Wrapper clamp={clamp} active={view} sizeY={valueSet(height)} styles={style}>
      <div>
        <div>{children}</div>
        <button onClick={() => setView(!view)}>
          <Icon.Screen isActive={!view} size={12} />
        </button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ active, sizeY, clamp, styles }) => `
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    width:100%;
    height:${sizeY};
    z-index:${active ? 10 : 0};
    >div{
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:flex-end;
        background:${active ? '#F6F8FF' : 'rgba(255,255,255,0)'};
        border-radius:4rem;
        >div{
            position:relative;
            width:100%;
            display:flex;
            flex-direction:column;
            height:${active ? 'fit-content' : sizeY};
            ${active ? '' : 'overflow:hidden;'}
            top:0;
            padding:${active ? '16rem 16rem 10rem' : '0rem'};
            z-index:${active ? 1 : -1};
        }
        >button{
            display:flex
            justify-content:center;
            align-items:center;
            margin:6rem;
            z-index:1;
        }
        p{
            word-break:break-all;;
            white-space: pre-line;
        }
        ${
          active
            ? ''
            : `
        .clamp{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: ${clamp};
            -webkit-box-orient: vertical;
        }
        `
        }
    }
    ${styles || ''}
`}
`;

export default CompressionText;
