import { Box, Button, CheckBox, DropBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextSmall, TextSucceed, reload_second } from 'Common/Utils/util';
import { requestLoadWorkVmwareMakeOrder } from 'Common/Service/common';
import { onShowToast } from 'features/common/commonSlice';

const WorkVmwareOrderMakeOrder = () => {
  const [orderParamInfos, setOrderParamInfos] = useState({});
  const { loginInfo } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([
    // {
    //   title: 'cmd server',
    //   value: {
    //     order: 'cmd server',
    //     order_code: 'cmd_server_run',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: '',
    //     order_network_type: '',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 10000,
    //   },
    // },
    {
      title: 'reboot',
      value: {
        order: '재부팅 (reboot)',
        order_code: 'reboot',
        param1: '',
        param2: '',
        param3: '',
        order_workstation_name: 'test1',
        order_vmware_type: 'do',
        order_network_type: '',
        start_delay_min: '0',
        is_fiddler_chk: false,
        order_min: '10',
        run_vmwares_count_max: 10000,
      },
    },
    {
      title: 'shutdown',
      value: {
        order: '컴퓨터 종료 (shutdown)',
        order_code: 'shutdown',
        param1: '',
        param2: '',
        param3: '',
        order_workstation_name: 'test1',
        order_vmware_type: '',
        order_network_type: '',
        start_delay_min: '0',
        is_fiddler_chk: false,
        order_min: '10',
        run_vmwares_count_max: 10000,
      },
    },
    // {
    //   title: '유입 (do)',
    //   value: {
    //     order: '유입 (do)',
    //     order_code: 'do',
    //     param1: '5',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'fiber',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 10000,
    //   },
    // },
    // {
    //   title: '노출 (View)',
    //   value: {
    //     order: '노출 (View)',
    //     order_code: 'view',
    //     param1: '4',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 4,
    //   },
    // },
    // {
    //   title: 'F 유입 (f do) lte',
    //   value: {
    //     order: 'F 유입 (f do) lte',
    //     order_code: 'f_do_lte',
    //     param1: '5',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: 'F 유입 (f do) proxy',
    //   value: {
    //     order: 'F 유입 (f do) proxy',
    //     order_code: 'f_do_proxy',
    //     param1: '15',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'fiber',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: 'Get Tonghap Keyword',
    //   value: {
    //     order: 'Tonghap 키워드 수집 (Get Tonghap Keywords)',
    //     order_code: 'tonghap_keyword',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: true,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: 'Get Shop Keyword',
    //   value: {
    //     order: 'Shop 키워드 수집 (Get Shop Keywords)',
    //     order_code: 'shop_keyword',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: true,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: '키워드 수집 (All)',
    //   value: {
    //     order: '키워드 수집 (Get All Keywords)',
    //     order_code: 'random_keyword',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: true,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: 'Slot Info Update',
    //   value: {
    //     order: '슬롯 정보 업데이트 (slot_update)',
    //     order_code: 'slot_update',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: 'rank',
    //   value: {
    //     order: 'rank',
    //     order_code: 'rank',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: 'sbth',
    //   value: {
    //     order: 'sbth',
    //     order_code: 'sbth',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: 'ready',
    //     order_network_type: 'lte',
    //     start_delay_min: '0',
    //     is_fiddler_chk: true,
    //     order_min: '5',
    //     run_vmwares_count_max: 1,
    //   },
    // },
    // {
    //   title: '중지 (stop)',
    //   value: {
    //     order: '중지 (stop)',
    //     order_code: 'stop',
    //     param1: '',
    //     param2: '',
    //     param3: '',
    //     order_vmware_type: '',
    //     order_network_type: '',
    //     start_delay_min: '0',
    //     is_fiddler_chk: false,
    //     order_min: '5',
    //     run_vmwares_count_max: 10000,
    //   },
    // },
  ]);
  const orderFunc = async () => {
    const info = orderParamInfos;
    if (!info?.order_code) return;
    const result = await requestLoadWorkVmwareMakeOrder(info);
    if (result) {
      dispatch(
        onShowToast({
          status: 'success',
          message: `Maked order Id (${result})`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  return (
    <Box column gap={24}>
      <Box>
        <TextSmall>vmware 자동 명령</TextSmall>
      </Box>
      <Box>
        <Button
          disabled={!orderParamInfos?.order_code}
          onClick={async () => {
            orderFunc();
          }}
          size={'md'}
          fill
        >
          Order
        </Button>
      </Box>
      <Box column gap={8}>
        <Box>
          <DropBox
            fontSize={14}
            placeholder={'Select Order'}
            option={orderList?.map((i) => {
              return {
                ...i,
                selected: i.value?.order_code === orderParamInfos?.order_code,
              };
            })}
            onChange={(_, value) => {
              setOrderParamInfos(value);
            }}
          />
        </Box>
        <Box>{orderParamInfos?.order}</Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>명령</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              fill
              name={'order'}
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.order}
              onChange={(i) => {
                setOrderParamInfos({
                  ...orderParamInfos,
                  [i?.target?.name]: i?.target?.value,
                });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>code</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'order_code'}
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.order_code}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>설명</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'status'}
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.status}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>컴퓨터명</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'order_workstation_name'}
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              placeholder="공백시 전체"
              value={orderParamInfos?.order_workstation_name}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        {/* <Box>
          <Box width={'30%'}>
            <TextSmall>value1</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'param1'}
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.param1}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>value2</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'param2'}
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.param2}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>value3</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'param3'}
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.param3}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box> */}
        <Box>
          <Box width={'30%'}>
            <TextSmall>vms ct</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'run_vmwares_count_max'}
              placeholder="10000"
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.run_vmwares_count_max}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>vm type</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'order_vmware_type'}
              placeholder="ready, do, ..."
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.order_vmware_type}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>network</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'order_network_type'}
              placeholder="fiber, lte"
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.order_network_type}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>start delay</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'start_delay_min'}
              placeholder="min"
              fontSize={12}
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.start_delay_min}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>order min</TextSmall>
          </Box>
          <Box width={'70%'}>
            <InputComponent
              name={'order_min'}
              fontSize={12}
              placeholder="min"
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.order_min}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box width={'30%'}>
            <TextSmall>fiddler</TextSmall>
          </Box>
          <Box width={'70%'}>
            <CheckBox
              checked={orderParamInfos?.is_fiddler_chk}
              onChange={() => {
                setOrderParamInfos({
                  ...orderParamInfos,
                  is_fiddler_chk: !orderParamInfos?.is_fiddler_chk,
                });
              }}
            />
          </Box>
        </Box>
        <Box column>
          <Box>
            <TextSmall>명령 내릴 vmwares order vmwares</TextSmall>
          </Box>
          <Box>
            <InputComponent
              name={'need_order_vmwares'}
              fontSize={12}
              placeholder="w1_n01, w1_n02, etc"
              size={30}
              weight={400}
              maxLength={12}
              value={orderParamInfos?.need_order_vmwares}
              onChange={(i) => {
                setOrderParamInfos({ ...orderParamInfos, [i?.target?.name]: i?.target?.value });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkVmwareOrderMakeOrder;
