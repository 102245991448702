import React from 'react';
import styled, { css } from 'styled-components';

const Tooltip = ({
  children,
  fill = 'var(--P_75)',
  gap = 8,
  isActive = true,
  padding = '8rem 12rem',
  direction,
  className,
}) => {
  const setting = (data) => {
    switch (data) {
      case 'left':
        return {
          size: '0 0 12 16',
          gap: `right:calc(100% + ${gap + 16}rem);
                    top:50%;
                    justify-content:flex-end;
                    align-items:center;
                    `,
          svgCss: `
                        width:12rem;
                        left:calc(100% - 2rem);
                    `,
          item: (
            <path
              d="M10.7519 7.16795C11.3457 7.56377 11.3457 8.43623 10.7519 8.83205L1.5547 14.9635C0.890144 15.4066 0 14.9302 0 14.1315V1.86852C0 1.06982 0.890145 0.59343 1.5547 1.03647L10.7519 7.16795Z"
              fill={fill}
            />
          ),
        };
      case 'right':
        return {
          size: '0 0 12 16',
          gap: `
                    left:calc(100% + ${gap + 16}rem);
                    top:50%;
                    justify-content:flex-start;
                    align-items:center;
                    `,
          svgCss: `
                        width:12rem;
                        right:calc(100% - 2rem);
                    `,
          item: (
            <path
              d="M1.24807 8.83205C0.654342 8.43623 0.654342 7.56377 1.24808 7.16795L10.4453 1.03647C11.1099 0.59343 12 1.06982 12 1.86852V14.1315C12 14.9302 11.1099 15.4066 10.4453 14.9635L1.24807 8.83205Z"
              fill={fill}
            />
          ),
        };
      case 'bottom':
        return {
          size: '0 0 16 12',
          gap: `top:calc(100% + ${gap + 12}rem);
                    left:50%;
                    justify-content:center;
                    align-items:flex-start;
                    `,
          svgCss: `
                        width:16rem;
                        bottom:calc(100% - 2rem);
                    `,
          item: (
            <path
              d="M6.66795 1.24807C7.06377 0.654342 7.93623 0.654342 8.33205 1.24808L14.4635 10.4453C14.9066 11.1099 14.4302 12 13.6315 12H1.36852C0.569822 12 0.0934303 11.1099 0.536467 10.4453L6.66795 1.24807Z"
              fill={fill}
            />
          ),
        };

      default:
        return {
          size: '0 0 16 12',
          gap: `
                    bottom:calc(100% + ${gap + 12}rem);
                    left:50%;
                    justify-content:center;
                    align-items:flex-end;
                    `,
          svgCss: `
                        width:16rem;
                        top:calc(100% - 2rem);
                    `,
          item: (
            <path d="M7.16795 10.7519C7.56377 11.3457 8.43623 11.3457 8.83205 10.7519L14.9635 1.5547C15.4066 0.890144 14.9302 0 14.1315 0H1.86852C1.06982 0 0.59343 0.890145 1.03647 1.5547L7.16795 10.7519Z" />
          ),
        };
    }
  };

  return (
    <Wrapper
      className={className}
      side={gap}
      fill={fill}
      padding={padding}
      active={isActive}
      gap={setting(direction).gap}
      svgCss={setting(direction).svgCss}
    >
      <aside>
        {children}
        <svg className="svg" viewBox={setting(direction).size} fill="none">
          {setting(direction).item}
        </svg>
      </aside>
    </Wrapper>
  );
};

const ellipsis = css`
  ${({ theme: { ellipsis } }) => {
    const ellipsisCss = ellipsis('1');
    return css`
      ${ellipsisCss};
    `;
  }}
`;

const Wrapper = styled.div`
  ${({ svgCss, side, gap, padding, fill, active }) => `
        position:absolute;
        // width:0;
        // height:0;
        
        ${gap}
        display:${active ? 'flex' : 'none'};
        aside{
            position:absolute;
            width:max-content;
            padding:${padding};
            font-size:14rem;
            color:var(--G_700);
            display:flex;
            justify-content:center;
            align-items:center;
            background-color:${fill};
            border-radius:6rem;
            flex-direction: column;
            width: 278rem;
            p {
                display: flex;
                width: 100%;
                height: 2em;
                color: #5b637c;
                cursor: pointer;
                svg {
                    display: flex;
                    flex-basis: 16rem;
                    vertical-align: middle;
                }
                span {
                    display: block;
                    // display: flex;
                    // flex-basis: 2;
                    line-height: 2em;
                    margin-left: 5rem;
                    font-size: 14rem;
                    text-align: left;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                button {
                    display: flex;
                    flex-basis: 16rem;
                    margin-left: auto;
                }
            }
            .svg{
                position:absolute;
                ${svgCss}
                fill:${fill};
            }
            .box {
                justify-content: flex-start;
                width: 100%;
                + .box {
                    margin-top: 16rem;
                }
            }
            &::after{
                content:'';
                position:absolute;
                left:0;
                width:100%;
                height:${side + 12}rem;
                top:100%;
            }
        }
    `}
`;

export default Tooltip;
