import React from 'react';
import styled, { css } from 'styled-components';
import Icon from './IconBox';

function TextBox({ children, onClick, isActive, size, error }) {
  return (
    <Wrapper className="textBox">
      <WrapperInfo isActive={isActive} className="textBox">
        <p>{children}</p>
        <span>
          {isActive ? <Icon.Complate full size={'sm'} /> : <Icon.Danger full size={'sm'} />}
        </span>
        <span className="warningInfo">{error}</span>
        <span className="fileSizeInfo">{`${size}`}</span>
      </WrapperInfo>
      <button className="deleteBtn" type="button" onClick={onClick}>
        <Icon.Trash size="20" />
      </button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  padding-right: 28rem;
  .deleteBtn {
    margin-left: 8rem;
  }
`;

const WrapperInfo = styled(Wrapper)`
  ${({ isActive, theme: { colors, ellipsis } }) => {
    const ellipsisCss = ellipsis('1');
    return css`
      background: ${isActive ? `${colors.primaryBg}` : colors.errorBg};
      display: flex;
      border-radius: 8rem;
      width: 100%;
      padding: 10rem 20rem 6rem 20rem;
      color: ${isActive ? '#555' : colors.error};
      p {
        font-weight: 400;
        font-size: 14rem;
        line-height: 140%;
        margin-right: 20rem;
        color: inherit;
        ${ellipsisCss};
      }

      .warningInfo {
        flex-grow: 1;
        color: ${colors.error};
        margin-left: 12rem;
        font-size: 14rem;
      }
      .fileSizeInfo {
        font-weight: 400;
        font-size: 12rem;
        color: #94a1c7;
      }
    `;
  }}
`;

export default TextBox;
