import { Box, InputComponent, TextButton } from 'Common/UI/atoms';
import { viewDate } from 'Common/Utils/date';
import moment from 'moment';
import React, { useEffect } from 'react';
import useDatepicker from '../useDatepicker';
import DatepickerReact from './DatepickerReact';
import { BottomSection } from './styled';

function DatepickerMutiple({
  onChange = () => '',
  name,
  value,
  date = value,
  className,
  size = 44,
  placeholder,
  disabled,
  minDate,
  maxDate,
  type,
  DateHeader,
  showMonthYearPicker,
  format = 'completeDate',
}) {
  const {
    state,
    ref,
    onChange: onChangeDate,
    onToggleMonth,
    isMonth,
    onCurrentMont,
    getRangValue,
    onClearDate,
  } = useDatepicker({
    start: date?.start,
    end: date?.end,
  });
  useEffect(() => {
    if (
      viewDate(value?.start) === viewDate(state?.start) &&
      viewDate(value?.end) === viewDate(state?.end)
    )
      return;
    onChange(name, {
      start: moment(state.start).format(),
      end: moment(state.end).format(),
    });
  }, [state]);

  useEffect(() => {
    onChangeDate({
      start: new Date(date?.start),
      end: new Date(date?.end),
    });
  }, [date]);
  return (
    <Box gap="20rem">
      <InputComponent
        className={className}
        value={getRangValue(state)(format)}
        name={name}
        readOnly
        size={size}
        placeholder={placeholder ? placeholder : '기간을 선택하세요'}
        disabled={disabled}
        rightIcon={
          <DatepickerReact
            selected={state?.start}
            onChange={(dates) => {
              onChangeDate({
                start: dates[0],
                end: dates[1],
              });
            }}
            ref={ref}
            selectsRange={true}
            openToDate={state?.start ? state?.start : null}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            showMonthYearPicker={showMonthYearPicker}
            startDate={state?.start}
            endDate={state?.end}
            renderCustomHeader={({ ...props }) => (
              <DateHeader
                {...props}
                type={type}
                onClick={onToggleMonth}
                onCurrentMont={onCurrentMont}
                state={state?.start}
                isActive={isMonth}
                onChange={(date) => {
                  onChangeDate({
                    start: date,
                    end: '',
                  });
                }}
                onToggle={onToggleMonth}
              />
            )}
          >
            {!state?.start ? (
              <BottomSection className="bottom">
                <span>시작일을 선택하세요</span>
              </BottomSection>
            ) : (
              <BottomSection className="bottom">
                <span>{!state?.end && '종료일을 선택하세요'}</span>
                <TextButton
                  size={'xxs'}
                  onClick={() =>
                    onClearDate({
                      start: '',
                      end: '',
                    })
                  }
                >
                  초기화
                </TextButton>
              </BottomSection>
            )}
          </DatepickerReact>
        }
      />
    </Box>
  );
}

export default DatepickerMutiple;
