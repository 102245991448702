import { useSearch } from 'Common/Hooks';
import React, { useEffect, useRef, useState } from 'react';
import DropBadgeList from '../atoms/DropBadgeList';
import Icon from '../atoms/IconBox';
import InputComponent from '../atoms/Input/InputComponent';
import { InputWrapper } from '../atoms/Input/styled';

const DropBadgeInput = ({
  name,
  size,
  value,
  option = [],
  onChange,
  placeholder,
  disabled,
  readOnly,
  isSingle,
  onDelete,
  onClick,
  onAllDelete,
  leftIcon,
  isKeyControll = true,
  arrow,
  className,
}) => {
  const [isActive, setIsActive] = useState();
  const [direction, setDirection] = useState('buttom');
  const ref = useRef();
  const {
    searchValue,
    isSearch,
    onSearch,
    searchMatching, // search === data matching
    searchSlice, // data <span>search</span>
  } = useSearch();
  useEffect(() => {
    onSearch && onSearch({ target: { name: 'search', value: '' } });
    !isActive && propsRef.current.blur();
  }, [isActive]);
  const optionFilter = !isSearch
    ? option
        ?.filter(({ title }) => searchMatching(title))
        .map(({ title, subTitle, value, ...e }) => {
          return {
            title: (
              <>
                {searchSlice(title)}
                {subTitle}
              </>
            ),

            value,
            ...e,
          };
        })
    : option?.map(({ title, subTitle, value, ...e }) => ({
        title: (
          <>
            {title}
            {subTitle}
          </>
        ),
        value,
        ...e,
      }));
  const propsRef = useRef();

  const textList = (() => {
    const isObject = typeof value === 'object';
    const isArray = Array.isArray(value);
    let text = '';
    const nameValue = (i) => i?.title || i?.name || i?.memberName || i?.teamName || '';
    isObject
      ? !isArray
        ? (text += nameValue(value))
        : value?.map((i, key) =>
            typeof i === 'object'
              ? (text += (key ? ', ' : '') + nameValue(i))
              : option?.map(({ title, value }) =>
                  typeof value === 'object'
                    ? (text += (key ? ', ' : '') + nameValue(i))
                    : (text += value === i ? (key ? ', ' : '') + title : ''),
                ),
          )
      : (text = [...(option || [])].filter((i) => i.value === value)[0] || value);
    return text;
  })();
  const position = () => {
    const layout = document.getElementById('layout').getBoundingClientRect();
    const layoutY = layout.y;
    const layoutHeight = layout.height;
    const target = propsRef.current.getBoundingClientRect();
    const targetY = target.y + target.height + 50;

    const childHeight = Math.min(300, (optionFilter?.length || 0) * 48);
    const y = layoutHeight + layoutY;
    const filter = y > targetY + childHeight ? 'bottom' : 'top';
    filter !== direction && setDirection(filter);
  };

  useEffect(position, [optionFilter, isActive]);

  return (
    <InputWrapper ref={ref} className={className}>
      <InputComponent
        autocomplete
        leftIcon={leftIcon}
        ref={propsRef}
        size={size}
        onFocus={() => setIsActive(true)}
        onClick={() => setIsActive(true)}
        placeholder={placeholder}
        value={isActive ? searchValue : textList}
        name="search"
        onChange={onSearch}
        disabled={disabled}
        onCancel={() => {
          setIsActive(true);
          propsRef.current.focus();
        }}
        readOnly={readOnly}
        isDelete={isActive}
        rightIcon={arrow ? <Icon.Arrow size={20} /> : null}
      />
      <DropBadgeList
        onChange={onChange}
        propsRef={propsRef}
        onDelete={onDelete}
        position={direction}
        isSingle={isSingle}
        searchValue={searchValue}
        onAllDelete={onAllDelete}
        onClick={() => {
          onClick();
          setIsActive(false);
        }}
        value={value}
        name={name}
        onClose={() => setIsActive(false)}
        isActive={isActive}
        defaultOption={option}
        option={optionFilter}
        parent={ref.current}
        isKeyControll={isKeyControll}
      />
    </InputWrapper>
  );
};

export default DropBadgeInput;
