import { API_SERVER_URL } from 'Common/Env';
import { auth_headers } from 'Common/Utils';
import axios from 'axios';
export const requestLoadShopLinkCountGetDate = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/shop_link_count/get/date`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
