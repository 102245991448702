import React, { useState } from 'react';
import Icon from 'Common/UI/atoms/IconBox';
import { Box, TextButton } from 'Common/UI/atoms';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import SideMenuNavi from 'Components/UI/molecules/SideMenu/SideMenuNavi';
import { useDispatch, useSelector } from 'react-redux';
import { removeCookie } from 'Common/Utils';
import { token_name } from 'Common/Env';
import { setLoginInfo } from 'features/common/commonSlice';
import { TextSmall, useForceUpdate } from 'Common/Utils/util';
import UserModifyModal from 'Page/User/UserModifyModal';

const LeftMenu = ({}) => {
  const [isTab, setIsTab] = useState(null);
  const { loginInfo } = useSelector((state) => state.common);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [menuSize, setMenuSize] = useState(loginInfo?.user_level === 5 ? 50 : 60);
  const [userModifyModalStatus, setUserModifyModalStatus] = useState({
    isActive: false,
    onSuccess: () => {
      setUserModifyModalStatus({ ...userModifyModalStatus, isActive: false });
    },
    onClose: () => {
      setUserModifyModalStatus({ ...userModifyModalStatus, isActive: false });
    },
  });
  return (
    <Wrapper className="sideMenu">
      <Box
        width={'100%'}
        align={'center'}
        column
        gap={10}
        // gap={56}
      >
        {loginInfo?.user_level === 5 ? (
          ''
        ) : (
          <Logo onClick={() => navigate('/')}>
            <img src="/favicon.png" width="100%" />
          </Logo>
        )}
        <Nav>
          {loginInfo?.user_level === 5 ? null : (
            <Box column gap={0}>
              <StatusText>
                {loginInfo.user_name}{' '}
                {loginInfo?.user_nickname ? (
                  <>
                    (<h1>{loginInfo?.user_nickname}</h1>)
                  </>
                ) : null}
              </StatusText>
              <TextButton
                onClick={async () => {
                  setUserModifyModalStatus({
                    ...userModifyModalStatus,
                    user_name: loginInfo?.user_name,
                    isActive: true,
                    onSuccess: ({ user_nickname, user_password }) => {
                      // e.user_nickname = user_nickname;
                      // e.user_password = user_password;

                      dispatch(setLoginInfo({ ...loginInfo, user_nickname }));
                      forceUpdate();

                      setUserModifyModalStatus({
                        ...userModifyModalStatus,
                        isActive: false,
                      });
                    },
                  });
                }}
              >
                <TextSmall>정보 변경</TextSmall>
              </TextButton>
              <StatusText>
                (
                <small>
                  {loginInfo?.user_level === 5
                    ? '개발자'
                    : loginInfo?.user_level === 4
                    ? '관리자'
                    : loginInfo?.user_level === 3
                    ? '2차 대행사'
                    : loginInfo?.user_level === 2
                    ? '일반 사용자'
                    : ''}
                </small>
                )
              </StatusText>
            </Box>
          )}
          {loginInfo?.user_level === 5 ? (
            ''
          ) : (
            <SideMenuNavi
              height={menuSize}
              fontSize={12}
              isActive={isTab === 'main'}
              onClick={() => {
                setIsTab('main');
                navigate('/');
              }}
              space
            >
              <Alarm>
                <Icon.Home size={24} fill />
              </Alarm>
              메인
            </SideMenuNavi>
          )}
          {loginInfo.user_level >= 3 && (
            <SideMenuNavi
              height={menuSize}
              fontSize={12}
              isActive={isTab === 'signup'}
              onClick={() => {
                setIsTab('signup');
                navigate('/signup');
              }}
              space
            >
              <Alarm>
                <Icon.UserSafty size={24} fill />
              </Alarm>
              회원가입
            </SideMenuNavi>
          )}
          {loginInfo.user_level >= 4 && (
            <SideMenuNavi
              height={menuSize}
              fontSize={12}
              isActive={isTab === 'partner'}
              onClick={() => {
                setIsTab('partner');
                navigate('/partner');
              }}
              space
            >
              <Alarm>
                <Icon.Company size={24} fill />
              </Alarm>
              대행사 목록
            </SideMenuNavi>
          )}
          {loginInfo.user_level >= 3 && (
            <SideMenuNavi
              height={menuSize}
              fontSize={12}
              isActive={isTab === 'user'}
              onClick={() => {
                setIsTab('user');
                navigate('/user');
              }}
              space
            >
              <Alarm>
                <Icon.User size={24} fill />
              </Alarm>
              회원 목록
            </SideMenuNavi>
          )}
          <SideMenuNavi
            isActive={isTab === 'product'}
            space
            fontSize={12}
            height={menuSize}
            onClick={() => {
              setIsTab('product');
              navigate('/product/slot');
            }}
          >
            <Icon.Numbered size={24} fill />
            슬롯 관리
          </SideMenuNavi>
          {loginInfo.user_level >= 3 && (
            <SideMenuNavi
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'product_add'}
              space
              onClick={() => {
                setIsTab('product_add');
                navigate('/product/add');
              }}
            >
              <Icon.Vactor size={24} fill />
              슬롯 등록
            </SideMenuNavi>
          )}

          <SideMenuNavi
            isActive={isTab === 'payment'}
            space
            fontSize={12}
            height={menuSize}
            onClick={() => {
              if (loginInfo.user_level === 2) {
                setIsTab('payment');
                navigate('/payment/issue');
              } else {
                setIsTab('payment');
                navigate('/payment');
              }
            }}
          >
            <Icon.Company size={24} fill />
            {loginInfo?.user_level >= 3 ? '정산' : '내역'}
          </SideMenuNavi>

          {/* {loginInfo.user_level >= 4 && (
            <SideMenuNavi
              disabled={true}
              isActive={isTab === 'naver_id'}
              space
              onClick={() => {
                setIsTab('naver_id');
                navigate('/naver_id');
              }}
            >
              <Icon.Logo size={32} fill />
              네이버 아이디
            </SideMenuNavi>
          )} */}

          {loginInfo.user_level >= 5 && (
            <SideMenuNavi
              space
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'work'}
              onClick={() => {
                setIsTab('work');
                navigate('/work');
              }}
            >
              <Icon.Task size={24} fill />
              Work
            </SideMenuNavi>
          )}

          {loginInfo.user_level >= 5 && (
            <SideMenuNavi
              space
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'vmware_count'}
              onClick={() => {
                setIsTab('vmware_count');
                navigate('/vmware_count');
              }}
            >
              <Icon.Data size={24} fill />
              <TextSmall>유입 상세 현황</TextSmall>
            </SideMenuNavi>
          )}
          {loginInfo.user_level >= 5 && (
            <SideMenuNavi
              space
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'cmd'}
              onClick={() => {
                setIsTab('cmd');
                navigate('/cmd');
              }}
            >
              <Icon.Eyes size={24} fill />
              <TextSmall>CMD 가동 현황</TextSmall>
            </SideMenuNavi>
          )}
          {loginInfo.user_level >= 5 && (
            <SideMenuNavi
              space
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'new_pattern'}
              onClick={() => {
                setIsTab('new_pattern');
                navigate('/new_pattern');
              }}
            >
              <Icon.Swap size={24} fill />
              <TextSmall>신규 패턴 설정</TextSmall>
            </SideMenuNavi>
          )}
          {loginInfo.user_level >= 5 && (
            <SideMenuNavi
              space
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'vmware_setup'}
              onClick={() => {
                setIsTab('vmware_setup');
                navigate('/vmware_setup');
              }}
            >
              <Icon.Setting size={24} fill />
              <TextSmall>Vmware 설정</TextSmall>
            </SideMenuNavi>
          )}

          <SideMenuNavi
            space
            fontSize={12}
            height={menuSize}
            isActive={isTab === 'logout'}
            onClick={() => {
              setIsTab('logout');

              removeCookie(token_name);

              dispatch(setLoginInfo({ user_name: null }));

              forceUpdate();
              setTimeout(() => {
                navigate('/', { force: true });
              }, 100);
            }}
          >
            <Icon.Exit size={32} fill />
            로그아웃
          </SideMenuNavi>
          {loginInfo.user_level >= 5 && (
            <SideMenuNavi
              space
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'manage'}
              onClick={() => {
                setIsTab('manage');
                navigate('/manage/vmwares/default');
              }}
            >
              <Icon.Setting size={24} fill />
              설정
            </SideMenuNavi>
          )}
          {loginInfo.user_level >= 5 && (
            <SideMenuNavi
              onClose={() => setIsTab(null)}
              space
              fontSize={12}
              height={menuSize}
              isActive={isTab === 'analytics'}
              onClick={() => {
                setIsTab('analytics');
                navigate('/analytics/vmwares_shop_link_counts');
              }}
            >
              <Icon.DataBase size={24} fill />
              통계
            </SideMenuNavi>
          )}
        </Nav>
      </Box>
      <UserModifyModal {...userModifyModalStatus} />
    </Wrapper>
  );
};
export default LeftMenu;
const StatusText = styled.div`
  text-align: center;
  font-size: 12rem;
  display: inline-block;
  gap: 12rem;
  /* min-width: 200rem; */
  justify-content: space-between;
  h1 {
    /* text-overflow: ellipsis; */
    display: inline;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* width: 80rem; */
    /* display: inline; */
    font-size: 11;
    font-weight: 400;
    color: #a0a0f0;
  }

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 25rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    /* padding-left: 2rem; */
    font-size: 12rem;
    line-height: 14rem;
    /* padding: 8rem 16rem; */
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return `
      color: #fff;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 
      background-color: #bc340b;
          
      `;
  }}
`;
const Wrapper = styled.aside`
  position: fixed;
  left: 0;
  top: 0;
  width: 140rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--White);
  border-radius: 0 20rem 20rem 0;
  font-weight: 500;
  box-sizing: border-box;
  padding: 16rem 12rem 48rem;
  z-index: 30;
  box-shadow: var(--Elevation_002);
`;
const Logo = styled.button`
  width: 60rem;
  height: 60rem;
  overflow: hidden;
`;

const Nav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12rem;
`;

const Alarm = styled.div`
  ${(props) => {
    const { isActive } = props;
    return css`
      position: relative;
      display: flex;
      align-items: center;
      &::after {
        ${isActive ? `content:''` : ''};
        position: absolute;
        top: 0;
        left: 100%;
        width: 8rem;
        height: 8rem;
        box-sizing: border-box;
        border-radius: 100%;
        background-color: var(--S_500);
        border: 1rem solid var(--White);
      }
    `;
  }}
`;
