import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoadWorkNewGetNewPatternClickDoneLogs } from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
// import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternActiveWorkerState = ({ uuid, worker_id, shop_link_id }) => {
  // if (loginInfo?.user_level !== 5) return;
  const [activeLogs, setActiveLogs] = useState();
  // const [count, setCount] = useState(20);
  // const [apiId, setApiId] = useState();
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCount((prevCount) => {
  //       var ct = prevCount - 1;
  //       if (ct < 0) {
  //         getWorkInfos();
  //         ct = reload_second;
  //       }
  //       return ct;
  //     });
  //   }, 1000);
  //   setApiId(intervalId);
  //   return () => clearInterval(intervalId);
  // }, []);
  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetNewPatternClickDoneLogs({ worker_id, shop_link_id });
    setActiveLogs(result);
  };

  useEffect(() => {
    getWorkInfos();
  }, [worker_id, uuid]);
  if (!activeLogs) return;
  return (
    <Block>
      <Box column width="100%" gap={8}>
        {activeLogs?.map((active_info, index) => {
          const product_info_id = active_info.product_info_id;
          const list = active_info?.list;
          const title = list?.[0]?.shop_link_title;
          const mall_name = list?.[0]?.shop_link_mall_name;
          const count = active_info.count;
          return (
            <Box column key={index}>
              <Box>
                <TextSmall>
                  <TextSucceed>
                    [{mall_name}] {title} [{count}]
                  </TextSucceed>
                </TextSmall>
              </Box>
              <Box column>
                {list?.map((info, index) => {
                  // const info = data?.list[0];
                  const id = info.id;
                  const worker_id = info.worker_id;
                  const click_result = info.click_result;
                  const step_done_ct = info.step_done_ct;
                  const step_done_ct_max = info.step_done_ct_max;
                  const using_sec = info.using_sec;
                  const proxy_ip_country = info.proxy_ip_country;
                  const workstation_name = info.workstation_name;
                  const vmware_name = info.vmware_name;
                  const vmware_f_num = info.vmware_f_num;
                  const search_type = info.search_type;
                  const search_keyword = info.search_keyword;
                  const url_access_request_size = info.url_access_request_size;
                  const url_access_response_size = info.url_access_response_size;
                  const url_access_total_time = info.url_access_total_time;
                  const url_access_count = info.url_access_count;
                  const size = url_access_request_size + url_access_response_size;

                  const click_done_count = info.click_done_count;
                  const real_click_count = info.real_click_count;
                  const real_etc_do1_click_count = info.real_etc_do1_click_count;

                  const datetime = info.datetime;
                  const datetime_ago_sec = info.datetime_ago_sec;

                  return (
                    <Box key={index} width="100%">
                      <ActiveLogStatusBox>
                        <Box gap={8}>
                          [<TextFail>{datetime_ago_sec}</TextFail>sec] <Box>{worker_id}</Box>
                          <Box>
                            {vmware_name}
                            <TextFail>{vmware_f_num}</TextFail>
                          </Box>
                          <Box>{proxy_ip_country}</Box>
                          <Box>
                            <TextSucceed>{click_result}</TextSucceed> [{search_keyword}]
                          </Box>
                          <Box>using:{using_sec}</Box>
                          <Box>
                            {step_done_ct}/{step_done_ct_max}{' '}
                            <PercentModule value={step_done_ct} valueMax={step_done_ct_max} />
                          </Box>
                          <Box>
                            <TrafficSizeModule size={size} />
                          </Box>
                          <Box></Box>
                        </Box>
                      </ActiveLogStatusBox>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Block>
  );
};
const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ActiveLogStatusBox = styled.div`
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  /* border-style: solid; */
  border-width: 1rem;
  border-color: blue;
`;
const StatusBox = styled.div`
  display: flex;
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
export default WorkNewPatternActiveWorkerState;
