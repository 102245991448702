import styled, { css } from 'styled-components';
export const LineIcon = styled.div`
  width: 0;
  height: 0;
  ${(props) => {
    const { type, size, isActive } = props;
    if (type === 'circle')
      return css`
        width: ${size ? size : 5}rem;
        height: ${size ? size : 5}rem;
        background-color: ${isActive ? '#FC6B19' : '#C4C9DF'};
        border-radius: 50%;
      `;
    else if (type === 'arrow_right')
      return css`
        padding-right: 3rem;
        border-top: ${size ? size : 5}rem solid transparent;
        border-bottom: ${size ? size : 5}rem solid transparent;
        border-left: ${size ? size : 5}rem solid ${isActive ? '#FC6B19' : '#C4C9DF'};
      `;
    else if (type === 'arrow_left')
      return css`
        padding-right: 3rem;
        border-top: ${size ? size : 5}rem solid transparent;
        border-bottom: ${size ? size : 5}rem solid transparent;
        border-right: ${size ? size : 5}rem solid ${isActive ? '#FC6B19' : '#C4C9DF'};
      `;
    else if (type === 'arrow_up')
      return css`
        padding-right: 3rem;
        border-left: ${size ? size : 5}rem solid transparent;
        border-right: ${size ? size : 5}rem solid transparent;
        border-bottom: ${size ? size : 5}rem solid ${isActive ? '#FC6B19' : '#C4C9DF'};
      `;
    else if (type === 'arrow_right')
      return css`
        padding-right: 3rem;
        border-left: ${size ? size : 5}rem solid transparent;
        border-right: ${size ? size : 5}rem solid transparent;
        border-top: ${size ? size : 5}rem solid ${isActive ? '#FC6B19' : '#C4C9DF'};
      `;
  }}
`;
const Svg = styled.svg`
  ${(props) => {
    const { sizeX, styles, isAction } = props;
    return `
            width:${sizeX}rem;
            >*{
                ${isAction === true ? '' : 'transition:0.3s;'}
            }
            ${styles || ''}
            
        `;
  }}
`;
export const SafetyStatusIcon = ({ type }) => {
  if (type === 'done') {
    return (
      <svg width="101" height="100" viewBox="0 0 101 100" fill="none">
        <path
          d="M79.2555 99.2048H15.7253C14.4336 99.2048 13.3867 98.1475 13.3867 96.8429V8.07782C13.3867 6.77322 14.4336 5.71594 15.7253 5.71594H79.2555C80.5472 5.71594 81.594 6.77322 81.594 8.07782V96.8429C81.594 98.1475 80.5468 99.2048 79.2555 99.2048Z"
          fill="#E0EBFC"
        />
        <path
          d="M79.2573 100H15.7275C14.0039 100 12.6016 98.5841 12.6016 96.8433V8.07777C12.6016 6.33698 14.0039 4.92065 15.7275 4.92065H79.2573C80.9809 4.92065 82.3833 6.33698 82.3833 8.07777V96.8429C82.3837 98.5841 80.9809 100 79.2573 100ZM15.7275 6.51114C14.8724 6.51114 14.1764 7.21414 14.1764 8.07777V96.8429C14.1764 97.7065 14.8724 98.4095 15.7275 98.4095H79.2573C80.1128 98.4095 80.8085 97.7065 80.8085 96.8429V8.07777C80.8085 7.21414 80.1124 6.51114 79.2573 6.51114H15.7275Z"
          fill="#3762CC"
        />
        <path
          d="M47.755 90.7087C47.3267 90.7087 46.9007 90.5317 46.5924 90.1838C46.0184 89.5357 46.0731 88.5404 46.7149 87.9607L53.8956 81.4719C54.8444 80.6142 56.1129 80.2087 57.3786 80.3602C58.6432 80.5109 59.7837 81.2039 60.5085 82.2612L61.5644 83.8004L62.9392 81.9133C63.6538 80.9323 64.7046 80.2989 65.8987 80.1295C67.0955 79.9601 68.2762 80.277 69.2306 81.0206L69.6239 81.3268C70.3054 81.858 70.4317 82.8469 69.9057 83.5352C69.3798 84.2234 68.4018 84.3515 67.7191 83.8203L67.3254 83.5137C67.0408 83.2914 66.6869 83.1972 66.3325 83.2477C65.9766 83.2982 65.6636 83.4867 65.4506 83.7793L63.4117 86.5782C62.9692 87.1857 62.2632 87.5452 61.5113 87.5317C60.764 87.5209 60.0636 87.144 59.6384 86.5233L57.9455 84.0541C57.7262 83.7344 57.3951 83.5332 57.0125 83.4874C56.6337 83.4417 56.2621 83.5594 55.9747 83.8187L48.794 90.3075C48.4963 90.5766 48.1251 90.7087 47.755 90.7087Z"
          fill="#4671C6"
        />
        <path
          d="M61.7856 17.3611H33.7231C32.8621 17.3611 32.1641 16.6561 32.1641 15.7865C32.1641 14.9169 32.8621 14.2119 33.7231 14.2119H61.7856C62.6467 14.2119 63.3447 14.9169 63.3447 15.7865C63.3447 16.6561 62.6467 17.3611 61.7856 17.3611Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 31.625H23.9184C23.0574 31.625 22.3594 30.92 22.3594 30.0504C22.3594 29.1808 23.0574 28.4758 23.9184 28.4758H71.079C71.94 28.4758 72.638 29.1808 72.638 30.0504C72.638 30.92 71.9396 31.625 71.0786 31.625Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 40.9539H23.9184C23.0574 40.9539 22.3594 40.2489 22.3594 39.3793C22.3594 38.5097 23.0574 37.8047 23.9184 37.8047H71.079C71.94 37.8047 72.638 38.5097 72.638 39.3793C72.638 40.2489 71.9396 40.9539 71.0786 40.9539Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 50.2828H23.9184C23.0574 50.2828 22.3594 49.5778 22.3594 48.7082C22.3594 47.8387 23.0574 47.1337 23.9184 47.1337H71.079C71.94 47.1337 72.638 47.8387 72.638 48.7082C72.638 49.5778 71.9396 50.2828 71.0786 50.2828Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 59.6116H23.9184C23.0574 59.6116 22.3594 58.9066 22.3594 58.037C22.3594 57.1674 23.0574 56.4624 23.9184 56.4624H71.079C71.94 56.4624 72.638 57.1674 72.638 58.037C72.6376 58.9066 71.9396 59.6116 71.0786 59.6116Z"
          fill="#4671C6"
        />
        <path
          d="M70.7616 73.3071H53.0278C52.1668 73.3071 51.4688 72.6021 51.4688 71.7325C51.4688 70.8629 52.1668 70.158 53.0278 70.158H70.7616C71.6226 70.158 72.3207 70.8629 72.3207 71.7325C72.3207 72.6021 71.6226 73.3071 70.7616 73.3071Z"
          fill="#4671C6"
        />
        <path
          d="M13.3871 26.2505C20.347 26.2505 25.989 20.5522 25.989 13.523C25.989 6.49382 20.347 0.795532 13.3871 0.795532C6.42724 0.795532 0.785156 6.49382 0.785156 13.523C0.785156 20.5522 6.42724 26.2505 13.3871 26.2505Z"
          fill="#6BDDDD"
        />
        <path
          d="M10.8843 19.3894C10.8438 19.3894 10.8029 19.3878 10.7619 19.3846C10.3072 19.3485 9.89104 19.1131 9.62294 18.7405L6.13397 13.8911C5.62767 13.1877 5.782 12.2028 6.47885 11.6919C7.17491 11.1801 8.1501 11.3364 8.6564 12.0402L11.0725 15.3981L18.2808 8.11803C18.8898 7.5033 19.8769 7.5033 20.4859 8.11803C21.0946 8.73275 21.0946 9.72998 20.4859 10.3451L11.9867 18.9282C11.693 19.2244 11.2962 19.3894 10.8843 19.3894Z"
          fill="#E0EBFC"
        />
        <path
          d="M88.918 40.7133L95.5896 44.6037L98.0148 40.3611C98.251 39.9475 98.1109 39.4187 97.7014 39.1801L92.5125 36.1542C92.103 35.9156 91.5794 36.0572 91.3432 36.4707L88.918 40.7133Z"
          fill="#F9A7A7"
        />
        <path
          d="M75.9062 79.0444L69.2347 75.1541L71.6599 70.9118L78.3314 74.8022L75.9062 79.0444Z"
          fill="#F9A7A7"
        />
        <path
          d="M69.2241 75.1527L75.8957 79.043L69.7906 82.6956C69.5036 82.8674 69.1406 82.6558 69.1446 82.3191L69.2241 75.1527Z"
          fill="#A4C9FF"
        />
        <path
          d="M78.332 74.8009L71.6604 70.9106L88.9226 40.7147L95.5942 44.605L78.332 74.8009Z"
          fill="#4671C6"
        />
        <path
          d="M93.0664 36.4785L94.9782 33.1337C95.301 32.5687 96.0164 32.3755 96.5755 32.7015L98.6274 33.898C99.1865 34.224 99.3782 34.9465 99.0554 35.5111L97.1436 38.8559L93.0664 36.4785Z"
          fill="#A4C9FF"
        />
        <path
          d="M95.5881 45.3988C95.4511 45.3988 95.3153 45.3626 95.1944 45.2922L88.5228 41.4019C88.3421 41.2965 88.2098 41.1228 88.1558 40.9192C88.1015 40.7156 88.1302 40.4985 88.2342 40.3156L90.6594 36.0734C90.8791 35.6893 91.2334 35.4149 91.6574 35.3C92.081 35.1847 92.5239 35.2439 92.9047 35.4658L98.0936 38.4913C98.8783 38.949 99.1483 39.9661 98.6952 40.7586L96.27 45.0012C96.1653 45.1837 95.9936 45.3169 95.7917 45.3718C95.7251 45.3901 95.6566 45.3988 95.5881 45.3988ZM89.9921 40.4222L95.2995 43.5173L97.331 39.9633C97.3476 39.9351 97.3342 39.885 97.3062 39.8687L92.1169 36.8432C92.0889 36.8273 92.0397 36.8408 92.0235 36.8686L89.9921 40.4222Z"
          fill="#3762CC"
        />
        <path
          d="M75.9002 79.8383C75.7631 79.8383 75.6273 79.8021 75.5065 79.7317L68.8352 75.8414C68.4584 75.6219 68.3297 75.1356 68.547 74.7551L70.9722 70.5125C71.0769 70.3299 71.2486 70.1967 71.4506 70.1419C71.6521 70.0874 71.8675 70.116 72.0482 70.2214L78.7194 74.1117C79.0962 74.3312 79.225 74.8175 79.0076 75.198L76.5824 79.4403C76.4777 79.6228 76.3061 79.756 76.1041 79.8108C76.0372 79.8291 75.9683 79.8383 75.9002 79.8383ZM70.3045 74.8616L75.6116 77.9567L77.2494 75.0915L71.9423 71.9964L70.3045 74.8616Z"
          fill="#3762CC"
        />
        <path
          d="M69.5787 83.551C69.3689 83.551 69.1586 83.4962 68.9708 83.3864C68.5909 83.1649 68.3582 82.7522 68.3634 82.3097L68.4429 75.1437C68.446 74.8614 68.5976 74.6018 68.8405 74.4618C69.083 74.3218 69.3815 74.3226 69.624 74.4642L76.2952 78.3545C76.5374 78.4957 76.6874 78.7561 76.6889 79.0388C76.6905 79.3215 76.5437 79.5836 76.3031 79.7275L70.198 83.3805C70.0071 83.4942 69.7929 83.551 69.5787 83.551ZM70.0027 76.5215L69.9452 81.6826L74.3417 79.0519L70.0027 76.5215Z"
          fill="#3762CC"
        />
        <path
          d="M78.322 75.5958C78.185 75.5958 78.0492 75.5596 77.9283 75.4892L71.2571 71.5989C70.8803 71.3794 70.7516 70.8931 70.9689 70.5126L88.2311 40.3156C88.3358 40.1331 88.5074 39.9999 88.7094 39.945C88.9114 39.8901 89.1267 39.9188 89.307 40.0245L95.9787 43.9149C96.3555 44.1343 96.4842 44.6206 96.2669 45.0012L79.0043 75.1982C78.8996 75.3807 78.7279 75.5139 78.526 75.5687C78.459 75.587 78.3901 75.5958 78.322 75.5958ZM72.7264 70.6191L78.0334 73.7142L94.5086 44.8946L89.2011 41.7995L72.7264 70.6191Z"
          fill="#3762CC"
        />
        <path
          d="M97.1455 39.6513C97.0085 39.6513 96.8727 39.6151 96.7518 39.5448L92.6751 37.1674C92.2983 36.9479 92.1695 36.4616 92.3869 36.0811L94.2987 32.7363C94.5597 32.2794 94.9817 31.9522 95.4869 31.8158C95.9912 31.679 96.5191 31.7494 96.9715 32.0134L99.0235 33.2099C99.4758 33.4739 99.7998 33.8997 99.9349 34.4095C100.07 34.9196 100.001 35.452 99.7392 35.9089L97.8278 39.2537C97.7231 39.4362 97.5514 39.5694 97.3495 39.6243C97.2825 39.6422 97.2136 39.6513 97.1455 39.6513ZM94.1443 36.1876L96.8573 37.7698L98.3754 35.1137C98.4443 34.9932 98.429 34.8787 98.414 34.821C98.3987 34.7634 98.3554 34.6564 98.2361 34.5868L96.1841 33.3904C96.0644 33.3212 95.951 33.3367 95.8943 33.3518C95.8376 33.3669 95.7317 33.411 95.6628 33.5311L94.1443 36.1876Z"
          fill="#3762CC"
        />
        <path
          d="M13.3893 27.0458C6.00629 27.0458 0 20.9797 0 13.5231C0 6.06652 6.00629 0 13.3893 0C20.7724 0 26.7787 6.06612 26.7787 13.5227C26.7787 20.9793 20.7724 27.0458 13.3893 27.0458ZM13.3893 1.59049C6.87479 1.59049 1.5748 6.94327 1.5748 13.5231C1.5748 20.103 6.87479 25.4554 13.3893 25.4554C19.9039 25.4554 25.2039 20.1026 25.2039 13.5231C25.2039 6.94367 19.9043 1.59049 13.3893 1.59049Z"
          fill="#3762CC"
        />
        <path
          d="M10.8908 20.1846C10.8294 20.1846 10.7676 20.1822 10.7057 20.1771C10.0215 20.1226 9.39669 19.7695 8.99236 19.2077L5.50339 14.3583C5.13489 13.8461 4.98607 13.2199 5.0841 12.5948C5.18213 11.9698 5.5152 11.4202 6.02229 11.0481C6.52898 10.6755 7.14945 10.5248 7.76874 10.6246C8.38764 10.7236 8.93134 11.0604 9.29984 11.5725L11.1742 14.1774L17.7305 7.55496C18.6455 6.63168 20.1337 6.63129 21.0486 7.55456C21.9636 8.47864 21.9636 9.98205 21.049 10.9065L12.5498 19.4904C12.1061 19.9381 11.5172 20.1846 10.8908 20.1846ZM7.39985 12.1853C7.23843 12.1853 7.08174 12.2362 6.94867 12.3344C6.78134 12.4572 6.67189 12.6378 6.63961 12.8433C6.60733 13.0489 6.65654 13.2545 6.7778 13.4231L10.2668 18.2729C10.3994 18.4578 10.605 18.5739 10.8302 18.5918C11.0695 18.61 11.277 18.5269 11.4368 18.3659L19.9353 9.78204C20.236 9.47786 20.236 8.98362 19.9353 8.67983C19.6345 8.37645 19.1443 8.37605 18.8435 8.68023L11.6357 15.9599C11.4727 16.1245 11.2471 16.2116 11.0172 16.1905C10.7876 16.1722 10.5774 16.0533 10.442 15.8653L8.02583 12.5073C7.90457 12.3387 7.72544 12.2278 7.52189 12.1952C7.48134 12.1884 7.4404 12.1853 7.39985 12.1853Z"
          fill="#3762CC"
        />
      </svg>
    );
  } else if (type === 'error') {
    return (
      <svg width="101" height="100" viewBox="0 0 101 100" fill="none">
        <path
          d="M79.2555 99.2048H15.7253C14.4336 99.2048 13.3867 98.1475 13.3867 96.8429V8.07782C13.3867 6.77322 14.4336 5.71594 15.7253 5.71594H79.2555C80.5472 5.71594 81.594 6.77322 81.594 8.07782V96.8429C81.594 98.1475 80.5468 99.2048 79.2555 99.2048Z"
          fill="#E0EBFC"
        />
        <path
          d="M79.2573 100H15.7275C14.0039 100 12.6016 98.5841 12.6016 96.8433V8.07777C12.6016 6.33698 14.0039 4.92065 15.7275 4.92065H79.2573C80.9809 4.92065 82.3833 6.33698 82.3833 8.07777V96.8429C82.3837 98.5841 80.9809 100 79.2573 100ZM15.7275 6.51114C14.8724 6.51114 14.1764 7.21414 14.1764 8.07777V96.8429C14.1764 97.7065 14.8724 98.4095 15.7275 98.4095H79.2573C80.1128 98.4095 80.8085 97.7065 80.8085 96.8429V8.07777C80.8085 7.21414 80.1124 6.51114 79.2573 6.51114H15.7275Z"
          fill="#3762CC"
        />
        <path
          d="M47.755 90.7087C47.3267 90.7087 46.9007 90.5317 46.5924 90.1838C46.0184 89.5357 46.0731 88.5404 46.7149 87.9607L53.8956 81.4719C54.8444 80.6142 56.1129 80.2087 57.3786 80.3602C58.6432 80.5109 59.7837 81.2039 60.5085 82.2612L61.5644 83.8004L62.9392 81.9133C63.6538 80.9323 64.7046 80.2989 65.8987 80.1295C67.0955 79.9601 68.2762 80.277 69.2306 81.0206L69.6239 81.3268C70.3054 81.858 70.4317 82.8469 69.9057 83.5352C69.3798 84.2234 68.4018 84.3515 67.7191 83.8203L67.3254 83.5137C67.0408 83.2914 66.6869 83.1972 66.3325 83.2477C65.9766 83.2982 65.6636 83.4867 65.4506 83.7793L63.4117 86.5782C62.9692 87.1857 62.2632 87.5452 61.5113 87.5317C60.764 87.5209 60.0636 87.144 59.6384 86.5233L57.9455 84.0541C57.7262 83.7344 57.3951 83.5332 57.0125 83.4874C56.6337 83.4417 56.2621 83.5594 55.9747 83.8187L48.794 90.3075C48.4963 90.5766 48.1251 90.7087 47.755 90.7087V90.7087Z"
          fill="#4671C6"
        />
        <path
          d="M61.7856 17.3611H33.7231C32.8621 17.3611 32.1641 16.6561 32.1641 15.7865C32.1641 14.9169 32.8621 14.2119 33.7231 14.2119H61.7856C62.6467 14.2119 63.3447 14.9169 63.3447 15.7865C63.3447 16.6561 62.6467 17.3611 61.7856 17.3611Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 31.625H23.9184C23.0574 31.625 22.3594 30.92 22.3594 30.0504C22.3594 29.1808 23.0574 28.4758 23.9184 28.4758H71.079C71.94 28.4758 72.638 29.1808 72.638 30.0504C72.638 30.92 71.9396 31.625 71.0786 31.625V31.625Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 40.9539H23.9184C23.0574 40.9539 22.3594 40.2489 22.3594 39.3793C22.3594 38.5097 23.0574 37.8047 23.9184 37.8047H71.079C71.94 37.8047 72.638 38.5097 72.638 39.3793C72.638 40.2489 71.9396 40.9539 71.0786 40.9539V40.9539Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 50.2828H23.9184C23.0574 50.2828 22.3594 49.5778 22.3594 48.7082C22.3594 47.8387 23.0574 47.1337 23.9184 47.1337H71.079C71.94 47.1337 72.638 47.8387 72.638 48.7082C72.638 49.5778 71.9396 50.2828 71.0786 50.2828V50.2828Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 59.6116H23.9184C23.0574 59.6116 22.3594 58.9066 22.3594 58.037C22.3594 57.1674 23.0574 56.4624 23.9184 56.4624H71.079C71.94 56.4624 72.638 57.1674 72.638 58.037C72.6376 58.9066 71.9396 59.6116 71.0786 59.6116V59.6116Z"
          fill="#4671C6"
        />
        <path
          d="M70.7616 73.3071H53.0278C52.1668 73.3071 51.4688 72.6021 51.4688 71.7325C51.4688 70.8629 52.1668 70.158 53.0278 70.158H70.7616C71.6226 70.158 72.3207 70.8629 72.3207 71.7325C72.3207 72.6021 71.6226 73.3071 70.7616 73.3071V73.3071Z"
          fill="#4671C6"
        />
        <path
          d="M13.3871 26.2505C20.347 26.2505 25.989 20.5522 25.989 13.523C25.989 6.49382 20.347 0.795532 13.3871 0.795532C6.42724 0.795532 0.785156 6.49382 0.785156 13.523C0.785156 20.5522 6.42724 26.2505 13.3871 26.2505Z"
          fill="#CE0808"
        />
        <path
          d="M13.3307 13.9545L11.1571 16.1281C11.0758 16.2094 10.9723 16.2501 10.8466 16.2501C10.7209 16.2501 10.6174 16.2094 10.5361 16.1281C10.4547 16.0468 10.4141 15.9433 10.4141 15.8176C10.4141 15.6919 10.4547 15.5884 10.5361 15.5071L12.7097 13.3334L10.5361 11.1598C10.4547 11.0785 10.4141 10.9749 10.4141 10.8493C10.4141 10.7236 10.4547 10.6201 10.5361 10.5387C10.6174 10.4574 10.7209 10.4167 10.8466 10.4167C10.9723 10.4167 11.0758 10.4574 11.1571 10.5387L13.3307 12.7124L15.5044 10.5387C15.5857 10.4574 15.6892 10.4167 15.8149 10.4167C15.9406 10.4167 16.0441 10.4574 16.1254 10.5387C16.2067 10.6201 16.2474 10.7236 16.2474 10.8493C16.2474 10.9749 16.2067 11.0785 16.1254 11.1598L13.9518 13.3334L16.1254 15.5071C16.2067 15.5884 16.2474 15.6919 16.2474 15.8176C16.2474 15.9433 16.2067 16.0468 16.1254 16.1281C16.0441 16.2094 15.9406 16.2501 15.8149 16.2501C15.6892 16.2501 15.5857 16.2094 15.5044 16.1281L13.3307 13.9545Z"
          fill="#686D78"
        />
        <path
          d="M88.918 40.7133L95.5896 44.6037L98.0148 40.3611C98.251 39.9475 98.1109 39.4187 97.7014 39.1801L92.5125 36.1542C92.103 35.9156 91.5794 36.0572 91.3432 36.4707L88.918 40.7133Z"
          fill="#F9A7A7"
        />
        <path
          d="M75.9062 79.0444L69.2347 75.1541L71.6599 70.9118L78.3314 74.8022L75.9062 79.0444Z"
          fill="#F9A7A7"
        />
        <path
          d="M69.2241 75.1527L75.8957 79.043L69.7906 82.6956C69.5036 82.8674 69.1406 82.6558 69.1446 82.3191L69.2241 75.1527Z"
          fill="#A4C9FF"
        />
        <path
          d="M78.332 74.8009L71.6604 70.9106L88.9226 40.7147L95.5942 44.605L78.332 74.8009Z"
          fill="#4671C6"
        />
        <path
          d="M93.0664 36.4785L94.9782 33.1337C95.301 32.5687 96.0164 32.3755 96.5755 32.7015L98.6274 33.898C99.1865 34.224 99.3782 34.9465 99.0554 35.5111L97.1436 38.8559L93.0664 36.4785Z"
          fill="#A4C9FF"
        />
        <path
          d="M95.5881 45.3988C95.4511 45.3988 95.3153 45.3626 95.1944 45.2922L88.5228 41.4019C88.3421 41.2965 88.2098 41.1228 88.1558 40.9192C88.1015 40.7156 88.1302 40.4985 88.2342 40.3156L90.6594 36.0734C90.8791 35.6893 91.2334 35.4149 91.6574 35.3C92.081 35.1847 92.5239 35.2439 92.9047 35.4658L98.0936 38.4913C98.8783 38.949 99.1483 39.9661 98.6952 40.7586L96.27 45.0012C96.1653 45.1837 95.9936 45.3169 95.7917 45.3718C95.7251 45.3901 95.6566 45.3988 95.5881 45.3988V45.3988ZM89.9921 40.4222L95.2995 43.5173L97.331 39.9633C97.3476 39.9351 97.3342 39.885 97.3062 39.8687L92.1169 36.8432C92.0889 36.8273 92.0397 36.8408 92.0235 36.8686L89.9921 40.4222Z"
          fill="#3762CC"
        />
        <path
          d="M75.9002 79.8383C75.7631 79.8383 75.6273 79.8021 75.5065 79.7317L68.8352 75.8414C68.4584 75.6219 68.3297 75.1356 68.547 74.7551L70.9722 70.5125C71.0769 70.3299 71.2486 70.1967 71.4506 70.1419C71.6521 70.0874 71.8675 70.116 72.0482 70.2214L78.7194 74.1117C79.0962 74.3312 79.225 74.8175 79.0076 75.198L76.5824 79.4403C76.4777 79.6228 76.3061 79.756 76.1041 79.8108C76.0372 79.8291 75.9683 79.8383 75.9002 79.8383ZM70.3045 74.8616L75.6116 77.9567L77.2494 75.0915L71.9423 71.9964L70.3045 74.8616Z"
          fill="#3762CC"
        />
        <path
          d="M69.5787 83.551C69.3689 83.551 69.1586 83.4962 68.9708 83.3864C68.5909 83.1649 68.3582 82.7522 68.3634 82.3097L68.4429 75.1437C68.446 74.8614 68.5976 74.6018 68.8405 74.4618C69.083 74.3218 69.3815 74.3226 69.624 74.4642L76.2952 78.3545C76.5374 78.4957 76.6874 78.7561 76.6889 79.0388C76.6905 79.3215 76.5437 79.5836 76.3031 79.7275L70.198 83.3805C70.0071 83.4942 69.7929 83.551 69.5787 83.551V83.551ZM70.0027 76.5215L69.9452 81.6826L74.3417 79.0519L70.0027 76.5215Z"
          fill="#3762CC"
        />
        <path
          d="M78.322 75.5958C78.185 75.5958 78.0492 75.5596 77.9283 75.4892L71.2571 71.5989C70.8803 71.3794 70.7516 70.8931 70.9689 70.5126L88.2311 40.3156C88.3358 40.1331 88.5074 39.9999 88.7094 39.945C88.9114 39.8901 89.1267 39.9188 89.307 40.0245L95.9787 43.9149C96.3555 44.1343 96.4842 44.6206 96.2669 45.0012L79.0043 75.1982C78.8996 75.3807 78.7279 75.5139 78.526 75.5687C78.459 75.587 78.3901 75.5958 78.322 75.5958ZM72.7264 70.6191L78.0334 73.7142L94.5086 44.8946L89.2011 41.7995L72.7264 70.6191Z"
          fill="#3762CC"
        />
        <path
          d="M97.1455 39.6513C97.0085 39.6513 96.8727 39.6151 96.7518 39.5448L92.6751 37.1674C92.2983 36.9479 92.1695 36.4616 92.3869 36.0811L94.2987 32.7363C94.5597 32.2794 94.9817 31.9522 95.4869 31.8158C95.9912 31.679 96.5191 31.7494 96.9715 32.0134L99.0235 33.2099C99.4758 33.4739 99.7998 33.8997 99.9349 34.4095C100.07 34.9196 100.001 35.452 99.7392 35.9089L97.8278 39.2537C97.7231 39.4362 97.5514 39.5694 97.3495 39.6243C97.2825 39.6422 97.2136 39.6513 97.1455 39.6513V39.6513ZM94.1443 36.1876L96.8573 37.7698L98.3754 35.1137C98.4443 34.9932 98.429 34.8787 98.414 34.821C98.3987 34.7634 98.3554 34.6564 98.2361 34.5868L96.1841 33.3904C96.0644 33.3212 95.951 33.3367 95.8943 33.3518C95.8376 33.3669 95.7317 33.411 95.6628 33.5311L94.1443 36.1876Z"
          fill="#3762CC"
        />
        <path
          d="M13.3893 27.0458C6.00629 27.0458 0 20.9797 0 13.5231C0 6.06652 6.00629 0 13.3893 0C20.7724 0 26.7787 6.06612 26.7787 13.5227C26.7787 20.9793 20.7724 27.0458 13.3893 27.0458ZM13.3893 1.59049C6.87479 1.59049 1.5748 6.94327 1.5748 13.5231C1.5748 20.103 6.87479 25.4554 13.3893 25.4554C19.9039 25.4554 25.2039 20.1026 25.2039 13.5231C25.2039 6.94367 19.9043 1.59049 13.3893 1.59049V1.59049Z"
          fill="#3762CC"
        />
      </svg>
    );
  } else if (type === 'check') {
    return (
      <svg width="101" height="100" viewBox="0 0 101 100" fill="none">
        <path
          d="M79.2555 99.2048H15.7253C14.4336 99.2048 13.3867 98.1475 13.3867 96.8429V8.07782C13.3867 6.77322 14.4336 5.71594 15.7253 5.71594H79.2555C80.5472 5.71594 81.594 6.77322 81.594 8.07782V96.8429C81.594 98.1475 80.5468 99.2048 79.2555 99.2048Z"
          fill="#E0EBFC"
        />
        <path
          d="M79.2573 100H15.7275C14.0039 100 12.6016 98.5841 12.6016 96.8433V8.07777C12.6016 6.33698 14.0039 4.92065 15.7275 4.92065H79.2573C80.9809 4.92065 82.3833 6.33698 82.3833 8.07777V96.8429C82.3837 98.5841 80.9809 100 79.2573 100ZM15.7275 6.51114C14.8724 6.51114 14.1764 7.21414 14.1764 8.07777V96.8429C14.1764 97.7065 14.8724 98.4095 15.7275 98.4095H79.2573C80.1128 98.4095 80.8085 97.7065 80.8085 96.8429V8.07777C80.8085 7.21414 80.1124 6.51114 79.2573 6.51114H15.7275Z"
          fill="#3762CC"
        />
        <path
          d="M47.755 90.7087C47.3267 90.7087 46.9007 90.5317 46.5924 90.1838C46.0184 89.5357 46.0731 88.5404 46.7149 87.9607L53.8956 81.4719C54.8444 80.6142 56.1129 80.2087 57.3786 80.3602C58.6432 80.5109 59.7837 81.2039 60.5085 82.2612L61.5644 83.8004L62.9392 81.9133C63.6538 80.9323 64.7046 80.2989 65.8987 80.1295C67.0955 79.9601 68.2762 80.277 69.2306 81.0206L69.6239 81.3268C70.3054 81.858 70.4317 82.8469 69.9057 83.5352C69.3798 84.2234 68.4018 84.3515 67.7191 83.8203L67.3254 83.5137C67.0408 83.2914 66.6869 83.1972 66.3325 83.2477C65.9766 83.2982 65.6636 83.4867 65.4506 83.7793L63.4117 86.5782C62.9692 87.1857 62.2632 87.5452 61.5113 87.5317C60.764 87.5209 60.0636 87.144 59.6384 86.5233L57.9455 84.0541C57.7262 83.7344 57.3951 83.5332 57.0125 83.4874C56.6337 83.4417 56.2621 83.5594 55.9747 83.8187L48.794 90.3075C48.4963 90.5766 48.1251 90.7087 47.755 90.7087Z"
          fill="#4671C6"
        />
        <path
          d="M61.7856 17.3611H33.7231C32.8621 17.3611 32.1641 16.6561 32.1641 15.7865C32.1641 14.9169 32.8621 14.2119 33.7231 14.2119H61.7856C62.6467 14.2119 63.3447 14.9169 63.3447 15.7865C63.3447 16.6561 62.6467 17.3611 61.7856 17.3611Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 31.625H23.9184C23.0574 31.625 22.3594 30.92 22.3594 30.0504C22.3594 29.1808 23.0574 28.4758 23.9184 28.4758H71.079C71.94 28.4758 72.638 29.1808 72.638 30.0504C72.638 30.92 71.9396 31.625 71.0786 31.625Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 40.9539H23.9184C23.0574 40.9539 22.3594 40.2489 22.3594 39.3793C22.3594 38.5097 23.0574 37.8047 23.9184 37.8047H71.079C71.94 37.8047 72.638 38.5097 72.638 39.3793C72.638 40.2489 71.9396 40.9539 71.0786 40.9539Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 50.2828H23.9184C23.0574 50.2828 22.3594 49.5778 22.3594 48.7082C22.3594 47.8387 23.0574 47.1337 23.9184 47.1337H71.079C71.94 47.1337 72.638 47.8387 72.638 48.7082C72.638 49.5778 71.9396 50.2828 71.0786 50.2828Z"
          fill="#4671C6"
        />
        <path
          d="M71.0786 59.6116H23.9184C23.0574 59.6116 22.3594 58.9066 22.3594 58.037C22.3594 57.1674 23.0574 56.4624 23.9184 56.4624H71.079C71.94 56.4624 72.638 57.1674 72.638 58.037C72.6376 58.9066 71.9396 59.6116 71.0786 59.6116Z"
          fill="#4671C6"
        />
        <path
          d="M70.7616 73.3071H53.0278C52.1668 73.3071 51.4688 72.6021 51.4688 71.7325C51.4688 70.8629 52.1668 70.158 53.0278 70.158H70.7616C71.6226 70.158 72.3207 70.8629 72.3207 71.7325C72.3207 72.6021 71.6226 73.3071 70.7616 73.3071Z"
          fill="#4671C6"
        />
        <path
          d="M13.3871 26.2505C20.347 26.2505 25.989 20.5522 25.989 13.523C25.989 6.49382 20.347 0.795532 13.3871 0.795532C6.42724 0.795532 0.785156 6.49382 0.785156 13.523C0.785156 20.5522 6.42724 26.2505 13.3871 26.2505Z"
          fill="#3C69FC"
        />
        <g clip-path="url(#clip0_2891_124858)">
          <path
            d="M13.3294 8.64583C13.3294 8.35818 13.0962 8.125 12.8086 8.125C12.5209 8.125 12.2878 8.35818 12.2878 8.64583V14.375C12.2878 14.5619 12.3879 14.7345 12.5502 14.8272L16.196 16.9105C16.4458 17.0533 16.7639 16.9665 16.9066 16.7167C17.0494 16.467 16.9626 16.1488 16.7128 16.0061L13.3294 14.0727V8.64583Z"
            fill="white"
          />
          <path
            d="M13.3294 21.6667C17.9318 21.6667 21.6628 17.9357 21.6628 13.3333C21.6628 8.73096 17.9318 5 13.3294 5C8.72705 5 4.99609 8.73096 4.99609 13.3333C4.99609 17.9357 8.72705 21.6667 13.3294 21.6667ZM20.6211 13.3333C20.6211 17.3604 17.3565 20.625 13.3294 20.625C9.30235 20.625 6.03776 17.3604 6.03776 13.3333C6.03776 9.30626 9.30235 6.04167 13.3294 6.04167C17.3565 6.04167 20.6211 9.30626 20.6211 13.3333Z"
            fill="white"
          />
        </g>
        <path
          d="M88.918 40.7133L95.5896 44.6037L98.0148 40.3611C98.251 39.9475 98.1109 39.4187 97.7014 39.1801L92.5125 36.1542C92.103 35.9156 91.5794 36.0572 91.3432 36.4707L88.918 40.7133Z"
          fill="#F9A7A7"
        />
        <path
          d="M75.9062 79.0444L69.2347 75.1541L71.6599 70.9118L78.3314 74.8022L75.9062 79.0444Z"
          fill="#F9A7A7"
        />
        <path
          d="M69.2241 75.1527L75.8957 79.043L69.7906 82.6956C69.5036 82.8674 69.1406 82.6558 69.1446 82.3191L69.2241 75.1527Z"
          fill="#A4C9FF"
        />
        <path
          d="M78.332 74.8009L71.6604 70.9106L88.9226 40.7147L95.5942 44.605L78.332 74.8009Z"
          fill="#4671C6"
        />
        <path
          d="M93.0664 36.4785L94.9782 33.1337C95.301 32.5687 96.0164 32.3755 96.5755 32.7015L98.6274 33.898C99.1865 34.224 99.3782 34.9465 99.0554 35.5111L97.1436 38.8559L93.0664 36.4785Z"
          fill="#A4C9FF"
        />
        <path
          d="M95.5881 45.3988C95.4511 45.3988 95.3153 45.3626 95.1944 45.2922L88.5228 41.4019C88.3421 41.2965 88.2098 41.1228 88.1558 40.9192C88.1015 40.7156 88.1302 40.4985 88.2342 40.3156L90.6594 36.0734C90.8791 35.6893 91.2334 35.4149 91.6574 35.3C92.081 35.1847 92.5239 35.2439 92.9047 35.4658L98.0936 38.4913C98.8783 38.949 99.1483 39.9661 98.6952 40.7586L96.27 45.0012C96.1653 45.1837 95.9936 45.3169 95.7917 45.3718C95.7251 45.3901 95.6566 45.3988 95.5881 45.3988ZM89.9921 40.4222L95.2995 43.5173L97.331 39.9633C97.3476 39.9351 97.3342 39.885 97.3062 39.8687L92.1169 36.8432C92.0889 36.8273 92.0397 36.8408 92.0235 36.8686L89.9921 40.4222Z"
          fill="#3762CC"
        />
        <path
          d="M75.9002 79.8383C75.7631 79.8383 75.6273 79.8021 75.5065 79.7317L68.8352 75.8414C68.4584 75.6219 68.3297 75.1356 68.547 74.7551L70.9722 70.5125C71.0769 70.3299 71.2486 70.1967 71.4506 70.1419C71.6521 70.0874 71.8675 70.116 72.0482 70.2214L78.7194 74.1117C79.0962 74.3312 79.225 74.8175 79.0076 75.198L76.5824 79.4403C76.4777 79.6228 76.3061 79.756 76.1041 79.8108C76.0372 79.8291 75.9683 79.8383 75.9002 79.8383ZM70.3045 74.8616L75.6116 77.9567L77.2494 75.0915L71.9423 71.9964L70.3045 74.8616Z"
          fill="#3762CC"
        />
        <path
          d="M69.5787 83.551C69.3689 83.551 69.1586 83.4962 68.9708 83.3864C68.5909 83.1649 68.3582 82.7522 68.3634 82.3097L68.4429 75.1437C68.446 74.8614 68.5976 74.6018 68.8405 74.4618C69.083 74.3218 69.3815 74.3226 69.624 74.4642L76.2952 78.3545C76.5374 78.4957 76.6874 78.7561 76.6889 79.0388C76.6905 79.3215 76.5437 79.5836 76.3031 79.7275L70.198 83.3805C70.0071 83.4942 69.7929 83.551 69.5787 83.551ZM70.0027 76.5215L69.9452 81.6826L74.3417 79.0519L70.0027 76.5215Z"
          fill="#3762CC"
        />
        <path
          d="M78.322 75.5958C78.185 75.5958 78.0492 75.5596 77.9283 75.4892L71.2571 71.5989C70.8803 71.3794 70.7516 70.8931 70.9689 70.5126L88.2311 40.3156C88.3358 40.1331 88.5074 39.9999 88.7094 39.945C88.9114 39.8901 89.1267 39.9188 89.307 40.0245L95.9787 43.9149C96.3555 44.1343 96.4842 44.6206 96.2669 45.0012L79.0043 75.1982C78.8996 75.3807 78.7279 75.5139 78.526 75.5687C78.459 75.587 78.3901 75.5958 78.322 75.5958ZM72.7264 70.6191L78.0334 73.7142L94.5086 44.8946L89.2011 41.7995L72.7264 70.6191Z"
          fill="#3762CC"
        />
        <path
          d="M97.1455 39.6513C97.0085 39.6513 96.8727 39.6151 96.7518 39.5448L92.6751 37.1674C92.2983 36.9479 92.1695 36.4616 92.3869 36.0811L94.2987 32.7363C94.5597 32.2794 94.9817 31.9522 95.4869 31.8158C95.9912 31.679 96.5191 31.7494 96.9715 32.0134L99.0235 33.2099C99.4758 33.4739 99.7998 33.8997 99.9349 34.4095C100.07 34.9196 100.001 35.452 99.7392 35.9089L97.8278 39.2537C97.7231 39.4362 97.5514 39.5694 97.3495 39.6243C97.2825 39.6422 97.2136 39.6513 97.1455 39.6513ZM94.1443 36.1876L96.8573 37.7698L98.3754 35.1137C98.4443 34.9932 98.429 34.8787 98.414 34.821C98.3987 34.7634 98.3554 34.6564 98.2361 34.5868L96.1841 33.3904C96.0644 33.3212 95.951 33.3367 95.8943 33.3518C95.8376 33.3669 95.7317 33.411 95.6628 33.5311L94.1443 36.1876Z"
          fill="#3762CC"
        />
        <path
          d="M13.3893 27.0458C6.00629 27.0458 0 20.9797 0 13.5231C0 6.06652 6.00629 0 13.3893 0C20.7724 0 26.7787 6.06612 26.7787 13.5227C26.7787 20.9793 20.7724 27.0458 13.3893 27.0458ZM13.3893 1.59049C6.87479 1.59049 1.5748 6.94327 1.5748 13.5231C1.5748 20.103 6.87479 25.4554 13.3893 25.4554C19.9039 25.4554 25.2039 20.1026 25.2039 13.5231C25.2039 6.94367 19.9043 1.59049 13.3893 1.59049Z"
          fill="#3762CC"
        />
        <defs>
          <clipPath id="clip0_2891_124858">
            <rect width="16.6667" height="16.6667" fill="white" transform="translate(4.99609 5)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
export const BlankImageIcon = () => {
  return (
    <BlankImageIconWrapper>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 40C4.33333 40 2.91667 39.4167 1.75 38.25C0.583333 37.0833 0 35.6667 0 34V6C0 4.33333 0.583333 2.91667 1.75 1.75C2.91667 0.583333 4.33333 0 6 0H34C35.6667 0 37.0833 0.583333 38.25 1.75C39.4167 2.91667 40 4.33333 40 6V34C40 35.6667 39.4167 37.0833 38.25 38.25C37.0833 39.4167 35.6667 40 34 40H6ZM6 36H34C34.5667 36 35.0413 35.808 35.424 35.424C35.808 35.0413 36 34.5667 36 34V6C36 5.43333 35.808 4.958 35.424 4.574C35.0413 4.19133 34.5667 4 34 4H6C5.43333 4 4.958 4.19133 4.574 4.574C4.19133 4.958 4 5.43333 4 6V34C4 34.5667 4.19133 35.0413 4.574 35.424C4.958 35.808 5.43333 36 6 36ZM8 32L16 24L19.6 27.55L24 22L32 32H8ZM12 16C10.9 16 9.95867 15.608 9.176 14.824C8.392 14.0413 8 13.1 8 12C8 10.9 8.392 9.95867 9.176 9.176C9.95867 8.392 10.9 8 12 8C13.1 8 14.042 8.392 14.826 9.176C15.6087 9.95867 16 10.9 16 12C16 13.1 15.6087 14.0413 14.826 14.824C14.042 15.608 13.1 16 12 16Z"
          fill="#BBBCC0"
        />
      </svg>
    </BlankImageIconWrapper>
  );
};
const BlankImageIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12rem;
  gap: 8rem;

  width: 346rem;
  height: 194rem;

  /* Grayscale/G_25 (F4F5F5)

input Disabled bg

라인/텍스트 버튼 호버 bg (Line btn Hover bg)
*/
  background: #f4f5f5;
  border-radius: 8rem;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const PaperAirplaneIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.7889 63.1815L39.8367 74.9559C39.0783 75.703 37.7949 75.1657 37.7949 74.1012V57.6074H51.7887V63.1815H51.7889Z"
        fill="#879499"
      />
      <path
        d="M46.0766 58.7473L72.7533 80.7163C73.4374 81.2798 74.4757 80.9323 74.6825 80.0702L93.5439 1.48236C93.7759 0.515557 92.7986 -0.297921 91.89 0.105986L7.13438 37.775C6.18437 38.1973 6.18437 39.5455 7.13438 39.9678L34.4821 52.1223L46.0766 58.7473Z"
        fill="#CAEBF9"
      />
      <path
        d="M85.0659 10.3477L46.0772 58.7476L39.7234 74.7096C39.2248 75.8067 37.6012 75.5662 37.4418 74.3717L34.4824 52.1226L83.3345 8.69871C84.4347 7.72019 85.9894 9.20144 85.0659 10.3477Z"
        fill="#BCCED5"
      />
      <path
        d="M48.793 77.5632C48.2073 76.9777 47.2577 76.9775 46.6721 77.5634L33.4217 90.814C32.836 91.4 32.836 92.3494 33.4217 92.9351C33.7145 93.2279 34.0985 93.3742 34.4821 93.3742C34.8659 93.3742 35.2497 93.2279 35.5424 92.9349L48.7929 79.6843C49.3788 79.0986 49.3788 78.1492 48.793 77.5632Z"
        fill="#04A1EE"
      />
      <path
        d="M26.7954 61.1031L13.6483 74.2503C13.0625 74.8363 13.0625 75.7857 13.6483 76.3716C13.9411 76.6644 14.3251 76.8107 14.7086 76.8107C15.0924 76.8107 15.4762 76.6642 15.769 76.3716L28.9161 63.2243C29.5018 62.6384 29.5018 61.689 28.9161 61.1032C28.3307 60.5177 27.3811 60.5177 26.7954 61.1031Z"
        fill="#04A1EE"
      />
      <path
        d="M26.7956 77.5632L6.91978 97.4396C6.33403 98.0256 6.33403 98.975 6.91978 99.5607C7.21255 99.8535 7.59654 99.9998 7.98013 99.9998C8.36392 99.9998 8.74771 99.8535 9.04048 99.5605L28.9165 79.6841C29.5022 79.0982 29.5022 78.1488 28.9165 77.563C28.331 76.9777 27.3813 76.9777 26.7956 77.5632Z"
        fill="#04A1EE"
      />
    </svg>
  );
};

// export const ToggleButtonIcon = ({ active }) => {
//   if (active) {
//     return (
//       <svg
//         width="45"
//         height="34"
//         viewBox="0 0 45 34"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <rect y="7" width="40" height="18" rx="9" fill="#E6ECFF" />
//         <g filter="url(#filter0_dd_3778_448551)">
//           <circle cx="28" cy="16" r="12" fill="#0C2CCA" />
//         </g>
//         <defs>
//           <filter
//             id="filter0_dd_3778_448551"
//             x="11"
//             y="0"
//             width="34"
//             height="34"
//             filterUnits="userSpaceOnUse"
//             colorInterpolationFilters="sRGB"
//           >
//             <feFlood floodOpacity="0" result="BackgroundImageFix" />
//             <feColorMatrix
//               in="SourceAlpha"
//               type="matrix"
//               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//               result="hardAlpha"
//             />
//             <feOffset dy="1" />
//             <feGaussianBlur stdDeviation="2.5" />
//             <feComposite in2="hardAlpha" operator="out" />
//             <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
//             <feBlend
//               mode="normal"
//               in2="BackgroundImageFix"
//               result="effect1_dropShadow_3778_448551"
//             />
//             <feColorMatrix
//               in="SourceAlpha"
//               type="matrix"
//               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//               result="hardAlpha"
//             />
//             <feOffset />
//             <feGaussianBlur stdDeviation="1.5" />
//             <feComposite in2="hardAlpha" operator="out" />
//             <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
//             <feBlend
//               mode="normal"
//               in2="effect1_dropShadow_3778_448551"
//               result="effect2_dropShadow_3778_448551"
//             />
//             <feBlend
//               mode="normal"
//               in="SourceGraphic"
//               in2="effect2_dropShadow_3778_448551"
//               result="shape"
//             />
//           </filter>
//         </defs>
//       </svg>
//     );
//   } else {
//     return (
//       <svg
//         width="45"
//         height="34"
//         viewBox="0 0 45 34"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <rect y="7" width="40" height="18" rx="9" fill="#EBEBED" />
//         <g filter="url(#filter0_dd_3778_447225)">
//           <circle cx="28" cy="16" r="12" fill="#CECFD2" />
//         </g>
//         <defs>
//           <filter
//             id="filter0_dd_3778_447225"
//             x="11"
//             y="0"
//             width="34"
//             height="34"
//             filterUnits="userSpaceOnUse"
//             colorInterpolationFilters="sRGB"
//           >
//             <feFlood floodOpacity="0" result="BackgroundImageFix" />
//             <feColorMatrix
//               in="SourceAlpha"
//               type="matrix"
//               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//               result="hardAlpha"
//             />
//             <feOffset dy="1" />
//             <feGaussianBlur stdDeviation="2.5" />
//             <feComposite in2="hardAlpha" operator="out" />
//             <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
//             <feBlend
//               mode="normal"
//               in2="BackgroundImageFix"
//               result="effect1_dropShadow_3778_447225"
//             />
//             <feColorMatrix
//               in="SourceAlpha"
//               type="matrix"
//               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//               result="hardAlpha"
//             />
//             <feOffset />
//             <feGaussianBlur stdDeviation="1.5" />
//             <feComposite in2="hardAlpha" operator="out" />
//             <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
//             <feBlend
//               mode="normal"
//               in2="effect1_dropShadow_3778_447225"
//               result="effect2_dropShadow_3778_447225"
//             />
//             <feBlend
//               mode="normal"
//               in="SourceGraphic"
//               in2="effect2_dropShadow_3778_447225"
//               result="shape"
//             />
//           </filter>
//         </defs>
//       </svg>
//     );
//   }
// };
export const ToggleButtonIcon = ({ active, disabled = false }) => {
  if (active) {
    return (
      <svg
        width="44rem"
        height="23rem"
        viewBox="0 0 44 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.00292969"
          width="44"
          height="22"
          rx="11"
          fill={disabled ? '#CCCCCC' : '#0068FE'}
        />
        <path
          d="M24 11.0029C24 6.03237 28.0294 2.00293 33 2.00293C37.9706 2.00293 42 6.03237 42 11.0029C42 15.9735 37.9706 20.0029 33 20.0029C28.0294 20.0029 24 15.9735 24 11.0029Z"
          fill="white"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="44rem"
        height="22rem"
        viewBox="0 0 44 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.000976562" width="44" height="22" rx="11" fill="#CCCCCC" />
        <path
          d="M2 11.001C2 6.03041 6.02944 2.00098 11 2.00098C15.9706 2.00098 20 6.03041 20 11.001C20 15.9715 15.9706 20.001 11 20.001C6.02944 20.001 2 15.9715 2 11.001Z"
          fill="white"
        />
      </svg>
    );
  }
};
export const HandIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6186 65.133L27.6408 76.1442H61.7534L61.8481 65.133C65.7422 62.472 71.2922 56.7049 71.2922 45.4453C71.2922 38.9787 68.9312 34.1425 67.1158 31.3796C66.0085 29.7307 64.1529 28.7417 62.1666 28.7417C57.4871 28.7363 48.7762 28.7363 48.7762 28.7363H20.2852V46.3742C20.2852 57.0332 24.559 62.5495 27.6186 65.133Z"
        fill="#F8AE96"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4185 53.2148C49.4185 53.2148 47.248 38.2946 51.386 35.6364C55.5242 32.9786 66.1096 34.5926 64.2354 40.6519C62.3612 46.7112 49.4185 53.2148 49.4185 53.2148Z"
        fill="#E69A83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.4695 80.826C66.4695 78.2406 64.3734 76.1445 61.7881 76.1445C53.4737 76.1445 36.2263 76.1445 27.9119 76.1445C25.3265 76.1445 23.2305 78.2406 23.2305 80.826V94.2867H66.4695V80.826Z"
        fill="#4399A4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8302 80.826C59.8302 78.2406 57.7341 76.1445 55.1485 76.1445C48.1246 76.1445 34.9361 76.1445 27.9119 76.1445C25.3265 76.1445 23.2305 78.2406 23.2305 80.826V91.0538H59.8302V80.826Z"
        fill="#51ACB8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.9551 18.1255L67.4006 9.70417C67.8068 8.71175 68.6939 7.99715 69.7498 7.81175C72.7857 7.27889 79.1127 6.16878 82.1484 5.63591C83.2045 5.45052 84.2821 5.82046 85.0017 6.61541C87.0515 8.87917 91.1088 13.3603 91.1088 13.3603C91.1088 13.3603 82.3295 14.9011 78.5503 15.5643C77.4941 15.7497 76.6071 16.464 76.2012 17.4564C75.8009 18.4345 75.2874 19.6898 74.8714 20.7064C74.4166 21.8182 74.6402 23.0927 75.4464 23.9831C76.1838 24.7974 77.0941 25.8028 77.8031 26.5862C78.5228 27.3809 79.6003 27.7508 80.6565 27.5654C84.4354 26.9022 93.2149 25.3615 93.2149 25.3615C93.2149 25.3615 90.9259 30.9564 89.7694 33.7831C89.3633 34.7755 88.4762 35.4898 87.42 35.6752C84.3843 36.2081 78.0574 37.3185 75.0214 37.8511C73.9655 38.0365 72.888 37.6668 72.1683 36.8719L66.0613 30.1266L13.8051 39.2971C12.2144 39.5764 10.5778 39.212 9.25566 38.2848C7.93375 37.3573 7.03403 35.9427 6.75481 34.3522C6.75453 34.3502 6.75425 34.3483 6.75369 34.346C6.47476 32.7556 6.8388 31.1191 7.76633 29.7969C8.69358 28.4747 10.1082 27.5752 11.6989 27.296L63.9551 18.1255Z"
        fill="#DDD4D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40039 29.0328C9.28522 28.1294 10.4363 27.5174 11.6981 27.296L63.9543 18.1255L67.3998 9.70417C67.806 8.71175 68.6931 7.99715 69.749 7.81175C72.7849 7.27889 79.1119 6.16878 82.1476 5.63591C83.2038 5.45052 84.2813 5.82046 85.001 6.61541C87.0507 8.87917 91.108 13.3603 91.108 13.3603C91.108 13.3603 82.3288 14.9011 78.5495 15.5643C77.4934 15.7497 76.6063 16.464 76.2004 17.4564C75.8001 18.4345 75.2866 19.6898 74.8706 20.7064C74.4158 21.8182 74.6394 23.0927 75.4456 23.9831C76.183 24.7974 77.0934 25.8028 77.8024 26.5862C78.522 27.3809 79.5995 27.7508 80.6557 27.5654L93.0951 25.3825L91.5023 29.2747C91.0965 30.2671 90.2094 30.9817 89.1532 31.1671C86.1172 31.7 79.7906 32.8101 76.7546 33.3429C75.6984 33.5283 74.6212 33.1584 73.9015 32.3637L67.7945 25.6185L15.5383 34.789C13.9476 35.0682 12.3111 34.7039 10.9889 33.7766C9.66668 32.8491 8.76724 31.4345 8.48803 29.8438C8.48775 29.8418 8.48719 29.8398 8.48691 29.8379C8.44 29.5705 8.41135 29.3016 8.40039 29.0328Z"
        fill="#ECE4E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6523 21.3564C30.6523 20.5286 30.3234 19.7348 29.738 19.1494C29.1529 18.5643 28.3588 18.2354 27.5312 18.2354C26.2256 18.2354 24.7116 18.2354 23.4062 18.2354C22.5784 18.2354 21.7846 18.5643 21.1992 19.1494C20.6138 19.7348 20.2852 20.5286 20.2852 21.3564V39.407C20.2852 40.2348 20.6138 41.0286 21.1992 41.614C21.7846 42.1991 22.5784 42.528 23.4062 42.528H27.5312C28.3588 42.528 29.1529 42.1991 29.738 41.614C30.3234 41.0286 30.6523 40.2348 30.6523 39.407C30.6523 34.746 30.6523 26.0174 30.6523 21.3564Z"
        fill="#E69A83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0242 21.3564C29.0242 19.6328 27.6267 18.2354 25.9031 18.2354C25.0916 18.2354 24.2175 18.2354 23.4062 18.2354C21.6823 18.2354 20.2852 19.6328 20.2852 21.3564V34.7019C20.2852 36.4255 21.6823 37.823 23.4062 37.823H25.9031C27.6267 37.823 29.0242 36.4255 29.0242 34.7019C29.0242 31.0429 29.0242 25.0154 29.0242 21.3564Z"
        fill="#F8AE96"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.0195 15.8535C41.0195 14.1299 39.622 12.7324 37.8981 12.7324C36.5928 12.7324 35.0787 12.7324 33.7734 12.7324C32.0495 12.7324 30.6523 14.1299 30.6523 15.8535V41.0108C30.6523 42.7347 32.0495 44.1321 33.7734 44.1321H37.8981C39.622 44.1321 41.0195 42.7347 41.0195 41.0108C41.0195 34.997 41.0195 21.8675 41.0195 15.8535Z"
        fill="#E69A83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3217 15.8535C39.3217 15.0257 38.9928 14.2319 38.4077 13.6465C37.8223 13.0611 37.0285 12.7324 36.2007 12.7324C35.4111 12.7324 34.563 12.7324 33.7734 12.7324C32.9456 12.7324 32.1518 13.0611 31.5664 13.6465C30.981 14.2319 30.6523 15.0257 30.6523 15.8535V35.5858C30.6523 36.4136 30.981 37.2074 31.5664 37.7928C32.1518 38.3782 32.9456 38.7071 33.7734 38.7069H36.2007C37.0285 38.7071 37.8223 38.3782 38.4077 37.7928C38.9928 37.2074 39.3217 36.4136 39.3217 35.5858C39.3217 30.5894 39.3217 20.8501 39.3217 15.8535Z"
        fill="#F8AE96"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.3864 13.2695C51.3864 11.5456 49.9892 10.1484 48.2653 10.1484C46.96 10.1484 45.4459 10.1484 44.1406 10.1484C42.4167 10.1484 41.0195 11.5456 41.0195 13.2695V39.4074C41.0195 41.131 42.4167 42.5285 44.1406 42.5285H48.2653C49.9892 42.5285 51.3864 41.131 51.3864 39.4074C51.3864 33.2189 51.3864 19.458 51.3864 13.2695Z"
        fill="#E69A83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7586 13.2695C49.7586 12.4417 49.4296 11.6479 48.8443 11.0625C48.2591 10.4771 47.465 10.1484 46.6375 10.1484C45.826 10.1484 44.9518 10.1484 44.1406 10.1484C43.3128 10.1484 42.519 10.4771 41.9336 11.0625C41.3482 11.6479 41.0195 12.4417 41.0195 13.2695V34.0875C41.0195 34.9153 41.3482 35.7091 41.9336 36.2945C42.519 36.8796 43.3128 37.2085 44.1406 37.2085H46.6375C47.465 37.2085 48.2591 36.8796 48.8443 36.2945C49.4296 35.7091 49.7586 34.9153 49.7586 34.0875C49.7586 28.8796 49.7586 18.4771 49.7586 13.2695Z"
        fill="#F8AE96"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.7519 15.8535C61.7519 14.1296 60.3547 12.7324 58.6308 12.7324C57.3255 12.7324 55.8115 12.7324 54.5061 12.7324C52.7822 12.7324 51.3848 14.1296 51.3848 15.8535V31.6538C51.3848 33.3776 52.7822 34.7748 54.5061 34.7748H58.6308C60.3547 34.7748 61.7519 33.3776 61.7519 31.6538C61.7519 27.4597 61.7519 20.0476 61.7519 15.8535Z"
        fill="#E69A83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.1836 15.8535C60.1836 15.0257 59.8547 14.2319 59.2696 13.6465C58.6842 13.0614 57.8904 12.7324 57.0626 12.7324C56.2325 12.7324 55.3359 12.7324 54.5061 12.7324C53.6783 12.7324 52.8845 13.0614 52.2991 13.6465C51.7137 14.2319 51.3848 15.0257 51.3848 15.8535V22.1813C51.3848 23.0091 51.7137 23.8029 52.2991 24.3883C52.8845 24.9737 53.6783 25.3026 54.5061 25.3026H57.0626C57.8904 25.3026 58.6842 24.9737 59.2696 24.3883C59.8547 23.8029 60.1836 23.0091 60.1836 22.1813C60.1836 20.2515 60.1836 17.7833 60.1836 15.8535Z"
        fill="#F8AE96"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.508 52.5925C53.0159 44.0369 60.8347 41.3633 60.8347 41.3633C52.5226 41.7226 49.9162 34.7563 49.1176 31.0212C49.0019 30.463 49.1437 29.8821 49.5035 29.44C49.8634 28.9976 50.4035 28.7411 50.9738 28.7411C53.8673 28.7363 58.9622 28.7363 62.1665 28.7363C64.1547 28.736 66.0122 29.7259 67.1207 31.3762C68.9311 34.1425 71.292 38.9787 71.292 45.4453C71.292 62.79 58.1224 67.1015 58.1224 67.1015C58.1224 67.1015 48.0656 56.1105 49.508 52.5925Z"
        fill="#E69A83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0153 40.2904C52.9929 41.3836 59.5625 27.0092 57.8561 27.0098C55.7788 27.0103 53.7313 27.0115 52.2561 27.014C50.9856 27.014 49.7788 27.5876 48.9743 28.5761C48.1698 29.5643 47.853 30.8623 48.1117 32.11C48.1128 32.1148 48.1136 32.1196 48.1148 32.1244C48.6063 34.4227 49.7055 37.7072 52.0153 40.2904Z"
        fill="#E69A83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.4496 28.7363C62.2836 29.2535 62.9842 29.9728 63.4805 30.8377C64.9901 33.4835 67.1971 38.5327 67.1971 45.4453C67.1971 58.4537 61.1364 64.7492 58.1063 67.0838C57.658 66.5914 48.0993 56.0282 49.508 52.5925C53.0159 44.0369 60.8347 41.3633 60.8347 41.3633C52.5226 41.7226 49.9162 34.7563 49.1176 31.0212C49.0019 30.463 49.1437 29.8821 49.5035 29.44C49.8634 28.9976 50.4035 28.7411 50.9738 28.7411C53.6597 28.7366 58.2429 28.7363 61.4496 28.7363Z"
        fill="#F8AE96"
      />
    </svg>
  );
};
