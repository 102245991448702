import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { useForceUpdate } from 'Common/Utils/util';
import { requestLoadSlotGetRankLog } from 'Common/Service/slot';
import { Icon } from 'Common/UI/atoms';
import { currencyComma } from 'Common/Utils';
import { ResponsiveBump } from '@nivo/bump';
import { useSelector } from 'react-redux';
const RankLogGraph = ({
  id = null,
  nvmid = null,
  group_search_nvmid = null,
  data = null,
  showInfoChk = false,
}) => {
  const { loginInfo } = useSelector((state) => state.common);
  const [graphData, setGraphData] = useState(data);
  const [info, setInfo] = useState();
  const [shopLinkInfo, setShopLinkInfo] = useState();
  // const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (!data) return;
    setGraphData(data);
  }, [data]);

  const loadNvmidRankInfo = async ({ id, nvmid, group_search_nvmid }) => {
    const result = await requestLoadSlotGetRankLog({ id, nvmid, group_search_nvmid });
    if (showInfoChk) {
      setInfo(result?.info);
      setShopLinkInfo(result?.shop_link_info);
    }

    setGraphData(result?.list);
  };
  useEffect(() => {
    if (!nvmid && !id) return;
    loadNvmidRankInfo({ id, nvmid, group_search_nvmid });
  }, [nvmid, group_search_nvmid, id]);
  if (!data && !nvmid && !id) return;
  if (!graphData) return;
  return (
    <Box column>
      {showInfoChk && info && (
        <Box column>
          <StatusTitleText>
            <Box>
              {!info?.search_rank ? '-' : info?.search_rank?.toString()} (
              {!info?.search_last_rank ? (
                '-'
              ) : info?.search_last_rank > info?.search_rank ? (
                <>
                  <Icon.DoubleArrow direction="top" fill={'#0000f0'} size="15" />
                  <small>{info?.search_last_rank - info?.search_rank}</small>
                </>
              ) : (
                <>
                  <Icon.DoubleArrow direction="bottom" fill={'#f00000'} size="15" />
                  <b>{info?.search_rank - info?.search_last_rank}</b>
                </>
              )}
              )
            </Box>
            {info.smartstore_chk === false ? (
              <small>[오픈마켓]</small>
            ) : info.navershopping_foreign_chk ? (
              <small>[해외직구]</small>
            ) : info.navershopping_chk ? (
              <small>[쇼핑윈도]</small>
            ) : info.brandstore_chk ? (
              <small>[브랜드몰]</small>
            ) : null}
            {info.adult_chk ? <small>[성인인증상품]</small> : null}
            <h1>{info?.mall_name?.toString()}</h1> {info?.title} [{info.nvmid}]
          </StatusTitleText>
          {shopLinkInfo && loginInfo?.user_level >= 5 ? (
            <Box>
              유효타: [{currencyComma(shopLinkInfo?.to_click_count)}], 유입: [
              {currencyComma(shopLinkInfo?.to_etc_do1_count)}], 노출: [
              {currencyComma(shopLinkInfo?.to_view_count)}]
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}
      <Box column width={1000} height={400}>
        <ResponsiveBump
          data={graphData}
          width={1000}
          interpolation="linear"
          xPadding={0}
          xOuterPadding={0}
          yOuterPadding={0.6}
          colors={{ scheme: 'spectral' }}
          lineWidth={3}
          activeLineWidth={1}
          inactiveOpacity={0.15}
          startLabelPadding={5}
          endLabel={true}
          endLabelPadding={21}
          pointSize={7}
          activePointSize={7}
          inactivePointSize={0}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={3}
          activePointBorderWidth={3}
          inactivePointBorderWidth={3}
          pointBorderColor={{ from: 'serie.color', modifiers: [] }}
          enableGridX={false}
          axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -36,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'ranking',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
          axisRight={null}
        />
        {/* <ResponsiveLine
          data={graphData}
          width={1000}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          yFormat=" <-#,.2p"
          axisTop={null}
          axisRight={null}
          // axisBottom={{
          //   tickSize: 5,
          //   tickPadding: 5,
          //   tickRotation: 0,
          //   legend: 'transportation',
          //   legendOffset: 36,
          //   legendPosition: 'middle',
          // }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '순위',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          enableGridY={false}
          pointSize={7}
          colors={{ scheme: 'category10' }}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          margin={{ top: 40, right: 170, bottom: 40, left: 60 }}
          // useMesh={true}
          legends={[
            {
              anchor: 'top-right',
              direction: 'column',
              justify: false,
              translateX: 170,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 150,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        /> */}
      </Box>
      {/* </Block> */}
    </Box>
  );
};

const StatusTitleText = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 14rem;
  /* display: inline-block; */
  /* display: inline; */
  gap: 12rem;
  /* text-align: left; */
  /* background-color: red; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  h1 {
    font-size: 13rem;
    /* line-height: 9rem; */
    font-weight: 500;
    color: #4879ba;
  }
  small {
    /* width: 100%; */
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #f00000;
  }
`;
export default RankLogGraph;
