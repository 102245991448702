import React from "react";
import styled from "styled-components";
import { Box, Button, Icon, IconButton } from "../atoms";

const CheckedController = ({
    size,
    idx,
    onDownload,
    onDelete,
    downloadDisabled,
    deleteDisabled,
    addBtn,
    notChecked,
    downloadText = "다운로드",
    deleteText = "삭제",
    isDelete = true,
}) => {
    const matching = () => {
        switch (size) {
            case "xl":
                return { btn: "xs", number: 48 };
            case "xxxl":
                return { btn: "xs", number: 56 };
            default:
                return { btn: "xs", number: 48 };
        }
    };
    return (
        <Wrapper sizeY={matching().number}>
            <p>
                선택한 <strong>{idx}</strong> 개의 항목을
            </p>
            {(onDownload || onDelete || addBtn) && (
                <Box gap={8}>
                    {addBtn}
                    {onDownload && (
                        <Button
                            size={matching().btn}
                            Theme="line"
                            onClick={onDownload}
                            disabled={downloadDisabled}
                        >
                            {downloadText}
                        </Button>
                    )}
                    {onDelete && isDelete && (
                        <Button
                            size={matching().btn}
                            Theme="line"
                            onClick={onDelete}
                            disabled={deleteDisabled}
                        >
                            {deleteText}
                        </Button>
                    )}
                </Box>
            )}
            <IconButton
                Theme="unFill"
                size={matching().btn}
                onClick={notChecked}
                Icon={Icon.Closer}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${({ sizeY }) => `
    display: flex;
    align-items: center;
    background-color: var(--White);
    padding: 0 20rem;
    height: ${sizeY}rem;
    gap: 20rem;
    border-radius: 8rem;
    p {
        font-size: 14rem;
        strong {
            color: var(--P_500);
            margin: 0 4rem;
            font-weight: 700;
        }
    }
    `}
`;

export default CheckedController;
