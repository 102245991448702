import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetActivePatterns,
  requestLoadWorkNewGetManualCountryNormal,
  requestLoadWorkNewGetManualOtherLink,
  requestLoadWorkNewSetActivePatterns,
  requestLoadWorkNewSetManualCountryNormal,
  requestLoadWorkNewSetManualOtherLink,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  getKorDate,
  reload_second,
  to_integer,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { sortSet } from 'Common/Utils';
import { onShowToast } from 'features/common/commonSlice';
import PercentModule from './Module/PercentModule';

const WorkNewPatternAddPattern = () => {
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const [patterns, setPatterns] = useState();
  const [patternId, setPatternId] = useState();
  const [patternInfo, setPatternInfo] = useState();

  const getWorkInfos = async (id = null) => {
    const result = await requestLoadWorkNewGetActivePatterns({ id });
    console.log(result);
    setPatterns(result?.patterns);
    setPatternInfo(
      result?.pattern_info
        ? { ...result?.pattern_info, active_list: result?.pattern_info?.active_list?.join('\n') }
        : {},
    );
    // setWorkstationNames(workstation_infos);
  };
  const addTextPattern = (text) => {
    var active_list = patternInfo?.active_list?.trim();
    active_list = active_list ? `${active_list}\n${text}` : text;
    setPatternInfo({ ...patternInfo, active_list });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Grid column={['200rem', '1fr', '400rem']} valign="top" gap={12}>
      <Box column valign="top" height="100%">
        {patterns?.map((e) => {
          return (
            <Box>
              <TextButton
                onClick={async () => {
                  getWorkInfos(e?.id);
                }}
              >
                {e?.pattern_name}
              </TextButton>
            </Box>
          );
        })}
      </Box>
      <Box column>
        <Box>패턴 등록</Box>
        <Box gap={8}>
          <Box column width="100%">
            <Box>
              <Button
                fill
                Theme={patternInfo?.id ? 'unFill' : null}
                onClick={async () => {
                  const result = await requestLoadWorkNewSetActivePatterns(patternInfo);
                  if (!result) return;
                  if (patternInfo?.id) {
                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${patternInfo?.pattern_name}의 패턴이 수정 되었습니다.`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  } else {
                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `${patternInfo?.pattern_name}의 패턴이 신규 추가 되었습니다.`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }
                }}
              >
                {patternInfo?.id ? '수정' : '신규 등록'}
              </Button>
            </Box>
            <Box>
              <Box>패턴이름</Box>
              <Box>
                <InputComponent
                  name={'pattern_name'}
                  value={patternInfo?.pattern_name}
                  onChange={(e) => {
                    setPatternInfo({ ...patternInfo, pattern_name: e.target.value });
                  }}
                />
              </Box>
              <Box>
                <button
                  onClick={() => {
                    setPatternInfo({ ...patternInfo, id: null, pattern_name: null });
                  }}
                >
                  (고유번호: {patternInfo?.id})
                </button>
              </Box>
            </Box>
            <Box>
              <Box>패턴 종류</Box>
              <Box>
                <CheckBox
                  type="radio"
                  checked={patternInfo?.pattern_type === 'active'}
                  onChange={() => {
                    patternInfo.pattern_type = 'active';
                    setPatternInfo(patternInfo);
                  }}
                >
                  활동
                </CheckBox>{' '}
                <CheckBox
                  type="radio"
                  checked={patternInfo?.pattern_type === 'click'}
                  onChange={() => {
                    patternInfo.pattern_type = 'click';
                    setPatternInfo(patternInfo);
                  }}
                >
                  클릭
                </CheckBox>
              </Box>
            </Box>

            {/* <Box>
              <Box>허용 시간 설정</Box>
              <Box>
                <CheckBox
                  checked={patternInfo?.gender_chk}
                  onChange={() => {
                    patternInfo.hour_chk = !patternInfo.hour_chk;
                    setPatternInfo(patternInfo);
                  }}
                >
                  사용
                </CheckBox>
              </Box>
              <Box gap={8}>
                <Box width={100}>
                  <InputComponent
                    name={'hour_start'}
                    value={patternInfo?.hour_start}
                    onChange={(e) => {
                      setPatternInfo({ ...patternInfo, hour_start: e.target.value });
                    }}
                  />
                </Box>
                ~
                <Box width={100}>
                  <InputComponent
                    name={'hour_end'}
                    value={patternInfo?.hour_end}
                    onChange={(e) => {
                      setPatternInfo({ ...patternInfo, hour_end: e.target.value });
                    }}
                  />
                </Box>
                시
              </Box>
            </Box> */}

            <Box>
              <InputComponent
                //   autocomplete={'off'}
                size={500}
                el="textarea"
                //   underLine

                placeholder={''}
                value={patternInfo?.active_list}
                onChange={(e) => {
                  //   addMdDelay();
                  setPatternInfo({ ...patternInfo, active_list: e.target.value });
                  //   addMd();
                  // setProductIds(e.target.value);
                }}
                // onKeyUp={onKeyUp}
                //   required
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box column>
        <Box>
          <TextButton onClick={() => addTextPattern('[접속]')}>[접속]</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[메인페이지]')}>[메인페이지]</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[통합검색]select_keyword')}>
            [통합검색]--키워드선택
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[통합검색-외부링크클릭]')}>
            [통합검색-외부링크클릭]
          </TextButton>
        </Box>
        {/* <Box>
          <TextButton onClick={() => addTextPattern('[통합검색_쇼핑키워드]')}>
            [통합검색_쇼핑키워드]
          </TextButton>
        </Box> */}
        <Box>
          <TextButton onClick={() => addTextPattern('[통합검색_쇼핑클릭]')}>
            [통합검색_쇼핑클릭]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[뒤로가기]통합검색')}>
            [뒤로가기]통합검색
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[탭]블로그')}>[탭]블로그</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[탭]통합')}>[탭]통합</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[탭]쇼핑')}>[탭]쇼핑</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[블로그접속]')}>[블로그접속]</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[뒤로가기]블로그검색')}>
            [뒤로가기]블로그검색
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[통합-관심상품]보기')}>
            [통합-관심상품]보기
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑-관심상품]보기')}>
            [쇼핑-관심상품]보기
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[통합-관심상품]클릭')}>
            [통합-관심상품]클릭
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑-관심상품]클릭')}>
            [쇼핑-관심상품]클릭
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑검색]select_keyword')}>
            [쇼핑검색]--키워드선택
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑검색_쇼핑키워드]')}>
            [쇼핑검색_쇼핑키워드]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑검색_쇼핑키워드]저장키워드')}>
            [쇼핑검색_쇼핑키워드]저장키워드
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑검색_쇼핑키워드]신규슬롯')}>
            [쇼핑검색_쇼핑키워드]신규슬롯
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑검색_쇼핑클릭]')}>
            [쇼핑검색_쇼핑클릭]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑검색_추가검색]')}>
            [쇼핑검색_추가검색]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[쇼핑검색_연관검색어]')}>
            {/* 기존에 검색한걸 이어서 검색함 */}
            [쇼핑검색_연관검색어]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[클릭카운터증가]')}>
            {/* 기존에 검색한걸 이어서 검색함 */}
            [클릭카운터증가]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[노출]n1_n2')}>[노출]n1_n2</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[대기]n1_n2')}>[대기]n1_n2</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[종료]')}>[종료]</TextButton>
        </Box>
        <Box>-----------------------</Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[자동_관심사키워드]')}>
            [자동_관심사키워드]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[수동_외부링크]')}>[수동_외부링크]</TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[자동_이슈키워드]')}>
            [자동_이슈키워드]
          </TextButton>
        </Box>
        {/* <Box>
          <TextButton onClick={() => addTextPattern('[자동_상품카테고리1]')}>
            [자동_상품카테고리1]
          </TextButton>
        </Box> */}
        <Box>
          <TextButton onClick={() => addTextPattern('[자동_신규상품_상품카테고리3]')}>
            [자동_신규상품_상품카테고리3]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[자동_기존상품_상품카테고리3]')}>
            [자동_기존상품_상품카테고리3]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[자동_국가별키워드]')}>
            [자동_국가별키워드]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[수동_국가별키워드]')}>
            [수동_국가별키워드]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[자동_검색량많은키워드]')}>
            [자동_검색량많은키워드]
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={() => addTextPattern('[수동_그룹키워드_그룹1]')}>
            [수동_그룹키워드_그룹1]
          </TextButton>
        </Box>
        {/* <Box> */}
        {/* <TextButton onClick={() => addTextPattern('[자동_타슬롯모든카테고리1노출]')}> */}
        {/* [자동_타슬롯모든카테고리1노출] */}
        {/* </TextButton> */}
        {/* </Box> */}
      </Box>
    </Grid>
  );
};

export default WorkNewPatternAddPattern;
