import { Box, Button, Grid, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestLoadWorkVmwareGerOrderInfos } from 'Common/Service/common';
import { currencyComma, get_percent, sortSet } from 'Common/Utils';
import PercentModule from './Module/PercentModule';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import styled from 'styled-components';

const WorkVmwareOrderInfos = () => {
  const [orderInfos, setOrderInfos] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);
  const [apiId, setApiId] = useState();

  const getOrderInfos = async () => {
    const order_infos = await requestLoadWorkVmwareGerOrderInfos();
    setOrderInfos(order_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getOrderInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getOrderInfos();
  }, []);
  return (
    <Grid column={['1fr', '1fr']} gap={8} valign="top" height="1000">
      <Box valign="top" column>
        {orderInfos?.using_infos
          ?.filter((e) => {
            const test_offChk = e?.time_ago_sec > 60 * 30;
            const vmware_name = e.vmware_name;
            if (vmware_name?.includes('test') && test_offChk) return false;
            return true;
          })
          ?.map((e, index) => {
            const offChk = e?.time_ago_sec > 60 * 5;
            const program_version = e.program_version;

            return (
              <Box index={index}>
                <Box>
                  <VmwareText>
                    {offChk ? (
                      <>
                        <TextFail>[Off] </TextFail>
                      </>
                    ) : (
                      <>
                        <TextSucceed>[On] </TextSucceed>
                      </>
                    )}
                  </VmwareText>
                  <VmwareText>
                    <TextSmall>{e?.vmware_name}</TextSmall>
                  </VmwareText>
                </Box>
                <Box>
                  <VmwareText>{e?.vmware_type}</VmwareText>
                </Box>
                <Box>
                  <VmwareText>
                    <TextWarning>{e?.vmware_network}</TextWarning>
                  </VmwareText>
                </Box>
                {/* <Box>
                  <VmwareText>{e?.time}</VmwareText>
                </Box> */}
                <Box>
                  <VmwareText>
                    <TextSmall>
                      <TextSucceed>{e?.time_ago_sec} sec ago</TextSucceed>
                    </TextSmall>
                  </VmwareText>
                </Box>
                <Box>
                  -
                  <VmwareText>
                    <TextSmall>
                      <TextSucceed>{program_version}</TextSucceed>
                    </TextSmall>
                  </VmwareText>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Box column>
        <Box>
          order list <TextSmall>{count}</TextSmall>
        </Box>
        <Box valign="top" column>
          {orderInfos?.order_infos?.map((e, index) => {
            const stop_chk =
              !e?.use1 || e?.order_expire_chk || e?.run_vmwares_count >= e?.run_vmwares_count_max;
            return (
              <OrderBox key={index} column>
                <Box>
                  <Box>{e?.order}</Box>

                  <Box>
                    {e?.run_vmwares_count}/{e?.run_vmwares_count_max}
                  </Box>
                  <Box>
                    {stop_chk ? (
                      <>
                        <TextFail>종료</TextFail> {e?.get_order_limit_time}
                      </>
                    ) : e?.order_start_chk ? (
                      <>
                        <TextSucceed>진행중</TextSucceed> {e?.get_order_limit_time}
                      </>
                    ) : (
                      <>
                        <TextWarning>대기</TextWarning> {e?.can_access_datetime}
                      </>
                    )}
                  </Box>
                  <Box>
                    {stop_chk || !e?.lately_get_order_time ? null : (
                      <>
                        최근 명령 받은시간: {e?.lately_get_order_time} (
                        {e?.lately_get_order_time_ago_sec} 초 전)
                      </>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Box>ordered vmwares</Box>
                  <Box>{e?.get_order_vmwares?.join(', ')}</Box>
                </Box>
              </OrderBox>
            );
          })}
        </Box>
      </Box>
    </Grid>
  );
};
const OrderBox = styled.div`
  font-size: 12rem;
`;

const VmwareText = styled.div`
  flex-direction: row;
  color: black;
  font-size: 13rem;
  font-weight: 700;
`;
export default WorkVmwareOrderInfos;
