import { FileJpgOutlined } from '@ant-design/icons';
import React from 'react';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import Icon from './IconBox';
import Button from './Button/Button';
import Category from './Category';

function Select({
  placeholder,
  fill,
  value,
  category,
  className,
  customClass,
  big,
  option,
  label,
  onChange,
  name,
  disabled,
  isOpen,
  Theme,
  size,
}) {
  const ref = useRef();
  const ref2 = useRef();
  const [vertical, setVertical] = useState('bottom');
  const [isActive, setIsActive] = useState(isOpen);
  const [selected, setSelected] = useState();
  const [currentHeigth, setCurrentHeigth] = useState(0);

  const onFocus = () => {
    setIsActive(true);
    setSelectListPosition();
  };

  const onBlur = (e) => {
    if (ref?.current !== e.target && !ref?.current?.contains(e.target)) {
      setIsActive(false);
    }
  };

  const onClick = (v) => {
    const [selected] = option.filter((i) => i.value === v);
    setSelected(selected);
    if (onChange) onChange(name, v);
    setTimeout(() => {
      setIsActive(false);
    }, 0);
  };

  const setSelectListPosition = () => {
    setVertical('top');
    setTimeout(() => {
      const intFrameHeight = window.innerHeight;
      const currentPoint = ref?.current?.getBoundingClientRect().top;
      const ListHeigth = ref2?.current?.clientHeight;
      intFrameHeight >= currentPoint + ListHeigth + 40 ? setVertical('top') : setVertical('bottom');
    }, 0);
  };

  useEffect(() => {
    setCurrentHeigth(ref?.current?.clientHeight);
  }, [ref]);

  useEffect(() => {
    setSelected(option.find((i) => i.selected || i.value === value));
    document.addEventListener('click', onBlur);
    return () => {
      document.removeEventListener('click', onBlur);
    };
  }, [option]);

  const selectList = (option) => {
    if (option?.length === 0) return;
    return (
      <SelectList ref={ref2} vertical={vertical} contentTop={currentHeigth} className="selectList">
        {option?.map(({ title, value }, idx) => (
          <SelectItem key={idx}>
            <button
              type="button"
              className={selected?.title === title ? 'active' : ''}
              onClick={(e) => onClick(value)}
              value={value}
            >
              {title}
            </button>
          </SelectItem>
        ))}
      </SelectList>
    );
  };
  return (
    <>
      <InputWrapper customClass={customClass}>
        {label && (
          <LabelText weight={400} big={big} className="out">
            {label}
          </LabelText>
        )}
        <Wrapper
          className={className}
          fill={fill}
          ref={ref}
          disabled={disabled}
          onFocus={(e) => onFocus(e)}
          category={category}
        >
          {category && <Category>{category}</Category>}
          <div className="innerBox">
            <Button
              size={size ? size : 'lg'}
              Theme={Theme ? Theme : 'unLine'}
              value={value}
              name={name}
              disabled={disabled}
              rightIcon={<Icon.Arrow size={'12'} direction={'bottom'} />}
            >
              {
                <>
                  {selected ? (
                    selected.title
                  ) : placeholder ? (
                    <span className="placeholder">{placeholder}</span>
                  ) : (
                    '선택하세요'
                  )}
                </>
              }
            </Button>
            {!disabled && isActive && selectList(option)}
          </div>
        </Wrapper>
      </InputWrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  .innerBox {
    position: relative;
    flex: 0 1 auto;
    width: 100%;
  }
  button {
    width: 100%;
    border-radius: ${(props) => (props.category ? '0 4rem 4rem 0' : '4rem')};
  }
  .rightIcon {
    margin-left: auto;
  }
`;
const SelectList = styled.ul`
  background: var(--White);
  box-shadow: 0rem 0rem 12rem rgb(13 45 148 / 12%);
  border-radius: 4rem;
  z-index: 10;
  ${({ contentTop, vertical }) => {
    return css`
      position: absolute;
      left: 0;
      ${vertical}:${contentTop + 8}rem;
      width: 100%;
    `;
  }}
`;
const SelectItem = styled.li`
  ${({ theme: { colors } }) => {
    return css`
      position: relative;
      border-radius: 4rem;
      color: #5b637c;
      button {
        width: 100%;
        padding: 13rem 20rem 11rem 14rem;
        font-weight: 500;
        font-size: 16rem;
        line-height: 20rem;
        text-align: left;
        color: inherit;
        border: 1rem solid transparent;
        &::after {
          display: none;
          position: absolute;
          left: 0;
          top: -1rem;
          height: calc(100% + 2rem);
          width: 6rem;
          content: '';
          border-radius: 4rem 0 0 4rem;
        }
        &:not(.active):hover {
          border-color: ${colors.primary};
          color: ${colors.primary};
          &::after {
            display: block;
            background-color: ${colors.primary};
          }
        }
        &.active {
          border-color: ${colors.primaryActive};
          color: ${colors.primaryActive};
          &::after {
            display: block;
            background-color: ${colors.primaryActive};
          }
        }
      }
    `;
  }}
`;

const LabelText = styled.span`
  ${({ weight, big }) => `
    display:block;
    width:100%;
    margin-bottom:8rem;
    font-weight: ${weight};
    font-size :${big ? '16rem' : '14rem'};
    line-height :${big ? '22rem' : '20rem'};
    color: #666666;
    &.out {
        padding-left:20rem;
    }
`}
`;
const InputWrapper = styled.div`
  width: 100%;
`;

Select.defaultProps = {
  onChange: () => {},
  option: [],
};
export default Select;
