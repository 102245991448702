import { useMutation } from 'react-query';
import { requestLoadShopLinkCountGetDate } from './count';
// all_force_test_click_allow_chk_false
const useMutations = (submit) => {
  const requestLoadShopLinkCountGetDateMutation = useMutation(requestLoadShopLinkCountGetDate, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadShopLinkCountGetDateMutation',
        success: true,
        result: data,
      });
    },
  });

  return {
    requestLoadShopLinkCountGetDateMutation,
  };
};

export default useMutations;
