import React from "react";
import styled, { css } from "styled-components";
import Icon from "./IconBox";

function Badge({
    children,
    fill,
    circle,
    size,
    color,
    barColor,
    txtColor,
    fontSize = 14,
    leftIcon = <Icon.PM size="10" />,
    status,
    className,
    icon = true,
}) {
    return (
        <Wrapper
            fil={fill}
            circle={circle}
            size={size}
            color={color}
            txtColor={txtColor}
            fSize={fontSize}
            barColor={barColor}
            status={status}
            icon={icon}
            className={`badge ${className || ""}`}
        >
            {icon && <i>{leftIcon}</i>}
            <span>{children}</span>
        </Wrapper>
    );
}

const propsStylee = css`
    ${({ fil, size, color, circle, status, txtColor, icon, barColor }) => {
        const propsSize = size ? `${size}rem` : "28rem";
        const propsColor = color ? color : "P_500";
        if (status) {
            return css`
                position: relative;
                color: var(--${txtColor || propsColor});
                padding-left: 16rem;
                ${icon
                    ? `
                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 8rem;
                    height: 8rem;
                    background: var(--${barColor || propsColor});
                    border-radius: 100rem;
                    transform: translateY(-50%);
                }`
                    : ""}
                i {
                    display: none;
                }
            `;
        }
        return css`
            height: ${propsSize};
            background-color: ${fil ? `var(--${propsColor})` : "var(--White)"};
            color: ${fil ? "var(--White)" : `var(--${propsColor})`};
            border-radius: ${circle ? "999rem" : "4rem"};
            padding: ${circle ? "0 9rem" : "0 8rem"};
            border: 1px solid ${fil ? "transparent" : `var(--${propsColor})`};
        `;
    }}
`;
const Wrapper = styled.div`
    ${({ fSize }) => `
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: ${fSize}rem;
        line-height: 172%;
        letter-spacing: -0.03rem;
        color: var(--White);
        i {
            display: inline-block;
            color: inherit;
            font-style: normal;
            line-height: 0;
            text-align: center;
            text-transform: none;
            vertical-align: -0.125em;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            svg {
                fill: currentcolor;
                line-height: 1;
                path {
                    fill: inherit;
                }
            }
        }
`}
    ${propsStylee};
`;
export default Badge;
