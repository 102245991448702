import { Box, Button, Icon, Tag } from "Common/UI/atoms";
import LabelBox from "Common/UI/molecules/LabelBox";
import React, { useState } from "react";
import FormBadgeModal from "./FormBadgeModal";
import { Wrapper } from "./styled";

const FormBadge = ({
    label,
    option,
    value = option,
    isEdit = true,
    onDelete,
    btn = true,
    sizeY = 44,
    btnName,
    onClick,
    size = "lg",
    disabled,
    side = "leftIcon",
    isWrap,
    row,
    gap,
    maxLength = 10,
    fontSize,
}) => {
    const [isModal, setIsModal] = useState(false);
    const valueSet = (target) => {
        const type = typeof target;
        const array = Array.isArray(target);
        let style = "";
        array
            ? target.map((i) => {
                  return (style +=
                      typeof i === "number" ? i + "rem " : i + " ");
              })
            : (style += type === "number" ? `${target}rem` : target);
        return target === undefined ? target : style;
    };
    const onToggleModal = () => setIsModal(!isModal);
    return (
        <Wrapper>
            <LabelBox gap={gap} fontSize={fontSize} label={label} row={row}>
                <Box
                    gap={12}
                    style={`flex-flow:wrap;min-height:${valueSet(sizeY)};`}
                >
                    {value ? (
                        value?.title ? (
                            <Tag
                                onClick={
                                    onDelete && (() => onDelete(value?.value))
                                }
                                rightIcon={<Icon.Closer size={12} />}
                                isDelete={isEdit && value?.isControll}
                                size={32}
                            >
                                {value.title}
                            </Tag>
                        ) : isWrap ? (
                            <>
                                {value?.map(
                                    (
                                        { title, value: id, isControll = true },
                                        key
                                    ) => {
                                        if (key < maxLength)
                                            return (
                                                <Tag
                                                    onClick={() => onDelete(id)}
                                                    rightIcon={
                                                        <Icon.Closer
                                                            size={12}
                                                        />
                                                    }
                                                    isDelete={
                                                        isEdit && isControll
                                                    }
                                                    size={32}
                                                    key={key}
                                                >
                                                    {title}
                                                </Tag>
                                            );
                                    }
                                )}
                                <Box>
                                    {value?.length > maxLength && (
                                        <FormBadgeModal
                                            isEdit={isEdit}
                                            onDelete={onDelete}
                                            maxLength={maxLength}
                                            isModal={isModal}
                                            onToggleModal={onToggleModal}
                                            items={value.filter(
                                                (_, idx) => idx > maxLength
                                            )}
                                        />
                                    )}
                                </Box>
                            </>
                        ) : (
                            <Box gap={12}>
                                {value?.map(
                                    (
                                        { title, value: id, isControll = true },
                                        key
                                    ) => {
                                        if (key < maxLength)
                                            return (
                                                <Tag
                                                    onClick={() => onDelete(id)}
                                                    rightIcon={
                                                        <Icon.Closer
                                                            size={12}
                                                        />
                                                    }
                                                    isDelete={
                                                        isEdit && isControll
                                                    }
                                                    size={32}
                                                    key={key}
                                                >
                                                    {title}
                                                </Tag>
                                            );
                                    }
                                )}
                                {value?.length > maxLength && (
                                    <FormBadgeModal
                                        maxLength={maxLength}
                                        onDelete={onDelete}
                                        isModal={isModal}
                                        onToggleModal={onToggleModal}
                                        items={value.filter(
                                            (_, idx) => idx > maxLength
                                        )}
                                    />
                                )}
                            </Box>
                        )
                    ) : undefined}
                    {isEdit && btn && (
                        <Button
                            Theme="line"
                            onClick={onClick}
                            leftIcon={
                                side === "leftIcon" && <Icon.Search size={12} />
                            }
                            rightIcon={
                                side === "rightIcon" && (
                                    <Icon.Search size={12} />
                                )
                            }
                            disabled={disabled}
                            size={size}
                        >
                            {btnName || "선택하기"}
                        </Button>
                    )}
                </Box>
            </LabelBox>
        </Wrapper>
    );
};

export default FormBadge;
