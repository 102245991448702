import React from "react";
import { forwardRef } from "react";
import styled, { css } from "styled-components";

const InputButton = forwardRef(
    (
        {
            Theme,
            fill,
            paddingSm,
            color,
            onClick,
            disabled,
            className,
            type,
            size,
            loading,
            leftIcon,
            rightIcon,
            center,
            children,
            circle,
            successForm,
            gap,
            isActive,
            accept,
            style,
            ...props
        },
        ref
    ) => {
        const acceptTypes = (() => {
            let text = "";
            const set = (e) => {
                switch (e) {
                    case "xls":
                    case "xlsx":
                    case "excel":
                        return "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    case "jpg":
                    case "png":
                    case "gif":
                    case "jpeg":
                    case "image":
                    case "images":
                    case "img":
                        return "image/*";
                    default:
                        return e ? "." + e : undefined;
                }
            };
            accept?.map((i, n) => {
                text += n ? ", " + set(i) : set(i);
            });
            return text;
        })();

        return (
            <Wrapper
                className={isActive ? `${className} active` : className}
                Theme={Theme}
                full={fill}
                color={color}
                onClick={(e) => {
                    onClick && onClick(e);
                    e.stopPropagation();
                }}
                paddingSm={paddingSm}
                disabled={disabled}
                type={type || "checkbox"}
                circle={circle}
                size={size}
                loading={loading || undefined}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                gap={gap}
                center={center}
                form={successForm}
                styles={style}
            >
                {leftIcon ? <i className="leftIcon">{leftIcon}</i> : ""}
                <span className="tit">{children}</span>
                <input accept={acceptTypes} ref={ref} type="file" {...props} />
                {rightIcon ? <i className="rightIcon">{rightIcon}</i> : ""}
            </Wrapper>
        );
    }
);

const ButtonPropsStyle = css`
    ${({ size, Theme, full, theme: { button } }) => {
        const propsSize = size ? size : "lg";
        const propsTheme = Theme ? Theme : "normal";
        const buttonSize = full
            ? {
                  ...button.size[propsSize],
                  padding: 0,
                  justifyContent: "center",
              }
            : button.size[propsSize];
        const colorFill =
            Theme !== "unLine" &&
            css`
                path {
                    fill: inherit;
                }
            `;
        return css`
            ${colorFill};
            ${buttonSize};
            ${button.theme[propsTheme]};
            gap: ${(props) => (props.gap ? `${props.gap}rem` : "8rem")};
            &:not(:disabled):not(:focus):hover {
                ${button.theme[propsTheme].hover}
            }
            &:active,
            &.active {
                ${button.theme[propsTheme].active}
            }
            &:disabled {
                cursor: no-drop;
                ${button.theme[propsTheme].disabled}
            }
            &:not(:active):focus {
                ${button.theme[propsTheme].focus}
            }
        `;
    }}
`;

const Wrapper = styled.label`
    ${({ center, full, circle, styles }) => `
    display: flex;
    align-items: center;
    ${center ? "justify-content: center;" : ""}
    border: 1px solid transparent;
    font-weight: 400;
    width: ${full ? "100%" : "auto"};
    width: ${full ? "100%" : "auto"};
    border-radius:${circle ? "100%" : "6rem"};
    flex: ${full ? "0 1 auto" : "0 0 auto;"};
    ${styles || ""}
    input {
        height: 0;
        width: 0;
        opacity: 0;
        display:none;
    }
    .tit {
        display: inline-block;
        width: 100%;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit;
    }
    i {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        svg {
            fill: currentcolor;
            line-height: 1;
        }
    }
`}
    ${ButtonPropsStyle}
`;

InputButton.defaultProps = {
    Theme: false,
    fill: false,
    color: null,
    onClick: () => {},
    disabled: false,
    className: null,
    type: "checkbox",
    loading: false,
    leftIcon: false,
    rightIcon: false,
};
export default InputButton;
