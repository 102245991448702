import { Box } from 'Common/UI/atoms';
import RankLogGraph from 'Page/Product/RankLogGraph';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const SlotRankGraph = ({ id, nvmid }) => {
  const [rankNvmid, setRankNvmid] = useState(nvmid);
  const [rankId, setRankId] = useState(id);
  const { check } = useParams();
  useEffect(() => {
    const url = new URL(window.location.href);
    const id_value = url.searchParams.get('id')?.trim();
    const nvmid_value = url.searchParams.get('nvmid')?.trim();
    if (id_value) setRankId(id_value);
    if (nvmid_value) setRankNvmid(nvmid_value);
    if (check) {
      if (check.length >= 6) {
        setRankNvmid(check);
      } else {
        setRankId(check);
      }
    }
  }, [check]);
  if (!rankId && !rankNvmid) return;
  return (
    <Block>
      <Box column>
        <Box>
          <TitleText>랭킹 리스트</TitleText>
        </Box>
        <Box top={10} width={1000} height={500}>
          <RankLogGraph id={rankId} nvmid={rankNvmid} showInfoChk />
        </Box>
      </Box>
    </Block>
  );
};
const Block = styled.div`
  margin-top: 30rem;
`;
const TitleText = styled.div`
  font-size: 20rem;
  margin: 10rem;
  font-weight: 800;
  color: red;
`;
export default SlotRankGraph;
