import React, { forwardRef, useEffect } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { Box, Icon, Title } from '.';

const TabMenu = forwardRef(
  (
    {
      onClose,
      outClose = false,
      position = 'fixed',
      motionPosition = 'right',
      children,
      padding = [0, 0],
      isActive,
      zIndex = isActive ? 51 : 50,
      width = 100,
      style,
      height = 50,
      gap = { top: 80, right: 20 },
      transition,
      title,
      subTit,
    },
    ref,
  ) => {
    const propsSet = (target) => {
      const type = typeof target;
      const array = Array.isArray(target);
      let style = '';
      array
        ? target.map((i) => {
            return (style += typeof i === 'number' ? i + 'rem ' : i + ' ');
          })
        : (style += type === 'number' ? `${target}rem` : target);
      return style;
    };
    const settingMotion = (type) => {
      const set = typeof height;
      const isCalc = Array.isArray(set);
      const setX = gap.left === undefined ? gap.right : gap.left;
      const setY = gap.top === undefined ? gap.bottom : gap.top;
      let calcY = '';
      isCalc && height?.map((i, n) => (calcY += n ? propsSet(i) : ' - ' + propsSet(i)));
      const positionCheck = position === 'fixed' || position === 'absolute';
      switch (type) {
        case 'left':
          return `
                        ${
                          gap.top !== undefined
                            ? `top:${propsSet(gap.top)};`
                            : gap.bottom !== undefined
                            ? `bottom:${propsSet(gap.bottom)};`
                            : ''
                        }
                        left:${
                          positionCheck && !isActive ? `-${width + setX + 50}rem` : propsSet(setX)
                        };`;

        case 'right':
          return `
                        ${
                          gap.top !== undefined
                            ? `top:${propsSet(gap.top)};`
                            : gap.bottom !== undefined
                            ? `bottom:${propsSet(gap.bottom)};`
                            : ''
                        }
                        right:${
                          positionCheck && !isActive ? `-${width + setX + 50}rem` : propsSet(setX)
                        };`;

        case 'top':
          return `
                        ${
                          gap.left !== undefined
                            ? `left:${propsSet(gap.left)};`
                            : gap.right !== undefined
                            ? `right:${propsSet(gap.right)};`
                            : ''
                        }
                        top:${
                          positionCheck && !isActive
                            ? isCalc
                              ? `calc(-${calcY})`
                              : `-${height + setY + 50}rem`
                            : propsSet(setY)
                        };`;

        case 'bottom':
          return `
                        ${
                          gap.left !== undefined
                            ? `left:${propsSet(gap.left)};`
                            : gap.right !== undefined
                            ? `right:${propsSet(gap.right)};`
                            : ''
                        }
                        bottom:${
                          positionCheck && !isActive ? `-${height + setY + 50}rem` : propsSet(setY)
                        }; `;
        default:
          break;
      }
    };
    const tabmenu = useRef(ref);

    const clickTarget = (e) => {
      if (!isActive) return;
      const targetSearchFilter = tabmenu?.current?.contains(e.target);
      targetSearchFilter || (onClose && onClose());
      targetSearchFilter || controller.mouseEventClose();
    };
    const controller = {
      mouseEvent: () => document.addEventListener('click', clickTarget, true),
      mouseEventClose: () => document.removeEventListener('click', clickTarget, true),
    };

    useEffect(() => {
      outClose && isActive && controller.mouseEvent();
      return () => {
        controller.mouseEventClose();
      };
    }, [isActive]);

    return (
      <Wrapper
        leftClose={motionPosition === 'right'}
        motionPosition={motionPosition}
        position={position}
        ref={ref || tabmenu}
        pd={propsSet(padding)}
        styles={style}
        effect={transition}
        zIndex={zIndex}
        motion={settingMotion(motionPosition)}
        sizeX={propsSet(width)}
        sizeY={propsSet(height)}
        isActive={isActive}
      >
        {title && (
          <Box bottom={28} gap={8} column>
            <Title size={'xs'} color={'G_900'}>
              {title}
            </Title>
            {subTit && (
              <Title weight={400} size={'xxxs'} color={'G_500'}>
                {subTit}
              </Title>
            )}
          </Box>
        )}
        {children}
        {motionPosition === 'right' && (
          <button onClick={onClose ? () => onClose() : false} className="closeBtn">
            <Icon.Arrow direction="right" size={24} />
          </button>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.aside`
  ${({
    motionPosition,
    zIndex,
    position,
    sizeX,
    sizeY,
    styles,
    motion,
    effect,
    isActive,
    leftClose,
    pd,
  }) => `
    width:${sizeX};
    height:${sizeY};
    position:${position};
    display:flex;
    flex-direction:column;
    box-shadow:var(--Elevation_007);
    padding:${pd};
    background-color:var(--White);
    border-radius:12rem;
    z-index:${zIndex};
    transition: ${
      effect ||
      `${motionPosition} 0.7s cubic-bezier${
        isActive ? '(0.250, 0.250, 0.50, 1.300)' : '(0.50, -0.3, 0.750, 0.750)'
      }`
    },z-index 0s;
    ${styles || ''}
    ${motion}
    h3{
        font-size:20rem;
        font-weight:500;
    }
    ${
      leftClose
        ? `
    &::after{
        content:'';
        position:absolute;
        width:28rem;
        height:80%;
        left:0;
        top:10%;
        background-color:var(--White);
    }
    `
        : ''
    }
    .closeBtn{
        position:absolute;
        height:60rem;
        display:flex;
        align-items:center;
        right:100%;
        top:calc(50% - 30rem);
        background-color:var(--White);
        box-shadow:var(--Elevation_007);
        border-radius:8rem 0 0 8rem;
    }
`}
`;
const HeadTitle = styled.div`
  width: 100%;
  height: 34rem;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 20rem;
  line-height: 172%;
  letter-spacing: -0.03em;
  color: var(--G_900);
  .back {
    display: flex;
    align-items: center;
  }
`;

export default TabMenu;
