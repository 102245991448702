import useTab from 'Common/Hooks/useTab';
import { Box, Grid } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  requestLoadWorkBasicUrlTypeGetBrowserTypes,
  requestLoadWorkSbthGetInfos,
} from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import { currencyComma } from 'Common/Utils';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const WorkGetSbth = () => {
  const location = useLocation();

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const [sbthInfos, setSbthInfos] = useState();
  const [count, setCount] = useState(reload_second);

  const getGetSbthInfo = async () => {
    const sbth_infos = await requestLoadWorkSbthGetInfos({});
    setSbthInfos(sbth_infos);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getGetSbthInfo();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getGetSbthInfo();
  }, []);
  return (
    <>
      <Box>do_sbth</Box>
      <Box column gap={24}>
        <Box>
          <Box>수집</Box>
          <Box>
            {sbthInfos?.use_count} [lately: {sbthInfos?.info?.datetime}{' '}
            {sbthInfos?.info?.datetime_ago_hour} 시간 전] <TextSmall>{count}</TextSmall>
          </Box>
        </Box>
        <Box column>
          <Box>수집 기록</Box>
          {sbthInfos?.list?.map((e) => {
            return (
              <Box>
                <Box>{e?.date}</Box>
                <Box>
                  {e?.count === 86400 ? (
                    <>
                      <TextSucceed>{currencyComma(e?.count)}</TextSucceed>
                    </>
                  ) : e?.count < 86400 ? (
                    <>
                      <TextWarning>{currencyComma(e?.count)}</TextWarning>
                    </>
                  ) : (
                    <>
                      <TextFail>{currencyComma(e?.count)}</TextFail>
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
export default WorkGetSbth;
