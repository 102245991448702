export const default_counts_fields = [
  'error_count',
  'worker_do_count',
  'worker_re_do_count',
  'worker_do_sec',
  'shop_click_count',
  'shop_click_sec',
  'ip_change_count',
  'ip_no_use_change_count',
  'ip_change_sec',
  'naver_login_count',
  'naver_active_count',
  'naver_active_sec',
  'browser_active_count',
  'browser_active_sec',
  'naver_block_count',
];
export const default_vmwares_fields = [
  'force_do_type',
  'force_do_type_shop_click_worker_login_naver_id_type',
  'allow_shop_click_chk',
  'force_click_stop_chk',
  'stop_chk',
  // 'use1',
  // 'lately_access_status',
  'error_count',
  'worker_do_count',
  'worker_re_do_count',
  'worker_do_sec',
  'shop_click_count',
  'shop_click_sec',
  'ip_change_count',
  'ip_no_use_change_count',
  'ip_change_sec',
  'naver_login_count',
  'naver_active_count',
  'naver_active_sec',
  'browser_active_count',
  'browser_active_sec',
  'naver_block_count',
  'worker_login_type',
  'worker_login_country',
  'worker_network_type',
  'worker_network_type_mobile_chk',
  'worker_network_adapter',
  'naver_active_success_count',
  'naver_active_fail_count',

  'worker_login_type_proxy_chk',
  'worker_login_type_proxy_port',

  'vmware_type',
  'vmware_workstation_run_multi_mode_chk',
  // 'vmware_setting_idle_sec_max',
  'vmwaer_setting_reboot_auto_program_start_chk',
  'vmware_setting_fiddler_auto_check_restart_chk',
  'vmware_setting_reboot_auto_mode_chk',
  'vmware_setting_reboot_sec_max',
  'vmware_setting_reboot_start_fiddler_chk',
  'vmware_setting_fiddler_auto_check_restart_chk',

  'vmware_setting_auto_start_program_chk',
  'vmware_setting_auto_start_idle_sec_max',
  'worker_login_network_socket_mode_chk',

  'vmware_setting_lan_default_network_adapter_mac_address',
  'vmware_setting_lan_default_ip_address',

  'phone_num',
  'phone',
  'owner_name',
  'network_speed',
  'etc_status',
  'router_ip',
  'network_price',
  'partner_owner_name',
  'owner_name',
  'id',
  // 'expire_date',
];
export const default_info_fields = [
  'default_click_active_time_hour0_0',
  'default_click_active_time_hour0_30',
  'default_click_active_time_hour1_0',
  'default_click_active_time_hour1_30',
  'default_click_active_time_hour2_0',
  'default_click_active_time_hour2_30',
  'default_click_active_time_hour3_0',
  'default_click_active_time_hour3_30',
  'default_click_active_time_hour4_0',
  'default_click_active_time_hour4_30',
  'default_click_active_time_hour5_0',
  'default_click_active_time_hour5_30',
  'default_click_active_time_hour6_0',
  'default_click_active_time_hour6_30',
  'default_click_active_time_hour7_0',
  'default_click_active_time_hour7_30',
  'default_click_active_time_hour8_0',
  'default_click_active_time_hour8_30',
  'default_click_active_time_hour9_0',
  'default_click_active_time_hour9_30',
  'default_click_active_time_hour10_0',
  'default_click_active_time_hour10_30',
  'default_click_active_time_hour11_0',
  'default_click_active_time_hour11_30',
  'default_click_active_time_hour12_0',
  'default_click_active_time_hour12_30',
  'default_click_active_time_hour13_0',
  'default_click_active_time_hour13_30',
  'default_click_active_time_hour14_0',
  'default_click_active_time_hour14_30',
  'default_click_active_time_hour15_0',
  'default_click_active_time_hour15_30',
  'default_click_active_time_hour16_0',
  'default_click_active_time_hour16_30',
  'default_click_active_time_hour17_0',
  'default_click_active_time_hour17_30',
  'default_click_active_time_hour18_0',
  'default_click_active_time_hour18_30',
  'default_click_active_time_hour19_0',
  'default_click_active_time_hour19_30',
  'default_click_active_time_hour20_0',
  'default_click_active_time_hour20_30',
  'default_click_active_time_hour21_0',
  'default_click_active_time_hour21_30',
  'default_click_active_time_hour22_0',
  'default_click_active_time_hour22_30',
  'default_click_active_time_hour23_0',
  'default_click_active_time_hour23_30',
  // #아래는 중지 상태에서도 임시로 클릭 허용할때 사용함
  'all_force_test_click_allow_chk',
  'all_force_test_click_allow_vmware_names',

  'default_vmware_idle_sec_max',
  'default_vmware_reboot_sec_max',
  'use_vmware_browser_mode_stop_chk',
  'use_vmware_network_mode_stop_chk',
  'use_vmware_korea_country_mode_stop_chk',
  'use_vmware_other_country_mode_stop_chk',

  // #전체 강제 중지중
  'all_force_click_stop_chk',
  // 같은 쇼핑몰에 다시 클릭 가능한 딜레이초
  'default_click_mall_delay_second',
  // #같은 상품 클릭후에 다시 클릭 가능한 딜레이 초
  'default_click_product_delay_second',
  // #딜레이 초에 +- 오차율 강제 만드는 퍼센트
  'default_click_product_delay_second_percent',

  // #네이버 몇회 활동후에 유효클릭 가능할지
  'default_allow_shop_click_need_naver_active',
  // #네이버 엑티브후에 클릭 시킬때까지 딜레이 시간
  'default_delay_hour_after_naver_active',
  // #하루 할당 클릭 카운터
  'default_naver_id_day_per_click_count',
  // #접속당 몇회 클릭할지
  'default_naver_id_day_step_per_click_count',
  // #활동후에 몇시간 쉴지
  'default_naver_id_per_click_delay_hour',
  // #다음 접속시까지 딜레이시간
  'default_naver_id_per_step_delay_hour',

  // #몇회 클릭후 장기 휴식 들어갈지
  'default_naver_id_per_long_term_click_count',
  // #n회 클릭후 장기 휴식 딜레이 시간
  'default_naver_id_per_long_term_delay_hour',

  // #몇시간 이내에 n 시간동안 아이피 클릭 들어갔을때 아이피 휴식할지
  'default_ip_address_per_long_term_click_count',
  // #몇시간동안 n회 클릭 했다면 사용 안함
  // #클릭 허용 카운터가 10 회 이상일때만 처리함
  'default_ip_address_per_long_term_in_check_hour',
  //같은 아이디 같은 상품 다시 클릭하는 가능하는 주기 시간
  'default_same_product_click_lock_hour',
  'default_same_mall_name_allow_click_count',
  'default_same_mall_name_click_lock_hour',
  'default_active_browser_click_after_active_count',
  'use_worker_login_types',
  'use_worker_login_countries',
  'use_worker_network_types',

  'use_shop_click_worker_login_types',
  'use_shop_click_worker_login_countries',
  'use_shop_click_worker_network_types',
];
