import { Box, Button, CheckBox, Text } from 'Common/UI/atoms';
import { Table, Tbody, THeader } from 'Common/UI/organisms/FormTabel/styeld';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import { formatDate } from 'Common/Utils/date';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import useMutations from 'Common/Service/commonMutations';
import { currencyComma } from 'Common/Utils';

function AnalyticsRealtimeProducts({
  selectedId,
  onReset,
  onChangeInterval,
  pages,
  state = ['1'],
}) {
  const navigate = useNavigate();
  const setDate = (date) => formatDate(new Date(date)).completeDate;
  const [vmwareStatusData, setVmwareStatusData] = useState([]);
  const [result, setResults] = useState({});
  const [allCountData, setAllCountData] = useState({});
  const [nowHourN, setNowHourN] = useState(new Date().getHours());
  const [allowShopLinkClickInfo, setAllowShopLinkClickInfo] = useState({});
  const {
    requestLoadShopLinkCountGetDataApiInfoMutation,
    requestLoadVmwareSetApiInfoMutation,
    requestLoadCountGetApiInfoMutation,
    requestLoadVmwareGetApiInfoMutation,
    requestLoadVmwareGetApiRealtimeCountMutation,
    requestLoadShopLinkGetApiDataInfoMutation,
    requestLoadInfoSetApiInfoMutation,
    requestLoadInfoGetApiInfoMutation,
    requestLoadDashboardGetDataInfoMutation,
  } = useMutations(setResults);
  const fields = [
    'force_do_type',
    'allow_shop_click_chk',
    'force_click_stop_chk',
    // 'use1',
    // 'lately_access_status',
    'error_count',
    'worker_do_count',
    'worker_re_do_count',
    'worker_do_sec',
    'shop_click_count',
    'shop_click_sec',
    'ip_change_count',
    'ip_no_use_change_count',
    'ip_change_sec',
    'naver_login_count',
    'naver_active_count',
    'naver_active_sec',
    'browser_active_count',
    'browser_active_sec',
    'naver_block_count',
  ];
  const count_fields = [
    'error_count',
    'worker_do_count',
    'worker_re_do_count',
    'worker_do_sec',
    'shop_click_count',
    'shop_click_sec',
    'ip_change_count',
    'ip_no_use_change_count',
    'ip_change_sec',
    'naver_login_count',
    'naver_active_count',
    'naver_active_sec',
    'browser_active_count',
    'browser_active_sec',
    'naver_block_count',
  ];
  const get_hour_fields = (fields) => {
    // const hour_n = new Date().getHours();
    return fields.map((field) => {
      return `${field}_hour${nowHourN}`;
    });
  };
  const get_all_hour_fields = (fields) => {
    const list = Array.from({ length: 24 }, (_, i) => i);

    return fields
      .map((field) => {
        return list.map((hour_n) => {
          return `${field}_hour${hour_n}`;
        });
      })
      .reduce((acc, cur) => acc.concat(cur), []);
  };

  const list = [
    {
      vmware_name: '오늘',
      ip_change_count: 0,
      ip_no_use_change_count: 0,
      ip_change_sec: 0,
      naver_login_count: 0,
      shop_click_count: 0,

      worker_do_count: 0,
      worker_re_do_count: 0,
      worker_do_sec: 0,

      active_count: 0,
      error_count: 0,
      active_sec: 0,
    },
  ];

  useEffect(() => {
    const all_vmware_fields = [...fields, ...get_hour_fields(count_fields)];
    requestLoadVmwareGetApiInfoMutation.mutate({
      fields: all_vmware_fields,
    });
    const all_fields = [...count_fields, ...get_all_hour_fields(count_fields)];
    requestLoadCountGetApiInfoMutation.mutate({ fields: all_fields });
  }, []);
  useEffect(() => {
    setNowHourN(new Date().getHours());
    if (result?.request === 'requestLoadShopLinkGetApiDataInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        setAllowShopLinkClickInfo(result?.result?.realtime_click_info);
      }
    }
    if (result?.request === 'requestLoadCountGetApiInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        setAllCountData(result?.result);
      }
      setTimeout(() => {
        const all_vmware_fields = [...fields, ...get_hour_fields(count_fields)];
        requestLoadVmwareGetApiInfoMutation.mutate({
          fields: all_vmware_fields,
        });
      }, 2000);
      setTimeout(() => {
        requestLoadShopLinkGetApiDataInfoMutation.mutate();
      }, 4000);
      setTimeout(() => {
        const all_fields = [...count_fields, ...get_all_hour_fields(count_fields)];
        requestLoadCountGetApiInfoMutation.mutate({ fields: all_fields });
      }, 6000);
    }
    if (result?.request === 'requestLoadVmwareGetApiInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        setVmwareStatusData(result?.result?.data);
      }
    }
    if (result?.request === 'requestLoadVmwareSetApiInfoMutation') {
      if (result?.success && result?.result !== undefined) {
        requestLoadVmwareGetApiInfoMutation.mutate({ fields });
      }
    }

    setResults({});
  }, [result.request]);

  return (
    <Box column>
      <TableComponent
        onSearch={null}
        isController={false}
        allCheckbox={false}
        isInterval={false}
        searchText={null}
        checkList={selectedId}
        resetChecked={onReset}
        onChangeInterval={onChangeInterval}
        pages={pages}
      >
        <Table>
          <colgroup>
            <col style={{ width: '60rem' }} />
            {Array.from(Array(12)).map((_, i) => (
              <col style={{ width: 'auto' }} />
            ))}
          </colgroup>
          <THeader>
            <tr>
              <th rowSpan={'2'}></th>
              {Array.from(Array(12)).map((_, i) => (
                <th rowSpan={'1'}>{i}시</th>
              ))}
            </tr>
            <tr>
              {Array.from(Array(12)).map((_, i) => (
                <th rowSpan={'1'}>{i + 12}시</th>
              ))}
            </tr>
          </THeader>
          <Tbody>
            {list?.length === 0 ? (
              <tr>
                <td colSpan="25">등록된 정보가 없어요</td>
              </tr>
            ) : (
              <>
                <React.Fragment>
                  <tr>
                    <td rowSpan={'3'}>오늘</td>

                    {Array.from(Array(12)).map((_, hour_n) => {
                      const shop_click_count = allCountData?.[`shop_click_count_hour${hour_n}`];
                      const shop_click_sec = allCountData?.[`shop_click_sec_hour${hour_n}`];
                      const ip_change_count = allCountData?.[`ip_change_count_hour${hour_n}`];
                      const ip_change_sec = allCountData?.[`ip_change_sec_hour${hour_n}`];
                      const naver_login_count = allCountData?.[`naver_login_count_hour${hour_n}`];
                      const naver_login_sec = allCountData?.[`naver_login_sec_hour${hour_n}`];
                      const naver_active_count = allCountData?.[`naver_active_count_hour${hour_n}`];
                      const naver_active_sec = parseInt(
                        allCountData?.[`naver_active_sec_hour${hour_n}`],
                      );
                      if (hour_n > nowHourN) return <td rowSpan={'1'}></td>;
                      return (
                        <td rowSpan={'1'}>
                          <StatusText active={nowHourN === hour_n}>
                            유효클릭: <span>{currencyComma(shop_click_count)}</span>
                            <small>
                              {currencyComma(Math.round(shop_click_sec / shop_click_count))} s
                            </small>
                            <br />
                            아이피변환: <span>{ip_change_count}</span>
                            <small>
                              {currencyComma(Math.round(ip_change_sec / ip_change_count))} s
                            </small>
                            <br />
                            네이버로그인: <span>{currencyComma(naver_login_count)}</span>
                            <small>
                              {currencyComma(Math.round(naver_login_sec / naver_login_count))} s
                            </small>
                            <br />
                            활동: <span>{currencyComma(naver_active_count)}</span>
                            <small>
                              {currencyComma(Math.round(naver_active_sec / naver_active_count))} s
                            </small>
                          </StatusText>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    {Array.from(Array(12)).map((_, i) => {
                      const hour_n = i + 12;
                      const shop_click_count = allCountData?.[`shop_click_count_hour${hour_n}`];
                      const shop_click_sec = allCountData?.[`shop_click_sec_hour${hour_n}`];
                      const ip_change_count = allCountData?.[`ip_change_count_hour${hour_n}`];
                      const ip_change_sec = allCountData?.[`ip_change_sec_hour${hour_n}`];
                      const naver_login_count = allCountData?.[`naver_login_count_hour${hour_n}`];
                      const naver_login_sec = allCountData?.[`naver_login_sec_hour${hour_n}`];
                      const naver_active_count = allCountData?.[`naver_active_count_hour${hour_n}`];
                      const naver_active_sec = parseInt(
                        allCountData?.[`naver_active_sec_hour${hour_n}`],
                      );
                      if (hour_n > nowHourN) return <td rowSpan={'1'}></td>;

                      return (
                        <td rowSpan={'1'}>
                          <StatusText active={nowHourN === hour_n}>
                            유효클릭: <span>{currencyComma(shop_click_count)}</span>
                            <small>
                              {currencyComma(Math.round(shop_click_sec / shop_click_count))} s
                            </small>
                            <br />
                            아이피변환: <span>{ip_change_count}</span>
                            <small>
                              {currencyComma(Math.round(ip_change_sec / ip_change_count))} s
                            </small>
                            <br />
                            네이버로그인: <span>{currencyComma(naver_login_count)}</span>
                            <small>
                              {currencyComma(Math.round(naver_login_sec / naver_login_count))} s
                            </small>
                            <br />
                            활동: <span>{currencyComma(naver_active_count)}</span>
                            <small>
                              {currencyComma(Math.round(naver_active_sec / naver_active_count))} s
                            </small>
                          </StatusText>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td colSpan={'12'}>
                      <LineStatusText>
                        유효클릭가능한상품 현황: {allowShopLinkClickInfo?.allow_use_shop_link_count}{' '}
                        / {allowShopLinkClickInfo?.allow_use_shop_link_count_max}, 총 유효클릭{' '}
                        {allCountData?.shop_click_count}, 아이피 변환:{' '}
                        {allCountData?.ip_change_count}, 네이버 로그인:{' '}
                        {allCountData?.ip_change_count}, 네이버 활동:{' '}
                        {allCountData?.naver_active_count}, 브라우저 활동:{' '}
                        {allCountData?.browser_active_count}, 첫 클릭 완료 ID:{' '}
                        {allCountData?.worker_do_count}, 클릭2회 완료 ID:{' '}
                        {allCountData?.worker_re_do_count}, 네이버 제제 ID:{' '}
                        {allCountData?.naver_block_count}
                      </LineStatusText>
                    </td>
                  </tr>
                </React.Fragment>
              </>
            )}
          </Tbody>
        </Table>
      </TableComponent>

      <TableComponent
        onSearch={null}
        isController={false}
        allCheckbox={false}
        isInterval={false}
        searchText={null}
        checkList={selectedId}
        resetChecked={onReset}
        onChangeInterval={onChangeInterval}
        pages={pages}
      >
        <Table>
          <colgroup>
            <col style={{ width: '80rem' }} />
            {Array.from(Array(12)).map((_, i) => (
              <col style={{ width: 'auto' }} />
            ))}
          </colgroup>
          <THeader>
            <tr>
              <th rowSpan={'1'}>오늘</th>
              <th rowSpan={'2'}>유효클릭</th>
              <th rowSpan={'2'}>아이피변환</th>
              <th rowSpan={'2'}>네이버로그인</th>
              <th rowSpan={'2'}>활동</th>
              <th rowSpan={'2'}>브라우저활동</th>
              <th rowSpan={'2'}>네이버제제</th>
              <th rowSpan={'2'}>오류</th>
              <th rowSpan={'2'} colspan={5}>
                상황
              </th>
            </tr>
            <tr>
              <th rowSpan={'1'}>{nowHourN}시</th>
            </tr>
          </THeader>
          <Tbody>
            {vmwareStatusData?.length === 0 ? (
              <tr>
                <td colSpan="12">정보를 로딩중입니다.</td>
              </tr>
            ) : (
              <>
                {vmwareStatusData?.map((i, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <tr>
                        <td rowSpan={'2'}>
                          <Text>
                            {i.workstation_name}
                            <br />
                            {i.vmware_name}
                            <br />
                            {i.status}
                          </Text>
                        </td>
                        <td rowSpan={'1'}>{i.shop_click_count}</td>
                        <td rowSpan={'1'}>{i.ip_change_count}</td>
                        <td rowSpan={'1'}>{i.naver_login_count}</td>
                        <td rowSpan={'1'}>{i.naver_active_count}</td>
                        <td rowSpan={'1'}>{i.browser_active_count}</td>
                        <td rowSpan={'1'}>{i.naver_block_count}</td>
                        <td rowSpan={'1'}>{i.error_count}</td>
                        <td rowSpan={'1'} colSpan={'5'}>
                          <StatusBlock>
                            <Box>
                              [{i.force_do_type}] {i.force_click_shop_chk}
                              {/* 유효클릭여부
                              네이버활동,유효클릭,네이버활동,브라우저활동 */}
                            </Box>
                          </StatusBlock>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={'1'}>
                          <NowStatusText>{i[`shop_click_count_hour${nowHourN}`]}</NowStatusText>
                        </td>
                        <td rowSpan={'1'}>
                          <NowStatusText>{i[`ip_change_count_hour${nowHourN}`]}</NowStatusText>
                        </td>
                        <td rowSpan={'1'}>
                          <NowStatusText>{i[`naver_login_count_hour${nowHourN}`]}</NowStatusText>
                        </td>
                        <td rowSpan={'1'}>
                          <NowStatusText>{i[`naver_active_count_hour${nowHourN}`]}</NowStatusText>
                        </td>
                        <td rowSpan={'1'}>
                          <NowStatusText>{i[`browser_active_count_hour${nowHourN}`]}</NowStatusText>
                        </td>
                        <td rowSpan={'1'}>
                          <NowStatusText>{i[`naver_block_count_hour${nowHourN}`]}</NowStatusText>
                        </td>
                        <td rowSpan={'1'}>
                          <NowStatusText>{i[`error_count_hour${nowHourN}`]}</NowStatusText>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Tbody>
        </Table>
      </TableComponent>
    </Box>
  );
}
const StatusBlock = styled.div`
  width: 100%;
  float: left;
`;
const StatusText = styled.div`
  text-align: left;
  font-size: 12rem;
  display: inline-block;
  gap: 12rem;
  width: 100%;
  justify-content: space-between;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    padding-left: 2rem;
    font-size: 10rem;
    line-height: 14rem;
    /* padding: 8rem 16rem; */
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return `
        border-width: 1rem;
        border-style: solid;
        border-color: var(--P_300); 
        background-color: #9cd0e7;
            
        `;
  }}
`;
const NowStatusText = styled.div`
  color: blue;
`;
const LineStatusText = styled.div`
  font-size: 15rem;
  /* background-color: red; */
  /* display: flex; */
  align-items: left;
  text-align: left;
  gap: 12rem;
  justify-content: space-between;
  small {
    font-size: 12rem;
    line-height: 18rem;
    /* padding: 8rem 16rem; */
    color: #7876f9;
  }
`;
export default AnalyticsRealtimeProducts;
