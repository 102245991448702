import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Box, Icon, Button } from '.';
import InputComponent from './Input/InputComponent';

const DropList = ({
  isActive,
  option = [],
  onClick,
  onClose,
  align = 'left',
  textAlign = 'left',
  isAdd,
  name,
  className,
  position = 'bottom',
  //추가  20220928
  isOnAdd = false,
  onAddText,
  onChangeText,
  isClose = true,
  //추가 20221018
  editOption,
  isKeyControll,
  onEdit,
  onDeleteEdit,
  isHover,
  parent,
  readOnly,
}) => {
  const [list, setList] = useState([]);
  const [toggle, setToggle] = useState(isActive);
  const [onAdd, setOnAdd] = useState(isOnAdd); //수정 20220928
  const [addData, setAddData] = useState();
  const [editData, setEditData] = useState();
  const [keyFocus, setKeyFocus] = useState(null);
  const keyNum = useRef(keyFocus);
  const optionNum = useRef(0);
  const ref = useRef();
  const active = useRef(isActive);
  const closeSet = (e) => {
    const isClick = ref?.current?.contains(e?.target);
    isClick || (onClose && onClose());
    isClick || controller.removeClick();
    controller.endKeyPress();
  };

  const onKeyPress = useCallback((e) => {
    if (!active.current) return controller.endKeyPress();
    const number = keyNum.current;
    const option = optionNum.current;
    const enter = e.keyCode === 13;
    if (enter && number !== null) {
      const { title, value } = option?.filter((_, key) => key === number)[0];
      onClick && onClick({ name, title, value });
      onClose();
      return controller.endKeyPress();
    }
    const keyUp = e.keyCode === 38; // ↑
    const keyDown = e.keyCode === 40; // ↓
    const length = option?.length;
    const num = keyUp
      ? !number
        ? length - 1
        : number - 1
      : keyDown
      ? number === length - 1
        ? 0
        : number === null
        ? 0
        : number + 1
      : number;

    keyNum.current = num;
    setKeyFocus(num);
  });
  useEffect(() => {
    keyNum.current = null;
    optionNum.current = option;
    setKeyFocus(null);
  }, [option]);

  const controller = {
    click: () => document.addEventListener('click', closeSet, true),
    removeClick: () => document.removeEventListener('click', closeSet, true),
    startKeyPress: () => document.addEventListener('keydown', onKeyPress, true),
    endKeyPress: () => document.removeEventListener('keydown', onKeyPress, true),
  };

  const getData = (title, value, optionClick) => {
    isClose && setToggle(false);
    optionClick && optionClick();
    onClick && onClick({ name, title, value });
    if (onClose) onClose();

    return () => controller.endKeyPress();
  };

  useEffect(() => {
    active.current = isActive;
    setToggle(isActive);
    isActive && !isHover && controller.click();
    if (isActive && isKeyControll) controller?.startKeyPress();
  }, [isActive]);

  useEffect(() => {
    editOption //추가 221019
      ? setList([
          ...option?.map((i) => i && (i.basic !== undefined ? { ...i } : { ...i, basic: true })), //20221028 수정
        ])
      : setList([...option?.map((i) => i && { ...i, basic: true })]); //기존코드
  }, [option]);

  useEffect(() => {
    isClose && setToggle(false);
  }, [isClose]);

  const add = () => {
    const value = addData?.target?.value;
    const filter = [{ title: value, value: value }, ...list];
    setList([...filter]);
    setOnAdd(isOnAdd); // 수정 20220928
    setEditData(undefined);
    onAddText && onAddText(value); //추가 20220928
  };

  const edit = (e) => {
    const value = e.target.value;
    const filter = { ...editData, value: value };
    setEditData({ ...filter });
  };

  const submitModify = () => {
    const value = editData.value;
    const filter = list.map((i, n) =>
      n === editData.idx ? { ...i, title: value, value } : { ...i },
    );
    onChangeText && onChangeText(value, editData?.idx);
    onEdit && onEdit(value, editData.idx); //추가 20221018
    setList([...filter]);
    setEditData(undefined);
  };

  const editDelete = (key) => {
    const filter = [...list.filter((_, n) => n !== key)];
    setList([...filter]);

    onDeleteEdit && onDeleteEdit(key); //추가 20221019
  };

  useEffect(() => {
    setEditData();
    setOnAdd(false);
  }, [isActive]);
  useEffect(() => {
    setAddData({ target: { value: '' } });
  }, [onAdd]);

  return (
    <Wrapper
      className={className ? `${className} dropList` : 'dropList'}
      align={align}
      position={position}
      ref={ref}
      isActive={toggle}
      onClick={(e) => e.stopPropagation()}
      textAlign={textAlign}
    >
      <Box height={'100%'} style={`overflow-y:scroll;`} column>
        <Box height={'max-content'} column>
          {isAdd ? (
            onAdd ? (
              <Box left={20} right={20} gap={8}>
                <InputComponent
                  underLine
                  name="addOption"
                  value={addData?.target?.value} // 추가 20220928
                  onChange={(e) => setAddData(e)}
                />
                <Button size={'sm'} Theme="line" onClick={add} disabled={!addData?.target?.value}>
                  추가
                </Button>
              </Box>
            ) : (
              <button onClick={() => setOnAdd(true)} className="addBtn">
                <Icon.PM fill size={28} />
                추가하기
              </button>
            )
          ) : undefined}
          {list.map(({ title, value, selected, disabled, onClick, isAdd }, key) =>
            editData?.idx === key && isAdd ? (
              <Box key={key} left={20} right={20} gap={8}>
                <InputComponent
                  underLine
                  name="modify"
                  value={editData?.value}
                  onChange={(e) => edit(e)}
                />
                <Button
                  size={'sm'}
                  Theme="line"
                  onClick={submitModify}
                  disabled={!addData?.target?.value && !onEdit && !onChangeText} //20221026 수정 &&!onEdit 추가
                >
                  변경
                </Button>
              </Box>
            ) : (
              <button
                className={`${selected ? 'selected ' : ''}${key === keyFocus ? 'focus ' : ''}`}
                key={key}
                onClick={() => getData(title, value, onClick)}
                disabled={disabled}
              >
                <p>{title}</p>
                {isAdd && (
                  <Box
                    gap={8}
                    style={`
                                        >button{
                                        color: var(--G_700);
                                        &:hover{color:var(--P_500);}
                                    }`}
                  >
                    <button
                      onClick={(e) => {
                        setEditData({
                          idx: key,
                          value: title,
                        });
                        e.stopPropagation();
                      }}
                    >
                      <Icon.Edit fill size={24} />
                    </button>
                    <button
                      onClick={(e) => {
                        editDelete(key);
                        e.stopPropagation();
                      }}
                    >
                      {/* &#93; */}
                      <Icon.Closer fill size={24} />
                    </button>
                  </Box>
                )}
              </button>
            ),
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ isActive, align, textAlign, position }) => `
    position: absolute;
    display:${isActive ? 'flex' : 'none'};
    flex-direction:column;
    box-shadow: var(--Elevation_004);
    background-color:var(--White);
    padding: 8rem 0;
    width: 100%;
    ${align}:0;
    ${position === 'bottom' ? 'top' : 'bottom'}:calc(100% + 8rem);
    // max-height: 532rem;
    border-radius: 8rem;
    overflow:hidden;
    z-index:6;
    >div>div>button,
    >div>div>p {
        height: 34rem;
        display:flex;
        align-items:center;
        padding:0 10rem;
        color:var(--G_700);
        font-size:14rem;
        // background-color:#fff;
        background-color: inherit;
        font-weight:400;
        .gray {
            display: contents;
            color: var(--G_300);
        }
    }
    >div>div>button{
        display:flex;
        align-items:center;
        p{
            flex:1;
            text-align:${textAlign};
        }
        &:not(:disabled):hover{
            background-color:var(--G_25);
        }
        &:not(:disabled):not(.addBtn):active{
            background-color:var(--G_50);
        }
        &:not(:disabled):not(.addBtn):not(:active):focus,&.selected{
            color:var(--P_500);
        }
        &:not(:disabled):not(.addBtn):not(:active):focus,&.focus{
            background-color:var(--P_50);
        }
        &.addBtn{
            position:sticky;
            top:0;
        }
        &:disabled{
            background-color:var(--G_25);
            color:var(--G_150);
            cursor:no-drop;
        }
        
    }
`}
`;

export default DropList;
