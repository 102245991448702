import useTab from 'Common/Hooks/useTab';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { TextSmall } from 'Common/Utils/util';
import { Box } from 'Common/UI/atoms';

const MallNameModule = ({
  product_info_id,
  onClick,
  close_product_chk,
  nvmid = null,
  close_product_status,
  smartstore_chk,
  navershopping_chk,
  navershopping_foreign_chk,
  brandstore_chk,
  mall_name,
  title,
  group_search_nvmid,
  group_title,
  adult_chk,
}) => {
  return (
    <>
      {close_product_chk ? (
        <StatusText>
          <span>{close_product_status}</span>
        </StatusText>
      ) : (
        <MallText
          width="100%"
          isClickChk={onClick}
          onClick={() => {
            if (onClick) onClick();
            // if (mall_name) navigate_newtab(`/mall/${mall_name}`);
          }}
        >
          <StatusTitleText>
            <Box>
              {product_info_id ? `[${product_info_id}]` : null}
              {group_search_nvmid ? <h2>[원부]</h2> : null}
              {navershopping_foreign_chk ? <h2>[해외직구]</h2> : null}
              {navershopping_chk ? <h2>[쇼핑윈도]</h2> : null}
              {brandstore_chk ? <h2>[브랜드몰]</h2> : null}
              {smartstore_chk === false ? <small>[오픈마켓]</small> : null}
              {adult_chk ? <small>[성인인증상품]</small> : null}
              <h1>{mall_name}</h1>
            </Box>
            <Box column>
              {group_title ? (
                <>
                  <Box>
                    <h3>비교검색 - [{group_title}]</h3>{' '}
                  </Box>
                  <Box>
                    <TextLimitBox>
                      <TextSmall>{title}</TextSmall>
                    </TextLimitBox>
                  </Box>
                </>
              ) : (
                <>
                  <TextLimitBox>
                    <TextSmall>{title}</TextSmall>
                  </TextLimitBox>
                </>
              )}
            </Box>

            {/* <Highlight search={searchWord}>{e.title?.toString()}</Highlight> */}
          </StatusTitleText>
        </MallText>
      )}
    </>
  );
};
const StatusTitleText = styled.span`
  font-weight: 400;
  display: flex;
  flex: 1;
  width: 100%;
  /* width: 600rem; */
  /* background-color: red; */
  /* word-wrap: break-word; */
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 14rem;
  /* display: inline-block; */
  gap: 12rem;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  h1 {
    font-size: 13rem;
    font-weight: 500;
    color: #4879ba;
  }
  small {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #f00000;
  }

  h2 {
    width: 100%;
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #265926;
  }
  h3 {
    width: 100%;
    margin-top: 2rem;
    /* background-color: red; */
    text-align: center;
    font-size: 11rem;
    font-weight: 400;
    color: #00a000;
  }
`;
const MallText = styled.button`
  ${(props) => {
    const { isClickChk } = props;
    if (!isClickChk) {
      return css`
        cursor: default;
      `;
    }
  }}
`;
const TextLimitBox = styled.div`
  align-items: left;
  width: 300rem;
  word-wrap: break-word;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;

  span {
    font-size: 14rem;
    line-height: 18rem;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return css`
      color: #fff;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300);
      background-color: #bc340b;
    `;
  }}
`;
export default MallNameModule;
