import React from 'react';
import styled from 'styled-components';
import { Box } from '../atoms';

function LabelBox({
  label,
  children,
  className,
  big,
  gap = 8,
  fontSize = 14,
  row = false,
  back = false,
  alignItems = 'baseline',
  fill,
}) {
  return (
    <Wrapper
      label={label || ''}
      className={className}
      fSize={fontSize}
      big={big}
      row={row}
      fill={fill}
    >
      {row ? (
        <Box className="labelText" gap={gap} style={`align-items: ${alignItems};`}>
          {!back && label}
          {children}
          {back && label}
        </Box>
      ) : (
        <>
          <Box className="labelText" gap={8}>
            {label}
          </Box>
          {children}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ label, fSize, row, fill }) => `
        width:${fill ? '100%' : 'auto'};
        .labelText {
        margin-bottom: ${label && !row ? 8 : 0}rem;
        color: var(--G_500);
        font-size: ${fSize}rem;
    }
    `}
  .value {
    color: var(--G_700);
  }
`;
export default LabelBox;
