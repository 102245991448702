import { Box, Button, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { onShowToast } from 'features/common/commonSlice';
import PercentModule from './Module/PercentModule';
import {
  requestLoadWorkNewGetManualGroupShopKeyword,
  requestLoadWorkNewSetManualGroupShopKeyword,
} from 'Common/Service/common';

const WorkNewPatternAddShopKeywordManualGroup = () => {
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();
  const [keywordInfo, setKeywordInfo] = useState();
  const [keywordCountInfo, setKeywordCountInfo] = useState();

  const getWorkInfos = async ({ keyword_group_name = null }) => {
    const result = await requestLoadWorkNewGetManualGroupShopKeyword({ keyword_group_name });
    console.log(result);
    setKeywordInfo(result?.keyword_info);
    setKeywordCountInfo(result?.count_infos);
    // setWorkstationNames(workstation_infos);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getWorkInfos({});
  }, []);
  return (
    <Box column width={800} height="100%">
      <Box>일반 쇼핑 키워드 등록</Box>
      <Grid column={['1fr', '350rem']} valign="top" gap={8}>
        <Box column>
          <Box>
            <Button
              fill
              onClick={async () => {
                const result = await requestLoadWorkNewSetManualGroupShopKeyword(keywordInfo);
                if (!result) return;
                const new_keywords_count = result?.new_keywords_count;
                const del_keywords_count = result?.del_keywords_count;
                dispatch(
                  onShowToast({
                    status: 'success',
                    message: `${new_keywords_count}개의 신규 키워드가 추가 되었습니다. ${del_keywords_count}개의 키워드가 삭제 되었습니다.`,
                    cancelText: '',
                    onCancel: false,
                  }),
                );
              }}
            >
              수정
            </Button>
          </Box>
          <Box>
            <Box>그룹 </Box>
            <Box>
              <InputComponent
                name={'keyword_group_name'}
                value={keywordInfo?.keyword_group_name}
                onChange={(e) => {
                  setKeywordInfo({ ...keywordInfo, keyword_group_name: e.target.value });
                }}
              />
            </Box>
          </Box>
          <Box>
            <InputComponent
              //   autocomplete={'off'}
              size={500}
              el="textarea"
              //   underLine

              placeholder={''}
              value={keywordInfo?.keywords}
              onChange={(e) => {
                //   addMdDelay();
                setKeywordInfo({ ...keywordInfo, keywords: e.target.value });
                //   addMd();
                // setProductIds(e.target.value);
              }}
              // onKeyUp={onKeyUp}
              //   required
            />
          </Box>
        </Box>
        <Box column>
          {keywordCountInfo?.map((e) => {
            return (
              <Box column align="left">
                <TextButton
                  onClick={() => {
                    getWorkInfos({ keyword_group_name: e?.keyword_group_name });
                  }}
                >
                  {e?.keyword_group_name}
                </TextButton>
                <Box>
                  {e?.count}개 수집{' '}
                  <PercentModule value={e?.get_shop_chk_count} valueMax={e?.count} />, 수집(활동){' '}
                  <PercentModule value={e?.get_shop_active_chk_count} valueMax={e?.count} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Box>
  );
};

export default WorkNewPatternAddShopKeywordManualGroup;
