import useMutations from 'Common/Service/userMutations';
import { Button, DropBox, InputComponent } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { getAlphaAndNumber } from 'Common/Utils';
import { debounce } from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const UserAdd = () => {
  const [userNameStatus, setUserNameStatus] = useState({ status: 'asdf' });
  const [result, setResults] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [addUserType, setAddUserType] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  const {
    requestLoadPartnerGetNamesMutation,
    requestLoadUserAddMutation,
    requestLoadUserNameExistCheckMutation,
  } = useMutations(setResults);
  const location = useLocation();
  const [partnerName, setPartnerName] = useState();
  const [partnerNames, setPartnerNames] = useState([
    // { title: '미분류', value: null, selected: true },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (result?.request === 'requestLoadPartnerGetNamesMutation') {
      if (result?.success && result?.result !== undefined) {
        const list1 = [
          // { title: '미분류', value: null, selected: true }
        ];
        var list2 = result?.result?.map((e, _idx) => {
          return {
            title: e,
            value: e,
          };
        });
        if (list2 === undefined) list2 = [];

        setPartnerNames([...list1, ...list2]);
      }
    }
    if (result?.request === 'requestLoadUserNameExistCheckMutation') {
      if (result?.success && result?.result?.result === true) {
        setUserNameStatus({ status: '사용자명을 사용하실 수 있습니다.', isUse: true });
        setUserInfo({ ...userInfo, user_name: result?.result?.user_name });
      } else {
        setUserNameStatus({ status: '사용자명을 사용하실 수 없습니다.', isUse: false });
      }
    } else if (result?.request === 'requestLoadUserAddMutation') {
      if (result?.success) {
        if (result?.result?.result) {
          setUserNameStatus({ status: '', isUse: false });
          setUserInfo({ ...userInfo, user_name: '', user_password: '', user_nickname: '' });
        }
        dispatch(
          onShowToast({
            status: result?.result?.result ? 'success' : 'error',
            message: result?.result?.message,
            cancelText: '',
            onCancel: false,
          }),
        );
      }
    }
    setResults({});
  }, [result.request]);

  const onSearchInputChange = async (e) => {
    setUserNameStatus({ status: '', isUse: false });

    InputSearchDebounce(e.target.value);
  };
  const InputSearchDebounce = useCallback(
    debounce((value) => {
      requestLoadUserNameExistCheckMutation.mutate({ user_name: value });
    }, 2000),
    [],
  );

  useEffect(() => {
    const user_type =
      location.pathname === '/signup/' || location.pathname === '/signup' ? 'user' : 'partner';
    setAddUserType(user_type);
    if (user_type === 'partner' && loginInfo.user_level === 3) {
      setPartnerName(loginInfo.user_name);
    }
    setUserNameStatus({});
    setUserInfo({ user_type });
    requestLoadPartnerGetNamesMutation.mutate({});
  }, [location.pathname]);

  const isUserAddCheck = () => {
    // if (!partnerName && addUserType === 'user') return false;
    if (!userNameStatus?.isUse) return false;
    if (userInfo?.user_name?.length < 4) return false;
    if (userInfo?.user_name?.length > 12) return false;
    if (userInfo?.user_password?.length < 4) return false;
    if (userInfo?.user_password?.length > 12) return false;
    return true;
  };
  const userAdd = () => {
    var partner_name = partnerName;
    const fields = {
      user_name: userInfo?.user_name,
      user_password: userInfo?.user_password,
      user_nickname: userInfo?.user_nickname,
      user_type: userInfo?.user_type,
      partner_name,
    };
    requestLoadUserAddMutation.mutate(fields);
  };
  return (
    <>
      <Wrapper>
        <Box column gap="40rem" top={6} width={300}>
          {/* {userInfo?.user_type === 'user' && loginInfo?.user_level >= 4 && (
            <DropBox
              title={'2차대행사'}
              isColor
              option={partnerNames}
              size={44}
              onChange={(_name, partner_name) => {
                setPartnerName(partner_name);
              }}
            />
          )} */}
          <LabelBox label={'아이디'}>
            {/* {partnerName}:{addUserType} */}
            <InputComponent
              // disabled={!partnerName && addUserType !== 'partner'}
              autocomplete={'off'}
              size="44"
              name="user_name"
              maxLength={12}
              value={userInfo?.user_name}
              onChange={(e) => {
                setUserNameStatus({ status: '', isUse: false });
                const user_name = getAlphaAndNumber(e.target.value);
                setUserInfo({ ...userInfo, [e.target.name]: user_name });
                if (!user_name) return;

                if (user_name?.length < 4) return;
                if (user_name?.length > 12) return;

                onSearchInputChange(e);
              }}
              underLine
              placeholder={'4~12 자리 영문, 숫자'}
              required
            />
            <StatusText isError={!userNameStatus?.isUse}>{userNameStatus?.status}</StatusText>
          </LabelBox>
          <LabelBox label={'닉네임'}>
            {/* {partnerName}:{addUserType} */}
            <InputComponent
              // disabled={!partnerName && addUserType !== 'partner'}
              autocomplete={'off'}
              size="44"
              name="user_nickname"
              value={userInfo?.user_nickname}
              onChange={(e) => {
                const user_nickname = e.target.value;
                setUserInfo({ ...userInfo, [e.target.name]: user_nickname });
                if (!user_nickname) return;

                // onSearchInputChange(e);
              }}
              underLine
              // placeholder={'4~12 자리 영문, 숫자'}
              required
            />
            {/* <StatusText isError={!userNameStatus?.isUse}>{userNameStatus?.status}</StatusText> */}
          </LabelBox>
          <LabelBox label={'비밀번호'}>
            <InputComponent
              // disabled={!partnerName && addUserType !== 'partner'}
              autocomplete={'off'}
              maxLength={12}
              placeholder={'4~12 자리'}
              size="44"
              underLine
              value={userInfo?.user_password}
              name="user_password"
              onChange={(e) => {
                const value = e.target.value.trim();

                setUserInfo({ ...userInfo, [e.target.name]: value });
              }}
            />
          </LabelBox>
          <Box width="100%" align="center">
            <Button
              onClick={() => {
                userAdd();
              }}
              disabled={!isUserAddCheck()}
            >
              등록
            </Button>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;
  color: #18795a;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 25rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { isError } = props;
    if (isError)
      return `
      color: #f00000;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 

      // background-color: #bc340b;
          
      `;
  }}
`;
export default UserAdd;
