import { requestLoadWorkResultLogsGetTrafficDateLogs } from 'Common/Service/common';
// import useMutations from 'Common/Service/userMutations';
import Box from 'Common/UI/atoms/Box';
import { currencyComma } from 'Common/Utils';
import { TextFail, TextSmall, TextSucceed, reload_second, today_str } from 'Common/Utils/util';
import React, { useEffect, useState } from 'react';

const WorkResultLogsTrafficDate = ({ date = null, uuid }) => {
  const [etcDo1TrafficLogs, setEtcDo1TrafficLogs] = useState([]);
  const [count, setCount] = useState(reload_second);
  const [checkDate, setCheckDate] = useState(date);
  const [apiId, setApiId] = useState();
  useEffect(() => {
    clearInterval(apiId);
    setCheckDate(date);
    getEtcDo1TrafficLogs(date);
  }, [date, uuid]);

  const getEtcDo1TrafficLogs = async (check_date = null) => {
    const infos = await requestLoadWorkResultLogsGetTrafficDateLogs({
      date: check_date ? check_date : checkDate,
    });
    setEtcDo1TrafficLogs(infos);
  };
  useEffect(() => {
    if (today_str(checkDate) != today_str()) return;
    clearInterval(apiId);
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getEtcDo1TrafficLogs(checkDate);
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, [date, uuid]);
  if (!date) return;

  return (
    <Box column>
      <Box>
        traffic date [{etcDo1TrafficLogs?.date}] <TextSmall>{count}</TextSmall>
      </Box>
      <Box column gap={20}>
        {etcDo1TrafficLogs?.work_traffic_logs?.map((e, index) => {
          return (
            <Box column key={index}>
              <Box>{e?.pure_url_type}</Box>
              <Box>
                <Box>
                  <TextSmall>
                    access count: <TextSucceed>{currencyComma(e?.access_count)}</TextSucceed>
                  </TextSmall>{' '}
                </Box>
                <Box>
                  <TextSmall>
                    {' '}
                    tf:{' '}
                    <TextSucceed>
                      {((e?.access_response_size + e?.access_request_size) / 1024 / 1024).toFixed(
                        1,
                      )}{' '}
                    </TextSucceed>
                    MB
                  </TextSmall>
                </Box>
                <Box>
                  <TextSmall>
                    <TextSucceed>{currencyComma(e?.access_total_time)} </TextSucceed>sec
                  </TextSmall>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default WorkResultLogsTrafficDate;
