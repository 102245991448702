import { Icon, TextButton } from 'Common/UI/atoms';
import { formatDate } from 'Common/Utils/date';
import React from 'react';
import DatepickerReact from './DatepickerReact';
import { BottomSection, DatepickerHeaderBox, InnerMont } from './styled';

function DatepickerHeaderMonth({ state, isActive, onToggle, onChange, ref }) {
  return (
    <InnerMont isActive={isActive}>
      <DatepickerReact
        selected={state}
        onChange={onChange}
        showMonthYearPicker
        className={'innerMonth'}
        inline
        openToDate={state ? state : null}
        changeYear={state}
        renderCustomHeader={({ date, decreaseYear, increaseYear }) => (
          <DatepickerHeaderBox>
            <button onClick={decreaseYear}>
              <Icon.Arrow size="xxs" direction={'left'} />
            </button>
            <span className="headerDate">{formatDate(date).yearOnlyKo}</span>
            <button onClick={increaseYear}>
              <Icon.Arrow size="xxs" direction={'right'} />
            </button>
          </DatepickerHeaderBox>
        )}
      >
        <BottomSection className="bottom">
          <TextButton className={'textClose'} size={'xxs'} onClick={onToggle}>
            닫기
          </TextButton>
        </BottomSection>
      </DatepickerReact>
    </InnerMont>
  );
}

export default DatepickerHeaderMonth;
