import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetNewPatternActiveDoneFinishLogs,
  requestLoadWorkNewGetNewPatternClickDoneLogs,
} from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
// import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternActiveDoneFinishDoneState = ({ date }) => {
  // if (loginInfo?.user_level !== 5) return;
  const [activeDoneInfo, setActiveDoneInfo] = useState();
  const [count, setCount] = useState(20);
  const [apiId, setApiId] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetNewPatternActiveDoneFinishLogs({
      date,
    });
    setActiveDoneInfo(result);
    console.log(result);
  };

  useEffect(() => {
    getWorkInfos();
  }, [date]);
  if (!activeDoneInfo) return;
  return (
    <Block>
      <Box>{count}</Box>
      <StatusBox>
        <Box>
          <TextSucceed>start status</TextSucceed>
        </Box>
        {activeDoneInfo?.start_status?.map((info, index) => {
          return (
            <Box key={index}>
              <Box>{info?.last_work_do}</Box>
              <Box>{info?.count}</Box>
            </Box>
          );
        })}
      </StatusBox>
      <StatusBox>
        <Box>
          <TextSucceed>done status</TextSucceed>
        </Box>
        {activeDoneInfo?.done_status?.map((info, index) => {
          return (
            <Box key={index}>
              <Box>{info?.last_work_do}</Box>
              <Box>{info?.count}</Box>
            </Box>
          );
        })}
      </StatusBox>
      <StatusBox>
        <Box>
          <TextWarning>stop idle status</TextWarning>
        </Box>
        {activeDoneInfo?.start_idle_status?.map((info, index) => {
          return (
            <Box key={index}>
              <Box>{info?.last_work_do}</Box>
              <Box>{info?.count}</Box>
            </Box>
          );
        })}
      </StatusBox>
      <StatusBox>
        <Box>
          <TextFail>stop status</TextFail>
        </Box>
        {activeDoneInfo?.stop_status?.map((info, index) => {
          return (
            <Box key={index}>
              <Box>{info?.last_work_do}</Box>
              <Box>{info?.count}</Box>
            </Box>
          );
        })}
      </StatusBox>
    </Block>
  );
};
const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ActiveLogStatusBox = styled.div`
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  /* border-style: solid; */
  border-width: 1rem;
  border-color: blue;
`;
const StatusBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
export default WorkNewPatternActiveDoneFinishDoneState;
