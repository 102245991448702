import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestLoadWorkNewGetNewPatternProductKeywordStatus } from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
import WorkNewPatternActiveWorkerState from './WorkNewPatternActiveWorkerState';
import { keyword_strip } from 'Common/Utils';
import WorkNewPatternProductInfoKeywordStatusModule from './WorkNewPatternProductInfoKeywordStatusModule';
// import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternProductInfoKeywordStatus = ({ uuid, shop_link_id, id = 6808 }) => {
  // if (loginInfo?.user_level !== 5) return;

  const [productInfoKeywordInfo, setProductInfoKeywordInfo] = useState();

  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetNewPatternProductKeywordStatus({ id, shop_link_id });

    setProductInfoKeywordInfo(result);
  };
  useEffect(() => {
    // setProductInfoKeywordStatus(null);
    getWorkInfos();
  }, [id, shop_link_id, uuid]);
  if (!productInfoKeywordInfo) return;
  return <WorkNewPatternProductInfoKeywordStatusModule data={productInfoKeywordInfo} />;
};

const Block = styled.div`
  font-size: 13rem;
  font-weight: 500;
`;
const ActiveLogStatusBox = styled.div`
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  /* border-style: solid; */
  border-width: 1rem;
  border-color: blue;
`;
const StatusBox = styled.div`
  display: flex;
  padding: 2rem;
  width: 100%;
  /* border-radius: 10rem; */
  font-size: 12rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
export default WorkNewPatternProductInfoKeywordStatus;
