import { Box, Button, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetWorkFiddlerCountInfos,
  requestLoadWorkProxyGetInfos,
  requestLoadWorkVmwareFInfos,
  requestLoadWorkVmwareInfos,
} from 'Common/Service/common';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  getKorDate,
  reload_second,
  to_integer,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { sortSet } from 'Common/Utils';
import WorkNewPatternAddGroupPattern from './WorkNewPatternAddGroupPattern';
import WorkNewPatternAddPattern from './WorkNewPatternAddPattern';
import WorkNewPatternAddKeywordManualCountryNormal from './WorkNewPatternAddKeywordManualCountryNormal';
import WorkNewPatternAddKeywordManualGroup from './WorkNewPatternAddKeywordManualGroup';
import WorkNewPatternAddShopKeywordManualGroup from './WorkNewPatternAddShopKeywordManualGroup';
import WorkNewPatternAddKeywordManualOtherLink from './WorkNewPatternAddKeywordManualOtherLink';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import WorkNewPatternAddKeywordAutoHotNormal from './WorkNewPatternAddKeywordAutoHotNormal';
import WorkNewPatternAddKeywordAutoCountryNormal from './WorkNewPatternAddKeywordAutoCountryNormal';
import WorkNewPatternNeedAlbaInfos from './WorkNewPatternNeedAlbaInfos';

const WorkNewPattern = () => {
  const [workFiddlerCountInfos, setWorkFiddlerCountInfos] = useState();
  const { loginInfo } = useSelector((state) => state.common);
  const [apiId, setApiId] = useState();
  // if (loginInfo?.user_level !== 5) return;
  const navigate = useNavigate();

  const getWorkInfos = async () => {
    const work_fiddler_count_infos = await requestLoadWorkNewGetWorkFiddlerCountInfos();
    setWorkFiddlerCountInfos(work_fiddler_count_infos ? work_fiddler_count_infos : {});
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Box column>
      <GridBox>
        <Box gap={20}>
          <Box column>
            <Box>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/group_pattern');
                }}
              >
                활동 그룹 등록
              </TextButton>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/pattern');
                }}
              >
                패턴 등록
              </TextButton>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/manual_keyword_country');
                }}
              >
                나라 키워드 등록
              </TextButton>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/manual_keyword_group');
                }}
              >
                일반 키워드 등록
              </TextButton>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/manual_shop_keyword_group');
                }}
              >
                일반 쇼핑 키워드 등록
              </TextButton>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/manual_keyword_other_link');
                }}
              >
                외부 링크 등록
              </TextButton>
            </Box>
            <Box>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/auto_hot_normal');
                }}
              >
                검색량 많은 키워드 등록
              </TextButton>
              <TextButton
                onClick={() => {
                  navigate('/new_pattern/auto_keyword_country');
                }}
              >
                [자동] 나라 키워드
              </TextButton>
            </Box>
          </Box>
          <Box>
            <WorkNewPatternNeedAlbaInfos />
          </Box>
        </Box>
      </GridBox>
      <Box column gap={30} width={'100%'}>
        <Routes>
          <Route path={'/'} element={<WorkNewPatternAddGroupPattern />} />
          <Route path={'group_pattern'} element={<WorkNewPatternAddGroupPattern />} />
          <Route path={'pattern'} element={<WorkNewPatternAddPattern />} />
          <Route
            path={'manual_keyword_country'}
            element={<WorkNewPatternAddKeywordManualCountryNormal />}
          />
          <Route
            path={'auto_keyword_country'}
            element={<WorkNewPatternAddKeywordAutoCountryNormal />}
          />
          <Route path={'manual_keyword_group'} element={<WorkNewPatternAddKeywordManualGroup />} />
          <Route
            path={'manual_shop_keyword_group'}
            element={<WorkNewPatternAddShopKeywordManualGroup />}
          />
          <Route
            path={'manual_keyword_other_link'}
            element={<WorkNewPatternAddKeywordManualOtherLink />}
          />
          <Route path={'auto_hot_normal'} element={<WorkNewPatternAddKeywordAutoHotNormal />} />
        </Routes>
      </Box>
    </Box>
  );
};
const GridBox = styled.div`
  /* background-color: red; */
  /* height: 100rem; */
`;

export default WorkNewPattern;
