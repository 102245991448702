import { API_SERVER_URL } from 'Common/Env';
import { auth_headers, getCookie } from 'Common/Utils';
import axios from 'axios';

export const requestLoadUserLoginCheck = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/user/login_check`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadUserLogin = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/user/login`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadUserSet = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/user/set`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadUserModify = async (data = {}) => {
  try {
    // const api = axios.create({
    //   withCredentials: false,
    // });
    const api_url = `${API_SERVER_URL}/user/modify`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadUserGetModifyInfo = async (data = {}) => {
  try {
    // const api = axios.create({
    //   withCredentials: false,
    // });
    const api_url = `${API_SERVER_URL}/user/get_modify_info`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadUserAdd = async (data = {}) => {
  try {
    // const api = axios.create({
    //   withCredentials: false,
    // });
    const api_url = `${API_SERVER_URL}/user/add`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadUserNameCheck = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/user/name_check`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadUserNameExistCheck = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/user/name_exist_check`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadUserList = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/user/list`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSlotGroupGetNames = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/slot_group/get/names`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadPartnerGetNames = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/partner/get/names`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadUserGetNames = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/user/get/names`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
