import { Grid } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ManageVmwares from './Page/ManageVmwares';
import { useSelector } from 'react-redux';
const Manage = () => {
  const [result, setResults] = useState({});
  const { loginInfo } = useSelector((state) => state.common);

  useEffect(() => {
    if (result?.request === 'requestLoadVmwareGetApiInfoMutation') {
      if (result?.success && result?.result !== undefined) {
      }
    }
    setResults({});
  }, [result.request]);
  if (loginInfo?.user_level !== 5) return;
  return (
    <>
      <Grid row={['auto', 1]} gap={32}>
        <Routes>
          <Route path={'/vmwares/*'} element={<ManageVmwares />} />
        </Routes>
      </Grid>
    </>
  );
};
export default Manage;
