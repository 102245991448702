import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function useTab(initialState = 0, isBreak) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(initialState);
  const onClickTab = (idx) => setCurrent(idx);

  const onClickLocation = (pathname) => {
    navigate(`${pathname}`);
  };

  useEffect(() => setCurrent(initialState), [initialState]);

  return {
    current,
    onClickLocation,
    onClickTab,
  };
}

export default useTab;
