import { Box } from 'Common/UI/atoms';
import { TextFail, TextSmall, TextSucceed, TextWarning } from 'Common/Utils/util';
import styled from 'styled-components';
import TrafficSizeModule from './TrafficSizeModule';
import PercentModule from './PercentModule';
import {
  requestLoadWorkNewGetGroupPatternInfo,
  requestLoadWorkNewNewPatternGroupInfoGetTrafficClickInfoLog,
  requestLoadWorkNewNewPatternGroupInfoGetTrafficDateLogs,
  requestLoadWorkNewNewPatternGroupInfoGetTrafficDetailInfoLog,
} from 'Common/Service/common';
import { useEffect, useState } from 'react';
const GroupPatternStateWorkerInfoModule = ({
  url_access_request_size,
  url_access_response_size,
  url_access_total_time,
  url_access_count,
  worker_count,
}) => {
  return (
    <Box>
      <TrafficSizeModule size={url_access_request_size + url_access_response_size} />
      <Box>Spd: {(url_access_total_time / url_access_count)?.toFixed(1)} s</Box>
      <Box>
        Avg:{' '}
        <TrafficSizeModule
          size={(url_access_request_size + url_access_response_size) / worker_count}
        />
      </Box>
    </Box>
  );
};
const GroupPatternStateModule = ({ group_name, state_info }) => {
  const [groupPatternInfo, setGroupPatternInfo] = useState([]);
  const [trafficDateInfos, setTrafficDateInfos] = useState([]);
  const [clickTrafficInfo, setClickTrafficInfo] = useState();
  const [trafficDetailInfo, setTrafficDetailInfo] = useState();
  const [date, setDate] = useState();
  const getWorkInfos = async () => {
    var data;
    const new_pattern_group_name = group_name;
    data = await requestLoadWorkNewGetGroupPatternInfo({
      group_name,
    });
    setGroupPatternInfo(data);
    data = await requestLoadWorkNewNewPatternGroupInfoGetTrafficClickInfoLog({
      new_pattern_group_name,
      date,
    });
    setClickTrafficInfo(data);
    data = await requestLoadWorkNewNewPatternGroupInfoGetTrafficDetailInfoLog({
      new_pattern_group_name,
      date,
    });
    setTrafficDetailInfo(data);
    data = await requestLoadWorkNewNewPatternGroupInfoGetTrafficDateLogs({
      new_pattern_group_name,
      ago_day: 7,
    });
    setTrafficDateInfos(data);
  };
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Box width="100%" height="100%" valign="top">
      <Box width="100%" valign="top">
        <Box width={500}>
          <PatternStateInfoBox>
            <Box>
              <h1>{group_name}</h1> ({groupPatternInfo?.worker_count} /{' '}
              {groupPatternInfo?.worker_count_max})
            </Box>
            <Box>
              상품정보: 완료:{' '}
              <TextSucceed>{groupPatternInfo?.product_click_info?.done_count}</TextSucceed> ,
              클릭가능{' '}
              <TextSucceed>{groupPatternInfo?.product_click_info?.can_access_count}</TextSucceed>/
              {groupPatternInfo?.product_click_info?.all_count -
                groupPatternInfo?.product_click_info?.done_count}
            </Box>
            <Box>
              할당 브라우저: <TextSucceed>{state_info?.count}</TextSucceed>개
            </Box>
            <Box>
              활동패턴상태:{' '}
              <TextSucceed>
                {state_info?.new_pattern_active_count_under_3 +
                  state_info?.new_pattern_active_count_over_3}
              </TextSucceed>{' '}
              (활동가능: <TextSucceed>{state_info?.active_can_access_count}</TextSucceed>)
            </Box>
            <Box>
              <PercentModule
                value={state_info?.new_pattern_url_access_result_ok_count}
                valueMax={state_info?.new_pattern_url_access_count}
              />{' '}
              {(
                state_info?.new_pattern_url_access_total_time /
                state_info?.new_pattern_url_access_count
              ).toFixed(2)}
              ,
              <TrafficSizeModule
                size={
                  state_info?.new_pattern_url_access_request_size +
                  state_info?.new_pattern_url_access_response_size
                }
              />
              up:
              {(
                state_info?.new_pattern_url_access_upload_speed /
                state_info?.new_pattern_url_access_count /
                1024
              ).toFixed(1)}
              Kb , down:
              {(
                state_info?.new_pattern_url_access_download_speed /
                state_info?.new_pattern_url_access_count /
                1024
              ).toFixed(1)}
              Kb
            </Box>
            <Box column>
              {state_info?.new_pattern_active_count_step_0 > 0 && (
                <Box>
                  <TextSmall>
                    active step 0: worker:
                    <TextFail>{state_info?.new_pattern_active_count_step_0}</TextFail>
                  </TextSmall>

                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.active_infos?.find((e) => e?.new_pattern_count === 0)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_active_count_step_1 > 0 && (
                <Box>
                  <TextSmall>
                    active step 1: worker:
                    <TextFail>{state_info?.new_pattern_active_count_step_1}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.active_infos?.find((e) => e?.new_pattern_count === 0)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_active_count_step_2 > 0 && (
                <Box>
                  <TextSmall>
                    active step 2: worker:{' '}
                    <TextFail>{state_info?.new_pattern_active_count_step_2}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.active_infos?.find((e) => e?.new_pattern_count === 1)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_active_count_step_3 > 0 && (
                <Box>
                  <TextSmall>
                    active step 3: worker:{' '}
                    <TextFail>{state_info?.new_pattern_active_count_step_3}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.active_infos?.find((e) => e?.new_pattern_count === 2)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_active_count_step_4 > 0 && (
                <Box>
                  <TextSmall>
                    active step 4: worker:
                    <TextFail>{state_info?.new_pattern_active_count_step_4}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.active_infos?.find((e) => e?.new_pattern_count === 3)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_active_count_step_5 > 0 && (
                <Box>
                  <TextSmall>
                    active step 5: worker:
                    <TextFail>{state_info?.new_pattern_active_count_step_5}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.active_infos?.find((e) => e?.new_pattern_count === 4)}
                  />
                </Box>
              )}
            </Box>
            <Box>
              클릭패턴상태:{' '}
              <TextSucceed>
                {state_info?.new_pattern_click_count_under_3 +
                  state_info?.new_pattern_click_count_over_3}
              </TextSucceed>{' '}
              (활동가능: <TextFail>{state_info?.click_can_access_count}</TextFail>)
            </Box>
            <Box column>
              {state_info?.new_pattern_click_count_step_0 > 0 && (
                <Box>
                  <TextSmall>
                    click step 0: worker:
                    <TextFail>{state_info?.new_pattern_click_count_step_0}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.click_infos?.find((e) => e?.new_pattern_count === 2)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_click_count_step_1 > 0 && (
                <Box>
                  <TextSmall>
                    click step 1: worker:
                    <TextFail>{state_info?.new_pattern_click_count_step_1}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.click_infos?.find((e) => e?.new_pattern_count === 0)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_click_count_step_2 > 0 && (
                <Box>
                  <TextSmall>
                    click step 2: worker:
                    <TextFail>{state_info?.new_pattern_click_count_step_2}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.click_infos?.find((e) => e?.new_pattern_count === 1)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_click_count_step_3 > 0 && (
                <Box>
                  <TextSmall>
                    click step 3: worker:
                    <TextFail>{state_info?.new_pattern_click_count_step_3}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.click_infos?.find((e) => e?.new_pattern_count === 2)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_click_count_step_4 > 0 && (
                <Box>
                  <TextSmall>
                    click step 4: worker:
                    <TextFail>{state_info?.new_pattern_click_count_step_4}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.click_infos?.find((e) => e?.new_pattern_count === 3)}
                  />
                </Box>
              )}
              {state_info?.new_pattern_click_count_step_5 > 0 && (
                <Box>
                  <TextSmall>
                    click step 5: worker:
                    <TextFail>{state_info?.new_pattern_click_count_step_5}</TextFail>
                  </TextSmall>
                  <GroupPatternStateWorkerInfoModule
                    {...trafficDetailInfo?.click_infos?.find((e) => e?.new_pattern_count === 4)}
                  />
                </Box>
              )}
            </Box>
            <Box>
              장기휴식중: <TextSucceed>{state_info?.new_pattern_longterm_countnt}</TextSucceed>
            </Box>
            {/* <Box>
          여자20대:<TextSucceed>{state_info?.woman_20_count}</TextSucceed>,여자30대:
          <TextSucceed>{state_info?.woman_30_count}</TextSucceed>
          ,여자40대:<TextSucceed>{state_info?.woman_40_count}</TextSucceed>
        </Box>
        <Box>
          남자20대: <TextSucceed>{state_info?.man_20_count}</TextSucceed>,남자30대:
          <TextSucceed>{state_info?.man_30_count}</TextSucceed>
          ,남자40대:<TextSucceed>{state_info?.man_40_count}</TextSucceed>
        </Box> */}
          </PatternStateInfoBox>
        </Box>
        <Box width="100%" column>
          <Box>
            {trafficDateInfos?.map((e, index) => {
              const date = e?.date;
              const worker_count = e?.worker_count;
              // const active_url_access_count = e?.active_url_access_count;
              // const active_url_access_total_time = e?.active_url_access_total_time;
              // const click_url_access_count = e?.click_url_access_count;
              // const click_url_access_total_time = e?.click_url_access_total_time;
              // const active_speed = active_url_access_total_time / active_url_access_count;
              // const click_speed = click_url_access_total_time / click_url_access_count;
              return (
                <DateTrafficBox>
                  <Box column key={index}>
                    <Box>{date}</Box>
                    <Box>workers: {worker_count}</Box>
                    <Box>
                      active:{' '}
                      <GroupPatternStateWorkerInfoModule
                        url_access_total_time={e?.active_url_access_total_time}
                        url_access_count={e?.active_url_access_count}
                        url_access_request_size={e?.active_url_access_request_size}
                        url_access_response_size={e?.active_url_access_response_size}
                        worker_count
                      />
                      {/* <TrafficSizeModule
                      size={e.active_url_access_request_size + e.active_url_access_response_size}
                    />
                    <Box>속도: {active_speed.toFixed(1)} 초</Box> */}
                    </Box>
                    <Box>
                      click:{' '}
                      <GroupPatternStateWorkerInfoModule
                        url_access_total_time={e?.click_url_access_total_time}
                        url_access_count={e?.click_url_access_count}
                        url_access_request_size={e?.click_url_access_request_size}
                        url_access_response_size={e?.click_url_access_response_size}
                        worker_count
                      />
                      {/* <TrafficSizeModule
                      size={e.click_url_access_request_size + e.click_url_access_response_size}
                    />
                    <Box>속도: {click_speed.toFixed(1)} 초</Box> */}
                    </Box>
                  </Box>
                </DateTrafficBox>
              );
            })}
          </Box>
          <DateTrafficBox>
            <Box>클릭 전 트래픽 현황</Box>
            {clickTrafficInfo && (
              <>
                <Box column>
                  <Box>{clickTrafficInfo?.date}</Box>
                  <Box>워커수: {clickTrafficInfo?.click_worker_count}</Box>

                  <Box>
                    클릭 전 활동 트래픽:{' '}
                    <TrafficSizeModule
                      size={
                        clickTrafficInfo?.active_traffic_info?.active_url_access_request_size +
                        clickTrafficInfo?.active_traffic_info?.active_url_access_response_size
                      }
                    />
                    평균:{' '}
                    <TrafficSizeModule
                      size={
                        (clickTrafficInfo?.active_traffic_info?.active_url_access_request_size +
                          clickTrafficInfo?.active_traffic_info?.active_url_access_response_size) /
                        clickTrafficInfo?.click_worker_count
                      }
                    />
                  </Box>
                  <Box>
                    클릭 트래픽 :{' '}
                    <TrafficSizeModule
                      size={
                        clickTrafficInfo?.click_traffic_info?.click_url_access_request_size +
                        clickTrafficInfo?.click_traffic_info?.click_url_access_response_size
                      }
                    />
                    평균:{' '}
                    <TrafficSizeModule
                      size={
                        (clickTrafficInfo?.click_traffic_info?.click_url_access_request_size +
                          clickTrafficInfo?.click_traffic_info?.click_url_access_response_size) /
                        clickTrafficInfo?.click_worker_count
                      }
                    />
                  </Box>
                </Box>
              </>
            )}
          </DateTrafficBox>
        </Box>
      </Box>
    </Box>
  );
};
const DateTrafficBox = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  gap: 8;
  padding: 5rem;
  border-radius: 10rem;
  font-size: 13rem;
  width: 100%;
  /* height: 100%; */
  /* background-color: red; */
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
const PatternStateInfoBox = styled.div`
  font-size: 15rem;
  font-weight: 500;
  h1 {
    font-size: 17rem;
    font-weight: 700;
  }
`;
export default GroupPatternStateModule;
