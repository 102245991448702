import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({
    isActive,
    overflow,
    isHidden,
    accordionLabel,
    isMin,
    isRoof,
    pd,
    styles,
    gap,
    isBorder,
    isRound = true,
  }) => `
display:flex;
flex-direction:column;
overflow-x:visible;
${isHidden ? 'overflow-y:hidden;' : ''}
// transition:0.3s,overflow 0s 0.3s;
// border-radius:12rem;
${isRound ? 'border-radius:12rem;' : ''}
background-color:var(--White);
box-shadow:var(--Elevation_001);
width:100%;
justify-content: left;


.accordionHeader{
    background-color:var(--${isActive ? `P_${isRoof ? 75 : 50}` : 'White'});
    ${isRoof ? 'height:48rem;' : isMin ? 'min-height:96rem;' : ''}
    padding: ${pd};
    gap:${gap};
    position:relative;
    margin: 0rem;
    // transition: 0.5s;

    &:hover {
      background-color: var(--P_200);
    }
    .categoryBadge{
      position:absolute;
      left:0;
      top:12rem;
      font-size:12rem;
    }
    .checkBox{
        margin-right:12rem;
    }
    .accordionTitle{
        flex: 1 1;
        color:var(--${isActive ? 'P_700' : 'G_900'});
        font-weight:500;
        h5{font-size:16rem;}
        h3{font-size:20rem;}
    }
    .btnBox{
        button{
            font-size:14rem;
        }
    }
    &:not(:last-child){
        // border-radius:12rem 12rem 0 0;
    }

  }
  
  >div:not(.accordionHeader){
    gap:0;
    >*{
      width:100%;
      ${accordionLabel ? `&:first-child{height:34rem;}` : ''}
      display:flex;
      align-items:center;
      // transition:0.3s;
      padding:0rem 40rem;
      &:last-child{
        border-radius:0 0 12rem 12rem;
      }
      &:not(:last-child){
        ${isBorder && `border-bottom:1rem solid var(--G_75);`}
      }
    }
  }
  ${styles}
`}
`;
