import { Box, Button, CheckBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkNewGetNewPatternRealtimeState,
  requestLoadWorkNewNewPatternGroupInfoGetWorkerStatus,
  requestLoadWorkNewResetWorkerDoCanAccessDatetime,
  requestLoadWorkProductInfoResetCanAccessDatetime,
} from 'Common/Service/common';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import PercentModule from './Module/PercentModule';
import styled from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';
import GroupPatternStateModule from './Module/GroupPatternStateModule';

const WorkNewPatternVmwareState = () => {
  // if (loginInfo?.user_level !== 5) return;
  const [vmwareRealtimeState, setVmwareRealtimeState] = useState();
  const [count, setCount] = useState(20);
  const [apiId, setApiId] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
    return () => clearInterval(intervalId);
  }, []);
  const getWorkInfos = async () => {
    const result = await requestLoadWorkNewGetNewPatternRealtimeState({});
    setVmwareRealtimeState(result);
  };

  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <Box column width="100%">
      <Box width="100%">
        within 2 hour
        <TextSmall>[{count}]</TextSmall>
      </Box>
      <Box column width="100%">
        {vmwareRealtimeState?.map((info, index) => {
          const vmware_name = info?.vmware_name;
          // const info = data?.list[0];
          var realtime_worker_count = info?.realtime_worker_count;
          if (!realtime_worker_count) realtime_worker_count = 0;
          const realtime_worker_active_pattern_count = info?.realtime_worker_active_pattern_count;
          const realtime_worker_click_pattern_count = info?.realtime_worker_click_pattern_count;

          const pattern_done_count = info?.pattern_done_count;
          const pattern_count = info?.pattern_count;
          const url_access_count = info?.url_access_count;
          const url_access_request_size = info?.url_access_request_size;
          const url_access_response_size = info?.url_access_response_size;
          const url_access_total_time = info?.url_access_total_time;
          var size = url_access_request_size + url_access_response_size;
          if (!size) size = 0;
          return (
            <Box key={index} width="100%">
              <KeywordStatusBox>
                <Box gap={8}>
                  <Box>{vmware_name}</Box>
                  <Box>
                    worker:{' '}
                    {realtime_worker_count < 5 ? (
                      <>
                        <TextFail>{realtime_worker_count}</TextFail>
                      </>
                    ) : realtime_worker_count < 50 ? (
                      <>
                        <TextWarning>{realtime_worker_count}</TextWarning>
                      </>
                    ) : (
                      <TextSucceed>{realtime_worker_count}</TextSucceed>
                    )}
                  </Box>
                  <Box>
                    succeed:{pattern_done_count}/{pattern_count}{' '}
                    <PercentModule value={pattern_done_count} valueMax={pattern_count} />
                  </Box>
                  <Box>active: {realtime_worker_active_pattern_count}</Box>
                  <Box>click: {realtime_worker_click_pattern_count}</Box>
                  <Box>
                    <TrafficSizeModule size={size} />
                  </Box>
                  <Box></Box>
                </Box>
              </KeywordStatusBox>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
const KeywordStatusBox = styled.div`
  padding: 5rem;
  width: 100%;
  border-radius: 10rem;
  font-size: 13rem;
  /* font-weight: 500; */
  border-style: solid;
  border-width: 1rem;
  border-color: blue;
`;
export default WorkNewPatternVmwareState;
