import { useMutation } from 'react-query';
import {
  requestLoadSlotRequestSearchKeywordAgain,
  requestLoadSlotAdd,
  requestLoadSlotGroupList,
  requestLoadSlotList,
  requestLoadSlotGroupUse,
  requestLoadSlotUse,
  requestLoadSlotGroupSetOwner,
  requestLoadSlotGroupPaid,
  requestLoadSlotGroupExtendsDay,
  requestLoadSlotGroupSetDate,
  requestLoadSlotGroupSet,
  requestLoadSlotSetGroupName,
  requestLoadSlotSet,
  requestLoadSlotsSet,
  requestLoadSlotDel,
  requestLoadSlotExtendsDay,
  requestLoadSlotAsExtendsDay,
  requestLoadPaymentGetSlots,
  requestLoadSlotPaid,
  requestLoadPaymentGetPaidSlots,
  requestLoadSlotCancelPaid,
  requestLoadPaymentGetSlotStateCalendarMonthInfos,
  requestLoadPaymentGetSlotStateCalendarDayInfos,
  requestLoadPaymentGetSearchInfo,
  requestLoadPaymentGetSlotIds,
  requestLoadSlotGetRankInfos,
  requestLoadSlotCancelDelete,
  requestLoadSlotRequestEtcDo1ProblemReset,
} from './slot';
import { requestLoadPartnerGetNames } from './user';
// all_force_test_click_allow_chk_false
const useMutations = (submit) => {
  const requestLoadSlotGetRankInfosMutation = useMutation(requestLoadSlotGetRankInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGetRankInfosMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadPaymentGetSlotIdsMutation = useMutation(requestLoadPaymentGetSlotIds, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadPaymentGetSlotIdsMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadPaymentGetSlotStateCalendarMonthInfosMutation = useMutation(
    requestLoadPaymentGetSlotStateCalendarMonthInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadPaymentGetSlotStateCalendarMonthInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadPaymentGetSlotStateCalendarDayInfosMutation = useMutation(
    requestLoadPaymentGetSlotStateCalendarDayInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadPaymentGetSlotStateCalendarDayInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadPaymentGetSearchInfoMutation = useMutation(requestLoadPaymentGetSearchInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadPaymentGetSearchInfoMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotCancelDeleteMutation = useMutation(requestLoadSlotCancelDelete, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotCancelDeleteMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotCancelPaidMutation = useMutation(requestLoadSlotCancelPaid, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotCancelPaidMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadPaymentGetPaidSlotsMutation = useMutation(requestLoadPaymentGetPaidSlots, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadPaymentGetPaidSlotsMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadPaymentGetSlotsMutation = useMutation(requestLoadPaymentGetSlots, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadPaymentGetSlotsMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotExtendsDayMutation = useMutation(requestLoadSlotExtendsDay, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotExtendsDayMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotAsExtendsDayMutation = useMutation(requestLoadSlotAsExtendsDay, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotAsExtendsDayMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadPartnerGetNamesMutation = useMutation(requestLoadPartnerGetNames, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadPartnerGetNamesMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotGroupSetMutation = useMutation(requestLoadSlotGroupSet, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupSetMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotGroupSetDateMutation = useMutation(requestLoadSlotGroupSetDate, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupSetDateMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotGroupExtendsDayMutation = useMutation(requestLoadSlotGroupExtendsDay, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupExtendsDayMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotGroupPaidMutation = useMutation(requestLoadSlotGroupPaid, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupPaidMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotGroupListMutation = useMutation(requestLoadSlotGroupList, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupListMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotSetGroupNameMutation = useMutation(requestLoadSlotSetGroupName, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotSetGroupNameMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotRequestSearchKeywordAgainMutation = useMutation(
    requestLoadSlotRequestSearchKeywordAgain,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadSlotRequestSearchKeywordAgainMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadSlotRequestEtcDo1ProblemResetMutation = useMutation(
    requestLoadSlotRequestEtcDo1ProblemReset,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadSlotRequestEtcDo1ProblemResetMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadSlotAddMutation = useMutation(requestLoadSlotAdd, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotAddMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotDelMutation = useMutation(requestLoadSlotDel, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotDelMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotSetMutation = useMutation(requestLoadSlotSet, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotSetMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotsSetMutation = useMutation(requestLoadSlotsSet, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotsSetMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotListMutation = useMutation(requestLoadSlotList, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotListMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotGroupUseMutation = useMutation(requestLoadSlotGroupUse, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupUseMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotUseMutation = useMutation(requestLoadSlotUse, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotUseMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotPaidMutation = useMutation(requestLoadSlotPaid, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotPaidMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadSlotGroupSetOwnerMutation = useMutation(requestLoadSlotGroupSetOwner, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupSetOwnerMutation',
        success: true,
        result: data,
      });
    },
  });

  return {
    requestLoadSlotExtendsDayMutation,
    requestLoadPartnerGetNamesMutation,
    requestLoadSlotSetGroupNameMutation,
    requestLoadSlotGroupListMutation,
    requestLoadSlotGroupSetMutation,
    requestLoadSlotAddMutation,
    requestLoadSlotRequestSearchKeywordAgainMutation,
    requestLoadSlotListMutation,
    requestLoadSlotGroupUseMutation,
    requestLoadSlotUseMutation,
    requestLoadSlotGroupSetOwnerMutation,
    requestLoadSlotGroupSetDateMutation,
    requestLoadSlotGroupPaidMutation,
    requestLoadSlotGroupExtendsDayMutation,
    requestLoadSlotDelMutation,
    requestLoadSlotsSetMutation,
    requestLoadSlotSetMutation,
    requestLoadSlotAsExtendsDayMutation,
    requestLoadPaymentGetSlotsMutation,
    requestLoadSlotPaidMutation,
    requestLoadSlotCancelPaidMutation,
    requestLoadSlotCancelDeleteMutation,
    requestLoadPaymentGetPaidSlotsMutation,
    requestLoadPaymentGetSearchInfoMutation,
    requestLoadPaymentGetSlotIdsMutation,
    requestLoadSlotGetRankInfosMutation,
    requestLoadSlotRequestEtcDo1ProblemResetMutation,
    requestLoadPaymentGetSlotStateCalendarMonthInfosMutation,
    requestLoadPaymentGetSlotStateCalendarDayInfosMutation,
  };
};

export default useMutations;
