import { requestLoadPaymentGetSlotIds } from 'Common/Service/slot';
import { Box } from 'Common/UI/atoms';
import { currencyComma } from 'Common/Utils';
import RankModule from 'Page/Product/Module/RankModule';
import RankLogGraph from 'Page/Product/RankLogGraph';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const MallInfo = ({ mall_names = null, graph_chk = null }) => {
  const [count, setCount] = useState();
  const [rank, setRank] = useState(0);
  const [lastRank, setLastRank] = useState(0);
  const [rankCount, setRankCount] = useState(0);
  const [lastRankCount, setLastRankCount] = useState(0);
  const { mallNames } = useParams();
  const [malls, setMalls] = useState(mall_names);
  const [categoryInfos, setCategoryInfos] = useState([]);
  const get_mall_infos = async () => {
    var shop_infos;
    shop_infos = await requestLoadPaymentGetSlotIds({ mall_names: malls });
    var category_shop_infos = {};
    setCount(shop_infos?.length);
    var all_rank_sum = 0;
    var all_rank_count = 0;
    var all_last_rank_sum = 0;
    var all_last_rank_count = 0;
    shop_infos?.forEach((e) => {
      const categoy_name = e.category1;
      if (!category_shop_infos[categoy_name])
        category_shop_infos[categoy_name] = {
          rank_count: 0,
          rank_sum: 0,
          last_rank_count: 0,
          last_rank_sum: 0,
          list: [],
        };
      if (e?.search_rank > 0) {
        all_rank_sum += e.search_rank;
        category_shop_infos[categoy_name].rank_sum += e.search_rank;
        all_rank_count += 1;
        category_shop_infos[categoy_name].rank_count += 1;
      }
      if (e?.search_last_rank > 0) {
        all_last_rank_sum += e.search_last_rank;
        category_shop_infos[categoy_name].last_rank_sum += e.search_last_rank;
        all_last_rank_count += 1;
        category_shop_infos[categoy_name].last_rank_count += 1;
      }

      category_shop_infos[categoy_name].list.push(e);
    });
    setRank(parseInt(all_rank_sum / all_rank_count));
    setLastRank(parseInt(all_last_rank_sum / all_last_rank_count));
    setRankCount(all_rank_count);
    setLastRankCount(all_last_rank_count);
    setCategoryInfos(category_shop_infos);
  };
  useEffect(() => {
    if (mallNames) {
      setMalls(mallNames);
    }
  }, [mallNames]);
  useEffect(() => {
    if (malls) {
      get_mall_infos();
    }
  }, [malls]);
  return (
    <Block>
      <Box column>
        <Box>
          <Box>
            <TitleText>
              {malls === 'all' ? '전체' : malls} ({currencyComma(count)} 상품){' '}
              <RankModule keyword={'keyword'} rank={rank} last_rank={lastRank} />{' '}
            </TitleText>
          </Box>
          {/* <Box valign={'bottom'}>
            {rankCount - lastRankCount > 0 ? <>오늘추가 ({rankCount - lastRankCount})</> : null}
          </Box> */}
        </Box>
        <Box column>
          {Object.keys(categoryInfos).map((category_name) => {
            return (
              <Box key={category_name}>
                {category_name} {categoryInfos[category_name]?.['list'].length}개 -
                {/* {categoryInfos[category_name]?.['rank_sum']},
                {categoryInfos[category_name]?.['rank_count']},
                {categoryInfos[category_name]?.['last_rank_sum']},
                {categoryInfos[category_name]?.['last_rank_count']} */}
                <RankModule
                  keyword={'keyword'}
                  rank={
                    categoryInfos[category_name]?.['rank_sum'] /
                    categoryInfos[category_name]?.['rank_count']
                  }
                  last_rank={
                    categoryInfos[category_name]?.['last_rank_sum'] /
                    categoryInfos[category_name]?.['last_rank_count']
                  }
                />
                {/* {categoryInfos[category_name]?.['rank_count'] -
                  categoryInfos[category_name]?.['last_rank_count'] >
                0 ? (
                  <>
                    오늘추가 (
                    {categoryInfos[category_name]?.['rank_count'] -
                      categoryInfos[category_name]?.['last_rank_count']}
                    )
                  </>
                ) : null} */}
              </Box>
            );
          })}
        </Box>
        {graph_chk && malls !== 'all' && (
          <Box>
            <Box column>
              {Object.keys(categoryInfos).map((category_name) => {
                const infos = categoryInfos[category_name]?.['list'];
                return (
                  <React.Fragment key={category_name}>
                    <Box>
                      <CategoryText>
                        {category_name} - 상품: {infos.length}개
                      </CategoryText>
                    </Box>
                    {infos.map((info) => {
                      return (
                        <Box top={30} width={1000} height={500}>
                          <RankLogGraph id={info.id} showInfoChk />
                        </Box>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Block>
  );
};
const Block = styled.div`
  /* display: flex; */
  /* margin-top: 30rem; */
  gap: 20;
`;
const TitleText = styled.div`
  /* padding-top: 0rem; */
  font-size: 20rem;
  margin: 10rem;
  font-weight: 800;
  color: red;
`;
const CategoryText = styled.div`
  padding-top: 30rem;
  font-size: 16rem;
  margin: 10rem;
  font-weight: 800;
  color: blue;
`;
const RankText = styled.button`
  font-size: 13rem;
  font-weight: 700;
  b {
    color: red;
  }
  small {
    color: blue;
  }
`;
export default MallInfo;
