import { requestLoadWorkRefilterUrlTypeGetInfos } from 'Common/Service/common';
import useMutations from 'Common/Service/userMutations';
import { Button, DropBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { getAlphaAndNumber } from 'Common/Utils';
import { TextFail, TextSmall, TextSucceed, debounce, reload_second } from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import WorkRefilterUrlFetchAccessLog from './WorkRefilterUrlTypeFetchAccessLog';

const WorkRefilterUrlTypeFetch = () => {
  const [userNameStatus, setUserNameStatus] = useState({ status: 'asdf' });
  const [result, setResults] = useState({});
  const [urlTypeLogId, setUrlTypeLogId] = useState();
  const [urlTypeInfos, setUrlTypeInfos] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);
  // const [count, setCount] = useState(reload_second);

  const location = useLocation();
  const [partnerNames, setPartnerNames] = useState([
    // { title: '미분류', value: null, selected: true },
  ]);

  // const dispatch = useDispatch();
  const { url_type } = useParams();
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCount((prevCount) => {
  //       var ct = prevCount - 1;
  //       if (ct < 0) {
  //         getUrlTypeInfos();
  //         ct = reload_second;
  //       }
  //       return ct;
  //     });
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);
  const getUrlTypeInfos = async () => {
    if (!url_type) return;
    const url_type_r = url_type?.replaceAll('_backslash_', '/')?.replaceAll('_question_', '?');

    const infos = await requestLoadWorkRefilterUrlTypeGetInfos({ url_type: url_type_r });
    setUrlTypeInfos(infos);
  };
  useEffect(() => {
    setUrlTypeInfos([]);
    getUrlTypeInfos();
  }, [url_type]);

  return (
    <>
      {/* <Wrapper> */}
      <Grid column={['500rem', '500rem']} gap={32} valign="top" height="1000">
        {/* <Box column> */}
        <Box column gap={24}>
          {urlTypeInfos?.map((e, index) => {
            return (
              <UrlInfoBox
                key={index}
                onClick={() => {
                  setUrlTypeLogId(e.id);
                }}
              >
                <Box valign="top" width="100%" gap={12}>
                  <Box>등록일</Box>
                  <Box>
                    {e?.datetime} ({e?.datetime_ago_hour} 시간 전)
                    {/* <TextSmall>{count}</TextSmall> */}
                  </Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>고유번호</Box>
                  <Box>{e?.id}</Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>[전체] 성공 횟수</Box>
                  <Box>
                    <TextSucceed>{e?.all_succeed_count}</TextSucceed>
                  </Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>[전체] 최종 실패 횟수</Box>
                  <Box>
                    <TextFail>{e?.all_fail_count}</TextFail>
                  </Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>[전체] 일반 시도 포함 실패 횟수</Box>
                  <Box>
                    {' '}
                    <TextFail>{e?.all_access_fail_count}</TextFail>
                  </Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>자동 필터링 카운터</Box>
                  <Box>
                    {e?.check_error_count} / {e?.check_error_count_max}{' '}
                    <TextButton>[강제중지]</TextButton>
                  </Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>최근 10분 최종 성공</Box>
                  <Box>{e?.succeed_count_10}</Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>최근 10분 최종 실패</Box>
                  <Box>{e?.fail_count_10}</Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>최근 30분 최종 성공</Box>
                  <Box>{e?.succeed_count_30}</Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>최근 30분 최종 실패</Box>
                  <Box>{e?.fail_count_30}</Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>최근 2시간 최종 성공</Box>
                  <Box>{e?.succeed_count_120}</Box>
                </Box>
                <Box valign="top" width="100%" gap={12}>
                  <Box>최근 2시간 최종 실패</Box>
                  <Box>{e?.fail_count_120}</Box>
                </Box>
              </UrlInfoBox>
            );
          })}
        </Box>
        {/* </Box> */}
        {/* <Box column> */}
        <Box valign="top" column>
          <Box column gap={30}>
            <WorkRefilterUrlFetchAccessLog refilter_header_id={urlTypeLogId} />
            {/* WorkUrlFetch */}
            {/* {[1, 2, 3, 4].map((e, index) => {
              return (
                <Box column key={index}>
                  <Box>
                    <Box>[성공]</Box>
                    <Box>2024-22-12</Box>
                  </Box>
                  <Box>
                    <Box>검색어</Box>
                    <Box>오야르 쇼핑몰 여자가방</Box>
                  </Box>
                  <Box>
                    <Box>URL</Box>
                    <Box>https://</Box>
                  </Box>
                  <Box>[오야르] 제목</Box>
                  <Box>
                    <Box>아이피</Box>
                    <Box>KR - 123.123123.123</Box>
                  </Box>
                  <Box>
                    <Box>접속기기</Box>
                    <Box>[삼성] sm-3334f</Box>
                  </Box>
                  <Box>
                    <Box>브라우저</Box>
                    <Box>안드로이드-크롬</Box>
                  </Box>
                </Box>
              );
            })} */}
          </Box>
        </Box>
        {/* </Box> */}
      </Grid>
      {/* </Wrapper> */}
    </>
  );
};
const UrlInfoBox = styled.button`
  &:hover {
    color: var(--P_700);
    &::after {
      background-color: var(--P_700);
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;
  color: #18795a;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 25rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { isError } = props;
    if (isError)
      return `
      color: #f00000;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 

      // background-color: #bc340b;
          
      `;
  }}
`;
export default WorkRefilterUrlTypeFetch;
