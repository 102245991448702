import useTab from 'Common/Hooks/useTab';
import { Box, Grid } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { requestLoadWorkBasicUrlTypeGetBrowserTypes } from 'Common/Service/common';
import WorkBasicUrlTypeFetch from './WorkBasicUrlTypeFetch';
import { TextSmall, reload_second } from 'Common/Utils/util';
import WorkBasicUrlTypeRequired from './WorkBasicUrlTypeRequired';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const WorkBasicUrlType = () => {
  const location = useLocation();

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const [menuItems, setMenuItems] = useState();
  const [browserTypeInfos, setBrowserTypeInfos] = useState();
  const [browserTypeInfo, setBrowserTypeInfo] = useState({});
  const { current, onClickLocation } = useTab(location.pathname, true);
  const [count, setCount] = useState(reload_second);

  const getBrowserType = async () => {
    const browser_type_infos = await requestLoadWorkBasicUrlTypeGetBrowserTypes({});
    setBrowserTypeInfos(browser_type_infos?.url_basic_types);
    const menuItems_url_basic_type_required = {
      title: `Required (${browser_type_infos?.url_basic_type_requireds?.length})`,
      value: `/work/basic_url_type/url_type_required`,
    };
    var menuItems_url_basic_type = browser_type_infos?.url_basic_types?.map((e) => {
      const browser_type = e.browser_type;
      return {
        title: `${e.browser_type} (${e.count})`,
        value: `/work/basic_url_type/${browser_type}`,
      };
    });
    // if (!menuItems_url_basic_type) menuItems_url_basic_type = [];
    setMenuItems([menuItems_url_basic_type_required, ...menuItems_url_basic_type]);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getBrowserType();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getBrowserType();
  }, []);
  return (
    <>
      <Box bottom={-44}>
        work_add_url_rules <TextSmall>{count}</TextSmall>
      </Box>
      <Grid row={['auto', 1]} gap={32}>
        <TabComponent
          current={current}
          onClick={(url) => {
            onClickLocation(url);
            const list = url.split('/');
            const browser_type = list[list.length - 1];
            var browser_type_info = browserTypeInfos?.find((e) => e?.browser_type === browser_type);
            if (!browser_type_info) browser_type_info = {};
            setBrowserTypeInfo(browser_type_info);
          }}
          items={menuItems}
        />
        <Routes>
          {/* <Route path={'/'} element={<WorkUrlFetch />} /> */}
          <Route path={'/url_type_required'} element={<WorkBasicUrlTypeRequired />} />
          <Route path={'/url_type_required/:browser_type'} element={<WorkBasicUrlTypeRequired />} />
          <Route path={'/:browser_type'} element={<WorkBasicUrlTypeFetch {...browserTypeInfo} />} />
          {/* <Route path={'/'} element={<UserAdd />} /> */}
        </Routes>
      </Grid>
    </>
  );
};
export default WorkBasicUrlType;
