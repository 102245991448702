import React, { useState } from 'react';
import DatepickerReact from './components/DatepickerReact';
import { formatDate } from 'Common/Utils/date';
import Icon from '../../atoms/IconBox';
import { useEffect } from 'react';
import { useRef } from 'react';
import InputComponent from '../../atoms/Input/InputComponent';
function RangepickerCompoent({ onChange, name, date, className, placeholder, big, disabled }) {
  const [startDate, setStartDate] = useState(new Date(date?.startDate));
  const [endDate, setEndDate] = useState(new Date(date?.endDate));
  const ref = useRef();
  const initState = (start, end) => {
    if (onChange)
      onChange(name, {
        startDate: start,
        endDate: end,
      });
  };
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    initState(start, end);
  };

  const setNextMonth = () => {
    const monthContainer = document.querySelectorAll('.react-datepicker__month-container');
    for (let i = 0; i < monthContainer.length; i++) {
      const header = monthContainer[i].querySelector('.headerDate');
      const month = monthContainer[i].querySelector('.react-datepicker__month');
      const monthData = month.getAttribute('aria-label');
      header.innerText = formatDate(new Date(monthData)).yearMonthKo;
    }
  };
  const getDateValue = () => {
    const start = startDate ? `${formatDate(startDate).completeDate} ~ ` : '';
    const end = endDate ? formatDate(endDate).completeDate : '';
    return start + end;
  };
  useEffect(() => {
    setTimeout(() => setNextMonth());
  }, [startDate]);

  useEffect(() => {
    if (date.startDate) {
      setStartDate(new Date(date?.startDate));
    }
    if (date.endDate) {
      setEndDate(new Date(date?.endDate));
    }
  }, [date]);

  return (
    <InputComponent
      value={getDateValue()}
      className={className}
      name={name}
      placeholder={placeholder ? placeholder : '기간을 선택하세요'}
      disabled={disabled}
      readOnly
      rightIcon={
        <DatepickerReact
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDate}
          monthsShown={2}
          className={'multipleMonth'}
          onOpen={setNextMonth}
          onMonthChange={setNextMonth}
          selectsRange
          big={big}
          ref={ref}
          disabled={disabled}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            return (
              <div className="custormHeader">
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  <Icon.Arrow size="xxs" direction={'left'} />
                </button>
                <span className="headerDate">{formatDate(date).yearMonthKo}</span>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <Icon.Arrow size="xxs" direction={'right'} />
                </button>
              </div>
            );
          }}
        />
      }
    />
  );
}

RangepickerCompoent.defaultProps = {};
export default RangepickerCompoent;
