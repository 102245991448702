import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import { Table, Tbody, THeader } from 'Common/UI/organisms/FormTabel/styeld';
import { Box, InputComponent, TextButton } from 'Common/UI/atoms';
import useMutations from 'Common/Service/commonMutations';

import { useSelector } from 'react-redux';
import LabelBox from 'Common/UI/molecules/LabelBox';
import moment from 'moment';
import Highlight from 'react-highlighter';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import { getWeekColorStr, getWeekStr } from 'Common/Utils';
import PaginationBox from 'Page/Module/PaginationBox';
import { today_str } from 'Common/Utils/util';

const IssueLog = ({
  start_date,
  end_date,
  setAllSlotcount,
  setTestSlotCount,
  setDeleteSlotCount,
  setAsExtendSlotCount,
}) => {
  const [result, setResults] = useState({});
  const [searchWord, setSearchWord] = useState('');
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);
  const [modal, setModal] = useState({
    title: '',
    successText: '삭제',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, isActive: false });
    },
  });

  useEffect(() => {
    if (result?.request === 'requestLoadGetSlotLogsMutation') {
      if (result?.success && result?.result !== undefined) {
        setAllList(result?.result);
      }
    }
    setResults({});
  }, [result.request]);

  const { requestLoadGetSlotLogsMutation } = useMutations(setResults);
  useEffect(() => {
    setList([]);
    requestLoadGetSlotLogsMutation.mutate({
      start_date: today_str(start_date),
      end_date: today_str(end_date),
    });
  }, [start_date, end_date]);
  // if (loginInfo.user_level <= 2) return <></>;
  return (
    <Box valign={'top'} column>
      <TableComponent
        onSearch={null}
        isController={false}
        isHeaad={false}
        allCheckbox={true}
        isInterval={false}
        searchText={null}
      >
        <LabelBox row label="검색">
          <Box fill top={8}>
            <InputComponent
              value={searchWord}
              onChange={(e) => {
                setSearchWord(e.target.value?.trim());
              }}
            />
          </Box>
        </LabelBox>

        <Box top={8} />
        <PaginationBox
          // checkList={checkList}
          // setCheckList={setCheckList}
          setList={setList}
          allList={allList}
        />

        <Table>
          <colgroup>
            <col style={{ width: '50rem' }} />
            {/* <col style={{ width: '120rem' }} /> */}
            {/* <col style={{ width: '120rem' }} /> */}
            <col style={{ width: 'auto' }} />
            <col style={{ width: '200rem' }} />
          </colgroup>
          <THeader>
            <tr>
              <th rowSpan={'1'}>&nbsp;</th>
              {/* <th rowSpan={'1'}>유저</th> */}
              {/* <th rowSpan={'1'}>분류</th> */}
              <th rowSpan={'1'}>내역</th>
              <th rowSpan={'1'}>시간</th>
            </tr>
          </THeader>
          <Tbody>
            <React.Fragment>
              {list
                ?.filter((e) => {
                  if (searchWord === '') return true;
                  const date = e.date;
                  const week_str = getWeekStr(moment(e.date)?.format('ddd'));
                  if (e.user_name?.indexOf(searchWord) >= 0) return true;
                  if (e.status?.indexOf(searchWord) >= 0) return true;
                  if (week_str?.indexOf(searchWord) >= 0) return true;
                  if (date?.indexOf(searchWord) >= 0) return true;
                  return false;
                })
                ?.map((e, idx) => {
                  return (
                    <TrBlock key={idx} closeChk={e.close_product_chk} isCancel={e?.isCancel}>
                      <td>{idx + 1}</td>
                      {/* <td>
                        <Highlight search={searchWord}>{e.user_name}</Highlight>
                      </td> */}
                      {/* <td>
                        {e.user_level >= 4 ? '관리자' : e.uesr_level === 3 ? '2차 대행사' : '유저'}
                      </td> */}
                      <td>
                        <StatusText>
                          <Highlight search={searchWord}>{e.status}</Highlight>
                        </StatusText>
                      </td>
                      <td>
                        (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getWeekColorStr(moment(e.date)?.format('ddd')),
                          }}
                        />
                        ) <Highlight search={searchWord}>{e?.datetime}</Highlight>
                      </td>
                    </TrBlock>
                  );
                })}
            </React.Fragment>
          </Tbody>
        </Table>
      </TableComponent>
      <ModalQuestion {...modal} />
    </Box>
  );
};
const TrBlock = styled.tr`
  height: 50rem;
  &:hover {
    background-color: #f0f0f0;
    ${(props) => {
      const { isCancel } = props;
      if (isCancel) {
        return css`
          text-decoration: line-through;
          background-color: rgb(255, 0, 0);
          opacity: 0.5;
        `;
      }
    }}
  }
`;
const ButtonBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-height: 50rem;
  padding: 5rem;
  padding-left: 10rem;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const StatusText = styled.div`
  text-align: left;

  font-size: 14rem;
  display: inline-block;
  padding-left: 10rem;
  gap: 12rem;
  width: 100%;
`;
export default IssueLog;
