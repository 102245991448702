import { valueSet } from "Common/Utils/util";
import React, { forwardRef } from "react";
import styled from "styled-components";

const Box = forwardRef(
    (
        {
            top,
            bottom,
            left,
            className,
            width,
            height,
            right,
            valign,
            style,
            gap = 4,
            align,
            column,
            children,
            padding,
            margin,
            fill,
        },
        ref
    ) => {
        const horizon = {
            left: "flex-start",
            right: "flex-end",
            center: "center",
            side: "space-between",
        };

        const vertical = {
            top: "flex-start",
            middle: "center",
            bottom: "flex-end",
            side: "space-between",
        };

        return (
            <Wrapper
                className={className}
                styles={style}
                gap={valueSet(gap)}
                align={horizon[align]}
                valign={vertical[valign]}
                top={valueSet(top)}
                pd={valueSet(padding)}
                mg={valueSet(margin)}
                bottom={valueSet(bottom)}
                left={valueSet(left)}
                right={valueSet(right)}
                column={column}
                sizeX={valueSet(width)}
                sizeY={valueSet(height)}
                ref={ref}
                setFill={fill}
            >
                {children}
            </Wrapper>
        );
    }
);

const Wrapper = styled.div`
    ${({
        gap,
        align,
        valign,
        sizeX,
        sizeY,
        top,
        bottom,
        styles,
        left,
        right,
        column,
        setFill,
        pd,
        mg,
    }) => `
            display:flex;
            ${column ? "flex-direction:column" : "align-items:center"};
            ${
                align
                    ? column
                        ? `align-items:${align};`
                        : `justify-content:${align};`
                    : ""
            }
            ${
                valign
                    ? !column
                        ? `align-items:${valign};`
                        : `justify-content:${valign};`
                    : ""
            }
            ${sizeX ? `width:${sizeX};` : ""}
            ${sizeY ? `height:${sizeY};` : ""}
            ${mg ? `margin:${mg};` : ""}
            ${top ? `margin-top:${top};` : ""}
            ${bottom ? `margin-bottom:${bottom};` : ""}
            ${pd ? `padding:${pd};` : ""}
            ${left ? `padding-left:${left};` : ""}
            ${right ? `padding-right:${right};` : ""}
            box-sizing:border-box;
            position:relative;
            gap:${gap};
            ${setFill ? "flex:1;" : ""}
            ${styles || ""}
            `}
`;

export default Box;
