import { useSearch } from "Common/Hooks";
import React, { useEffect, useRef, useState } from "react";
import DropList from "../atoms/DropList";
import Icon from "../atoms/IconBox";
import InputComponent from "../atoms/Input/InputComponent";
import { InputWrapper } from "../atoms/Input/styled";

const DropInput = ({
    name,
    onSearch,
    searchValue,
    size,
    value,
    option = [],
    onChange,
    isSearch = true,
    placeholder,
    disabled,
    isAdd,
    onAdd,
    readOnly,
    onClickAdd,
    leftIcon,
    isKeyControll = true,
    arrow,
    className,
}) => {
    const [isActive, setIsActive] = useState();
    const ref = useRef();
    const {
        searchValue: setValue,
        onSearch: setSearch,
        searchMatching, // search === data matching
        searchSlice, // data <span>search</span>
    } = useSearch();

    useEffect(() => {
        isSearch
            ? setSearch && setSearch({ target: { name: "search", value } })
            : onSearch && onSearch({ target: { name: "search", value } });
    }, [value]);

    const optionFilter = isSearch
        ? option
              ?.filter(({ title }) => readOnly || searchMatching(title))
              .map(({ title, value }) => {
                  return {
                      title: readOnly ? title : searchSlice(title),
                      value,
                  };
              })
        : option;
    const propsRef = useRef();
    return (
        <InputWrapper ref={ref} className={className}>
            <InputComponent
                autocomplete
                leftIcon={leftIcon}
                ref={propsRef}
                size={size}
                onClick={() => setIsActive(true)}
                placeholder={placeholder}
                value={isSearch ? setValue : searchValue}
                name="search"
                onChange={isSearch ? setSearch : onSearch}
                disabled={disabled}
                readOnly={readOnly}
                rightIcon={arrow ? <Icon.Arrow size={20} /> : null}
            />
            <DropList
                onClick={onChange}
                propsRef={propsRef}
                name={name}
                onClose={() => setIsActive(false)}
                isActive={isActive}
                option={optionFilter}
                isAdd={isAdd}
                onAddText={onAdd}
                onClickAdd={onClickAdd}
                parent={ref.current}
                isKeyControll={isKeyControll}
            />
        </InputWrapper>
    );
};

export default DropInput;
