import { Box, Icon, IconButton } from "Common/UI/atoms";
import DragModal from "Common/UI/molecules/DragModal";
import React from "react";
import FormSelectItem from "./FormSelectItem";
import { TagWrapper } from "./styled";

function FormBadgeModal({
    isModal,
    isEdit,
    onToggleModal,
    onDelete,
    maxLength,
    items,
}) {
    return (
        <TagWrapper isActive={isModal}>
            <Box>
                <IconButton Theme="line" size="xs" onClick={onToggleModal}>
                    <Icon.More size="sm" />
                </IconButton>
                {isModal && (
                    <Box
                        width={300}
                        style={`position:absolute;left:calc(100% + 8rem);`}
                    >
                        <DragModal
                            // title={
                            //     isEdit && (
                            //         <CheckBox
                            //             onChange={onClickAll}
                            //             checked={isAll}
                            //         >
                            //             전체 선택
                            //         </CheckBox>
                            //     )
                            // }
                            isActive={true}
                            cancelText={isEdit ? "삭제" : false}
                            // onCancel={() => deleteArray()}
                            className={"dragModal"}
                        >
                            {items.map((i, idx) => (
                                <FormSelectItem
                                    isEdit={isEdit}
                                    // checkedIds={selectedId}
                                    // onClick={onClick}
                                    onDelete={() =>
                                        onDelete(
                                            i?.value || i?.idx + maxLength + 1
                                        )
                                    }
                                    key={idx}
                                    title={i.title}
                                />
                            ))}
                        </DragModal>
                    </Box>
                )}
            </Box>
        </TagWrapper>
    );
}

export default FormBadgeModal;
