import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
function TestTable() {
  return (
    <>
      <Table>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
        <tr>
          <td>asdfasdfasdf</td>
        </tr>
      </Table>
    </>
  );

  // const TestTable = styled.table``;
}
const Table = styled.table`
  overflow-y: scroll;
  overflow: scroll;
  width: 500rem;
  height: 400rem;

  /* overflow: hidden; */
  background-color: red;
`;
export default TestTable;
