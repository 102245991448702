import { fileAcceptTypes } from "Common/Utils/file";
import React from "react";
import { FileWrapper } from "./styled";

function FileInput({ onChange, multiple = true, accept }) {
    const acceptFilter = fileAcceptTypes(accept);
    return (
        <FileWrapper
            type="file"
            onChange={onChange}
            multiple={multiple}
            accept={acceptFilter}
        />
    );
}

export default FileInput;
