import { Box } from 'Common/UI/atoms';
import Title from 'Common/UI/atoms/Title';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React from 'react';
import styled from 'styled-components';

const MainListSetting = ({
  width,
  columns,
  children,
  title,
  items,
  topContent,
  current,
  onClickTab,
}) => {
  const valueSet = (target) => {
    const type = typeof target;
    const array = Array.isArray(target);
    let style = '';
    array
      ? target.map((i) => {
          return (style += typeof i === 'number' ? i + 'rem ' : i + ' ');
        })
      : (style += type === 'number' ? `${target}rem` : target);
    return target === undefined ? target : style;
  };
  return (
    <Wrapper columns={columns} sizeX={valueSet(width)}>
      {title && (
        <Box height={36}>
          <Title size={'xxs'}>{title}</Title>
        </Box>
      )}
      {items && (
        <TabComponent
          background={`White`}
          current={current}
          padding={0}
          fontSize={16}
          onClick={onClickTab}
          style={`background-color:var(--White);`}
          items={items?.map((i, key) => ({ title: i, value: key }))}
        />
      )}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ columns, sizeX }) => `
    ${sizeX ? `width:${sizeX};` : ''}
    height:100%;
    ${columns ? `grid-column:${columns};` : ''}
    display:flex;
    flex-direction:column;
    gap:20rem;
    background-color:var(--White);
    border-radius:12rem;
    box-shadow:var(--Elevation_001);
    padding:32rem;
`}
`;

export default MainListSetting;
