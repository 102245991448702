import LabelBox from "Common/UI/molecules/LabelBox";
import React from "react";
import { Wrapper } from "./styled";

function Address({ label, children, row, fontSize }) {
    return (
        <Wrapper>
            <LabelBox fontSize={fontSize} row={row} label={label}>
                {children}
            </LabelBox>
        </Wrapper>
    );
}

export default Address;
