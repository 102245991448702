import { requestLoadWorkRefilterUrlTypeGetLogs } from 'Common/Service/common';
// import useMutations from 'Common/Service/userMutations';
import { Button, CheckBox, DropBox, Grid, InputComponent } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import { TextFail, TextSucceed } from 'Common/Utils/util';
// import { useForceUpdate } from 'Common/Utils/util';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const WorkRefilterUrlTypeFetchAccessLog = ({ refilter_header_id = null }) => {
  const [log, setLogs] = useState([]);
  const [isSucceedTrue, setSucceedTrue] = useState(true);
  const [isSucceedFail, setSucceedFail] = useState(true);
  useEffect(() => {
    getUrlTypeInfoLogs();
  }, [refilter_header_id]);

  const getUrlTypeInfoLogs = async () => {
    const infos = await requestLoadWorkRefilterUrlTypeGetLogs({ refilter_header_id });
    setLogs(infos);
  };
  // const forceUpdate = useForceUpdate();
  useEffect(() => {
    getUrlTypeInfoLogs();
  }, [refilter_header_id]);
  if (!refilter_header_id) return;
  //   useEffect(() => {
  //     const user_type =
  //       location.pathname === '/user/' || location.pathname === '/user' ? 'user' : 'partner';
  //     setAddUserType(user_type);
  //     if (user_type === 'partner' && loginInfo.user_level === 3) {
  //       setPartnerName(loginInfo.user_name);
  //     }
  //     setUserNameStatus({});
  //     setUserInfo({ user_type });
  //     requestLoadPartnerGetNamesMutation.mutate({});
  //   }, [location.pathname]);
  return (
    <>
      <Box>
        <CheckBox
          checked={isSucceedTrue}
          onChange={() => {
            setSucceedTrue(!isSucceedTrue);
          }}
        >
          <TextSucceed>Succeed Logs</TextSucceed>
        </CheckBox>
        <CheckBox
          checked={isSucceedFail}
          onChange={() => {
            setSucceedFail(!isSucceedFail);
          }}
        >
          <TextFail>Problem Logs</TextFail>
        </CheckBox>
      </Box>
      {log
        ?.filter((e) => {
          if (e.is_succeed && isSucceedTrue) return true;
          else if (!e.is_succeed && isSucceedFail) return true;
          return false;
        })
        ?.map((e, index) => {
          return (
            <Box column key={index}>
              <Box>
                <Box>
                  [
                  {e.is_succeed ? <TextSucceed>Succeed</TextSucceed> : <TextFail>Problem</TextFail>}
                  ]
                </Box>
                <Box>
                  {e?.datetime} ({e.datetime_ago_sec} 초 전)
                </Box>
              </Box>
              <Box>
                <Box>해당 url 엑세스 시도 횟수</Box>
                <Box>{e?.access_try_count}</Box>
              </Box>
              <Box>
                <Box>마지막 엑세스 여부</Box>
                <Box>{e?.last_try_chk}</Box>
              </Box>
              <Box>
                <Box>검색어</Box>
                <Box>{e.keyword}</Box>
              </Box>
              <Box>
                <StatusText>{e.url}</StatusText>
                {/* <Box>https://</Box> */}
              </Box>
              <Box>
                [{e?.shop_link_mall_name}] {e.shop_link_title}
              </Box>
              <Box>
                <Box>아이피</Box>
                <Box>
                  {e?.ip_country} - {e?.ip_address}
                </Box>
              </Box>
              <Box>
                <Box>접속기기</Box>
                <Box>
                  [{e?.device_brand}] {e?.device_model}
                </Box>
              </Box>
              <Box>
                <Box>브라우저</Box>
                <Box>{e?.browser_type}</Box>
              </Box>
              <Box>
                <Box>유입 시도 횟수</Box>
                <Box>
                  {e?.etc_do1_shop_link_try_count} 성공({e?.etc_do1_shop_link_ok_count})
                </Box>
              </Box>
              <Box>
                <Box>위치</Box>
                <Box>{e?.from_step}</Box>
              </Box>
              <Box>
                <Box>전체 제제 횟수 (알반시도 포함)</Box>
                <Box>{e?.all_access_problem_count}</Box>
              </Box>
              <Box>
                <Box>전체 성공 횟수 (알반시도 포함)</Box>
                <Box>{e?.all_access_ok_count}</Box>
              </Box>
            </Box>
          );
        })}
    </>
  );
};
const UrlInfoBox = styled.button`
  &:hover {
    color: var(--P_700);
    &::after {
      background-color: var(--P_700);
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;
const StatusText = styled.div`
  text-align: left;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;
  /* color: #18795a; */
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 25rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { isError } = props;
    if (isError)
      return `
      color: #f00000;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 

      // background-color: #bc340b;
          
      `;
  }}
`;
export default WorkRefilterUrlTypeFetchAccessLog;
