import {
  requestLoadWorkRefilterUrlTypeGetInfos,
  requestLoadWorkRefilterUrlTypeGetNoDataLogInfos,
} from 'Common/Service/common';
import useMutations from 'Common/Service/userMutations';
import { Button, DropBox, Grid, InputComponent } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { getAlphaAndNumber } from 'Common/Utils';
import { debounce } from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import WorkRefilterUrlFetchAccessLog from './WorkRefilterUrlTypeFetchAccessLog';

const WorkRefilterUrlTypeFetchNewProblem = () => {
  const [noDataInfos, setNoDataInfos] = useState([]);
  const [noDataLogs, setNoDataLogs] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);

  // const dispatch = useDispatch();
  // const { url_type } = useParams();
  const getUrlTypeInfos = async () => {
    // const url_type_r = url_type?.replaceAll('_backslash_', '/')?.replaceAll('_question_', '?');

    const r = await requestLoadWorkRefilterUrlTypeGetNoDataLogInfos();
    const no_data_refilter_infos = r['no_data_refilter_infos'];
    // const no_data_logs = r['no_data_logs'];
    setNoDataInfos(no_data_refilter_infos);
    // setNoDataLogs(no_data_logs);
  };
  useEffect(() => {
    getUrlTypeInfos();
  }, []);

  return (
    <>
      {/* <Wrapper> */}
      <Grid column={['500rem', '500rem']} gap={32} valign="top" height="1000">
        {/* <Box column> */}
        <Box column gap={24}>
          {noDataInfos?.map((e, index) => {
            return (
              <UrlInfoBox
                key={index}
                // onClick={() => {
                //   setUrlTypeLogId(e.id);
                // }}
              >
                <Box valign="top" width="100%" gap={12}>
                  <Box>{e?.url_type}</Box>
                  <Box>{e?.count}</Box>
                </Box>
              </UrlInfoBox>
            );
          })}
        </Box>
        {/* </Box> */}
        {/* <Box column> */}
        <Box valign="top" column>
          <Box column gap={30}>
            <WorkRefilterUrlFetchAccessLog refilter_header_id={null} />
            {/* WorkUrlFetch */}
            {/* {[1, 2, 3, 4].map((e, index) => {
              return (
                <Box column key={index}>
                  <Box>
                    <Box>[성공]</Box>
                    <Box>2024-22-12</Box>
                  </Box>
                  <Box>
                    <Box>검색어</Box>
                    <Box>오야르 쇼핑몰 여자가방</Box>
                  </Box>
                  <Box>
                    <Box>URL</Box>
                    <Box>https://</Box>
                  </Box>
                  <Box>[오야르] 제목</Box>
                  <Box>
                    <Box>아이피</Box>
                    <Box>KR - 123.123123.123</Box>
                  </Box>
                  <Box>
                    <Box>접속기기</Box>
                    <Box>[삼성] sm-3334f</Box>
                  </Box>
                  <Box>
                    <Box>브라우저</Box>
                    <Box>안드로이드-크롬</Box>
                  </Box>
                </Box>
              );
            })} */}
          </Box>
        </Box>
        {/* </Box> */}
      </Grid>
      {/* </Wrapper> */}
    </>
  );
};
const UrlInfoBox = styled.button`
  &:hover {
    color: var(--P_700);
    &::after {
      background-color: var(--P_700);
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;
  color: #18795a;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  b {
    font-size: 25rem;
    font-weight: 1000;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { isError } = props;
    if (isError)
      return `
      color: #f00000;
      border-width: 1rem;
      border-style: solid;
      border-color: var(--P_300); 

      // background-color: #bc340b;
          
      `;
  }}
`;
export default WorkRefilterUrlTypeFetchNewProblem;
