const deviceSizes = {
  mobileS: '320rem',
  mobileM: '375rem',
  mobileL: '450rem',
  tablet: '768rem',
  tabletL: '1024rem',
};

const HeadLine = {
  h1: { fontSize: '48rem', lineHeight: '60rem' },
  h2: { fontSize: '32rem', lineHeight: '44rem' },
  h3: { fontSize: '24rem', lineHeight: '34rem' },
  h4: { fontSize: '20rem', lineHeight: '28rem' },
};

const categoryBadge = {
  sizeX: {
    xl: {
      width: '220rem',
    },
    lg: {
      width: '160rem',
    },
    md: {
      width: '120rem',
    },
    sm: {
      width: '88rem',
    },
  },
  sizeY: {
    lg: {
      height: '28rem',
      lineHeight: '28rem',
    },
    md: {
      height: '24rem',
      lineHeight: '24rem',
    },
  },
};

const title = {
  xxxl: {
    fontSize: '60rem',
    lineHeight: '74rem',
  },
  xxl: {
    fontSize: '40rem',
    lineHeight: '56rem',
  },
  xl: {
    fontSize: '36rem',
    lineHeight: '50rem',
  },
  lg: {
    fontSize: '32rem',
    lineHeight: '44rem',
  },
  md: {
    fontSize: '28rem',
    lineHeight: '36rem',
  },
  sm: {
    fontSize: '24rem',
    lineHeight: '34rem',
  },
  xs: {
    fontSize: '20rem',
    lineHeight: '28rem',
  },
  xxs: {
    fontSize: '16rem',
    lineHeight: '22rem',
  },
  xxxs: {
    fontSize: '14rem',
    lineHeight: '20rem',
  },
  xxxxs: {
    fontSize: '12rem',
    lineHeight: '16rem',
  },
};

const ellipsis = (line) => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    ['-webkit-line-clamp']: String(line),
    ['-webkit-box-orient']: 'vertical',
  };
};

const elevation = {
  Elevation__001: '0rem 0rem 3rem rgba(0, 0, 0, 0.04), 0rem 0rem 5rem rgba(0, 0, 0, 0.08)',
  Elevation__002: '0rem 0rem 3rem rgba(0, 0, 0, 0.04), 0rem 0rem 6rem rgba(0, 0, 0, 0.1);',
  Elevation__003: '0rem 0rem 3rem rgba(0, 0, 0, 0.04), 0rem 0rem 8rem rgba(0, 0, 0, 0.1);',
  Elevation__004: '0rem 0rem 3rem rgba(0, 0, 0, 0.04), 0rem 0rem 11rem rgba(0, 0, 0, 0.11)',
  Elevation__005: '0rem 0rem 3rem rgba(0, 0, 0, 0.04), 0rem 0rem 13rem rgba(0, 0, 0, 0.12)',
  Elevation__006: '0rem 0rem 5rem rgba(0, 0, 0, 0.06), 0rem 0rem 15rem rgba(0, 0, 0, 0.15)',
  Elevation__007: '0rem 0rem 5rem rgba(0, 0, 0, 0.06), 0rem 0rem 17rem rgba(0, 0, 0, 0.18)',
  Elevation__008: '0rem 0rem 5rem rgba(0, 0, 0, 0.08), 0rem 0rem 28rem rgba(0, 0, 0, 0.22)',
};
const colors = {
  Black: '#000',
  White: '#fff',

  G_900: '#1D1F2B',
  G_800: '#313644',
  G_700: '#444A57',
  G_600: '#565B69',
  G_500: '#686D78',
  G_400: '#787C87',
  G_300: '#888C94',
  G_200: '#A6A9AF',
  G_150: '#BBBCC0',
  G_100: '#CECFD2',
  G_75: '#DADBDD',
  G_50: '#EBEBED',
  G_25: '#F4F5F5',
  G_20: '#F9F9FB',

  P_900: '#0A196F',
  P_800: '#102587',
  P_700: '#0C2CCA',
  P_600: '#193DF0',
  P_500: '#3C69FC',
  P_400: '#6487FA',
  P_300: '#8CA5FF',
  P_200: '#B4C3FF',
  P_100: '#E6ECFF',
  P_75: '#E2E9FF',
  P_60: '#F0F3FF',
  P_50: '#F5F7FF',
  P_25: '#FAFBFF',
  P_10: '#FAFBFF',

  S_600: '#E05F16',
  S_500: '#FC6B19',
  S_400: '#FC8B4C',
  S_300: '#FDAC7F',
  S_200: '#FFF0E6',
  S_150: '#FFFAF8',
  S_100: '#F4EEE5',

  Success_600: '#248F34',
  Success_500: '#29A33B',
  Success_400: '#37B44A',
  Success_300: '#79D287',
  Success_250: '#81EE65',
  Success_200: '#F0F9F2',
  Success_100: '#EEF1EF',

  Error_600: '#A70606',
  Error_500: '#CE0808',
  Error_400: '#D83A3A',
  Error_300: '#E48181',
  Error_200: '#F5CECE',
  Error_100: '#F6EEEE',

  Warning_900: '#7A4D0B',
  Warning_800: '#936312',
  Warning_700: '#B7821D',
  Warning_600: '#DBA32A',
  Warning_500: '#FFC73A',
  Warning_400: '#FFD96B',
  Warning_300: '#FFE488',
  Warning_200: '#FFEFB0',
  Warning_100: '#FFF8D7',
};

const commonSize = {
  size80: 80,
  size76: 76,
  size72: 72,
  size68: 68,
  size64: 64,
  size60: 60,
  size56: 56,
  size52: 52,
  size48: 48,
  size44: 44,
  size40: 40,
  size36: 36,
  size32: 32,
  size28: 28,
  size24: 24,
  size20: 20,
  size16: 16,
  size12: 12,
  size8: 8,
  size4: 4,
};

const modal = {
  size: {
    sm: 500,
    md: 700,
    lg: 900,
    xl: 1100,
    xxl: 1400,
    xxxl: 1700,
    xxxxl: 1920,
  },
};
const status = {
  success: {
    color: colors.Success_500,
  },
  error: {
    color: colors.Error_500,
  },
  warning: {
    color: colors.Warning_500,
  },
};
const tag = {
  size: {
    size24: {
      height: '24rem',
      padding: '0 8rem',
    },
    size28: {
      height: '28rem',
      padding: '0 8rem',
    },
    size32: {
      height: '32rem',
      padding: '0 12rem',
    },
    size36: {
      height: '36rem',
      padding: '0 16rem',
    },
    size40: {
      height: '40rem',
      padding: '0 16rem',
    },
    size44: {
      height: '44rem',
      padding: '0 20rem',
    },
  },
};
const input = {
  theme: {
    line: {
      BorderColor: colors.G_100,
      hover: {
        background: colors.P_500,
      },
      focus: {
        background: colors.P_500,
      },
      disabled: {
        color: colors.G_200,
        background: colors.G_25,
        fill: colors.G_500,
      },
    },
  },
  focus: {
    color: colors.G_300,
    borderColor: colors.P_500,
  },
  success: {
    color: colors.success,
    borderColor: colors.Success_500,
    backgroundColor: colors.White,
  },
  error: {
    color: colors.Error_500,
    borderColor: colors.Error_500,
    backgroundColor: colors.White,
  },
  disabled: {
    backgroundColor: colors.G_25,
    borderColor: colors.G_100,
    color: colors.G_200,
  },
};
const textButton = {
  size: {
    xs: {
      height: '28rem',
      fontSize: '12rem',
    },
    sm: {
      height: '32rem',
      fontSize: '14rem',
    },
    md: {
      height: '36rem',
      fontSize: '16rem',
    },
    lg: {
      height: '38rem',
      fontSize: '18rem',
    },
    xl: {
      height: '42rem',
      fontSize: '20rem',
    },
  },
  theme: {
    normal: {
      color: colors.G_600,
      hover: {
        color: colors.P_500,
        background: colors.G_25,
      },
      active: {
        color: colors.P_800,
        background: colors.G_50,
      },
      focus: {
        color: colors.P_700,
      },
      disabled: {
        color: colors.P_150,
      },
    },
    primary: {
      color: colors.P_500,
      hover: {
        color: colors.P_600,
        background: colors.G_25,
      },
      active: {
        color: colors.P_800,
        background: colors.G_50,
      },
      focus: {
        color: colors.P_700,
      },
      disabled: {
        color: colors.G_150,
      },
    },
    error: {
      color: colors.Error_500,
      hover: {
        color: colors.Error_600,
        background: colors.G_25,
      },
      active: {
        color: colors.Error_800,
        background: colors.G_50,
      },
      focus: {
        color: colors.Error_700,
      },
      disabled: {
        color: colors.Error_150,
      },
    },
  },
};

const button = {
  size: {
    xxxs: {
      height: '24rem',
      fontSize: '10rem',
      padding: '0 4rem',
    },
    xxs: {
      height: '28rem',
      fontSize: '12rem',
      padding: '0 8rem',
    },
    xs: {
      height: '32rem',
      fontSize: '14rem',
      padding: '0 12rem',
    },
    sm: {
      height: '36rem',
      fontSize: '14rem',
      padding: '0 16rem',
    },
    md: {
      height: '40rem',
      fontSize: '14rem',
      padding: '0 20rem',
    },
    lg: {
      height: '44rem',
      fontSize: '14rem',
      padding: '0 20rem',
    },
    xl: {
      height: '48rem',
      fontSize: '14rem',
      padding: '0 24rem',
    },
    xxl: {
      height: '52rem',
      fontSize: '14rem',
      padding: '0 28rem',
    },
    xxxl: {
      height: '56rem',
      fontSize: '16rem',
      padding: '0 32rem',
    },
  },
  theme: {
    normal: {
      color: colors.White,
      background: colors.P_500,
      fill: colors.White,
      hover: {
        background: colors.P_600,
      },
      active: {
        background: colors.P_800,
      },
      focus: {
        background: colors.P_700,
      },
      disabled: {
        color: colors.G_150,
        background: colors.G_50,
        fill: colors.G_100,
      },
    },
    line: {
      borderColor: colors.G_150,
      color: colors.G_600,
      background: colors.White,
      fill: colors.G_600,
      hover: {
        color: colors.P_500,
        background: colors.G_25,
        borderColor: colors.G_150,
        fill: colors.P_500,
        boxShadow: elevation.Elevation__001,
      },
      active: {
        color: colors.P_800,
        background: colors.G_100,
        borderColor: colors.G_300,
        fill: colors.P_800,
      },
      focus: {
        color: colors.P_700,
        background: colors.G_75,
        borderColor: colors.G_300,
        fill: colors.P_700,
      },
      disabled: {
        background: colors.G_50,
        color: colors.G_150,
        fill: colors.G_100,
        borderColor: colors.G_100,
      },
    },
    unLine: {
      color: colors.G_600,
      background: colors.White,
      fill: colors.G_600,
      hover: {
        color: colors.P_500,
        background: colors.G_25,
        fill: colors.P_500,
        boxShadow: elevation.Elevation__001,
      },
      active: {
        color: colors.P_800,
        background: colors.G_100,
        fill: colors.P_800,
      },
      focus: {
        color: colors.P_700,
        background: colors.G_75,
        fill: colors.P_700,
      },
      disabled: {
        background: colors.G_50,
        color: colors.G_150,
        fill: colors.G_100,
      },
    },
    unFill: {
      color: colors.G_600,
      fill: colors.G_600,
      hover: {
        color: colors.P_500,
        fill: colors.P_500,
        boxShadow: elevation.Elevation__001,
      },
      active: {
        color: colors.P_800,
        fill: colors.P_800,
      },
      focus: {
        color: colors.P_700,
        fill: colors.P_700,
      },
      disabled: {
        color: colors.G_150,
        fill: colors.G_100,
      },
    },
    ghost: {
      borderColor: colors.G_150,
      color: colors.G_600,
      fill: colors.G_600,
      hover: {
        color: colors.P_500,
        background: colors.P_25,
        borderColor: colors.G_150,
        fill: colors.P_500,
        boxShadow: elevation.Elevation__001,
      },
      active: {
        color: colors.P_800,
        background: colors.P_50,
        borderColor: colors.P_800,
        fill: colors.P_800,
      },
      focus: {
        color: colors.P_700,
        background: colors.P_50,
        borderColor: colors.P_700,
        fill: colors.P_700,
      },
      disabled: {
        background: colors.G_50,
        color: colors.G_150,
        fill: colors.G_100,
        borderColor: colors.G_100,
      },
    },
    textButtonDefault: {
      color: colors.G_600,
      hover: {
        color: colors.P_500,
        BorderColor: colors.P_500,
        background: colors.G_25,
      },
      active: {
        color: colors.P_800,
        BorderColor: colors.P_800,
        background: colors.G_50,
      },
      focus: {
        color: colors.P_700,
        BorderColor: colors.P_700,
      },
      disabled: {
        color: colors.G_150,
        fill: colors.G_100,
      },
    },
    underLine: {
      color: colors.G_600,
      borderRadius: 0,
      borderBottomColor: colors.G_600,
      hover: {
        color: colors.P_500,
        borderBottomColor: colors.P_500,
      },
      active: {
        color: colors.P_800,
        borderBottomColor: colors.P_800,
      },
      focus: {
        color: colors.P_700,
        borderBottomColor: colors.P_700,
      },
      disabled: {
        color: colors.G_150,
        fill: colors.G_100,
        borderBottomColor: 'transparent',
      },
    },
    link: {
      color: colors.P_500,
      borderRadius: 0,
      borderBottomColor: colors.P_500,
      hover: {
        color: colors.P_600,
        borderBottomColor: colors.P_600,
      },
      active: {
        color: colors.P_800,
        borderBottomColor: colors.P_800,
      },
      focus: {
        color: colors.P_700,
        borderBottomColor: colors.P_700,
      },
      disabled: {
        color: colors.G_150,
        fill: colors.G_100,
        borderBottomColor: 'transparent',
      },
    },
    primary: {
      color: colors.P_500,
      borderRadius: 0,
      hover: {
        color: colors.P_600,
      },
      active: {
        color: colors.P_800,
      },
      focus: {
        color: colors.P_700,
      },
      disabled: {
        color: colors.G_150,
        fill: colors.G_100,
      },
    },
    tooltip: {
      color: colors.G_700,
      background: colors.P_75,
      fill: colors.P_75,
      hover: {
        color: colors.P_500,
        background: colors.P_60,
        fill: colors.P_75,
        boxShadow: elevation.Elevation__001,
      },
      active: {
        color: colors.P_800,
        background: colors.P_75,
        fill: colors.P_800,
      },
      focus: {
        color: colors.P_700,
        background: colors.P_75,
        fill: colors.P_700,
      },
      disabled: {
        background: colors.G_50,
        color: colors.G_150,
        fill: colors.G_100,
      },
    },
  },
};
const theme = {
  button,
  input,
  title,
  colors,
  HeadLine,
  ellipsis,
  textButton,
  categoryBadge,
  elevation,
  tag,
  commonSize,
  status,
  modal,
};

export default theme;
