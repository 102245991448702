import { requestLoadPaymentGetSlotStateCalendarDayInfos } from 'Common/Service/slot';
import { Box, CheckBox, Icon, IconButton, TextButton, TimeBar } from 'Common/UI/atoms';
import { currencyComma, getWeekColorStr } from 'Common/Utils';
import { TextSmall, TextSucceed, TextWarning, today_str, useForceUpdate } from 'Common/Utils/util';
// import { useQuery } from '@tanstack/react-query';
import { onShowToast } from 'features/common/commonSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
const ShowData = ({ user_level, data = [], color = '', title, isShowShopLinkId = null }) => {
  if (user_level >= 4)
    return (
      <ShowDataByAdmin
        data={data}
        color={color}
        title={title}
        isShowShopLinkId={isShowShopLinkId}
      />
    );
};
const ShowDataByAdmin = ({ data = [], color = '', title, isShowShopLinkId = null }) => {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    forceUpdate();
  }, [isShowShopLinkId]);
  if (!data?.count) return;

  return (
    <DayCell
      onClick={() => {
        if (data) {
          data['is_show'] = !data?.['is_show'];
        }
        forceUpdate();
      }}
    >
      <Box column>
        <TimeBar color={color}>
          {title} ({currencyComma(data?.count)})
        </TimeBar>

        <Box align="left" column left={20}>
          <TimeBar>2차 대행사</TimeBar>
          {data?.list?.map((e, index) => {
            const user_list = e?.user_list;
            return (
              <Box column left={8}>
                <Box>
                  <Box>
                    <TextSmall>{e?.partner_name} [2차대행사]</TextSmall>
                  </Box>
                  <Box>
                    <TextSmall>
                      <TextSucceed>{currencyComma(e?.count)}</TextSucceed>
                    </TextSmall>
                  </Box>
                </Box>
                {data['is_show'] ? (
                  <>
                    {/* <TimeBar color="Warning_700">유저</TimeBar> */}
                    <Box column>
                      {user_list?.map((user, index2) => {
                        return (
                          <Box key={index2} left={20} column>
                            <Box>
                              <Box>
                                <TextSmall>{user?.user_name} [유저]</TextSmall>
                              </Box>
                              <Box column>
                                <TextSmall>
                                  <TextWarning>{currencyComma(user?.count)}</TextWarning>
                                </TextSmall>
                                {/* {isShowShopLinkId ? ( */}
                              </Box>
                            </Box>
                            {isShowShopLinkId ? (
                              <Box left={8} align="left">
                                {user?.list
                                  ?.map((slot) => {
                                    return `${slot?.shop_link_id}`;
                                  })
                                  .join(', ')}
                              </Box>
                            ) : null}
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </DayCell>
  );
};
const PaymentSlotDateCalendarDay = ({ date, setDate, setCalendarType, partner_name }) => {
  const [infos, setInfos] = useState({});
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const { loginInfo } = useSelector((state) => state.common);
  const [isShowShopLinkId, setIsShowShopLinkId] = useState(false);
  const getSlotStateCalendarDay = async ({ date: date, partner_name: partner_name }) => {
    const result = await requestLoadPaymentGetSlotStateCalendarDayInfos({
      date: date,
      partner_name: partner_name,
    });
    if (result) {
      setInfos(result);
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  useEffect(() => {
    if (!date) return;
    setInfos({});
    getSlotStateCalendarDay({ date: today_str(date), partner_name });

    // setEndDay(lastDayDate);
    // setWeekDay(week_n);
  }, [date, partner_name]);

  // const { data: scheduleList } = useQuery(
  //   [PRODUCT.SCHEDULE, period.startDate],
  //   () =>
  //     getProductSchedule({
  //       startDate: period.startDate,
  //       endDate: period.endDate,
  //     }),
  //   {
  //     enabled: !!period.startDate,
  //   },
  // );

  return (
    <Box column width="100%">
      <Box align="center">
        <Box>
          <TextButton
            onClick={() => {
              setCalendarType('month');
            }}
          >
            월별 보기
          </TextButton>
        </Box>
        <Box>
          <IconButton
            size={'sm'}
            Theme={'unFill'}
            Icon={Icon.Arrow}
            onClick={() => {
              const oneMonthAgo = moment(date).add(-1, 'days');

              setDate(oneMonthAgo.format('YYYY-MM-DD'));
            }}
            direction="left"
          />
          {moment(date).format(`YYYY-MM-DD`)} (
          <span
            dangerouslySetInnerHTML={{ __html: getWeekColorStr(moment(date)?.format('ddd')) }}
          />
          )
          <IconButton
            size={'sm'}
            Theme={'unFill'}
            Icon={Icon.Arrow}
            onClick={() => {
              const oneMonthAgo = moment(date).add(1, 'days');

              setDate(oneMonthAgo.format('YYYY-MM-DD'));
            }}
            direction="right"
          />
        </Box>
      </Box>
      <Box bottom={4}>
        <CheckBox
          disabled
          checked={isShowShopLinkId}
          onClick={() => {
            setIsShowShopLinkId(!isShowShopLinkId);
            forceUpdate();
          }}
        >
          슬롯 고유번호 보기
        </CheckBox>
      </Box>
      <Box>
        <Block>
          <ShowData
            user_level={loginInfo?.user_level}
            title={'발급[신규]'}
            color={'Success_600'}
            data={infos?.issue_new_slot_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'발급[연장]'}
            color={'Success_600'}
            data={infos?.issue_extend_slot_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'진행[신규]'}
            color={'Success_600'}
            data={infos?.active_new_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'진행[연장]'}
            color={'Success_600'}
            data={infos?.active_extend_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'진행[테스트]'}
            color={'Success_600'}
            data={infos?.active_test_slot_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'진행[AS연장]'}
            color={'Success_600'}
            data={infos?.active_as_extend_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'정산 완료[신규]'}
            color={'Success_600'}
            data={infos?.paid_new_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'정산 완료[연장]'}
            color={'Success_600'}
            data={infos?.paid_extend_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'환불[신규]'}
            color={'Success_600'}
            data={infos?.refund_new_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'환불[연장'}
            color={'Success_600'}
            data={infos?.refund_extend_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'취소[신규]'}
            color={'Success_600'}
            data={infos?.cancel_new_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'취소[연장]'}
            color={'Success_600'}
            data={infos?.cancel_extend_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'취소[테스트]'}
            color={'Success_600'}
            data={infos?.cancel_test_slot_list}
            isShowShopLinkId={isShowShopLinkId}
          />
          <ShowData
            user_level={loginInfo?.user_level}
            title={'취소[AS연장]'}
            color={'Success_600'}
            data={infos?.cancel_as_extend_list}
            isShowShopLinkId={isShowShopLinkId}
          />
        </Block>
      </Box>
    </Box>
  );
};
const DayCell = styled.button`
  display: flex;
  padding: 5rem;
  &:hover {
    background-color: #f0f0f0;
  }
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 10rem;
  border-width: 1rem 1rem 0rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  padding: 5rem;
  ${(props) => {
    const { isShow } = props;
    if (isShow) {
      return css`
        font-size: 14rem;
      `;
    }
  }};
`;
const Block = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  border-width: 0rem 0rem 1rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  ${(props) => {
    const { isShow } = props;
    // if (isShow) {
    //   return css`
    //     grid-template-columns: 3fr repeat(7, 1fr);
    //   `;
    // }
  }}
`;

export default PaymentSlotDateCalendarDay;
