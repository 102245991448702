import useTab from 'Common/Hooks/useTab';
import { Grid } from 'Common/UI/atoms';
import TabComponent from 'Common/UI/molecules/Tab/TabComponent';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AnalyticsAllVmware from './Page/AnalyticsAllVmware';
import AnalyticsRealtimeVmware from './Page/AnalyticsRealtimeVmware';
import AnalyticsRealtimeProducts from './Page/AnalyticsRealtimeProducts';
import AnalyticsVmwareShopLinkCounts from './Page/AnalyticsVmwareShopLinkCounts';
import AnalyticsVmwareShopLinkClickLogs from './Page/AnalyticsVmwareShopLinkClickLogs';
import AnalyticsVmwareLatelyLogs from './Page/AnalyticsVmwareLatelyLogs';
import { useSelector } from 'react-redux';

const Analytics = () => {
  //   const url = `/analytics`;
  const { loginInfo } = useSelector((state) => state.common);
  const location = useLocation();
  const { current, onClickLocation } = useTab(location.pathname, true);
  if (loginInfo?.user_level !== 5) return;
  return (
    <>
      <Grid row={['auto', 1]} gap={32}>
        <TabComponent
          current={current}
          onClick={(url) => {
            onClickLocation(url);
          }}
          items={[
            {
              title: '회선별 유효클릭 상황',
              value: '/analytics/vmwares_shop_link_counts',
            },
            { title: '전체 정보', value: '/analytics/all' },
            {
              title: '실시간 정보 [상품]',
              value: '/analytics/products',
            },
            {
              title: '실시간 정보 [Vmware]',
              value: '/analytics/vmwares',
            },
            // {
            //   disabled: true,
            //   title: 'Vmware',
            //   value: '/analytics/vmwares',
            // },
          ]}
        />
        <Routes>
          <Route path={'/all'} element={<AnalyticsAllVmware />} />
          <Route path={'/vmwares_shop_link_counts'} element={<AnalyticsVmwareShopLinkCounts />} />
          <Route path={'/products'} element={<AnalyticsRealtimeProducts />} />
          {/* <Route path={'/product/:product_id'} element={<AnalyticsVmwareShopLinkClickLogs />} /> */}
          <Route path={'/vmware/:vmware_name'} element={<AnalyticsVmwareLatelyLogs />} />
          <Route path={'/vmwares'} element={<AnalyticsRealtimeVmware />} />
        </Routes>
      </Grid>
    </>
  );
};
export default Analytics;
