import { useMutation } from 'react-query';
import {
  requestLoadTestLatelyclickLogs,
  requestLoadShopMallSetWorkerLoginInfoRatio,
  requestLoadShopMallGetWorkerLoginInfoRatios,
  requestLoadWorkerLoginTypeCounts,
  requestLoadShopLinkCountGetDataApiInfo,
  requestLoadVmwareGetApiInfo,
  requestLoadVmwareSetApiInfo,
  requestLoadVmwareSetInitdefaultApiInfo,
  requestLoadVmwareGetApiRealtimeCount,
  requestLoadShopLinkGetApiDataInfo,
  requestLoadInfoSetApiInfo,
  requestLoadInfoGetApiInfo,
  requestLoadDashboardGetDataInfo,
  requestLoadStatusGetDataInfo,
  requestLoadCountGetApiInfo,
  requestLoadEtcStatusGetApiInfo,
  requestLoadAddDoSchedule,
  requestLoadAddMd,
  requestLoadGetMdCount,
  requestLoadAddSc2,
  requestLoadGetSc2Count,
  requestLoadAddNlogid,
  requestLoadGetNlogCount,
  requestLoadAddImpCid,
  requestLoadGetImpCidCount,
  requestLoadVmwareShopLinkCounts,
  requestLoadShopLinkLatelyClickLogs,
  requestLoadVmwareShopLinkCountWorkstationInfos,
  requestLoadVmwareLatelyLogs,
  requestLoadShopLinkPaidLogs,
  requestLoadShopLinkPaymentClickCountDateInfo,
  requestLoadShopLinkPaymentClickCountDates,
  requestLoadGetSlotLogs,
  requestLoadAddStore,
  requestLoadGetWorkStoreInfo,
  requestLoadSetClickFinalReport,
  requestLoadWorkFinalResultLogsGetInfos,
  requestLoadWorkResultLogsGetInfos,
  requestLoadWorkResultLogsGetTrafficDateLogs,
  requestLoadWorkResultLogsGetErrorLastErrorStatusLogs,
  requestLoadWorkResultLogsGetErrorLastWhichLogs,
  requestLoadWorkResultLogsGetDateLogs,
  requestLoadWorkRefilterUrlTypeGetLogs,
  requestLoadWorkRefilterUrlTypeGetInfos,
  requestLoadWorkRefilterUrltypeGetUrlType,
  requestLoadWorkRankForceUpdateRankMakeTodayLogs,
  requestLoadWorkRankGetInfos,
  requestLoadWorkProductInfoGetShopLinkUpdateInfos,
  requestLoadWorkProductInfoGetShopLinkDetailUpdateInfos,
  requestLoadWorkKeywordGetNeedKeywordInfos,
  requestLoadWorkKeywordGetNeedNewKeywordInfos,
  requestLoadWorkBasicUrlTypeGetRequiredBrowserTypes,
  requestLoadWorkBasicUrlTypeGetBrowserTypes,
  requestLoadWorkBasicUrlTypeGetAddTypeDates,
  requestLoadWorkSbthGetInfos,
  requestLoadWorkViewGetInfos,
  requestLoadWorkProxyGetInfos,
  requestLoadWorkProxyGetControlInfos,
  requestLoadWorkProxySetControlInfos,
  requestLoadWorkRefilterUrlTypeGetNoDataLogInfos,
  requestLoadWorkKeywordGetKeywordStatus,
  requestLoadWorkCategorySimpleInfos,
  requestLoadWorkRankGetDateInfos,
  requestLoadWorkVmwareMakeOrder,
  requestLoadWorkVmwareGerOrderInfos,
  requestLoadWorkVmwareViewRealtimeInfos,
  requestLoadWorkVmwareEtcDo1RealtimeInfos,
  requestLoadWorkVmwareFInfos,
  requestLoadWorkVmwareFNumInfos,
  requestLoadWorkRankResetCanAccessDatetime,
  requestLoadWorkDateLogsGetInfos,
  requestLoadGetBasicInfo,
  requestLoadSetBasicInfo,
  requestLoadWorkProductGetSetupInfos,
  requestLoadWorkProductSetSetupInfos,
  requestLoadWorkVmwareGetSetupInfos,
  requestLoadWorkVmwareSetSetupInfos,
  requestLoadWorkVmwareGetCalcHourInfos,
  requestLoadWorkVmwareGetCurrentCmdInfos,
  requestLoadWorkVmwareSetCurrentCmdStatusOff,
} from './common';
// all_force_test_click_allow_chk_false

const useMutations = (submit) => {
  //
  const requestLoadWorkProductGetSetupInfosMutation = useMutation(
    requestLoadWorkProductGetSetupInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkProductGetSetupInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkProductSetSetupInfosMutation = useMutation(
    requestLoadWorkProductSetSetupInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkProductSetSetupInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkVmwareSetCurrentCmdStatusOffMutation = useMutation(
    requestLoadWorkVmwareSetCurrentCmdStatusOff,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareSetCurrentCmdStatusOffMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkVmwareGetCurrentCmdInfosMutation = useMutation(
    requestLoadWorkVmwareGetCurrentCmdInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareGetCurrentCmdInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkVmwareGetCalcHourInfosMutation = useMutation(
    requestLoadWorkVmwareGetCalcHourInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareGetCalcHourInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkVmwareGetSetupInfosMutation = useMutation(
    requestLoadWorkVmwareGetSetupInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareGetSetupInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkVmwareSetSetupInfosMutation = useMutation(
    requestLoadWorkVmwareSetSetupInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareSetSetupInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadGetBasicInfoMutation = useMutation(requestLoadGetBasicInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadGetBasicInfoMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSetBasicInfoMutation = useMutation(requestLoadSetBasicInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSetBasicInfoMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadWorkVmwareMakeOrderMutation = useMutation(requestLoadWorkVmwareMakeOrder, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkVmwareMakeOrderMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadWorkVmwareGerOrderInfosMutation = useMutation(
    requestLoadWorkVmwareGerOrderInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareGerOrderInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkDateLogsGetInfosMutation = useMutation(requestLoadWorkDateLogsGetInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkDateLogsGetInfosMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadWorkVmwareViewRealtimeInfosMutation = useMutation(
    requestLoadWorkVmwareViewRealtimeInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareViewRealtimeInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkVmwareEtcDo1RealtimeInfosMutation = useMutation(
    requestLoadWorkVmwareEtcDo1RealtimeInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkVmwareEtcDo1RealtimeInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkVmwareFInfosMutation = useMutation(requestLoadWorkVmwareFInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkVmwareFInfosMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadWorkVmwareFNumInfosMutation = useMutation(requestLoadWorkVmwareFNumInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkVmwareFNumInfosMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadWorkCategorySimpleInfosMutation = useMutation(
    requestLoadWorkCategorySimpleInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkCategorySimpleInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkFinalResultLogsGetInfosMutation = useMutation(
    requestLoadWorkFinalResultLogsGetInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkFinalResultLogsGetInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkResultLogsGetInfosMutation = useMutation(requestLoadWorkResultLogsGetInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkResultLogsGetInfosMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadWorkResultLogsGetErrorLastErrorStatusLogsMutation = useMutation(
    requestLoadWorkResultLogsGetErrorLastErrorStatusLogs,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkResultLogsGetErrorLastErrorStatusLogsMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkResultLogsGetErrorLastWhichLogsMutation = useMutation(
    requestLoadWorkResultLogsGetErrorLastWhichLogs,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkResultLogsGetErrorLastWhichLogsMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkResultLogsGetTrafficDateLogsMutation = useMutation(
    requestLoadWorkResultLogsGetTrafficDateLogs,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkResultLogsGetTrafficDateLogsMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkResultLogsGetDateLogsMutation = useMutation(
    requestLoadWorkResultLogsGetDateLogs,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkResultLogsGetDateLogsMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkProxyGetInfosMutation = useMutation(requestLoadWorkProxyGetInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkProxyGetInfosMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadWorkProxyGetControlInfosMutation = useMutation(
    requestLoadWorkProxyGetControlInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkProxyGetControlInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkProxySetControlInfosMutation = useMutation(
    requestLoadWorkProxySetControlInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkProxySetControlInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkViewGetInfosMutation = useMutation(requestLoadWorkViewGetInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkViewGetInfosMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadWorkSbthGetInfosMutation = useMutation(requestLoadWorkSbthGetInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkSbthGetInfosMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadWorkRankResetCanAccessDatetimeMutation = useMutation(
    requestLoadWorkRankResetCanAccessDatetime,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkRankResetCanAccessDatetimeMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkRankForceUpdateRankMakeTodayLogsMutation = useMutation(
    requestLoadWorkRankForceUpdateRankMakeTodayLogs,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkRankForceUpdateRankMakeTodayLogsMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkRankGetInfosMutation = useMutation(requestLoadWorkRankGetInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkRankGetInfosMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadWorkRankGetDateInfosMutation = useMutation(requestLoadWorkRankGetDateInfos, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkRankGetDateInfosMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadWorkProductInfoGetShopLinkUpdateInfosMutation = useMutation(
    requestLoadWorkProductInfoGetShopLinkUpdateInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkProductInfoGetShopLinkUpdateInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkProductInfoGetShopLinkDetailUpdateInfosMutation = useMutation(
    requestLoadWorkProductInfoGetShopLinkDetailUpdateInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkProductInfoGetShopLinkDetailUpdateInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkKeywordGetKeywordStatusMutation = useMutation(
    requestLoadWorkKeywordGetKeywordStatus,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkKeywordGetKeywordStatusMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkKeywordGetNeedKeywordInfosMutation = useMutation(
    requestLoadWorkKeywordGetNeedKeywordInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkKeywordGetNeedKeywordInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkKeywordGetNeedNewKeywordInfosMutation = useMutation(
    requestLoadWorkKeywordGetNeedNewKeywordInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkKeywordGetNeedNewKeywordInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkBasicUrlTypeGetRequiredBrowserTypesMutation = useMutation(
    requestLoadWorkBasicUrlTypeGetRequiredBrowserTypes,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkBasicUrlTypeGetRequiredBrowserTypesMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkBasicUrlTypeGetBrowserTypesMutation = useMutation(
    requestLoadWorkBasicUrlTypeGetBrowserTypes,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkBasicUrlTypeGetBrowserTypesMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkBasicUrlTypeGetAddTypeDatesMutation = useMutation(
    requestLoadWorkBasicUrlTypeGetAddTypeDates,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkBasicUrlTypeGetAddTypeDatesMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  //

  const requestLoadWorkRefilterUrlTypeGetNoDataLogInfosMutation = useMutation(
    requestLoadWorkRefilterUrlTypeGetNoDataLogInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkRefilterUrlTypeGetNoDataLogInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkRefilterUrlTypeGetLogsMutation = useMutation(
    requestLoadWorkRefilterUrlTypeGetLogs,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkRefilterUrlTypeGetLogsMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkRefilterUrlTypeGetInfosMutation = useMutation(
    requestLoadWorkRefilterUrlTypeGetInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkRefilterUrlTypeGetInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadWorkRefilterUrltypeGetUrlTypeMutation = useMutation(
    requestLoadWorkRefilterUrltypeGetUrlType,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadWorkRefilterUrltypeGetUrlTypeMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  ///////
  const requestLoadSetClickFinalReportMutation = useMutation(requestLoadSetClickFinalReport, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSetClickFinalReportMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadGetSlotLogsMutation = useMutation(requestLoadGetSlotLogs, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadGetSlotLogsMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadShopLinkPaymentClickCountDateInfoMutation = useMutation(
    requestLoadShopLinkPaymentClickCountDateInfo,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadShopLinkPaymentClickCountDateInfoMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadShopLinkPaymentClickCountDatesMutation = useMutation(
    requestLoadShopLinkPaymentClickCountDates,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadShopLinkPaymentClickCountDatesMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadShopLinkPaidLogsMutation = useMutation(requestLoadShopLinkPaidLogs, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadShopLinkPaidLogsMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadVmwareLatelyLogsMutation = useMutation(requestLoadVmwareLatelyLogs, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadVmwareLatelyLogsMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadVmwareShopLinkCountWorkstationInfosMutation = useMutation(
    requestLoadVmwareShopLinkCountWorkstationInfos,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadVmwareShopLinkCountWorkstationInfosMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadVmwareShopLinkCountsMutation = useMutation(requestLoadVmwareShopLinkCounts, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadVmwareShopLinkCountsMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadShopLinkLatelyClickLogsMutation = useMutation(
    requestLoadShopLinkLatelyClickLogs,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadShopLinkLatelyClickLogsMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadTestLatelyclickLogsMutation = useMutation(requestLoadTestLatelyclickLogs, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadTestLatelyclickLogsMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadShopMallSetWorkerLoginInfoRatioMutation = useMutation(
    requestLoadShopMallSetWorkerLoginInfoRatio,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadShopMallSetWorkerLoginInfoRatioMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadShopMallGetWorkerLoginInfoRatiosMutation = useMutation(
    requestLoadShopMallGetWorkerLoginInfoRatios,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadShopMallGetWorkerLoginInfoRatiosMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadWorkerLoginTypeCountsMutation = useMutation(requestLoadWorkerLoginTypeCounts, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadWorkerLoginTypeCountsMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadGetWorkStoreInfoMutation = useMutation(requestLoadGetWorkStoreInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadGetWorkStoreInfoMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadAddStoreMutation = useMutation(requestLoadAddStore, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadAddStoreMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadAddNlogidMutation = useMutation(requestLoadAddNlogid, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadAddNlogidMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadAddImpCidMutation = useMutation(requestLoadAddImpCid, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadAddImpCidMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadAddMdMutation = useMutation(requestLoadAddMd, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadAddMdMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadAddSc2Mutation = useMutation(requestLoadAddSc2, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadAddSc2Mutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadGetNlogCountMutation = useMutation(requestLoadGetNlogCount, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadGetNlogCountMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadGetImpCidCountMutation = useMutation(requestLoadGetImpCidCount, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadGetImpCidCountMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadGetMdCountMutation = useMutation(requestLoadGetMdCount, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadGetMdCountMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadGetSc2CountMutation = useMutation(requestLoadGetSc2Count, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadGetSc2CountMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadAddDoScheduleMutation = useMutation(requestLoadAddDoSchedule, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadAddDoScheduleMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadEtcStatusGetApiInfoMutation = useMutation(requestLoadEtcStatusGetApiInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadEtcStatusGetApiInfoMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadShopLinkCountGetDataApiInfoMutation = useMutation(
    requestLoadShopLinkCountGetDataApiInfo,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadShopLinkCountGetDataApiInfo',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadVmwareSetInitdefaultApiInfoMutation = useMutation(
    requestLoadVmwareSetInitdefaultApiInfo,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadVmwareSetInitdefaultApiInfoMutation',
          success: true,
          result: data,
        });
      },
    },
  );

  const requestLoadVmwareSetApiInfoMutation = useMutation(requestLoadVmwareSetApiInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadVmwareSetApiInfoMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadCountGetApiInfoMutation = useMutation(requestLoadCountGetApiInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadCountGetApiInfoMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadVmwareGetApiInfoMutation = useMutation(requestLoadVmwareGetApiInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadVmwareGetApiInfoMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadVmwareGetApiRealtimeCountMutation = useMutation(
    requestLoadVmwareGetApiRealtimeCount,
    {
      onError: (error, variable, context) => {},
      onSuccess: (data, variables, context) => {
        submit({
          request: 'requestLoadVmwareGetApiRealtimeCountMutation',
          success: true,
          result: data,
        });
      },
    },
  );
  const requestLoadShopLinkGetApiDataInfoMutation = useMutation(requestLoadShopLinkGetApiDataInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadShopLinkGetApiDataInfoMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadInfoSetApiInfoMutation = useMutation(requestLoadInfoSetApiInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadInfoSetApiInfoMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadInfoGetApiInfoMutation = useMutation(requestLoadInfoGetApiInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadInfoGetApiInfoMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadDashboardGetDataInfoMutation = useMutation(requestLoadDashboardGetDataInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadDashboardGetDataInfoMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadStatusGetDataInfoMutation = useMutation(requestLoadStatusGetDataInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadStatusGetDataInfoMutation',
        success: true,
        result: data,
      });
    },
  });
  return {
    requestLoadWorkProductGetSetupInfosMutation,
    requestLoadWorkProductSetSetupInfosMutation,
    requestLoadWorkVmwareGetSetupInfosMutation,
    requestLoadWorkVmwareSetSetupInfosMutation,
    requestLoadWorkVmwareGetCalcHourInfosMutation,
    requestLoadVmwareShopLinkCountWorkstationInfosMutation,
    requestLoadTestLatelyclickLogsMutation,
    requestLoadShopMallSetWorkerLoginInfoRatioMutation,
    requestLoadShopMallGetWorkerLoginInfoRatiosMutation,
    requestLoadWorkerLoginTypeCountsMutation,
    requestLoadAddDoScheduleMutation,
    requestLoadShopLinkCountGetDataApiInfoMutation,
    requestLoadCountGetApiInfoMutation,
    requestLoadWorkVmwareMakeOrderMutation,
    requestLoadWorkVmwareGerOrderInfosMutation,
    requestLoadVmwareGetApiInfoMutation,
    requestLoadVmwareSetApiInfoMutation,
    requestLoadVmwareSetInitdefaultApiInfoMutation,
    requestLoadVmwareGetApiRealtimeCountMutation,
    requestLoadShopLinkGetApiDataInfoMutation,
    requestLoadInfoSetApiInfoMutation,
    requestLoadInfoGetApiInfoMutation,
    requestLoadDashboardGetDataInfoMutation,
    requestLoadStatusGetDataInfoMutation,
    requestLoadEtcStatusGetApiInfoMutation,
    requestLoadAddMdMutation,
    requestLoadGetMdCountMutation,
    requestLoadGetSc2CountMutation,
    requestLoadAddNlogidMutation,
    requestLoadGetNlogCountMutation,
    requestLoadAddImpCidMutation,
    requestLoadGetImpCidCountMutation,
    requestLoadAddSc2Mutation,
    requestLoadVmwareShopLinkCountsMutation,
    requestLoadShopLinkLatelyClickLogsMutation,
    requestLoadVmwareLatelyLogsMutation,
    requestLoadShopLinkPaidLogsMutation,
    requestLoadShopLinkPaymentClickCountDateInfoMutation,
    requestLoadShopLinkPaymentClickCountDatesMutation,
    requestLoadGetSlotLogsMutation,
    requestLoadGetWorkStoreInfoMutation,
    requestLoadAddStoreMutation,
    requestLoadSetClickFinalReportMutation,
    requestLoadWorkRefilterUrlTypeGetLogsMutation,
    requestLoadWorkRefilterUrlTypeGetInfosMutation,
    requestLoadWorkRefilterUrltypeGetUrlTypeMutation,
    requestLoadWorkRefilterUrlTypeGetNoDataLogInfosMutation,
    requestLoadWorkProxyGetInfosMutation,
    requestLoadWorkProxyGetControlInfosMutation,
    requestLoadWorkProxySetControlInfosMutation,
    requestLoadWorkViewGetInfosMutation,
    requestLoadWorkSbthGetInfosMutation,
    requestLoadWorkRankForceUpdateRankMakeTodayLogsMutation,
    requestLoadWorkRankGetInfosMutation,
    requestLoadWorkRankResetCanAccessDatetimeMutation,
    requestLoadWorkRankGetDateInfosMutation,
    requestLoadWorkProductInfoGetShopLinkUpdateInfosMutation,
    requestLoadWorkProductInfoGetShopLinkDetailUpdateInfosMutation,
    requestLoadWorkKeywordGetKeywordStatusMutation,
    requestLoadWorkKeywordGetNeedKeywordInfosMutation,
    requestLoadWorkKeywordGetNeedNewKeywordInfosMutation,
    requestLoadWorkBasicUrlTypeGetRequiredBrowserTypesMutation,
    requestLoadWorkBasicUrlTypeGetBrowserTypesMutation,
    requestLoadWorkBasicUrlTypeGetAddTypeDatesMutation,
    requestLoadWorkFinalResultLogsGetInfosMutation,
    requestLoadWorkResultLogsGetInfosMutation,
    requestLoadWorkResultLogsGetTrafficDateLogsMutation,
    requestLoadWorkResultLogsGetErrorLastErrorStatusLogsMutation,
    requestLoadWorkResultLogsGetErrorLastWhichLogsMutation,
    requestLoadWorkResultLogsGetDateLogsMutation,
    requestLoadWorkCategorySimpleInfosMutation,
    requestLoadWorkVmwareViewRealtimeInfosMutation,
    requestLoadWorkVmwareGetCurrentCmdInfosMutation,
    requestLoadWorkVmwareSetCurrentCmdStatusOffMutation,
    requestLoadWorkVmwareEtcDo1RealtimeInfosMutation,
    requestLoadWorkVmwareFInfosMutation,
    requestLoadWorkVmwareFNumInfosMutation,
    requestLoadWorkDateLogsGetInfosMutation,
    requestLoadGetBasicInfoMutation,
    requestLoadSetBasicInfoMutation,
  };
};

export default useMutations;
