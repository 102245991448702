import { Box, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLoadWorkProductInfoGetShopLinkUpdateInfos,
  requestLoadWorkProductInfoResetCanAccessDatetime,
} from 'Common/Service/common';
import { currencyComma, get_percent } from 'Common/Utils';
import { TextFail, TextSmall, TextSucceed, TextWarning, reload_second } from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const WorkProductInfo = () => {
  const [productInfos, setProductInfos] = useState();
  const [count, setCount] = useState(reload_second);

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const dispatch = useDispatch();
  const getProductInfos = async () => {
    const product_infos = await requestLoadWorkProductInfoGetShopLinkUpdateInfos({});
    setProductInfos(product_infos);
    // const menuItems = url_type_infos.map((e) => {
    //   return {
    //     title: `${e.url_type} (${e.count})`,
    //     value: `/work/${url_type}`,
    //   };
    // });
    // setMenuItems(menuItems);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getProductInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getProductInfos();
  }, []);
  return (
    <>
      <Box>
        must_everyday_do3, 4 <TextSmall>{count}</TextSmall>
      </Box>
      <Box column gap={24}>
        <Box column>
          <Box>
            <Box>
              쇼핑몰 정보 업데이트 진행 상황{' '}
              <TextSmall>Shopping Mall Information Update Progress</TextSmall>
            </Box>
            <Box>
              [
              <TextSucceed>
                {get_percent(
                  productInfos?.update_done_count + productInfos?.no_find_nvmid_count,
                  productInfos?.all_count,
                )}
              </TextSucceed>{' '}
              %] {productInfos?.update_done_count + productInfos?.no_find_nvmid_count} /{' '}
              {productInfos?.all_count}{' '}
              <TextButton
                onClick={async () => {
                  const result = await requestLoadWorkProductInfoResetCanAccessDatetime();
                  if (result) {
                    dispatch(
                      onShowToast({
                        status: 'success',
                        message: `초기화가 완료되었습니다..`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  } else {
                    dispatch(
                      onShowToast({
                        status: 'error',
                        message: `오류가 발생했습니다`,
                        cancelText: '',
                        onCancel: false,
                      }),
                    );
                  }
                }}
              >
                time reset
              </TextButton>
            </Box>
          </Box>
          <Box>
            <Box>
              쇼핑몰 업데이트 필요 상품수 <TextSmall>Products Requiring Updates</TextSmall>
            </Box>
            <Box>
              <TextFail> {currencyComma(productInfos?.need_update_shop_count)}</TextFail>
            </Box>
          </Box>
          <Box>
            <Box>
              쇼핑몰 업데이트 완료 <TextSmall>Completed Updates</TextSmall>
            </Box>
            <Box>
              <TextSucceed> {currencyComma(productInfos?.update_done_count)}</TextSucceed>
            </Box>
          </Box>
          <Box>
            <Box>
              쇼핑몰 업데이트 실패 <TextSmall>Update Failure</TextSmall>
            </Box>
            <Box>
              <TextFail> {currencyComma(productInfos?.no_find_nvmid_count)}</TextFail>
            </Box>
          </Box>
        </Box>
        <Box column>
          <Box>
            <Box>
              쇼핑몰 정보 세부(fiddler) 업데이트 진행 상황{' '}
              <TextSmall>Fiddler Detailed Information Update Rate</TextSmall>{' '}
            </Box>
            <Box>
              [
              <TextSucceed>
                {get_percent(
                  productInfos?.update_shop_fetch_done_count + productInfos?.no_find_nvmid_count,
                  productInfos?.all_count,
                )}
              </TextSucceed>{' '}
              %]{' '}
              {currencyComma(
                productInfos?.update_shop_fetch_done_count - productInfos?.no_find_nvmid_count,
              )}{' '}
              / {currencyComma(productInfos?.all_count)} (작업중:{' '}
              {productInfos?.shop_fetch_update_doing_count}){' '}
              <TextButton
                onClick={async () => {
                  await requestLoadWorkProductInfoResetCanAccessDatetime();
                }}
              >
                time reset
              </TextButton>
            </Box>
          </Box>
          <Box>
            <Box>
              쇼핑몰 정보 세부 업데이트 필요 상품{' '}
              <TextSmall>Products Requiring Detailed Updates</TextSmall>
            </Box>
            <Box>
              <TextFail>{currencyComma(productInfos?.need_update_shop_fetch_count)}</TextFail>{' '}
              {productInfos?.need_update_shop_count > 0 ? (
                <>
                  (쇼핑몰 업데이트 대기 상품수{' '}
                  <TextWarning>{currencyComma(productInfos?.need_update_shop_count)}</TextWarning>)
                </>
              ) : null}
            </Box>
          </Box>
          <Box>
            <Box>
              쇼핑몰 정보 세부 업데이트 완료{' '}
              <TextSmall>Detailed Information Updates Completed</TextSmall>
            </Box>
            <Box>
              <TextSucceed>{currencyComma(productInfos?.update_shop_fetch_done_count)}</TextSucceed>
            </Box>
          </Box>
          <Box>
            <Box>
              쇼핑몰 업데이트 실패 <TextSmall>Update Failure</TextSmall>
            </Box>
            <Box>
              <TextFail> {currencyComma(productInfos?.no_find_nvmid_count)}</TextFail>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default WorkProductInfo;
