import React from 'react';
import styled from 'styled-components';

function DashBoard({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  padding: 60rem 0;
  background: var(--P_25);
  border: 1rem dashed var(--P_500);
  border-radius: 12rem;
`;

export default DashBoard;
