import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const Switch = ({
  type,
  onChange,
  value,
  name,
  size = 24,
  disabled,
  checked,
  children,
  align = 'left',
}) => {
  const [check, setCheck] = useState(checked);
  const change = (e) => {
    setCheck(e.target.checked);
    onChange(e);
  };

  useEffect(() => setCheck(checked), [checked]);

  return (
    <Wrapper disabled={disabled} size={size} checked={check}>
      {children && align === 'left' && <span className="switchChildren">{children}</span>}
      <input
        value={value || ''}
        disabled={disabled}
        name={name || ''}
        onChange={change}
        type={type || 'checkbox'}
        checked={check || false}
      />
      <div className="switchBar">
        <span className="switchCircle"></span>
      </div>

      {children && align === 'right' && <span className="switchChildren">{children}</span>}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  ${({ size, disabled, checked }) => `
    display: flex;
    align-items: center;
    cursor: ${disabled ? 'no-drop' : 'pointer'};
    hegiht:${size}rem;
    gap:8rem;
    input {
    display: none;
    }
    .switchChildren {
        margin-right: 6rem;
        font-size: 14rem;
        line-height: 16rem;
    }
    .switchBar{
        position:relative;
        display:flex;
        align-items:center;
        width:${size * 1.75}rem;
        height:${size * 0.75}rem;
        background-color:var(--${disabled ? 'G_50' : checked ? 'P_100' : 'G_100'});
        border-radius:100vw;
        transition:0.3s;
        .switchCircle{
            position:absolute;
            left:${checked ? size * 0.75 : 0}rem;
            width:${size}rem;
            height:${size}rem;
            border-radius:100%;
            background-color:var(--${disabled ? 'G_100' : checked ? 'P_700' : 'White'});
            box-shadow:var(--Elevation_001);
            transition:0.3s;

        }
    }
`}
`;

export default Switch;
