import { valueSet } from "Common/Utils/util";
import React from "react";
import styled, { css } from "styled-components";
import { Box } from "../atoms";

function DragModal({
    children,
    title,
    cancelText = "닫기",
    position = "relative",
    onCancel,
    full,
    onFull,
    style,
    width = "100%",
    isToggle = true,
    padding = [14, 12],
    isActive,
    scrollValue = 50,
    className,
    rightBtn,
}) {
    return (
        <Wrapper
            isToggle={isToggle}
            className={className}
            position={position}
            sizeX={valueSet(width)}
            full={full}
            scrollValue={scrollValue}
            styles={style}
            isActive={isActive}
        >
            <div className="header">
                {title && <strong>{title}</strong>}
                <button className="fullBtn" onClick={onFull}></button>
                <Box style={`margin-left:auto;`} gap={32}>
                    {rightBtn}
                    {cancelText && (
                        <button
                            className="cancel"
                            onClick={onCancel}
                            type="button"
                        >
                            {cancelText}
                        </button>
                    )}
                </Box>
            </div>
            <Inner padding={valueSet(padding)} className="inner">
                <div className="scroll">{children}</div>
            </Inner>
        </Wrapper>
    );
}
const Inner = styled.div`
    ${({ padding }) => `
    position: relative;
    padding:${padding};
    .scroll {
        position:absolute;
        display:flex;
        flex-direction:column;
        left:12rem;
        top:14rem;
        width:calc(100% - 24rem);
        height:calc(100% - 28rem);
        overflow: auto;
    }
`}
`;
const Wrapper = styled.div`
    ${({
        theme: { colors },
        isActive,
        scrollValue,
        position,
        isToggle,
        sizeX,
        styles,
        full,
    }) => {
        return css`
            display: ${isActive ? `flex` : "none"} !important;
            flex-direction: column;
            width: ${sizeX};
            height: ${isToggle ? `${full ? 90 : scrollValue}%` : "44px"};
            background-color: var(--P_10);
            transition: all 0.2s;
            bottom: 0;
            z-index: 10;
            overflow-y: hidden;
            position: ${full ? "absolute" : position};
            .header {
                display: flex;
                align-items: center;
                padding: 13rem 20rem 11rem 20rem;
                border-radius: 12rem 12rem 0 0;
                background-color: var(--P_500);
                position: relative;
                > .fullBtn {
                    display: ${(props) => (props.isToggle ? "block" : "none")};
                    width: 50rem;
                    height: 2rem;
                    left: calc(50% - 25rem);
                    top: 8rem;
                    background-color: var(--White);
                    position: absolute;
                }
                strong {
                    display: flex;
                    align-items: center;
                    font-size: 14rem;
                    line-height: 20rem;
                    color: var(--White);
                    span {
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                    }
                }
                .cancel {
                    margin-left: auto;
                    font-weight: 400;
                    font-size: 14rem;
                    line-height: 140%;
                    color: var(--White);
                }
            }
            .inner {
                height: ${isActive ? "calc(100% - 44px)" : ""};
            }
            ${styles}
        `;
    }}
`;
export default DragModal;
