import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TableComponent } from 'Common/UI/organisms/TableComponent';
import { Table, Tbody, THeader } from 'Common/UI/organisms/FormTabel/styeld';
import ToggleButton from 'Page/Module/CheckToggleButton';
import { Box, Button, DropBox, InputComponent, TextButton } from 'Common/UI/atoms';
import useMutations from 'Common/Service/userMutations';
import { requestLoadUserList, requestLoadUserSet } from 'Common/Service/user';
import { useDispatch, useSelector } from 'react-redux';
import { onShowToast } from 'features/common/commonSlice';
import ModalQuestion from 'Common/UI/atoms/ModalQuestion';
import Highlight from 'react-highlighter';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PaginationComponent from 'Page/Module/PaginationComponent';
import PaginationBox from 'Page/Module/PaginationBox';
import UserModifyModal from './UserModifyModal';
import { useForceUpdate } from 'Common/Utils/util';
import { navigate_newtab } from 'Common/Utils';

const UserList = () => {
  const { partner_name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);
  const [result, setResults] = useState({});
  const [userModifyModalStatus, setUserModifyModalStatus] = useState({
    isActive: false,
    onSuccess: () => {
      setUserModifyModalStatus({ ...userModifyModalStatus, isActive: false });
    },
    onClose: () => {
      setUserModifyModalStatus({ ...userModifyModalStatus, isActive: false });
    },
  });
  const [modal, setModal] = useState({
    title: '',
    successText: '삭제',
    isActive: false,
    cancelText: '취소',
    onSuccess: () => {
      setModal({ ...modal, isActive: false });
    },
    onClose: () => {
      setModal({ ...modal, isActive: false });
    },
  });
  const [selectedPartnerName, setSelectedPartnerName] = useState(null);
  const dispatch = useDispatch();
  const onPage = (page) => {
    // requestBuildingsMutation.mutate({ search, page, pageSize });
  };
  const [partnerNames, setPartnerNames] = useState([
    { title: '전체', value: null, selected: true },
  ]);
  const forceUpdate = useForceUpdate();
  const [searchWord, setSearchWord] = useState('');

  const { requestLoadUserListMutation, requestLoadPartnerGetNamesMutation } =
    useMutations(setResults);

  useEffect(() => {
    if (result?.request === 'requestLoadUserSetMutation') {
      if (result?.success && result?.result !== undefined) {
        const list1 = [{ title: '전체', value: null, selected: partner_name === null }];
        var list2 = result?.result?.map((e, _idx) => {
          return {
            title: e,
            value: e,
            selected: partner_name === e,
          };
        });

        if (list2 === undefined) list2 = [];
        setPartnerNames([...list1, ...list2]);
      }
    } else if (result?.request === 'requestLoadPartnerGetNamesMutation') {
      if (result?.success && result?.result !== undefined) {
        const list1 = [{ title: '전체', value: null, selected: partner_name === undefined }];
        var list2 = result?.result?.map((e, _idx) => {
          return {
            title: e,
            value: e,
            selected: partner_name === e,
          };
        });
        if (list2 === undefined) list2 = [];
        setPartnerNames([...list1, ...list2]);
      }
    } else if (result?.request === 'requestLoadUserListMutation') {
      if (result?.success && result?.result !== undefined) {
        setAllList(result?.result);
        setTimeout(() => {
          requestLoadPartnerGetNamesMutation.mutate();
        }, 1000);
      }
    }
    setResults({});
  }, [result.request]);

  const selectPartnerName = async (partner_name = null) => {
    setAllList([]);
    const result = await requestLoadUserList({ partner_name, partner_chk: true });
    setAllList(result);
  };
  const deleteUser = async (user_name) => {
    const result = await requestLoadUserSet({
      user_name,
      fields: {
        delete_chk: true,
      },
    });

    if (result) {
      const t_list = list.filter((user) => user.user_name !== user_name);
      setAllList(t_list);
      dispatch(
        onShowToast({
          status: 'success',
          message: '아이디가 삭제되었습니다',
          cancelText: '',
          onCancel: false,
        }),
      );
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: '오류가 발생했습니다',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  const toggleUserUse = async (user_name, use1) => {
    const result = await requestLoadUserSet({
      user_name,
      fields: {
        use1,
      },
    });

    if (result) {
      const t_list = list.map((user) => {
        return { ...user, use1: user.user_name === user_name ? use1 : user.use1 };
      });
      setAllList(t_list);
      dispatch(
        onShowToast({
          status: 'success',
          message: '변경 사항이 적용되었습니다',
          cancelText: '',
          onCancel: false,
        }),
      );
      // users;
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: '오류가 발생했습니다',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };

  const roadDatas = () => {
    var partner_name = selectedPartnerName;
    if (loginInfo.user_level <= 2) {
      return;
    }
    if (loginInfo.user_level === 3) {
      partner_name = loginInfo.user_name;
    }
    requestLoadUserListMutation.mutate({ partner_name, partner_chk: true });
  };
  // useEffect(() => {
  //   roadDatas();
  // }, []);

  // const Highlighted = ({ text = '', highlight = '' }) => {
  //   if (!highlight.trim()) {
  //     return <span>{text}</span>;
  //   }
  //   const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  //   const parts = text.split(regex);
  //   return (
  //     <span>
  //       {parts
  //         .filter((part) => part)
  //         .map((part, i) =>
  //           regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>,
  //         )}
  //     </span>
  //   );
  // };
  useEffect(() => {
    const partner_chk = location?.pathname?.startsWith('/partner') ? true : false;
    setList([]);

    if (loginInfo.user_level === 3) {
      const partner_name = loginInfo.user_name;
      setSelectedPartnerName(partner_name);
      requestLoadUserListMutation.mutate({ partner_name, partner_chk });
    } else {
      setSelectedPartnerName(partner_name);
      requestLoadUserListMutation.mutate({ partner_name, partner_chk });
    }

    // requestLoadSlotGroupListMutation.mutate({ partner_name, user_name });

    if (loginInfo?.user_level >= 4) requestLoadPartnerGetNamesMutation.mutate();
  }, [partner_name, location?.pathname]);

  return (
    <Box valign={'top'} column top={6}>
      {loginInfo?.user_level >= 4 && (
        <Box top={20}>
          <DropBox
            title={'2차대행사'}
            isColor
            option={partnerNames}
            onChange={(_a, value) => {
              // setSelectedPartnerName(value);
              // selectPartnerName(value);
              if (value) navigate(`/user/${value}`);
              else navigate('/user');
            }}
            size={44}
          />

          <Box left={8}>
            <Button
              size="sm"
              onClick={() => {
                selectPartnerName(selectedPartnerName);
              }}
            >
              검색
            </Button>
          </Box>
        </Box>
      )}

      <TableComponent
        onSearch={null}
        isController={false}
        isHeaad={false}
        allCheckbox={false}
        isInterval={false}
        searchText={null}
      >
        <LabelBox row label="검색">
          <Box fill top={20} bottom={10}>
            <InputComponent
              value={searchWord}
              onChange={(e) => {
                setSearchWord(e.target.value?.trim());
              }}
            />
          </Box>
        </LabelBox>
        <Box top={8} />

        <PaginationBox
          // checkList={checkList}
          // setCheckList={setCheckList}
          setList={setList}
          allList={allList}
        />

        <Table>
          <colgroup>
            {/*  */}
            <col style={{ width: '50rem' }} />
            {/* id */}
            <col style={{ width: 'auto' }} />
            {/* 유저명 */}
            <col style={{ width: 'auto' }} />
            {/* 비밀번호 */}
            <col style={{ width: 'auto' }} />
            {/* 2차대행사 */}
            {location?.pathname?.startsWith('/partner') ? null : <col style={{ width: 'auto' }} />}
            {/* 진행슬롯 */}
            <col style={{ width: 'auto' }} />
            {/* 진행 예정 슬롯 */}
            <col style={{ width: 'auto' }} />
            {/* 미세팅 슬롯 */}
            <col style={{ width: 'auto' }} />
            {/* 정보 변경 */}
            <col style={{ width: 'auto' }} />
            {/* 타입 */}
            <col style={{ width: 'auto' }} />
            {/* 로그인 허용 */}
            <col style={{ width: 'auto' }} />
            {/* 버튼 */}
            {/* <col style={{ width: 'auto' }} /> */}
          </colgroup>
          <THeader>
            <tr>
              <th rowSpan={'1'}>&nbsp;</th>
              {location?.pathname?.startsWith('/partner') ? (
                <th rowSpan={'1'}>대행사ID</th>
              ) : (
                <th rowSpan={'1'}>유저ID</th>
              )}
              <th rowSpan={'1'}>유저명</th>
              <th rowSpan={'1'}>비밀번호</th>
              {location?.pathname?.startsWith('/partner') ? null : <th rowSpan={'1'}>2차대행사</th>}
              <th rowSpan={'1'}>진행 슬롯</th>
              <th rowSpan={'1'}>진행 예정 슬롯</th>
              <th rowSpan={'1'}>미세팅 슬롯</th>
              <th rowSpan={'1'}>정보 변경</th>
              <th rowSpan={'1'}>타입</th>
              <th rowSpan={'1'}>로그인 허용</th>
            </tr>
          </THeader>
          <Tbody>
            <React.Fragment>
              {list
                ?.filter((e) => {
                  if (searchWord === '') return true;

                  if (e.user_name?.indexOf(searchWord) >= 0) return true;
                  if (e.user_nickname?.indexOf(searchWord) >= 0) return true;
                  if (e.user_password?.indexOf(searchWord) >= 0) return true;
                  if (e.partner_name?.indexOf(searchWord) >= 0) return true;
                  return false;
                })
                ?.map((e, idx) => {
                  return (
                    <TrBlock
                      key={idx}
                      onClick={() => {
                        // const url = `/product/${'group_name'}`;
                        // navigate(url);
                      }}
                    >
                      <td rowSpan={'1'}>{idx + 1}</td>
                      <td rowSpan={'1'}>
                        <TextButton
                          to={null}
                          size={18}
                          onClick={() => {
                            if (e.user_level === 2) {
                              navigate(`/product/slot?slot=${e.user_name}`);
                            } else {
                              navigate(`/user/${e.user_name}`);
                            }
                          }}
                        >
                          <Highlight search={searchWord}>{e.user_name}</Highlight>
                        </TextButton>
                      </td>
                      <td rowSpan={'1'}>
                        <Highlight search={searchWord}>{e.user_nickname}</Highlight>
                      </td>
                      <td rowSpan={'1'}>
                        <Highlight search={searchWord}>
                          <TextButton
                            size={18}
                            onClick={async () => {
                              setUserModifyModalStatus({
                                ...userModifyModalStatus,
                                user_name: e?.user_name,
                                isActive: true,
                                onSuccess: ({ user_nickname, user_password }) => {
                                  e.user_nickname = user_nickname;
                                  e.user_password = user_password;
                                  forceUpdate();

                                  setUserModifyModalStatus({
                                    ...userModifyModalStatus,
                                    isActive: false,
                                  });
                                },
                              });
                            }}
                          >
                            {e.user_password}
                          </TextButton>
                        </Highlight>
                      </td>
                      <td rowSpan={'1'}>
                        {/* <TextButton
                          size={18}
                          onClick={() => {
                            navigate(`/product/group?partner=${e.partner_name}`);
                          }}
                        > */}
                        <Highlight search={searchWord}>{e.partner_name}</Highlight>
                        {/* </TextButton> */}
                      </td>
                      <td rowSpan={'1'}>
                        <TextButton
                          onClick={() => {
                            // 진행슬롯
                            if (e.user_level === 2) {
                              // navigate(`/product/slot?user=${e.user_name}`);
                            } else {
                              // navigate(`/product/slot?partner=${e.user_name}`);
                            }

                            navigate_newtab(`/product/slot?slot=${e.user_name}&active=a`);
                          }}
                        >
                          <StatusText>{e.slot_count}</StatusText>
                        </TextButton>
                      </td>
                      <td rowSpan={'1'}>
                        <TextButton
                          onClick={() => {
                            if (e.user_level === 2) {
                              // navigate(`/product/slot?user=${e.user_name}`);
                            } else {
                              // navigate(`/product/slot?partner=${e.user_name}`);
                            }
                            navigate_newtab(`/product/slot?slot=${e.user_name}&active=p`);
                          }}
                        >
                          <StatusText>{e.pre_slot_count}</StatusText>
                        </TextButton>
                      </td>
                      <td rowSpan={'1'}>
                        <TextButton
                          onClick={() => {
                            if (e.user_level === 2) {
                              // navigate(`/product/slot?user=${e.user_name}`);
                            } else {
                              // navigate(`/product/slot?partner=${e.user_name}`);
                            }
                            navigate_newtab(`/product/slot?slot=${e.user_name}&active=s`);
                          }}
                        >
                          <StatusText>{e.not_setting_count}</StatusText>
                        </TextButton>
                      </td>
                      <td rowSpan={'1'}>
                        <TextButton
                          size={18}
                          onClick={async () => {
                            setUserModifyModalStatus({
                              ...userModifyModalStatus,
                              user_name: e?.user_name,
                              isActive: true,
                              onSuccess: ({ user_nickname, user_password }) => {
                                e.user_nickname = user_nickname;
                                e.user_password = user_password;
                                forceUpdate();

                                setUserModifyModalStatus({
                                  ...userModifyModalStatus,
                                  isActive: false,
                                });
                              },
                            });
                          }}
                        >
                          변경
                        </TextButton>
                      </td>
                      <td rowSpan={'1'}>
                        <StatusText>
                          {e.user_type === 'user'
                            ? '일반'
                            : e.user_type === 'partner'
                            ? '2차 대행사'
                            : e.user_type === 'admin'
                            ? '관리자'
                            : ''}
                        </StatusText>
                      </td>
                      <td rowSpan={'1'} align="center">
                        <Box>
                          <ToggleButton
                            active={e?.use1}
                            onClick={() => {
                              toggleUserUse(e.user_name, !e?.use1);
                            }}
                          >
                            {e?.use1 ? '가능' : '로그인 제한'}
                          </ToggleButton>
                          <Button
                            Theme="unFill"
                            size="sm"
                            onClick={() => {
                              setModal({
                                ...modal,
                                isActive: true,
                                title: '사용자',
                                text: '사용자를 삭제 처리합니다.',
                                onSuccess: () => {
                                  deleteUser(e.user_name);
                                  setModal({ ...modal, isActive: false });
                                },
                              });
                            }}
                          >
                            삭제
                          </Button>
                        </Box>
                      </td>
                    </TrBlock>
                  );
                })}
            </React.Fragment>
          </Tbody>
        </Table>
      </TableComponent>
      <UserModifyModal {...userModifyModalStatus} />
      <ModalQuestion {...modal} />
    </Box>
  );
};
const TrBlock = styled.tr`
  &:hover {
    background-color: #f0f0f0;
  }
`;
const ButtonBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-height: 50rem;
  padding: 5rem;
  padding-left: 10rem;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const StatusText = styled.div`
  text-align: center;
  font-size: 14rem;
  display: inline-block;
  gap: 12rem;
  justify-content: space-between;

  span {
    font-size: 12rem;
    line-height: 18rem;
    color: #ba1717;
  }
  small {
    font-size: 10rem;
    line-height: 14rem;
    color: #18795a;
  }
  ${(props) => {
    const { active } = props;
    if (!active) return;
    return `
    color: #fff;
    border-width: 1rem;
    border-style: solid;
    border-color: var(--P_300); 
    background-color: #bc340b;
        
    `;
  }}
`;
export default UserList;
