import { Box, Grid, Icon, IconButton, TextButton, TimeBar } from 'Common/UI/atoms';
import { getWeekColorStr } from 'Common/Utils';
import { today_str } from 'Common/Utils/util';
// import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PaymentSlotDateCalendarMonth from './PaymentSlotDateCalendarMonth';
import PaymentSlotDateCalendarDay from './PaymentSlotDateCalendarDay';

const PaymentSlotDateCalendar = ({
  start_date,
  end_date,
  issue_type,
  slot_status,
  partner_name,
}) => {
  const [dates, setDates] = useState([
    '2024-04-21',
    '2024-04-22',
    '2024-04-23',
    '2024-04-24',
    '2024-04-25',
    '2024-04-26',
    '2024-04-27',
  ]);
  const [date, setDate] = useState(new Date());
  const [startDay, setStartDay] = useState();
  const [endDay, setEndDay] = useState(0);
  const [weekDay, setWeekDay] = useState(0);
  const [calendarType, setCalendarType] = useState('month');

  useEffect(() => {
    const d = new Date(date);
    const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    const lastDayDate = lastDayOfMonth.getDate();
    const firstDayOfMonth = moment(d).startOf('month');
    // console.log(firstDayOfMonth);
    const week_n = firstDayOfMonth.day(); // 0: 일요일, 1: 월요일, ..., 6: 토요일

    setEndDay(lastDayDate);
    setWeekDay(week_n);
  }, [date]);

  // const { data: scheduleList } = useQuery(
  //   [PRODUCT.SCHEDULE, period.startDate],
  //   () =>
  //     getProductSchedule({
  //       startDate: period.startDate,
  //       endDate: period.endDate,
  //     }),
  //   {
  //     enabled: !!period.startDate,
  //   },
  // );

  return (
    <Box column width="100%">
      {calendarType === 'month' ? (
        <PaymentSlotDateCalendarMonth
          date={date}
          setDate={setDate}
          setCalendarType={setCalendarType}
        />
      ) : calendarType === 'day' ? (
        <PaymentSlotDateCalendarDay
          date={date}
          setDate={setDate}
          setCalendarType={setCalendarType}
        />
      ) : null}
    </Box>
  );
};
const DayCell = styled.button`
  display: flex;
  padding: 5rem;
  &:hover {
    background-color: #f0f0f0;
  }
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 10rem;
  border-width: 1rem 1rem 0rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  /* border-color: gray; */
  height: 100rem;
  ${(props) => {
    const { isShow } = props;
    if (isShow) {
      return css`
        font-size: 14rem;
      `;
    }
  }};
`;
const Block = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  border-width: 0rem 0rem 1rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  ${(props) => {
    const { isShow } = props;
    // if (isShow) {
    //   return css`
    //     grid-template-columns: 3fr repeat(7, 1fr);
    //   `;
    // }
  }}
`;

export default PaymentSlotDateCalendar;
