import React, { useEffect, useState } from 'react';
import Modal from 'Common/UI/organisms/Modal/Modal';
import styled from 'styled-components';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { Box, Button, InputComponent } from 'Common/UI/atoms';
import { requestLoadUserGetModifyInfo, requestLoadUserModify } from 'Common/Service/user';
import { useDispatch } from 'react-redux';
import { onShowToast } from 'features/common/commonSlice';
const UserModifyModal = ({ isActive = false, user_name = null, onClose, onSuccess }) => {
  const [userInfo, setUserInfo] = useState({});
  const [userModifyInfo, setUserModifyInfo] = useState({});
  const getUserInfo = async ({ user_name }) => {
    const user_info = await requestLoadUserGetModifyInfo({ user_name });
    if (user_info) {
      setUserInfo(user_info);
      setUserModifyInfo(user_info);
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setUserInfo({});
    setUserModifyInfo({});
    if (!user_name) return;
    getUserInfo({ user_name });
  }, [user_name]);
  return (
    <Modal
      className={'modal'}
      size="sm"
      isActive={isActive}
      close={true}
      onClose={() => {
        onClose();
      }}
      successText={null}
    >
      <Wrapper>
        <Box column gap={8}>
          <Title>회원 정보 변경</Title>
          <LabelBox label={'아이디'}>{user_name}</LabelBox>
          <LabelBox label={'닉네임'}>
            <InputComponent
              autocomplete={'off'}
              size="44"
              name="user_nickname"
              value={userModifyInfo?.user_nickname}
              onChange={(e) => {
                const user_nickname = e.target.value;
                setUserModifyInfo({ ...userModifyInfo, [e.target.name]: user_nickname });
                if (!user_nickname) return;
              }}
              underLine
              required
            />
          </LabelBox>
          <LabelBox label={'비밀번호'}>
            <InputComponent
              autocomplete={'off'}
              maxLength={12}
              placeholder={'4~12 자리'}
              size="44"
              underLine
              value={userModifyInfo?.user_password}
              name="user_password"
              onChange={(e) => {
                const value = e.target.value.trim();
                setUserModifyInfo({ ...userModifyInfo, [e.target.name]: value });
              }}
            />
          </LabelBox>
          <Box width="100%" align="center">
            <Button
              disabled={
                (userInfo?.user_nickname === userModifyInfo?.user_nickname &&
                  userInfo?.user_password === userModifyInfo?.user_password) ||
                userModifyInfo?.user_password?.length < 4
              }
              fill
              onClick={async () => {
                const result = await requestLoadUserModify({
                  ...userModifyInfo,
                  user_name: user_name,
                });
                if (result) {
                  dispatch(
                    onShowToast({
                      status: 'success',
                      message: `회원 정보가 변경되었습니다.`,
                      cancelText: '',
                      onCancel: false,
                    }),
                  );
                  onSuccess(userModifyInfo);
                } else {
                  dispatch(
                    onShowToast({
                      status: 'error',
                      message: `오류가 발생했습니다`,
                      cancelText: '',
                      onCancel: false,
                    }),
                  );
                }
              }}
            >
              변경
            </Button>
          </Box>
        </Box>
      </Wrapper>
    </Modal>
  );
};
const Title = styled.div`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  font-size: 20rem;
  line-height: 172%;

  letter-spacing: -0.03em;
  color: var(--G_900);
`;
const Wrapper = styled.div`
  position: relative;
  transition: 0.3s;

  display: flex;
  flex-direction: column;
  border-radius: 8rem;
`;

UserModifyModal.defaultProps = {
  setting: () => {},
  status: {},
  onClose: () => {},
  onSuccess: () => {},
};

export default UserModifyModal;
