import { Box, Grid, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestLoadWorkVmwareViewRealtimeInfos } from 'Common/Service/common';
import { currencyComma, get_percent } from 'Common/Utils';
import PercentModule from './Module/PercentModule';
import { TextSmall, TextSucceed, reload_second } from 'Common/Utils/util';

const WorkVmwareViewLogInfos = () => {
  const [workInfos, setWorkInfos] = useState();

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;

  const getWorkInfos = async () => {
    const work_infos = await requestLoadWorkVmwareViewRealtimeInfos({});
    setWorkInfos(work_infos);
  };
  const [count, setCount] = useState(reload_second);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getWorkInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    getWorkInfos();
  }, []);
  return (
    <>
      <Box bottom={0}>
        <TextSmall>{count}</TextSmall>
      </Box>
      {/* <Grid column={['1fr']} gap={32} valign="top"> */}
      {workInfos?.working_vmware_infos?.map((e) => {
        return (
          <Box column>
            <Box>
              <Box>
                <Box>
                  {e?.workstation_name} succeed rate (10 min):{' '}
                  <TextSucceed>{e?.succeed_count}</TextSucceed>/{e?.working_count}
                  <PercentModule
                    succeed_limit={80}
                    value={e?.succeed_count}
                    valueMax={e?.working_count}
                  />
                </Box>
              </Box>
            </Box>
            <Box gap={12} column>
              {e?.vmware_infos?.map((info, index) => {
                return (
                  <Box key={index}>
                    <Box>{info?.vmware_name}</Box>
                    <Box>
                      {' '}
                      <TextSucceed>{currencyComma(info?.f_num_list?.length)}</TextSucceed>
                    </Box>
                    <Box>cmds: {info?.f_num_list_str}</Box>
                    <Box>
                      <TextSucceed>{info?.succeed_count}</TextSucceed>/{info?.working_count}
                      <PercentModule
                        succeed_limit={80}
                        value={info?.succeed_count}
                        valueMax={info?.working_count}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
      {/* </Grid> */}
    </>
  );
};
export default WorkVmwareViewLogInfos;
