import React from 'react';

const Menu = () => {
  return [
    {
      title: '메인',
      url: '/',
      hide: false,
    },
    {
      title: '회원 가입',
      url: '/signup',
      disabled: true,
      hide: false,
      el: <></>,
      depth: [
        {
          title: '2차 대행사',
          url: '/partner',
          hide: false,
          el: <></>,
        },
        {
          title: '목록',
          url: '/list',
          hide: false,
          el: <></>,
        },
      ],
    },
    {
      title: 'CMD 가동 현황',
      url: '/cmd',
      disabled: true,
      hide: true,
      el: <></>,
    },
    {
      title: '유입 상세 현황',
      url: '/vmware_count',
      disabled: true,
      hide: true,
      el: <></>,
    },
    {
      title: '대행사 목록',
      url: '/partner',
      disabled: true,
      hide: true,
      el: <></>,
      depth: [
        {
          title: '목록',
          url: '/list',
          hide: false,
          el: <></>,
        },
      ],
    },
    {
      title: '컴퓨터 세부 설정',
      url: '/vmware_setup',
      disabled: true,
      hide: true,
      el: <></>,
      depth: [],
    },
    {
      title: '유저 목록',
      url: '/user',
      disabled: true,
      hide: true,
      el: <></>,
      depth: [
        {
          title: '목록',
          url: '/list',
          hide: false,
          el: <></>,
        },
      ],
    },
    {
      title: '정산',
      url: '/payment',
      disabled: true,
      hide: false,
      el: <></>,
      depth: [
        {
          title: '결제 내역',
          url: '/log',
          hide: false,
          el: <></>,
        },
        {
          title: '슬롯 타수 내역',
          url: '/counts',
          hide: false,
          el: <></>,
        },
      ],
    },
    {
      title: '슬롯 관리',
      url: '/product',
      disabled: true,
      hide: false,
      el: <></>,
      depth: [
        {
          title: '등록',
          url: '/add',
          hide: false,
          el: <></>,
        },
      ],
    },
    {
      title: '네이버 ID',
      url: '/naver_id',
      disabled: true,
      hide: false,
      el: <></>,
      depth: [
        {
          title: '등록',
          url: '/add',
          hide: false,
          el: <></>,
        },
      ],
    },
    {
      title: '이력관리',
      url: '/logs',
      hide: false,
    },
    {
      title: '통계',
      url: '/analytics',
      hide: false,
      depth: [
        {
          title: '전체',
          url: '/all',
          hide: false,
          // disabled: true,
        },
        {
          title: '회선별 유효클릭 상황',
          url: '/vmwares_shop_link_counts',
          hide: false,
        },
        {
          title: '실시간 정보 [상품]',
          url: '/products',
          hide: false,
        },
        {
          title: '실시간 정보 [Vmwares]',
          url: '/vmwares',
          hide: false,
        },
      ],
    },
    {
      title: '관리',
      url: '/manage',
      hide: false,
      depth: [
        {
          title: 'Vmware',
          url: '/vmwares',
          hide: false,
          disabled: false,
          depth: [
            {
              title: '기본 세팅',
              url: '/default',
              hide: false,
              // disabled: true,
            },
          ],
        },
      ],
    },
    {
      title: '로그인',
      url: '/login',
      hide: false,
      depth: [],
    },
  ];
};

export default Menu;
