import { Box, Icon, IconButton } from 'Common/UI/atoms';
import React from 'react';
import styled from 'styled-components';

const FormSelectItem = ({ title, onDelete, isEdit }) => {
  return (
    <Wrapper className="listItem">
      <Box fill className="textBox">
        <p>{title}</p>
      </Box>
      {isEdit && <IconButton Theme={'unFill'} size={'xs'} Icon={Icon.Trash} onClick={onDelete} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32rem;
  .imgBox {
    margin-left: 12rem;
  }
  .textBox {
    margin-left: 8rem;
    p {
      font-weight: 500;
      font-size: 14rem;
      color: var(--G_800);
      > .highlighted {
        color: var(--P_500);
      }
    }
    > span {
      font-weight: 400;
      font-size: 12rem;
      line-height: 130%;
      color: var(--G_300);
    }
  }
  + .listItem {
    margin-top: 8rem;
  }
`;
export default FormSelectItem;
