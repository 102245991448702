export const API_SERVER_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/smartstore_admin/smartstore/api'
    : // ? 'http://localhost:4000/smartstore/api'
      // : 'https://api.m-smartstore.com/smartstore/api';
      //'https://m-smartstore.com/smartstore/api';
      `http://${window.location.hostname}/smartstore/api`;
// export const SERVER_URL = 'http://localhost:4000';

export const token_name =
  'SFMyNTY.g3QAAAACZAAEZGF0YW0AAACNeyJsb2dpbl9kYXRldGltZSI6IjIwMjMtMDktMzAgMDE6NDg6MjAiLCJsb2dpbl9pcF9hZGRyZXNzIjoiMTQuMjA3LjIwNC4xMzIiLCJwYXJ0bmVyX25hbWUiOiJwYXJ0bmVyMSIsInVzZXJfbmFtZSI6InRlc3QxIiwidXNlcl90eXBlIjoidXNlciJ9ZAAGc2lnbmVkbgYAElfX4YoB.2dDZ0g3B_lyKzfsFu5kdICsevE5Hps0YpTRpaO9Mbus';
