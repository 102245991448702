import React from 'react';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import Icon from './IconBox';

function Tag({
  children,
  onClick,
  name,
  value,
  className,
  disabled,
  rightIcon = <Icon.Closer size={8} />,
  isActive,
  isDelete = true,
  size,
  icon = true,
}) {
  const onFocus = (e) => {};
  const onBlur = (e) => {};
  return (
    <Wrapper
      name={name}
      value={value}
      className={className}
      disabled={disabled}
      isActive={isActive}
      size={size}
    >
      <p>{children}</p>
      {isDelete && icon && rightIcon && (
        <button className="icon" onClick={onClick} onFocus={onFocus} onBlur={onBlur}>
          {rightIcon}
        </button>
      )}
    </Wrapper>
  );
}

const propsStyle = css`
  ${({ size, theme: { tag, ellipsis } }) => {
    const propsSize = size ? `size${size}` : 'size24';
    return css`
      ${tag.size[propsSize]};
      flex: 0 0 auto;
      p {
        ${ellipsis(1)}
      }
    `;
  }}
`;
const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8rem;
  background: var(--P_75);
  border-radius: 4rem;
  font-weight: 400;
  font-size: 12rem;
  line-height: 170%;
  color: var(--G_700);
  letter-spacing: -0.03rem;
  max-width: 200rem;
  .icon {
    display: flex;
    align-items: center;
  }
  &:hover {
    background-color: var(--P_50);
  }
  &.active {
    background-color: var(--P_100);
  }
  &:disabled {
    background-color: var(--G_50);
    cursor: no-drop;
    color: var(--G_150);
    .icon {
      cursor: no-drop;
      svg {
        stroke: var(--G_50);
      }
    }
  }

  p {
    // flex-basis: 200rem;
  }
  ${propsStyle};
`;
export default Tag;
