import {
  requestLoadWorkBasicUrlTypeGetAddTypeDates,
  requestLoadWorkRefilterUrlTypeGetInfos,
} from 'Common/Service/common';
import useMutations from 'Common/Service/userMutations';
import { Button, DropBox, Grid, InputComponent } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { currencyComma, getAlphaAndNumber } from 'Common/Utils';
import { TextSmall, TextSucceed, debounce, reload_second } from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import WorkRefilterUrlFetchAccessLog from './WorkRefilterUrlTypeFetchAccessLog';

const WorkBasicUrlTypeFetch = ({ ua_browser_version = null, ua_chromium_version = null }) => {
  const [userNameStatus, setUserNameStatus] = useState({ status: 'asdf' });
  const [browserTypeInfos, setBrowserTypeInfos] = useState([]);
  const { loginInfo } = useSelector((state) => state.common);

  const location = useLocation();
  // { title: '미분류', value: null, selected: true },
  const [apiId, setApidId] = useState();
  const [count, setCount] = useState(reload_second);

  // const dispatch = useDispatch();
  const { browser_type } = useParams();
  // const [count, setCount] = useState(reload_second);

  const getBrowserTypeInfos = async () => {
    if (!browser_type) return;
    // const url_type_r = url_type?.replaceAll('_backslash_', '/')?.replaceAll('_question_', '?');

    const infos = await requestLoadWorkBasicUrlTypeGetAddTypeDates({ browser_type });
    setBrowserTypeInfos(infos);
  };
  useEffect(() => {
    clearInterval(apiId);
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getBrowserTypeInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApidId(intervalId);
    return () => clearInterval(intervalId);
  }, [browser_type]);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCount((prevCount) => {
  //       var ct = prevCount - 1;
  //       if (ct < 0) {
  //         getBrowserTypeInfos();
  //         ct = reload_second;
  //       }
  //       return ct;
  //     });
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);
  useEffect(() => {
    clearInterval(apiId);

    setBrowserTypeInfos({});
    getBrowserTypeInfos();
  }, [browser_type]);

  return (
    <>
      {/* <Wrapper> */}
      <Box bottom={-60} column>
        <Box>
          {browser_type} {count}
        </Box>
        <Box>
          <TextSmall>https://chromereleases.googleblog.com/</TextSmall>
        </Box>

        {/* <TextSmall>{count}</TextSmall> */}
      </Box>
      <Grid
        column={['200rem', '400rem', 'auto']}
        gap={32}
        style={'background: red'}
        valign="top"
        height="1000"
      >
        {/* <Box column> */}
        <Box column gap={24}>
          {browserTypeInfos?.date_logs?.map((e, index) => {
            return (
              <UrlInfoBox
                key={index}
                // onClick={() => {
                // setUrlTypeLogId(e.id);
                // }}
              >
                <Box>
                  <Box>{e?.date}</Box>
                  <Box>
                    <TextSucceed>{currencyComma(e?.count)}</TextSucceed>
                  </Box>
                </Box>
              </UrlInfoBox>
            );
          })}
        </Box>
        <Box valign="top" column>
          {ua_chromium_version ? (
            <>
              <Box>
                browser version: <TextSucceed>{ua_browser_version}</TextSucceed>
              </Box>
              <Box>
                chromium version: <TextSucceed>{ua_chromium_version}</TextSucceed>
              </Box>
            </>
          ) : (
            <>
              <Box>iOS Version: {ua_browser_version}</Box>
            </>
          )}
        </Box>
        {/* </Box> */}
        {/* <Box column> */}
        <Box valign="top" column>
          <Box column gap={30}>
            {browserTypeInfos?.version_logs?.map((e, index) => {
              return (
                <UrlInfoBox
                  key={index}
                  // onClick={() => {
                  // setUrlTypeLogId(e.id);
                  // }}
                >
                  <Box>
                    <Box>{e?.ua_browser_version}</Box>
                    <Box>
                      <TextSucceed>{currencyComma(e?.count)}</TextSucceed>
                    </Box>
                  </Box>
                </UrlInfoBox>
              );
            })}
          </Box>
        </Box>
        {/* </Box> */}
      </Grid>
      {/* </Wrapper> */}
    </>
  );
};
const UrlInfoBox = styled.div`
  &:hover {
    color: var(--P_700);
    &::after {
      background-color: var(--P_700);
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;

export default WorkBasicUrlTypeFetch;
