export const cardData = {
    //위험성 평가
    riskAssessment: {
        title: "위험성평가 등록 방식을 선택하세요",
        cardList: [
            {
                icon: "stemp",
                title: "스마플 템플릿을 이용할게요!",
                subject: "KRAS 기법을 사용해요",
                color: "P_500",
                items: [
                    "대한산업안전협회의 전문 컨설턴트의 노하우가 녹아있는 스마플 템플릿을 제공해 드립니다",
                ],
            },
            {
                icon: "complate",
                title: "이미 완료된 평가표가 있어요!",
                subject: "작성된 평가표 파일을 업로드해 주세요",
                color: "Success_500",
                items: [
                    "기본 정보 입력을 통해 우리사업장정보의 공정 및 공간에 연동됩니다",
                    "안전톡톡에서 받은 사전의견을 반영시킬 수 있습니다",
                ],
            },
        ],
    },
    policy: {
        title: "규정 및 지침 등록방식을 선택하세요",
        cardList: [
            {
                icon: "stemp",
                title: "스마플 템플릿을 이용할게요!",
                subject: "스마플 양식을 제공해요",
                color: "P_500",
                items: [
                    "대한산업안전협회의 전문 컨설턴트의 노하우가 녹아있는 스마플 템플릿을 제공해 드립니다.",
                ],
            },
            {
                icon: "complate",
                title: "이미 완료된 규정 및 지침이 있어요!",
                subject: "작성된 규정 및 지침 파일을 업로드해요",
                color: "Succes_500",
                items: [
                    "회사 내부에서 쓰시던 양식 을 그대로 업로드해 사용합니다.",
                ],
            },
        ],
    },
    guidelines: {
        title: "위험성평가 등록 방식을 선택하세요",
        cardList: [
            {
                icon: "stemp",
                title: "스마플 템플릿을 이용할게요!",
                subject: "스마플 양식을 제공해요",
                color: "P_500",
                items: [
                    "대한산업안전협회의 전문 컨설턴트의 노하우가 녹아있는 스마플 템플릿을 제공해 드립니다.",
                ],
            },
            {
                icon: "complate",
                title: "이미 완료된 규정 및 경영방침이 있어요!",
                subject: "작성된 경영방침 파일을 업로드해요",
                color: "Succes_500",
                items: [
                    "회사 내부에서 쓰시던 양식 을 그대로 업로드해 사용합니다.",
                ],
            },
        ],
    },
    space: {
        title: "무엇을 추가할까요?",
        cardList: [
            {
                icon: "folder",
                title: "공간 그룹 추가하기",
                subject: "그룹을 추가해 공간들을 넣을 수 있어요",
                color: "P_500",
                items: [
                    "공간들을 묶는 역할을 해요",
                    "공간에 대한 정보를 입력할 수 없어요",
                    "사업장 부터 4단계까지 생성할 수 있어요",
                    "그룹이 생성되야 공간이 생성돼요",
                ],
            },
            {
                icon: "location",
                title: "공간 추가하기",
                subject: "공간을 추가하고 정보를 입력할 수 있어요",
                color: "Succes_500",
                items: [
                    "정보를 입력해 관리할 수 있어요",
                    "기존 공간에 가벽 등을 설치해 임시 창고과 같은 새로운 공간을 만드셨다면 상위에 그룹을 생성하신 뒤 두 공간을 넣어보세요",
                    "사업장 직하위에는 생성할 수 없어요",
                ],
            },
        ],
    },
    order: {
        title: "도급 금지 사업인지 확인하세요",
        cardList: [
            {
                icon: "stemp",
                title: "도급 사업을 승인 받았어요!",
                subject: "도급 사업 승인서가 있어요",
                color: "P_500",
                items: [
                    "수급인이 보유한 기술이 전문적이고 사업 운영에 필수 불가결한 경우라서 고용노동부장관의 승인을 받았어요",
                ],
            },
            {
                icon: "complate",
                title: "도급 사업이 가능해요!",
                subject: "도급 사업 승인서는 없어요",
                color: "Succes_500",
                items: [
                    "유해한 작업이라 도급이 불가능하지만 30일 이내에 종료되는 1회성 작업이거나 연간 총 60일을 초과하지 않는 간헐적 작업에 해당해요",
                    "도급 사업을 할 수 없는 법적 규제가 있는 작업이 아니면 별도 승인없이 가능해요",
                ],
            },
        ],
    },
    bid: {
        title: "무엇을 추가할까요?",
        cardList: [
            {
                icon: "stemp",
                title: "비회원 지원",
                subject: "스마플 비회원이셔도 지원 가능해요!",
                color: "S_500",
                items: [
                    "스마플을 이용하지 않더라도 간단한 정보 입력으로 쉽게 지원이 가능해요!",
                ],
            },
            {
                icon: "transmit",
                title: "스마플 로그인 후 지원하세요!",
                subject: "공간을 추가하고 정보를 입력할 수 있어요",
                color: "P_500",
                items: [
                    "스마플 이용 회원이라면 등록된 정보를 불러와 지원이 더욱 간편해요!",
                ],
            },
        ],
    },
    safetyInspection: {
        title: "대상품 안전검사 방식을 선택하세요",
        cardList: [
            {
                icon: "security",
                title: `안전검사기관에 검사 신청해야하는
                사업장이에요`,
                color: "P_500",
                items: [
                    "유해·위험기계 등의 안전에 관한 성능이 안전검사기준에 적합한지 여부에 대하여 안전검사기관으로부터 안전검사를 받을게요",
                ],
            },
            {
                icon: "quality",
                title: `자율 안전검사를 할 수 있는 
                사업장이에요`,
                color: "Success_500",
                items: [
                    "자체적으로 안전에 관한 검사를 할 수 있는 전문인력과 기준을 고용노동부로부터 승인을 받았어요",
                ],
            },
        ],
    },
};
