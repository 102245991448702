import { Box, Icon } from 'Common/UI/atoms';
import styled, { css } from 'styled-components';

const RankModule = ({
  insertKeywordChk = false,
  onClick = null,
  rank = null,
  rank_keyword = null,

  last_rank = null,
  last_rank_keyword = null,
  last_rank_date_ago_day = null,
  font_size = 13,
  icon_size = 15,
}) => {
  return (
    <RankText
      font_size={font_size}
      isClickChk={onClick}
      onClick={async () => {
        if (onClick) onClick();
      }}
    >
      <Box column>
        {!insertKeywordChk ? (
          <>
            <Box>
              {!rank ? '-' : parseInt(rank)?.toString()} (
              {rank_keyword === last_rank_keyword ? (
                <>
                  {!last_rank ? (
                    '-'
                  ) : last_rank > rank ? (
                    <>
                      <Box>
                        <Icon.DoubleArrow direction="top" fill={'#0000f0'} size={icon_size} />
                        <small>{parseInt(last_rank - rank)}</small>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <Icon.DoubleArrow direction="bottom" fill={'#f00000'} size={icon_size} />
                        <b>{parseInt(rank - last_rank)}</b>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>-</>
              )}
              )
            </Box>
          </>
        ) : (
          <RankEmptyBlock font_size={font_size}>키워드 입력</RankEmptyBlock>
        )}
      </Box>
    </RankText>
  );
};
const RankText = styled.button`
  color: black;
  font-size: 13rem;
  font-weight: 700;

  b {
    color: red;
  }
  small {
    color: blue;
  }
  ${(props) => {
    const { font_size } = props;
    return css`
      font-size: ${font_size}rem;
    `;
  }}
  ${(props) => {
    const { isClickChk } = props;
    if (!isClickChk) {
      return css`
        cursor: default;
      `;
    }
  }}
`;
const RankEmptyBlock = styled.div`
  font-size: 14rem;
  color: #67616176;
  ${(props) => {
    const { font_size } = props;
    return css`
      font-size: ${font_size}rem;
    `;
  }}
`;
export default RankModule;
