// import { useInput } from "Common/Hooks";
import React, { useEffect } from 'react';
import { Box, Icon, IconButton, InputComponent } from 'Common/UI/atoms';
import { Wrapper, PageMaxLength } from './styled';
import { valueSet } from 'Common/Utils/util';
function PaginationComponent({
  seq = 0,
  onClick,
  onKeyPress,
  pageLength,
  height = 60,
  isScroll,
  position = 'sticky',
  color = 'G_20',
}) {
  //   const { onChange, state } = useInput();

  useEffect(() => {
    // onChange({
    //   target: {
    //     name: 'seq',
    //     value: seq + 1,
    //   },
    // });
  }, [seq]);

  return (
    <Wrapper
      bg={color}
      position={position}
      sizeY={valueSet(height)}
      active={isScroll}
      className="pagination"
    >
      <IconButton
        fill="#94A1C7"
        disabled={seq === 0}
        type="butotn"
        className="arrowBox"
        direction={'left'}
        Icon={Icon.Arrow}
        onClick={() => onClick(seq - 1, 'prev')}
        Theme="unFill"
        size="xs"
      />
      <Box align="center" valign="middle" right={19} left={19} gap={8}>
        <InputComponent
          name="seq"
          className="inputSection"
          //   value={state?.seq}
          size={36}
          placeholder="1"
          type="number"
          min={1}
          padding={0}
          bulr
          //   onChange={onChange}
          onKeyPress={onKeyPress}
        />
        <PageMaxLength>{pageLength || 1}</PageMaxLength>
      </Box>
      <IconButton
        fill="#94A1C7"
        disabled={seq + 1 === pageLength || pageLength === 0}
        direction={'right'}
        Icon={Icon.Arrow}
        className="arrowBox"
        onClick={() => onClick(seq + 1, 'next')}
        Theme="unFill"
        size="xs"
      />
    </Wrapper>
  );
}

PaginationComponent.defaultProps = {
  count: 0,
  seq: 0,
  start: 0,
  view: 10,
};
export default PaginationComponent;
