import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ active, position, bg, sizeY }) => `
height:${sizeY};
  >button {
    width:auto;
    height:auto;
  }
  display: flex;
  justify-content: center;
  position:sticky;
  bottom:0;
  background-color:var(--${bg});
  opacity:${active === undefined ? 1 : active ? 0 : 0.8};
  position:${position};
  transition:0.3s;
  z-index:5;
  .inputSection {
    width:60rem;
    padding:0;
    .inner {
      flex:1 1 auto;
      input {
        padding-right:12rem;
        text-align: right;
      }
    }
  }
  .iconBox {
    display:none;
  }
`}
`;
export const PageMaxLength = styled.div`
  font-weight: 400;
  font-size: 14rem;
  color: #5b637c;
  &::before {
    font-size: inherit;
    color: inherit;
    content: '/';
    padding: 0 8rem;
  }
`;
export const NumberBox = styled.div`
  ${({ theme: { colors } }) => {
    return css`
      display: flex;
      align-items: center;
      padding: 2rem 8rem;
      margin: 0 8rem;
      background: ${colors.white};
      border-radius: 8rem;
      button {
        width: 32rem;
        height: 32rem;
        font-weight: 400;
        font-size: 14rem;
        text-align: center;
        color: ${colors.primaryText};
        border: 1rem solid transparent;
        border-radius: 4rem;
        &.active {
          font-weight: 700;
          color: ${colors.primary};
          border-color: ${colors.primary};
          transition: 0.2s all ease-in;
        }
        + button {
          margin-left: 4rem;
        }
      }
    `;
  }}
`;
