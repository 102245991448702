import { API_SERVER_URL } from 'Common/Env';
import { auth_headers } from 'Common/Utils';
import axios from 'axios';
export const requestLoadGetBasicInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/basic_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSetKeywordCheckVersion = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/set/keyword_check_version`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSetBasicInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/set/basic_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetNewPatternAllProductKeywordStatus = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_new_pattern_all_product_keyword_status`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetNewPatternProductKeywordStatus = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_new_pattern_product_keyword_status`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetNewPatternActiveDoneFinishLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_new_pattern_active_done_finish_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkNewGetNewPatternActiveDoneLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_new_pattern_active_done_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkNewGetNewPatternClickDoneLogs = async (data = { ago_hour: null }) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get/new_pattern_click_done_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkNewGetNewPatternRealtimeState = async (data = { ago_hour: null }) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get/new_pattern_realtime_state`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkNewResetWorkerDoCanAccessDatetime = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/reset/worker_do_can_access_daetime`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewRemoveGotShopSearchKeyword = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/remove/got_shop_search_keyword`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetProductSimpleStatusInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_product_simple_status_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetShopLinkSetManualKeywords = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_shop_link_set_manual_keywords`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewPatternSimpleInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_new_pattern_simple_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetShopLinkAddManualKeywords = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_shop_link_add_manual_keywords`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewNewPatternGroupInfoGetWorkerStatus = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/new_pattern_group_info/get_worker_status`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewNewPatternGroupInfoGetTrafficClickInfoLog = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/new_pattern_group_info/get_traffic_click_info_log`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewNewPatternGroupInfoGetTrafficDetailInfoLog = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/new_pattern_group_info/get_traffic_detail_info_log`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewNewPatternGroupInfoGetTrafficDateLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/new_pattern_group_info/get_traffic_date_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkNewNewPatternKeywordTimeReset = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/new_pattern_keyword_time_reset`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkNewGetWorkFiddlerCountInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_work_fiddler_count_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetActivePatterns = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_active_patterns`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetActivePatterns = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_active_patterns`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetGroupActivePatterns = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_group_active_patterns`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetGroupActivePatternsSubInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_group_active_patterns_sub_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetGroupPatternInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_group_pattern_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetGroupActivePatterns = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_group_active_patterns`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetAutoCountryNormal = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_auto_country_normals`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetAutoCountryNormal = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_auto_country_normals`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetManualCountryNormal = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_manual_country_normals`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetManualCountryNormal = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_manual_country_normals`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetAutoHotNormal = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_auto_hot_normals`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetAutoHotNormal = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_auto_hot_normals`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetManualGroupShopKeyword = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_manual_group_shop_keywords`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetManualGroupShopKeyword = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_manual_group_shop_keywords`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetManualGroupKeyword = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_manual_group_keywords`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetManualGroupKeyword = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_manual_group_keywords`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewSetManualOtherLink = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/set_manual_other_links`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkNewGetManualOtherLink = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/new/get_manual_other_links`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

// category
export const requestLoadWorkCategorySimpleInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/category/simple_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
// product
export const requestLoadWorkProductSetSetupInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/product/set_setup_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkProductGetSetupInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/product/get_setup_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

// vmware_infos
export const requestLoadWorkVmwareSetCurrentCmdStatusOff = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/set_current_cmd_status_off`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkVmwareGetCurrentCmdInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_current_cmd_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkVmwareGetCalcHourInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_calc_hour_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkVmwareSetSetupInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/set_setup_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkVmwareGetSetupInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_setup_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkVmwareMakeOrder = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/make_order`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkVmwareGerOrderInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_order_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkVmwareFNumInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_f_num_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkVmwareFInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_f_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkVmwareViewRealtimeInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_view_realtime_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkVmwareEtcDo1RealtimeInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/vmware/get_etc_do1_realtime_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
//final result_logs
export const requestLoadWorkFinalResultLogsGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/final_result_logs/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
//result_logs
export const requestLoadWorkResultLogsGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/result_logs/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
//result_logs
export const requestLoadWorkResultLogsGetErrorLastErrorStatusLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/result_logs/get_error_date_last_error_status_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
//result_logs
export const requestLoadWorkResultLogsGetErrorLastWhichLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/result_logs/get_error_date_last_which_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
//result_logs
export const requestLoadWorkResultLogsGetTrafficDateLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/result_logs/get_traffic_date_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//result_logs
export const requestLoadWorkResultLogsGetDateLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/result_logs/get_date_logs`;
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
//proxy
export const requestLoadWorkProxyGetControlInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/proxy/get_control_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkProxySetControlInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/proxy/set_control_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkProxyGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/proxy/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
//sbth
export const requestLoadWorkViewGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/view/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//date_logs
export const requestLoadWorkDateLogsGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/date_logs/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//sbth
export const requestLoadWorkSbthGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/sbth/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//rank
export const requestLoadWorkRankForceUpdateRankMakeTodayLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/rank/force_update_rank_make_today_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkRankGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/rank/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkRankResetCanAccessDatetime = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/rank/reset_can_access_datetime`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkRankGetDateInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/rank/get_date_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//product_info
export const requestLoadWorkProductInfoResetKeywordFind = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/product_info/reset_keyword_find`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkProductInfoResetCanAccessDatetime = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/product_info/reset_can_access_datetime`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkProductInfoGetShopLinkUpdateInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/product_info/get_shop_link_update_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkProductInfoGetShopLinkDetailUpdateInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/product_info/get_shop_link_detail_update_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//keyword
export const requestLoadWorkKeywordGetKeywordStatus = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/keyword/get_keyword_status`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkKeywordResetCanAccessDatetime = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/keyword/reset_can_access_datetime`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkKeywordGetNeedKeywordInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/keyword/get_need_keyword_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkKeywordGetNeedNewKeywordInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/keyword/get_need_new_keyword_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//basic url type
export const requestLoadWorkBasicUrlTypeGetRequiredBrowserTypes = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/basic_url_type/get_required_browser_types`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkBasicUrlTypeGetBrowserTypes = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/basic_url_type/get_browser_types`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkBasicUrlTypeGetAddTypeDates = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/basic_url_type/get_add_type_dates`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

//
export const requestLoadWorkRefilterUrlTypeGetNoDataLogInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/refilter_url_type/get_no_data_log_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkRefilterUrlTypeGetLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/refilter_url_type/get_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadWorkRefilterUrlTypeGetInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/refilter_url_type/get_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkRefilterUrltypeGetUrlType = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/work/refilter_url_type/get_url_types`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadGetSlotLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/issue_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadSetClickFinalReport = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/set/click_final_report`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadShopLinkPaymentClickCountDateInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/shop_link_click_count_date_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadShopLinkPaymentClickCountDates = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/shop_link_click_count_dates`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadShopLinkPaidLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/shop_link_paid_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadVmwareLatelyLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/vmware_lately_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadVmwareShopLinkCountWorkstationInfos = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/vmware_shop_link_count_workstation_infos`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadVmwareShopLinkCounts = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/vmware_shop_link_counts`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadShopLinkLatelyClickLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/shop_link_lately_click_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadTestLatelyclickLogs = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/test/lately_click_logs`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadShopMallSetWorkerLoginInfoRatio = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/set/shop_mall/worker_login_info_ratio`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadShopMallGetWorkerLoginInfoRatios = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/shop_mall/worker_login_info_ratios`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadWorkerLoginTypeCounts = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/worker_login_type_counts`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadGetWorkStoreInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/work_store_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadAddStore = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/add/store`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadAddImpCid = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/add/imp_cid`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadAddNlogid = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/add/nlogid`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadAddMd = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/add/md`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadAddSc2 = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/add/sc2`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadGetImpCidCount = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/imp_cid_count`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadGetNlogCount = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/nlog_count`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadGetMdCount = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/md_count`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadGetSc2Count = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/get/sc2_count`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadAddDoSchedule = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/auto_schedule/add`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadEtcStatusGetApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/etc_status/get_api_info`;
    //
    //
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadShopLinkCountGetDataApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/shop_link_count/get_date_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadCountGetApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/count/get_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadVmwareGetApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/vmware/get_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadVmwareSetInitdefaultApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/vmware/set/init_default_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadVmwareSetApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/vmware/set_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadVmwareGetApiRealtimeCount = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/vmware/get_api_realtime_count`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadShopLinkGetApiDataInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/shop_link/get_api_data_info`;
    //
    //
    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadInfoSetApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/info/set_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};

export const requestLoadInfoGetApiInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/info/get_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadDashboardGetDataInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/dashboard/get_data_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
export const requestLoadStatusGetDataInfo = async (data = {}) => {
  try {
    const api_url = `${API_SERVER_URL}/status/get_data_api_info`;

    const response = await axios.put(api_url, data, auth_headers());
    return response.data;
  } catch (e) {}
};
