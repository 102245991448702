import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, Icon, Tag, TextButton, Title } from '.';

const DropBadgeList = ({
  isActive,
  option = [],
  onChange,
  align = 'left',
  textAlign = 'left',
  name,
  className,
  position = 'bottom',
  //추가 20221018
  defaultOption,
  propsRef,
  searchValue,
  onDelete,
  onAllDelete,
  isSingle,
  onClose,
  onClick,
}) => {
  const [idx, setIdx] = useState(-1);
  const ref = useRef();
  const dataRef = useRef();
  const focusRef = useRef(-1);
  const deleteRef = useRef(onDelete);
  const changeRef = useRef(onChange);
  const closeSet = (e) => {
    const isClick = ref?.current?.contains(e?.target) || propsRef.current?.contains(e?.target);
    isClick || switchRef.current.endClick();
    isClick || switchRef.current.endKeyPress();
    isClick || (onClose && onClose());
  };

  const onMove = useCallback((up, fullUp, fullDown) => {
    const data = dataRef.current;
    if (!data?.length) return;
    const idx = focusRef.current;
    const getItem = up
      ? idx < 1
        ? data.length - 1
        : idx - 1
      : idx === data.length - 1
      ? 0
      : idx + 1;
    setIdx(fullUp ? 0 : fullDown ? data.length - 1 : getItem);
  });

  const onKeyPress = (e) => {
    const keyUp = e.keyCode === 38; // ↑
    const keyDown = e.keyCode === 40; // ↓
    const keyEnter = e.keyCode === 13;
    const keyEsc = e.keyCode === 27;
    const keyPageUp = e.keyCode === 33;
    const keyPageDown = e.keyCode === 34;
    const idx = focusRef.current;
    if (!keyUp && !keyDown && !keyEnter && !keyEsc && !keyPageUp && !keyPageDown) return;
    const root = document.getElementById('root');
    const rootY = root.scrollTop;
    root.scrollTo(0, rootY);
    if (keyEnter) {
      const onDelete = deleteRef.current;
      if (idx < 0) return;
      const { title, value, selected, onClick } = dataRef.current[idx];
      selected
        ? isSingle
          ? getData(title, undefined, onClick)
          : onDelete({ title, value })
        : getData(title, value, onClick);
      isSingle && onClose && onClose();
      isSingle ? propsRef.current.blur() : propsRef.current.focus();
      isSingle && switchRef.current.endKeyPress();
    } else if (keyEsc) {
      onClose && onClose();
      return switchRef.current.endKeyPress();
    } else {
      onMove(e.keyCode === 38, keyPageUp, keyPageDown);
    }
  };

  const controller = {
    startClick: () => document.addEventListener('click', closeSet, true),
    startKeyPress: () => document.addEventListener('keydown', onKeyPress, true),
    endClick: () => document.removeEventListener('click', closeSet, true),
    endKeyPress: () => document.removeEventListener('keydown', onKeyPress, true),
  };

  const getData = (title, value, optionClick) => {
    const onChange = changeRef.current;
    optionClick && optionClick();
    onChange && onChange({ name, title, value });
    isSingle ? propsRef.current.blur() : propsRef.current.focus();
    isSingle && controller.endClick();
    isSingle && controller.endKeyPress();
    isSingle && onClose();
  };

  const switchRef = useRef(controller);

  useEffect(() => {
    switchRef.current.endClick();
    switchRef.current.endKeyPress();
    if (isActive) {
      switchRef.current = controller;
      switchRef.current.startClick();
      switchRef.current.startKeyPress();
    } else {
      switchRef.current.endClick();
      switchRef.current.endKeyPress();
    }
  }, [isActive]);

  useEffect(() => {
    isActive && setIdx(-1);
    dataRef.current = option;
  }, [searchValue, isActive]);

  useEffect(() => {
    dataRef.current = option;
    changeRef.current = onChange;
    deleteRef.current = onDelete;
  }, [onChange, onDelete]);

  useEffect(() => {
    focusRef.current = idx;
  }, [idx]);
  const selectList = defaultOption?.filter(({ selected }) => selected);
  const [max, setMax] = useState(false);

  const selectedRef = useRef();
  const selectSet = useCallback(() => {
    const child = [...selectedRef.current?.children]?.filter(({ tagName }) => tagName === 'DIV');
    let top = [];
    let idx = false;
    child?.map((tag, key) => {
      if (top?.length > 2) return;
      const tagY = Math.floor(tag.getBoundingClientRect().y);
      const isTop = top?.length;
      if (!isTop || top[isTop - 1] !== tagY) top.push(tagY);
      if (top?.length === 3) idx = key;
    });
    setMax(idx);
  });

  useEffect(() => {
    !isSingle && selectSet();
  }, [option]);

  return (
    <Wrapper
      className={className ? `${className} dropList` : 'dropList'}
      align={align}
      position={position}
      ref={ref}
      isActive={isActive}
    >
      {!isSingle && (
        <Box ref={selectedRef} className={'hide selectList'} gap={8}>
          {selectList?.map(({ title, value }, key) => (
            <Tag size={28} key={key} onClick={() => onDelete({ title, value })}>
              {title}
            </Tag>
          ))}
        </Box>
      )}
      {!isSingle && !!selectList?.length && (
        <Box gap={8} className={'selectList'}>
          {selectList
            ?.filter((_, key) => !max || key + 1 < max)
            ?.map(({ title, value }, key) => (
              <Tag size={28} key={key} onClick={() => onDelete({ title, value })}>
                {title}
              </Tag>
            ))}
          {max && <TagButton>{selectList?.length}</TagButton>}
        </Box>
      )}
      <Box className={'scroll'} fill column>
        <Box height={'max-content'} column>
          {option.map(({ title, value, selected, disabled, onClick }, key) => (
            <ListButton
              selected={selected}
              key={key}
              isFocus={key === idx}
              onClick={() =>
                selected
                  ? isSingle
                    ? getData(title, undefined, onClick)
                    : onDelete({ title, value })
                  : getData(title, value, onClick)
              }
              textAlign={textAlign}
              disabled={disabled}
              title={title}
            />
          ))}
        </Box>
      </Box>
      <Box className="base" height={40}>
        <Box height={40} fill>
          {!isSingle && (
            <TextButton onClick={onAllDelete} size={'xs'}>
              전체 해제
            </TextButton>
          )}
        </Box>
        <TextButton onClick={onClick} Theme={'primary'} size={'xs'}>
          모든 항목 보기
        </TextButton>
      </Box>
    </Wrapper>
  );
};

const ListButton = ({ selected, isFocus, onClick, textAlign, disabled, title }) => {
  const ref = useRef();
  useEffect(() => {
    const root = document.getElementById('root');
    const rootY = root.scrollTop;
    if (isFocus) {
      ref.current.scrollIntoView({ block: 'center' });
      root.scrollTo(0, rootY);
    }
  }, [isFocus]);
  return (
    <WrapperButton
      ref={ref}
      className={`${selected ? 'selected ' : ''}`}
      isFocus={isFocus}
      onClick={onClick}
      disabled={disabled}
    >
      <Title size={'xxs'} weight={400} color={'G_700'} align={textAlign}>
        {title}
      </Title>
      {selected !== false && <Icon.Check fill={'var(--P_500)'} />}
    </WrapperButton>
  );
};

const Wrapper = styled.div`
  ${({ isActive, align, position }) => `
    position: absolute;
    display:${isActive ? 'flex' : 'none'};
    flex-direction:column;
    box-shadow: var(--Elevation_004);
    background-color:var(--White);
    padding: 8rem 0 0;
    min-width: 100%;
    width:max-content;
    ${align}:0;
    ${position === 'bottom' ? 'top' : 'bottom'}:calc(100% + 8rem);
    border-radius: 8rem;
    overflow:hidden;
    z-index:6;
    .hide{
        position:absolute;
        width:100%;
        left:0;
        z-index:0;
        opacity:0;
    }
    .selectList{
        border-bottom:1rem solid var(--G_50);
        padding:8rem 20rem;
        flex-flow:wrap;
    }
    .scroll{
        overflow-y:scroll;
        max-height:264rem;
        button{
        }
    }
    .base{
        background-color:var(--G_25);
        padding:0 20rem;
    }
`}
`;

const WrapperButton = styled.button`
  ${({ isActive, isFocus }) => `
    padding:0 20rem;
    display:flex;
    align-items:center;
    height:48rem;
    .title{
        flex:1;
        .gray{
            color:var(--G_300);
            margin-left:8rem;
        }
    }
    ${
      isFocus
        ? `
    background-color:var(--P_60);
    `
        : `
    &:hover{
        background-color:var(--P_25);
    }
    
    `
    }
`}
`;

const TagButton = styled.div`
  height: 28rem;
  padding: 0 12rem;
  background-color: var(--P_500);
  font-size: 14rem;
  color: var(--White);
  display: flex;
  align-items: center;
  border-radius: 4rem;
`;

export default DropBadgeList;
