import React from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';
const Textarea = forwardRef(({ ...props }, ref) => {
  return <Wrapper ref={ref} {...props}></Wrapper>;
});
Textarea.defaultProps = {
  value: '',
};

const Wrapper = styled.textarea`
  white-space: nowrap;

  width: 100%;
  /* background-color: red; */
  height: 100%;
  ${(props) => (props.sizeY ? `height:${props.sizeY};` : '')}
  resize:${(props) => (props.resize ? 'vertical' : 'none')};
  padding: 8rem 0;
`;
export default Textarea;
