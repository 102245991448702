import React from 'react';
import styled, { css } from 'styled-components';

function Category({ children, color }) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  ${({ theme: { colors }, color }) => {
    return css`
      background-color: ${color ? color : colors.primaryBorderIcon};
    `;
  }}
  flex: 0 0 100rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14rem;
  color: var(--White);
  border-radius: 4rem 0 0 4rem;
`;

export default Category;
