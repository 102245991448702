import styled, { css } from "styled-components";

export const Wrapper = styled.div`
    ${({ scrollHeight, fix }) => {
        return css`
            height: ${scrollHeight && fix ? scrollHeight : "auto"};
            max-height: ${scrollHeight};
            overflow: auto;
        `;
    }}
`;
