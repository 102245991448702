import { Button, InputComponent } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { useDispatch } from 'react-redux';
import useMutations from 'Common/Service/userMutations';
import { setCookie } from 'Common/Utils';
import { token_name } from 'Common/Env';
import { onShowToast, setLoginInfo } from 'features/common/commonSlice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [userName, setUserName] = useState();
  const [userPassword, setUserPassword] = useState();

  const [result, setResults] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestLoadUserLoginMutation } = useMutations(setResults);
  useEffect(() => {
    if (result?.request === 'requestLoadUserLoginMutation') {
      if (result?.success) {
        const message = result?.result?.message;
        if (result?.result?.result) {
          const token = result?.result?.token;
          const loginInfo = result?.result?.userInfo;
          dispatch(setLoginInfo({ ...loginInfo, token }));
          var sec = 84600 * 7;
          if (loginInfo.user_level >= 5) sec = 84600 * 30;
          setCookie(token_name, token, sec);
          setTimeout(() => {
            navigate('/', { force: true });
          }, 100);
        }

        if (message) {
          dispatch(
            onShowToast({
              status: message?.status,
              message: message?.message,
              cancelText: '',
              onCancel: false,
            }),
          );
        }
      }
    }
    setResults({});
  }, [result.request]);
  const login = async () => {
    requestLoadUserLoginMutation.mutate({
      userName,
      userPassword,
    });
  };
  return (
    <Wrapper>
      <Box column gap="40rem">
        <LabelBox label={'아이디'}>
          <InputComponent
            autocomplete={'off'}
            size="44"
            underLine
            name="userName"
            placeholder={'4~12 자리 영문, 숫자'}
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            required
          />
        </LabelBox>
        <LabelBox label={'비밀번호'}>
          <InputComponent
            autocomplete={'off'}
            placeholder={'4~12 자리'}
            size="44"
            underLine
            name="userPassword"
            value={userPassword}
            onKeyPress={(e) => {
              if (e.charCode == 13) login();
            }}
            onChange={(e) => {
              setUserPassword(e.target.value);
            }}
            el={'password'}
          />
        </LabelBox>
        <Box width="100%" align="center">
          <Button
            disabled={!userName || !userPassword}
            onClick={() => {
              login();
            }}
          >
            로그인
          </Button>
        </Box>
      </Box>
    </Wrapper>
  );
};
export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export default Login;
