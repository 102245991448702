import styled from 'styled-components';

export const Wrapper = styled.aside`
  ${(props) => {
    const { status, bottom, left, isActive } = props;
    return `
            position:fixed;
            bottom:${bottom}rem;
            left:${left}rem;
            min-width:480rem;
            height:48rem;
            display:flex;
            justify-content:space-between;
            border-radius:6rem;
            z-index:${isActive ? 400 : -1};
            align-items:center;
            padding: 0 16rem;
            opacity:${isActive ? 1 : 0};
            transition: opacity .3s ${isActive ? '' : ', z-index 0s 0.3s'};
            border:1rem solid var(--${status});
            background-color:var(--${status});
            p {
                color:var(--White);
                font-size:16rem;
            }
            .btnCancel {
                background-color:var(--${status});
                color:var(--White);
                font-size:16rem;
                &:not(:disabled):hover,
                &:not(:disabled):focus,
                &:not(:disabled):active {
                    background-color:var(--${status});
                    color:var(--White);
                }
                
            }
            .btnClose {
                display:flex;
                align-items:center;
            }
        `;
  }}
`;
