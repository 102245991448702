import moment from 'moment';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';
export const reload_second = 600;
export const get_uuid = () => {
  return uuid();
};

export const getKorDate = () => {
  const now = new Date(); // 현재 시간
  const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000; // 현재 시간을 utc로 변환한 밀리세컨드값
  const koreaTimeDiff = 9 * 60 * 60 * 1000; // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
  const koreaNow = new Date(utcNow + koreaTimeDiff); // utc로 변환된 값을 한국 시간으로 변환시키기 위해 9시간(밀리세컨드)를 더함
  return koreaNow;
};

export const dateDiffInDays = (startDate, endDate) => {
  // const oneDay = 24 * 60 * 60 * 1000; // 하루는 밀리초로 24시간 * 60분 * 60초 * 1000밀리초
  // return Math.floor((endDate - startDate) / oneDay);

  const start = moment(startDate);
  const end = moment(endDate);
  const daysDiff = end.diff(start, 'days');

  return daysDiff;
};
export const secondDiffInDays = (startDate, endDate) => {
  // const oneDay = 24 * 60 * 60 * 1000; // 하루는 밀리초로 24시간 * 60분 * 60초 * 1000밀리초
  // return Math.floor((endDate - startDate) / oneDay);

  const start = moment(startDate);
  const end = moment(endDate);
  const daysDiff = end.diff(start, 'second');

  return daysDiff;
};
// export const new_date = (d = null) => {
//   const now = d ? new Date(d) : new Date(); // 현재 시간
//   // const utcNow = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
//   // const koreaTimeDiff = 18 * 60 * 60 * 1000; // 서버 시간이 utc시간임으로 맞게 보정
//   // const koreaNow = new Date(utcNow + koreaTimeDiff);
//   // return koreaNow;
//   return now;
// };

export const to_integer = (value) => {
  try {
    if (!value?.toString()) return '';
    return parseInt(value);
  } catch (e) {}
};

export const getNumbers = (value) => {
  return value?.replaceAll(/[^0-9]/g, '');
};
export const today_str = (date = null) => {
  if (date === null) date = new Date();
  return moment(date).format('YYYY-MM-DD');
};
export const today = today_str();
export const now_str = (date = null) => {
  if (date === null) date = new Date();
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};
export const getEndOfDate = (date) => {
  return moment(date).endOf('month').format('YYYY-MM-DD');
};
export const getMonth_str = (date = null) => {
  if (!date) date = new Date();
  return moment(date).format('YYYY-MM');
};
export const getStartOfDate = (date) => {
  return moment(date).startOf('month').format('YYYY-MM-DD');
};
export const isSlotUsing = (slot) => {
  if (slot?.end_date?.toString() > today?.toString()) return false;
  return true;
};
export const slotStatus = (slot) => {
  const today = new Date();
  if (slot.delete_chk) return '취소';
  else if (slot.paid_chk) return '정산 완료';
  else if (slot.close_product_chk) return '상품오류';
  else if (!slot.nvmid && !slot.keywords) return '세팅전';
  else if (new Date(slot.start_date) > today) return '진행 예정';
  else if (slot.temp_stop_chk) return '일시 중지중';
  else if (new Date(slot.end_date) >= today) return '진행 중';
  else if (new Date(slot.end_date) < today) return '기간 만료';
};
export function chunk(array, size) {
  const chunked = [];
  let index = 0;

  while (index < array.length) {
    chunked.push(array.slice(index, index + size));
    index += size;
  }

  return chunked;
}
export const TextSmall = styled.span`
  font-size: 13rem;
  font-weight: 500;
`;
export const TextSucceed = styled.span`
  color: green;
`;
export const TextWarning = styled.span`
  color: #c66d41;
`;
export const get_time = (sec) => {
  if (sec > 60 * 60) {
    return `${(sec / 60 / 60).toFixed(1)}시간`;
  } else if (sec > 60) {
    return `${(sec / 60).toFixed(1)}분`;
  } else {
    return `${sec}초`;
  }
};

export const TextFail = styled.span`
  color: red;
`;
export const valueSet = (target) => {
  const isNumber = typeof target === 'number' || Number(target) >= 0 || Number(target) <= 0;
  const array = Array.isArray(target);
  let style = '';
  array
    ? target.map((i) => {
        return (style += typeof i === 'number' ? i + 'rem ' : i + ' ');
      })
    : (style += isNumber ? `${target}rem` : target);

  return target === undefined ? target : style;
};
export const getRandomAlphabet = () => {
  // 알파벳 소문자 26개를 배열로 생성합니다.
  const alphabets = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  // 랜덤 인덱스를 생성하고 해당 인덱스의 알파벳을 리턴합니다.
  return alphabets[Math.floor(Math.random() * alphabets.length)];
};

export const random = (n) => {
  return Math.floor(Math.random() * Number(n));
};
export const pipeFn =
  (...funcs) =>
  (v) => {
    return funcs.reduce((res, func) => {
      return func(res);
    }, v);
  };

export const getPromiseAll = async (fn) => {
  const result = await Promise.all(fn);
  return result;
};

export const phoneRemoveHyphen = (number) => {
  if (!number) return;
  const newNumber = number.replace(/-/g, '');
  return newNumber;
};

export const phoneHyphen = (number) => {
  if (!number) return;
  const phoneNum = number.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  return phoneNum;
};
export const useForceUpdate = () => {
  const [ignored, newState] = useState();
  return useCallback(() => newState({}), []);
};
export function debounce(func, delay) {
  let inDebounce;
  return function (...args) {
    if (inDebounce) {
      clearTimeout(inDebounce);
    }
    inDebounce = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
