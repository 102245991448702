import { Box, Grid, TextButton } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { requestLoadWorkKeywordGetKeywordStatus } from 'Common/Service/common';
import { get_percent } from 'Common/Utils';
import styled from 'styled-components';
import { TextFail, TextSmall, TextSucceed } from 'Common/Utils/util';
import MallNameModule from './Module/MallNameModule';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const WorkKeywordStatus = ({ product_info_id, uuid }) => {
  const location = useLocation();

  const { loginInfo } = useSelector((state) => state.common);
  // if (loginInfo?.user_level !== 5) return;
  const [keywordStatusInfos, setKeywordStatusInfos] = useState();

  const getKeywordStatusInfos = async ({ product_info_id }) => {
    const keyword_status_infos = await requestLoadWorkKeywordGetKeywordStatus({ product_info_id });
    setKeywordStatusInfos(keyword_status_infos);
    // const menuItems = url_type_infos.map((e) => {
    //   return {
    //     title: `${e.url_type} (${e.count})`,
    //     value: `/work/${url_type}`,
    //   };
    // });
    // setMenuItems(menuItems);
  };
  useEffect(() => {
    if (!product_info_id) return;
    getKeywordStatusInfos({ product_info_id });
  }, [product_info_id, uuid]);
  if (!product_info_id) return;
  return (
    <>
      {/* all_count: 0, doing_count: 0, shop_done_count: 0, shop_less_count: 0, shop_search_fail_count:
      0, slot_shop_done_count: 0, slot_shop_less_count: 0, slot_shop_search_fail_count: 0,
      slot_tonghap_done_count: 0, slot_tonghap_less_count: 0, slot_tonghap_search_fail_count: 0,
      tonghap_done_count: 0, tonghap_less_count: 0, tonghap_search_fail_count: 0 */}
      <Box column gap={14}>
        {/* <Box>[미작업] [쇼핑몰] 상품명 </Box> */}
        {keywordStatusInfos?.status_list?.map((e) => {
          return (
            <Box column>
              <Box>
                [{e?.page_type?.replace('_search', '')}] {e?.pure_keyword}
              </Box>
              <Box>
                {typeof e?.allow_datetime_ago_min == 'number' ? (
                  <>[{e?.allow_datetime_ago_min} min ago]</>
                ) : (
                  <>[no used]</>
                )}{' '}
                <TextSucceed>succeed rate</TextSucceed> [
                {get_percent(e?.check_ok_count, e?.check_allow_count)} %]{' '}
                {e.check_search_fail_count >= 2 ? <TextFail>fail</TextFail> : 'fail'}:{' '}
                {e?.check_search_fail_count}/5{' '}
                {e?.block_search_fail_chk ? (
                  <TextFail>
                    block[{e?.block_search_fail_date} {e?.block_search_fail_date_ago_day} days ago]
                  </TextFail>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          );
        })}

        <Box column gap={4}>
          <Box bottom={15}>
            <MallNameModule {...keywordStatusInfos?.shop_info} />
          </Box>
          <Box>
            Tonghap 키워드 추출 실패 (
            <TextFail>{keywordStatusInfos?.check_fail_tonghap_search_keywords?.length}</TextFail>){' '}
            <TextSmall>Get Tonghap Keywords Failed</TextSmall>
          </Box>
          <Box column>
            {keywordStatusInfos?.check_fail_tonghap_search_keywords?.map((e) => {
              return (
                <Box>
                  <TextSmall>
                    <TextFail>{e}</TextFail>
                  </TextSmall>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box column gap={4}>
          <Box>
            Shop 키워드 추출 실패 (
            <TextFail>{keywordStatusInfos?.check_fail_shop_search_keywords?.length}</TextFail>){' '}
            <TextSmall>Get Shop Keywords Failed</TextSmall>{' '}
          </Box>
          <Box column>
            {keywordStatusInfos?.check_fail_shop_search_keywords?.map((e) => {
              return (
                <Box>
                  <TextSmall>
                    <TextFail>{e}</TextFail>
                  </TextSmall>
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* all_search_fail_count==0 */}

        {/*  all_search_fail_count > 0 */}

        {/*  all_search_fail_count > 0 */}

        {/* [] 쇼핑검색 */}

        {/* 성공율 미검색성공율 all_search_fail_count > 0 [부족] 상품별: 25 % 통합키워드 25%, 쇼핑키워드
        25% field :check_tonghap_search_ok_count, :integer, default: 0 field
        :all_tonghap_search_ok_count, :integer, default: 0 field :check_shop_search_ok_count,
        :integer, default: 0 field :all_shop_search_ok_count, :integer, default: 0 field
        :check_search_ok_count, :integer, default: 0 field :all_search_ok_count, :integer, default:
        0 field :check_tonghap_search_fail_count, :integer, default: 0 field
        :all_tonghap_search_fail_count, :integer, default: 0 field :check_shop_search_fail_count,
        :integer, default: 0 field :all_shop_search_fail_count, :integer, default: 0 field
        :check_search_fail_count, :integer, default: 0 field :all_search_fail_count, :integer,
        default: 0 field :check_tonghap_search_access_count, :integer, default: 0 field
        :all_tonghap_search_access_count, :integer, default: 0 field
        :check_shop_search_access_count, :integer, default: 0 field :all_shop_search_access_count,
        :integer, default: 0 field :check_search_access_count, :integer, default: 0 field
        :all_search_access_count, :integer, default: 0 field :tonghap_search_ok_date,
        Timex.Ecto.Date field :tonghap_search_ok_datetime, Timex.Ecto.DateTime field
        :shop_search_ok_date, Timex.Ecto.Date field :shop_search_ok_datetime, Timex.Ecto.DateTime
        field :search_ok_date, Timex.Ecto.Date field :search_ok_datetime, Timex.Ecto.DateTime field
        :tonghap_search_fail_date, Timex.Ecto.Date field :tonghap_search_fail_datetime,
        Timex.Ecto.DateTime field :shop_search_fail_date, Timex.Ecto.Date field
        :shop_search_fail_datetime, Timex.Ecto.DateTime field :search_fail_date, Timex.Ecto.Date
        field :search_fail_datetime, Timex.Ecto.DateTime */}
      </Box>
    </>
  );
};
export default WorkKeywordStatus;
