import React from 'react';
import { BottomGroup, Content, InnerBox, Sub, Title, Wrapper } from './styled';
import Overlays from '../../atoms/Overlays';
import TextButton from '../../atoms/TextButton';
import Icon from '../../atoms/IconBox';
import Confirm from './Confirm';
import Share from './Share';
import SelectCard from './SelectCard';
import { Button } from 'Common/UI/atoms';

function Modal({
  isActive,
  isBlind,
  title,
  titleIcon,
  subTit,
  children,
  onSuccess,
  onClose,
  onCancel,
  successText = '확인',
  cancelText = '취소',
  successForm,
  leftContent,
  close,
  timer,
  zIndex,
  blur,
  className,
  isOuterDelete = true,
  size,
  background,
  padding,
  titleContent,
}) {
  return (
    <Overlays
      isActive={isActive}
      isBlind={isBlind}
      blur={blur}
      timer={timer}
      zIndex={zIndex}
      onClose={onClose}
      isOuterDelete={isOuterDelete}
    >
      <Wrapper className={className} size={size}>
        <InnerBox padding={padding}>
          {title ? (
            <Title>
              {titleIcon}
              {title}
              {close && (
                <Button onClick={() => onClose()} type="button" className="btnClose">
                  <Icon.Closer size="xxs" />
                </Button>
              )}
              {titleContent ? titleContent : <></>}
            </Title>
          ) : (
            ''
          )}
          {subTit ? <Sub>{subTit}</Sub> : ''}
          {children ? (
            <Content className="modalContent" background={background}>
              {children}
            </Content>
          ) : (
            ''
          )}
        </InnerBox>
        <BottomGroup>
          {leftContent}
          <div className="rightBtn">
            {cancelText && (
              <TextButton size="md" onClick={onCancel || onClose}>
                {cancelText}
              </TextButton>
            )}
            {successText && (
              <TextButton
                size="md"
                type="submit"
                form={successForm}
                Theme={'primary'}
                onClick={onSuccess ? onSuccess : undefined}
                disabled={!onSuccess}
              >
                {successText}
              </TextButton>
            )}
          </div>
        </BottomGroup>
      </Wrapper>
    </Overlays>
  );
}
Modal.Confirm = Confirm;
Modal.Share = Share;
Modal.SelectCard = SelectCard;
export default Modal;
