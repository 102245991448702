import React, { forwardRef, useEffect } from "react";
import { formatDate } from "Common/Utils/date";
import { InputComponent, Icon } from "Common/UI/atoms";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { TimepickerBox } from "./components/styled";
import useDatepicker from "./useDatepicker";
const TimepickerCompoent = ({
    value,
    date = value,
    fill,
    onChange,
    isSingle = true,
    ...props
}) => {
    const {
        ref,
        state,
        onChange: onChangeTime,
        onCalendarOpen,
        onCalendarClose,
    } = useDatepicker(date);
    useEffect(() => {
        onChange(state);
    }, [state]);

    const IconButton = forwardRef(
        ({ onClick, placeholder = "00:00", ...props }, ref) => (
            <InputComponent
                size="44"
                isDelete={false}
                rightIcon={<Icon.Watch size={12} />}
                value={date ? formatDate(date).dateTime : ""}
                onClick={onClick}
                dateFormat="HH:mm"
                autocomplete="off"
                placeholder={placeholder}
                {...props}
            />
        )
    );

    return (
        <TimepickerBox ref={ref} fill={fill}>
            <DatePicker
                customInput={<IconButton {...props} />}
                locale={ko}
                showPopperArrow={false}
                {...props}
                selected={isSingle ? state : date}
                onChange={(date) => onChangeTime(date)}
                onCalendarOpen={onCalendarOpen}
                onCalendarClose={onCalendarClose}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                ref={ref}
                timeIntervals={30}
            ></DatePicker>
        </TimepickerBox>
    );
};

export default TimepickerCompoent;
