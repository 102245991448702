import {
  requestLoadWorkBasicUrlTypeGetAddTypeDates,
  requestLoadWorkBasicUrlTypeGetRequiredBrowserTypes,
  requestLoadWorkRefilterUrlTypeGetInfos,
} from 'Common/Service/common';
import useMutations from 'Common/Service/userMutations';
import { Button, DropBox, Grid, InputComponent, TextButton } from 'Common/UI/atoms';
import Box from 'Common/UI/atoms/Box';
import LabelBox from 'Common/UI/molecules/LabelBox';
import { currencyComma, getAlphaAndNumber } from 'Common/Utils';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  reload_second,
  today_str,
} from 'Common/Utils/util';
import { onShowToast } from 'features/common/commonSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import WorkRefilterUrlFetchAccessLog from './WorkRefilterUrlTypeFetchAccessLog';

const WorkBasicUrlTypeRequired = () => {
  const [requiredLogs, setRequiredLogs] = useState([]);
  const [requiredDateLogs, setRequiredDateLogs] = useState([]);
  const [requiredBrowserTypes, setRequiredBrowserTypes] = useState([]);
  const [date, setDate] = useState(today_str());
  const { loginInfo } = useSelector((state) => state.common);
  const [count, setCount] = useState(reload_second);
  const [apiId, setApidId] = useState();

  // const dispatch = useDispatch();
  // const [count, setCount] = useState(reload_second);
  const { browser_type } = useParams();
  const dispatch = useDispatch();

  const getBrowserTypeInfos = async () => {
    // const url_type_r = url_type?.replaceAll('_backslash_', '/')?.replaceAll('_question_', '?');
    const infos = await requestLoadWorkBasicUrlTypeGetRequiredBrowserTypes({ browser_type, date });
    setRequiredLogs(infos?.url_type_requireds);
    setRequiredDateLogs(infos?.required_date_logs);
    setRequiredBrowserTypes(infos?.required_browser_types);
  };
  const navigate = useNavigate();
  useEffect(() => {
    clearInterval(apiId);
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getBrowserTypeInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApidId(intervalId);
    return () => clearInterval(intervalId);
  }, [browser_type, date]);

  const CopyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(
        onShowToast({
          status: 'success',
          message: 'Clipboard copy succeeded.',
          cancelText: '',
          onCancel: false,
        }),
      );
    } catch (error) {
      dispatch(
        onShowToast({
          status: 'error',
          message: 'Clipboard copy failed.',
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };
  useEffect(() => {
    clearInterval(apiId);

    getBrowserTypeInfos();
  }, [browser_type, date]);

  return (
    <Box column>
      {/* <Wrapper> */}
      <Box top={-10} bottom={-40}>
        {/* {browser_type} */}
        <TextSmall>{count}</TextSmall>
      </Box>
      <Grid
        column={['200rem', 'auto']}
        gap={32}
        style={'background: red'}
        valign="top"
        height="1000"
      >
        {/* <Box column> */}
        <Box column gap={24}>
          {requiredDateLogs?.map((e, index) => {
            return (
              <UrlInfoBox
                active={e?.date === date}
                key={index}
                onClick={() => {
                  setDate(e?.date);
                  // setUrlTypeLogId(e.id);
                }}
              >
                <Box>
                  <Box>{e?.date}</Box>
                  <Box>
                    <TextFail>{currencyComma(e?.count)}</TextFail>
                  </Box>
                </Box>
              </UrlInfoBox>
            );
          })}
        </Box>
        {/* </Box> */}
        {/* <Box column> */}
        <Box valign="top" column gap={24}>
          <Box>
            {requiredBrowserTypes?.map((e, index) => {
              return (
                <Box key={index}>
                  <TextButton
                    Theme={
                      browser_type === e?.browser_type || today_str() !== today_str(date)
                        ? 'primary'
                        : null
                    }
                    onClick={() => {
                      navigate(`/work/basic_url_type/url_type_required/${e?.browser_type}`);
                    }}
                  >
                    {e?.browser_type} ({e?.count})
                  </TextButton>
                </Box>
              );
            })}
          </Box>
          <Box column gap={30}>
            {requiredLogs?.map((e, index) => {
              return (
                <UrlInfoBox
                  key={index}
                  // onClick={() => {
                  // setUrlTypeLogId(e.id);
                  // }}
                >
                  <Box>
                    <Box>
                      <TextSmall>[{index + 1}]</TextSmall>
                    </Box>
                    <Box>
                      <TextSmall>{e?.datetime?.slice(0, 19)}</TextSmall>
                    </Box>
                    <Box>
                      <TextSmall>
                        <TextSucceed>{e?.browser_type}</TextSucceed>
                      </TextSmall>
                    </Box>
                    <Box>
                      <TextSmall>
                        <TextWarning>{e?.pure_url_type}</TextWarning>
                      </TextSmall>
                    </Box>
                    <Box>
                      <button
                        onClick={async () => {
                          CopyText(e?.pure_url);
                        }}
                      >
                        <TextSmall>
                          {e?.pure_url} <TextSucceed>copy</TextSucceed>
                        </TextSmall>
                      </button>
                    </Box>
                  </Box>
                </UrlInfoBox>
              );
            })}
          </Box>
        </Box>
        {/* </Box> */}
      </Grid>
      {/* </Wrapper> */}
    </Box>
  );
};
const UrlInfoBox = styled.button`
  padding: 0 12rem;
  transition: 0.3s;

  &:hover {
    color: var(--P_700);
    &::after {
      background-color: var(--P_700);
    }
  }
  ${(props) => {
    const { active } = props;
    if (active)
      return css`
        border-style: solid;

        border-radius: 8rem;
        border-width: 1rem;
        border-color: #f0f0f0;

        background-color: #fff2f2;
      `;
  }}
`;
export const Wrapper = styled.div`
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: left;
`;

export default WorkBasicUrlTypeRequired;
