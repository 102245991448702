import { valueSet } from 'Common/Utils/util';
import React from 'react';
import styled from 'styled-components';

const Grid = ({
  row = 1,
  column = 1,
  children,
  top,
  gap = 0,
  padding = [40, 0, 0],
  style,
  width,
  areas,
  isSection = true,
}) => {
  const gridSet = (target) => {
    const type = typeof target;
    const array = Array.isArray(target);
    let style = '';
    array
      ? target.map((i) => {
          return (style += typeof i === 'number' ? i + 'fr ' : i + ' ');
        })
      : (style += type === 'number' ? `repeat(${target},1fr)` : target);
    return style;
  };

  return (
    <>
      {isSection ? (
        <Wrapper
          areas={areas}
          styles={style}
          top={valueSet(top)}
          row={gridSet(row)}
          column={gridSet(column)}
          gap={valueSet(gap)}
          padding={padding && valueSet(padding)}
        >
          {children}
        </Wrapper>
      ) : (
        <WrapperMini
          areas={areas}
          styles={style}
          top={valueSet(top)}
          row={gridSet(row)}
          sizeX={width && valueSet(width)}
          column={gridSet(column)}
          className="grid"
          gap={valueSet(gap)}
          padding={padding && valueSet(padding)}
        >
          {children}
        </WrapperMini>
      )}
    </>
  );
};

const Wrapper = styled.section`
  ${(props) => {
    const { row, column, gap, styles, areas, top, padding } = props;
    return `
            display:grid;

            min-height:calc(100vh - 80rem);
            ${top ? `padding-top:${top};` : ''}
            ${
              areas ||
              `
            grid-template-rows: ${row};
            grid-template-columns: ${column};
            `
            }
            ${padding ? `padding:${padding};` : ''}
            gap:${gap};
            transition: all 0.3s;

            ${styles || ''}
            
            >div:not(.board)>.title {
              margin-bottom: 28rem;
            }
            
            .sideTabFullSticky{
              width: 280rem;
              height: calc(100vh - 160rem);
              top: 120rem;
              position: sticky;
              background-color: var(--White);
              box-shadow: var(--Elevation_001);
            }
            .sideFullSticky{
              width: 360rem;
              height: calc(100vh - 160rem);
              top: 120rem;
              position: sticky;
              background-color: var(--White);
              box-shadow: var(--Elevation_001);
            }
            .board {
                width: 100%;
                display: flex;
                flex-direction: column;
                height: fit-content;
                &.initial{
                    height: initial;
                }
                flex: 1 1;
                padding: 40rem 60rem;
                border-radius: 20rem;
                background-color: var(--White);
                box-shadow: var(--Elevation_001);
            }
        `;
  }}
`;
const WrapperMini = styled.div`
  ${({ row, column, gap, styles, sizeX, areas, padding, top }) => `
        display:grid;
        ${top ? `padding-top:${top};` : ''}
        ${
          areas ||
          `
            ${sizeX ? `width:${sizeX};` : ''}
        grid-template-rows: ${row};
        grid-template-columns: ${column};
        transition: all 0.3s;
        `
        }
        ${padding ? `padding:${padding};` : ''}
        gap:${gap};
        ${styles || ''}
    `}
`;

export default Grid;
