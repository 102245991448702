import { Icon } from "Common/UI/atoms";
import { formatDate } from "Common/Utils/date";
import React from "react";
import { DatepickerHeaderBox } from "./styled";

function DatepickerHeader({
    date,
    decreaseMonth,
    prevMonthButtonDisabled,
    increaseMonth,
    nextMonthButtonDisabled,
}) {
    return (
        <DatepickerHeaderBox>
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <Icon.Arrow size="xxs" direction={"left"} />
            </button>
            <span className="headerDate">{formatDate(date).yearMonthKo}</span>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <Icon.Arrow size="xxs" direction={"right"} />
            </button>
        </DatepickerHeaderBox>
    );
}

export default DatepickerHeader;
