import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../atoms';
import { useNavigate } from 'react-router-dom';

const WorkStatusBox = ({ style, current, onClick, items }) => {
  const currentItem = items.filter((i) => i.value === current)[0];
  const navigate = useNavigate();
  return (
    <>
      <Wrapper styles={style}>
        {items?.map((list, key) => (
          <React.Fragment key={key}>
            <Item
              type="button"
              // className={list.value === current ? 'active' : ''}
              to={list?.to}
              onClick={() => {
                // onClick && onClick(list.value, key);
                if (list?.to) {
                  navigate(list?.to);
                }
              }}
            >
              <h5>{list.title}</h5>
              <h2>
                {list.main_text}
                {/* 100% */}
                <span>
                  <b>{list.color_text}</b>
                  {list.text}
                  {/* / 33 */}
                </span>
              </h2>

              {list.items && (
                <Box column gap={2}>
                  {list.items?.map((data, sKey) => (
                    <p key={sKey}>{data}</p>
                  ))}
                </Box>
              )}
            </Item>
          </React.Fragment>
        ))}
      </Wrapper>
      {currentItem?.panel}
    </>
  );
};
// const Number = styled.div``;
const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  background: var(--White);
  box-shadow: var(--Elevation_001);
  border-radius: 12rem;
  overflow: hidden;
`;
const Item = styled.button`
  flex: 1;
  padding: 40rem 0 32rem 48rem;
  text-align: left;
  transition: 0.2s ease-in;
  cursor: default;
  ${(props) => {
    const { to } = props;
    if (to)
      return css`
        cursor: pointer;
      `;
  }}
  h5 {
    margin-bottom: 8rem;
    font-weight: 400;
    font-size: 14rem;
    line-height: 172%;
    color: var(--G_300);
  }
  h2 {
    font-weight: 500;
    font-size: 30rem;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: var(--Success_500);
    span {
      margin-left: 8rem;
      font-weight: 400;
      font-size: 20rem;
      line-height: 172%;
      letter-spacing: -0.03em;
      color: var(--G_300);
      b {
        color: var(--Success_300);
      }
    }
  }

  + button {
    border-left: 1px solid var(--G_50);
  }
  &:hover {
    background: var(--P_25);
    box-shadow: var(--Elevation_002);
    h2 {
      color: var(--P_500);
    }
  }
  &.active {
    background: var(--P_60);
    h2 {
      color: var(--P_800);
    }
  }
  &:focus {
    background: var(--P_50);
    h2 {
      color: var(--P_700);
    }
  }
`;

export default WorkStatusBox;
