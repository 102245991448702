import { Box, Grid } from 'Common/UI/atoms';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { requestLoadWorkRankGetInfos } from 'Common/Service/common';
import { get_percent } from 'Common/Utils';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  getKorDate,
  get_uuid,
  reload_second,
  today_str,
} from 'Common/Utils/util';
import styled, { css } from 'styled-components';
import WorkRankDateLog from './WorkRankDateLog';

const WorkRank = () => {
  const { loginInfo } = useSelector((state) => state.common);
  const [rankInfos, setRankInfos] = useState([]);
  const [count, setCount] = useState(reload_second);

  const [today, setToday] = useState(today_str(getKorDate()));
  // if (loginInfo?.user_level !== 5) return;
  const [checkDate, setCheckDate] = useState(today_str(getKorDate()));
  const [uuid, setUuid] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        setToday(today_str(getKorDate()));
        if (ct < 0) {
          getRankInfos();
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const getRankInfos = async () => {
    const url_type_infos = await requestLoadWorkRankGetInfos({});
    setRankInfos(url_type_infos);

    // setMenuItems(menuItems);
  };
  useEffect(() => {
    getRankInfos();
  }, []);
  return (
    <Box column>
      <Box>
        do_rank() <TextSmall>{count}</TextSmall>
      </Box>
      <Box top={-25}>
        <Grid column={['1fr', '2fr']} gap={32} valign="top">
          <Box column gap={24}>
            {rankInfos?.rank_date_logs?.map((e) => {
              const not_try_count = e.not_try_count;
              const done_count = e.done_count;
              const fail_count = e.fail_count;
              const fail_done_count = e.fail_done_count;
              const all_count = e.all_count;
              const count = done_count + fail_done_count;
              const per = get_percent(count, all_count);
              return (
                <RankInfoBox
                  column
                  active={today == e.date}
                  selected={e.date == checkDate}
                  onClick={() => {
                    setCheckDate(e?.date);
                    setUuid(get_uuid());
                  }}
                >
                  <Box>{e.date}</Box>
                  <Box>
                    <Box>
                      랭킹 체크 상황<TextSmall>Ranking Check Progress</TextSmall>
                    </Box>
                    <Box>
                      [
                      {per >= 100 ? (
                        <>
                          <TextSucceed>{per}</TextSucceed>{' '}
                        </>
                      ) : per > 80 ? (
                        <>
                          <TextWarning>{per}</TextWarning>{' '}
                        </>
                      ) : (
                        <>
                          <TextFail>{per}</TextFail>{' '}
                        </>
                      )}
                      %] {count} / {all_count}
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      체크 안된 키워드 <TextSmall>Unchecked Ranking Keywords</TextSmall>
                    </Box>
                    <Box>{not_try_count}</Box>
                  </Box>
                  <Box>
                    <Box>
                      랭킹 랭킹 체크 1회 이상 실패한 키워드{' '}
                      <TextSmall>Keywords Failed Ranking Checks at Least Once</TextSmall>
                    </Box>
                    <Box>{fail_count}</Box>
                  </Box>
                  <Box>
                    <Box>
                      {' '}
                      랭킹 랭킹 체크 최종 실패한 키워드{' '}
                      <TextSmall>Keywords Final Ranking Check Failure</TextSmall>
                    </Box>
                    <Box>{fail_done_count}</Box>
                  </Box>
                </RankInfoBox>
              );
            })}
          </Box>
          <Box valign={'top'}>
            <WorkRankDateLog date={checkDate} uuid={uuid} />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
const RankInfoBox = styled.button`
  display: flex;
  flex-direction: column;
  ${(props) => {
    const { active, selected } = props;
    if (selected && active) {
      return css`
        /* color: #fff; */
        border-radius: 8rem;
        padding: 0 10rem;
        border-color: blue;
        border-width: 1rem;
        border-style: solid;
        font-weight: 500;
        background-color: #f0f0f0;
      `;
    } else if (active) {
      return css`
        /* color: #fff; */
        border-radius: 8rem;
        padding: 0 10rem;
        border-color: blue;
        border-width: 1rem;
        border-style: solid;
        font-weight: 500;
      `;
    } else if (selected) {
      return css`
        /* color: #fff; */
        background-color: #f0f0f0;
      `;
    }
  }}
`;
export default WorkRank;
