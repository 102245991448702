import React, { useEffect, useState } from 'react';
import { TextFail, TextSucceed, TextWarning, get_time } from 'Common/Utils/util';
import { currencyComma, divide, get_percent, sortSet } from 'Common/Utils';
import styled from 'styled-components';
import { Box, CheckBox } from 'Common/UI/atoms';
import PercentModule from './PercentModule';
import TrafficSizeModule from './TrafficSizeModule';
// import { requestLoadWorkRefilterUrltypeGetUrlType } from 'Common/Service/common';

const ShowInfoModule = ({ info }) => {
  return (
    <InfoBlock>
      <Box>
        {currencyComma(info?.succeed_count)} / {currencyComma(info?.count)}{' '}
        <PercentModule value={info?.succeed_count} valueMax={info?.count} /> 총제제수
        <TextFail>{currencyComma(divide(info?.visit_problem_count, info?.count, 1))}</TextFail>회
      </Box>
      <Box>
        cpu 사용률: <TextSucceed>{divide(info?.cpu_usage, info?.count, 1)}</TextSucceed>% 메모리
        사용률: <TextSucceed>{divide(info?.cpu_memory, info?.count, 1)}</TextSucceed>%
      </Box>
      <Box>
        유입 시도 전체 초:{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_network_etc_do1_total_sec, info?.count, 0))}
        </TextSucceed>
        트래픽:{' '}
        <TrafficSizeModule size={info?.url_access_request_size + info?.url_access_response_size} />
      </Box>
      <Box>
        컴퓨터:{' '}
        <PercentModule
          value={info?.calc_all_program_process_using_sec}
          valueMax={
            info?.calc_all_program_process_using_sec + info?.calc_all_network_process_using_sec
          }
          n={1}
        />
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_program_process_using_sec, info?.count, 0))}
        </TextSucceed>
        초 네트웍:{' '}
        <PercentModule
          value={info?.calc_all_network_process_using_sec}
          valueMax={
            info?.calc_all_program_process_using_sec + info?.calc_all_network_process_using_sec
          }
          n={1}
        />
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_network_process_using_sec, info?.count, 0))}
        </TextSucceed>
        초
      </Box>
      <Box>
        [
        <PercentModule
          value={info?.calc_all_active_using_sec}
          valueMax={info?.calc_network_etc_do1_total_sec}
          n={1}
        />
        ] 활동{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_active_count, info?.count, 1))}
        </TextSucceed>
        회{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_active_using_sec, info?.count, 1))}
        </TextSucceed>
        초 제제{' '}
        <TextFail>
          {currencyComma(divide(info?.calc_all_active_problem_count, info?.count, 1))}
        </TextFail>
        회
        <TextFail>
          {currencyComma(divide(info?.calc_all_active_problem_sec, info?.count, 1))}
        </TextFail>
        초
      </Box>
      <Box>
        [
        <PercentModule
          value={info?.calc_all_tonghap_search_using_sec}
          valueMax={info?.calc_network_etc_do1_total_sec}
          n={1}
        />
        ] 통합검색{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_tonghap_search_count, info?.count, 1))}
        </TextSucceed>
        회{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_tonghap_search_using_sec, info?.count, 1))}
        </TextSucceed>
        초 제제{' '}
        <TextFail>
          {currencyComma(divide(info?.calc_all_tonghap_search_problem_count, info?.count, 1))}
        </TextFail>
        회
        <TextFail>
          {currencyComma(divide(info?.calc_all_tonghap_search_problem_sec, info?.count, 1))}
        </TextFail>
        초
      </Box>
      <Box>
        [
        <PercentModule
          value={info?.calc_all_shop_search_using_sec}
          valueMax={info?.calc_network_etc_do1_total_sec}
          n={1}
        />
        ] 쇼핑검색{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_shop_search_count, info?.count, 1))}
        </TextSucceed>
        회{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_shop_search_using_sec, info?.count, 1))}
        </TextSucceed>
        초 제제{' '}
        <TextFail>
          {currencyComma(divide(info?.calc_all_shop_search_problem_count, info?.count, 1))}
        </TextFail>
        회
        <TextFail>
          {currencyComma(divide(info?.calc_all_shop_search_problem_sec, info?.count, 1))}
        </TextFail>
        초
      </Box>
      <Box>
        [
        <PercentModule
          value={info?.calc_all_click_using_sec}
          valueMax={info?.calc_network_etc_do1_total_sec}
          n={1}
        />
        ] 클릭{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_click_count, info?.count, 1))}
        </TextSucceed>
        회{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_click_using_sec, info?.count, 1))}
        </TextSucceed>
        초 제제{' '}
        <TextFail>
          {currencyComma(divide(info?.calc_all_click_problem_count, info?.count, 1))}
        </TextFail>
        회
        <TextFail>
          {currencyComma(divide(info?.calc_all_click_problem_sec, info?.count, 1))}
        </TextFail>
        초
      </Box>
      <Box>
        [
        <PercentModule
          value={info?.calc_all_etc_do1_using_sec}
          valueMax={info?.calc_network_etc_do1_total_sec}
          n={1}
        />
        ] 유입활동{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_etc_do1_count, info?.count, 1))}
        </TextSucceed>
        회{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_etc_do1_using_sec, info?.count, 1))}
        </TextSucceed>
        초 제제{' '}
        <TextFail>
          {currencyComma(divide(info?.calc_all_etc_do1_problem_count, info?.count, 1))}
        </TextFail>
        회
        <TextFail>
          {currencyComma(divide(info?.calc_all_etc_do1_problem_sec, info?.count, 1))}
        </TextFail>
        초
      </Box>
      <Box>
        [
        <PercentModule
          value={info?.calc_all_into_shop_using_sec}
          valueMax={info?.calc_network_etc_do1_total_sec}
          n={1}
        />
        ] 상품진입{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_into_shop_count, info?.count, 1))}
        </TextSucceed>
        회{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_all_into_shop_using_sec, info?.count, 1))}
        </TextSucceed>
        초 제제{' '}
        <TextFail>
          {currencyComma(divide(info?.calc_all_into_shop_problem_count, info?.count, 1))}
        </TextFail>
        회
        <TextFail>
          {currencyComma(divide(info?.calc_all_into_shop_problem_sec, info?.count, 1))}
        </TextFail>
        초
      </Box>
      <Box>
        아이피 변환 횟수{' '}
        <TextSucceed>
          {currencyComma(divide(info?.calc_proxy_ip_change_count, info?.count, 1))}
        </TextSucceed>
        회 <TextSucceed>{divide(info?.calc_proxy_ip_change_using_sec, info?.count, 1)}</TextSucceed>
        초 실패 <TextFail>{divide(info?.calc_proxy_ip_change_fail_count, info?.count, 1)}</TextFail>
      </Box>
      <Box>
        아이피 변환시 할당된 아이피수{' '}
        <TextSucceed>
          {currencyComma(
            divide(info?.calc_proxy_ip_change_ip_address_list_uniq_count, info?.count, 0),
          )}
        </TextSucceed>{' '}
        할당된 국가수:{' '}
        <TextSucceed>
          {currencyComma(
            divide(info?.calc_proxy_ip_change_ip_country_list_uniq_count, info?.count, 0),
          )}
        </TextSucceed>{' '}
      </Box>
      <Box valign="top">
        <LineBox column>
          <Box>유입성공 국가</Box>
          {info?.proxy_ip_infos
            ?.filter((e) => e?.count)
            ?.slice(0, 10)
            ?.sort((b, a) => sortSet(a?.succeed_count, b?.succeed_count))
            ?.map((proxy_info) => {
              return (
                <SubBox column>
                  <Box>
                    <Box>{proxy_info?.proxy_ip_country}</Box>
                    <Box>
                      <TextSucceed>{currencyComma(proxy_info?.succeed_count)}</TextSucceed>/
                      {currencyComma(proxy_info?.count)}
                    </Box>
                    <Box>
                      <PercentModule
                        value={proxy_info?.succeed_count}
                        valueMax={proxy_info?.count}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <TextFail>{proxy_info?.count}</TextFail>회 (진행시간 평균
                    <TextFail>
                      {currencyComma(
                        divide(proxy_info?.calc_network_etc_do1_total_sec, proxy_info?.count, 0),
                      )}
                    </TextFail>
                    초)
                  </Box>

                  <Box>
                    평균 제제 발견수{' '}
                    {divide(
                      proxy_info?.calc_network_error_etc_do1_problem_count,
                      proxy_info?.count,
                    )}{' '}
                    평균:
                    {divide(
                      proxy_info?.calc_network_error_etc_do1_problem_using_sec,
                      proxy_info?.count,
                    )}
                    초
                  </Box>
                </SubBox>
              );
            })}
        </LineBox>
        <LineBox column>
          <Box>유입실패 국가</Box>
          {info?.proxy_ip_infos
            ?.filter((e) => e?.count)
            ?.slice(0, 10)
            ?.sort((b, a) => sortSet(a?.count - a?.succeed_count, b?.count - b?.succeed_count))
            ?.map((proxy_info) => {
              return (
                <SubBox column>
                  <Box>
                    <Box>{proxy_info?.proxy_ip_country}</Box>
                    <Box>
                      <TextFail>{currencyComma(proxy_info?.succeed_count)}</TextFail>/
                      {currencyComma(proxy_info?.count)}
                    </Box>
                    <Box>
                      <PercentModule
                        value={proxy_info?.succeed_count}
                        valueMax={proxy_info?.count}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <TextFail>{proxy_info?.count}</TextFail>회 (진행시간 평균
                    <TextFail>
                      {currencyComma(
                        divide(proxy_info?.calc_network_etc_do1_total_sec, proxy_info?.count, 0),
                      )}
                    </TextFail>
                    초)
                  </Box>
                  <Box>
                    평균 제제 발견수 {divide(proxy_info?.visit_problem_count, proxy_info?.count)}
                  </Box>
                  <Box>
                    평균 제제 발견수{' '}
                    {divide(
                      proxy_info?.calc_network_error_etc_do1_problem_count,
                      proxy_info?.count,
                    )}{' '}
                    평균:
                    {divide(
                      proxy_info?.calc_network_error_etc_do1_problem_using_sec,
                      proxy_info?.count,
                    )}
                    초
                  </Box>
                </SubBox>
              );
            })}
        </LineBox>
      </Box>

      <Box width="100%">
        <LineBox column width="100%" valign="top" height="100%">
          <Box>
            유입 실패 원인 (총:
            <TextFail>{currencyComma(info?.last_error_status_infos_count)}</TextFail>회)
          </Box>
          {info?.last_error_status_infos
            ?.filter((e) => e?.count)
            ?.sort((b, a) => sortSet(a?.count, b?.count))
            ?.slice(0, 15)
            ?.map((last_error_status_info) => {
              const last_error_status = last_error_status_info?.last_error_status;
              var error_status = last_error_status;
              if (last_error_status === 'tonghap_search_error') {
                error_status = '통합검색실패';
              } else if (last_error_status === 'shop_search_error') {
                error_status = '쇼핑검색실패';
              } else if (last_error_status === 'get_foryou_url_error') {
                error_status = '유입 제제';
              } else if (last_error_status === 'get_foryou_ce_list_error') {
                error_status = '유입 활동';
              } else if (last_error_status === 'get_foryou_ce_list_error') {
                error_status = '상품 진입';
              } else if (last_error_status === 'network_click_error') {
                error_status = '상품 클릭';
              } else if (last_error_status === 'timeout') {
                error_status = '시간 초과';
              } else if (last_error_status === 'into_product_error') {
                error_status = '상품 진입 실패';
              } else if (last_error_status === 'reload_product_error') {
                error_status = '상품 진입후 재시도 실패';
              } else if (last_error_status === 'network_click_foryou_error') {
                error_status = '관심 물품 클릭 실패';
              } else if (!last_error_status) {
                error_status = '원인 불명';
              }

              return (
                <SubBox>
                  <Box>{error_status}</Box>
                  <Box>
                    <TextFail>{last_error_status_info?.count}</TextFail>회 (진행시간 평균
                    <TextFail>
                      {currencyComma(
                        divide(
                          last_error_status_info?.calc_network_etc_do1_total_sec,
                          last_error_status_info?.count,
                          0,
                        ),
                      )}
                    </TextFail>
                    초) 전체({get_time(last_error_status_info?.calc_network_etc_do1_total_sec)})
                  </Box>
                  <Box>
                    평균 제제 발견수{' '}
                    {divide(
                      last_error_status_info?.calc_network_error_etc_do1_problem_count,
                      last_error_status_info?.count,
                    )}{' '}
                    평균:
                    {divide(
                      last_error_status_info?.calc_network_error_etc_do1_problem_using_sec,
                      last_error_status_info?.count,
                    )}
                    초
                  </Box>
                </SubBox>
              );
            })}
        </LineBox>
        {/* <Box column width="100%" valign="top" height="100%">
          <Box>유입실패 진행상태</Box>
          {info?.last_which_infos
            ?.filter((e) => e?.count)
            ?.slice(0, 10)
            ?.sort((a, b) => sortSet(a?.succeed_count / a?.count, b?.succeed_count / b?.count))
            ?.map((last_which_info) => {
              return (
                <Box>
                  <Box>{last_which_info?.last_which}</Box>
                  <Box>
                    <TextFail>
                      {currencyComma(
                        divide(
                          last_which_info?.calc_network_etc_do1_total_sec,
                          last_which_info?.count,
                          0,
                        ),
                      )}
                    </TextFail>
                    초
                  </Box>
                  <Box>
                    <TextFail>{currencyComma(last_which_info?.succeed_count)}</TextFail>/
                    {currencyComma(last_which_info?.count)}
                  </Box>
                  <Box>
                    <PercentModule
                      value={last_which_info?.succeed_count}
                      valueMax={last_which_info?.count}
                    />
                  </Box>
                </Box>
              );
            })}
        </Box> */}
      </Box>

      <Box>
        <CheckBox>설정 사항 보기</CheckBox>
      </Box>
      {/* <Box>브라우저당 유입시도: 45회 setup_try_count_etc_do1</Box>
      <Box>유입 횟수실패 종료 제한: setup_stop_count_after_fail</Box>

      <Box>검색실패 재시도 45회, 클릭실패 재시도 setup_try_count_click_fail</Box>
      <Box>
        유입활동실패 재시도 setup_try_count_etc_do1_fail 상품진입실패 재시도
        setup_try_count_into_product_fail
      </Box>

      <Box>URL 엑세스 시간 제한 45초 , </Box>
      <Box>제제 발견시 URL당 45회까지 시도 45회 마다 아이피 변환 시도</Box>
      <Box>400회 발견시 유입 시도 종료</Box>
      <Box>브라우저 첫시작 45% 확률로 4회 시도후 45초 대기</Box>
      <Box>유입 시도 전 45% 확률로 4회 시도후 45초 대기</Box> */}
    </InfoBlock>
  );
};

const SetupBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem;
  /* gap: 10rem; */
`;
const HelpStatus = styled.div`
  font-size: 11rem;
  font-weight: 400;
  color: #0000a5;
`;
const SetupInfoDefaultBlock = styled.div`
  padding: 10rem 0rem 10rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  margin-bottom: 10rem;

  border-width: 1rem;
  border-style: solid;
  /* border-color: transparent; */
  /* &:hover { */
  /* background-color: #f0f0f0; */
  border-color: blue;
  /* } */
`;

const InfoBlock = styled.div`
  font-size: 12rem;
  font-weight: 400;
`;
const VmwareBlock = styled.div`
  padding: 10rem 0rem 0rem 10rem;
  border-radius: 5rem;
  transition: all 0.2s;
  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  &:hover {
    background-color: #f0f0f0;
    border-color: blue;
  }
`;
const SubBox = styled.div`
  font-size: 10rem;
  padding: 2rem;
`;

const LineBox = styled.div`
  /* display: flex; */
  height: 100%;
  border-width: 1rem;
  border-style: solid;
  border-color: blue;
  padding: 2rem;
  border-radius: 5rem;
`;
const WorkStationBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 110rem;
  font-size: 14rem;
  font-weight: 400;

  border-width: 1rem;
  border-style: solid;
  border-color: transparent;
  /* &:hover { */
  /* background-color: #f0f0f0; */
  /* border-color: blue; */
  border-color: #a0a0f0;
  /* } */

  h1 {
    color: #f00000;
    font-weight: 600;
  }
  h2 {
    margin-right: 10rem;
    color: blue;
    font-size: 18rem;
    font-weight: 600;
  }
  b {
    margin-right: 10rem;
    color: #1010a0;
    font-size: 16rem;
    font-weight: 600;
  }
`;
export default ShowInfoModule;
