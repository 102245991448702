import React from 'react'

function ErrorMessage({
    errors,
    name
}) {

    if(!errors?.[name]) return
  return (
    <>
    {errors?.[name].message}
    </>
  )
}

export default ErrorMessage