import { requestLoadWorkResultLogsGetDateLogs } from 'Common/Service/common';
import { Button, InputComponent } from 'Common/UI/atoms';
// import useMutations from 'Common/Service/userMutations';
import Box from 'Common/UI/atoms/Box';
import { currencyComma } from 'Common/Utils';
import {
  TextFail,
  TextSmall,
  TextSucceed,
  TextWarning,
  getKorDate,
  reload_second,
  today,
  today_str,
  useForceUpdate,
} from 'Common/Utils/util';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import TrafficSizeModule from './Module/TrafficSizeModule';

const WorkResultLogsDate = ({ date = null, uuid }) => {
  const [etcDo1Logs, setEtcDo1Logs] = useState([]);
  const [count, setCount] = useState(reload_second);
  const [checkDate, setCheckDate] = useState(date);
  const [apiId, setApiId] = useState();
  const [vmwareName, setVmwareName] = useState(null);
  const [fNum, setFNum] = useState(null);
  const [productInfoId, setProductInfoId] = useState(null);
  const [loadVmwareName, setLoadVmwareName] = useState(null);
  const [loadFNum, setLoadFNum] = useState(null);
  const [loadProductInfoId, setLoadProductInfoId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    clearInterval(apiId);
    const url = new URL(window.location.href);
    var f_num = url.searchParams.get('f_num');
    if (f_num) {
      setLoadFNum(f_num);
      setFNum(f_num);
    }
    var vmware_name = url.searchParams.get('vmware_name');
    if (vmware_name) {
      setVmwareName(vmware_name);
      setLoadVmwareName(vmware_name);
    }
    var product_info_id = url.searchParams.get('product_info_id');
    if (product_info_id) {
      setProductInfoId(product_info_id);
      setLoadProductInfoId(product_info_id);
    }
    setCheckDate(date);

    getEtcDo1Logs({ date, vmware_name, f_num, product_info_id });
    time_reload({ date, vmware_name, f_num, product_info_id });
  }, [date, uuid, loadVmwareName, loadFNum, loadProductInfoId]);
  const time_reload = ({ date, vmware_name, f_num, product_info_id }) => {
    clearInterval(apiId);

    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        var ct = prevCount - 1;
        if (ct < 0) {
          getEtcDo1Logs({ date, vmware_name, f_num, product_info_id });
          ct = reload_second;
        }
        return ct;
      });
    }, 1000);
    setApiId(intervalId);
  };
  // const reload = () => {
  //   clearInterval(apiId);
  //   getEtcDo1Logs({ date: checkDate, vmware_name: loadVmwareName, f_num: loadFNum });
  //   time_reload({ date: checkDate, vmware_name: loadVmwareName, f_num: loadFNum });
  // };
  const getEtcDo1Logs = async ({
    date = null,
    vmware_name = null,
    f_num = null,
    product_info_id = null,
  }) => {
    const infos = await requestLoadWorkResultLogsGetDateLogs({
      date: date ? date : checkDate,
      vmware_name: vmware_name ? vmware_name : loadVmwareName,
      f_num: f_num ? f_num : loadFNum,
      product_info_id: product_info_id ? product_info_id : loadProductInfoId,
    });
    setEtcDo1Logs(infos);
  };
  // useEffect(() => {
  // clearInterval(apiId);
  // if (today_str(checkDate) != today_str()) return;
  // const intervalId = setInterval(() => {
  //   setCount((prevCount) => {
  //     var ct = prevCount - 1;
  //     if (ct < 0) {
  //       getEtcDo1Logs(checkDate);
  //       ct = reload_second;
  //     }
  //     return ct;
  //   });
  // }, 1000);
  // setApiId(intervalId);
  // return () => clearInterval(intervalId);
  // }, [date, uuid]);
  if (!date) return;
  return (
    <Box column>
      <Box>
        result logs [{etcDo1Logs?.date}] <TextSmall>{count}</TextSmall>
      </Box>
      <Box>
        <Box left={50} width="300rem" column>
          <Box>succeed info</Box>
          <Box>
            <TextSmall>
              count:{' '}
              <TextSucceed>
                {currencyComma(etcDo1Logs?.work_log_infos?.ok_infos?.count)}
              </TextSucceed>
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              using time:{' '}
              <TextSucceed>
                {(etcDo1Logs?.work_log_infos?.ok_infos?.url_access_total_time / 60 / 60).toFixed(1)}{' '}
                /
                {(etcDo1Logs?.work_log_infos?.ok_infos?.access_real_using_sec / 60 / 60).toFixed(1)}{' '}
                hrs
              </TextSucceed>
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              avg using time:{' '}
              <TextSucceed>
                {(
                  etcDo1Logs?.work_log_infos?.ok_infos?.url_access_total_time /
                  etcDo1Logs?.work_log_infos?.ok_infos?.count /
                  1
                ).toFixed(1)}{' '}
                /
                {(
                  etcDo1Logs?.work_log_infos?.ok_infos?.access_real_using_sec /
                  etcDo1Logs?.work_log_infos?.ok_infos?.count /
                  1
                ).toFixed(1)}{' '}
                sec
              </TextSucceed>
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              traffic:{' '}
              <TrafficSizeModule
                size={
                  etcDo1Logs?.work_log_infos?.ok_infos?.url_access_request_size +
                  etcDo1Logs?.work_log_infos?.ok_infos?.url_access_response_size
                }
              />
            </TextSmall>
          </Box>
        </Box>
        <Box width="300rem" column>
          <Box>fail info</Box>
          <Box>
            <TextSmall>
              count:{' '}
              <TextFail>{currencyComma(etcDo1Logs?.work_log_infos?.fail_infos?.count)}</TextFail>
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              using time:{' '}
              <TextFail>
                {(etcDo1Logs?.work_log_infos?.fail_infos?.url_access_total_time / 60 / 60).toFixed(
                  1,
                )}{' '}
                /
                {(etcDo1Logs?.work_log_infos?.fail_infos?.access_real_using_sec / 60 / 60).toFixed(
                  1,
                )}{' '}
                hrs
              </TextFail>
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              avg using time:{' '}
              <TextFail>
                {(
                  etcDo1Logs?.work_log_infos?.fail_infos?.url_access_total_time /
                  etcDo1Logs?.work_log_infos?.fail_infos?.count /
                  1
                ).toFixed(1)}{' '}
                /
                {(
                  etcDo1Logs?.work_log_infos?.fail_infos?.access_real_using_sec /
                  etcDo1Logs?.work_log_infos?.fail_infos?.count /
                  1
                ).toFixed(1)}{' '}
                sec
              </TextFail>
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              traffic:{' '}
              <TextFail>
                {(
                  (etcDo1Logs?.work_log_infos?.fail_infos?.url_access_request_size +
                    etcDo1Logs?.work_log_infos?.fail_infos?.url_access_response_size) /
                  1024 /
                  1024
                ).toFixed(1)}
              </TextFail>{' '}
              MB
            </TextSmall>
          </Box>
        </Box>
        <Box column>
          <Box>
            <TextSmall>
              vmware{' '}
              <InputComponent
                // name={e?.id}
                fontSize={12}
                size={30}
                weight={400}
                maxLength={12}
                value={vmwareName}
                // padding={[0, 10, 0, 10]}
                // value={e.group_search_nvmid}
                onChange={(i) => {
                  setVmwareName(i?.target?.value);
                  // onInputChange(i);
                }}
              />
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              f_num{' '}
              <InputComponent
                // name={e?.id}
                fontSize={12}
                weight={400}
                size={30}
                maxLength={12}
                // padding={[0, 10, 0, 10]}
                value={fNum}
                onChange={(i) => {
                  setFNum(i?.target?.value);

                  // onInputChange(i);
                }}
              />
            </TextSmall>
          </Box>
          <Box>
            <TextSmall>
              product_info_id{' '}
              <InputComponent
                // name={e?.id}
                fontSize={12}
                weight={400}
                maxLength={12}
                size={30}
                // padding={[0, 10, 0, 10]}
                value={productInfoId}
                onChange={(i) => {
                  setProductInfoId(i?.target?.value);

                  // onInputChange(i);
                }}
              />
            </TextSmall>
          </Box>
          <Box>
            <Button
              Theme={'unFill'}
              fill
              onClick={async () => {
                // 49.228.235.37
                setLoadVmwareName(vmwareName);
                setLoadFNum(fNum);
                setLoadProductInfoId(productInfoId);
                navigate(
                  `/work/result_logs?vmware_name=${vmwareName ? vmwareName : ''}&f_num=${
                    fNum ? fNum : ''
                  }&product_info_id=${productInfoId ? productInfoId : ''}`,
                );
              }}
            >
              load
            </Button>
          </Box>
        </Box>
      </Box>

      {etcDo1Logs?.work_logs?.map((e, index) => {
        var problem_chk = false;
        const url_access_total_time = e?.url_access_total_time;
        const access_real_using_sec = e?.access_real_using_sec;
        const size_mb = (e?.url_access_request_size + e?.url_access_response_size) / 1024 / 1024;
        // const url_access_count = e?.url_access_countb;
        if (size_mb > 0.7) problem_chk = true;
        if (access_real_using_sec > url_access_total_time * 2 && access_real_using_sec > 180)
          problem_chk = true;

        return (
          <InfoBox column key={index} problem_chk={problem_chk}>
            <Box>
              <Box>
                <button
                  onClick={() => {
                    setProductInfoId(e?.product_info_id);
                    setLoadProductInfoId(e?.product_info_id);
                    navigate(
                      `/work/result_logs?vmware_name=${vmwareName ? vmwareName : ''}&f_num=${
                        fNum ? fNum : ''
                      }&product_info_id=${e?.product_info_id}`,
                    );
                  }}
                >
                  {e?.product_info_id}
                </button>
              </Box>
              <Box>
                <TextSmall>
                  [{e.is_succeed ? <TextSucceed>Succeed</TextSucceed> : <TextFail>Fail</TextFail>}]
                </TextSmall>
              </Box>
              <Box>
                <TextSmall>{e?.datetime.slice(10, 19)}</TextSmall>
              </Box>

              <Box>{e?.browser_type}</Box>
              <Box>
                <TextSmall>
                  <TextSucceed> {e?.proxy_db_ip_country}</TextSucceed>
                </TextSmall>
              </Box>
              <Box>
                <TextSmall>{e?.mall_name}</TextSmall>
              </Box>
              <Box>
                {e?.vmware_name} [{e?.f_num}]
              </Box>
              <Box>
                <TextSmall>
                  ct:
                  <TextSucceed>{e?.url_access_count}</TextSucceed>
                </TextSmall>
              </Box>
              <Box>
                tf: <TextSucceed>{size_mb.toFixed(1)}</TextSucceed> <TextSmall>MB</TextSmall>
              </Box>
              <Box>
                <TextSmall>
                  <TextSucceed>{e?.url_access_total_time} </TextSucceed>sec
                </TextSmall>
              </Box>
              <Box>
                <TextSmall>
                  <TextSucceed>{e?.access_real_using_sec} </TextSucceed>sec
                </TextSmall>
              </Box>
              <Box>
                <TextSmall>
                  problem:<TextFail> {e?.visit_problem_count}</TextFail>, timeout:
                  <TextFail> {e?.timeout_count}</TextFail>
                </TextSmall>
              </Box>
            </Box>
            <Box>
              <TextEtcStatus>{e?.step_logs?.slice(0, 300)}</TextEtcStatus>
            </Box>
            {e?.etc_status ? (
              <>
                <TextEtcStatus>
                  <TextSmall>
                    <TextWarning>{e?.etc_status}</TextWarning>
                  </TextSmall>
                </TextEtcStatus>
              </>
            ) : null}
            {e.is_succeed ? null : (
              <Box>
                <TextSmall>
                  last: <TextFail>{e?.last_which}</TextFail> error_status:{' '}
                  <TextFail>{e?.last_error_status}</TextFail>
                </TextSmall>
              </Box>
            )}
          </InfoBox>
        );
      })}
    </Box>
  );
};
const InfoBox = styled.div`
  gap: 8rem;
  font-size: 12rem;

  ${(props) => {
    const { problem_chk } = props;
    if (problem_chk)
      return css`
        background-color: #fff2f2;
      `;
  }}
`;
const TextEtcStatus = styled.div`
  width: 600rem;
  word-wrap: break-word;
`;
export default WorkResultLogsDate;
