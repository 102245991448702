import React from 'react';
import styled from 'styled-components';

const TimeBar = ({ children, color = 'G_100' }) => {
  const fColor = (() => {
    switch (color) {
      case 'G_100':
        return 'G_500';
      default:
        return color;
    }
  })();
  return (
    <Wrapper color={color} fColor={fColor}>
      <p className="time">{children}</p>
      <span />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ color, fColor }) => `
    display:flex;
    align-items:center;
    width:100%;
    gap:8rem;
    .time{
        min-width:32rem;
        color:var(--${fColor});
        font-size:12rem;
    }
    span{
        flex:1;
        height:1rem;
        display:flex;
        align-items:center;
        position:relative;
        background-color:var(--${color});
        &::before{
            content:'';
            width:4rem;
            height:4rem;
            border-radius:100%;
            background-color:var(--${color});
        }
    }
`}
`;

export default TimeBar;
