import styled, { css } from 'styled-components';
import { ToggleButtonIcon } from './CheckIconBox';

const ToggleButton = ({
  disabled = false,
  isReadOnly = false,
  active,
  text = '',
  onClick,
  children,
}) => {
  return (
    <ButtonWrapper
      isReadOnly={disabled || isReadOnly}
      // isDisabled={disabled}
      onClick={() => {
        if (disabled || isReadOnly) return;
        onClick();
      }}
    >
      <>
        <ToggleButtonIcon disabled={disabled} active={active} /> <Text>{children}</Text>
      </>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  gap: 8rem;
  height: 24rem;
  flex-direction: row;
  display: flex;
  align-items: center;

  ${(props) => {
    const { isReadOnly } = props;
    if (!isReadOnly) return;
    return css`
      cursor: not-allowed;
    `;
  }}
`;
const Text = styled.div`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 14rem;
  line-height: 172%;

  letter-spacing: -0.03em;

  color: var(--G_500);
`;
ToggleButton.defaultProps = {
  onClick: () => {},
};
export default ToggleButton;
