import styled, { css } from 'styled-components';

const inputPropsStyle = css`
  ${({ isDisabled, isFocus, isError, isSuccess, theme: { input } }) => {
    const { focus, success, error, disabled } = input;
    if (isDisabled)
      return css`
        ${disabled}
      `;
    if (isFocus)
      return css`
        ${focus}
      `;
    if (isSuccess)
      return css`
        ${success}
      `;
    if (isError)
      return css`
        ${error}
        input,textarea {
          &::placeholder {
            color: ${error.color};
          }
        }
      `;
  }}
`;
export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  ${({ isEdit, align, fil }) => {
    return css`
      ${!isEdit && align === 'center' && `width: auto;`}
      ${fil ? 'flex:1;' : ''}
    `;
  }}
`;
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  width: 100%;
  .viewText {
    width: 100%;
    overflow-y: scroll;
  }
  ${({ sizeY, underLine, isEdit, fSize, weight, fil, theme: { colors } }) => {
    const propsSize = sizeY ? `${sizeY}` : '36rem';
    const border = underLine
      ? {
          borderBottom: '1rem solid var(--G_100)',
        }
      : {
          border: `1rem solid ${isEdit ? 'var(--G_100)' : 'transparent'};`,
        };
    return css`
      ${fil ? 'flex:1;' : ''}
      height: ${propsSize};
      color: ${colors.G_700};
      border-color: ${colors.G_100};
      border-radius: ${underLine ? 0 : '8rem'};
      background-color: ${underLine ? 'transparent' : 'var(--White)'};
      transition: all 0.2s;
      ${border};
      &:hover {
        border-color: ${colors.g_150};
      }
      .inner {
        display: flex;
        align-items: center;
        height: 100%;
        flex: 1 1;
        padding: ${(props) => props.padding};
        gap: 12rem;
      }
      .iconBox {
        display: flex;
      }
      input,
      textarea {
        display: block;
        width: 100%;
        color: inherit;
        flex: 1;
        font-weight: ${weight};
        font-size: ${fSize};
        line-height: 172%;
        color: var(--G_700);
        &::placeholder {
          color: var(--G_200);
        }
      }
    `;
  }}
  p {
    height: 100%;
  }
  .iconDelete {
    display: flex;
    cursor: pointer;
  }
  .inputUtilsBox {
    display: flex;
    button {
      display: flex;
      + button {
        margin-left: 12rem;
      }
    }
  }
  .statusIcon {
    display: flex;
    margin-left: 12rem;
  }
  ${inputPropsStyle}
`;
export const Inner = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

export const LabelText = styled.span`
  display: flex;
  padding-left: 4rem;
  margin-bottom: 8rem;
  font-weight: 400;
  font-size: 16rem;
  line-height: 172%;
  color: var(--G_500);
`;

export const BottomTextBox = styled.div`
  display: ${(props) => (props.isHelp ? 'block' : 'none')};
  min-height: 20rem;
  padding-left: 4rem;
  margin-top: 8rem;
  font-weight: 400;
  font-size: 12rem;
  line-height: 18rem;
  color: var(--Error_500);
  .infoText {
    font-weight: 400;
    font-size: 12rem;
    line-height: 170%;
    color: var(--G_300);
  }
`;
export const TextLengthBox = styled.div`
  display: flex;
  margin: auto 0 8rem 0;
  font-weight: 400;
  font-size: 12rem;
  color: var(--P_500);
`;

export const FileWrapper = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const InputButtonWrapper = styled.div`
  position: relative;
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;
