import { requestLoadPaymentGetSlotStateCalendarMonthInfos } from 'Common/Service/slot';
import { Box, Grid, Icon, IconButton, TextButton } from 'Common/UI/atoms';
import { currencyComma, getWeekColorStr } from 'Common/Utils';
import { TextFail, TextSucceed, today_str } from 'Common/Utils/util';
// import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { onShowToast } from 'features/common/commonSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

const PaymentSlotDateCalendarMonth = ({ date, setDate, setCalendarType, partner_name }) => {
  const [infos, setInfos] = useState({});
  const [startDay, setStartDay] = useState();
  const [endDay, setEndDay] = useState(0);
  const [weekDay, setWeekDay] = useState(0);
  const dispatch = useDispatch();
  const getSlotStateCalendarMonth = async ({ start_date, end_date, partner_name }) => {
    const result = await requestLoadPaymentGetSlotStateCalendarMonthInfos({
      start_date,
      end_date,
      partner_name,
    });
    if (result) {
      setInfos(result);
    } else {
      dispatch(
        onShowToast({
          status: 'error',
          message: `오류가 발생했습니다`,
          cancelText: '',
          onCancel: false,
        }),
      );
    }
  };

  useEffect(() => {
    if (!date) return;
    const d = new Date(date);
    const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    const end_day_n = lastDayOfMonth.getDate();
    const first_date = moment(d).startOf('month');
    const last_date = moment(d).endOf('month');
    const week_n = first_date.day(); // 0: 일요일, 1: 월요일, ..., 6: 토요일
    const start_date = first_date.format('YYYY-MM-DD');
    const end_date = last_date.format('YYYY-MM-DD');
    setEndDay(end_day_n);
    setWeekDay(week_n);
    getSlotStateCalendarMonth({ start_date, end_date, partner_name });
  }, [date]);

  // const { data: scheduleList } = useQuery(
  //   [PRODUCT.SCHEDULE, period.startDate],
  //   () =>
  //     getProductSchedule({
  //       startDate: period.startDate,
  //       endDate: period.endDate,
  //     }),
  //   {
  //     enabled: !!period.startDate,
  //   },
  // );

  return (
    <Box column width="100%">
      <Box align="center">
        <IconButton
          size={'sm'}
          Theme={'unFill'}
          Icon={Icon.Arrow}
          onClick={() => {
            const oneMonthAgo = moment(date).add(-1, 'months');

            setDate(oneMonthAgo.format('YYYY-MM-DD'));
          }}
          direction="left"
        />
        {new Date(date).getFullYear()} - {new Date(date).getMonth() + 1}{' '}
        <IconButton
          size={'sm'}
          Theme={'unFill'}
          Icon={Icon.Arrow}
          onClick={() => {
            const oneMonthAgo = moment(date).add(1, 'months');

            setDate(oneMonthAgo.format('YYYY-MM-DD'));
          }}
          direction="right"
        />
      </Box>
      <Box>
        <Block>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((i, index) => {
            return (
              <>
                <Box align="center">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getWeekColorStr(i),
                    }}
                  />
                </Box>
              </>
            );
          })}

          {Array.from({ length: weekDay }, (_, i) => {
            return <DayCell>&nbsp;</DayCell>;
          })}
          {Array.from({ length: endDay }, (_, i) => {
            const check_date = moment(moment(date).format(`YYYY-MM-${i + 1}`)).format('YYYY-MM-DD');
            const d2 = moment(date).format('YYYY-MM-DD');
            const date_info = infos?.date_list?.[check_date]?.[0];
            const issue_day_count = date_info?.issue_day_count;
            const first_day_count = date_info?.first_day_count;
            const end_day_count = date_info?.end_day_count;
            const active_count = date_info?.active_new_count + date_info?.active_extend_count;
            const paid_count = date_info?.paid_new_count + date_info?.paid_extend_count;
            const refund_count = date_info?.refund_new_count + date_info?.refund_extend_count;
            const cancel_count = date_info?.cancel_new_count + date_info?.cancel_extend_count;
            const active_as_extend_count = date_info?.active_as_extend_count;
            const active_test_slot_count = date_info?.active_test_slot_count;
            return (
              <DayCell
                // active={}
                active={d2 === check_date}
                // onClick={() => {
                //   setDate(check_date);
                //   setCalendarType('day');
                // }}
              >
                <Box column width="100%">
                  <Box right={20} align="right">
                    {i + 1}
                  </Box>
                  <Box>
                    {issue_day_count ? (
                      <>
                        신규발급: <TextSucceed>{currencyComma(issue_day_count)}</TextSucceed>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box>
                    {end_day_count ? (
                      <>
                        슬롯만료: <TextFail>{currencyComma(end_day_count)}</TextFail>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box></Box>
                  <Box>{active_count ? <>일반&연장: {currencyComma(active_count)}</> : <></>}</Box>
                  <Box>{paid_count ? <>정산: {currencyComma(paid_count)}</> : <></>}</Box>
                  <Box>
                    {refund_count + cancel_count ? (
                      <>환불/취소: {currencyComma(refund_count + cancel_count)}</>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box>
                    {active_as_extend_count ? (
                      <>as연장: {currencyComma(active_as_extend_count)}</>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box>
                    {active_test_slot_count ? (
                      <>test: {currencyComma(active_test_slot_count)}</>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </DayCell>
            );
          })}
        </Block>
      </Box>
    </Box>
  );
};
const DayCell = styled.div`
  display: flex;
  padding: 5rem;
  &:hover {
    background-color: #f0f0f0;
  }
  /* justify-content: first baseline; */
  align-items: flex-start;
  font-size: 10rem;
  border-width: 1rem 1rem 0rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  /* border-color: gray; */
  min-height: 120rem;
  ${(props) => {
    const { active } = props;
    if (active) {
      return css`
        background-color: #e0e0f0;
      `;
    }
  }};
`;
const Block = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  border-width: 0rem 0rem 1rem 0rem;
  border-style: solid;
  border-color: #f0f0f0;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  ${(props) => {
    const { isShow } = props;
    // if (isShow) {
    //   return css`
    //     grid-template-columns: 3fr repeat(7, 1fr);
    //   `;
    // }
  }}
`;

export default PaymentSlotDateCalendarMonth;
