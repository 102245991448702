import { navigate_newtab } from 'Common/Utils';
import React from 'react';
import styled, { css } from 'styled-components';

const CommonProgressBar = ({
  click_fail_continue_count,
  // product,
  icon,
  mall_name = null,
  column,
  percent,
  name,
}) => {
  return (
    <Wrapper>
      <MinRow>
        <img
          src={icon}
          style={{
            height: '100%',
          }}
        />
      </MinRow>
      <MaxRow>
        <ProgressWrapper column={column}>
          <ProgressDescription
            click_fail_continue_count={click_fail_continue_count}
            onClick={() => {
              // product_id && navigate(`/logs/${product_id}`);
              if (mall_name) navigate_newtab(`/product/slot?slot=${mall_name}&active=s`);
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: name }} />
          </ProgressDescription>
          <Progress>
            <ProgressInner width={percent === undefined ? 0 : percent}></ProgressInner>
          </Progress>
          <ProgressStatus>
            {Math.floor(percent) < 10 ? '0' : ''}
            {percent === undefined ? 0 : Math.floor(percent)}%
          </ProgressStatus>
        </ProgressWrapper>
      </MaxRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 4rem 20rem;
  width: 100%;
  background: #f5f7ff;
  box-shadow: 0rem 0rem 3rem rgba(0, 0, 0, 0.04), 0rem 1rem 5rem rgba(0, 0, 0, 0.08);
  border-radius: 12rem;
  gap: 18rem;
  height: 30rem;
  justify-content: left;
  align-items: center;
`;
const MinRow = styled.div`
  position: relative;
`;
const MaxRow = styled.div`
  position: relative;
  flex: 1;
`;

const ProgressDescription = styled.button`
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  text-align: left;
  font-size: 12rem;
  letter-spacing: -0.03em;
  color: var(--G_700);

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${(props) => {
    const { click_fail_continue_count } = props;
    if (click_fail_continue_count === undefined) return;
    if (click_fail_continue_count < 20) return;
    return css`
      color: red;
    `;
  }}
`;
const ProgressWrapper = styled.div`
  display: grid;

  grid-template-columns: ${(props) => `${props.column}`};

  grid-gap: 0rem;

  gap: 30rem;
  justify-content: left;
  align-items: center;
`;
const Progress = styled.div`
  position: relative;
  flex: 1;
  height: 12rem;
  background: #ebebed;
  border-radius: 6rem;
`;
const ProgressInner = styled.div`
  width: ${(props) => props.width}%;
  height: 12rem;
  background: rgb(41, ${(props) => 100 + props.width}, 59);
  ${(props) => (props.width === 100 ? 'background: var(--P_400);' : '')}
  border-radius: 6rem;
`;
const ProgressStatus = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  font-size: 16rem;

  color: #29a33b;

  &::before {
    content: ' ';
    width: 8rem;
    height: 8rem;
    background: #29a33b;
    position: relative;
    display: inline-block;
    border-radius: 8rem;
    margin-right: 8rem;
    bottom: 1rem;
  }
`;

export default CommonProgressBar;
