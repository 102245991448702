import { useMutation } from 'react-query';
import {
  requestLoadUserModify,
  requestLoadUserGetModifyInfo,
  requestLoadUserAdd,
  requestLoadUserSet,
  requestLoadUserLoginCheck,
  requestLoadUserLogin,
  requestLoadUserNameCheck,
  requestLoadUserList,
  requestLoadSlotGroupGetNames,
  requestLoadPartnerGetNames,
  requestLoadUserGetNames,
  requestLoadUserNameExistCheck,
} from './user';
// all_force_test_click_allow_chk_false
const useMutations = (submit) => {
  const requestLoadUserLoginCheckMutation = useMutation(requestLoadUserLoginCheck, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserLoginCheckMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadSlotGroupGetNamesMutation = useMutation(requestLoadSlotGroupGetNames, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadSlotGroupGetNamesMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadPartnerGetNamesMutation = useMutation(requestLoadPartnerGetNames, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadPartnerGetNamesMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserGetNamesMutation = useMutation(requestLoadUserGetNames, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserGetNamesMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserLoginMutation = useMutation(requestLoadUserLogin, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserLoginMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserGetModifyInfoMutation = useMutation(requestLoadUserGetModifyInfo, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserGetModifyInfoMutation',
        success: true,
        result: data,
      });
    },
  });

  const requestLoadUserModifyMutation = useMutation(requestLoadUserModify, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserModifyMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserAddMutation = useMutation(requestLoadUserAdd, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserAddMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserSetMutation = useMutation(requestLoadUserSet, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserSetMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserNameCheckMutation = useMutation(requestLoadUserNameCheck, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserNameCheckMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserNameExistCheckMutation = useMutation(requestLoadUserNameExistCheck, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserNameExistCheckMutation',
        success: true,
        result: data,
      });
    },
  });
  const requestLoadUserListMutation = useMutation(requestLoadUserList, {
    onError: (error, variable, context) => {},
    onSuccess: (data, variables, context) => {
      submit({
        request: 'requestLoadUserListMutation',
        success: true,
        result: data,
      });
    },
  });
  return {
    requestLoadUserGetModifyInfoMutation,
    requestLoadUserModifyMutation,
    requestLoadUserLoginMutation,
    requestLoadUserLoginCheckMutation,
    requestLoadUserAddMutation,
    requestLoadUserSetMutation,
    requestLoadUserNameCheckMutation,
    requestLoadUserListMutation,
    requestLoadSlotGroupGetNamesMutation,
    requestLoadPartnerGetNamesMutation,
    requestLoadUserGetNamesMutation,
    requestLoadUserNameExistCheckMutation,
  };
};

export default useMutations;
